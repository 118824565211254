import { routerApp } from '../app.module';

routerApp.factory('CargoService', function() {

    var cargoes = [
        { id: 75, name: 'ACCOMMODATION UNITS' },
        { id: 52, name: 'AGGREGATE' },
        { id: 105, name: 'AGRI-PRODS' },
        { id: 37, name: 'AGRICULTURAL PRODUCTS' },
        { id: 3, name: 'ALUMINA' },
        { id: 15, name: 'AMMONIUM SULPHATE' },
        { id: 97, name: 'ANHYDRITE' },
        { id: 22, name: 'AUSSIE LOGS' },
        { id: 72, name: 'BAGGED CEMENT' },
        { id: 94, name: 'BAGGED RICE' },
        { id: 32, name: 'BARLEY' },
        { id: 74, name: 'BARYTES' },
        { id: 5, name: 'BAUXITE' },
        { id: 103, name: 'BEACH IRON' },
        { id: 30, name: 'BHF' },
        { id: 65, name: 'BIOMASS' },
        { id: 66, name: 'CANOLA' },
        { id: 106, name: 'CANOLA MEAL PELLETS' },
        { id: 82, name: 'CASTOR SEED' },
        { id: 13, name: 'CEMENT' },
        { id: 51, name: 'CHROME ORE' },
        { id: 116, name: 'CLAY' },
        { id: 17, name: 'CLINKER' },
        { id: 2, name: 'COAL' },
        { id: 89, name: 'COAL TAR -PITCH' },
        { id: 81, name: 'COKE' },
        { id: 108, name: 'CONCENTRATES' },
        { id: 48, name: 'CONENTRATES' },
        { id: 112, name: 'COPPER CONCENTRATE' },
        { id: 117, name: 'COOKIE MEAL'},
        { id: 19, name: 'CORN' },
        { id: 101, name: 'CORN+SOYBEAN MEAL' },
        { id: 42, name: 'DAP' },
        { id: 45, name: 'DDGS' },
        { id: 67, name: 'DOLOMITE' },
        { id: 71, name: 'DRI' },
        { id: 91, name: 'FELDSPAR' },
        { id: 110, name: 'FERTILIZERS' },
        { id: 84, name: 'FLUORSPAR' },
        { id: 80, name: 'FLXSEED' },
        { id: 99, name: 'FLY ASH' },
        { id: 69, name: 'GENRAL CARGO' },
        { id: 50, name: 'GYPSUM' },
        { id: 63, name: 'HBI' },
        { id: 61, name: 'HRC' },
        { id: 10, name: 'HSS' },
        { id: 87, name: 'ILMENITE SAND' },
        { id: 9, name: 'IRON ORE' },
        { id: 11, name: 'IRON ORE FINES' },
        { id: 54, name: 'IRON ORE PELLETS' },
        { id: 12, name: 'KIWI LOGS' },
        { id: 107, name: 'KMAG' },
        { id: 18, name: 'LIMESTONE' },
        { id: 26, name: 'LOG' },
        { id: 59, name: 'LUMBER' },
        { id: 55, name: 'LUPINS' },
        { id: 70, name: 'MAGNETITE CONCS' },
        { id: 92, name: 'MAIZE' },
        { id: 40, name: 'MANGANESE ORE' },
        { id: 43, name: 'MAP' },
        { id: 78, name: 'MCP' },
        { id: 77, name: 'MESZ' },
        { id: 49, name: 'MET COAL' },
        { id: 28, name: 'METALLURGICAL COKE' },
        { id: 62, name: 'METCOKE' },
        { id: 38, name: 'MILL SCALE' },
        { id: 39, name: 'MILLED RICE' },
        { id: 34, name: 'MILLSCALE' },
        { id: 104, name: 'MINERALS' },
        { id: 90, name: 'MOP' },
        { id: 33, name: 'NICKEL ORE' },
        { id: 20, name: 'NPK' },
        { id: 56, name: 'OATS' },
        { id: 95, name: 'OLIVINE' },
        { id: 102, name: 'PADDY RICE' },
        { id: 76, name: 'PEAS' },
        { id: 1, name: 'PETCOKE' },
        { id: 100, name: 'PHOSPHATE' },
        { id: 21, name: 'PHOSROCK' },
        { id: 93, name: 'PIG IRON' },
        { id: 64, name: 'PKE' },
        { id: 58, name: 'PKS' },
        { id: 16, name: 'POTASH' },
        { id: 47, name: 'PYRITES' },
        { id: 88, name: 'QUARTZ' },
        { id: 83, name: 'RAPE SEED' },
        { id: 29, name: 'SALT' },
        { id: 4, name: 'SAND' },
        { id: 46, name: 'SBM' },
        { id: 53, name: 'SCRAP' },
        { id: 69, name: 'SEED CAKE' },
        { id: 73, name: 'SINTFD' },
        { id: 68, name: 'SLABS' },
        { id: 31, name: 'SLAG' },
        { id: 114, name: 'SODA ASH' },
        { id: 57, name: 'SOREL FLUX' },
        { id: 25, name: 'SOYA BEAN MEAL' },
        { id: 44, name: 'SOYABEANS' },
        { id: 41, name: 'STEEL COILS' },
        { id: 111, name: 'STEEL SHELLS' },
        { id: 27, name: 'STEEL SLABS' },
        { id: 79, name: 'STEELP' },
        { id: 8, name: 'SUGAR' },
        { id: 14, name: 'SULPHUR' },
        { id: 96, name: 'SUNFLOWER SEEDS' },
        { id: 86, name: 'TALC' },
        { id: 85, name: 'TAPIOCA CHIPS' },
        { id: 109, name: 'TSP' },
        { id: 7, name: 'UREA' },
        { id: 6, name: 'WHEAT' },
        { id: 98, name: 'WOOD CHIPS' },
        { id: 35, name: 'WOOD PELLETS' },
        { id: 60, name: 'WOODPULP' },
        { id: 24, name: 'ZINC CONCENTRATE' }
    ];

    var dorianCargoes = [
        { name: 'N-BUTANE' },
        { name: 'ISOBUTANE' },
        { name: 'BUTYLENE' },
        { name: 'PROPANE' },
        { name: 'COMMERCIAL PROPANE (2.5 MOLE% ETHANE)' },
        { name: 'COMMERCIAL PROPANE (5.0 MOLE% ETHANE)' },
        { name: 'COMMERCIAL PROPANE (8.0 MOLE% ETHANE)' },
        { name: 'PROPYLENE' },
    ];

    return {
        getCargoes: function(selectedVessel) {
            if (selectedVessel.vesselType && selectedVessel.vesselType.indexOf('tanker') != -1) {
                // Disable autocomplete/typeahead for tankers.
                return [];
            } else if (selectedVessel.company == 'Dorian LPG') {
                return dorianCargoes;
            } else {
                return cargoes;
            }
        }
    };
});
