import { routerApp, baseUrl, fuelGradeMapping } from '../app.module';

routerApp.controller('clientSettingsController', ['ClientSettingsService', '$rootScope', '$scope', '$http', 'notify', function(ClientSettingsService, $rootScope, $scope, $http, notify) {
    $rootScope.selectedLevels = ['client-settings'];

    $scope.clientSettingsTab = 'veslinkConfig';

    $scope.fuelGradeMapping = fuelGradeMapping;

    $scope.saveClient = () => {
        ClientSettingsService.saveClient($scope.selectedClient).then((res: any) => {
            notify({message: 'Client saved successfully', duration: 2000, classes: ['ok-notification']});
            $scope.selectedClient = ClientSettingsService.setClient(res.data.data);
        }).catch(() => {
            notify({message: 'Error saving client', duration: 2000, classes: ['bad-notification']});
        });
    };

    $scope.$watch('selectedClientId', (newValue: string) => {
        if (newValue) {
            $scope.pageState.setLoadingData();
            ClientSettingsService.getClient(newValue).then((res: any) => {
                $scope.selectedClient = ClientSettingsService.setClient(res.data.data);
                $scope.pageState.setDataLoaded();
            });
        }
    });

    // get clients for dropdown
    $http({
        url: baseUrl + 'clients/',
        method: 'GET',
    }).then(function(response: any) {
        $scope.clientsList = response.data.data;
    });
}]);
