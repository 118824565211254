import { routerApp, analyticsUrl } from '../../app.module';

routerApp.factory('VesselService', ['$http',function($http) {

    var vessels = null;
    var metrics = null;

    return {
        loadVessels: function(username) {
            var url = analyticsUrl + "vessels?username=" + username;
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        setVessels: function(data) {
            vessels = data;
        },
        getVessels: function() {
            return vessels;
        },
        getVesselFromVesselList: function(allVessels, vesselId) {
            for (var i = 0; i < allVessels.length; i++) {
                var _vessel = allVessels[i];
                if (_vessel.id == vesselId) {
                    return _vessel;
                }
            }
            return null;
        },
        getVesselVoyages: function(vesselIds, voyageIdType, reportFrom, reportTo) {
            return $http.post(analyticsUrl + 'vessels/voyages', {
                vessel_ids: vesselIds,
                voyage_id_type: voyageIdType,
                report_from: reportFrom,
                report_to: reportTo
            });
        },
        loadMetrics: function(vesselId, reportFrom, reportTo) {
            var url = analyticsUrl + "vessels/" + vesselId + "/voyage_metrics";
            return $http.get(url, {
                params: {
                    reportFrom: reportFrom,
                    reportTo: reportTo
                }
            });
        },
        setMetrics: function(data) {
            metrics = data;
        },
        getMetrics: function() {
            return metrics;
        }
    };

}]);
