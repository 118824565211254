import { routerApp } from '../app.module';

routerApp.factory('PageState', function() {

    var PageState = function () {

        var States = {
            INITIALIZED: 'initialized',
            LOADING: 'loading',
            LOADED: 'data_loaded',
            NODATA: 'no_data',
            ERROR: 'error'
        }

        var state = States.INITIALIZED;

        return {
            // returns currently set state
            state: state,

            // trigger before the page starts loading data
            setLoadingData: function() {
                this.state = States.LOADING;
            },

            // trigger before the page is done loading data
            setDataLoaded: function() {
                this.state = States.LOADED;
            },

            // trigger when the page loads but there's no data
            setNoData: function() {
                this.state = States.NODATA;
            },

            // trigger when the requests are made but there's an error
            setError: function() {
                this.state = States.ERROR;
            },

            // display loading page
            isLoadingData: function() {
                return this.state === States.LOADING;
            },
            // display actual page
            isDataLoaded: function() {
                return this.state === States.LOADED;
            },
            // display no data page
            isNoData: function() {
                return this.state === States.NODATA;
            },
            // display error page
            isError: function() {
                return this.state === States.ERROR;
            }
        }
    }
    return PageState;

});