const fuelGrades = {
    'hfo' :'HFO',
    'lfo' :'LFO',
    'mgo' :'MGO',
    'mdo' :'MDO',
    'b10lfo' :'B10LFO',
    'b10mgo' :'B10MGO',
    'biolfo' :'BioLFO',
    'biomgo' :'BioMGO',
    'ulsfo2020' :'ULSFO2020',
    'ulslfo2020' :'ULSLFO2020',
    'ulsmdo2020' :'ULSMDO2020',
    'ulsmgo2020' :'ULSMGO2020',
    'vlsfo2020' :'VLSFO2020',
    'vlslfo2020' :'VLSLFO2020',
    'lpgp' :'LPGP',
    'lpgb' :'LPGB',
    'lng' :'LNG',
    'methanol' :'Methanol',
    'ethanol' :'Ethanol',
    'other' :'Other'
}

var addUsedFuelstoListOfFuels = function(enabledFuels, fuelsUsedInReport) {
    var enabled_fuel_types = enabledFuels
    if (fuelsUsedInReport != undefined && fuelsUsedInReport.length > 0) {
        for (var j=0; j<fuelsUsedInReport.length; j++) {
            if (enabled_fuel_types.includes(fuelsUsedInReport[j]) != true) {
                enabled_fuel_types.push(fuelsUsedInReport[j])
            }
        }
    }
    return enabled_fuel_types
}

export {
    fuelGrades,
    addUsedFuelstoListOfFuels
}