import { routerApp, baseUrl } from '../../app.module';

routerApp.controller('imoDcsReportingController', ['$rootScope', '$scope', '$http', 'PageState', function($rootScope, $scope, $http, PageState) {

    $rootScope.selectedMenu = 'imo-dcs';

    $scope.pageState = new PageState();
    $scope.pageState.setLoadingData();

    $http({
        url: baseUrl + 'mrv/' + $rootScope.selectedVessel.id + "/imoDcs",
        method: "GET"
    }).then(function(response) {
        var data = response.data.data;
        $scope.imoDcsData = data.imo_dcs_data;
        if (!$scope.imoDcsData) {
            $scope.pageState.setNoData();
        } else {
            $scope.pageState.setDataLoaded();
        }
        $scope.year = data.year;
        if ($rootScope.username == 'verifavia') {
            $scope.year = 2018;
        }
        $scope.imoDcsDataFuelTypes = [];
        for (var key in $scope.imoDcsData.fuel_types_used) {
            if ($scope.imoDcsData.fuel_types_used[key]) {
                $scope.imoDcsDataFuelTypes.push($scope.imoDcsData.fuel_types_used[key])
            }
        }
        $scope.IMODCSDownloadLink = data.imo_dcs_dropbox_url;
    }, function() {
        $scope.pageState.setNoData();
    });

}]);
