import { routerApp } from '../../app.module';

routerApp.controller('takeawaysController', ['$rootScope', '$scope', 'TakeawaysService', 
    function($rootScope, $scope, TakeawaysService) {
    $rootScope.selectedMenu = 'takeaways';

    TakeawaysService.getTakeaways($rootScope.selectedVessel.id).then(function(data) {
        $scope.takeawaysList = data.data;
    });

    $scope.saveTakeaway = function(takeaway) {
        if ($scope.takeawayForm.$invalid) {

        } else {
            TakeawaysService.postTakeaway($rootScope.selectedVessel.id, $scope.takeaways).then(function(data) {
                // reload takeaways
                $scope.takeawaysList = data.data;
                console.log('new list ');
                console.log($scope.takeawaysList);
                $scope.takeaways = {};
            });
        }

    }
}]);