import { routerApp, analyticsUrl } from '../../app.module';

routerApp.factory('FleetPerformanceService', ['$http', function($http) {

    return {
        getAggregateMetrics: function(username, reportFrom = null, vessel_ids = null) {
            return $http.post(analyticsUrl + 'fleetPerformanceAPI/aggregateMetrics', {
                username: username,
                reportFrom: reportFrom,
                vesselIds: vessel_ids                
            });
        },
        getMetricData: function(username, metricKey, reportFrom = null, vessel_ids = null) {
            return $http.post(analyticsUrl + 'fleetPerformanceAPI/getMetric', {
                    username: username,
                    metric: metricKey,
                    reportFrom: reportFrom,
                    vesselIds: vessel_ids
            })
        }
    }
}]);