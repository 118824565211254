import { routerApp } from '../app.module';

routerApp.factory('PortService', function() {

    var allPorts = [
            { n: "(ARCTIC) BERING SEA",	l: "UNITED STATES" },
            { n: "(ARCTIC) NORTH SEA ROUTE",	l: "RUSSIA" },
            { n: "(ARCTIC) NORTH-WEST PASSAGE",	l: "CANADA" },
            { n: "1 DAMORTIS ANCHORAGE",	l: "PHILIPPINES" },
            { n: "16N RED SEA",	l: "SAUDI ARABIA" },
            { n: "17N RED SEA",	l: "SAUDI ARABIA" },
            { n: "18N RED SEA",	l: "SAUDI ARABIA" },
            { n: "19N RED SEA",	l: "SUDAN" },
            { n: "7N GALLE",	l: "SRI LANKA" },
            { n: "A.R.A.",	l: "BELGIUM" },
            { n: "A.R.A.G.",	l: "BELGIUM" },
            { n: "AABENRAA",	l: "DENMARK" },
            { n: "AAHEIM",	l: "NORWAY" },
            { n: "AAIUN",	l: "WESTERN SAHARA" },
            { n: "AALBORG",	l: "DENMARK" },
            { n: "AALESUND",	l: "NORWAY" },
            { n: "AARHUS",	l: "DENMARK" },
            { n: "AARSNES",	l: "NORWAY" },
            { n: "AASIAAT",	l: "GREENLAND" },
            { n: "ABADAN",	l: "IRAN" },
            { n: "ABASHIRI",	l: "JAPAN" },
            { n: "ABBOT POINT",	l: "AUSTRALIA" },
            { n: "ABERAERON",	l: "UNITED KINGDOM" },
            { n: "ABERDEEN (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "ABERDEEN (USA)",	l: "UNITED STATES" },
            { n: "ABERDOVEY",	l: "UNITED KINGDOM" },
            { n: "ABIDJAN",	l: "COTE D'IVOIRE" },
            { n: "ABO",	l: "FINLAND" },
            { n: "ABO OFFSHORE TERMINAL",	l: "NIGERIA" },
            { n: "ABONNEMA",	l: "NIGERIA" },
            { n: "ABOSHI",	l: "JAPAN" },
            { n: "ABRA",	l: "SPAIN" },
            { n: "ABRAAO, ILHA GRANDE",	l: "BRAZIL" },
            { n: "ABU AL BUKHOOSH",	l: "UNITED ARAB EMIRATES" },
            { n: "ABU AL KASHIB",	l: "IRAQ" },
            { n: "ABU DHABI",	l: "UNITED ARAB EMIRATES" },
            { n: "ABU FULUS",	l: "IRAQ" },
            { n: "ABU GHUSUN",	l: "EGYPT" },
            { n: "ABU KAMMASH",	l: "LIBYA" },
            { n: "ABU QIR",	l: "EGYPT" },
            { n: "ABU TERMINAL",	l: "MALAYSIA" },
            { n: "ABU ZENIMA",	l: "EGYPT" },
            { n: "ABURAKAWA",	l: "JAPAN" },
            { n: "ACAJUTLA",	l: "EL SALVADOR" },
            { n: "ACAJUTLA OFFSHORE TERMINAL",	l: "EL SALVADOR" },
            { n: "ACANDIA",	l: "GREECE" },
            { n: "ACAPULCO",	l: "MEXICO" },
            { n: "ACC PILOTAGE ZONE 1",	l: "CANADA" },
            { n: "ACC PILOTAGE ZONE 2",	l: "CANADA" },
            { n: "ACC PILOTAGE ZONE 3",	l: "CANADA" },
            { n: "ACC PILOTAGE ZONE 4",	l: "CANADA" },
            { n: "ACCRA",	l: "GHANA" },
            { n: "ACEH",	l: "INDONESIA" },
            { n: "ACHLADI",	l: "GREECE" },
            { n: "ACKANSA",	l: "TURKEY" },
            { n: "ACU",	l: "BRAZIL" },
            { n: "ADABIYA",	l: "EGYPT" },
            { n: "ADAK",	l: "UNITED STATES" },
            { n: "ADALIA",	l: "TURKEY" },
            { n: "ADAMAS",	l: "GREECE" },
            { n: "ADANG BAY",	l: "INDONESIA" },
            { n: "ADDU ATOLL",	l: "MALDIVES" },
            { n: "ADELAIDE",	l: "AUSTRALIA" },
            { n: "ADEN",	l: "YEMEN" },
            { n: "ADEN ALPHA",	l: "YEMEN" },
            { n: "ADEN BRAVO",	l: "YEMEN" },
            { n: "ADEN RENDEZVOUS A",	l: "YEMEN" },
            { n: "ADEN RENDEZVOUS B",	l: "YEMEN" },
            { n: "ADEN RENDEZVOUS C",	l: "YEMEN" },
            { n: "ADEN-JAPAN",	l: "YEMEN" },
            { n: "ADLAY",	l: "PHILIPPINES" },
            { n: "ADMIRAL BARROSO TERMINAL",	l: "BRAZIL" },
            { n: "ADRA",	l: "SPAIN" },
            { n: "AEGEAN SEA",	l: "GREECE" },
            { n: "AEROSKOBING",	l: "DENMARK" },
            { n: "AES ANDRES",	l: "DOMINICAN REPUBLIC" },
            { n: "AGADIR",	l: "MOROCCO" },
            { n: "AGBAMI TERMINAL",	l: "NIGERIA" },
            { n: "AGGERSUND",	l: "DENMARK" },
            { n: "AGHIOS NIKOLAOS",	l: "GREECE" },
            { n: "AGIA MARINA",	l: "GREECE" },
            { n: "AGIA TRIAS",	l: "GREECE" },
            { n: "AGIOI THEODOROI",	l: "GREECE" },
            { n: "AGIOS NIKOLAOS",	l: "GREECE" },
            { n: "AGOTNES",	l: "NORWAY" },
            { n: "AGUADILLA",	l: "PUERTO RICO" },
            { n: "AGUADULCE",	l: "PANAMA" },
            { n: "AGUATHUNA",	l: "CANADA" },
            { n: "AGUILAS",	l: "SPAIN" },
            { n: "AGUIRRE",	l: "PUERTO RICO" },
            { n: "AHMED BIN RASHID PORT",	l: "UNITED ARAB EMIRATES" },
            { n: "AHUS",	l: "SWEDEN" },
            { n: "AIGIALLI",	l: "GREECE" },
            { n: "AIMELIIK",	l: "PACIFIC ISLANDS (PALAU)" },
            { n: "AIN SUKHNA TERMINAL",	l: "EGYPT" },
            { n: "AIOI",	l: "JAPAN" },
            { n: "AIRLIE BEACH",	l: "AUSTRALIA" },
            { n: "AIRLIE ISLAND",	l: "AUSTRALIA" },
            { n: "AITAPE",	l: "PAPUA NEW GUINEA" },
            { n: "AIYION",	l: "GREECE" },
            { n: "AJACCIO",	l: "FRANCE" },
            { n: "AJMAN",	l: "UNITED ARAB EMIRATES" },
            { n: "AJOS",	l: "FINLAND" },
            { n: "AJUY",	l: "PHILIPPINES" },
            { n: "AKABA",	l: "JORDAN" },
            { n: "AKAROA",	l: "NEW ZEALAND" },
            { n: "AKASAKI",	l: "JAPAN" },
            { n: "AKCANSA",	l: "TURKEY" },
            { n: "AKER BASE",	l: "NORWAY" },
            { n: "AKITA",	l: "JAPAN" },
            { n: "AKO",	l: "JAPAN" },
            { n: "AKONNIEMI",	l: "FINLAND" },
            { n: "AKPO OFFSHORE TERMINAL",	l: "NIGERIA" },
            { n: "AKRANES",	l: "ICELAND" },
            { n: "AKREHAMN",	l: "NORWAY" },
            { n: "AKROTIRI",	l: "CYPRUS" },
            { n: "AKTAU",	l: "KAZAKHSTAN" },
            { n: "AKULIVIK, QC",	l: "CANADA" },
            { n: "AKUREYRI",	l: "ICELAND" },
            { n: "AKUTAN",	l: "UNITED STATES" },
            { n: "AKYAB",	l: "MYANMAR" },
            { n: "AL HOCEIMA",	l: "MOROCCO" },
            { n: "AL KHALIJ",	l: "LIBYA" },
            { n: "AL KHOBAR",	l: "SAUDI ARABIA" },
            { n: "AL KHUMS",	l: "LIBYA" },
            { n: "AL KUWAYT",	l: "KUWAIT" },
            { n: "AL LITH",	l: "SAUDI ARABIA" },
            { n: "AL QADIMAH",	l: "SAUDI ARABIA" },
            { n: "AL RAYYAN MARINE TERMINAL",	l: "QATAR" },
            { n: "AL SHAHEEN TERMINAL",	l: "QATAR" },
            { n: "ALA",	l: "SWEDEN" },
            { n: "ALABAMA INLAND PORTS",	l: "UNITED STATES" },
            { n: "ALABAMA RIVER END",	l: "UNITED STATES" },
            { n: "ALADJA",	l: "NIGERIA" },
            { n: "ALAMEDA",	l: "UNITED STATES" },
            { n: "ALAND ARCHIPELAGO",	l: "FINLAND" },
            { n: "ALANG",	l: "INDIA" },
            { n: "ALANYA",	l: "TURKEY" },
            { n: "ALAS STRAIT",	l: "INDONESIA" },
            { n: "ALBAKR TERMINAL",	l: "IRAQ" },
            { n: "ALBANIA (COUNTRY)",	l: "ALBANIA" },
            { n: "ALBANY (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "ALBANY (USA)",	l: "UNITED STATES" },
            { n: "ALBUERA",	l: "PHILIPPINES" },
            { n: "ALCAN",	l: "UNITED STATES" },
            { n: "ALCANAR",	l: "SPAIN" },
            { n: "ALCASA",	l: "VENEZUELA" },
            { n: "ALCOY",	l: "PHILIPPINES" },
            { n: "ALCUDIA",	l: "SPAIN" },
            { n: "ALDABRA ISLAND",	l: "SEYCHELLES" },
            { n: "ALDERNEY",	l: "CHANNEL ISLANDS" },
            { n: "ALEKSANDROVSK SAKHALINSKY",	l: "RUSSIA" },
            { n: "ALEMOA TERMINAL",	l: "BRAZIL" },
            { n: "ALEUTIAN EXCLUSION",	l: "UNITED STATES" },
            { n: "ALEXANDRETTA",	l: "TURKEY" },
            { n: "ALEXANDRIA (EGYPT)",	l: "EGYPT" },
            { n: "ALEXANDRIA (USA)",	l: "UNITED STATES" },
            { n: "ALEXANDROUPOLIS",	l: "GREECE" },
            { n: "ALGECIRAS",	l: "SPAIN" },
            { n: "ALGERIA (COUNTRY)",	l: "ALGERIA" },
            { n: "ALGHERO",	l: "ITALY" },
            { n: "ALGIERS",	l: "ALGERIA" },
            { n: "ALGIERS CANAL END",	l: "UNITED STATES" },
            { n: "ALGOA BAY",	l: "SOUTH AFRICA" },
            { n: "ALGONAC",	l: "UNITED STATES" },
            { n: "ALIAGA",	l: "TURKEY" },
            { n: "ALICANTE",	l: "SPAIN" },
            { n: "ALICANTE BAY",	l: "SPAIN" },
            { n: "ALIVERI BAY",	l: "GREECE" },
            { n: "ALIVERION",	l: "GREECE" },
            { n: "ALKMAAR",	l: "NETHERLANDS" },
            { n: "ALLARDYCE HARBOUR",	l: "SOLOMON ISLANDS" },
            { n: "ALLEGHENY END",	l: "UNITED STATES" },
            { n: "ALLEPEY",	l: "INDIA" },
            { n: "ALLIANCE",	l: "UNITED STATES" },
            { n: "ALLINGE",	l: "DENMARK" },
            { n: "ALMERIA",	l: "SPAIN" },
            { n: "ALMIRANTE",	l: "PANAMA" },
            { n: "ALMOTA",	l: "UNITED STATES" },
            { n: "ALOTAU",	l: "PAPUA NEW GUINEA" },
            { n: "ALPENA",	l: "UNITED STATES" },
            { n: "ALPHA LIGHTERING ZONE",	l: "ARGENTINA" },
            { n: "ALPHA ZONE (RIVER PLATE)",	l: "URUGUAY" },
            { n: "ALSEN",	l: "UNITED STATES" },
            { n: "ALTA",	l: "NORWAY" },
            { n: "ALTAGRACIA",	l: "VENEZUELA" },
            { n: "ALTAMIRA",	l: "MEXICO" },
            { n: "ALTER DO CHAO",	l: "BRAZIL" },
            { n: "ALUMAR",	l: "BRAZIL" },
            { n: "ALVARADO",	l: "MEXICO" },
            { n: "ALVIK",	l: "NORWAY" },
            { n: "AMA",	l: "UNITED STATES" },
            { n: "AMAGASAKI",	l: "JAPAN" },
            { n: "AMAGER",	l: "DENMARK" },
            { n: "AMALIAPOLIS",	l: "GREECE" },
            { n: "AMAMAPARE",	l: "INDONESIA" },
            { n: "AMBARLI",	l: "TURKEY" },
            { n: "AMBAS BAY",	l: "CAMEROON" },
            { n: "AMBER COVE",	l: "DOMINICAN REPUBLIC" },
            { n: "AMBES",	l: "FRANCE" },
            { n: "AMBILOBE BAY",	l: "MADAGASCAR" },
            { n: "AMBON",	l: "INDONESIA" },
            { n: "AMBRIZ",	l: "ANGOLA" },
            { n: "AMBROSE LIGHT",	l: "UNITED STATES" },
            { n: "AMBROSE LIGHT 2",	l: "UNITED STATES" },
            { n: "AMCHIT",	l: "LEBANON" },
            { n: "AMDERMA",	l: "RUSSIA" },
            { n: "AMERICAN SAMOA (COUNTRY)",	l: "AMERICAN SAMOA" },
            { n: "AMHERST",	l: "CANADA" },
            { n: "AMHERSTBURG",	l: "CANADA" },
            { n: "AMIRABAD",	l: "IRAN" },
            { n: "AMLAN",	l: "PHILIPPINES" },
            { n: "AMMASSALIK",	l: "GREENLAND" },
            { n: "AMORGOS ISLAND",	l: "GREECE" },
            { n: "AMOY",	l: "CHINA" },
            { n: "AMPENAM",	l: "INDONESIA" },
            { n: "AMRUN PORT", l: "AUSTRALIA"},
            { n: "AMSTERDAM",	l: "NETHERLANDS" },
            { n: "AMUAY BAY",	l: "VENEZUELA" },
            { n: "AMURANG",	l: "INDONESIA" },
            { n: "ANACORTES",	l: "UNITED STATES" },
            { n: "ANADYR",	l: "RUSSIA" },
            { n: "ANAKAN",	l: "PHILIPPINES" },
            { n: "ANAN",	l: "JAPAN" },
            { n: "ANAPA",	l: "RUSSIA" },
            { n: "ANAVILHANAS",	l: "BRAZIL" },
            { n: "ANCHORAGE",	l: "UNITED STATES" },
            { n: "ANCON",	l: "PERU" },
            { n: "ANCONA",	l: "ITALY" },
            { n: "ANCUD",	l: "CHILE" },
            { n: "ANDALSNES",	l: "NORWAY" },
            { n: "ANDIPORT",	l: "ECUADOR" },
            { n: "ANDOANY",	l: "MADAGASCAR" },
            { n: "ANDRES LNG TERMINAL",	l: "DOMINICAN REPUBLIC" },
            { n: "ANDROS ISLAND",	l: "GREECE" },
            { n: "ANDROS TOWN",	l: "THE BAHAMAS" },
            { n: "ANEGADA PASSAGE",	l: "ANGUILLA" },
            { n: "ANEWA BAY",	l: "PAPUA NEW GUINEA" },
            { n: "ANFENG",	l: "CHINA" },
            { n: "ANGGREK PORT",	l: "INDONESIA" },
            { n: "ANGMAGSSALIK",	l: "GREENLAND" },
            { n: "ANGO-ANGO",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "ANGOCHE",	l: "MOZAMBIQUE" },
            { n: "ANGOLA (COUNTRY)",	l: "ANGOLA" },
            { n: "ANGOON",	l: "UNITED STATES" },
            { n: "ANGRA DO HEROISMO",	l: "PORTUGAL" },
            { n: "ANGRA DOS REIS",	l: "BRAZIL" },
            { n: "ANGSI TERMINAL",	l: "MALAYSIA" },
            { n: "ANGUILLA",	l: "ANGUILLA" },
            { n: "ANGUILLA ISLAND (COUNTRY)",	l: "ANGUILLA" },
            { n: "ANJUNG KECIL TERMINAL",	l: "MALAYSIA" },
            { n: "ANLINGDU",	l: "CHINA" },
            { n: "ANNABA",	l: "ALGERIA" },
            { n: "ANNAPOLIS ANCHORAGE",	l: "UNITED STATES" },
            { n: "ANNAPOLIS ROYAL",	l: "CANADA" },
            { n: "ANOA NATUNA",	l: "INDONESIA" },
            { n: "AN-PING",	l: "TAIWAN" },
            { n: "ANQING",	l: "CHINA" },
            { n: "ANTALAHA",	l: "MADAGASCAR" },
            { n: "ANTALYA",	l: "TURKEY" },
            { n: "ANTAN TERMINAL",	l: "NIGERIA" },
            { n: "ANTARCTICA",	l: "ANTARCTICA" },
            { n: "ANT-GHENT",	l: "BELGIUM" },
            { n: "ANT-HBG",	l: "BELGIUM" },
            { n: "ANTIBES",	l: "FRANCE" },
            { n: "ANTIFER",	l: "FRANCE" },
            { n: "ANTIGUA (COUNTRY)",	l: "ANTIGUA AND BARBUDA" },
            { n: "ANTIKYRA",	l: "GREECE" },
            { n: "ANTILLA",	l: "CUBA" },
            { n: "ANTING",	l: "CHINA" },
            { n: "ANTIOCH",	l: "UNITED STATES" },
            { n: "ANTIVARI",	l: "MONTENEGRO" },
            { n: "ANTOFAGASTA",	l: "CHILE" },
            { n: "ANTONINA",	l: "BRAZIL" },
            { n: "ANT-RDM",	l: "BELGIUM" },
            { n: "ANTSIRANANA",	l: "MADAGASCAR" },
            { n: "ANTWERP",	l: "BELGIUM" },
            { n: "ANVERS",	l: "BELGIUM" },
            { n: "ANYER TERMINAL",	l: "INDONESIA" },
            { n: "ANZAC COVE",	l: "TURKEY" },
            { n: "ANZIO",	l: "ITALY" },
            { n: "AOKATA",	l: "JAPAN" },
            { n: "AOLA BAY",	l: "SOLOMON ISLANDS" },
            { n: "AOMORI",	l: "JAPAN" },
            { n: "AOSHAN OIL TERMINAL",	l: "CHINA" },
            { n: "APALACHICOLA",	l: "UNITED STATES" },
            { n: "APALACHICOLA END",	l: "UNITED STATES" },
            { n: "APALACHICOLA START",	l: "UNITED STATES" },
            { n: "APAPA",	l: "NIGERIA" },
            { n: "APAR BAY",	l: "INDONESIA" },
            { n: "APARRI",	l: "PHILIPPINES" },
            { n: "APENRADE",	l: "DENMARK" },
            { n: "API API",	l: "INDONESIA" },
            { n: "APIA",	l: "WESTERN SAMOA" },
            { n: "APPLEDORE",	l: "UNITED KINGDOM" },
            { n: "APRA HARBOUR",	l: "GUAM" },
            { n: "AQABA",	l: "JORDAN" },
            { n: "AQUILA TERMINAL (FPSO)",	l: "ITALY" },
            { n: "ARACAJU",	l: "BRAZIL" },
            { n: "ARACRUZ",	l: "BRAZIL" },
            { n: "ARAHOVITIKA",	l: "GREECE" },
            { n: "ARANSAS CHANNEL END",	l: "UNITED STATES" },
            { n: "ARANSAS PASS",	l: "UNITED STATES" },
            { n: "ARATU",	l: "BRAZIL" },
            { n: "ARAYA",	l: "VENEZUELA" },
            { n: "ARBATAX",	l: "ITALY" },
            { n: "ARBROATH",	l: "UNITED KINGDOM" },
            { n: "ARCACHON",	l: "FRANCE" },
            { n: "ARCHANGEL",	l: "RUSSIA" },
            { n: "ARCTIC",	l: "CANADA" },
            { n: "ARCTIC BAY, NU",	l: "CANADA" },
            { n: "ARDALSTANGEN",	l: "NORWAY" },
            { n: "ARDEA",	l: "ITALY" },
            { n: "ARDGLASS",	l: "UNITED KINGDOM" },
            { n: "ARDJUNA MARINE TERMINAL",	l: "INDONESIA" },
            { n: "ARDRISHAIG",	l: "UNITED KINGDOM" },
            { n: "ARDROSSAN (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "ARDROSSAN (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "ARECIBO",	l: "PUERTO RICO" },
            { n: "AREIA BRANCA",	l: "BRAZIL" },
            { n: "ARENDAL",	l: "NORWAY" },
            { n: "ARGENTIA",	l: "CANADA" },
            { n: "ARGENTINA (COUNTRY)",	l: "ARGENTINA" },
            { n: "ARGOSTOLI",	l: "GREECE" },
            { n: "ARGUINEGUIN",	l: "SPAIN" },
            { n: "ARICA",	l: "CHILE" },
            { n: "ARICHAT",	l: "CANADA" },
            { n: "ARINAGA",	l: "SPAIN" },
            { n: "ARKANSAS END",	l: "UNITED STATES" },
            { n: "ARKANSAS START",	l: "UNITED STATES" },
            { n: "ARKLOW",	l: "IRELAND" },
            { n: "ARLINGTON",	l: "UNITED STATES" },
            { n: "ARMACAO DE BUZIOS",	l: "BRAZIL" },
            { n: "ARNOS VALE",	l: "SAINT VINCENT AND THE GRENADINES" },
            { n: "ARRECIFE DE LANZAROTE",	l: "SPAIN" },
            { n: "ARROYO",	l: "PUERTO RICO" },
            { n: "ARROYO BARRIL",	l: "DOMINICAN REPUBLIC" },
            { n: "ARROYO COLORADO END",	l: "UNITED STATES" },
            { n: "ARROYO SECO",	l: "ARGENTINA" },
            { n: "ARUBA (COUNTRY)",	l: "ARUBA" },
            { n: "ARUBA ISLAND",	l: "ARUBA" },
            { n: "ARUN TERMINAL",	l: "INDONESIA" },
            { n: "ARVIAT, NU",	l: "CANADA" },
            { n: "ARWAD ISLAND",	l: "SYRIA" },
            { n: "ARZANAH ISLAND",	l: "UNITED ARAB EMIRATES" },
            { n: "ARZEW",	l: "ALGERIA" },
            { n: "ARZEW EL-DJEDID",	l: "ALGERIA" },
            { n: "ASAHAN",	l: "INDONESIA" },
            { n: "ASAM ASAM",	l: "INDONESIA" },
            { n: "ASCENSION ISLAND",	l: "UNITED KINGDOM" },
            { n: "ASCENSION ISLAND (COUNTRY)",	l: "SAINT HELENA" },
            { n: "ASENG FPSO",	l: "EQUATORIAL GUINEA" },
            { n: "ASGARD",	l: "NORWAY" },
            { n: "ASH SHIHR",	l: "YEMEN" },
            { n: "ASHAR",	l: "IRAQ" },
            { n: "ASHDOD",	l: "ISRAEL" },
            { n: "ASHIHE",	l: "CHINA" },
            { n: "ASHKELON",	l: "ISRAEL" },
            { n: "ASHLAND",	l: "UNITED STATES" },
            { n: "ASHTABULA",	l: "UNITED STATES" },
            { n: "ASHTART TERMINAL",	l: "TUNISIA" },
            { n: "ASIKE",	l: "INDONESIA" },
            { n: "ASLP1",	l: "ARGENTINA" },
            { n: "ASLP2",	l: "URUGUAY" },
            { n: "ASLP3",	l: "BRAZIL" },
            { n: "ASLP4",	l: "SOUTH AFRICA" },
            { n: "ASNAESVAERKETS HAVN",	l: "DENMARK" },
            { n: "ASPRIA SPITIA ORE TERMINAL",	l: "GREECE" },
            { n: "ASPROPYRGOS",	l: "GREECE" },
            { n: "ASSAB",	l: "ERITREA" },
            { n: "ASSALUYEH",	l: "IRAN" },
            { n: "ASSEMINI",	l: "ITALY" },
            { n: "ASSENS",	l: "DENMARK" },
            { n: "ASTAKOS",	l: "GREECE" },
            { n: "ASTORIA",	l: "UNITED STATES" },
            { n: "ASTRAKHAN",	l: "RUSSIA" },
            { n: "AT SEA",	l: "NO COUNTRY" },
            { n: "AT SEA GOA PASSAGE",	l: "SUDAN" },
            { n: "ATAQA",	l: "EGYPT" },
            { n: "ATCHAFALAYA END",	l: "UNITED STATES" },
            { n: "ATCHAFALAYA START",	l: "UNITED STATES" },
            { n: "ATHENS",	l: "GREECE" },
            { n: "ATHENS (USA)",	l: "UNITED STATES" },
            { n: "ATHERINOLAKKOS",	l: "GREECE" },
            { n: "ATHETINOLAKOS",	l: "GREECE" },
            { n: "ATICO",	l: "PERU" },
            { n: "ATIMONAN",	l: "PHILIPPINES" },
            { n: "ATRECO",	l: "UNITED STATES" },
            { n: "ATSUMI",	l: "JAPAN" },
            { n: "ATUCHA",	l: "ARGENTINA" },
            { n: "AU",	l: "SWITZERLAND" },
            { n: "AUCKLAND",	l: "NEW ZEALAND" },
            { n: "AUGHINISH",	l: "IRELAND" },
            { n: "AUGUSTA",	l: "ITALY" },
            { n: "AUGUSTA/PRIOLO",	l: "ITALY" },
            { n: "AUKRA",	l: "NORWAY" },
            { n: "AULD'S COVE",	l: "CANADA" },
            { n: "AULTBEA",	l: "UNITED KINGDOM" },
            { n: "AUPALUK, QC",	l: "CANADA" },
            { n: "AUSTRALIA (COUNTRY)",	l: "AUSTRALIA" },
            { n: "AUSTRALIA-NZ",	l: "AUSTRALIA" },
            { n: "AUTOPORT",	l: "TURKEY" },
            { n: "AUX CAYES",	l: "HAITI" },
            { n: "AVATIU",	l: "COOK ISLANDS" },
            { n: "AVEDORE",	l: "DENMARK" },
            { n: "AVEIRO",	l: "PORTUGAL" },
            { n: "AVERNAKKE",	l: "DENMARK" },
            { n: "AVEROY",	l: "NORWAY" },
            { n: "AVILES",	l: "SPAIN" },
            { n: "AVOLA",	l: "ITALY" },
            { n: "AVON",	l: "UNITED STATES" },
            { n: "AVONDALE",	l: "UNITED STATES" },
            { n: "AVONMOUTH",	l: "UNITED KINGDOM" },
            { n: "AXEL PLAIN",	l: "NETHERLANDS" },
            { n: "AXELSVIK",	l: "SWEDEN" },
            { n: "AXELSVIK OIL TERMINAL",	l: "SWEDEN" },
            { n: "AYIA MARINA",	l: "GREECE" },
            { n: "AYIOS NIKOLAOS",	l: "GREECE" },
            { n: "AYR",	l: "UNITED KINGDOM" },
            { n: "AYUNGON",	l: "PHILIPPINES" },
            { n: "AZERBAIJAN (COUNTRY)",	l: "AZERBAIJAN" },
            { n: "AZORES (COUNTRY)",	l: "PORTUGAL" },
            { n: "AZOV",	l: "RUSSIA" },
            { n: "AZOVSTAL",	l: "UKRAINE" },
            { n: "AZUA",	l: "DOMINICAN REPUBLIC" },
            { n: "AZURITE OFFSHORE TERMINAL",	l: "CONGO" },
            { n: "AZZAWIYA",	l: "LIBYA" },
            { n: "BA NGOI",	l: "VIETNAM" },
            { n: "BABOUILLAT",	l: "NEW CALEDONIA" },
            { n: "BACH HO TERMINAL",	l: "VIETNAM" },
            { n: "BACHAQUERO",	l: "VENEZUELA" },
            { n: "BACOLOD",	l: "PHILIPPINES" },
            { n: "BACONG",	l: "PHILIPPINES" },
            { n: "BADAS PORT",	l: "INDONESIA" },
            { n: "BADDECK",	l: "CANADA" },
            { n: "BADE",	l: "INDONESIA" },
            { n: "BADGELEY ISLAND",	l: "CANADA" },
            { n: "BADO",	l: "SWEDEN" },
            { n: "BAGAEVSKAYA",	l: "RUSSIA" },
            { n: "BAGAN LUAR",	l: "MALAYSIA" },
            { n: "BAGFAS-ISKUR FERTILIZER JETTY",	l: "TURKEY" },
            { n: "BAGNOLI",	l: "ITALY" },
            { n: "BAGONGSHAN",	l: "CHINA" },
            { n: "BAHAMAS (COUNTRY)",	l: "THE BAHAMAS" },
            { n: "BAHAMAS LZ",	l: "THE BAHAMAS" },
            { n: "BAHE",	l: "CHINA" },
            { n: "BAHIA",	l: "BRAZIL" },
            { n: "BAHIA BLANCA",	l: "ARGENTINA" },
            { n: "BAHIA DE CARAQUEZ",	l: "ECUADOR" },
            { n: "BAHIA HONDA",	l: "CUBA" },
            { n: "BAHIA LAS MINAS",	l: "PANAMA" },
            { n: "BAHODOPI ANCHORAGE, PANTOLOAN PORT",	l: "INDONESIA" },
            { n: "BAHRAIN",	l: "BAHRAIN" },
            { n: "BAHRAIN (COUNTRY)",	l: "BAHRAIN" },
            { n: "BAHRAIN OPL",	l: "BAHRAIN" },
            { n: "BAHRAIN PORT FAIRWAY BUOY",	l: "BAHRAIN" },
            { n: "BAHREGAN",	l: "IRAN" },
            { n: "BAHRGAN",	l: "IRAN" },
            { n: "BAIA",	l: "ITALY" },
            { n: "BAIE COMEAU",	l: "CANADA" },
            { n: "BAIE DE NUMBO",	l: "NEW CALEDONIA" },
            { n: "BAIE DES DAMES",	l: "NEW CALEDONIA" },
            { n: "BAIE N'GO",	l: "NEW CALEDONIA" },
            { n: "BAIE UGUE",	l: "NEW CALEDONIA" },
            { n: "BAIE VERTE",	l: "CANADA" },
            { n: "BAILIANJING",	l: "CHINA" },
            { n: "BAIS",	l: "PHILIPPINES" },
            { n: "BAISHIGANG",	l: "CHINA" },
            { n: "BAITKAL COVE",	l: "INDIA" },
            { n: "BAIYANGWAN",	l: "CHINA" },
            { n: "BAJO GRANDE REFINERY",	l: "VENEZUELA" },
            { n: "BAKAPIT",	l: "MALAYSIA" },
            { n: "BAKAR",	l: "CROATIA" },
            { n: "BAKARISTA",	l: "RUSSIA" },
            { n: "BAKER LAKE, NU",	l: "CANADA" },
            { n: "BAKU",	l: "AZERBAIJAN" },
            { n: "BALABAC STRAIT",	l: "MALAYSIA" },
            { n: "BALAKOVO",	l: "RUSSIA" },
            { n: "BALAMBAN",	l: "PHILIPPINES" },
            { n: "BALAO TERMINAL",	l: "ECUADOR" },
            { n: "BALBOA",	l: "PANAMA" },
            { n: "BALCHIK",	l: "BULGARIA" },
            { n: "BALHAF",	l: "YEMEN" },
            { n: "BALHAF LNG TERMINAL",	l: "YEMEN" },
            { n: "BALIKPAPAN",	l: "INDONESIA" },
            { n: "BALINGASAG",	l: "PHILIPPINES" },
            { n: "BALLANGEN",	l: "NORWAY" },
            { n: "BALLAST HEAD",	l: "AUSTRALIA" },
            { n: "BALLAST POINT",	l: "AUSTRALIA" },
            { n: "BALLINA",	l: "IRELAND" },
            { n: "BALL'S HEAD",	l: "AUSTRALIA" },
            { n: "BALLYLUMFORD",	l: "UNITED KINGDOM" },
            { n: "BALMAIN",	l: "AUSTRALIA" },
            { n: "BALNAVES FPSO",	l: "AUSTRALIA" },
            { n: "BALONGAN",	l: "INDONESIA" },
            { n: "BALTIC",	l: "DENMARK" },
            { n: "BALTICPORT",	l: "ESTONIA" },
            { n: "BALTIISK",	l: "RUSSIA" },
            { n: "BALTIMORE (IRELAND)",	l: "IRELAND" },
            { n: "BALTIMORE (USA)",	l: "UNITED STATES" },
            { n: "BALTIYSK",	l: "RUSSIA" },
            { n: "BAMBERTON",	l: "CANADA" },
            { n: "BANABA",	l: "KIRIBATI" },
            { n: "BANAGO",	l: "PHILIPPINES" },
            { n: "BANANA",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "BANANG MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "BANCO BAY",	l: "COTE D'IVOIRE" },
            { n: "BANDA ACEH",	l: "INDONESIA" },
            { n: "BANDAR ABBAS",	l: "IRAN" },
            { n: "BANDAR ANZALI",	l: "IRAN" },
            { n: "BANDAR ASSALUYEH",	l: "IRAN" },
            { n: "BANDAR IMAM KHOMEINI",	l: "IRAN" },
            { n: "BANDAR MAHSHAHR",	l: "IRAN" },
            { n: "BANDAR MELAKA STS ANCHORAGE",	l: "MALAYSIA" },
            { n: "BANDAR NOSHAHR",	l: "IRAN" },
            { n: "BANDAR SHAHID BAHONAR",	l: "IRAN" },
            { n: "BANDAR SHAHID REJAIE",	l: "IRAN" },
            { n: "BANDAR TAHERI",	l: "IRAN" },
            { n: "BANDHOLM",	l: "DENMARK" },
            { n: "BANDIRMA",	l: "TURKEY" },
            { n: "BANES",	l: "CUBA" },
            { n: "BANFF",	l: "UNITED KINGDOM" },
            { n: "BANG HUA SUA",	l: "THAILAND" },
            { n: "BANG SAPHAN",	l: "THAILAND" },
            { n: "BANGGI SOUTH CHANNEL",	l: "MALAYSIA" },
            { n: "BANGKA MARINE TERMINAL",	l: "INDONESIA" },
            { n: "BANGKOK",	l: "THAILAND" },
            { n: "BANGLADESH (COUNTRY)",	l: "BANGLADESH" },
            { n: "BANGOR (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "BANGOR (USA)",	l: "UNITED STATES" },
            { n: "BANIAS",	l: "SYRIA" },
            { n: "BANJARMASIN",	l: "INDONESIA" },
            { n: "BANJUL",	l: "THE GAMBIA" },
            { n: "BANTEN",	l: "INDONESIA" },
            { n: "BANTRY",	l: "IRELAND" },
            { n: "BANTRY BAY",	l: "IRELAND" },
            { n: "BANYAN BASIN",	l: "SINGAPORE" },
            { n: "BANYU URIP MARINE TERMINAL",	l: "INDONESIA" },
            { n: "BANYUWANGI - MENENG",	l: "INDONESIA" },
            { n: "BAOBAB TERMINAL",	l: "COTE D'IVOIRE" },
            { n: "BAOSHAN",	l: "CHINA" },
            { n: "BAOZHEN",	l: "CHINA" },
            { n: "BAR",	l: "MONTENEGRO" },
            { n: "BAR HARBOR",	l: "UNITED STATES" },
            { n: "BARACOA",	l: "CUBA" },
            { n: "BARAHONA",	l: "DOMINICAN REPUBLIC" },
            { n: "BARAO DE TEFFE",	l: "BRAZIL" },
            { n: "BARBADOS",	l: "BARBADOS" },
            { n: "BARBADOS (COUNTRY)",	l: "BARBADOS" },
            { n: "BARBER'S POINT HARBOR",	l: "UNITED STATES" },
            { n: "BARBER'S POINT TERMINAL - CHEVRON",	l: "UNITED STATES" },
            { n: "BARBER'S POINT TERMINAL - TESORO HAWAII",	l: "UNITED STATES" },
            { n: "BARBOURS CUT",	l: "UNITED STATES" },
            { n: "BARBOURS CUT END",	l: "UNITED STATES" },
            { n: "BARCADERA",	l: "ARUBA" },
            { n: "BARCARENA",	l: "BRAZIL" },
            { n: "BARCELONA",	l: "SPAIN" },
            { n: "BARCELONA-HBG",	l: "GIBRALTAR" },
            { n: "BARD OFFSHORE 1",	l: "NETHERLANDS" },
            { n: "BARENTSBURG",	l: "NORWAY" },
            { n: "BARI",	l: "ITALY" },
            { n: "BARIA SERECE",	l: "VIETNAM" },
            { n: "BARKAN",	l: "IRAN" },
            { n: "BARLETTA",	l: "ITALY" },
            { n: "BARMOUTH",	l: "UNITED KINGDOM" },
            { n: "BARNABE ISLAND TERMINAL",	l: "BRAZIL" },
            { n: "BARNEY POINT",	l: "AUSTRALIA" },
            { n: "BARQUITO",	l: "CHILE" },
            { n: "BARRA",	l: "UNITED KINGDOM" },
            { n: "BARRA DO RIACHO",	l: "BRAZIL" },
            { n: "BARRA DOS COQUEIROS",	l: "BRAZIL" },
            { n: "BARRANQUILLA",	l: "COLOMBIA" },
            { n: "BARREIRO",	l: "PORTUGAL" },
            { n: "BARROW ISLAND",	l: "AUSTRALIA" },
            { n: "BARROW-IN-FURNESS",	l: "UNITED KINGDOM" },
            { n: "BARRY",	l: "UNITED KINGDOM" },
            { n: "BARRY BEACH MARINE TERMINAL",	l: "AUSTRALIA" },
            { n: "BARRY DOCK",	l: "UNITED KINGDOM" },
            { n: "BARRY ISLAND",	l: "UNITED KINGDOM" },
            { n: "BARTIN",	l: "TURKEY" },
            { n: "BARTON",	l: "UNITED KINGDOM" },
            { n: "BARTON (HUMBER)",	l: "UNITED KINGDOM" },
            { n: "BARTON (MERSEY)",	l: "UNITED KINGDOM" },
            { n: "BASAMUK",	l: "PAPUA NEW GUINEA" },
            { n: "BASARABI",	l: "ROMANIA" },
            { n: "BASCO",	l: "PHILIPPINES" },
            { n: "BASILAN STRAIT",	l: "PHILIPPINES" },
            { n: "BASLE",	l: "SWITZERLAND" },
            { n: "BASRAH",	l: "IRAQ" },
            { n: "BASSE INDRE",	l: "FRANCE" },
            { n: "BASSEIN",	l: "MYANMAR" },
            { n: "BASSENS",	l: "FRANCE" },
            { n: "BASSETERRE",	l: "SAINT KITTS AND NEVIS" },
            { n: "BASSE-TERRE",	l: "GUADELOUPE" },
            { n: "BASTIA",	l: "FRANCE" },
            { n: "BASUO",	l: "CHINA" },
            { n: "BATA",	l: "EQUATORIAL GUINEA" },
            { n: "BATAAN",	l: "PHILIPPINES" },
            { n: "BATAM",	l: "INDONESIA" },
            { n: "BATAM ISLAND",	l: "INDONESIA" },
            { n: "BATANGAS",	l: "PHILIPPINES" },
            { n: "BATH (CANADA)",	l: "CANADA" },
            { n: "BATH (USA)",	l: "UNITED STATES" },
            { n: "BATHURST",	l: "CANADA" },
            { n: "BATO",	l: "PHILIPPINES" },
            { n: "BATON ROUGE",	l: "UNITED STATES" },
            { n: "BATSFJORD",	l: "NORWAY" },
            { n: "BATU AMPAR",	l: "INDONESIA" },
            { n: "BATU PAHAT OFFSHORE",	l: "MALAYSIA" },
            { n: "BATUMI",	l: "GEORGIA" },
            { n: "BAU-BAU",	l: "INDONESIA" },
            { n: "BAUXILUM",	l: "VENEZUELA" },
            { n: "BAY BULLS",	l: "CANADA" },
            { n: "BAY CITY",	l: "UNITED STATES" },
            { n: "BAY CITY, TEXAS",	l: "UNITED STATES" },
            { n: "BAY OF ISLANDS",	l: "NEW ZEALAND" },
            { n: "BAY ROBERTS",	l: "CANADA" },
            { n: "BAYAH",	l: "INDONESIA" },
            { n: "BAYAWAN",	l: "PHILIPPINES" },
            { n: "BAYONNE (FRANCE)",	l: "FRANCE" },
            { n: "BAYONNE (USA)",	l: "UNITED STATES" },
            { n: "BAYOU CASOTTE END",	l: "UNITED STATES" },
            { n: "BAYOU COSOTTE",	l: "UNITED STATES" },
            { n: "BAYOVAR",	l: "PERU" },
            { n: "BAYPORT",	l: "UNITED STATES" },
            { n: "BAYPORT CHANNEL END",	l: "UNITED STATES" },
            { n: "BAYSIDE",	l: "CANADA" },
            { n: "BAYTOWN",	l: "UNITED STATES" },
            { n: "BAYU UNDAN",	l: "AUSTRALIA" },
            { n: "BAYUQUAN",	l: "CHINA" },
            { n: "BAYWAY",	l: "UNITED STATES" },
            { n: "BEAR HEAD",	l: "CANADA" },
            { n: "BEAUFORT NC",	l: "UNITED STATES" },
            { n: "BEAUFORT SC",	l: "UNITED STATES" },
            { n: "BEAUMONT",	l: "UNITED STATES" },
            { n: "BEAUTY POINT HARBOUR",	l: "AUSTRALIA" },
            { n: "BEAVER HARBOUR",	l: "CANADA" },
            { n: "BEC D' AMBES",	l: "FRANCE" },
            { n: "BECANCOUR",	l: "CANADA" },
            { n: "BEDI BUNDER",	l: "INDIA" },
            { n: "BEIDA",	l: "CHINA" },
            { n: "BEIGANG",	l: "CHINA" },
            { n: "BEIHAI",	l: "CHINA" },
            { n: "BEIJIN",	l: "CHINA" },
            { n: "BEILUN",	l: "CHINA" },
            { n: "BEIMEN",	l: "CHINA" },
            { n: "BEIPEI",	l: "CHINA" },
            { n: "BEIRA",	l: "MOZAMBIQUE" },
            { n: "BEIRUT",	l: "LEBANON" },
            { n: "BEJAIA",	l: "ALGERIA" },
            { n: "BEKAPAI TERMINAL",	l: "INDONESIA" },
            { n: "BELANAK TERMINAL",	l: "INDONESIA" },
            { n: "BELAWAN",	l: "INDONESIA" },
            { n: "BELEKERI",	l: "INDIA" },
            { n: "BELEM",	l: "BRAZIL" },
            { n: "BELFAST",	l: "UNITED KINGDOM" },
            { n: "BELG-HOLL",	l: "BELGIUM" },
            { n: "BELGIUM (COUNTRY)",	l: "BELGIUM" },
            { n: "BELGOROD-DNESTROVSKIY",	l: "UKRAINE" },
            { n: "BELHAVEN NC",	l: "UNITED STATES" },
            { n: "BELIDA MARINE TERMINAL",	l: "INDONESIA" },
            { n: "BELIZE (COUNTRY)",	l: "BELIZE" },
            { n: "BELIZE CITY",	l: "BELIZE" },
            { n: "BELL BAY",	l: "AUSTRALIA" },
            { n: "BELLA COOLA",	l: "CANADA" },
            { n: "BELLE CHASSE",	l: "UNITED STATES" },
            { n: "BELLEDUNE",	l: "CANADA" },
            { n: "BELLEFONTAINE JETTY",	l: "MARTINIQUE" },
            { n: "BELLEORAM",	l: "CANADA" },
            { n: "BELLINGHAM",	l: "UNITED STATES" },
            { n: "BEN NGHE",	l: "VIETNAM" },
            { n: "BEN THUY",	l: "VIETNAM" },
            { n: "BENCHAMAS TERMINAL",	l: "THAILAND" },
            { n: "BENETE BAY",	l: "INDONESIA" },
            { n: "BENGBU",	l: "CHINA" },
            { n: "BENGHAZI",	l: "LIBYA" },
            { n: "BENGKALIS",	l: "INDONESIA" },
            { n: "BENGKULU",	l: "INDONESIA" },
            { n: "BENI SAF",	l: "ALGERIA" },
            { n: "BENICIA",	l: "UNITED STATES" },
            { n: "BENIN (COUNTRY)",	l: "BENIN" },
            { n: "BENITO",	l: "EQUATORIAL GUINEA" },
            { n: "BENOA",	l: "INDONESIA" },
            { n: "BENTENG",	l: "INDONESIA" },
            { n: "BERANTAI MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "BERAU BAY",	l: "INDONESIA" },
            { n: "BERBERA",	l: "SOMALIA" },
            { n: "BERDYANSK",	l: "UKRAINE" },
            { n: "BEREHAVEN",	l: "IRELAND" },
            { n: "BERG",	l: "NORWAY" },
            { n: "BERGEN",	l: "NORWAY" },
            { n: "BERINGEN",	l: "BELGIUM" },
            { n: "BERINGOVSKY",	l: "RUSSIA" },
            { n: "BERKELEY",	l: "UNITED STATES" },
            { n: "BERLEVAG",	l: "NORWAY" },
            { n: "BERMEO",	l: "SPAIN" },
            { n: "BERMUDA (COUNTRY)",	l: "BERMUDA" },
            { n: "BERONG",	l: "PHILIPPINES" },
            { n: "BERRE",	l: "FRANCE" },
            { n: "BERRYPOINT",	l: "CANADA" },
            { n: "BERRY'S BAY",	l: "AUSTRALIA" },
            { n: "BERTAM WELL HEAD PLATFORM",	l: "MALAYSIA" },
            { n: "BERVILLE SUR MER",	l: "FRANCE" },
            { n: "BERWICK UPON TWEED",	l: "UNITED KINGDOM" },
            { n: "BESIKTAS",	l: "TURKEY" },
            { n: "BETHEL",	l: "UNITED STATES" },
            { n: "BETHIOUA",	l: "ALGERIA" },
            { n: "BETIO",	l: "KIRIBATI" },
            { n: "BEVERWIJK",	l: "NETHERLANDS" },
            { n: "BEYPORE",	l: "INDIA" },
            { n: "BEYROUT",	l: "LEBANON" },
            { n: "BHAVNAGAR",	l: "INDIA" },
            { n: "BHEEMUNIPATNAM",	l: "INDIA" },
            { n: "BIAK",	l: "INDONESIA" },
            { n: "BIALLA",	l: "PAPUA NEW GUINEA" },
            { n: "BIARRITZ",	l: "FRANCE" },
            { n: "BIAWAK",	l: "MALAYSIA" },
            { n: "BIDEFORD",	l: "UNITED KINGDOM" },
            { n: "BIEN DONG TERMINAL",	l: "VIETNAM" },
            { n: "BIG BEND",	l: "UNITED STATES" },
            { n: "BIG CREEK",	l: "BELIZE" },
            { n: "BIG SANDY END",	l: "UNITED STATES" },
            { n: "BIG SANDY START",	l: "UNITED STATES" },
            { n: "BIG STONE ANCHORAGE",	l: "UNITED STATES" },
            { n: "BIG TROUT",	l: "UNITED STATES" },
            { n: "BIJELA",	l: "MONTENEGRO" },
            { n: "BIK ANCHORAGE",	l: "IRAN" },
            { n: "BILBAO",	l: "SPAIN" },
            { n: "BIMA",	l: "INDONESIA" },
            { n: "BIMA TERMINAL",	l: "INDONESIA" },
            { n: "BIMINI",	l: "BAHAMAS" },
            { n: "BIMLIPATAM",	l: "INDIA" },
            { n: "BIN QASIM",	l: "PAKISTAN" },
            { n: "BING BONG",	l: "AUSTRALIA" },
            { n: "BINH THUAN",	l: "VIETNAM" },
            { n: "BINHAI",	l: "CHINA" },
            { n: "BINTAN ISLAND",	l: "INDONESIA" },
            { n: "BINTAN ISLAND NE",	l: "INDONESIA" },
            { n: "BINTAN STRAITS",	l: "INDONESIA" },
            { n: "BINTANGOR",	l: "MALAYSIA" },
            { n: "BINTULU",	l: "MALAYSIA" },
            { n: "BINTULU TERMINAL",	l: "MALAYSIA" },
            { n: "BINTUNI",	l: "INDONESIA" },
            { n: "BIOKO ISLAND",	l: "GUINEA" },
            { n: "BIRD CAY",	l: "THE BAHAMAS" },
            { n: "BIRD PORT",	l: "UNITED KINGDOM" },
            { n: "BIRINGKASI",	l: "INDONESIA" },
            { n: "BIRKENHEAD",	l: "UNITED KINGDOM" },
            { n: "BIRSFELDEN",	l: "SWITZERLAND" },
            { n: "BISHOP ROCK",	l: "UNITED KINGDOM" },
            { n: "BISLIG",	l: "PHILIPPINES" },
            { n: "BISSAU",	l: "GUINEA-BISSAU" },
            { n: "BITUNG",	l: "INDONESIA" },
            { n: "BIZERTE",	l: "TUNISIA" },
            { n: "BJORNEBORG",	l: "FINLAND" },
            { n: "BLACK RIVER",	l: "JAMAICA" },
            { n: "BLACK ROCK HARBOR",	l: "UNITED STATES" },
            { n: "BLACK ROCK TANKER TERMINAL",	l: "BARBADOS" },
            { n: "BLACK SEA",	l: "UKRAINE" },
            { n: "BLACK WARRIOR RIVER END",	l: "UNITED STATES" },
            { n: "BLACKTIP TERMINAL",	l: "AUSTRALIA" },
            { n: "BLACKWATTLE BAY",	l: "AUSTRALIA" },
            { n: "BLANC-SABLON",	l: "CANADA" },
            { n: "BLANG LANCANG",	l: "INDONESIA" },
            { n: "BLAYE",	l: "FRANCE" },
            { n: "BLEXEN",	l: "GERMANY" },
            { n: "BLIND RIVER",	l: "CANADA" },
            { n: "BLOUNT ISLAND",	l: "UNITED STATES" },
            { n: "BLUEFIELDS",	l: "NICARAGUA" },
            { n: "BLUFF",	l: "NEW ZEALAND" },
            { n: "BLYTH",	l: "UNITED KINGDOM" },
            { n: "BOCA CHICA",	l: "DOMINICAN REPUBLIC" },
            { n: "BOCA DA VALERIA",	l: "BRAZIL" },
            { n: "BOCA DEL NAPO",	l: "PERU" },
            { n: "BOCA GRANDE (CUBA)",	l: "CUBA" },
            { n: "BOCA GRANDE (USA)",	l: "UNITED STATES" },
            { n: "BOCA GRANDE TRANSFER STATION",	l: "VENEZUELA" },
            { n: "BOCAS DEL TORO",	l: "PANAMA" },
            { n: "BOCATERMICA",	l: "PANAMA" },
            { n: "BODO",	l: "NORWAY" },
            { n: "BODRUM",	l: "TURKEY" },
            { n: "BOGEN",	l: "NORWAY" },
            { n: "BOGGY BAYOU",	l: "UNITED STATES" },
            { n: "BOHAI PL-19-3 TERMINAL",	l: "CHINA" },
            { n: "BOJONEGARA",	l: "INDONESIA" },
            { n: "BOLAMA",	l: "GUINEA-BISSAU" },
            { n: "BOLIVAR ROADS",	l: "UNITED STATES" },
            { n: "BOLLSTA",	l: "SWEDEN" },
            { n: "BOMA",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "BOMBAY",	l: "INDIA" },
            { n: "BOMLO",	l: "NORWAY" },
            { n: "BONABERI",	l: "CAMEROON" },
            { n: "BONAIRE TERMINAL",	l: "BONAIRE" },
            { n: "BONE",	l: "ALGERIA" },
            { n: "BO'NESS",	l: "UNITED KINGDOM" },
            { n: "BONGA OFFSHORE TERMINAL",	l: "NIGERIA" },
            { n: "BONGKOT TERMINAL",	l: "THAILAND" },
            { n: "BONIFACIO",	l: "FRANCE" },
            { n: "BONIFACIO STRAIT",	l: "FRANCE" },
            { n: "BONNIERES SUR SEINE",	l: "FRANCE" },
            { n: "BONNY",	l: "NIGERIA" },
            { n: "BONNY INSHORE TERMINAL",	l: "NIGERIA" },
            { n: "BONNY OFFSHORE TERMINAL",	l: "NIGERIA" },
            { n: "BONOIL",	l: "BONAIRE" },
            { n: "BONTANG",	l: "INDONESIA" },
            { n: "BOPEC TERMINAL",	l: "BONAIRE" },
            { n: "BORA-BORA",	l: "FRENCH POLYNESIA" },
            { n: "BORBURATA",	l: "VENEZUELA" },
            { n: "BORDEAUX",	l: "FRANCE" },
            { n: "BORD-HBG",	l: "FRANCE" },
            { n: "BORD-RDM",	l: "FRANCE" },
            { n: "BORG HAVNEVESEN",	l: "NORWAY" },
            { n: "BORGA",	l: "FINLAND" },
            { n: "BORGHOLM",	l: "SWEDEN" },
            { n: "BORJ ISLAM",	l: "SYRIA" },
            { n: "BORNEO STRAIT",	l: "INDONESIA" },
            { n: "BORONGAN",	l: "PHILIPPINES" },
            { n: "BORSELE",	l: "NETHERLANDS" },
            { n: "BORUSAN",	l: "TURKEY" },
            { n: "BORYEONG",	l: "KOREA, REPUBLIC" },
            { n: "BOSASO",	l: "SOMALIA" },
            { n: "BOSPORUS",	l: "TURKEY" },
            { n: "BOSPORUS & DARDANELLES STRAITS",	l: "TURKEY" },
            { n: "BOSTON (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "BOSTON (USA)",	l: "UNITED STATES" },
            { n: "BOSTON BAY",	l: "AUSTRALIA" },
            { n: "BOSTON-B.A.",	l: "UNITED STATES" },
            { n: "BOSTON-GALVESTON",	l: "UNITED STATES" },
            { n: "BOTANG",	l: "INDONESIA" },
            { n: "BOTANY BAY",	l: "AUSTRALIA" },
            { n: "BOTAS (CEYHAN) OIL TERMINAL",	l: "TURKEY" },
            { n: "BOTAS NATURAL GAS TERMINAL",	l: "TURKEY" },
            { n: "BOTLEK",	l: "NETHERLANDS" },
            { n: "BOTOLAN ZAMBALES",	l: "PHILIPPINES" },
            { n: "BOTWOOD",	l: "CANADA" },
            { n: "BOUL-HBG",	l: "FRANCE" },
            { n: "BOULOGNE-SUR-MER",	l: "FRANCE" },
            { n: "BOURBON BEN LUC",	l: "VIETNAM" },
            { n: "BOURGAS",	l: "BULGARIA" },
            { n: "BOURI",	l: "LIBYA" },
            { n: "BOURNE",	l: "UNITED STATES" },
            { n: "BOWEN",	l: "AUSTRALIA" },
            { n: "BOWLING",	l: "UNITED KINGDOM" },
            { n: "BOWMANVILLE",	l: "CANADA" },
            { n: "BP ANDREW FIELD",	l: "UNITED KINGDOM" },
            { n: "BRAAKMAN HARBOUR",	l: "NETHERLANDS" },
            { n: "BRABAZOS",	l: "UNITED STATES" },
            { n: "BRAEFOOT BAY",	l: "UNITED KINGDOM" },
            { n: "BRAHESTAD",	l: "FINLAND" },
            { n: "BRAILA",	l: "ROMANIA" },
            { n: "BRAITHWAITE",	l: "UNITED STATES" },
            { n: "BRAKE",	l: "GERMANY" },
            { n: "BRANI TERMINAL",	l: "SINGAPORE" },
            { n: "BRASIL (BONAIRE)",	l: "BONAIRE" },
            { n: "BRASS TERMINAL",	l: "NIGERIA" },
            { n: "BRATTVAAG",	l: "NORWAY" },
            { n: "BRAVIKEN",	l: "SWEDEN" },
            { n: "BRAVO ZONE",	l: "ARGENTINA" },
            { n: "BRAYE",	l: "CHANNEL ISLANDS" },
            { n: "BRAYTON POINT",	l: "UNITED STATES" },
            { n: "BRAZIL (COUNTRY)",	l: "BRAZIL" },
            { n: "BRAZOS SANTIAGO ENTRANCE",	l: "UNITED STATES" },
            { n: "BREDCO",	l: "PHILIPPINES" },
            { n: "BREIVIK",	l: "NORWAY" },
            { n: "BREIVIKA",	l: "NORWAY" },
            { n: "BREKSTAD",	l: "NORWAY" },
            { n: "BREMANGER",	l: "NORWAY" },
            { n: "BREMEN",	l: "GERMANY" },
            { n: "BREMERHAVEN",	l: "GERMANY" },
            { n: "BREMERTON",	l: "UNITED STATES" },
            { n: "BRESKENS",	l: "NETHERLANDS" },
            { n: "BREST",	l: "FRANCE" },
            { n: "BREVES",	l: "BRAZIL" },
            { n: "BREVIK",	l: "NORWAY" },
            { n: "BREVORT",	l: "UNITED STATES" },
            { n: "BRIDGEPORT (ALABAMA)",	l: "UNITED STATES" },
            { n: "BRIDGEPORT (CT)",	l: "UNITED STATES" },
            { n: "BRIDGETOWN",	l: "BARBADOS" },
            { n: "BRIDGEWATER",	l: "CANADA" },
            { n: "BRIDGWATER",	l: "UNITED KINGDOM" },
            { n: "BRIDLINGTON",	l: "UNITED KINGDOM" },
            { n: "BRIDPORT",	l: "UNITED KINGDOM" },
            { n: "BRIGHTLINGSEA",	l: "UNITED KINGDOM" },
            { n: "BRIGHTON",	l: "TRINIDAD AND TOBAGO" },
            { n: "BRINDISI",	l: "ITALY" },
            { n: "BRISBANE",	l: "AUSTRALIA" },
            { n: "BRISTOL",	l: "UNITED KINGDOM" },
            { n: "BRISTOL HARBOR",	l: "UNITED STATES" },
            { n: "BRISTOL PA",	l: "UNITED STATES" },
            { n: "BRITISH INDIAN OCEAN TERRITORY (COUNTRY)",	l: "BRIT. IND" },
            { n: "BRITT",	l: "CANADA" },
            { n: "BRIXHAM",	l: "UNITED KINGDOM" },
            { n: "BRODICK HARBOUR",	l: "UNITED KINGDOM" },
            { n: "BROFJORDEN",	l: "SWEDEN" },
            { n: "BROMBOROUGH",	l: "UNITED KINGDOM" },
            { n: "BRONNOYSUND",	l: "NORWAY" },
            { n: "BRONTE",	l: "CANADA" },
            { n: "BROOKLYN (CANADA)",	l: "CANADA" },
            { n: "BROOKLYN (UNITED STATES)",	l: "UNITED STATES" },
            { n: "BROOME",	l: "AUSTRALIA" },
            { n: "BROUWERSHAVEN",	l: "NETHERLANDS" },
            { n: "BROWNSVILLE",	l: "UNITED STATES" },
            { n: "BRUCE MINES",	l: "CANADA" },
            { n: "BRUGES",	l: "BELGIUM" },
            { n: "BRUNEI (COUNTRY)",	l: "BRUNEI" },
            { n: "BRUNEI DARUSSALAM (COUNTRY)",	l: "BRUNEI" },
            { n: "BRUNEI LNG",	l: "BRUNEI" },
            { n: "BRUNSBUTTEL",	l: "GERMANY" },
            { n: "BRUNSWICK",	l: "UNITED STATES" },
            { n: "BRUNSWICK GA",	l: "UNITED STATES" },
            { n: "BRUSSELS",	l: "BELGIUM" },
            { n: "BUALUANG TERMINAL",	l: "THAILAND" },
            { n: "BUATAN",	l: "INDONESIA" },
            { n: "BUCCARI",	l: "CROATIA" },
            { n: "BUCHANAN",	l: "LIBERIA" },
            { n: "BUCHANAN (USA)",	l: "UNITED STATES" },
            { n: "BUCKIE",	l: "UNITED KINGDOM" },
            { n: "BUCKNER BAY",	l: "JAPAN" },
            { n: "BUCKSPORT",	l: "UNITED STATES" },
            { n: "BUCKSPORT SC",	l: "UNITED STATES" },
            { n: "BUCTOUCHE HARBOUR",	l: "CANADA" },
            { n: "BUDE",	l: "UNITED KINGDOM" },
            { n: "BUDGE BUDGE",	l: "INDIA" },
            { n: "BUENAVENTURA",	l: "COLOMBIA" },
            { n: "BUENOS AIRES",	l: "ARGENTINA" },
            { n: "BUFFALO",	l: "UNITED STATES" },
            { n: "BUFFINGTON",	l: "UNITED STATES" },
            { n: "BUGPYONG",	l: "KOREA, REPUBLIC" },
            { n: "BUITAGRO",	l: "ARGENTINA" },
            { n: "BUKA",	l: "PAPUA NEW GUINEA" },
            { n: "BUKIT TUA MARINE TERMINAL",	l: "INDONESIA" },
            { n: "BUKPYUNG",	l: "KOREA, REPUBLIC" },
            { n: "BULA",	l: "INDONESIA" },
            { n: "BULGARIA (COUNTRY)",	l: "BULGARIA" },
            { n: "BULI",	l: "INDONESIA" },
            { n: "BULKHAMNEN",	l: "SWEDEN" },
            { n: "BULL ARM",	l: "CANADA" },
            { n: "BULLEN BAY",	l: "CURACAO" },
            { n: "BUMPYO",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "BUNATI",	l: "INDONESIA" },
            { n: "BUNBURY",	l: "AUSTRALIA" },
            { n: "BUNDABERG",	l: "AUSTRALIA" },
            { n: "BUNGA KEKWA",	l: "MALAYSIA" },
            { n: "BUNGA ORKID MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "BUNGA RAYA",	l: "MALAYSIA" },
            { n: "BUNYU",	l: "INDONESIA" },
            { n: "BURAN CHANNEL",	l: "SINGAPORE" },
            { n: "BURELA",	l: "SPAIN" },
            { n: "BURGEO",	l: "CANADA" },
            { n: "BURGHEAD",	l: "UNITED KINGDOM" },
            { n: "BURLINGTON",	l: "UNITED STATES" },
            { n: "BURLINGTON (NEW JERSEY)",	l: "UNITED STATES" },
            { n: "BURLINGTON BAY",	l: "CANADA" },
            { n: "BURNIE",	l: "AUSTRALIA" },
            { n: "BURNS HARBOR",	l: "UNITED STATES" },
            { n: "BURNSIDE",	l: "UNITED STATES" },
            { n: "BURNTISLAND",	l: "UNITED KINGDOM" },
            { n: "BURRAY",	l: "UNITED KINGDOM" },
            { n: "BURRIANA",	l: "SPAIN" },
            { n: "BURTON UPON STATHER",	l: "UNITED KINGDOM" },
            { n: "BURTONPORT",	l: "IRELAND" },
            { n: "BURU STRAIT",	l: "INDONESIA" },
            { n: "BURULLUS FISHING PORT",	l: "EGYPT" },
            { n: "BURUTU",	l: "NIGERIA" },
            { n: "BUSAN",	l: "KOREA, REPUBLIC" },
            { n: "BUSHEHR",	l: "IRAN" },
            { n: "BUSHIRE",	l: "IRAN" },
            { n: "BUSSELTON",	l: "AUSTRALIA" },
            { n: "BUSUM",	l: "GERMANY" },
            { n: "BUTADIENE DOCK ISLAND",	l: "BRAZIL" },
            { n: "BUTINGE MARINE TERMINAL",	l: "LITHUANIA" },
            { n: "BUTTERWORTH",	l: "MALAYSIA" },
            { n: "BUTUAN",	l: "PHILIPPINES" },
            { n: "BUTZFLETH",	l: "GERMANY" },
            { n: "BUZIOS",	l: "BRAZIL" },
            { n: "BZ 28 TERMINAL",	l: "CHINA" },
            { n: "BZ 34 TERMINAL",	l: "CHINA" },
            { n: "C AND D CANAL",	l: "UNITED STATES" },
            { n: "C AND D CANAL EAST",	l: "UNITED STATES" },
            { n: "C AND D CANAL WEST",	l: "UNITED STATES" },
            { n: "C CHRISTI LIGHT",	l: "UNITED STATES" },
            { n: "C CHRISTI LIGHT2",	l: "UNITED STATES" },
            { n: "CABANAS",	l: "CUBA" },
            { n: "CABEDELO",	l: "BRAZIL" },
            { n: "CABIMAS",	l: "VENEZUELA" },
            { n: "CABINDA",	l: "ANGOLA" },
            { n: "CABO BLANCO",	l: "PERU" },
            { n: "CABO FRIO",	l: "BRAZIL" },
            { n: "CABO NEGRO",	l: "CHILE" },
            { n: "CABO ROJO",	l: "DOMINICAN REPUBLIC" },
            { n: "CABO SAN ANTONIO",	l: "ARGENTINA" },
            { n: "CABO SAN LUCAS",	l: "MEXICO" },
            { n: "CABOT STRAIT",	l: "CANADA" },
            { n: "CABULI ISLAND",	l: "PHILIPPINES" },
            { n: "CADIZ BAY",	l: "SPAIN" },
            { n: "CAEN",	l: "FRANCE" },
            { n: "CAERNARFON",	l: "UNITED KINGDOM" },
            { n: "CAGAYAN DE ORO",	l: "PHILIPPINES" },
            { n: "CAGDIANAO",	l: "PHILIPPINES" },
            { n: "CAGLIARI",	l: "ITALY" },
            { n: "CAHIRSIVEEN",	l: "IRELAND" },
            { n: "CAI LAN",	l: "VIETNAM" },
            { n: "CAI MEP",	l: "VIETNAM" },
            { n: "CAIBARIEN",	l: "CUBA" },
            { n: "CAICOS PASSAGE",	l: "TURKS AND CAICOS ISLANDS" },
            { n: "CAIRNRYAN",	l: "UNITED KINGDOM" },
            { n: "CAIRNS",	l: "AUSTRALIA" },
            { n: "CAISHI",	l: "CHINA" },
            { n: "CAKERAWALA TERMINAL",	l: "MALAYSIA" },
            { n: "CALA FIGUERA",	l: "SPAIN" },
            { n: "CALA SABINA",	l: "SPAIN" },
            { n: "CALABAR",	l: "NIGERIA" },
            { n: "CALACA",	l: "PHILIPPINES" },
            { n: "CALAFAT",	l: "ROMANIA" },
            { n: "CALAIS",	l: "FRANCE" },
            { n: "CALAPAN",	l: "PHILIPPINES" },
            { n: "CALARASI",	l: "ROMANIA" },
            { n: "CALBAYOG",	l: "PHILIPPINES" },
            { n: "CALBUCO",	l: "CHILE" },
            { n: "CALCASIEU CHANNEL",	l: "UNITED STATES" },
            { n: "CALCASIEU RIVER END",	l: "UNITED STATES" },
            { n: "CALCIRE",	l: "UNITED STATES" },
            { n: "CALCITE",	l: "UNITED STATES" },
            { n: "CALCUTTA",	l: "INDIA" },
            { n: "CALDERA (CHILE)",	l: "CHILE" },
            { n: "CALDERA (COSTA RICA)",	l: "COSTA RICA" },
            { n: "CALEDONIAN CANAL",	l: "UNITED KINGDOM" },
            { n: "CALETA BARQUITO",	l: "CHILE" },
            { n: "CALETA CLARENCIA",	l: "CHILE" },
            { n: "CALETA COLOSO",	l: "CHILE" },
            { n: "CALETA CORDOVA",	l: "ARGENTINA" },
            { n: "CALETA MICHILLA",	l: "CHILE" },
            { n: "CALETA OLIVARES",	l: "ARGENTINA" },
            { n: "CALETA OLIVIA",	l: "ARGENTINA" },
            { n: "CALETA OSSA",	l: "CHILE" },
            { n: "CALETA PATILLOS",	l: "CHILE" },
            { n: "CALICA",	l: "MEXICO" },
            { n: "CALICUT",	l: "INDIA" },
            { n: "CALINGAPATNAM",	l: "INDIA" },
            { n: "CALLAO",	l: "PERU" },
            { n: "CALOUNDRA HEAD",	l: "AUSTRALIA" },
            { n: "CALUMET",	l: "UNITED STATES" },
            { n: "CALVI",	l: "FRANCE" },
            { n: "CAM PHA",	l: "VIETNAM" },
            { n: "CAM RANH",	l: "VIETNAM" },
            { n: "CAMAR MARINE TERMINAL",	l: "INDONESIA" },
            { n: "CAMARET",	l: "FRANCE" },
            { n: "CAMAS-WASHOUGAL",	l: "UNITED STATES" },
            { n: "CAMBODIA (COUNTRY)",	l: "CAMBODIA" },
            { n: "CAMBRIDGE",	l: "UNITED STATES" },
            { n: "CAMBRIDGE BAY",	l: "CANADA" },
            { n: "CAMDEN",	l: "UNITED STATES" },
            { n: "CAMERON",	l: "UNITED STATES" },
            { n: "CAMEROON REPUBLIC (COUNTRY)",	l: "CAMEROON" },
            { n: "CAMPANA",	l: "ARGENTINA" },
            { n: "CAMPBELL RIVER",	l: "CANADA" },
            { n: "CAMPBELLTON",	l: "CANADA" },
            { n: "CAMPBELTOWN",	l: "UNITED KINGDOM" },
            { n: "CAMPDEN PARK BAY",	l: "SAINT VINCENT AND THE GRENADINES" },
            { n: "CAMPECHE",	l: "MEXICO" },
            { n: "CAMPHA",	l: "VIETNAM" },
            { n: "CAMPOS BASIN",	l: "BRAZIL" },
            { n: "CAN THO",	l: "VIETNAM" },
            { n: "CANADA (COUNTRY)",	l: "CANADA" },
            { n: "CANAKKALE",	l: "TURKEY" },
            { n: "CANAL DE VRIDI",	l: "COTE D'IVOIRE" },
            { n: "CANAPORT",	l: "CANADA" },
            { n: "CANARY ISLANDS",	l: "SPAIN" },
            { n: "CANARY ISLANDS (COUNTRY)",	l: "SPAIN" },
            { n: "CANAVERAL",	l: "UNITED STATES" },
            { n: "CANCALE",	l: "FRANCE" },
            { n: "CANDIA",	l: "GREECE" },
            { n: "CANEA",	l: "GREECE" },
            { n: "CANICAL",	l: "PORTUGAL" },
            { n: "CANNANORE",	l: "INDIA" },
            { n: "CANNES",	l: "FRANCE" },
            { n: "CANSO",	l: "CANADA" },
            { n: "CANSO CANAL AND CAUSEWAY",	l: "CANADA" },
            { n: "CANTARELL OFFSHORE TERMINAL",	l: "MEXICO" },
            { n: "CANVEY ISLAND",	l: "UNITED KINGDOM" },
            { n: "CAOFEIDIAN",	l: "CHINA" },
            { n: "CAOFEIDIAN 11 TERMINAL",	l: "CHINA" },
            { n: "CAOJING",	l: "CHINA" },
            { n: "CAP HAITIEN",	l: "HAITI" },
            { n: "CAP-AUX-MEULES",	l: "CANADA" },
            { n: "CAPE AGULHAS",	l: "SOUTH AFRICA" },
            { n: "CAPE CHARLES",	l: "UNITED STATES" },
            { n: "CAPE COD CANAL",	l: "UNITED STATES" },
            { n: "CAPE COD CANAL",	l: "UNITED STATES" },
            { n: "CAPE COMORIN",	l: "INDIA" },
            { n: "CAPE CUVIER",	l: "AUSTRALIA" },
            { n: "CAPE DORSET, NU",	l: "CANADA" },
            { n: "CAPE FAREWELL",	l: "NEW ZEALAND" },
            { n: "CAPE FAREWELL (GREENLAND)",	l: "GREENLAND" },
            { n: "CAPE FINISTERRE",	l: "SPAIN" },
            { n: "CAPE FLATTERY",	l: "AUSTRALIA" },
            { n: "CAPE FLATTERY (USA)",	l: "UNITED STATES" },
            { n: "CAPE GIRARDEAU",	l: "UNITED STATES" },
            { n: "CAPE HENLOPEN",	l: "UNITED STATES" },
            { n: "CAPE HENRY",	l: "UNITED STATES" },
            { n: "CAPE HETTERAS",	l: "UNITED STATES" },
            { n: "CAPE HORN",	l: "CHILE" },
            { n: "CAPE HORN",	l: "CHILE" },
            { n: "CAPE JERVIS",	l: "AUSTRALIA" },
            { n: "CAPE LAMBERT",	l: "AUSTRALIA" },
            { n: "CAPE LEEUWIN",	l: "AUSTRALIA" },
            { n: "CAPE LEEUWIN SOUTH",	l: "AUSTRALIA" },
            { n: "CAPE LOPEZ",	l: "GABON" },
            { n: "CAPE MATAPAN",	l: "GREECE" },
            { n: "CAPE OF GOOD HOPE",	l: "SOUTH AFRICA" },
            { n: "CAPE OF GOOD HOPE",	l: "SOUTH AFRICA" },
            { n: "CAPE PALMAS",	l: "LIBERIA" },
            { n: "CAPE PASSERO",	l: "ITALY" },
            { n: "CAPE PRESTON",	l: "AUSTRALIA" },
            { n: "CAPE RACE",	l: "CANADA" },
            { n: "CAPE SPARTIVENTO",	l: "ITALY" },
            { n: "CAPE TOWN",	l: "SOUTH AFRICA" },
            { n: "CAPE VERDE ISLANDS (COUNTRY)",	l: "CAPE VERDE" },
            { n: "CAPE WRATH",	l: "UNITED KINGDOM" },
            { n: "CAPECOD CANAL EAST",	l: "UNITED STATES" },
            { n: "CAPECOD CANAL WEST",	l: "UNITED STATES" },
            { n: "CAPETOWN-DURBAN",	l: "SOUTH AFRICA" },
            { n: "CAPRI",	l: "ITALY" },
            { n: "CAPUABA",	l: "BRAZIL" },
            { n: "CAR NICOBAR",	l: "INDIA" },
            { n: "CARACAS BAY",	l: "CURACAO" },
            { n: "CARAQUET",	l: "CANADA" },
            { n: "CARBONEAR",	l: "CANADA" },
            { n: "CARBONERAS",	l: "SPAIN" },
            { n: "CARBOSAN",	l: "COLOMBIA" },
            { n: "CARDENAS",	l: "CUBA" },
            { n: "CARDIFF",	l: "UNITED KINGDOM" },
            { n: "CARDINAL",	l: "CANADA" },
            { n: "CARENERO",	l: "VENEZUELA" },
            { n: "CARIBBEAN ISLANDS",	l: "CUBA" },
            { n: "CARINO",	l: "SPAIN" },
            { n: "CARIPITO",	l: "VENEZUELA" },
            { n: "CARLETON",	l: "CANADA" },
            { n: "CARLINGFORD",	l: "IRELAND" },
            { n: "CARLOFORTE",	l: "ITALY" },
            { n: "CARMANVILLE",	l: "CANADA" },
            { n: "CARMOPOLIS",	l: "BRAZIL" },
            { n: "CARN",	l: "CHINA" },
            { n: "CARNARVON",	l: "AUSTRALIA" },
            { n: "CARNLOUGH",	l: "UNITED KINGDOM" },
            { n: "CARONTE",	l: "FRANCE" },
            { n: "CARPENTERS BAYOU END",	l: "UNITED STATES" },
            { n: "CARRASCAL BAY",	l: "PHILIPPINES" },
            { n: "CARRICKFERGUS",	l: "UNITED KINGDOM" },
            { n: "CARRINGTON",	l: "UNITED KINGDOM" },
            { n: "CARTAGENA (COLOMBIA)",	l: "COLOMBIA" },
            { n: "CARTAGENA (SPAIN)",	l: "SPAIN" },
            { n: "CARTERET",	l: "UNITED STATES" },
            { n: "CARTIER",	l: "CANADA" },
            { n: "CARUPANO",	l: "VENEZUELA" },
            { n: "CARVILLE",	l: "UNITED STATES" },
            { n: "CASABLANCA",	l: "MOROCCO" },
            { n: "CASCADE BAY",	l: "AUSTRALIA" },
            { n: "CASCAIS",	l: "PORTUGAL" },
            { n: "CASILDA",	l: "CUBA" },
            { n: "CASTELLAMMARE DI STABIA",	l: "ITALY" },
            { n: "CASTELLON",	l: "SPAIN" },
            { n: "CASTLE BAY",	l: "UNITED KINGDOM" },
            { n: "CASTLE ISLAND",	l: "UNITED STATES" },
            { n: "CASTLE-PORT MORRIS",	l: "CANADA" },
            { n: "CASTLETOWN",	l: "ISLE OF MAN" },
            { n: "CASTLETOWN BERE",	l: "IRELAND" },
            { n: "CASTRIES",	l: "SAINT LUCIA" },
            { n: "CASTRO",	l: "CHILE" },
            { n: "CASTRO - URDIALES",	l: "SPAIN" },
            { n: "CAT LAI",	l: "VIETNAM" },
            { n: "CAT LO",	l: "VIETNAM" },
            { n: "CATACOLO",	l: "GREECE" },
            { n: "CATALINA",	l: "CANADA" },
            { n: "CATALINA ISLAND",	l: "UNITED STATES" },
            { n: "CATALINA ISLAND (DO)",	l: "DOMINICAN REPUBLIC" },
            { n: "CATANIA",	l: "ITALY" },
            { n: "CATBALOGAN",	l: "PHILIPPINES" },
            { n: "CATHERINE HILL BAY",	l: "AUSTRALIA" },
            { n: "CATIA LA MAR",	l: "VENEZUELA" },
            { n: "CATO INFENTA",	l: "PHILIPPINES" },
            { n: "CATOOSA, OK",	l: "UNITED STATES" },
            { n: "CATTARO",	l: "MONTENEGRO" },
            { n: "CATTEWATER HARBOUR",	l: "UNITED KINGDOM" },
            { n: "CAUCEDO",	l: "DOMINICAN REPUBLIC" },
            { n: "CAYENNE",	l: "FRENCH GUIANA" },
            { n: "CAYMAN BRAC",	l: "CAYMAN ISLANDS" },
            { n: "CAYMAN ISLANDS (COUNTRY)",	l: "CAYMAN ISLANDS" },
            { n: "CAYO ARCAS TERMINAL",	l: "MEXICO" },
            { n: "CAYO FRANCES",	l: "CUBA" },
            { n: "CEARA",	l: "BRAZIL" },
            { n: "CEBU",	l: "PHILIPPINES" },
            { n: "CEBU (LILOAN)",	l: "PHILIPPINES" },
            { n: "CEBU (SAN FERNANDO)",	l: "PHILIPPINES" },
            { n: "CEDAR BAYOU END",	l: "UNITED STATES" },
            { n: "CEDROS ISLAND",	l: "MEXICO" },
            { n: "CEE BRENS",	l: "SPAIN" },
            { n: "CEIBA HUECA",	l: "CUBA" },
            { n: "CEIBA MARINE TERMINAL",	l: "EQUATORIAL GUINEA" },
            { n: "CELEBES SEA",	l: "INDONESIA" },
            { n: "CELUKAN BAWANG",	l: "INDONESIA" },
            { n: "CEMENTON",	l: "UNITED STATES" },
            { n: "CENDOR TERMINAL",	l: "MALAYSIA" },
            { n: "CENGKARENG",	l: "INDONESIA" },
            { n: "CENTRAL FERRY",	l: "UNITED STATES" },
            { n: "CENTREPORT",	l: "NEW ZEALAND" },
            { n: "CEPHALONIA",	l: "GREECE" },
            { n: "CERIGO",	l: "GREECE" },
            { n: "CERNAVODA",	l: "ROMANIA" },
            { n: "CESME",	l: "TURKEY" },
            { n: "CEUTA",	l: "SPAIN" },
            { n: "CEYHAN",	l: "TURKEY" },
            { n: "CHABAHAR",	l: "IRAN" },
            { n: "CHACAO CHANNEL",	l: "CHILE" },
            { n: "CHAGUARAMAS",	l: "TRINIDAD AND TOBAGO" },
            { n: "CHAI WAN",	l: "HONG KONG" },
            { n: "CHAKE CHAKE",	l: "TANZANIA" },
            { n: "CHALAMPE",	l: "FRANCE" },
            { n: "CHALKIS",	l: "GREECE" },
            { n: "CHALLIS VENTURE TERMINAL",	l: "AUSTRALIA" },
            { n: "CHALMETTE",	l: "UNITED STATES" },
            { n: "CHALNA",	l: "BANGLADESH" },
            { n: "CHAMPAGNE BAY",	l: "VANUATU" },
            { n: "CHAMPERICO",	l: "GUATEMALA" },
            { n: "CHAN MAY",	l: "VIETNAM" },
            { n: "CHANARAL",	l: "CHILE" },
            { n: "CHANCAY",	l: "PERU" },
            { n: "CHANDLER",	l: "CANADA" },
            { n: "CHANGAN-SEO PILOT STATION",	l: "KOREA, REPUBLIC" },
            { n: "CHANGDE",	l: "CHINA" },
            { n: "CHANGHANG",	l: "KOREA, REPUBLIC" },
            { n: "CHANGJIAGANG",	l: "CHINA" },
            { n: "CHANGJIANG",	l: "CHINA" },
            { n: "CHANGJIANGKOU",	l: "CHINA" },
            { n: "CHANGJIANGKOU PILOT STATION",	l: "CHINA" },
            { n: "CHANGSHA",	l: "CHINA" },
            { n: "CHANGSHU",	l: "CHINA" },
            { n: "CHANGXING ISLAND",	l: "CHINA" },
            { n: "CHANGZHOU",	l: "CHINA" },
            { n: "CHANNEL ISLANDS (COUNTRY)",	l: "CHANNEL ISLANDS" },
            { n: "CHANTENAY",	l: "FRANCE" },
            { n: "CHANTHABURI",	l: "THAILAND" },
            { n: "CHAOZHOU",	l: "CHINA" },
            { n: "CHARCO AZUL",	l: "PANAMA" },
            { n: "CHARLESTON",	l: "UNITED STATES" },
            { n: "CHARLESTON SC",	l: "UNITED STATES" },
            { n: "CHARLESTOWN (ST KITTS)",	l: "SAINT KITTS AND NEVIS" },
            { n: "CHARLESTOWN (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "CHARLEVOIX",	l: "UNITED STATES" },
            { n: "CHARLIE ZONE (RIVER PLATE)",	l: "URUGUAY" },
            { n: "CHARLOTTE",	l: "CANADA" },
            { n: "CHARLOTTE AMALIE",	l: "VIRGIN ISLANDS" },
            { n: "CHARLOTTETOWN",	l: "CANADA" },
            { n: "CHATHAM",	l: "CANADA" },
            { n: "CHATHAM DOCKS",	l: "UNITED KINGDOM" },
            { n: "CHATTACHOOCHEE END",	l: "UNITED STATES" },
            { n: "CHATTACHOOCHEE START",	l: "UNITED STATES" },
            { n: "CHEBOYGAN",	l: "UNITED STATES" },
            { n: "CHEFOO",	l: "CHINA" },
            { n: "CHEJU",	l: "KOREA, REPUBLIC" },
            { n: "CHEKKA",	l: "LEBANON" },
            { n: "CHELSEA",	l: "UNITED STATES" },
            { n: "CHEMAINUS",	l: "CANADA" },
            { n: "CHENGGUAN",	l: "CHINA" },
            { n: "CHENGLINGJI",	l: "CHINA" },
            { n: "CHENGWAN",	l: "CHINA" },
            { n: "CHENGXI",	l: "CHINA" },
            { n: "CHENJIAGANG",	l: "CHINA" },
            { n: "CHENNAI",	l: "INDIA" },
            { n: "CHENSHAN",	l: "CHINA" },
            { n: "CHERBOURG",	l: "FRANCE" },
            { n: "CHERCHELL",	l: "ALGERIA" },
            { n: "CHERNOMORSK",	l: "UKRAINE" },
            { n: "CHERRY POINT",	l: "UNITED STATES" },
            { n: "CHESAPEAKE",	l: "UNITED STATES" },
            { n: "CHESAPEAKE BAY ENTRANCE",	l: "UNITED STATES" },
            { n: "CHESAPEAKE LIGHT",	l: "UNITED STATES" },
            { n: "CHESTER",	l: "UNITED STATES" },
            { n: "CHESTERFIELD INLET, NU",	l: "CANADA" },
            { n: "CHIBA",	l: "JAPAN" },
            { n: "CHICAGO",	l: "UNITED STATES" },
            { n: "CHICHIRIVICHE",	l: "VENEZUELA" },
            { n: "CHICOUTIMI",	l: "CANADA" },
            { n: "CHIKOU",	l: "CHINA" },
            { n: "CHILE (COUNTRY)",	l: "CHILE" },
            { n: "CHILIA",	l: "ROMANIA" },
            { n: "CHILUNG",	l: "TAIWAN" },
            { n: "CHIM SAO MARINE TERMINAL",	l: "VIETNAM" },
            { n: "CHIMBOTE",	l: "PERU" },
            { n: "CHINA (COUNTRY)",	l: "CHINA" },
            { n: "CHINA BAY",	l: "SRI LANKA" },
            { n: "CHINDE",	l: "MOZAMBIQUE" },
            { n: "CHINGUETTI TERMINAL",	l: "MAURITANIA" },
            { n: "CHINHAE",	l: "KOREA, REPUBLIC" },
            { n: "CHINKIANG",	l: "CHINA" },
            { n: "CHINWANGTAO",	l: "CHINA" },
            { n: "CHIOGGIA",	l: "ITALY" },
            { n: "CHIOS",	l: "GREECE" },
            { n: "CHIRIQUI GRANDE TERMINAL",	l: "PANAMA" },
            { n: "CHISIMAIO",	l: "SOMALIA" },
            { n: "CHITA",	l: "JAPAN" },
            { n: "CHITTAGONG",	l: "BANGLADESH" },
            { n: "CHIWAN",	l: "CHINA" },
            { n: "CHIZHOU",	l: "CHINA" },
            { n: "CHOCOLATE BAYOU CHANNEL END",	l: "UNITED STATES" },
            { n: "CHOFU",	l: "JAPAN" },
            { n: "CHO-LON",	l: "VIETNAM" },
            { n: "CHONGJIN",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "CHONGMING",	l: "CHINA" },
            { n: "CHONGNONSRI MARINE TERMINAL",	l: "THAILAND" },
            { n: "CHONGQING",	l: "CHINA" },
            { n: "CHRISTCHURCH",	l: "NEW ZEALAND" },
            { n: "CHRISTIANSAND N",	l: "NORWAY" },
            { n: "CHRISTIANSAND S",	l: "NORWAY" },
            { n: "CHRISTIANSHAAB",	l: "GREENLAND" },
            { n: "CHRISTIANSTED",	l: "VIRGIN ISLANDS" },
            { n: "CHRISTMAS ISLAND",	l: "CHRISTMAS ISLAND" },
            { n: "CHRISTMAS ISLAND (COUNTRY)",	l: "CHRISTMAS ISLAND" },
            { n: "CHUANSHA",	l: "CHINA" },
            { n: "CHUANYANGHE",	l: "CHINA" },
            { n: "CHUK SAMET",	l: "THAILAND" },
            { n: "CHURCHILL",	l: "CANADA" },
            { n: "CHUUK",	l: "MICRONESIA" },
            { n: "CIENAGA",	l: "COLOMBIA" },
            { n: "CIENFUEGOS",	l: "CUBA" },
            { n: "CIGADING",	l: "INDONESIA" },
            { n: "CIHU",	l: "CHINA" },
            { n: "CILACAP",	l: "INDONESIA" },
            { n: "CILEGON",	l: "INDONESIA" },
            { n: "CILLERO",	l: "SPAIN" },
            { n: "CINTA",	l: "INDONESIA" },
            { n: "CIP 66 WEST",	l: "CANADA" },
            { n: "CIP 9",	l: "CANADA" },
            { n: "CIP CAPE VINCENT",	l: "UNITED STATES" },
            { n: "CIP CROSSOVER ISLAND",	l: "UNITED STATES" },
            { n: "CIREBON",	l: "INDONESIA" },
            { n: "CIRO MARINA",	l: "ITALY" },
            { n: "CITRA UJUNG BARU",	l: "INDONESIA" },
            { n: "CIUDAD BOLIVAR",	l: "VENEZUELA" },
            { n: "CIUDAD DEL CARMEN",	l: "MEXICO" },
            { n: "CIUDAD MADERO",	l: "MEXICO" },
            { n: "CIUDAD TRUJILLO",	l: "DOMINICAN REPUBLIC" },
            { n: "CIVITAVECCHIA",	l: "ITALY" },
            { n: "CIWANDAN",	l: "INDONESIA" },
            { n: "CJK",	l: "CHINA" },
            { n: "CLARECASTLE",	l: "IRELAND" },
            { n: "CLAREMONT",	l: "UNITED STATES" },
            { n: "CLARENCE BAY",	l: "SAINT HELENA" },
            { n: "CLARENCE BAY (CHILE)",	l: "CHILE" },
            { n: "CLARENVILLE",	l: "CANADA" },
            { n: "CLARKE CITY",	l: "CANADA" },
            { n: "CLARKSON",	l: "CANADA" },
            { n: "CLARKSTON",	l: "UNITED STATES" },
            { n: "CLAVERIA",	l: "PHILIPPINES" },
            { n: "CLAYMONT",	l: "UNITED STATES" },
            { n: "CLAYTON",	l: "UNITED STATES" },
            { n: "CLEARWATER END",	l: "UNITED STATES" },
            { n: "CLEARWATER START",	l: "UNITED STATES" },
            { n: "CLEVELAND",	l: "UNITED STATES" },
            { n: "CLIFFE",	l: "UNITED KINGDOM" },
            { n: "CLIFTON POINT",	l: "THE BAHAMAS" },
            { n: "CLINCH END",	l: "UNITED STATES" },
            { n: "CLINCH START",	l: "UNITED STATES" },
            { n: "CLOGHAN POINT",	l: "UNITED KINGDOM" },
            { n: "CLOV CALM",	l: "ANGOLA" },
            { n: "CLOV FPSO",	l: "ANGOLA" },
            { n: "CLYDE PORT",	l: "UNITED KINGDOM" },
            { n: "CLYDE RIVER, NU",	l: "CANADA" },
            { n: "CLYDEBANK",	l: "UNITED KINGDOM" },
            { n: "COATZACOALCOS",	l: "MEXICO" },
            { n: "COBH",	l: "IRELAND" },
            { n: "COCHIN",	l: "INDIA" },
            { n: "COCKATOO ISLAND",	l: "AUSTRALIA" },
            { n: "COCKATOO ISLAND",	l: "AUSTRALIA" },
            { n: "COCKBURN HARBOUR",	l: "TURKS AND CAICOS ISLANDS" },
            { n: "COCKENZIE",	l: "UNITED KINGDOM" },
            { n: "COCO SOLO NORTH",	l: "PANAMA" },
            { n: "COCOCAY",	l: "THE BAHAMAS" },
            { n: "COCOS ISLANDS",	l: "AUSTRALIA" },
            { n: "COCOS ISLANDS (COUNTRY)",	l: "COCOS ISLANDS" },
            { n: "COD BAY",	l: "SRI LANKA" },
            { n: "COEGA",	l: "SOUTH AFRICA" },
            { n: "COEYMANS",	l: "UNITED STATES" },
            { n: "COFFS HARBOUR",	l: "AUSTRALIA" },
            { n: "COGO",	l: "EQUATORIAL GUINEA" },
            { n: "COISHCO",	l: "PERU" },
            { n: "COLBORNE",	l: "CANADA" },
            { n: "COLCHESTER",	l: "UNITED KINGDOM" },
            { n: "COLD BAY",	l: "UNITED STATES" },
            { n: "COLE BAY",	l: "ST. MARTEN" },
            { n: "COLERAINE",	l: "UNITED KINGDOM" },
            { n: "COLLINGWOOD",	l: "CANADA" },
            { n: "COLLO",	l: "ALGERIA" },
            { n: "COLOGNE",	l: "GERMANY" },
            { n: "COLOMBIA (COUNTRY)",	l: "COLOMBIA" },
            { n: "COLOMBO",	l: "SRI LANKA" },
            { n: "COLOMBO-JAPAN",	l: "JAPAN" },
            { n: "COLON",	l: "PANAMA" },
            { n: "COLONCHA",	l: "VENEZUELA" },
            { n: "COLONIA",	l: "URUGUAY" },
            { n: "COLORADO RIVER END",	l: "UNITED STATES" },
            { n: "COLOSO",	l: "CHILE" },
            { n: "COLUMBIA",	l: "UNITED STATES" },
            { n: "COLUMBIA NC",	l: "UNITED STATES" },
            { n: "COLUMBIA/SNAKE RIVER SYSTEM",	l: "UNITED STATES" },
            { n: "COME BY CHANCE",	l: "CANADA" },
            { n: "COMEAU BAY",	l: "CANADA" },
            { n: "COMMODORE'S POINT",	l: "UNITED STATES" },
            { n: "COMODORO RIVADAVIA",	l: "ARGENTINA" },
            { n: "COMOROS (COUNTRY)",	l: "COMOROS" },
            { n: "COMOX",	l: "CANADA" },
            { n: "CONAKRY",	l: "GUINEA" },
            { n: "CONCARNEAU",	l: "FRANCE" },
            { n: "CONCEICAOZINHA TERMINAL",	l: "BRAZIL" },
            { n: "CONCEPCION BAY",	l: "CHILE" },
            { n: "CONCEPCION DEL URUGUAY",	l: "ARGENTINA" },
            { n: "CONCHAN",	l: "PERU" },
            { n: "CONFEDERATION BRIDGE",	l: "CANADA" },
            { n: "CONGO (COUNTRY)",	l: "CONGO" },
            { n: "CONGO (DEMOCRATIC REPUBLIC) (COUNTRY)",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "CONNEAUT",	l: "UNITED STATES" },
            { n: "CONSTANTZA",	l: "ROMANIA" },
            { n: "CONTRECOEUR",	l: "CANADA" },
            { n: "CONVENT",	l: "UNITED STATES" },
            { n: "CONWY",	l: "UNITED KINGDOM" },
            { n: "COOK ISLANDS (COUNTRY)",	l: "COOK ISLANDS" },
            { n: "COOK STRAIT",	l: "NEW ZEALAND" },
            { n: "COOKTOWN",	l: "AUSTRALIA" },
            { n: "COOLKERAGH",	l: "UNITED KINGDOM" },
            { n: "COONDAPUR",	l: "INDIA" },
            { n: "COOS BAY",	l: "UNITED STATES" },
            { n: "COPACABANA",	l: "BRAZIL" },
            { n: "COPENHAGEN",	l: "DENMARK" },
            { n: "COQUIMBO",	l: "CHILE" },
            { n: "CORAL HARBOUR, NU",	l: "CANADA" },
            { n: "CORCUBION",	l: "SPAIN" },
            { n: "CORDEMAIS",	l: "FRANCE" },
            { n: "CORDOVA (ALABAMA)",	l: "UNITED STATES" },
            { n: "CORDOVA (ALASKA)",	l: "UNITED STATES" },
            { n: "CORFU",	l: "GREECE" },
            { n: "CORIGLIANA CALABRO",	l: "ITALY" },
            { n: "CORINTH",	l: "GREECE" },
            { n: "CORINTH CANAL",	l: "GREECE" },
            { n: "CORINTH CANAL EAST",	l: "GREECE" },
            { n: "CORINTH CANAL WEST",	l: "GREECE" },
            { n: "CORINTO",	l: "NICARAGUA" },
            { n: "CORITO BAY",	l: "ANGUILLA" },
            { n: "CORK",	l: "IRELAND" },
            { n: "CORNER BROOK",	l: "CANADA" },
            { n: "CORNIGUEL",	l: "FRANCE" },
            { n: "CORNWALL",	l: "CANADA" },
            { n: "CORONEL",	l: "CHILE" },
            { n: "CORPACH",	l: "UNITED KINGDOM" },
            { n: "CORPUS CHRISTI",	l: "UNITED STATES" },
            { n: "CORPUS CHRISTI AND LAQUINTA",	l: "UNITED STATES" },
            { n: "CORPUS CHRISTI LIGHTERING AREA",	l: "UNITED STATES" },
            { n: "CORPUS CHRISTI LZ",	l: "UNITED STATES" },
            { n: "CORPUS CHRISTI SHIP CHANNEL END",	l: "UNITED STATES" },
            { n: "CORPUS CHRISTI SHIP CHANNEL START",	l: "UNITED STATES" },
            { n: "CORRALLE",	l: "CHILE" },
            { n: "CORUMBA",	l: "BRAZIL" },
            { n: "CORUNNA",	l: "SPAIN" },
            { n: "CORYTON",	l: "UNITED KINGDOM" },
            { n: "COSIPA TERMINAL",	l: "BRAZIL" },
            { n: "COSSACK",	l: "AUSTRALIA" },
            { n: "COSSACK PIONEER TERMINAL",	l: "AUSTRALIA" },
            { n: "COSTA MAYA",	l: "MEXICO" },
            { n: "COSTA RICA (COUNTRY)",	l: "COSTA RICA" },
            { n: "COTE STE CATHERINE",	l: "CANADA" },
            { n: "COTEGIPE",	l: "BRAZIL" },
            { n: "COTONOU",	l: "BENIN" },
            { n: "COUERON",	l: "FRANCE" },
            { n: "COUNTRY HARBOUR",	l: "CANADA" },
            { n: "COURTRIGHT",	l: "CANADA" },
            { n: "COVE ISLAND FW BUOY",	l: "CANADA" },
            { n: "COVE POINT",	l: "UNITED STATES" },
            { n: "COVENAS OFFSHORE TERMINAL",	l: "COLOMBIA" },
            { n: "COWES",	l: "UNITED KINGDOM" },
            { n: "COWICHAN BAY",	l: "CANADA" },
            { n: "COXEN HOLE",	l: "HONDURAS" },
            { n: "COZUMEL",	l: "MEXICO" },
            { n: "CRAIG",	l: "UNITED STATES" },
            { n: "CREAC'H POINT",	l: "FRANCE" },
            { n: "CREEK",	l: "CAYMAN ISLANDS" },
            { n: "CREEKSEA",	l: "UNITED KINGDOM" },
            { n: "CRESCENT CITY",	l: "UNITED STATES" },
            { n: "CRIB POINT",	l: "AUSTRALIA" },
            { n: "CRINAN CANAL",	l: "UNITED KINGDOM" },
            { n: "CRISTOBAL",	l: "PANAMA" },
            { n: "CRISTOBAL COLON",	l: "VENEZUELA" },
            { n: "CROATIA (COUNTRY)",	l: "CROATIA" },
            { n: "CROCKETT",	l: "UNITED STATES" },
            { n: "CROFTON",	l: "CANADA" },
            { n: "CROKER ISLAND",	l: "AUSTRALIA" },
            { n: "CROMARTY FIRTH",	l: "UNITED KINGDOM" },
            { n: "CROMER",	l: "UNITED KINGDOM" },
            { n: "CRONSTADT ISLAND",	l: "TRINIDAD AND TOBAGO" },
            { n: "CROOKED ISLAND",	l: "THE BAHAMAS" },
            { n: "CROSSMAN POINT",	l: "CANADA" },
            { n: "CROTONE",	l: "ITALY" },
            { n: "CROUCH HARBOUR",	l: "UNITED KINGDOM" },
            { n: "CROWN POINT",	l: "TRINIDAD AND TOBAGO" },
            { n: "CRUZ GRANDE",	l: "CHILE" },
            { n: "CSC LOCK",	l: "CANADA" },
            { n: "CUA LO",	l: "VIETNAM" },
            { n: "CUA VIET",	l: "VIETNAM" },
            { n: "CUBA (COUNTRY)",	l: "CUBA" },
            { n: "CUBATAO",	l: "BRAZIL" },
            { n: "CUDDALORE",	l: "INDIA" },
            { n: "CUKUROVA",	l: "TURKEY" },
            { n: "CULASI",	l: "PHILIPPINES" },
            { n: "CUL-DE-SAC",	l: "SAINT LUCIA" },
            { n: "CUMANA",	l: "VENEZUELA" },
            { n: "CUMAREBO",	l: "VENEZUELA" },
            { n: "CUMBERLAND END",	l: "UNITED STATES" },
            { n: "CUMBERLAND START",	l: "UNITED STATES" },
            { n: "CURACAO",	l: "CURACAO" },
            { n: "CUROIL MOORING",	l: "CURACAO" },
            { n: "CURRIMAO",	l: "PHILIPPINES" },
            { n: "CURTIS ISLAND",	l: "NEW ZEALAND" },
            { n: "CUTRALE TERMINAL",	l: "BRAZIL" },
            { n: "CUTTER COVE",	l: "CHILE" },
            { n: "CUTUCO",	l: "EL SALVADOR" },
            { n: "CUXHAVEN",	l: "GERMANY" },
            { n: "CYPRUS (COUNTRY)",	l: "CYPRUS" },
            { n: "CYRUS TERMINAL",	l: "IRAN" },
            { n: "DA NANG",	l: "VIETNAM" },
            { n: "DAAN BANWA",	l: "PHILIPPINES" },
            { n: "DABHOL",	l: "INDIA" },
            { n: "DABO",	l: "INDONESIA" },
            { n: "DABO SINGKEP",	l: "INDONESIA" },
            { n: "DABUQIAO",	l: "CHINA" },
            { n: "DADIANGAS",	l: "PHILIPPINES" },
            { n: "DADONG",	l: "CHINA" },
            { n: "DADUKOU",	l: "CHINA" },
            { n: "DADUKOU (HUNAN PROVINCE)",	l: "CHINA" },
            { n: "DAEBUL",	l: "KOREA, REPUBLIC" },
            { n: "DAEJEON",	l: "KOREA, REPUBLIC" },
            { n: "DAESAN",	l: "KOREA, REPUBLIC" },
            { n: "dafeng",	l: "CHINA" },
            { n: "DAFENG",	l: "CHINA" },
            { n: "DAGANG (DALIAN)",	l: "CHINA" },
            { n: "DAGANG (QINGDAO)",	l: "CHINA" },
            { n: "DAGENHAM",	l: "UNITED KINGDOM" },
            { n: "DAHANU",	l: "INDIA" },
            { n: "DAHEJ",	l: "INDIA" },
            { n: "DAI HUNG",	l: "VIETNAM" },
            { n: "DAIPORI",	l: "KOREA, REPUBLIC" },
            { n: "DAIREN",	l: "CHINA" },
            { n: "DAKAR",	l: "SENEGAL" },
            { n: "DAKHLA",	l: "WESTERN SAHARA" },
            { n: "DALHOUSIE",	l: "CANADA" },
            { n: "DALIA TERMINAL",	l: "ANGOLA" },
            { n: "DALIAN",	l: "CHINA" },
            { n: "DALRYMPLE BAY",	l: "AUSTRALIA" },
            { n: "DALVIK",	l: "ICELAND" },
            { n: "DAMES POINT",	l: "UNITED STATES" },
            { n: "DAMIETTA",	l: "EGYPT" },
            { n: "DAMMAM",	l: "SAUDI ARABIA" },
            { n: "DAMPIER",	l: "AUSTRALIA" },
            { n: "DAMPIER STRAIT",	l: "PAPUA NEW GUINEA" },
            { n: "DANANG",	l: "VIETNAM" },
            { n: "DANAO ESCALANTE",	l: "PHILIPPINES" },
            { n: "DANDE",	l: "ANGOLA" },
            { n: "DANDONG",	l: "CHINA" },
            { n: "DANGJING",	l: "KOREA, REPUBLIC" },
            { n: "DANGRIGA",	l: "BELIZE" },
            { n: "DANIA",	l: "DENMARK" },
            { n: "DANONG",	l: "VIETNAM" },
            { n: "DANSK",	l: "DENMARK" },
            { n: "DANTE",	l: "SOMALIA" },
            { n: "DANUBE - BLACK SEA CANAL",	l: "ROMANIA" },
            { n: "DANUBE RIVER",	l: "ROMANIA" },
            { n: "DANZIG",	l: "POLAND" },
            { n: "DAPENG",	l: "CHINA" },
            { n: "DAR ES SALAAM",	l: "TANZANIA" },
            { n: "DARDANELLES STRAITS",	l: "TURKEY" },
            { n: "DARICA",	l: "TURKEY" },
            { n: "DARLING HARBOUR",	l: "AUSTRALIA" },
            { n: "DARLOWO",	l: "POLAND" },
            { n: "DARROW",	l: "UNITED STATES" },
            { n: "DARTMOUTH",	l: "UNITED KINGDOM" },
            { n: "DARTMOUTH (CANADA)",	l: "CANADA" },
            { n: "DARU",	l: "PAPUA NEW GUINEA" },
            { n: "DARWIN",	l: "AUSTRALIA" },
            { n: "DAS ISLAND",	l: "UNITED ARAB EMIRATES" },
            { n: "DATONG",	l: "CHINA" },
            { n: "DATUAN",	l: "CHINA" },
            { n: "DATUK SIM KHENG HONG",	l: "MALAYSIA" },
            { n: "DAVANT",	l: "UNITED STATES" },
            { n: "DAVAO",	l: "PHILIPPINES" },
            { n: "DAWAI",	l: "INDONESIA" },
            { n: "DAWEI",	l: "MYANMAR" },
            { n: "DAWEI",	l: "MYANMAR" },
            { n: "DAWES ISLAND",	l: "NIGERIA" },
            { n: "DAXINGJI",	l: "CHINA" },
            { n: "DAYA BAY",	l: "CHINA" },
            { n: "DAYAO BAY",	l: "CHINA" },
            { n: "DAZHONG",	l: "CHINA" },
            { n: "DE GI",	l: "VIETNAM" },
            { n: "DE KASTRI",	l: "RUSSIA" },
            { n: "DEAUVILLE",	l: "FRANCE" },
            { n: "DECEPTION BAY",	l: "CANADA" },
            { n: "DEEP COVE",	l: "NEW ZEALAND" },
            { n: "DEEP GULF NO 1",	l: "UNITED STATES" },
            { n: "DEEP GULF NO 1 LZ",	l: "UNITED STATES" },
            { n: "DEER PARK",	l: "UNITED STATES" },
            { n: "DEGEMA",	l: "NIGERIA" },
            { n: "DEGERHAMN",	l: "SWEDEN" },
            { n: "DEGRAD DES CANNES",	l: "FRENCH GUIANA" },
            { n: "DEL GUAZU",	l: "ARGENTINA" },
            { n: "DELAWARE BAY ENTRANCE",	l: "UNITED STATES" },
            { n: "DELAWARE CITY",	l: "UNITED STATES" },
            { n: "DELAWARE LIGHT",	l: "UNITED STATES" },
            { n: "DELAWARE LIGHT 2",	l: "UNITED STATES" },
            { n: "DELFZIJL",	l: "NETHERLANDS" },
            { n: "DELLYS",	l: "ALGERIA" },
            { n: "DELTA",	l: "URUGUAY" },
            { n: "DELTA TERMINAL",	l: "TURKEY" },
            { n: "DEMERARA",	l: "GUYANA" },
            { n: "DEMOPOLIS",	l: "UNITED STATES" },
            { n: "DEN HELDER",	l: "NETHERLANDS" },
            { n: "DENIA",	l: "SPAIN" },
            { n: "DENMARK - WEST",	l: "DENMARK" },
            { n: "DENMARK (COUNTRY)",	l: "DENMARK" },
            { n: "DERBY",	l: "AUSTRALIA" },
            { n: "DERINCE",	l: "TURKEY" },
            { n: "DERINDJE",	l: "TURKEY" },
            { n: "DERNA",	l: "LIBYA" },
            { n: "DESEADO",	l: "ARGENTINA" },
            { n: "DESTREHAN",	l: "UNITED STATES" },
            { n: "DETOUR REEF LIGHT",	l: "UNITED STATES" },
            { n: "DETROIT",	l: "UNITED STATES" },
            { n: "DETROIT RIVER LIGHT",	l: "CANADA" },
            { n: "DEVIATION POINT FOR STOWAWAY 9-15-14",	l: "TOGO" },
            { n: "DEVIL'S ISLAND",	l: "FRENCH GUIANA" },
            { n: "DEVONPORT (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "DEVONPORT (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "DHAMRA",	l: "INDIA" },
            { n: "DHARAMTAR",	l: "INDIA" },
            { n: "DHEKELIA",	l: "CYPRUS" },
            { n: "DHIBA",	l: "SAUDI ARABIA" },
            { n: "DHOLERA",	l: "INDIA" },
            { n: "DHUBA",	l: "SAUDI ARABIA" },
            { n: "DIAMANTE",	l: "ARGENTINA" },
            { n: "DIAMOND HARBOUR",	l: "INDIA" },
            { n: "DIBBA",	l: "UNITED ARAB EMIRATES" },
            { n: "DIDON TERMINAL",	l: "TUNISIA" },
            { n: "DIDONG",	l: "TUNISIA" },
            { n: "DIDONG (CHINA)",	l: "CHINA" },
            { n: "DIEGO GARCIA",	l: "BRIT. IND" },
            { n: "DIEGO SUAREZ",	l: "MADAGASCAR" },
            { n: "DIEPPE",	l: "FRANCE" },
            { n: "DIGANG",	l: "CHINA" },
            { n: "DIGBY",	l: "CANADA" },
            { n: "DIGHI",	l: "INDIA" },
            { n: "DIKILI",	l: "TURKEY" },
            { n: "DIKSON",	l: "RUSSIA" },
            { n: "DILISKELESI",	l: "TURKEY" },
            { n: "DILLI",	l: "TIMOR-LESTE" },
            { n: "DILLINGHAM",	l: "UNITED STATES" },
            { n: "DINAGAT",	l: "PHILIPPINES" },
            { n: "DINGJIAQIAO",	l: "CHINA" },
            { n: "DINGLE",	l: "UNITED KINGDOM" },
            { n: "DINGSHU",	l: "CHINA" },
            { n: "DINISH ISLAND",	l: "IRELAND" },
            { n: "DIPOLOG",	l: "PHILIPPINES" },
            { n: "DIRDAL",	l: "NORWAY" },
            { n: "DISCOVERY BAY",	l: "JAMAICA" },
            { n: "DIXI",	l: "CHINA" },
            { n: "DJEN-DJEN",	l: "ALGERIA" },
            { n: "DJENO",	l: "CONGO" },
            { n: "DJIBOUTI",	l: "DJIBOUTI" },
            { n: "DJIBOUTI (COUNTRY)",	l: "DJIBOUTI" },
            { n: "DJUPIVOGUR",	l: "ICELAND" },
            { n: "DJURO",	l: "SWEDEN" },
            { n: "DNEPRO-BUGSKY",	l: "UKRAINE" },
            { n: "DOCK SUD",	l: "ARGENTINA" },
            { n: "DOCKYARD CREEK",	l: "NIGERIA" },
            { n: "DODGE ISLAND",	l: "UNITED STATES" },
            { n: "DOHA (KUWAIT)",	l: "KUWAIT" },
            { n: "DOHA (QATAR)",	l: "QATAR" },
            { n: "DOLVI",	l: "INDIA" },
            { n: "DOLVIKA",	l: "NORWAY" },
            { n: "DOMINICA",	l: "DOMINICA" },
            { n: "DOMINICA (COUNTRY)",	l: "DOMINICA" },
            { n: "DOMINICAN REPUBLIC (COUNTRY)",	l: "DOMINICAN REPUBLIC" },
            { n: "DOMSJO",	l: "SWEDEN" },
            { n: "DONALDSONVILLE",	l: "UNITED STATES" },
            { n: "DONG NAI",	l: "VIETNAM" },
            { n: "DONG THAP",	l: "VIETNAM" },
            { n: "DONG WU",	l: "CHINA" },
            { n: "DONGDU",	l: "CHINA" },
            { n: "DONGES",	l: "FRANCE" },
            { n: "DONGFANG",	l: "CHINA" },
            { n: "DONGFANG",	l: "CHINA" },
            { n: "DONGGALA",	l: "INDONESIA" },
            { n: "DONGGOU",	l: "CHINA" },
            { n: "DONGGUAN",	l: "CHINA" },
            { n: "DONGHAE",	l: "KOREA, REPUBLIC" },
            { n: "DONGJIAKOU",	l: "CHINA" },
            { n: "DONGSHAN",	l: "CHINA" },
            { n: "DONGTAI",	l: "CHINA" },
            { n: "DONGWAN",	l: "CHINA" },
            { n: "DONGYING",	l: "CHINA" },
            { n: "DONSO",	l: "SWEDEN" },
            { n: "DOORNZELLE",	l: "BELGIUM" },
            { n: "DORA TERMINAL",	l: "LEBANON" },
            { n: "DORDRECHT",	l: "NETHERLANDS" },
            { n: "DORTYOL OIL TERMINAL",	l: "TURKEY" },
            { n: "DOS BOCAS",	l: "MEXICO" },
            { n: "DOUALA",	l: "CAMEROON" },
            { n: "DOUARNENEZ BAY",	l: "FRANCE" },
            { n: "DOUBTFUL SOUND",	l: "NEW ZEALAND" },
            { n: "DOUGLAS",	l: "ISLE OF MAN" },
            { n: "DOUGOUFISSA CREEK",	l: "GUINEA" },
            { n: "DOURO",	l: "PORTUGAL" },
            { n: "DOVER",	l: "UNITED KINGDOM" },
            { n: "DOVER STRAIT",	l: "UNITED KINGDOM" },
            { n: "DOW QUIMICA TERMINAL",	l: "BRAZIL" },
            { n: "DRAGON LNG TERMINAL",	l: "UNITED KINGDOM" },
            { n: "DRAMMEN",	l: "NORWAY" },
            { n: "DRAPETSONA BAY",	l: "GREECE" },
            { n: "DRAVUNI ISLAND",	l: "FIJI" },
            { n: "DRELNES",	l: "FAROE ISLANDS" },
            { n: "DRENCOVA",	l: "ROMANIA" },
            { n: "DREPANO",	l: "GREECE" },
            { n: "DRIFT RIVER MARINE TERMINAL",	l: "UNITED STATES" },
            { n: "DROBAK",	l: "NORWAY" },
            { n: "DROBETA TURNU SEVERIN",	l: "ROMANIA" },
            { n: "DROGDEN",	l: "DENMARK" },
            { n: "DROGHEDA",	l: "IRELAND" },
            { n: "DRUMMOND IS.",	l: "UNITED STATES" },
            { n: "DTBASA",	l: "BRAZIL" },
            { n: "DTSE/GEGUA OIL TERMINAL",	l: "BRAZIL" },
            { n: "DUBAI",	l: "UNITED ARAB EMIRATES" },
            { n: "DUBLIN",	l: "IRELAND" },
            { n: "DUBROVNIK",	l: "CROATIA" },
            { n: "DUCOS",	l: "NEW CALEDONIA" },
            { n: "DUDINKA",	l: "RUSSIA" },
            { n: "DUGI RAT",	l: "CROATIA" },
            { n: "DUISBURG (RUHRORT)",	l: "GERMANY" },
            { n: "DUKE POINT",	l: "CANADA" },
            { n: "DUKHOVNITSKOYE",	l: "RUSSIA" },
            { n: "DULANG MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "DULUTH",	l: "UNITED STATES" },
            { n: "DULUTH-SUPERIOR",	l: "UNITED STATES" },
            { n: "DUMAGUETE",	l: "PHILIPPINES" },
            { n: "DUMAGUIT",	l: "PHILIPPINES" },
            { n: "DUMAI",	l: "INDONESIA" },
            { n: "DUMFRIES",	l: "UNITED STATES" },
            { n: "DUN LAOGHAIRE",	l: "IRELAND" },
            { n: "DUNBAR",	l: "UNITED KINGDOM" },
            { n: "DUNCAN BAY",	l: "CANADA" },
            { n: "DUNCANNON",	l: "IRELAND" },
            { n: "DUNDALK (IRELAND)",	l: "IRELAND" },
            { n: "DUNDALK (USA)",	l: "UNITED STATES" },
            { n: "DUNDEE",	l: "UNITED KINGDOM" },
            { n: "DUNDRUM BAY",	l: "UNITED KINGDOM" },
            { n: "DUNEDIN",	l: "NEW ZEALAND" },
            { n: "DUNG QUAT",	l: "VIETNAM" },
            { n: "DUNGARVAN",	l: "IRELAND" },
            { n: "DUNGENESS",	l: "UNITED KINGDOM" },
            { n: "DUNGUN",	l: "MALAYSIA" },
            { n: "DUNKIRK",	l: "FRANCE" },
            { n: "DUNMORE EAST",	l: "IRELAND" },
            { n: "DUNOON",	l: "UNITED KINGDOM" },
            { n: "DUQM",	l: "OMAN" },
            { n: "DURAN",	l: "ECUADOR" },
            { n: "DURBAN",	l: "SOUTH AFRICA" },
            { n: "DURBAN-MOMBASA",	l: "SOUTH AFRICA" },
            { n: "DURRES",	l: "ALBANIA" },
            { n: "DUSAVIK",	l: "NORWAY" },
            { n: "DUSHAN",	l: "CHINA" },
            { n: "DUSSELDORF",	l: "GERMANY" },
            { n: "DUTCH HARBOR",	l: "UNITED STATES" },
            { n: "DUYEN HAI SEAPORT",	l: "VIETNAM" },
            { n: "DYNAMIC PRODUCER",	l: "BRAZIL" },
            { n: "DZAOUDZI",	l: "COMOROS" },
            { n: "E MED",	l: "TURKEY" },
            { n: "EA TERMINAL",	l: "NIGERIA" },
            { n: "EAGLE POINT",	l: "UNITED STATES" },
            { n: "EAST BAY",	l: "UNITED STATES" },
            { n: "EAST INTERCOURSE ISLAND",	l: "AUSTRALIA" },
            { n: "EAST LONDON",	l: "SOUTH AFRICA" },
            { n: "EAST PORT SAID",	l: "EGYPT" },
            { n: "EAST SOCOTRA",	l: "YEMEN" },
            { n: "EAST ZEIT TERMINAL",	l: "EGYPT" },
            { n: "EASTER ISLAND",	l: "CHILE" },
            { n: "EASTERN POINT",	l: "YEMEN" },
            { n: "EASTHAM",	l: "UNITED KINGDOM" },
            { n: "EASTPORT",	l: "UNITED STATES" },
            { n: "EBELTOFT",	l: "DENMARK" },
            { n: "EBOK",	l: "NIGERIA" },
            { n: "EBOME MARINE TERMINAL",	l: "CAMEROON" },
            { n: "EC AFRICA",	l: "KENYA" },
            { n: "EC INDIA",	l: "INDIA" },
            { n: "EC ITALY",	l: "ITALY" },
            { n: "EC MEXICO",	l: "MEXICO" },
            { n: "ECA - ANTARCTIC AREA",	l: "ANTARCTICA" },
            { n: "ECA - BOHAI SEA",	l: "CHINA" },
            { n: "ECA - EURO PORTS",	l: "UNITED KINGDOM" },
            { n: "ECA - HONG KONG",	l: "CHINA" },
            { n: "ECA - PEARL RIVER DELTA",	l: "CHINA" },
            { n: "ECA - US 200NM HAWAII",	l: "UNITED STATES" },
            { n: "ECA - US 50NM PUERTO RICO",	l: "PUERTO RICO" },
            { n: "ECA - YANGTZE RIVER DELTA",	l: "CHINA" },
            { n: "ECAN",	l: "CANADA" },
            { n: "ECCLES",	l: "UNITED KINGDOM" },
            { n: "ECKERNFORDE",	l: "GERMANY" },
            { n: "ECOPETROL",	l: "COLOMBIA" },
            { n: "ECUADOR (COUNTRY)",	l: "ECUADOR" },
            { n: "EDDYSTONE",	l: "UNITED STATES" },
            { n: "EDEN",	l: "AUSTRALIA" },
            { n: "EDENTON NC",	l: "UNITED STATES" },
            { n: "EDINBURGH",	l: "UNITED KINGDOM" },
            { n: "EDINCIK",	l: "TURKEY" },
            { n: "EDSTRANDSBASSANGEN",	l: "SWEDEN" },
            { n: "EDWARD'S COVE",	l: "CANADA" },
            { n: "EEMSHAVEN",	l: "NETHERLANDS" },
            { n: "EFESANPORT",	l: "TURKEY" },
            { n: "EGE GUBRE",	l: "TURKEY" },
            { n: "EGEDESMINDE",	l: "GREENLAND" },
            { n: "EGERSUND",	l: "NORWAY" },
            { n: "EGVEKINOT",	l: "RUSSIA" },
            { n: "EGYPT (COUNTRY)",	l: "EGYPT" },
            { n: "EHOALA",	l: "MADAGASCAR" },
            { n: "EIDFJORD",	l: "NORWAY" },
            { n: "EIKEFET",	l: "NORWAY" },
            { n: "EIL",	l: "SOMALIA" },
            { n: "EILAT",	l: "ISRAEL" },
            { n: "EINSWARDEN",	l: "GERMANY" },
            { n: "EISENHOWER LOCK",	l: "UNITED STATES" },
            { n: "EKENAS",	l: "FINLAND" },
            { n: "EKONOMIYA",	l: "RUSSIA" },
            { n: "EL ALAMEIN",	l: "EGYPT" },
            { n: "EL ARAISH",	l: "MOROCCO" },
            { n: "EL ARISH",	l: "EGYPT" },
            { n: "EL BAJO",	l: "VENEZUELA" },
            { n: "EL BLUFF",	l: "NICARAGUA" },
            { n: "EL BOTADO",	l: "DOMINICAN REPUBLIC" },
            { n: "EL CAPITAN",	l: "UNITED STATES" },
            { n: "EL CHAURE",	l: "VENEZUELA" },
            { n: "EL DEKHEILA",	l: "EGYPT" },
            { n: "EL GUAMACHE",	l: "VENEZUELA" },
            { n: "EL JADIDA",	l: "MOROCCO" },
            { n: "EL KHOMS",	l: "LIBYA" },
            { n: "EL MEKS",	l: "EGYPT" },
            { n: "EL PALITO",	l: "VENEZUELA" },
            { n: "EL QUSEIR",	l: "EGYPT" },
            { n: "EL SALVADOR (COUNTRY)",	l: "EL SALVADOR" },
            { n: "EL SEGUNDO TERMINAL",	l: "UNITED STATES" },
            { n: "EL TABLAZO",	l: "VENEZUELA" },
            { n: "ELBA (ITALY)",	l: "ITALY" },
            { n: "ELBA ISLAND",	l: "UNITED STATES" },
            { n: "ELBE OUTER ANCHORAGE",	l: "GERMANY" },
            { n: "ELBEHARBOUR",	l: "GERMANY" },
            { n: "ELEFSIS",	l: "GREECE" },
            { n: "ELEUSIS",	l: "GREECE" },
            { n: "ELIZABETH",	l: "UNITED STATES" },
            { n: "ELIZABETH CITY NC",	l: "UNITED STATES" },
            { n: "ELK END",	l: "UNITED STATES" },
            { n: "ELK START",	l: "UNITED STATES" },
            { n: "ELLESMERE PORT",	l: "UNITED KINGDOM" },
            { n: "ELNESVAAGEN",	l: "NORWAY" },
            { n: "ELSFLETH",	l: "GERMANY" },
            { n: "ELSINORE",	l: "DENMARK" },
            { n: "EMDEN",	l: "GERMANY" },
            { n: "EMIRATES STEEL JETTY",	l: "UNITED ARAB EMIRATES" },
            { n: "EMMAHAVEN",	l: "INDONESIA" },
            { n: "EMMASTAD",	l: "CURACAO" },
            { n: "EMPIRE, LA",	l: "UNITED STATES" },
            { n: "EMU BAY",	l: "AUSTRALIA" },
            { n: "ENCINA",	l: "UNITED STATES" },
            { n: "ENDEH",	l: "INDONESIA" },
            { n: "ENGLISH BAY",	l: "SAINT HELENA" },
            { n: "ENGLISH CHANNEL",	l: "UNITED KINGDOM" },
            { n: "ENGURE",	l: "LATVIA" },
            { n: "ENKOPING",	l: "SWEDEN" },
            { n: "ENNORE",	l: "INDIA" },
            { n: "ENSCO 70",	l: "DENMARK" },
            { n: "ENSENADA",	l: "MEXICO" },
            { n: "ENSTED",	l: "DENMARK" },
            { n: "ENZELI",	l: "IRAN" },
            { n: "EPISCOPI",	l: "CYPRUS" },
            { n: "EQUATORIAL GUINEA (COUNTRY)",	l: "EQUATORIAL GUINEA" },
            { n: "ERAWAN TERMINAL",	l: "THAILAND" },
            { n: "ERDEMIR",	l: "TURKEY" },
            { n: "EREGLI",	l: "TURKEY" },
            { n: "EREGLISI",	l: "TURKEY" },
            { n: "EREN PORT",	l: "TURKEY" },
            { n: "ERETAN",	l: "INDONESIA" },
            { n: "ERHA TERMINAL",	l: "NIGERIA" },
            { n: "ERIE",	l: "UNITED STATES" },
            { n: "ERITH",	l: "UNITED KINGDOM" },
            { n: "ERITREA (COUNTRY)",	l: "ERITREA" },
            { n: "ERMAN",	l: "CHINA" },
            { n: "ERTVELDE",	l: "BELGIUM" },
            { n: "ES SIDER",	l: "LIBYA" },
            { n: "ESBJERG",	l: "DENMARK" },
            { n: "ESCANABA",	l: "UNITED STATES" },
            { n: "ESCOBAR LNG",	l: "ARGENTINA" },
            { n: "ESCOMBRERAS",	l: "SPAIN" },
            { n: "ESCOUMINS",	l: "CANADA" },
            { n: "ESCRAVOS OIL TERMINAL",	l: "NIGERIA" },
            { n: "ESKIFJORDUR",	l: "ICELAND" },
            { n: "ESMERALDAS",	l: "ECUADOR" },
            { n: "ESPERANCE",	l: "AUSTRALIA" },
            { n: "ESPERANZA",	l: "CHILE" },
            { n: "ESPEVIK",	l: "NORWAY" },
            { n: "ESPIRITO SANTO FPSO",	l: "BRAZIL" },
            { n: "ESPIRITU SANTO",	l: "VANUATU" },
            { n: "ESPOIR TERMINAL",	l: "COTE D'IVOIRE" },
            { n: "ESQUIMALT",	l: "CANADA" },
            { n: "ESSAOUIRA",	l: "MOROCCO" },
            { n: "ESSEQUIBO RIVER",	l: "GUYANA" },
            { n: "ESSEXVILLE",	l: "UNITED STATES" },
            { n: "ESSUNGO MARINE TERMINAL",	l: "ANGOLA" },
            { n: "ESTANCIA",	l: "PHILIPPINES" },
            { n: "ESTERO BAY",	l: "UNITED STATES" },
            { n: "ESTONIA (COUNTRY)",	l: "ESTONIA" },
            { n: "ETAJIMA",	l: "JAPAN" },
            { n: "ETAME",	l: "GABON" },
            { n: "ETANG DE BERRE",	l: "FRANCE" },
            { n: "ETEN",	l: "PERU" },
            { n: "ETNE",	l: "NORWAY" },
            { n: "EUFAULA",	l: "UNITED STATES" },
            { n: "EURASIAN INLAND WATERWAYS",	l: "RUSSIA" },
            { n: "EUREKA",	l: "UNITED STATES" },
            { n: "EUROPA POINT",	l: "GIBRALTAR" },
            { n: "EUROPE",	l: "BELGIUM" },
            { n: "EUROPOORT",	l: "NETHERLANDS" },
            { n: "EVANSVILLE",	l: "UNITED STATES" },
            { n: "EVERETT (BOSTON)",	l: "UNITED STATES" },
            { n: "EVERETT (WASHINGTON)",	l: "UNITED STATES" },
            { n: "EVERGLADES",	l: "UNITED STATES" },
            { n: "EVERGLADES, FLORIDA WC",	l: "UNITED STATES" },
            { n: "EVERTON",	l: "GUYANA" },
            { n: "EVPATORIA",	l: "UKRAINE" },
            { n: "EVRY",	l: "FRANCE" },
            { n: "EVYAP",	l: "TURKEY" },
            { n: "EXMOUTH (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "EXMOUTH (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "EXSPAN MARINE TERMINAL",	l: "INDONESIA" },
            { n: "EYDEHAVN",	l: "NORWAY" },
            { n: "EZHOU",	l: "CHINA" },
            { n: "FAABORG",	l: "DENMARK" },
            { n: "FAERINGEHAVN",	l: "GREENLAND" },
            { n: "FAGERSTRAND",	l: "NORWAY" },
            { n: "FAHAHEEL",	l: "KUWAIT" },
            { n: "FAIR ISLE",	l: "UNITED KINGDOM" },
            { n: "FAIRLESS HILLS",	l: "UNITED STATES" },
            { n: "FAIRPORT HARBOR",	l: "UNITED STATES" },
            { n: "FAIRVIEW COVE",	l: "CANADA" },
            { n: "FAJARDO",	l: "PUERTO RICO" },
            { n: "FAK FAK, WEST PAPUA",	l: "INDONESIA" },
            { n: "FAKSE LADEPLADS",	l: "DENMARK" },
            { n: "FALCONARA",	l: "ITALY" },
            { n: "FALKENBERG",	l: "SWEDEN" },
            { n: "FALKLAND ISLANDS (COUNTRY)",	l: "FALKLAND ISLANDS (ISLAS MALVINAS)" },
            { n: "FALL RIVER",	l: "UNITED STATES" },
            { n: "FALMOUTH",	l: "UNITED KINGDOM" },
            { n: "FALMOUTH (JAMAICA)",	l: "JAMAICA" },
            { n: "FALSTERBOKANALEN",	l: "SWEDEN" },
            { n: "FAMAGUSTA",	l: "CYPRUS" },
            { n: "FANARA",	l: "EGYPT" },
            { n: "FANGCHENG",	l: "CHINA" },
            { n: "FANNING ISLANDS",	l: "KIRIBATI" },
            { n: "FAO",	l: "IRAQ" },
            { n: "FARGE",	l: "GERMANY" },
            { n: "FARO & OLHAO",	l: "PORTUGAL" },
            { n: "FAROES (COUNTRY)",	l: "FAROE ISLANDS" },
            { n: "FARSUND",	l: "NORWAY" },
            { n: "FARWAH TERMINAL",	l: "LIBYA" },
            { n: "FASKRUDSFJORDUR",	l: "ICELAND" },
            { n: "FASLANE",	l: "UNITED KINGDOM" },
            { n: "FASTNET ROCK",	l: "IRELAND" },
            { n: "FATEH TERMINAL",	l: "UNITED ARAB EMIRATES" },
            { n: "FATHER POINT",	l: "CANADA" },
            { n: "FATSA",	l: "TURKEY" },
            { n: "FAUJI OIL TERMINAL",	l: "PAKISTAN" },
            { n: "FAWLEY",	l: "UNITED KINGDOM" },
            { n: "FAXE",	l: "DENMARK" },
            { n: "FAYID",	l: "EGYPT" },
            { n: "FAZENDINHA PILOT STATION",	l: "BRAZIL" },
            { n: "FECAMP",	l: "FRANCE" },
            { n: "FEDALA",	l: "MOROCCO" },
            { n: "FEDJE",	l: "NORWAY" },
            { n: "FELIXSTOWE",	l: "UNITED KINGDOM" },
            { n: "FELTON",	l: "CUBA" },
            { n: "FENGCHENG",	l: "CHINA" },
            { n: "FENGJIE",	l: "CHINA" },
            { n: "FENGJING",	l: "CHINA" },
            { n: "FENGMEN",	l: "CHINA" },
            { n: "FENGQIAO",	l: "CHINA" },
            { n: "FENGXIAN",	l: "CHINA" },
            { n: "FENIT",	l: "IRELAND" },
            { n: "FEODOSIA",	l: "UKRAINE" },
            { n: "FERNANDINA",	l: "UNITED STATES" },
            { n: "FERNANDINA BEACH FL",	l: "UNITED STATES" },
            { n: "FERNANDO DE NORONHA",	l: "BRAZIL" },
            { n: "FERNANDO POO",	l: "EQUATORIAL GUINEA" },
            { n: "FERNDALE",	l: "UNITED STATES" },
            { n: "FERROL",	l: "SPAIN" },
            { n: "FETHIYE",	l: "TURKEY" },
            { n: "FIBORGTANGEN",	l: "NORWAY" },
            { n: "FIGUEIRA DA FOZ",	l: "PORTUGAL" },
            { n: "FIJI (COUNTRY)",	l: "FIJI" },
            { n: "FINGRINGHOE",	l: "UNITED KINGDOM" },
            { n: "FINIKE",	l: "TURKEY" },
            { n: "FINLAND (COUNTRY)",	l: "FINLAND" },
            { n: "FINNART",	l: "UNITED KINGDOM" },
            { n: "FINNEID",	l: "NORWAY" },
            { n: "FINNSNES",	l: "NORWAY" },
            { n: "FINUCANE ISLAND",	l: "AUSTRALIA" },
            { n: "FIORUCCI",	l: "URUGUAY" },
            { n: "FISHER HARBOUR",	l: "CANADA" },
            { n: "FISHER ISLAND",	l: "UNITED STATES" },
            { n: "FISHERMAN ISLANDS",	l: "AUSTRALIA" },
            { n: "FISHGUARD",	l: "UNITED KINGDOM" },
            { n: "FISKAA",	l: "NORWAY" },
            { n: "FITZALAN",	l: "AUSTRALIA" },
            { n: "FIUME",	l: "CROATIA" },
            { n: "FIUMICINO",	l: "ITALY" },
            { n: "FIVE FATHOM BANK LIGHT",	l: "UNITED STATES" },
            { n: "FJELLDAL",	l: "NORWAY" },
            { n: "FLAAM",	l: "NORWAY" },
            { n: "FLAXENVIK",	l: "SWEDEN" },
            { n: "FLEETWOOD",	l: "UNITED KINGDOM" },
            { n: "FLEKKEFJORD",	l: "NORWAY" },
            { n: "FLENSBURG",	l: "GERMANY" },
            { n: "FLINT END",	l: "UNITED STATES" },
            { n: "FLINT START",	l: "UNITED STATES" },
            { n: "FLIXBOROUGH WHARF",	l: "UNITED KINGDOM" },
            { n: "FLORENCE (FIRENZE)",	l: "ITALY" },
            { n: "FLORO",	l: "NORWAY" },
            { n: "FLOTTA TERMINAL",	l: "UNITED KINGDOM" },
            { n: "FLUMINENSE TERMINAL",	l: "BRAZIL" },
            { n: "FLUSHING",	l: "NETHERLANDS" },
            { n: "FOLKESTONE",	l: "UNITED KINGDOM" },
            { n: "FOLLONICA",	l: "ITALY" },
            { n: "FOMBONI",	l: "COMOROS" },
            { n: "FOND MOMBIN",	l: "HAITI" },
            { n: "FOOCHOW",	l: "CHINA" },
            { n: "FORCADOS OIL TERMINAL",	l: "NIGERIA" },
            { n: "FORDE",	l: "NORWAY" },
            { n: "FORESTVILLE",	l: "CANADA" },
            { n: "FORMIA",	l: "ITALY" },
            { n: "FORNO",	l: "BRAZIL" },
            { n: "FORON BAY",	l: "GREECE" },
            { n: "FORT CHIMO",	l: "CANADA" },
            { n: "FORT DAUPHIN",	l: "MADAGASCAR" },
            { n: "FORT LAUDERDALE",	l: "UNITED STATES" },
            { n: "FORT LIBERTE",	l: "HAITI" },
            { n: "FORT PIERCE",	l: "UNITED STATES" },
            { n: "FORTALEZA",	l: "BRAZIL" },
            { n: "FORT-DE-FRANCE",	l: "MARTINIQUE" },
            { n: "FORTH PORTS",	l: "UNITED KINGDOM" },
            { n: "FORUS",	l: "NORWAY" },
            { n: "FOS",	l: "FRANCE" },
            { n: "FOS CAVAOU",	l: "FRANCE" },
            { n: "FOSDYKE",	l: "UNITED KINGDOM" },
            { n: "FOSHAN",	l: "CHINA" },
            { n: "FOURCHON",	l: "UNITED STATES" },
            { n: "FOWEY",	l: "UNITED KINGDOM" },
            { n: "FOYNES",	l: "IRELAND" },
            { n: "FPSO ANGRA DOS REIS",	l: "BRAZIL" },
            { n: "FPSO BERTAM",	l: "MALAYSIA" },
            { n: "FPSO BRASIL",	l: "BRAZIL" },
            { n: "FPSO CAPIXABA",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE ANCHIETA",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE ILHABELA",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE ITAGUI",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE ITAJAI",	l: "AUSTRALIA" },
            { n: "FPSO CIDADE DE MACAE",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE MANGARATIBA",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE MARICA",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE PARATY",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE SANTOS",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE SAO PAULO",	l: "BRAZIL" },
            { n: "FPSO CIDADE DE VITORIA",	l: "BRAZIL" },
            { n: "FPSO CIDADE DO RIO DE JANEIRO",	l: "BRAZIL" },
            { n: "FPSO CUULONG MV9",	l: "VIETNAM" },
            { n: "FPSO DYNAMIC PRODUCER",	l: "BRAZIL" },
            { n: "FPSO ESPADARTE",	l: "BRAZIL" },
            { n: "FPSO FLUMINENSE",	l: "BRAZIL" },
            { n: "FPSO FRADE",	l: "BRAZIL" },
            { n: "FPSO HAI YANG SHI YOU 111",	l: "CHINA" },
            { n: "FPSO HAI YANG SHI YOU 112",	l: "CHINA" },
            { n: "FPSO MARLIM SUL",	l: "BRAZIL" },
            { n: "FPSO MONTARA VENTURE",	l: "AUSTRALIA" },
            { n: "FPSO NITEROI",	l: "BRAZIL" },
            { n: "FPSO OSX1",	l: "BRAZIL" },
            { n: "FPSO OSX3",	l: "BRAZIL" },
            { n: "FPSO P-31",	l: "BRAZIL" },
            { n: "FPSO P-32",	l: "BRAZIL" },
            { n: "FPSO P-33",	l: "BRAZIL" },
            { n: "FPSO P-34",	l: "BRAZIL" },
            { n: "FPSO P-35",	l: "BRAZIL" },
            { n: "FPSO P-37",	l: "BRAZIL" },
            { n: "FPSO P-38",	l: "BRAZIL" },
            { n: "FPSO P-43",	l: "BRAZIL" },
            { n: "FPSO P-47",	l: "BRAZIL" },
            { n: "FPSO P-48",	l: "BRAZIL" },
            { n: "FPSO P-50",	l: "BRAZIL" },
            { n: "FPSO P-53",	l: "BRAZIL" },
            { n: "FPSO P-54",	l: "BRAZIL" },
            { n: "FPSO P-57",	l: "BRAZIL" },
            { n: "FPSO P-58",	l: "BRAZIL" },
            { n: "FPSO P62",	l: "BRAZIL" },
            { n: "FPSO P-63 PAPA TERRA",	l: "BRAZIL" },
            { n: "FPSO P92",	l: "BRAZIL" },
            { n: "FPSO PIRANEMA",	l: "BRAZIL" },
            { n: "FPSO PSVM TERMINAL",	l: "ANGOLA" },
            { n: "FPSO RANG DONG I",	l: "VIETNAM" },
            { n: "FPSO RIO DAS OSTRAS",	l: "BRAZIL" },
            { n: "FPSO RIO DE JANEIRO",	l: "BRAZIL" },
            { n: "FPSO SANTOS",	l: "BRAZIL" },
            { n: "FPSO SAO MATEUS",	l: "BRAZIL" },
            { n: "FPSO SAO VICENTE",	l: "BRAZIL" },
            { n: "FPSO SEA EAGLE",	l: "NIGERIA" },
            { n: "FRADE TERMINAL FPSO",	l: "BRAZIL" },
            { n: "FRANCE (COUNTRY)",	l: "FRANCE" },
            { n: "FRANCE ATLANTIC",	l: "FRANCE" },
            { n: "FRANCE MED",	l: "FRANCE" },
            { n: "FRANKFURT",	l: "GERMANY" },
            { n: "FRASER PORT",	l: "CANADA" },
            { n: "FRASERBURGH",	l: "UNITED KINGDOM" },
            { n: "FRAY BENTOS",	l: "URUGUAY" },
            { n: "FREDERICIA",	l: "DENMARK" },
            { n: "FREDERIKSHAAB",	l: "GREENLAND" },
            { n: "FREDERIKSHAMN",	l: "FINLAND" },
            { n: "FREDERIKSHAVN",	l: "DENMARK" },
            { n: "FREDERIKSSUND",	l: "DENMARK" },
            { n: "FREDERIKSTED",	l: "VIRGIN ISLANDS" },
            { n: "FREDERIKSVAERK",	l: "DENMARK" },
            { n: "FREDRIKSSKANS",	l: "SWEDEN" },
            { n: "FREDRIKSTAD",	l: "NORWAY" },
            { n: "FREEPORT (BAHAMAS)",	l: "THE BAHAMAS" },
            { n: "FREEPORT (BERMUDA)",	l: "BERMUDA" },
            { n: "FREEPORT (USA)",	l: "UNITED STATES" },
            { n: "FREEPORT (USA) LZ",	l: "UNITED STATES" },
            { n: "FREEPORT (USA) LZ2",	l: "UNITED STATES" },
            { n: "FREETOWN",	l: "SIERRA LEONE" },
            { n: "FREMANTLE",	l: "AUSTRALIA" },
            { n: "FRENCH GUIANA (COUNTRY)",	l: "FRENCH GUIANA" },
            { n: "FRENCH POLYNESIA (COUNTRY)",	l: "FRENCH POLYNESIA" },
            { n: "FRIHAMNEN",	l: "SWEDEN" },
            { n: "FROBISHER BAY",	l: "CANADA" },
            { n: "FROLAND",	l: "SWEDEN" },
            { n: "FRONTERA",	l: "MEXICO" },
            { n: "FROZEN AND ALDERS CAY (BERRY ISLANDS)",	l: "THE BAHAMAS" },
            { n: "FSO AVARE",	l: "BRAZIL" },
            { n: "FSO INTAN",	l: "MALAYSIA" },
            { n: "FSO LIBERDADE",	l: "TIMOR-LESTE" },
            { n: "FSO MACAE",	l: "BRAZIL" },
            { n: "FSO TULJA",	l: "NIGERIA" },
            { n: "FUAN",	l: "CHINA" },
            { n: "FUGLAFJORDUR",	l: "FAROE ISLANDS" },
            { n: "FUIK BAY",	l: "CURACAO" },
            { n: "FUJAIRAH",	l: "UNITED ARAB EMIRATES" },
            { n: "FUJIAN TERMINAL",	l: "CHINA" },
            { n: "FUJIN",	l: "CHINA" },
            { n: "FUKUI",	l: "JAPAN" },
            { n: "FUKUOKA",	l: "JAPAN" },
            { n: "FUKUYAMA",	l: "JAPAN" },
            { n: "FULING",	l: "CHINA" },
            { n: "FUNABASHI",	l: "JAPAN" },
            { n: "FUNAFUTI",	l: "TUVALU" },
            { n: "FUNAKAWA",	l: "JAPAN" },
            { n: "FUNCHAL",	l: "PORTUGAL" },
            { n: "FUSA",	l: "NORWAY" },
            { n: "FUSHIKI",	l: "JAPAN" },
            { n: "FUSINA",	l: "ITALY" },
            { n: "FUTILA TERMINAL",	l: "ANGOLA" },
            { n: "FUTONG",	l: "INDONESIA" },
            { n: "FUTONG BOARDING",	l: "INDONESIA" },
            { n: "FUTTSU",	l: "JAPAN" },
            { n: "FUTUNA ISLAND",	l: "WALLIS AND FUTUNA" },
            { n: "FUZHOU",	l: "CHINA" },
            { n: "GABES",	l: "TUNISIA" },
            { n: "GABION",	l: "INDONESIA" },
            { n: "GABON (COUNTRY)",	l: "GABON" },
            { n: "GAETA",	l: "ITALY" },
            { n: "GAINSBOROUGH",	l: "UNITED KINGDOM" },
            { n: "GALATZ",	l: "ROMANIA" },
            { n: "GALENA PARK",	l: "UNITED STATES" },
            { n: "GALEOTA POINT",	l: "TRINIDAD AND TOBAGO" },
            { n: "GALLE",	l: "SRI LANKA" },
            { n: "GALLIPOLI (ITALY)",	l: "ITALY" },
            { n: "GALLIPOLI (TURKEY)",	l: "TURKEY" },
            { n: "GALOA",	l: "FIJI" },
            { n: "GALVESTON",	l: "UNITED STATES" },
            { n: "GALVESTON BAY ENTRANCE",	l: "UNITED STATES" },
            { n: "GALVESTON EXXON LIGHTERING AREA",	l: "UNITED STATES" },
            { n: "GALVESTON LIGHT 1",	l: "UNITED STATES" },
            { n: "GALVESTON LIGHT 2",	l: "UNITED STATES" },
            { n: "GALVESTON NO 1 LZ",	l: "UNITED STATES" },
            { n: "GALVESTON NO 2 LZ",	l: "UNITED STATES" },
            { n: "GALWAY",	l: "IRELAND" },
            { n: "GAMAGORI",	l: "JAPAN" },
            { n: "GAMBA",	l: "GABON" },
            { n: "GAMBIA (COUNTRY)",	l: "THE GAMBIA" },
            { n: "GAMBOA",	l: "PANAMA" },
            { n: "GAMLAKARLEBY",	l: "FINLAND" },
            { n: "GAN ISLAND",	l: "MALDIVES" },
            { n: "GANCHONG",	l: "CHINA" },
            { n: "GAND",	l: "BELGIUM" },
            { n: "GANDIA",	l: "SPAIN" },
            { n: "GANGAVARAM",	l: "INDIA" },
            { n: "GANJINGZI",	l: "CHINA" },
            { n: "GANYU",	l: "CHINA" },
            { n: "GANZHOU",	l: "CHINA" },
            { n: "GANZIYUAN",	l: "CHINA" },
            { n: "GAOGANG",	l: "CHINA" },
            { n: "GAOLAN",	l: "CHINA" },
            { n: "GAOQIAO",	l: "CHINA" },
            { n: "GARCIA HERNANDEZ",	l: "PHILIPPINES" },
            { n: "GARFIELD",	l: "UNITED STATES" },
            { n: "GARLIESTON",	l: "UNITED KINGDOM" },
            { n: "GARONGKONG",	l: "INDONESIA" },
            { n: "GAROUA",	l: "CAMEROON" },
            { n: "GARRUCHA",	l: "SPAIN" },
            { n: "GARSTON",	l: "UNITED KINGDOM" },
            { n: "GARY",	l: "UNITED STATES" },
            { n: "GARYVILLE",	l: "UNITED STATES" },
            { n: "GASPAR STRAIT",	l: "INDONESIA" },
            { n: "GASPE",	l: "CANADA" },
            { n: "GASTGIVAREHAGEN",	l: "SWEDEN" },
            { n: "GATUN",	l: "PANAMA" },
            { n: "GAVLE",	l: "SWEDEN" },
            { n: "GAVRION",	l: "GREECE" },
            { n: "GAZENICA",	l: "CROATIA" },
            { n: "GDANSK",	l: "POLAND" },
            { n: "GDYNIA",	l: "POLAND" },
            { n: "GEBE ISLAND",	l: "INDONESIA" },
            { n: "GEBIG",	l: "BRAZIL" },
            { n: "GEBZE",	l: "TURKEY" },
            { n: "GEDSER",	l: "DENMARK" },
            { n: "GEE ISLAND",	l: "INDONESIA" },
            { n: "GEEL",	l: "BELGIUM" },
            { n: "GEELONG",	l: "AUSTRALIA" },
            { n: "GEFLE",	l: "SWEDEN" },
            { n: "GEIRANGER",	l: "NORWAY" },
            { n: "GEISMAR",	l: "UNITED STATES" },
            { n: "GEISUM TERMINAL",	l: "EGYPT" },
            { n: "GELA",	l: "ITALY" },
            { n: "GELENDZHGIC",	l: "RUSSIA" },
            { n: "GELIBOLU",	l: "TURKEY" },
            { n: "GEMAK GEMI INSA",	l: "TURKEY" },
            { n: "GEMIKONAGI",	l: "CYPRUS" },
            { n: "GEMLIK",	l: "TURKEY" },
            { n: "GENERAL LAGOS",	l: "ARGENTINA" },
            { n: "GENERAL SAN MARTIN",	l: "PERU" },
            { n: "GENERAL SANTOS",	l: "PHILIPPINES" },
            { n: "GENNEVILLIERS",	l: "FRANCE" },
            { n: "GENOA",	l: "ITALY" },
            { n: "GEOJE",	l: "KOREA, REPUBLIC" },
            { n: "GEORGE TOWN",	l: "AUSTRALIA" },
            { n: "GEORGETOWN (ASCENSION)",	l: "SAINT HELENA" },
            { n: "GEORGETOWN (CANADA)",	l: "CANADA" },
            { n: "GEORGETOWN (CAYMAN IS)",	l: "CAYMAN ISLANDS" },
            { n: "GEORGETOWN (GUYANA)",	l: "GUYANA" },
            { n: "GEORGETOWN (USA)",	l: "UNITED STATES" },
            { n: "GEORGETOWN SC",	l: "UNITED STATES" },
            { n: "GEORGIA (COUNTRY)",	l: "GEORGIA" },
            { n: "GERA BAY",	l: "GREECE" },
            { n: "GERAGAI",	l: "INDONESIA" },
            { n: "GERALDTON",	l: "AUSTRALIA" },
            { n: "GERMANY (COUNTRY)",	l: "GERMANY" },
            { n: "GHANA (COUNTRY)",	l: "GHANA" },
            { n: "GHAZAOUET",	l: "ALGERIA" },
            { n: "GHENICHESK",	l: "UKRAINE" },
            { n: "GHENT",	l: "BELGIUM" },
            { n: "GHENT-HBG",	l: "BELGIUM" },
            { n: "GIARDINI",	l: "ITALY" },
            { n: "GIB-HBG",	l: "GIBRALTAR" },
            { n: "GIBRALTAR",	l: "GIBRALTAR" },
            { n: "GIBRALTAR",	l: "GIBRALTAR" },
            { n: "GIBRALTAR (COUNTRY)",	l: "GIBRALTAR" },
            { n: "GIB-SKAW",	l: "GIBRALTAR" },
            { n: "GIJON",	l: "SPAIN" },
            { n: "GILIMANUK",	l: "INDONESIA" },
            { n: "GIMBOA TERMINAL",	l: "ANGOLA" },
            { n: "GINGOOG",	l: "PHILIPPINES" },
            { n: "GIOIA TAURO",	l: "ITALY" },
            { n: "GIRARD POINT",	l: "UNITED STATES" },
            { n: "GIRASOL TERMINAL",	l: "ANGOLA" },
            { n: "GIRASSOL",	l: "ANGOLA" },
            { n: "GIRESUN",	l: "TURKEY" },
            { n: "GIRGENTI",	l: "ITALY" },
            { n: "GIRVAN",	l: "UNITED KINGDOM" },
            { n: "GISBORNE",	l: "NEW ZEALAND" },
            { n: "GISMEROY",	l: "NORWAY" },
            { n: "GIURGIU",	l: "ROMANIA" },
            { n: "GIURGIULESTI",	l: "MOLDOVA" },
            { n: "GIWW EAST END",	l: "UNITED STATES" },
            { n: "GIWW EAST START",	l: "UNITED STATES" },
            { n: "GIWW WEST AND ATCHAFALAYA",	l: "UNITED STATES" },
            { n: "GIWW WEST END",	l: "UNITED STATES" },
            { n: "GIWW WEST START",	l: "UNITED STATES" },
            { n: "GIWW-W AND ALGIERS CANAL",	l: "UNITED STATES" },
            { n: "GIWW-W AND ARANSAS CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND ARROYO COLORADO RIVER",	l: "UNITED STATES" },
            { n: "GIWW-W AND CALCASIEU RIVER",	l: "UNITED STATES" },
            { n: "GIWW-W AND CHOCOLATE BAYOU CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND COLORADO RIVER",	l: "UNITED STATES" },
            { n: "GIWW-W AND CORPUS CHRISTI SHIP CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND GALVESTON CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND HOUSTON SHIP CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND LYDIA ANN CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND MATAGORDA CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND MERMENTAU RIVER",	l: "UNITED STATES" },
            { n: "GIWW-W AND NECHES RIVER",	l: "UNITED STATES" },
            { n: "GIWW-W AND SABINE RIVER",	l: "UNITED STATES" },
            { n: "GIWW-W AND SAN BERNARD RIVER",	l: "UNITED STATES" },
            { n: "GIWW-W AND TEXAS CITY CHANNEL",	l: "UNITED STATES" },
            { n: "GIWW-W AND VICTORIA BARGE CHANNEL",	l: "UNITED STATES" },
            { n: "GIZAN",	l: "SAUDI ARABIA" },
            { n: "GIZO",	l: "SOLOMON ISLANDS" },
            { n: "GJOA HAVEN, NU",	l: "CANADA" },
            { n: "GLADSTONE",	l: "AUSTRALIA" },
            { n: "GLADSTONE (UNITED STATES)",	l: "UNITED STATES" },
            { n: "GLASGOW",	l: "UNITED KINGDOM" },
            { n: "GLEBE ISLAND",	l: "AUSTRALIA" },
            { n: "GLEN DALE",	l: "UNITED STATES" },
            { n: "GLENSANDA",	l: "UNITED KINGDOM" },
            { n: "GLOMFJORD",	l: "NORWAY" },
            { n: "GLOUCESTER (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "GLOUCESTER (USA-MA)",	l: "UNITED STATES" },
            { n: "GLOUCESTER (USA-NJ)",	l: "UNITED STATES" },
            { n: "GLUCKSTADT",	l: "GERMANY" },
            { n: "GLWP0",	l: "CANADA" },
            { n: "GLWP1",	l: "CANADA" },
            { n: "GLWP2",	l: "CANADA" },
            { n: "GO DAU",	l: "VIETNAM" },
            { n: "GOA",	l: "INDIA" },
            { n: "GOCEK",	l: "TURKEY" },
            { n: "GODBOUT",	l: "CANADA" },
            { n: "GODERICH",	l: "CANADA" },
            { n: "GODERICH PIERS",	l: "CANADA" },
            { n: "GODHAVN",	l: "GREENLAND" },
            { n: "GODTHAAB",	l: "GREENLAND" },
            { n: "GOETA ALV-TROLLHAATE KAN",	l: "SWEDEN" },
            { n: "GOGLAND ISLAND",	l: "FINLAND" },
            { n: "GOI",	l: "JAPAN" },
            { n: "GOLCUK",	l: "TURKEY" },
            { n: "GOLD RIVER",	l: "CANADA" },
            { n: "GOLFITO",	l: "COSTA RICA" },
            { n: "GOLFO DULCE",	l: "COSTA RICA" },
            { n: "GOLFO SAN MATIAS",	l: "ARGENTINA" },
            { n: "GOLIAT",	l: "NORWAY" },
            { n: "GOLOVASI",	l: "TURKEY" },
            { n: "GONAIVES",	l: "HAITI" },
            { n: "GONFREVILLE L'ORCHER",	l: "FRANCE" },
            { n: "GOOD HOPE",	l: "UNITED STATES" },
            { n: "GOOD'S ISLAND",	l: "AUSTRALIA" },
            { n: "GOOLE",	l: "UNITED KINGDOM" },
            { n: "GOOSE BAY",	l: "CANADA" },
            { n: "GOPALPUR",	l: "INDIA" },
            { n: "GORE BAY",	l: "AUSTRALIA" },
            { n: "GORELE",	l: "TURKEY" },
            { n: "GORO",	l: "NEW CALEDONIA" },
            { n: "GORONTALO",	l: "INDONESIA" },
            { n: "GOSEONG",	l: "KOREA, REPUBLIC" },
            { n: "GOSPORT",	l: "UNITED KINGDOM" },
            { n: "GOTA CANAL",	l: "SWEDEN" },
            { n: "GOTEBORG",	l: "SWEDEN" },
            { n: "GOTHENBURG",	l: "SWEDEN" },
            { n: "GOTHENBURG ROADS",	l: "SWEDEN" },
            { n: "GOTO",	l: "BONAIRE" },
            { n: "GOUROCK",	l: "UNITED KINGDOM" },
            { n: "GOVE",	l: "AUSTRALIA" },
            { n: "GOVERNOR'S ISLAND",	l: "BRAZIL" },
            { n: "GRAASTEN",	l: "DENMARK" },
            { n: "GRAMERCY",	l: "UNITED STATES" },
            { n: "GRAND BANK",	l: "CANADA" },
            { n: "GRAND BANKS SOUTH",	l: "UNITED STATES" },
            { n: "GRAND HAVEN",	l: "UNITED STATES" },
            { n: "GRAND MAL TANKER TERMINAL",	l: "GRENADA" },
            { n: "GRAND TURK PORT",	l: "TURKS AND CAICOS ISLANDS" },
            { n: "GRANDE ANSE",	l: "CANADA" },
            { n: "GRANDE ENTREE",	l: "CANADA" },
            { n: "GRANGEMOUTH",	l: "UNITED KINGDOM" },
            { n: "GRANTON",	l: "UNITED KINGDOM" },
            { n: "GRANUDDEN",	l: "SWEDEN" },
            { n: "GRANVILLE",	l: "FRANCE" },
            { n: "GRATI",	l: "INDONESIA" },
            { n: "GRAVDAL",	l: "NORWAY" },
            { n: "GRAVELINES",	l: "FRANCE" },
            { n: "GRAVENCHON",	l: "FRANCE" },
            { n: "GRAVESEND",	l: "UNITED KINGDOM" },
            { n: "GRAVOSA",	l: "CROATIA" },
            { n: "GRAYS",	l: "UNITED KINGDOM" },
            { n: "GRAYS HARBOR",	l: "UNITED STATES" },
            { n: "GRAYS REEF LIGHT",	l: "UNITED STATES" },
            { n: "GREAKER",	l: "NORWAY" },
            { n: "GREAT BARRIER REEF",	l: "AUSTRALIA" },
            { n: "GREAT BELT",	l: "DENMARK" },
            { n: "GREAT ISAAC ISLAND",	l: "THE BAHAMAS" },
            { n: "GREAT STIRRUP CAY",	l: "THE BAHAMAS" },
            { n: "GREAT YARMOUTH",	l: "UNITED KINGDOM" },
            { n: "GREECE (COUNTRY)",	l: "GREECE" },
            { n: "GREEN BAY",	l: "UNITED STATES" },
            { n: "GREEN END",	l: "UNITED STATES" },
            { n: "GREEN START",	l: "UNITED STATES" },
            { n: "GREENLAND (COUNTRY)",	l: "GREENLAND" },
            { n: "GREENOCK",	l: "UNITED KINGDOM" },
            { n: "GREENORE",	l: "IRELAND" },
            { n: "GREENPORT HARBOR",	l: "UNITED STATES" },
            { n: "GREENS BAYOU",	l: "UNITED STATES" },
            { n: "GREENVILLE",	l: "LIBERIA" },
            { n: "GREENVILLE (USA)",	l: "UNITED STATES" },
            { n: "GREENWICH",	l: "UNITED KINGDOM" },
            { n: "GREENWICH POINT",	l: "UNITED STATES" },
            { n: "GREGORIO",	l: "CHILE" },
            { n: "GREIFSWALD",	l: "GERMANY" },
            { n: "GRENAA",	l: "DENMARK" },
            { n: "GRENADA (COUNTRY)",	l: "GRENADA" },
            { n: "GRENIVIK",	l: "ICELAND" },
            { n: "GRENLAND HARBOUR",	l: "NORWAY" },
            { n: "GRENVILLE CHANNEL",	l: "CANADA" },
            { n: "GRESIK",	l: "INDONESIA" },
            { n: "GRETNA",	l: "UNITED STATES" },
            { n: "GREYMOUTH",	l: "NEW ZEALAND" },
            { n: "GRIFFIN VENTURE TERMINAL",	l: "AUSTRALIA" },
            { n: "GRIMSBY",	l: "UNITED KINGDOM" },
            { n: "GRIMSTAD",	l: "NORWAY" },
            { n: "GRINDAVIK",	l: "ICELAND" },
            { n: "GRIP",	l: "NORWAY" },
            { n: "GRISE FIORD, NU",	l: "CANADA" },
            { n: "GRISSIK TERMINAL",	l: "INDONESIA" },
            { n: "GRONINGEN",	l: "NETHERLANDS" },
            { n: "GRONNEDAL",	l: "GREENLAND" },
            { n: "GROOTE EYLANDT",	l: "AUSTRALIA" },
            { n: "GROS CACOUNA",	l: "CANADA" },
            { n: "GROS CAP LIGHT",	l: "UNITED STATES" },
            { n: "GROTON",	l: "UNITED STATES" },
            { n: "GRUNDARFJORDUR",	l: "ICELAND" },
            { n: "GRUNDARTANGI",	l: "ICELAND" },
            { n: "GRUZ",	l: "CROATIA" },
            { n: "GRYTVIKEN",	l: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
            { n: "GUACOLDA",	l: "CHILE" },
            { n: "GUADELOUPE (COUNTRY)",	l: "GUADELOUPE" },
            { n: "GUAIBA ISLAND TERMINAL",	l: "BRAZIL" },
            { n: "GUAM",	l: "GUAM" },
            { n: "GUAM (COUNTRY)",	l: "GUAM" },
            { n: "GUAMARE OIL TERMINAL",	l: "BRAZIL" },
            { n: "GUANABARA BAY",	l: "BRAZIL" },
            { n: "GUANGDONG",	l: "CHINA" },
            { n: "GUANGZHOU",	l: "CHINA" },
            { n: "GUANICA",	l: "PUERTO RICO" },
            { n: "GUANTA",	l: "VENEZUELA" },
            { n: "GUANTANAMO",	l: "CUBA" },
            { n: "GUARAGUAO",	l: "VENEZUELA" },
            { n: "GUARANAO",	l: "VENEZUELA" },
            { n: "GUARD POINT",	l: "ERITREA" },
            { n: "GUATEMALA (COUNTRY)",	l: "GUATEMALA" },
            { n: "GUAYABAL",	l: "CUBA" },
            { n: "GUAYACAN",	l: "CHILE" },
            { n: "GUAYANILLA",	l: "PUERTO RICO" },
            { n: "GUAYAQUIL",	l: "ECUADOR" },
            { n: "GUAYMAS",	l: "MEXICO" },
            { n: "GUDVANGEN",	l: "NORWAY" },
            { n: "GUELDJUK",	l: "TURKEY" },
            { n: "GUIGANG",	l: "CHINA" },
            { n: "GUIHULNGAN",	l: "PHILIPPINES" },
            { n: "GUIJIAOWEI",	l: "CHINA" },
            { n: "GUINEA (COUNTRY)",	l: "GUINEA" },
            { n: "GUINEA-BISSAU (COUNTRY)",	l: "GUINEA-BISSAU" },
            { n: "GUIRIA",	l: "VENEZUELA" },
            { n: "GUISHAN ANCHORAGE",	l: "CHINA" },
            { n: "GULDBORGSUND",	l: "DENMARK" },
            { n: "GULEI",	l: "CHINA" },
            { n: "GULEI PORT YIDE TERMINAL",	l: "CHINA" },
            { n: "GULEN",	l: "NORWAY" },
            { n: "GULF GATEWAY",	l: "UNITED STATES" },
            { n: "GULF OF ADEN",	l: "SAUDI ARABIA" },
            { n: "GULF OF MEXICO (MOBILE/PASCAGOULA)",	l: "MEXICO" },
            { n: "GULF OF MEXICO (SOUTHWEST PASS)",	l: "MEXICO" },
            { n: "GULF OF MEXICO DRIFTING AREA",	l: "UNITED STATES" },
            { n: "GULFHAVN",	l: "DENMARK" },
            { n: "GULFMEX LIGHTERING AREA",	l: "UNITED STATES" },
            { n: "GULFMEX NO 2 LZ",	l: "UNITED STATES" },
            { n: "GULFMEX NO. 1",	l: "UNITED STATES" },
            { n: "GULFPORT (MISSISSIPPI)",	l: "UNITED STATES" },
            { n: "GULFPORT (NEW YORK)",	l: "UNITED STATES" },
            { n: "GULLFAKS",	l: "NORWAY" },
            { n: "GULLUK",	l: "TURKEY" },
            { n: "GUNNESS",	l: "UNITED KINGDOM" },
            { n: "GUNTERSVILLE",	l: "UNITED STATES" },
            { n: "GUNUNG SITOLI",	l: "INDONESIA" },
            { n: "GUSTAVIA",	l: "GUADELOUPE" },
            { n: "GUSTAVUS",	l: "UNITED STATES" },
            { n: "GUYANA (COUNTRY)",	l: "GUYANA" },
            { n: "GWADAR",	l: "PAKISTAN" },
            { n: "GWANGYANG",	l: "KOREA, REPUBLIC" },
            { n: "GYTHION",	l: "GREECE" },
            { n: "HAAKONINLAHTI",	l: "FINLAND" },
            { n: "HAAVIK",	l: "NORWAY" },
            { n: "HABANA",	l: "CUBA" },
            { n: "HABAS",	l: "TURKEY" },
            { n: "HACHINOHE",	l: "JAPAN" },
            { n: "HADERA",	l: "ISRAEL" },
            { n: "HADERSLEV",	l: "DENMARK" },
            { n: "HADONG",	l: "KOREA, REPUBLIC" },
            { n: "HADSUND",	l: "DENMARK" },
            { n: "HAEJU",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "HAFNARFJORDUR",	l: "ICELAND" },
            { n: "HAFUN",	l: "SOMALIA" },
            { n: "HAGI",	l: "JAPAN" },
            { n: "HAGU",	l: "INDONESIA" },
            { n: "HAIAN",	l: "CHINA" },
            { n: "HAIAN (JIANGSU PROVINCE)",	l: "CHINA" },
            { n: "HAIFA",	l: "ISRAEL" },
            { n: "HAIKOU",	l: "CHINA" },
            { n: "HAILI",	l: "CHINA" },
            { n: "HAIMEN",	l: "CHINA" },
            { n: "HAIMEN, GUANGDONG",	l: "CHINA" },
            { n: "HAINES",	l: "UNITED STATES" },
            { n: "HAIPHONG",	l: "VIETNAM" },
            { n: "HAITENG TERMINAL",	l: "CHINA" },
            { n: "HAITI (COUNTRY)",	l: "HAITI" },
            { n: "HAIYAN",	l: "CHINA" },
            { n: "HAIYANG",	l: "CHINA" },
            { n: "HAIZHOU",	l: "CHINA" },
            { n: "HAKATA",	l: "JAPAN" },
            { n: "HAKODATE",	l: "JAPAN" },
            { n: "HALDEN",	l: "NORWAY" },
            { n: "HALDIA",	l: "INDIA" },
            { n: "HALF MOON CAY",	l: "THE BAHAMAS" },
            { n: "HALIFAX",	l: "CANADA" },
            { n: "HALKIS",	l: "GREECE" },
            { n: "HALL BEACH, NU",	l: "CANADA" },
            { n: "HALLSTAVIK",	l: "SWEDEN" },
            { n: "HALMSTAD",	l: "SWEDEN" },
            { n: "HALONG",	l: "VIETNAM" },
            { n: "HALSA",	l: "NORWAY" },
            { n: "HALSVIK",	l: "NORWAY" },
            { n: "HALUL ISLAND",	l: "QATAR" },
            { n: "HALVORSHAVN",	l: "NORWAY" },
            { n: "HAMAD",	l: "QATAR" },
            { n: "HAMADA",	l: "JAPAN" },
            { n: "HAMBANTOTA",	l: "SRI LANKA" },
            { n: "HAMBANTOTA",	l: "SRI LANKA" },
            { n: "HAMBLE",	l: "UNITED KINGDOM" },
            { n: "HAMBURG",	l: "GERMANY" },
            { n: "HAMILTON (BERMUDA)",	l: "BERMUDA" },
            { n: "HAMILTON (CANADA)",	l: "CANADA" },
            { n: "HAMINA",	l: "FINLAND" },
            { n: "HAMMARBYHAMNEN",	l: "SWEDEN" },
            { n: "HAMMERFALL",	l: "NORWAY" },
            { n: "HAMMERFEST",	l: "NORWAY" },
            { n: "HAMMERHAVN",	l: "DENMARK" },
            { n: "HAMPTON ROADS",	l: "UNITED STATES" },
            { n: "HAMRAWEIN",	l: "EGYPT" },
            { n: "HAMRIYAH",	l: "UNITED ARAB EMIRATES" },
            { n: "HAMRIYAH LPG TERMINAL",	l: "UNITED ARAB EMIRATES" },
            { n: "HAMRIYAH OIL TERMINAL",	l: "UNITED ARAB EMIRATES" },
            { n: "HANCOCK",	l: "UNITED STATES" },
            { n: "HANDI",	l: "CHINA" },
            { n: "HANGA-PIKO",	l: "CHILE" },
            { n: "HANGCHOW",	l: "CHINA" },
            { n: "HANGO",	l: "CHINA" },
            { n: "HANGTOU",	l: "CHINA" },
            { n: "HANGZHOU",	l: "CHINA" },
            { n: "HANKO",	l: "FINLAND" },
            { n: "HANKOW",	l: "CHINA" },
            { n: "HANNAN",	l: "JAPAN" },
            { n: "HANSTHOLM",	l: "DENMARK" },
            { n: "HANSWEERT",	l: "NETHERLANDS" },
            { n: "HANTSPORT",	l: "CANADA" },
            { n: "HAPARANDA",	l: "SWEDEN" },
            { n: "HAPONNIEMI",	l: "FINLAND" },
            { n: "HARAMACHI",	l: "JAPAN" },
            { n: "HARAMIDERE",	l: "TURKEY" },
            { n: "HARBIN",	l: "CHINA" },
            { n: "HARBOR ISLAND",	l: "UNITED STATES" },
            { n: "HARBOUR BEACH",	l: "UNITED STATES" },
            { n: "HARBOUR BRETON",	l: "CANADA" },
            { n: "HARBOUR GRACE",	l: "CANADA" },
            { n: "HARBURG",	l: "GERMANY" },
            { n: "HARDANGERFJORDEN",	l: "NORWAY" },
            { n: "HAREID",	l: "NORWAY" },
            { n: "HARGSHAMN",	l: "SWEDEN" },
            { n: "HARLINGEN",	l: "NETHERLANDS" },
            { n: "HARMAC",	l: "CANADA" },
            { n: "HARNOSAND",	l: "SWEDEN" },
            { n: "HARO STRAIT",	l: "UNITED STATES" },
            { n: "HARPER",	l: "LIBERIA" },
            { n: "HARSOVA",	l: "ROMANIA" },
            { n: "HARSTAD",	l: "NORWAY" },
            { n: "HARTLEPOOL",	l: "UNITED KINGDOM" },
            { n: "HARWICH",	l: "UNITED KINGDOM" },
            { n: "HASHIHAMA",	l: "JAPAN" },
            { n: "HASLE",	l: "DENMARK" },
            { n: "HASTINGS",	l: "AUSTRALIA" },
            { n: "HASTINGS (NEW YORK)",	l: "UNITED STATES" },
            { n: "HATANGA",	l: "RUSSIA" },
            { n: "HATSUKAICHI",	l: "JAPAN" },
            { n: "HATTARO",	l: "JAPAN" },
            { n: "HAUGESUND",	l: "NORWAY" },
            { n: "HAUKIPUDAS",	l: "FINLAND" },
            { n: "HAULBOWLINE",	l: "IRELAND" },
            { n: "HAUT INDRE",	l: "FRANCE" },
            { n: "HAVANA",	l: "CUBA" },
            { n: "HAVERSTRAW, NY",	l: "UNITED STATES" },
            { n: "HAVRE",	l: "FRANCE" },
            { n: "HAVRE ST PIERRE",	l: "CANADA" },
            { n: "HAWK INLET",	l: "UNITED STATES" },
            { n: "HAWKINS POINT",	l: "UNITED STATES" },
            { n: "HAY POINT",	l: "AUSTRALIA" },
            { n: "HAYDARPASA",	l: "TURKEY" },
            { n: "HAZIRA",	l: "INDIA" },
            { n: "HBG-BARCELONA",	l: "GIBRALTAR" },
            { n: "HECHUAN",	l: "CHINA" },
            { n: "HEFEI",	l: "CHINA" },
            { n: "HEIANZA",	l: "JAPAN" },
            { n: "HEIDRUN",	l: "NORWAY" },
            { n: "HEIHE",	l: "CHINA" },
            { n: "HEILBRONN",	l: "GERMANY" },
            { n: "HEILIGENHAFEN",	l: "GERMANY" },
            { n: "HEIZUIZI",	l: "CHINA" },
            { n: "HEJIANGTAO",	l: "CHINA" },
            { n: "HEKINAN",	l: "JAPAN" },
            { n: "HELGUVIK",	l: "ICELAND" },
            { n: "HELL GATE",	l: "UNITED STATES" },
            { n: "HELL GATE EAST",	l: "UNITED STATES" },
            { n: "HELL GATE WEST",	l: "UNITED STATES" },
            { n: "HELLESYLT",	l: "NORWAY" },
            { n: "HELLEVOETSLUIS",	l: "NETHERLANDS" },
            { n: "HELSINGBORG",	l: "SWEDEN" },
            { n: "HELSINGFORS",	l: "FINLAND" },
            { n: "HELSINGOR",	l: "DENMARK" },
            { n: "HELSINKI",	l: "FINLAND" },
            { n: "HELSINKI ENTRANCE",	l: "FINLAND" },
            { n: "HELTERMAA",	l: "ESTONIA" },
            { n: "HEMIKSEN",	l: "BELGIUM" },
            { n: "HEMIXEN",	l: "BELGIUM" },
            { n: "HENDERSON",	l: "AUSTRALIA" },
            { n: "HENDERSON RIVERPORT",	l: "UNITED STATES" },
            { n: "HENGAM",	l: "IRAN" },
            { n: "HENGGANG",	l: "CHINA" },
            { n: "HENGYANG",	l: "CHINA" },
            { n: "HENJAM",	l: "IRAN" },
            { n: "HEPOKARI",	l: "FINLAND" },
            { n: "HEQIAO",	l: "CHINA" },
            { n: "HERAKLION",	l: "GREECE" },
            { n: "HERCULES",	l: "UNITED STATES" },
            { n: "HERCULES PORT",	l: "GREECE" },
            { n: "HEREKE",	l: "TURKEY" },
            { n: "HERON BAY",	l: "CANADA" },
            { n: "HEROYA",	l: "NORWAY" },
            { n: "HERTFORD NC",	l: "UNITED STATES" },
            { n: "HESAKI",	l: "JAPAN" },
            { n: "HESAKI ANCHORAGE",	l: "JAPAN" },
            { n: "HESHANGDAO",	l: "CHINA" },
            { n: "HESTVIKA",	l: "NORWAY" },
            { n: "HEXI",	l: "CHINA" },
            { n: "HEXI (HUNAN PROVINCE)",	l: "CHINA" },
            { n: "HEYSHAM",	l: "UNITED KINGDOM" },
            { n: "HIAGARI",	l: "JAPAN" },
            { n: "HIBI",	l: "JAPAN" },
            { n: "HIBIKINADA",	l: "JAPAN" },
            { n: "HIGASHI-HARIMA",	l: "JAPAN" },
            { n: "HIGASHI-OGISHIMA",	l: "JAPAN" },
            { n: "HIKARI",	l: "JAPAN" },
            { n: "HIKOSHIMA",	l: "JAPAN" },
            { n: "HILLSBOROUGH BAY",	l: "UNITED STATES" },
            { n: "HILO",	l: "UNITED STATES" },
            { n: "HILONGOS",	l: "PHILIPPINES" },
            { n: "HIMEJI",	l: "JAPAN" },
            { n: "HIMEKAWA",	l: "JAPAN" },
            { n: "HINATUAN",	l: "PHILIPPINES" },
            { n: "HIRAO",	l: "JAPAN" },
            { n: "HIRO",	l: "JAPAN" },
            { n: "HIROHATA",	l: "JAPAN" },
            { n: "HIROSHIMA",	l: "JAPAN" },
            { n: "HIRTSHALS",	l: "DENMARK" },
            { n: "HITACHI",	l: "JAPAN" },
            { n: "HITACHI NAKA",	l: "JAPAN" },
            { n: "HITACHINAKA",	l: "JAPAN" },
            { n: "HIWASSEE END",	l: "UNITED STATES" },
            { n: "HIWASSEE START",	l: "UNITED STATES" },
            { n: "HO CHI MINH CITY",	l: "VIETNAM" },
            { n: "HO PING",	l: "TAIWAN" },
            { n: "HOBART",	l: "AUSTRALIA" },
            { n: "HOBRO",	l: "DENMARK" },
            { n: "HOCHFELD",	l: "GERMANY" },
            { n: "HODEIDAH",	l: "YEMEN" },
            { n: "HOEK VAN HOLLAND",	l: "NETHERLANDS" },
            { n: "HOFN",	l: "ICELAND" },
            { n: "HOGANAS",	l: "SWEDEN" },
            { n: "HOIKOW",	l: "CHINA" },
            { n: "HOLBAEK",	l: "DENMARK" },
            { n: "HOLCHIT",	l: "MEXICO" },
            { n: "HOLLA",	l: "NORWAY" },
            { n: "HOLLAND HARBOR",	l: "UNITED STATES" },
            { n: "HOLLIS",	l: "UNITED STATES" },
            { n: "HOLM",	l: "UNITED KINGDOM" },
            { n: "HOLMEN",	l: "NORWAY" },
            { n: "HOLMESTRAND",	l: "NORWAY" },
            { n: "HOLMSUND",	l: "SWEDEN" },
            { n: "HOLSTEINSBORG",	l: "GREENLAND" },
            { n: "HOLSTON END",	l: "UNITED STATES" },
            { n: "HOLTENAU",	l: "GERMANY" },
            { n: "HOLYHEAD",	l: "UNITED KINGDOM" },
            { n: "HOLYROOD",	l: "CANADA" },
            { n: "HOMER",	l: "UNITED STATES" },
            { n: "HOMMELVIK",	l: "NORWAY" },
            { n: "HOMS",	l: "LIBYA" },
            { n: "HOMSVIK",	l: "NORWAY" },
            { n: "HON CHONG",	l: "VIETNAM" },
            { n: "HON GAY",	l: "VIETNAM" },
            { n: "HON LA",	l: "VIETNAM" },
            { n: "HON NET ANCHORAGE",	l: "VIETNAM" },
            { n: "HONAVAR",	l: "INDIA" },
            { n: "HONDAGUA",	l: "PHILIPPINES" },
            { n: "HONDURAS (COUNTRY)",	l: "HONDURAS" },
            { n: "HONFLEUR",	l: "FRANCE" },
            { n: "HONG GAI",	l: "VIETNAM" },
            { n: "HONG KONG",	l: "HONG KONG" },
            { n: "HONG KONG (CHINA)",	l: "CHINA" },
            { n: "HONG KONG (COUNTRY)",	l: "HONG KONG" },
            { n: "HONGMIAO",	l: "CHINA" },
            { n: "HONIARA",	l: "SOLOMON ISLANDS" },
            { n: "HONNINGSVAAG",	l: "NORWAY" },
            { n: "HONNINGSVAG",	l: "NORWAY" },
            { n: "HONOLULU",	l: "UNITED STATES" },
            { n: "HOOK OF HOLLAND",	l: "NETHERLANDS" },
            { n: "HOONAH",	l: "UNITED STATES" },
            { n: "HOPA",	l: "TURKEY" },
            { n: "HOPEWELL",	l: "UNITED STATES" },
            { n: "HOPING",	l: "TAIWAN" },
            { n: "HOQUIAM",	l: "UNITED STATES" },
            { n: "HORMUZ TERMINAL",	l: "IRAN" },
            { n: "HORNAFJORDUR",	l: "ICELAND" },
            { n: "HORNEFORS",	l: "SWEDEN" },
            { n: "HORNILLO",	l: "SPAIN" },
            { n: "HORSENS",	l: "DENMARK" },
            { n: "HORTA",	l: "PORTUGAL" },
            { n: "HORTEN",	l: "NORWAY" },
            { n: "HOSOSHIMA",	l: "JAPAN" },
            { n: "HOUAILOU",	l: "NEW CALEDONIA" },
            { n: "HOUMA",	l: "UNITED STATES" },
            { n: "HOUND POINT",	l: "UNITED KINGDOM" },
            { n: "HOUSTON",	l: "UNITED STATES" },
            { n: "HOUSTON OFF SHORE",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL AND BARBOURS CUT",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL AND BAYPORT CHANNEL",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL AND CARPENTERS BAYOU",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL AND GALVESTON CHANNEL",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL AND OLD RIVER-SAN JACINTO",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL AND SAN JACINTO RIVER",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL AND USI CANAL",	l: "UNITED STATES" },
            { n: "HOUSTON SHIP CHANNEL END",	l: "UNITED STATES" },
            { n: "HOVIC",	l: "VIRGIN ISLANDS" },
            { n: "HOWDEN",	l: "UNITED KINGDOM" },
            { n: "HOWDENDYKE",	l: "UNITED KINGDOM" },
            { n: "HOWTH",	l: "IRELAND" },
            { n: "HOYANGER",	l: "NORWAY" },
            { n: "HOYLANDSBYGDEN",	l: "NORWAY" },
            { n: "HSINTA",	l: "TAIWAN" },
            { n: "HUACHIPATO",	l: "CHILE" },
            { n: "HUACHO",	l: "PERU" },
            { n: "HUAHINE",	l: "FRENCH POLYNESIA" },
            { n: "HUAIAN",	l: "CHINA" },
            { n: "HUAINAN",	l: "CHINA" },
            { n: "HUAIYIN",	l: "CHINA" },
            { n: "HUALIEN",	l: "TAIWAN" },
            { n: "HUANGCUN",	l: "CHINA" },
            { n: "HUANGDAO",	l: "CHINA" },
            { n: "HUANGHUA",	l: "CHINA" },
            { n: "HUANGPU",	l: "CHINA" },
            { n: "HUANGSHI",	l: "CHINA" },
            { n: "HUANGTIANGANG",	l: "CHINA" },
            { n: "HUARAN",	l: "CHINA" },
            { n: "HUARMEY",	l: "PERU" },
            { n: "HUASCO",	l: "CHILE" },
            { n: "HUATULCO",	l: "MEXICO" },
            { n: "HUBBARD GLACIER",	l: "UNITED STATES" },
            { n: "HUDAIDA",	l: "YEMEN" },
            { n: "HUDIKSVALL",	l: "SWEDEN" },
            { n: "HUDONG",	l: "CHINA" },
            { n: "HUDSON",	l: "UNITED STATES" },
            { n: "HUE",	l: "VIETNAM" },
            { n: "HUE CITY",	l: "VIETNAM" },
            { n: "HUELVA",	l: "SPAIN" },
            { n: "HUELVA-BARCELONA",	l: "SPAIN" },
            { n: "HUELVA-MARSEILLES",	l: "SPAIN" },
            { n: "HUENEME",	l: "UNITED STATES" },
            { n: "HUILAI POWER PLANT",	l: "CHINA" },
            { n: "HUINAN",	l: "CHINA" },
            { n: "HUIYANG",	l: "CHINA" },
            { n: "HUIZHOU",	l: "CHINA" },
            { n: "HUKUI",	l: "JAPAN" },
            { n: "HULANHE",	l: "CHINA" },
            { n: "HULAYLAH",	l: "UNITED ARAB EMIRATES" },
            { n: "HULL",	l: "UNITED KINGDOM" },
            { n: "HULUDAO",	l: "CHINA" },
            { n: "HULUDAO",	l: "CHINA" },
            { n: "HUMBERMOUTH",	l: "CANADA" },
            { n: "HUMBOLDT BAY",	l: "UNITED STATES" },
            { n: "HUMBUG POINT",	l: "AUSTRALIA" },
            { n: "HUMEN",	l: "CHINA" },
            { n: "HUNDESTED",	l: "DENMARK" },
            { n: "HUNGNAM",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "HUNTERSTON",	l: "UNITED KINGDOM" },
            { n: "HUNTINGTON BEACH",	l: "UNITED STATES" },
            { n: "HURGHADA",	l: "EGYPT" },
            { n: "HURON",	l: "UNITED STATES" },
            { n: "HUSAVIK",	l: "ICELAND" },
            { n: "HUSEVIG",	l: "FAROE ISLANDS" },
            { n: "HUSNES",	l: "NORWAY" },
            { n: "HUSUM (GERMANY)",	l: "GERMANY" },
            { n: "HUSUM (SWEDEN)",	l: "SWEDEN" },
            { n: "HUWEN",	l: "CHINA" },
            { n: "HUZHOU",	l: "CHINA" },
            { n: "HVALFJORDUR",	l: "ICELAND" },
            { n: "HVAMMSTANGI",	l: "ICELAND" },
            { n: "HVAR",	l: "CROATIA" },
            { n: "HVIDE SANDE",	l: "DENMARK" },
            { n: "HYANNIS HARBOR",	l: "UNITED STATES" },
            { n: "HYDABURG",	l: "UNITED STATES" },
            { n: "HYDRA",	l: "GREECE" },
            { n: "HYDROGRAPHERS PASSAGE",	l: "AUSTRALIA" },
            { n: "HYEN",	l: "NORWAY" },
            { n: "IBICUY",	l: "ARGENTINA" },
            { n: "IBIZA",	l: "SPAIN" },
            { n: "IBT",	l: "INDONESIA" },
            { n: "IBT",	l: "INDONESIA" },
            { n: "ICDAS",	l: "TURKEY" },
            { n: "ICELAND (COUNTRY)",	l: "ICELAND" },
            { n: "ICHIHARA",	l: "JAPAN" },
            { n: "ICOARACI",	l: "BRAZIL" },
            { n: "ICY STRAIT POINT, AK",	l: "UNITED STATES" },
            { n: "IDKU LNG TERMINAL",	l: "EGYPT" },
            { n: "IGARKA",	l: "RUSSIA" },
            { n: "IGGESUND",	l: "SWEDEN" },
            { n: "IGLOOLIK, NU",	l: "CANADA" },
            { n: "IGOUMENITSA",	l: "GREECE" },
            { n: "IHO",	l: "JAPAN" },
            { n: "IJMUIDEN",	l: "NETHERLANDS" },
            { n: "IL WWY N BRANCH END",	l: "UNITED STATES" },
            { n: "IL WWY SAGINAW END",	l: "UNITED STATES" },
            { n: "ILE DE PINS",	l: "FRANCE" },
            { n: "ILE DE PORQUELLES",	l: "FRANCE" },
            { n: "ILE D'OUESSANT",	l: "FRANCE" },
            { n: "ILE D'OUESSANT 10'W",	l: "FRANCE" },
            { n: "ILE ROUSSE",	l: "FRANCE" },
            { n: "ILES DE LA MADELEINE",	l: "CANADA" },
            { n: "ILHA GRANDE",	l: "BRAZIL" },
            { n: "ILHA GUAIBA TERMINAL",	l: "BRAZIL" },
            { n: "ILHA REDONDA",	l: "BRAZIL" },
            { n: "ILHABELA",	l: "BRAZIL" },
            { n: "ILHEUS",	l: "BRAZIL" },
            { n: "ILIGAN",	l: "PHILIPPINES" },
            { n: "ILLINOIS END",	l: "UNITED STATES" },
            { n: "ILLINOIS START",	l: "UNITED STATES" },
            { n: "ILO",	l: "PERU" },
            { n: "ILOCOS SUR",	l: "PHILIPPINES" },
            { n: "ILOILO",	l: "PHILIPPINES" },
            { n: "ILULISSAT",	l: "GREENLAND" },
            { n: "ILYICHEVSK",	l: "UKRAINE" },
            { n: "IMA TERMINAL",	l: "NIGERIA" },
            { n: "IMABARI",	l: "JAPAN" },
            { n: "IMARI",	l: "JAPAN" },
            { n: "IMATRA",	l: "FINLAND" },
            { n: "IMBITUBA",	l: "BRAZIL" },
            { n: "IMMINGHAM",	l: "UNITED KINGDOM" },
            { n: "IMPERIA",	l: "ITALY" },
            { n: "INAGUA ISLANDS",	l: "THE BAHAMAS" },
            { n: "INCE",	l: "UNITED KINGDOM" },
            { n: "INCHCAPE BUOY",	l: "UNITED ARAB EMIRATES" },
            { n: "INCHON",	l: "KOREA, REPUBLIC" },
            { n: "INDIA (COUNTRY)",	l: "INDIA" },
            { n: "INDIAN ISLAND",	l: "UNITED STATES" },
            { n: "INDIANA HARBOUR",	l: "UNITED STATES" },
            { n: "INDONESIA (COUNTRY)",	l: "INDONESIA" },
            { n: "INDONESIA BULK TERMINAL",	l: "INDONESIA" },
            { n: "INDRET",	l: "FRANCE" },
            { n: "INEBOLU",	l: "TURKEY" },
            { n: "INGA",	l: "FINLAND" },
            { n: "INGELSIDE",	l: "UNITED STATES" },
            { n: "INGENIERO BUITRANGO",	l: "ARGENTINA" },
            { n: "INGENIERO WHITE",	l: "ARGENTINA" },
            { n: "INGLESIDE",	l: "UNITED STATES" },
            { n: "INHAMBANE",	l: "MOZAMBIQUE" },
            { n: "INISHTRAHULL ISLAND",	l: "IRELAND" },
            { n: "INKOO",	l: "FINLAND" },
            { n: "INLAND SEA",	l: "JAPAN" },
            { n: "INNISFAIL",	l: "AUSTRALIA" },
            { n: "INNOSHIMA",	l: "JAPAN" },
            { n: "INUKJUAK",	l: "CANADA" },
            { n: "INVERGORDON",	l: "UNITED KINGDOM" },
            { n: "INVERKEITHING",	l: "UNITED KINGDOM" },
            { n: "INVERNESS",	l: "UNITED KINGDOM" },
            { n: "IOCO",	l: "CANADA" },
            { n: "IOKANKA",	l: "RUSSIA" },
            { n: "IOKANKSKI",	l: "RUSSIA" },
            { n: "IOKANSKI",	l: "RUSSIA" },
            { n: "IONA",	l: "CANADA" },
            { n: "IPLP1",	l: "AUSTRALIA" },
            { n: "IPLP2",	l: "INDONESIA" },
            { n: "IPPA-DO PILOT STATION",	l: "KOREA, REPUBLIC" },
            { n: "IPSWICH",	l: "UNITED KINGDOM" },
            { n: "IQALUIT",	l: "CANADA" },
            { n: "IQALUIT",	l: "CANADA" },
            { n: "IQUIQUE",	l: "CHILE" },
            { n: "IQUITOS",	l: "PERU" },
            { n: "IRAGO",	l: "JAPAN" },
            { n: "IRAKLION",	l: "GREECE" },
            { n: "IRAN (COUNTRY)",	l: "IRAN" },
            { n: "IRAQ (COUNTRY)",	l: "IRAQ" },
            { n: "IRELAND (COUNTRY)",	l: "IRELAND" },
            { n: "IRELAND ISLAND",	l: "BERMUDA" },
            { n: "IRLAM",	l: "UNITED KINGDOM" },
            { n: "IROQUOIS LOCK",	l: "CANADA" },
            { n: "IRVINE",	l: "UNITED KINGDOM" },
            { n: "ISAAC'S HARBOUR",	l: "CANADA" },
            { n: "ISABEL",	l: "PHILIPPINES" },
            { n: "ISABELA",	l: "PHILIPPINES" },
            { n: "ISABELA DE SAGUA",	l: "CUBA" },
            { n: "ISACCEA",	l: "ROMANIA" },
            { n: "ISAFJORDUR",	l: "ICELAND" },
            { n: "ISDEMIR",	l: "TURKEY" },
            { n: "ISHIGAKI",	l: "JAPAN" },
            { n: "ISHIGAKI ISLAND",	l: "JAPAN" },
            { n: "ISHIKARA",	l: "JAPAN" },
            { n: "ISHIKARI",	l: "JAPAN" },
            { n: "ISHIKARIWAN SHINKO",	l: "JAPAN" },
            { n: "ISHINOMAKI",	l: "JAPAN" },
            { n: "ISKENDERUN",	l: "TURKEY" },
            { n: "ISLA DE PASCUA",	l: "CHILE" },
            { n: "ISLA DEL DORADO",	l: "ARGENTINA" },
            { n: "ISLA GUARELLO",	l: "CHILE" },
            { n: "ISLA RIESCO",	l: "CHILE" },
            { n: "ISLA ROATAN",	l: "HONDURAS" },
            { n: "ISLA ROBINSON CRUSOE",	l: "CHILE" },
            { n: "ISLE OF GRAIN",	l: "UNITED KINGDOM" },
            { n: "ISLE OF LEWIS",	l: "UNITED KINGDOM" },
            { n: "ISLE OF MAN (COUNTRY)",	l: "ISLE OF MAN" },
            { n: "ISLE OF PINES",	l: "NEW CALEDONIA" },
            { n: "ISLE OF WHITHORN",	l: "UNITED KINGDOM" },
            { n: "ISMAILIA",	l: "EGYPT" },
            { n: "ISNAS",	l: "FINLAND" },
            { n: "ISRAEL (COUNTRY)",	l: "ISRAEL" },
            { n: "ISTANBUL",	l: "TURKEY" },
            { n: "ISTHMIA",	l: "GREECE" },
            { n: "ITACOATIARA",	l: "BRAZIL" },
            { n: "ITAGUAI",	l: "BRAZIL" },
            { n: "ITAJAI",	l: "BRAZIL" },
            { n: "ITALY (COUNTRY)",	l: "ITALY" },
            { n: "ITAQUI",	l: "BRAZIL" },
            { n: "ITEA",	l: "GREECE" },
            { n: "ITOZAKI",	l: "JAPAN" },
            { n: "ITZEHOE",	l: "GERMANY" },
            { n: "IVIGTUT",	l: "GREENLAND" },
            { n: "IVORY COAST (COUNTRY)",	l: "COTE D'IVOIRE" },
            { n: "IVUJIVIK, QC",	l: "CANADA" },
            { n: "IWAKUNI",	l: "JAPAN" },
            { n: "IYOMISHIMA",	l: "JAPAN" },
            { n: "IZMAIL",	l: "UKRAINE" },
            { n: "IZMIR",	l: "TURKEY" },
            { n: "IZMIT",	l: "TURKEY" },
            { n: "IZOLA",	l: "SLOVENIA" },
            { n: "IZON",	l: "FRANCE" },
            { n: "IZUMISANO",	l: "JAPAN" },
            { n: "JABIRU MARINE TERMINAL",	l: "AUSTRALIA" },
            { n: "JACINTOPORT",	l: "UNITED STATES" },
            { n: "JACKSONVILLE",	l: "UNITED STATES" },
            { n: "JACKSONVILLE NC",	l: "UNITED STATES" },
            { n: "JADE JUDY",	l: "UNITED KINGDOM" },
            { n: "JAFARABAD",	l: "INDIA" },
            { n: "JAFFA",	l: "ISRAEL" },
            { n: "JAFFNA",	l: "SRI LANKA" },
            { n: "JAIGAD",	l: "INDIA" },
            { n: "JAIGARH",	l: "INDIA" },
            { n: "JAKARTA",	l: "INDONESIA" },
            { n: "JAKHAU",	l: "INDIA" },
            { n: "JAKOBSHAVN",	l: "GREENLAND" },
            { n: "JAKOBSTAD",	l: "FINLAND" },
            { n: "JAMAICA (COUNTRY)",	l: "JAMAICA" },
            { n: "JAMBI",	l: "INDONESIA" },
            { n: "JAMESTOWN",	l: "SAINT HELENA" },
            { n: "JAMESTOWN ANCHORAGE",	l: "UNITED STATES" },
            { n: "JAMNAGAR",	l: "INDIA" },
            { n: "JAPAN (COUNTRY)",	l: "JAPAN" },
            { n: "JARROW",	l: "UNITED KINGDOM" },
            { n: "JASAAN",	l: "PHILIPPINES" },
            { n: "JASK",	l: "IRAN" },
            { n: "JASMINE MARINE TERMINAL",	l: "THAILAND" },
            { n: "JAVEA",	l: "SPAIN" },
            { n: "JAWAHAR DWEEP",	l: "INDIA" },
            { n: "JAWAHARLAL NEHRU PORT",	l: "INDIA" },
            { n: "JAYAPURA",	l: "INDONESIA" },
            { n: "JEBEL ALI",	l: "UNITED ARAB EMIRATES" },
            { n: "JEBEL DHANNA/RUWAIS",	l: "UNITED ARAB EMIRATES" },
            { n: "JEDDAH",	l: "SAUDI ARABIA" },
            { n: "JEDDAH ISLAMIC PORT",	l: "SAUDI ARABIA" },
            { n: "JEFFRIES POINT",	l: "UNITED STATES" },
            { n: "JEJSK",	l: "RUSSIA" },
            { n: "JELSA",	l: "NORWAY" },
            { n: "JERSEY",	l: "UNITED KINGDOM" },
            { n: "JEWELL FULTON END",	l: "UNITED STATES" },
            { n: "JIADING",	l: "CHINA" },
            { n: "JIADONG",	l: "CHINA" },
            { n: "JIAMUSI",	l: "CHINA" },
            { n: "JIANCHUNMEN",	l: "CHINA" },
            { n: "JIANGCHENG HEDI",	l: "CHINA" },
            { n: "JIANGMEN",	l: "CHINA" },
            { n: "JIANGSU",	l: "CHINA" },
            { n: "JIANGYIN",	l: "CHINA" },
            { n: "JIANGYIN ASF",	l: "CHINA" },
            { n: "JIANHU",	l: "CHINA" },
            { n: "JIANNING",	l: "CHINA" },
            { n: "JIAOCHANGBA",	l: "CHINA" },
            { n: "JIAXING",	l: "CHINA" },
            { n: "JIBUTI",	l: "DJIBOUTI" },
            { n: "JIEBIAN",	l: "CHINA" },
            { n: "JIEH",	l: "LEBANON" },
            { n: "JIJEL",	l: "ALGERIA" },
            { n: "JIMENEZ",	l: "PHILIPPINES" },
            { n: "JINGJIANG",	l: "CHINA" },
            { n: "JINGTANG",	l: "CHINA" },
            { n: "JINGYANG",	l: "CHINA" },
            { n: "JINHAE",	l: "korea, republic" },
            { n: "JINHUADIAN",	l: "CHINA" },
            { n: "JINJIANG",	l: "CHINA" },
            { n: "JINSHAN",	l: "CHINA" },
            { n: "JINSHAN TERMINAL",	l: "CHINA" },
            { n: "JINSHI",	l: "CHINA" },
            { n: "JINZHOU",	l: "CHINA" },
            { n: "JIUCAIGANG",	l: "CHINA" },
            { n: "JIUJIANG",	l: "CHINA" },
            { n: "JIULONGPO",	l: "CHINA" },
            { n: "JIUZHOU",	l: "CHINA" },
            { n: "JOBOS BAY",	l: "PUERTO RICO" },
            { n: "JOENSUU",	l: "FINLAND" },
            { n: "JOETSU",	l: "JAPAN" },
            { n: "JOHNSTON ISLAND HARBOUR",	l: "UNITED STATES" },
            { n: "JOHNSTONE STRAIT",	l: "CANADA" },
            { n: "JOHNSTOWN",	l: "CANADA" },
            { n: "JOHOR",	l: "MALAYSIA" },
            { n: "JOHORE BAHRU",	l: "MALAYSIA" },
            { n: "JOLIET, IN",	l: "UNITED STATES" },
            { n: "JOLO",	l: "PHILIPPINES" },
            { n: "JOMARD (ENTRANCE)",	l: "PAPUA NEW GUINEA" },
            { n: "JORDAN",	l: "PHILIPPINES" },
            { n: "JORDAN (COUNTRY)",	l: "JORDAN" },
            { n: "JORF LASFAR",	l: "MOROCCO" },
            { n: "JORONG",	l: "INDONESIA" },
            { n: "JOSE IGNACIO",	l: "URUGUAY" },
            { n: "JOSE MONOBUOY",	l: "VENEZUELA" },
            { n: "JOSE PANGANIBAN",	l: "PHILIPPINES" },
            { n: "JOSE PLATFORM (TAECJ)",	l: "VENEZUELA" },
            { n: "JOSE TERMINAL",	l: "VENEZUELA" },
            { n: "JOSSINGFJORD",	l: "NORWAY" },
            { n: "JOUNIEH",	l: "LEBANON" },
            { n: "JOUTSENO",	l: "FINLAND" },
            { n: "JOVIK",	l: "NORWAY" },
            { n: "JUAN DE FUCA STRAIT",	l: "UNITED STATES" },
            { n: "JU'AYMAH CRUDE & LPG TERMINALS",	l: "SAUDI ARABIA" },
            { n: "JUBAIL",	l: "SAUDI ARABIA" },
            { n: "JUBAIL OPL",	l: "SAUDI ARABIA" },
            { n: "JUBAIL PORT PILOT STATION",	l: "SAUDI ARABIA" },
            { n: "JUBILEE FPSO",	l: "GHANA" },
            { n: "JUCARO",	l: "CUBA" },
            { n: "JULIANEHAAB",	l: "GREENLAND" },
            { n: "JUNA BUNDER",	l: "PAKISTAN" },
            { n: "JUNEAU",	l: "UNITED STATES" },
            { n: "JURELES",	l: "CHILE" },
            { n: "JURONG",	l: "SINGAPORE" },
            { n: "JURUTI TERMINAL",	l: "BRAZIL" },
            { n: "JUYUTUO",	l: "CHINA" },
            { n: "JUZNA LUKA",	l: "CROATIA" },
            { n: "JWC50E",	l: "SOMALIA" },
            { n: "JWC60N",	l: "OMAN" },
            { n: "JWC60S",	l: "OMAN" },
            { n: "JWC65N",	l: "OMAN" },
            { n: "JWC65S",	l: "OMAN" },
            { n: "KAARSTO",	l: "NORWAY" },
            { n: "KABIL",	l: "INDONESIA" },
            { n: "KADETBANK",	l: "DENMARK" },
            { n: "KAGE",	l: "SWEDEN" },
            { n: "KAGOSHIMA",	l: "JAPAN" },
            { n: "KAHULUI",	l: "UNITED STATES" },
            { n: "KAIFA",	l: "ISRAEL" },
            { n: "KAILORANG",	l: "INDONESIA" },
            { n: "KAIMANA",	l: "INDONESIA" },
            { n: "KAINAN",	l: "JAPAN" },
            { n: "KAITUMA",	l: "GUYANA" },
            { n: "KAKANDE",	l: "GUINEA" },
            { n: "KAKAP NATUNA MARINE TERMINAL",	l: "INDONESIA" },
            { n: "KAKE",	l: "UNITED STATES" },
            { n: "KAKINADA",	l: "INDIA" },
            { n: "KAKOGAWA",	l: "JAPAN" },
            { n: "KALABAHI",	l: "INDONESIA" },
            { n: "KALAJOKI",	l: "FINLAND" },
            { n: "KALAMA",	l: "UNITED STATES" },
            { n: "KALAMAI",	l: "GREECE" },
            { n: "KALAMAKI",	l: "GREECE" },
            { n: "KALAMATA",	l: "GREECE" },
            { n: "KALBUT SITUBONDO TERMINAL",	l: "INDONESIA" },
            { n: "KALECIK",	l: "CYPRUS" },
            { n: "KALI LIMENES",	l: "GREECE" },
            { n: "KALIANGET",	l: "INDONESIA" },
            { n: "KALIMANTAN",	l: "INDONESIA" },
            { n: "KALININGRAD",	l: "RUSSIA" },
            { n: "KALIORANG",	l: "INDONESIA" },
            { n: "KALIORANG",	l: "INDONESIA" },
            { n: "KALIX",	l: "SWEDEN" },
            { n: "KALMAR",	l: "SWEDEN" },
            { n: "KALMARSUND",	l: "SWEDEN" },
            { n: "KALNESET",	l: "NORWAY" },
            { n: "KALUNDBORG",	l: "DENMARK" },
            { n: "KALYMNOS",	l: "GREECE" },
            { n: "KAMAISHI",	l: "JAPAN" },
            { n: "KAMARAJAR",	l: "INDIA" },
            { n: "KAMARAN ISLAND",	l: "YEMEN" },
            { n: "KAMBO",	l: "NORWAY" },
            { n: "KAMIISO",	l: "JAPAN" },
            { n: "KAMPEN",	l: "NETHERLANDS" },
            { n: "KAMPOT",    l: "CAMBODIA" },
            { n: "KAMPONG SAOM",	l: "CAMBODIA" },
            { n: "KAMYSHIN",	l: "RUSSIA" },
            { n: "KANAWHA END",	l: "UNITED STATES" },
            { n: "KANAWHA START",	l: "UNITED STATES" },
            { n: "KANAZAWA",	l: "JAPAN" },
            { n: "KANDA",	l: "JAPAN" },
            { n: "KANDALAKSHA",	l: "RUSSIA" },
            { n: "KANDLA",	l: "INDIA" },
            { n: "DEENDAYAL",	l: "INDIA" },
            { n: "KANGERLUSSUAQ",	l: "GREENLAND" },
            { n: "KANGILINNGUIT",	l: "GREENLAND" },
            { n: "KANGIQSUALUJJUAQ, QC",	l: "CANADA" },
            { n: "KANGIQSUJUAQ, QC",	l: "CANADA" },
            { n: "KANGIRSUK, QC",	l: "CANADA" },
            { n: "KANIKA SAND",	l: "INDIA" },
            { n: "KANKESANTURAI",	l: "SRI LANKA" },
            { n: "KANMON",	l: "JAPAN" },
            { n: "KANMON KO",	l: "JAPAN" },
            { n: "KANMON STRAIT",	l: "JAPAN" },
            { n: "KANOKAWA",	l: "JAPAN" },
            { n: "KANTANG",	l: "THAILAND" },
            { n: "KANTVIK",	l: "FINLAND" },
            { n: "KAOHSIUNG",	l: "TAIWAN" },
            { n: "KAOLACK",	l: "SENEGAL" },
            { n: "KAPAL MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "KAPAR",	l: "MALAYSIA" },
            { n: "KAPELLSKAR",	l: "SWEDEN" },
            { n: "KAPPELN",	l: "GERMANY" },
            { n: "KAPPELSHAMN",	l: "SWEDEN" },
            { n: "KARABIGA",	l: "TURKEY" },
            { n: "KARACHI",	l: "PAKISTAN" },
            { n: "KARAIKAL",	l: "INDIA" },
            { n: "KARAKOY",	l: "TURKEY" },
            { n: "KARATSU",	l: "JAPAN" },
            { n: "KARAVOS HARBOUR",	l: "GREECE" },
            { n: "KARAVOSTASSI",	l: "CYPRUS" },
            { n: "KARDELJEVO",	l: "CROATIA" },
            { n: "KAREMBE",	l: "NEW CALEDONIA" },
            { n: "KARIKAL",	l: "INDIA" },
            { n: "KARIMATA STRAIT",	l: "INDONESIA" },
            { n: "KARIMUN",	l: "INDONESIA" },
            { n: "KARITA",	l: "JAPAN" },
            { n: "KARLOVASSI",	l: "GREECE" },
            { n: "KARLSBORG",	l: "SWEDEN" },
            { n: "KARLSHALL",	l: "SWEDEN" },
            { n: "KARLSHAMN",	l: "SWEDEN" },
            { n: "KARLSKRONA",	l: "SWEDEN" },
            { n: "KARLSRUHE",	l: "GERMANY" },
            { n: "KARLSTAD",	l: "SWEDEN" },
            { n: "KARLSVIK",	l: "SWEDEN" },
            { n: "KARMOY",	l: "NORWAY" },
            { n: "KARRATHA",	l: "AUSTRALIA" },
            { n: "KARREBAEKSMINDE",	l: "DENMARK" },
            { n: "KARSKAR",	l: "SWEDEN" },
            { n: "KARTAL",	l: "TURKEY" },
            { n: "KARUMBA",	l: "AUSTRALIA" },
            { n: "KARWAR",	l: "INDIA" },
            { n: "KASADO",	l: "JAPAN" },
            { n: "KASAOKA",	l: "JAPAN" },
            { n: "KASHIMA",	l: "JAPAN" },
            { n: "KASHIWAZAKI",	l: "JAPAN" },
            { n: "KASIM MARINE TERMINAL",	l: "INDONESIA" },
            { n: "KASKASKIA END",	l: "UNITED STATES" },
            { n: "KASKASKIA START",	l: "UNITED STATES" },
            { n: "KASKINEN",	l: "FINLAND" },
            { n: "KASKO",	l: "FINLAND" },
            { n: "KASTRUP",	l: "DENMARK" },
            { n: "KATAKOLON",	l: "GREECE" },
            { n: "KATAPOLA BAY",	l: "GREECE" },
            { n: "KATTUPALLI",	l: "INDIA" },
            { n: "KAUKAS",	l: "FINLAND" },
            { n: "KAUNAKAKAI",	l: "UNITED STATES" },
            { n: "KAUPANGER",	l: "NORWAY" },
            { n: "KAVALLA",	l: "GREECE" },
            { n: "KAVIENG",	l: "PAPUA NEW GUINEA" },
            { n: "KAVKAZ",	l: "RUSSIA" },
            { n: "KAVONISI-KISSAMOS",	l: "GREECE" },
            { n: "KAWAGOE",	l: "JAPAN" },
            { n: "KAWAIHAE",	l: "UNITED STATES" },
            { n: "KAWAJIRI",	l: "JAPAN" },
            { n: "KAWASAKI",	l: "JAPAN" },
            { n: "KAWTHAUNG",	l: "MYANMAR" },
            { n: "KAZAKHSTAN (COUNTRY)",	l: "KAZAKHSTAN" },
            { n: "KEA ISLAND",	l: "GREECE" },
            { n: "KEADBY",	l: "UNITED KINGDOM" },
            { n: "KEELING ISLAND",	l: "AUSTRALIA" },
            { n: "KEELUNG",	l: "TAIWAN" },
            { n: "KEFLAVIK - NJAROVIK",	l: "ICELAND" },
            { n: "KEHL",	l: "GERMANY" },
            { n: "KEIHIN",	l: "JAPAN" },
            { n: "KELLEYS ISLAND",	l: "UNITED STATES" },
            { n: "KELSEY BAY",	l: "CANADA" },
            { n: "KEMAMAN",	l: "MALAYSIA" },
            { n: "KEMEN",	l: "CHINA" },
            { n: "KEMI",	l: "FINLAND" },
            { n: "KEMIO",	l: "FINLAND" },
            { n: "KEMPO",	l: "INDONESIA" },
            { n: "KENAI",	l: "UNITED STATES" },
            { n: "KENDARI",	l: "INDONESIA" },
            { n: "KENDAWANGAN",	l: "INDONESIA" },
            { n: "KENITRA",	l: "MOROCCO" },
            { n: "KENNEWICK",	l: "UNITED STATES" },
            { n: "KENOSHA",	l: "UNITED STATES" },
            { n: "KENTUCKY END",	l: "UNITED STATES" },
            { n: "KENTUCKY START",	l: "UNITED STATES" },
            { n: "KENYA (COUNTRY)",	l: "KENYA" },
            { n: "KEPPEL",	l: "SINGAPORE" },
            { n: "KERATSINI",	l: "GREECE" },
            { n: "KERCH",	l: "UKRAINE" },
            { n: "KERKYRA",	l: "GREECE" },
            { n: "KERTEH",	l: "MALAYSIA" },
            { n: "KERTEH TERMINAL",	l: "MALAYSIA" },
            { n: "KERTEMINDE",	l: "DENMARK" },
            { n: "KESENNUMA",	l: "JAPAN" },
            { n: "KESKLINNA",	l: "ESTONIA" },
            { n: "KETAPANG",	l: "INDONESIA" },
            { n: "KETCHIKAN",	l: "UNITED STATES" },
            { n: "KETI BANDAR",	l: "PAKISTAN" },
            { n: "KEY WEST",	l: "UNITED STATES" },
            { n: "KHAFJI",	l: "SAUDI ARABIA" },
            { n: "KHALF HARBOUR",	l: "YEMEN" },
            { n: "KHALIFA",	l: "UNITED ARAB EMIRATES" },
            { n: "KHALIFA BIN SALMAN PORT",	l: "BAHRAIN" },
            { n: "KHANOM",	l: "THAILAND" },
            { n: "KHARG ISLAND",	l: "IRAN" },
            { n: "KHASAB",	l: "OMAN" },
            { n: "KHEMCO TERMINAL",	l: "IRAN" },
            { n: "KHERSON",	l: "UKRAINE" },
            { n: "KHOLMSK",	l: "RUSSIA" },
            { n: "KHOR AL AMAYA",	l: "IRAQ" },
            { n: "KHOR AL MUFFATTA",	l: "KUWAIT" },
            { n: "KHOR AL ZUBAIR",	l: "IRAQ" },
            { n: "KHOR FAKKAN",	l: "UNITED ARAB EMIRATES" },
            { n: "KHORRAMSHAHR",	l: "IRAN" },
            { n: "KHULNA",	l: "BANGLADESH" },
            { n: "KIABO MARINE TERMINAL",	l: "ANGOLA" },
            { n: "KIAME MARINE TERMINAL",	l: "ANGOLA" },
            { n: "KIDURONG MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "KIEL",	l: "GERMANY" },
            { n: "KIEL CANAL",	l: "GERMANY" },
            { n: "KIEL-BRUNSBUTTEL",	l: "GERMANY" },
            { n: "KIEL-HOLTENAU",	l: "GERMANY" },
            { n: "KIETA",	l: "PAPUA NEW GUINEA" },
            { n: "KIIRE",	l: "JAPAN" },
            { n: "KIJANG",	l: "INDONESIA" },
            { n: "KIKEH MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "KIKUMA",	l: "JAPAN" },
            { n: "KILAUEA",	l: "UNITED STATES" },
            { n: "KILINDI",	l: "KENYA" },
            { n: "KILIYA",	l: "UKRAINE" },
            { n: "KILKEEL",	l: "UNITED KINGDOM" },
            { n: "KILLALA",	l: "IRELAND" },
            { n: "KILLINGHOLME",	l: "UNITED KINGDOM" },
            { n: "KILLYBEGS",	l: "IRELAND" },
            { n: "KILRONAN",	l: "IRELAND" },
            { n: "KILROOT",	l: "UNITED KINGDOM" },
            { n: "KILRUSH",	l: "IRELAND" },
            { n: "KILWA KIVINJE",	l: "TANZANIA" },
            { n: "KILWA MASOKO",	l: "TANZANIA" },
            { n: "KIMANIS",	l: "MALAYSIA" },
            { n: "KIMANIS SPM 1",	l: "MALAYSIA" },
            { n: "KIMANIS SPM 2",	l: "MALAYSIA" },
            { n: "KIMBE",	l: "PAPUA NEW GUINEA" },
            { n: "KIMITSU",	l: "JAPAN" },
            { n: "KIMMIRUT, NU",	l: "CANADA" },
            { n: "KING ABDUL AZIZ PORT",	l: "SAUDI ARABIA" },
            { n: "KING ABDULLAH",	l: "SAUDI ARABIA" },
            { n: "KING COVE",	l: "UNITED STATES" },
            { n: "KING FAHD INDUSTRIAL PORT (JUBAIL)",	l: "SAUDI ARABIA" },
            { n: "KING FAHD INDUSTRIAL PORT (YANBU)",	l: "SAUDI ARABIA" },
            { n: "KING GEORGE ISLAND",	l: "CHILE" },
            { n: "KING ISLAND",	l: "AUSTRALIA" },
            { n: "KING'S FERRY",	l: "UNITED KINGDOM" },
            { n: "KING'S LYNN",	l: "UNITED KINGDOM" },
            { n: "KINGSCOTE",	l: "AUSTRALIA" },
            { n: "KINGSNORTH",	l: "UNITED KINGDOM" },
            { n: "KINGSTON",	l: "IRELAND" },
            { n: "KINGSTON (CANADA)",	l: "CANADA" },
            { n: "KINGSTON (JAMAICA)",	l: "JAMAICA" },
            { n: "KINGSTON (NEW YORK)",	l: "UNITED STATES" },
            { n: "KINGSTOWN",	l: "SAINT VINCENT AND THE GRENADINES" },
            { n: "KINGSVILLE",	l: "CANADA" },
            { n: "KINSALE",	l: "IRELAND" },
            { n: "KINUURA",	l: "JAPAN" },
            { n: "KINWAN",	l: "JAPAN" },
            { n: "KIPEVU OIL TERMINAL",	l: "KENYA" },
            { n: "KIRIBATI (COUNTRY)",	l: "KIRIBATI" },
            { n: "KIRITIMATI",	l: "KIRIBATI" },
            { n: "KIRKCALDY",	l: "UNITED KINGDOM" },
            { n: "KIRKCUDBRIGHT",	l: "UNITED KINGDOM" },
            { n: "KIRKENES",	l: "NORWAY" },
            { n: "KIRKWALL",	l: "UNITED KINGDOM" },
            { n: "KISARAZU",	l: "JAPAN" },
            { n: "KISH ISLAND",	l: "IRAN" },
            { n: "KISHIWADA",	l: "JAPAN" },
            { n: "KISHON ZONE",	l: "ISRAEL" },
            { n: "KISMAYU",	l: "SOMALIA" },
            { n: "KISSY OIL TERMINAL",	l: "SIERRA LEONE" },
            { n: "KITAKYUSHU",	l: "JAPAN" },
            { n: "KITAN TERMINAL FPSO",	l: "AUSTRALIA" },
            { n: "KITHIRA ISLAND",	l: "GREECE" },
            { n: "KITIMAT",	l: "CANADA" },
            { n: "KIVALINA",	l: "UNITED STATES" },
            { n: "KIYANLY",	l: "TURKMENISTAN" },
            { n: "KIZOMBA",	l: "ANGOLA" },
            { n: "KIZOMBA TERMINAL",	l: "ANGOLA" },
            { n: "KJOLLEFJORD",	l: "NORWAY" },
            { n: "KJOPMANNSKJAER",	l: "NORWAY" },
            { n: "KJOPSVIK",	l: "NORWAY" },
            { n: "KLAIPEDA",	l: "LITHUANIA" },
            { n: "KLAKSVIK",	l: "FAROE ISLANDS" },
            { n: "KLAWOCK",	l: "UNITED STATES" },
            { n: "KLEIN POINT",	l: "AUSTRALIA" },
            { n: "KLEINHUNINGEN",	l: "SWITZERLAND" },
            { n: "KLEVEN",	l: "NORWAY" },
            { n: "KLICKITAT",	l: "UNITED STATES" },
            { n: "KLINTEHAMN",	l: "SWEDEN" },
            { n: "KLONG TOEY",	l: "THAILAND" },
            { n: "KM 171, PARANA GUAZU RIVER",	l: "ARGENTINA" },
            { n: "KM171",	l: "ARGENTINA" },
            { n: "KNIK",	l: "UNITED STATES" },
            { n: "KO JUNG",	l: "KOREA, REPUBLIC" },
            { n: "KO PHALUAI",	l: "THAILAND" },
            { n: "KO SI CHANG",	l: "THAILAND" },
            { n: "KOBE",	l: "JAPAN" },
            { n: "KOBENHAVN",	l: "DENMARK" },
            { n: "KOBLENZ",	l: "GERMANY" },
            { n: "KOCHI (INDIA)",	l: "INDIA" },
            { n: "KOCHI (JAPAN)",	l: "JAPAN" },
            { n: "KODIAK",	l: "UNITED STATES" },
            { n: "KOEPANG",	l: "INDONESIA" },
            { n: "KOGE",	l: "DENMARK" },
            { n: "KOH KONG",	l: "CAMBODIA" },
            { n: "KOH KONG 12000",	l: "CAMBODIA" },
            { n: "KOH KONG 17000",	l: "CAMBODIA" },
            { n: "KOH KONG 20000",	l: "CAMBODIA" },
            { n: "KOH KONG 25000",	l: "CAMBODIA" },
            { n: "KOH PHALUAI",	l: "THAILAND" },
            { n: "KOH PHALUAI",	l: "THAILAND" },
            { n: "KOHSICHANG TPP",	l: "THAILAND" },
            { n: "KOHSICHANG TRANSHIPMENT AREA",	l: "THAILAND" },
            { n: "KOJE MARINE TERMINAL",	l: "KOREA, REPUBLIC" },
            { n: "KOJEONG",	l: "KOREA, REPUBLIC" },
            { n: "KOKKOLA",	l: "FINLAND" },
            { n: "KOKO",	l: "NIGERIA" },
            { n: "KOKURA",	l: "JAPAN" },
            { n: "KOLDING",	l: "DENMARK" },
            { n: "KOLE TERMINAL",	l: "CAMEROON" },
            { n: "KOLGUYEV ISLAND",	l: "RUSSIA" },
            { n: "KOLKATA",	l: "INDIA" },
            { n: "KOLLAFJORDUR",	l: "FAROE ISLANDS" },
            { n: "KOLO HARBOUR",	l: "SWEDEN" },
            { n: "KOLOBRZEG",	l: "POLAND" },
            { n: "KOLONODALE",	l: "INDONESIA" },
            { n: "KOLSNES",	l: "NORWAY" },
            { n: "KOMATSUJIMA",	l: "JAPAN" },
            { n: "KOME KRIBI 1 MARINE TERMINAL",	l: "CAMEROON" },
            { n: "KOMODO ISLAND",	l: "INDONESIA" },
            { n: "KOMPONG SOM",	l: "CAMBODIA" },
            { n: "KONA",	l: "UNITED STATES" },
            { n: "KONAKRI",	l: "GUINEA" },
            { n: "KONGSDAL",	l: "DENMARK" },
            { n: "KONGSFJORD",	l: "NORWAY" },
            { n: "KONGSHAVN",	l: "FAROE ISLANDS" },
            { n: "KONIGSBERG",	l: "RUSSIA" },
            { n: "KOOLAN ISLAND",	l: "AUSTRALIA" },
            { n: "KOPER",	l: "SLOVENIA" },
            { n: "KOPERVIK",	l: "NORWAY" },
            { n: "KOPING",	l: "SWEDEN" },
            { n: "KOPLI-PORT OF TALLINN",	l: "ESTONIA" },
            { n: "KORCULA",	l: "CROATIA" },
            { n: "KOREA (COUNTRY)",	l: "KOREA, REPUBLIC" },
            { n: "KOREA (NORTH) (COUNTRY)",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "KOREA (SOUTH) (COUNTRY)",	l: "KOREA, REPUBLIC" },
            { n: "KORFEZ",	l: "TURKEY" },
            { n: "KOROMACNO",	l: "CROATIA" },
            { n: "KORSAKOV",	l: "RUSSIA" },
            { n: "KORSNAES",	l: "FINLAND" },
            { n: "KORSOER",	l: "DENMARK" },
            { n: "KOS",	l: "GREECE" },
            { n: "KOSRAE",	l: "MICRONESIA" },
            { n: "KOSSEIR",	l: "EGYPT" },
            { n: "KOSULA",	l: "FINLAND" },
            { n: "KOTA BARU",	l: "INDONESIA" },
            { n: "KOTA KINABALU",	l: "MALAYSIA" },
            { n: "KOTKA",	l: "FINLAND" },
            { n: "KOTONU",	l: "BENIN" },
            { n: "KOTOR",	l: "MONTENEGRO" },
            { n: "KOTZEBUE",	l: "UNITED STATES" },
            { n: "KOUAOUA",	l: "NEW CALEDONIA" },
            { n: "KOUROU",	l: "FRENCH GUIANA" },
            { n: "KOVERHAR",	l: "FINLAND" },
            { n: "KOWEIT",	l: "KUWAIT" },
            { n: "KOWLOON",	l: "HONG KONG" },
            { n: "KOYO DOCK",	l: "JAPAN" },
            { n: "KOZAKI",	l: "JAPAN" },
            { n: "KOZHIKODE",	l: "INDIA" },
            { n: "KOZMINO",	l: "RUSSIA" },
            { n: "KPEME",	l: "TOGO" },
            { n: "KRABI",	l: "THAILAND" },
            { n: "KRAGERO",	l: "NORWAY" },
            { n: "KRALENDIJK",	l: "BONAIRE" },
            { n: "KRAMFORS",	l: "SWEDEN" },
            { n: "KRASNOGORSK",	l: "RUSSIA" },
            { n: "KRASNOVODSK",	l: "TURKMENISTAN" },
            { n: "KREFELD",	l: "GERMANY" },
            { n: "KRIBI",	l: "CAMEROON" },
            { n: "KRIKELO",	l: "GREECE" },
            { n: "KRISHNAPATNAM",	l: "INDIA" },
            { n: "KRISTIANSAND",	l: "NORWAY" },
            { n: "KRISTIANSAND (S)",	l: "NORWAY" },
            { n: "KRISTIANSUND",	l: "NORWAY" },
            { n: "KRISTIANSUND (N)",	l: "NORWAY" },
            { n: "KRISTINEHAMN",	l: "SWEDEN" },
            { n: "KRISTINESTAD",	l: "FINLAND" },
            { n: "KRK ISLAND",	l: "CROATIA" },
            { n: "KROMAN",	l: "TURKEY" },
            { n: "KRONSTADT",	l: "RUSSIA" },
            { n: "KROTZ SPRINGS",	l: "UNITED STATES" },
            { n: "KRUENG GEUKUEH",	l: "INDONESIA" },
            { n: "KRUENG RAYA",	l: "INDONESIA" },
            { n: "KUAH",	l: "MALAYSIA" },
            { n: "KUALA BARAM",	l: "MALAYSIA" },
            { n: "KUALA BELAIT",	l: "BRUNEI" },
            { n: "KUALA BEUKAH",	l: "INDONESIA" },
            { n: "KUALA ENOK",	l: "INDONESIA" },
            { n: "KUALA KAPUAS",	l: "INDONESIA" },
            { n: "KUALA LANGSA",	l: "INDONESIA" },
            { n: "KUALA TANJUNG",	l: "INDONESIA" },
            { n: "KUALA TUNGKAL",	l: "INDONESIA" },
            { n: "KUANTAN",	l: "MALAYSIA" },
            { n: "KUBIKENBORG",	l: "SWEDEN" },
            { n: "KUCHING",	l: "MALAYSIA" },
            { n: "KUDAMATSU",	l: "JAPAN" },
            { n: "KUDAT",	l: "MALAYSIA" },
            { n: "KUGLUKTUK, NU",	l: "CANADA" },
            { n: "KUITO TERMINAL",	l: "ANGOLA" },
            { n: "KUIVASTU",	l: "ESTONIA" },
            { n: "KULEVI",	l: "GEORGIA" },
            { n: "KULHUDUFFUSHI",	l: "MALDIVES" },
            { n: "KULTUS COVE",	l: "CANADA" },
            { n: "KULTUS COVE ANCHORAGE",	l: "CANADA" },
            { n: "KUMAI",	l: "INDONESIA" },
            { n: "KUMPORT",	l: "TURKEY" },
            { n: "KUMPUSALMI",	l: "FINLAND" },
            { n: "KUMUL MARINE TERMINAL",	l: "PAPUA NEW GUINEA" },
            { n: "KUNAK",	l: "MALAYSIA" },
            { n: "KUNDA",	l: "ESTONIA" },
            { n: "KUNDAPUR",	l: "INDIA" },
            { n: "KUNDAPURA",	l: "INDIA" },
            { n: "KUNGSOR",	l: "SWEDEN" },
            { n: "KUNSAN",	l: "KOREA, REPUBLIC" },
            { n: "KUNSHAN",	l: "CHINA" },
            { n: "KUOPIO",	l: "FINLAND" },
            { n: "KUPANG",	l: "INDONESIA" },
            { n: "KURE",	l: "JAPAN" },
            { n: "KURIHAMA",	l: "JAPAN" },
            { n: "KURIL ISLANDS",	l: "RUSSIA" },
            { n: "KURILSK",	l: "RUSSIA" },
            { n: "KURNELL",	l: "AUSTRALIA" },
            { n: "KUROSAKI",	l: "JAPAN" },
            { n: "KUSADASI",	l: "TURKEY" },
            { n: "KUSHIRO",	l: "JAPAN" },
            { n: "KUSKOKWIM BAY",	l: "UNITED STATES" },
            { n: "KUUJJUAQ",	l: "CANADA" },
            { n: "KUUJJUARAPIK",	l: "CANADA" },
            { n: "KUWAIT",	l: "KUWAIT" },
            { n: "KUWAIT (COUNTRY)",	l: "KUWAIT" },
            { n: "KVINESDAL",	l: "NORWAY" },
            { n: "KWA IBO",	l: "NIGERIA" },
            { n: "KWAI CHUNG",	l: "HONG KONG" },
            { n: "KWAJALEIN",	l: "MARSHALL ISLANDS" },
            { n: "KWANGYANG",	l: "KOREA, REPUBLIC" },
            { n: "KWINANA",	l: "AUSTRALIA" },
            { n: "KWUN TONG",	l: "HONG KONG" },
            { n: "KYLE OF LOCHALSH",	l: "UNITED KINGDOM" },
            { n: "KYMASSI",	l: "GREECE" },
            { n: "KYNDBY",	l: "DENMARK" },
            { n: "KYRENIA",	l: "CYPRUS" },
            { n: "KYUSHU SOUTH",	l: "JAPAN" },
            { n: "L. BEAUHARNOIS LOCK",	l: "CANADA" },
            { n: "LA BREA",	l: "TRINIDAD AND TOBAGO" },
            { n: "LA CEIBA (HONDURAS)",	l: "HONDURAS" },
            { n: "LA CEIBA (VENEZUELA)",	l: "VENEZUELA" },
            { n: "LA CHIMBA COVE",	l: "CHILE" },
            { n: "LA CIOTAT",	l: "FRANCE" },
            { n: "LA CORUNA",	l: "SPAIN" },
            { n: "LA ESTACA",	l: "SPAIN" },
            { n: "LA ESTACADA",	l: "VENEZUELA" },
            { n: "LA GOULETTE",	l: "TUNISIA" },
            { n: "LA GUAIRA",	l: "VENEZUELA" },
            { n: "LA HAVE",	l: "CANADA" },
            { n: "LA LIBERTAD (ECUADOR)",	l: "ECUADOR" },
            { n: "LA LIBERTAD (EL SALVADOR)",	l: "EL SALVADOR" },
            { n: "LA MADDALENA (CAGLIARI)",	l: "ITALY" },
            { n: "LA MADDALENA (SARDINIA)",	l: "ITALY" },
            { n: "LA MEDE",	l: "FRANCE" },
            { n: "LA NOUVELLE",	l: "FRANCE" },
            { n: "LA PALLICE",	l: "FRANCE" },
            { n: "LA PALMA",	l: "PANAMA" },
            { n: "LA PALOMA",	l: "URUGUAY" },
            { n: "LA PAMPILLA",	l: "PERU" },
            { n: "LA PAZ",	l: "MEXICO" },
            { n: "LA PLACE",	l: "UNITED STATES" },
            { n: "LA PLANCHARDA",	l: "PERU" },
            { n: "LA PLATA",	l: "ARGENTINA" },
            { n: "LA PLATA ROADS",	l: "ARGENTINA" },
            { n: "LA POINTE DE BERRE",	l: "FRANCE" },
            { n: "LA ROCHELLE",	l: "FRANCE" },
            { n: "LA ROCHELLE-PALLICE",	l: "FRANCE" },
            { n: "LA ROMANA",	l: "DOMINICAN REPUBLIC" },
            { n: "LA SALINA",	l: "VENEZUELA" },
            { n: "LA SALINETA",	l: "SPAIN" },
            { n: "LA SAVINA",	l: "SPAIN" },
            { n: "LA SEYNE-BREGAILLON",	l: "FRANCE" },
            { n: "LA SKHIRA",	l: "TUNISIA" },
            { n: "LA SPEZIA",	l: "ITALY" },
            { n: "LA TEJA",	l: "URUGUAY" },
            { n: "LA TRINITE",	l: "MARTINIQUE" },
            { n: "LA UNION",	l: "EL SALVADOR" },
            { n: "LAAJASALO OIL HARBOUR",	l: "FINLAND" },
            { n: "LAAYOUNE",	l: "WESTERN SAHARA" },
            { n: "LABADEE",	l: "HAITI" },
            { n: "LABAI",	l: "INDONESIA" },
            { n: "LABASA",	l: "FIJI" },
            { n: "LABOE",	l: "GERMANY" },
            { n: "LABUAN",	l: "MALAYSIA" },
            { n: "LABUAN AMUK TERMINAL",	l: "INDONESIA" },
            { n: "LABUANTRING",	l: "INDONESIA" },
            { n: "LADYSMITH",	l: "CANADA" },
            { n: "LAE",	l: "PAPUA NEW GUINEA" },
            { n: "LAEM CHABANG",	l: "THAILAND" },
            { n: "LAFFITEAU",	l: "HAITI" },
            { n: "LAGI (BIN TUANH)",	l: "VIETNAM" },
            { n: "LAGOS (GREECE)",	l: "GREECE" },
            { n: "LAGOS (NIGERIA)",	l: "NIGERIA" },
            { n: "LAGOS (PORTUGAL)",	l: "PORTUGAL" },
            { n: "LAGOS FREE ZONE ANCHORAGE AREA",	l: "NIGERIA" },
            { n: "LAGOS SAFE ANCHORAGE AREA",	l: "NIGERIA" },
            { n: "LAGUNA",	l: "BRAZIL" },
            { n: "LAGUNILLAS",	l: "VENEZUELA" },
            { n: "LAHAD DATU",	l: "MALAYSIA" },
            { n: "LAHAINA (WEST MAUI)",	l: "UNITED STATES" },
            { n: "LAIHEKOU",	l: "CHINA" },
            { n: "LAITEC",	l: "CHILE" },
            { n: "LAIZHOU",	l: "CHINA" },
            { n: "LAJES",	l: "PORTUGAL" },
            { n: "LAKACH BEACH PULL-IN",	l: "MEXICO" },
            { n: "LAKE BRAS D'OR",	l: "CANADA" },
            { n: "LAKE CALUMET HARBOR",	l: "UNITED STATES" },
            { n: "LAKE CHARLES",	l: "UNITED STATES" },
            { n: "LAKE HURON BUOYS 11 AND 12",	l: "UNITED STATES" },
            { n: "LAKE ST CLAIR CRIB LIGHT",	l: "UNITED STATES" },
            { n: "LAKES",	l: "CANADA" },
            { n: "LAKSELV",	l: "NORWAY" },
            { n: "LAKURAMAU",	l: "PAPUA NEW GUINEA" },
            { n: "LALANG MARINE TERMINAL",	l: "INDONESIA" },
            { n: "LAMA",	l: "VENEZUELA" },
            { n: "LAMAO",	l: "PHILIPPINES" },
            { n: "LAMBASA",	l: "FIJI" },
            { n: "LAMIA",	l: "GREECE" },
            { n: "LAMLASH HARBOUR",	l: "UNITED KINGDOM" },
            { n: "LAMMA ISLAND",	l: "HONG KONG" },
            { n: "LAMONGAN",	l: "INDONESIA" },
            { n: "LAMPEDUSA",	l: "ITALY" },
            { n: "LAMPUNG",	l: "INDONESIA" },
            { n: "LAMU",	l: "KENYA" },
            { n: "LAN SHUI TERMINAL",	l: "CHINA" },
            { n: "LANCASTER",	l: "UNITED KINGDOM" },
            { n: "LANDARIA",	l: "BRAZIL" },
            { n: "LAND'S END",	l: "UNITED KINGDOM" },
            { n: "LANDSKRONA",	l: "SWEDEN" },
            { n: "LANGESUND",	l: "NORWAY" },
            { n: "LANGKAWI PORT",	l: "MALAYSIA" },
            { n: "LANGROR",	l: "SWEDEN" },
            { n: "LANGSHAN",	l: "CHINA" },
            { n: "LANGTOU",	l: "CHINA" },
            { n: "LANJIATUO",	l: "CHINA" },
            { n: "LANQIAO",	l: "CHINA" },
            { n: "LANSHAN",	l: "CHINA" },
            { n: "LANSING SHOAL",	l: "UNITED STATES" },
            { n: "LANTIAN",	l: "CHINA" },
            { n: "LANXI",	l: "CHINA" },
            { n: "LAOGANG",	l: "CHINA" },
            { n: "LAOWU",	l: "CHINA" },
            { n: "LAPALUOTO",	l: "FINLAND" },
            { n: "LAPPEENRANTA",	l: "FINLAND" },
            { n: "LAPPOHJA",	l: "FINLAND" },
            { n: "LAQUINTA AND JEWELL FULTON",	l: "UNITED STATES" },
            { n: "LAQUINTA END",	l: "UNITED STATES" },
            { n: "LARACHE",	l: "MOROCCO" },
            { n: "LARAK ISLAND",	l: "IRAN" },
            { n: "LARANTUKA",	l: "INDONESIA" },
            { n: "LAREDO",	l: "CHILE" },
            { n: "LARENA",	l: "PHILIPPINES" },
            { n: "LARGS",	l: "UNITED KINGDOM" },
            { n: "LARIMNA",	l: "GREECE" },
            { n: "LARKOLLEN",	l: "NORWAY" },
            { n: "LARNACA",	l: "CYPRUS" },
            { n: "LARNE",	l: "UNITED KINGDOM" },
            { n: "LARONAI",	l: "INDONESIA" },
            { n: "LARVIK",	l: "NORWAY" },
            { n: "LAS LOSAS",	l: "CHILE" },
            { n: "LAS MAREAS",	l: "PUERTO RICO" },
            { n: "LAS MINAS BAY",	l: "PANAMA" },
            { n: "LAS PALMAS",	l: "SPAIN" },
            { n: "LAS PALMAS (ARG)",	l: "ARGENTINA" },
            { n: "LAS PIEDRAS",	l: "VENEZUELA" },
            { n: "LAS SALINAS",	l: "CHILE" },
            { n: "LAS VENTANAS",	l: "CHILE" },
            { n: "LATAKIA",	l: "SYRIA" },
            { n: "LATCHI",	l: "CYPRUS" },
            { n: "LATVIA (COUNTRY)",	l: "LATVIA" },
            { n: "LAUNCESTON",	l: "AUSTRALIA" },
            { n: "LAURIUM",	l: "GREECE" },
            { n: "LAUTOKA",	l: "FIJI" },
            { n: "LAVACA AND LAVACA REFUGE",	l: "UNITED STATES" },
            { n: "LAVACA END",	l: "UNITED STATES" },
            { n: "LAVACA REFUGE END",	l: "UNITED STATES" },
            { n: "LAVAN ISLAND",	l: "IRAN" },
            { n: "LAVERA",	l: "FRANCE" },
            { n: "LAVRION",	l: "GREECE" },
            { n: "LAWAS",	l: "MALAYSIA" },
            { n: "LAWAYAN",	l: "PHILIPPINES" },
            { n: "LAWI-LAWI TERMINAL",	l: "INDONESIA" },
            { n: "LAXE",	l: "SPAIN" },
            { n: "LAZAREV",	l: "RUSSIA" },
            { n: "LAZARO CARDENAS",	l: "MEXICO" },
            { n: "LAZI BAY",	l: "PHILIPPINES" },
            { n: "LE CHATEAU D'OLERON",	l: "FRANCE" },
            { n: "LE DONG PORT",	l: "CHINA" },
            { n: "LE GUILDO",	l: "FRANCE" },
            { n: "LE HAVRE",	l: "FRANCE" },
            { n: "LE LARIVOT",	l: "FRENCH GUIANA" },
            { n: "LE LEGUE/SAINT BRIEUC",	l: "FRANCE" },
            { n: "LE MON",	l: "VIETNAM" },
            { n: "LE PORT",	l: "REUNION" },
            { n: "LE TRAIT",	l: "FRANCE" },
            { n: "LE TREPORT",	l: "FRANCE" },
            { n: "LE VERDON",	l: "FRANCE" },
            { n: "LEAHILL QUARRY",	l: "IRELAND" },
            { n: "LEAMINGTON",	l: "CANADA" },
            { n: "LEBANON (COUNTRY)",	l: "LEBANON" },
            { n: "LEER",	l: "GERMANY" },
            { n: "LEFKADA",	l: "GREECE" },
            { n: "LEGASPI",	l: "PHILIPPINES" },
            { n: "LEGAZPI",	l: "PHILIPPINES" },
            { n: "LEGHORN",	l: "ITALY" },
            { n: "LEHAVRE-HBG",	l: "FRANCE" },
            { n: "LEIRVIK",	l: "NORWAY" },
            { n: "LEITH",	l: "UNITED KINGDOM" },
            { n: "LEIXOES",	l: "PORTUGAL" },
            { n: "LEKIR BULK TERMINAL",	l: "MALAYSIA" },
            { n: "LEMBAR",	l: "INDONESIA" },
            { n: "LEMONT",	l: "UNITED STATES" },
            { n: "LEMVIG",	l: "DENMARK" },
            { n: "LENADURA",	l: "CHILE" },
            { n: "LENINGRAD",	l: "RUSSIA" },
            { n: "LEROS ISLAND",	l: "GREECE" },
            { n: "LERWICK",	l: "UNITED KINGDOM" },
            { n: "LES ESCOUMINS",	l: "CANADA" },
            { n: "LES MECHINS",	l: "CANADA" },
            { n: "LES RAZADES",	l: "CANADA" },
            { n: "LES SABLES D'OLONNE",	l: "FRANCE" },
            { n: "L'ESPIGUETTE",	l: "FRANCE" },
            { n: "LESSREMMAN",	l: "NORWAY" },
            { n: "LEVANGER",	l: "NORWAY" },
            { n: "LEVIY BEREG",	l: "RUSSIA" },
            { n: "LEVUKA",	l: "FIJI" },
            { n: "LEWISPORTE",	l: "CANADA" },
            { n: "LEWISTON",	l: "UNITED STATES" },
            { n: "LHOKNGA",	l: "INDONESIA" },
            { n: "LHOKSEUMAWE",	l: "INDONESIA" },
            { n: "LIANGDUO",	l: "CHINA" },
            { n: "LIANYUNGANG",	l: "CHINA" },
            { n: "LIBAU",	l: "LATVIA" },
            { n: "LIBERIA (COUNTRY)",	l: "LIBERIA" },
            { n: "LIBJO",	l: "PHILIPPINES" },
            { n: "LIBOURNE",	l: "FRANCE" },
            { n: "LIBREVILLE",	l: "GABON" },
            { n: "LIBYA (COUNTRY)",	l: "LIBYA" },
            { n: "LICATA",	l: "ITALY" },
            { n: "LICKING END",	l: "UNITED STATES" },
            { n: "LICKING START",	l: "UNITED STATES" },
            { n: "LIDKOPING",	l: "SWEDEN" },
            { n: "LIEGE",	l: "BELGIUM" },
            { n: "LIELUPE",	l: "LATVIA" },
            { n: "LIEPAJA",	l: "LATVIA" },
            { n: "LIERSTRANDA",	l: "NORWAY" },
            { n: "LIFOU ISLE",	l: "NEW CALEDONIA" },
            { n: "LIGANG",	l: "CHINA" },
            { n: "LIHEKOU",	l: "CHINA" },
            { n: "LIHIR ISLAND",	l: "PAPUA NEW GUINEA" },
            { n: "LIJIAXIANG",	l: "CHINA" },
            { n: "LILLEBONNE",	l: "FRANCE" },
            { n: "LILLESAND",	l: "NORWAY" },
            { n: "LILOAN",	l: "PHILIPPINES" },
            { n: "LIMA (ARGENTINA)",	l: "ARGENTINA" },
            { n: "LIMAS",	l: "TURKEY" },
            { n: "LIMASSOL",	l: "CYPRUS" },
            { n: "LIMAY (FRANCE)",	l: "FRANCE" },
            { n: "LIMAY (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "LIMBANG",	l: "MALAYSIA" },
            { n: "LIMBE",	l: "CAMEROON" },
            { n: "LIMBOH TERMINAL",	l: "CAMEROON" },
            { n: "LIMENARIA",	l: "GREECE" },
            { n: "LIMERICK",	l: "IRELAND" },
            { n: "LIMETREE BAY",	l: "VIRGIN ISLANDS" },
            { n: "LIMFJORDEN",	l: "DENMARK" },
            { n: "LIMHAMN",	l: "SWEDEN" },
            { n: "LINAU ANCHORAGE",	l: "INDONESIA" },
            { n: "LINDEN",	l: "GUYANA" },
            { n: "LINDEN, NJ",	l: "UNITED STATES" },
            { n: "LINDESNES",	l: "NORWAY" },
            { n: "LINDHOLM",	l: "DENMARK" },
            { n: "LINDI",	l: "TANZANIA" },
            { n: "LINDOE TERMINAL",	l: "DENMARK" },
            { n: "LINGANG",	l: "CHINA" },
            { n: "LINGGA",	l: "MALAYSIA" },
            { n: "LINGGI",	l: "MALAYSIA" },
            { n: "LINKOU",	l: "TAIWAN" },
            { n: "LIPARI",	l: "ITALY" },
            { n: "LIPCI",	l: "MONTENEGRO" },
            { n: "LIRQUEN",	l: "CHILE" },
            { n: "LISAHALLY PORT",	l: "UNITED KINGDOM" },
            { n: "LISB/HBG",	l: "PORTUGAL" },
            { n: "LISBON",	l: "PORTUGAL" },
            { n: "LISCOMB",	l: "CANADA" },
            { n: "LITH",	l: "SAUDI ARABIA" },
            { n: "LITHUANIA (COUNTRY)",	l: "LITHUANIA" },
            { n: "LITTLE ADEN",	l: "YEMEN" },
            { n: "LITTLE BAY",	l: "MONTSERRAT" },
            { n: "LITTLE CREEK",	l: "UNITED STATES" },
            { n: "LITTLE CURRENT PASSAGE",	l: "CANADA" },
            { n: "LITTLE NARROWS",	l: "CANADA" },
            { n: "LITTLE RIVER SC",	l: "UNITED STATES" },
            { n: "LITTLE SABLE LINE",	l: "UNITED STATES" },
            { n: "LITTLE TENNESSEE END",	l: "UNITED STATES" },
            { n: "LITTLE TENNESSEE START",	l: "UNITED STATES" },
            { n: "LITTLEHAMPTON",	l: "UNITED KINGDOM" },
            { n: "LIUGONGQIAO",	l: "CHINA" },
            { n: "LIUHENG",	l: "CHINA" },
            { n: "LIUZHOU",	l: "CHINA" },
            { n: "LIVERPOOL (CANADA)",	l: "CANADA" },
            { n: "LIVERPOOL (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "LIVERPOOL BAY TERMINAL",	l: "UNITED KINGDOM" },
            { n: "LIVORNO",	l: "ITALY" },
            { n: "LIXOURI",	l: "GREECE" },
            { n: "LIZHIYUAN",	l: "CHINA" },
            { n: "LIZUIZI",	l: "CHINA" },
            { n: "LLANDDULAS",	l: "UNITED KINGDOM" },
            { n: "LOBAM ANCHORAGE",	l: "INDONESIA" },
            { n: "LOBITO",	l: "ANGOLA" },
            { n: "LOBITOS",	l: "PERU" },
            { n: "LOBOS ISLANDS",	l: "PERU" },
            { n: "LOCH CARNAN",	l: "UNITED KINGDOM" },
            { n: "LOCH EWE",	l: "UNITED KINGDOM" },
            { n: "LOCH GAIRLOCH",	l: "UNITED KINGDOM" },
            { n: "LOCH STRIVEN",	l: "UNITED KINGDOM" },
            { n: "LOCHALINE",	l: "UNITED KINGDOM" },
            { n: "LOCHBOISDALE",	l: "UNITED KINGDOM" },
            { n: "LOCHMADDY",	l: "UNITED KINGDOM" },
            { n: "LOCKEPORT",	l: "CANADA" },
            { n: "LOCODJO",	l: "COTE D'IVOIRE" },
            { n: "LOCTUDY",	l: "FRANCE" },
            { n: "LOCUST FORK END",	l: "UNITED STATES" },
            { n: "LOCUST FORK START",	l: "UNITED STATES" },
            { n: "LOCUST POINT",	l: "UNITED STATES" },
            { n: "LODINGEN",	l: "NORWAY" },
            { n: "LOFOTEN",	l: "NORWAY" },
            { n: "LOFUNG",	l: "SOLOMON ISLANDS" },
            { n: "LOHEIYA",	l: "YEMEN" },
            { n: "LOLOHO",	l: "PAPUA NEW GUINEA" },
            { n: "LOMBO MARINE TERMINAL",	l: "ANGOLA" },
            { n: "LOMBOK (LEMBAR)",	l: "INDONESIA" },
            { n: "LOMBOK STRAIT",	l: "INDONESIA" },
            { n: "LOMBOK STRAIT",	l: "INDONESIA" },
            { n: "LOMBRUM",	l: "PAPUA NEW GUINEA" },
            { n: "LOME",	l: "TOGO" },
            { n: "LOMMA",	l: "SWEDEN" },
            { n: "LOMONOSOV",	l: "RUSSIA" },
            { n: "LONDON",	l: "UNITED KINGDOM" },
            { n: "LONDONDERRY",	l: "UNITED KINGDOM" },
            { n: "LONG BEACH",	l: "UNITED STATES" },
            { n: "LONG HARBOUR",	l: "CANADA" },
            { n: "LONG ISLAND LP",	l: "UNITED STATES" },
            { n: "LONG ISLAND POINT",	l: "AUSTRALIA" },
            { n: "LONG POINT",	l: "SAINT KITTS AND NEVIS" },
            { n: "LONG POINT CLEVELAND",	l: "UNITED STATES" },
            { n: "LONG POINT DOWNBOUND",	l: "CANADA" },
            { n: "LONG POINT UPBOUND",	l: "CANADA" },
            { n: "LONG POND",	l: "CANADA" },
            { n: "LONG REACH",	l: "AUSTRALIA" },
            { n: "LONGHOPE",	l: "UNITED KINGDOM" },
            { n: "LONGKOU",	l: "CHINA" },
            { n: "LONGONI",	l: "COMOROS" },
            { n: "LONGSHANGANG",	l: "CHINA" },
            { n: "LONGSHIPS",	l: "UNITED KINGDOM" },
            { n: "LONGSTAFF BEACH, NU",	l: "CANADA" },
            { n: "LONGVIEW",	l: "UNITED STATES" },
            { n: "LONGYAN",	l: "CHINA" },
            { n: "LONGYEARBYEN",	l: "NORWAY" },
            { n: "LONGYEARBYEN (SPITZBERGEN)",	l: "NORWAY" },
            { n: "LOOP TERMINAL",	l: "UNITED STATES" },
            { n: "LOPRA",	l: "FAROE ISLANDS" },
            { n: "LORAIN",	l: "UNITED STATES" },
            { n: "LORENGAU",	l: "PAPUA NEW GUINEA" },
            { n: "LORETO",	l: "MEXICO" },
            { n: "LORETO (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "LORIENT",	l: "FRANCE" },
            { n: "LORIM POINT",	l: "AUSTRALIA" },
            { n: "LOS ANGELES",	l: "UNITED STATES" },
            { n: "LOS COCOS",	l: "VENEZUELA" },
            { n: "LOS ORGANOS",	l: "PERU" },
            { n: "LOSSIEMOUTH",	l: "UNITED KINGDOM" },
            { n: "LOTA",	l: "CHILE" },
            { n: "LOUDDEN",	l: "SWEDEN" },
            { n: "LOUGH SWILLY",	l: "IRELAND" },
            { n: "LOUISBOURG",	l: "CANADA" },
            { n: "LOUISIANA OFFSHORE OIL PORT",	l: "UNITED STATES" },
            { n: "LOUISVILLE RIVERPORT",	l: "UNITED STATES" },
            { n: "LOUMEN",	l: "CHINA" },
            { n: "LOURENCO MARQUES",	l: "MOZAMBIQUE" },
            { n: "LOUTANG (JIADING)",	l: "CHINA" },
            { n: "LOUTANG (SONGJIANG)",	l: "CHINA" },
            { n: "LOVIISA",	l: "FINLAND" },
            { n: "LOWDRAFT-ANTWERP",	l: "NETHERLANDS" },
            { n: "LOWDRAFT-JUBAIL-SITRA",	l: "SAUDI ARABIA" },
            { n: "LOWDRAFT-LOMBOK",	l: "INDONESIA" },
            { n: "LOWDRAFT-MAGELLAN",	l: "CHILE" },
            { n: "LOWDRAFT-MOKPO",	l: "KOREA, REPUBLIC" },
            { n: "LOWDRAFT-NANJING",	l: "CHINA" },
            { n: "LOWDRAFT-RAZENDE BOL",	l: "NETHERLANDS" },
            { n: "LOWDRAFT-ROTTERDAM",	l: "NETHERLANDS" },
            { n: "LOWDRAFT-SRILANKA",	l: "SRI LANKA" },
            { n: "LOWDRAFT-VANUATU",	l: "VANUATU" },
            { n: "LOWER COVE",	l: "CANADA" },
            { n: "LOWER MISSISSIPPI AND BC",	l: "UNITED STATES" },
            { n: "LOWER MISSISSIPPI START",	l: "UNITED STATES" },
            { n: "LOWESTOFT",	l: "UNITED KINGDOM" },
            { n: "LOYANG",	l: "SINGAPORE" },
            { n: "LPA 1",	l: "CANADA" },
            { n: "LPA 2",	l: "CANADA" },
            { n: "LSP-CAPEVINCENT-TORONTO",	l: "UNITED STATES" },
            { n: "LSP-COLBORNE-SESHOAL",	l: "UNITED STATES" },
            { n: "LSP-DETOUR-STMARIE",	l: "CANADA" },
            { n: "LSP-DETROITRIVERLIGHT-SARNIA",	l: "CANADA" },
            { n: "LSP-ESCOUMINS-QUEBEC",	l: "CANADA" },
            { n: "LSP-MONTREAL-STLAMBERT",	l: "CANADA" },
            { n: "LSP-QUEBEC-THREERIVERS",	l: "CANADA" },
            { n: "LSP-SARNIA-DETOUR",	l: "CANADA" },
            { n: "LSP-SESHOAL-DETROITRIVERLIGHT",	l: "UNITED STATES" },
            { n: "LSP-STLAMBERT-CAPEVINCENT",	l: "CANADA" },
            { n: "LSP-STMARIE-THUNDERBAY",	l: "CANADA" },
            { n: "LSP-THREERIVERS-MONTREAL",	l: "CANADA" },
            { n: "LSP-WELLER-COLBORNE",	l: "CANADA" },
            { n: "LUANDA",	l: "ANGOLA" },
            { n: "LUARCA",	l: "SPAIN" },
            { n: "LUBA",	l: "EQUATORIAL GUINEA" },
            { n: "LUBECK",	l: "GERMANY" },
            { n: "LUBMIN",	l: "GERMANY" },
            { n: "LUBUK GAUNG",	l: "INDONESIA" },
            { n: "LUBUK TUTUNG",	l: "INDONESIA" },
            { n: "LUCANIN",	l: "PHILIPPINES" },
            { n: "LUCEA",	l: "JAMAICA" },
            { n: "LUCHAO",	l: "CHINA" },
            { n: "LUCINA TERMINAL",	l: "GABON" },
            { n: "LUCINDA",	l: "AUSTRALIA" },
            { n: "LUDERITZ",	l: "NAMIBIA" },
            { n: "LUDINGTON",	l: "UNITED STATES" },
            { n: "LUDWIGSHAFEN AM RHEIN",	l: "GERMANY" },
            { n: "LUFENG TERMINAL",	l: "CHINA" },
            { n: "LUGAIT",	l: "PHILIPPINES" },
            { n: "LUGANVILLE",	l: "VANUATU" },
            { n: "LUGNVIK",	l: "SWEDEN" },
            { n: "LUHAIYA",	l: "YEMEN" },
            { n: "LUHUASHAN",	l: "CHINA" },
            { n: "LUIS FAUSTO DE MEDEIROS",	l: "BRAZIL" },
            { n: "LUKA GRUZ",	l: "CROATIA" },
            { n: "LUKUK GAUNG",	l: "INDONESIA" },
            { n: "LULEA",	l: "SWEDEN" },
            { n: "LUMMUS ISLAND",	l: "UNITED STATES" },
            { n: "LUMUT (BRUNEI)",	l: "BRUNEI" },
            { n: "LUMUT (MALAYSIA)",	l: "MALAYSIA" },
            { n: "LUNAO",	l: "PHILIPPINES" },
            { n: "LUNDU",	l: "MALAYSIA" },
            { n: "LUNENBURG",	l: "CANADA" },
            { n: "LUOJING",	l: "CHINA" },
            { n: "LUOYUAN",	l: "CHINA" },
            { n: "LURA",	l: "NORWAY" },
            { n: "LUSHUN",	l: "CHINA" },
            { n: "LUTONG",	l: "MALAYSIA" },
            { n: "LUWUK (EAST SULAWESI)",	l: "INDONESIA" },
            { n: "LUZHOU",	l: "CHINA" },
            { n: "LVSI",	l: "CHINA" },
            { n: "LVSI",	l: "CHINA" },
            { n: "LVSI DATANG TERMINAL",	l: "CHINA" },
            { n: "LYDIA ANN CHANNEL END",	l: "UNITED STATES" },
            { n: "LYDNEY",	l: "UNITED KINGDOM" },
            { n: "LYME BAY OFFSHORE",	l: "UNITED KINGDOM" },
            { n: "LYNDIANE",	l: "SENEGAL" },
            { n: "LYNESS",	l: "UNITED KINGDOM" },
            { n: "LYNGS ODDE",	l: "DENMARK" },
            { n: "LYSAKER",	l: "NORWAY" },
            { n: "LYSEKIL",	l: "SWEDEN" },
            { n: "LYTTELTON",	l: "NEW ZEALAND" },
            { n: "LYTTON",	l: "AUSTRALIA" },
            { n: "MAADIYA PORT",	l: "EGYPT" },
            { n: "MA'ALLA TERMINAL",	l: "YEMEN" },
            { n: "MAALOY",	l: "NORWAY" },
            { n: "MAANSHAN",	l: "CHINA" },
            { n: "MAARI",	l: "NEW ZEALAND" },
            { n: "MAASIN",	l: "PHILIPPINES" },
            { n: "MAASSLUIS",	l: "NETHERLANDS" },
            { n: "MAASVLAKTE",	l: "NETHERLANDS" },
            { n: "MABINI",	l: "PHILIPPINES" },
            { n: "MABULI ISLAND PORT",	l: "INDONESIA" },
            { n: "MACABI",	l: "CUBA" },
            { n: "MACAE",	l: "BRAZIL" },
            { n: "MACAPA",	l: "BRAZIL" },
            { n: "MACASSAR",	l: "INDONESIA" },
            { n: "MACAU",	l: "MACAU" },
            { n: "MACAU (COUNTRY)",	l: "MACAU" },
            { n: "MACDUFF",	l: "UNITED KINGDOM" },
            { n: "MACEIO",	l: "BRAZIL" },
            { n: "MACHILIPATNAM",	l: "INDIA" },
            { n: "MACHONG",	l: "CHINA" },
            { n: "MACIN",	l: "ROMANIA" },
            { n: "MACKAY",	l: "AUSTRALIA" },
            { n: "MACKENZIE",	l: "UNITED STATES" },
            { n: "MACKINAC BRIDGE",	l: "UNITED STATES" },
            { n: "MACQUARIE POINT",	l: "AUSTRALIA" },
            { n: "MACUN",	l: "CHINA" },
            { n: "MACURO",	l: "VENEZUELA" },
            { n: "MACUSE",	l: "MOZAMBIQUE" },
            { n: "MADAGASCAR (COUNTRY)",	l: "MADAGASCAR" },
            { n: "MADAGASCAR-KARACHI LINE",	l: "MADAGASCAR" },
            { n: "MADANG",	l: "PAPUA NEW GUINEA" },
            { n: "MADEIRA",	l: "PORTUGAL" },
            { n: "MADEIRA (COUNTRY)",	l: "PORTUGAL" },
            { n: "MADRAS",	l: "INDIA" },
            { n: "MADRE DEUS",	l: "BRAZIL" },
            { n: "MADRYN",	l: "ARGENTINA" },
            { n: "MADURA",	l: "INDONESIA" },
            { n: "MAERSK NGUJIMA-YIN",	l: "AUSTRALIA" },
            { n: "MAGADAN",	l: "RUSSIA" },
            { n: "MAGDALENA FJORD",	l: "NORWAY" },
            { n: "MAGDELLA",	l: "INDIA" },
            { n: "MAGELLAN ST.- DUNGENESS",	l: "CHILE" },
            { n: "MAGELLAN ST.- PILAR",	l: "CHILE" },
            { n: "MAGELLAN STRAIT",	l: "CHILE" },
            { n: "MAGNISI",	l: "ITALY" },
            { n: "MAGO",	l: "RUSSIA" },
            { n: "MAHAJANGA",	l: "MADAGASCAR" },
            { n: "MAHAKAM RIVER",	l: "INDONESIA" },
            { n: "MAHE",	l: "SEYCHELLES" },
            { n: "MAHMUDIA",	l: "ROMANIA" },
            { n: "MAHON",	l: "SPAIN" },
            { n: "MAILIAO",	l: "TAIWAN" },
            { n: "MAI-LIAO",	l: "TAIWAN" },
            { n: "MAIN BROOK",	l: "CANADA" },
            { n: "MAINTENANCE DREDGING FEE ZONE",	l: "CANADA" },
            { n: "MAINZ",	l: "GERMANY" },
            { n: "MAITLAND HARBOR",	l: "CANADA" },
            { n: "MAIZURU",	l: "JAPAN" },
            { n: "MAJENE",	l: "INDONESIA" },
            { n: "MAJIAHE",	l: "CHINA" },
            { n: "MAJISHAN",	l: "CHINA" },
            { n: "MAJUNGA",	l: "MADAGASCAR" },
            { n: "MAJURO",	l: "MARSHALL ISLANDS" },
            { n: "MAKAR",	l: "PHILIPPINES" },
            { n: "MAKASSAR",	l: "INDONESIA" },
            { n: "MAKHACHKALA",	l: "RUSSIA" },
            { n: "MA-KUNG",	l: "TAIWAN" },
            { n: "MALABO",	l: "EQUATORIAL GUINEA" },
            { n: "MALABO (NIGERIA)",	l: "NIGERIA" },
            { n: "MALABRIGO",	l: "PERU" },
            { n: "MALACCA",	l: "MALAYSIA" },
            { n: "MALACCA STRAIT",	l: "MALAYSIA" },
            { n: "MALAGA",	l: "SPAIN" },
            { n: "MALAGARI",	l: "GREECE" },
            { n: "MALAHAYATI",	l: "INDONESIA" },
            { n: "MALAKAL HARBOR",	l: "PACIFIC ISLANDS (PALAU)" },
            { n: "MALAMPAYA",	l: "PHILIPPINES" },
            { n: "MALAMPAYA TERMINAL",	l: "PHILIPPINES" },
            { n: "MALAU",	l: "FIJI" },
            { n: "MALAY COVE",	l: "SRI LANKA" },
            { n: "MALAYSIA (COUNTRY)",	l: "MALAYSIA" },
            { n: "MALDIVES (COUNTRY)",	l: "MALDIVES" },
            { n: "MALDON",	l: "UNITED KINGDOM" },
            { n: "MALE",	l: "MALDIVES" },
            { n: "MALHADO",	l: "BRAZIL" },
            { n: "MALI LOSINJ",	l: "CROATIA" },
            { n: "MALILI",	l: "INDONESIA" },
            { n: "MALIN HEAD",	l: "IRELAND" },
            { n: "MALINDI",	l: "KENYA" },
            { n: "MALLAIG",	l: "UNITED KINGDOM" },
            { n: "MALLOCO BAY",	l: "SOLOMON ISLANDS" },
            { n: "MALM",	l: "NORWAY" },
            { n: "MALMO",	l: "SWEDEN" },
            { n: "MALONG TERMINAL",	l: "MALAYSIA" },
            { n: "MALONGO TERMINAL",	l: "ANGOLA" },
            { n: "MALPE",	l: "INDIA" },
            { n: "MALTA (COUNTRY)",	l: "MALTA" },
            { n: "MALTA (GRAND HARBOUR)",	l: "MALTA" },
            { n: "MAMONAL",	l: "COLOMBIA" },
            { n: "MAMOUDZOU",	l: "COMOROS" },
            { n: "MANADO",	l: "INDONESIA" },
            { n: "MANAKARA",	l: "MADAGASCAR" },
            { n: "MANAMA",	l: "BAHRAIN" },
            { n: "MANANJARY",	l: "MADAGASCAR" },
            { n: "MANATI",	l: "CUBA" },
            { n: "MANAUS",	l: "BRAZIL" },
            { n: "MANCHESTER",	l: "UNITED KINGDOM" },
            { n: "MANCHESTER (TEXAS - USA)",	l: "UNITED STATES" },
            { n: "MANCHESTER (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "MANCHESTER SHIP CANAL",	l: "UNITED KINGDOM" },
            { n: "MANDAL",	l: "NORWAY" },
            { n: "MANDRAKI",	l: "GREECE" },
            { n: "MANDUKIO",	l: "GREECE" },
            { n: "MANDVI",	l: "INDIA" },
            { n: "MANFREDONIA",	l: "ITALY" },
            { n: "MANGALIA",	l: "ROMANIA" },
            { n: "MANGALORE",	l: "INDIA" },
            { n: "MANGGAR",	l: "INDONESIA" },
            { n: "MANGGIS",	l: "INDONESIA" },
            { n: "MANGKAJANG",	l: "INDONESIA" },
            { n: "MANGKASA OIL TERMINAL",	l: "INDONESIA" },
            { n: "MANHATTAN",	l: "UNITED STATES" },
            { n: "MANICANI",	l: "PHILIPPINES" },
            { n: "MANIITSOQ",	l: "GREENLAND" },
            { n: "MANILA",	l: "PHILIPPINES" },
            { n: "MANISTEE",	l: "UNITED STATES" },
            { n: "MANITOU ISLAND",	l: "UNITED STATES" },
            { n: "MANITOU SHOAL LIGHT",	l: "UNITED STATES" },
            { n: "MANITOWOC",	l: "UNITED STATES" },
            { n: "MANNHEIM",	l: "GERMANY" },
            { n: "MANOKWARI",	l: "INDONESIA" },
            { n: "MANORA",	l: "THAILAND" },
            { n: "MANTA",	l: "ECUADOR" },
            { n: "MANTEO NC",	l: "UNITED STATES" },
            { n: "MANTES",	l: "FRANCE" },
            { n: "MANTYLUOTO",	l: "FINLAND" },
            { n: "MANURAN",	l: "INDONESIA" },
            { n: "MANURAN-WAIGEO UTARA",	l: "INDONESIA" },
            { n: "MANZANILLO (CUBA)",	l: "CUBA" },
            { n: "MANZANILLO (DOMINICAN REP)",	l: "DOMINICAN REPUBLIC" },
            { n: "MANZANILLO (MEXICO)",	l: "MEXICO" },
            { n: "MANZANILLO INTL TERMINAL",	l: "PANAMA" },
            { n: "MAOCAOJIE",	l: "CHINA" },
            { n: "MAOERTUO",	l: "CHINA" },
            { n: "MAOMING",	l: "CHINA" },
            { n: "MAP TA PHUT",	l: "THAILAND" },
            { n: "MAPUTO",	l: "MOZAMBIQUE" },
            { n: "MAQAL",	l: "IRAQ" },
            { n: "MAR DEL PLATA",	l: "ARGENTINA" },
            { n: "MARACAIBO",	l: "VENEZUELA" },
            { n: "MARANS",	l: "FRANCE" },
            { n: "MARATHOCAMPOS",	l: "GREECE" },
            { n: "MARATHON",	l: "CANADA" },
            { n: "MARATHOUPOLIS",	l: "GREECE" },
            { n: "MARBLEHEAD",	l: "UNITED STATES" },
            { n: "MARCH POINT",	l: "UNITED STATES" },
            { n: "MARCHWOOD",	l: "UNITED KINGDOM" },
            { n: "MARCUS HOOK",	l: "UNITED STATES" },
            { n: "MARE (NEW CALEDONIA)",	l: "NEW CALEDONIA" },
            { n: "MARE ISLAND",	l: "UNITED STATES" },
            { n: "MARGATE",	l: "UNITED KINGDOM" },
            { n: "MARIAGER",	l: "DENMARK" },
            { n: "MARIANAS ISLANDS (COUNTRY)",	l: "NORTHERN MARIANA ISLANDS" },
            { n: "MARIEHAMN",	l: "FINLAND" },
            { n: "MARIEL",	l: "CUBA" },
            { n: "MARIESTAD",	l: "SWEDEN" },
            { n: "MARIFU",	l: "JAPAN" },
            { n: "MARIGOT",	l: "SINT MAARTEN (DUTCH PART)" },
            { n: "MARIN (MARTINIQUE)",	l: "MARTINIQUE" },
            { n: "MARIN (SPAIN)",	l: "SPAIN" },
            { n: "MARINA DI CARRARA",	l: "ITALY" },
            { n: "MARINE CITY",	l: "UNITED STATES" },
            { n: "MARINETTE",	l: "UNITED STATES" },
            { n: "MARIUP",	l: "UKRAINE" },
            { n: "MARIUPOL",	l: "UKRAINE" },
            { n: "MARIVELES",	l: "PHILIPPINES" },
            { n: "MARL",	l: "GERMANY" },
            { n: "MARLIM TERMINAL",	l: "BRAZIL" },
            { n: "MARMAGAO",	l: "INDIA" },
            { n: "MARMARA",	l: "TURKEY" },
            { n: "MARMARA EREGLISI",	l: "TURKEY" },
            { n: "MARMARIS",	l: "TURKEY" },
            { n: "MARMORILIK",	l: "GREENLAND" },
            { n: "MARQUETTE",	l: "UNITED STATES" },
            { n: "MARRERO",	l: "UNITED STATES" },
            { n: "MARSA AL HARIGA",	l: "LIBYA" },
            { n: "MARSA BASHAYER EXPORT TERMINAL",	l: "SUDAN" },
            { n: "MARSA EL BREGA",	l: "LIBYA" },
            { n: "MARSA EL HAMRA",	l: "EGYPT" },
            { n: "MARSALA",	l: "ITALY" },
            { n: "MARSAXLOKK",	l: "MALTA" },
            { n: "MARSDEN POINT",	l: "NEW ZEALAND" },
            { n: "MARSEILLES",	l: "FRANCE" },
            { n: "MARSHALL",	l: "LIBERIA" },
            { n: "MARSHALL ISLANDS (COUNTRY)",	l: "MARSHALL ISLANDS" },
            { n: "MARSTAL",	l: "DENMARK" },
            { n: "MARSTRAND",	l: "SWEDEN" },
            { n: "MARTAS",	l: "TURKEY" },
            { n: "MARTINEZ",	l: "UNITED STATES" },
            { n: "MARTINIQUE",	l: "MARTINIQUE" },
            { n: "MARTINIQUE (COUNTRY)",	l: "MARTINIQUE" },
            { n: "MARUGAME",	l: "JAPAN" },
            { n: "MARYBOROUGH",	l: "AUSTRALIA" },
            { n: "MARYSTOWN",	l: "CANADA" },
            { n: "MARYSVILLE",	l: "UNITED STATES" },
            { n: "MASAN",	l: "KOREA, REPUBLIC" },
            { n: "MASAO",	l: "PHILIPPINES" },
            { n: "MASBATE",	l: "PHILIPPINES" },
            { n: "MASINLOC",	l: "PHILIPPINES" },
            { n: "MASIRAH ISLAND",	l: "OMAN" },
            { n: "MASLENICA",	l: "CROATIA" },
            { n: "MASNEDOVAERKETS HAVN",	l: "DENMARK" },
            { n: "MASQAT",	l: "OMAN" },
            { n: "MASSAWA",	l: "ERITREA" },
            { n: "MASSENA",	l: "UNITED STATES" },
            { n: "MASSONGO TERMINAL",	l: "CAMEROON" },
            { n: "MATADI",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "MATAGORDA AND LAVACA",	l: "UNITED STATES" },
            { n: "MATAGORDA CHANNEL END",	l: "UNITED STATES" },
            { n: "MATALVIS",	l: "PHILIPPINES" },
            { n: "MATANE",	l: "CANADA" },
            { n: "MATANZAS (CUBA)",	l: "CUBA" },
            { n: "MATANZAS (VENEZUELA)",	l: "VENEZUELA" },
            { n: "MATARANI",	l: "PERU" },
            { n: "MATEGATA",	l: "JAPAN" },
            { n: "MATI",	l: "PHILIPPINES" },
            { n: "MATOLA",	l: "MOZAMBIQUE" },
            { n: "MATSUNAGA",	l: "JAPAN" },
            { n: "MATSUSHIMA",	l: "JAPAN" },
            { n: "MATSUURA",	l: "JAPAN" },
            { n: "MATSUYA",	l: "JAPAN" },
            { n: "MATSUYAMA",	l: "JAPAN" },
            { n: "MAUBAN",	l: "PHILIPPINES" },
            { n: "MAUI (LAHAINA)",	l: "UNITED STATES" },
            { n: "MAUMERE",	l: "INDONESIA" },
            { n: "MAURITANIA (COUNTRY)",	l: "MAURITANIA" },
            { n: "MAURITIUS (COUNTRY)",	l: "MAURITIUS" },
            { n: "MAWAN",	l: "CHINA" },
            { n: "MAWEI",	l: "CHINA" },
            { n: "MAWLAMYINE",	l: "MYANMAR" },
            { n: "MAYAGUANA",	l: "THE BAHAMAS" },
            { n: "MAYAGUEZ",	l: "PUERTO RICO" },
            { n: "MAYDOWN",	l: "UNITED KINGDOM" },
            { n: "MAYOTTE",	l: "COMOROS" },
            { n: "MAYUMBA",	l: "GABON" },
            { n: "MAZARA DEL VALLO",	l: "ITALY" },
            { n: "MAZARRON",	l: "SPAIN" },
            { n: "MAZATLAN",	l: "MEXICO" },
            { n: "M'BAO OIL TERMINAL",	l: "SENEGAL" },
            { n: "MBARAKI",	l: "KENYA" },
            { n: "M'BYA TERMINAL",	l: "GABON" },
            { n: "MC LELAN STRAIT",	l: "CANADA" },
            { n: "MCCLELLANVILLE SC",	l: "UNITED STATES" },
            { n: "MCDUFFIE ISLAND",	l: "UNITED STATES" },
            { n: "MCGREGOR BAY",	l: "CANADA" },
            { n: "MCKEES ROCKS",	l: "UNITED STATES" },
            { n: "MCKELLAR ISLAND",	l: "CANADA" },
            { n: "MCMURDO",	l: "ANTARCTICA" },
            { n: "MED",	l: "ITALY" },
            { n: "MEDAN",	l: "INDONESIA" },
            { n: "MEDGIDIA",	l: "ROMANIA" },
            { n: "MEDINA",	l: "PHILIPPINES" },
            { n: "MEDWAY PORTS",	l: "UNITED KINGDOM" },
            { n: "MEGA",	l: "JAPAN" },
            { n: "MEGARA OIL TERMINAL",	l: "GREECE" },
            { n: "MEGRI",	l: "TURKEY" },
            { n: "MEIJING",	l: "CHINA" },
            { n: "MEIZHOU",	l: "CHINA" },
            { n: "MEJILLONES",	l: "CHILE" },
            { n: "MEKJARVIK",	l: "NORWAY" },
            { n: "MELAKA",	l: "MALAYSIA" },
            { n: "MELBOURNE",	l: "AUSTRALIA" },
            { n: "MELBOURNE PILOT STATION",	l: "AUSTRALIA" },
            { n: "MELBU",	l: "NORWAY" },
            { n: "MELDRUM BAY",	l: "CANADA" },
            { n: "MELILLA",	l: "SPAIN" },
            { n: "MELILLI OIL TERMINAL",	l: "ITALY" },
            { n: "MELKOYA",	l: "NORWAY" },
            { n: "MELLITAH",	l: "LIBYA" },
            { n: "MELLITAH TERMINAL",	l: "LIBYA" },
            { n: "MELLOS HARBOUR",	l: "NORWAY" },
            { n: "MELONES OIL TERMINAL",	l: "PANAMA" },
            { n: "MELSOMVIK",	l: "NORWAY" },
            { n: "MELVILLE",	l: "UNITED STATES" },
            { n: "MEM",	l: "SWEDEN" },
            { n: "MEMEL",	l: "LITHUANIA" },
            { n: "MENAI STRAIT",	l: "UNITED KINGDOM" },
            { n: "MENENG",	l: "INDONESIA" },
            { n: "MENGJIAGOU",	l: "CHINA" },
            { n: "MENOMINEE/MARINETTE",	l: "UNITED STATES" },
            { n: "MENZEL BOURGUIBA",	l: "TUNISIA" },
            { n: "MERAK",	l: "INDONESIA" },
            { n: "MERAK PETROLEUM BASE",	l: "INDONESIA" },
            { n: "MERAUKE",	l: "INDONESIA" },
            { n: "MERAUX",	l: "UNITED STATES" },
            { n: "MERCA",	l: "SOMALIA" },
            { n: "MERGUI",	l: "MYANMAR" },
            { n: "MERGUI",	l: "MYANMAR" },
            { n: "MERIKARVIA",	l: "FINLAND" },
            { n: "MERMENTAU RIVER END",	l: "UNITED STATES" },
            { n: "MERS EL KEBIR",	l: "ALGERIA" },
            { n: "MERSA EL HAMRA",	l: "EGYPT" },
            { n: "MERSA MATRUH",	l: "EGYPT" },
            { n: "MERSIN",	l: "TURKEY" },
            { n: "MERSRAGS",	l: "LATVIA" },
            { n: "MESAIEED",	l: "QATAR" },
            { n: "MESOLONGI",	l: "GREECE" },
            { n: "MESSINA",	l: "ITALY" },
            { n: "MESSINA STRAIT",	l: "ITALY" },
            { n: "METAS",	l: "TURKEY" },
            { n: "METEGHAN",	l: "CANADA" },
            { n: "METHIL",	l: "UNITED KINGDOM" },
            { n: "METKOVIC",	l: "CROATIA" },
            { n: "METLAKATLA",	l: "UNITED STATES" },
            { n: "MEULABOH",	l: "INDONESIA" },
            { n: "MEXICO (COUNTRY)",	l: "MEXICO" },
            { n: "MEZEN",	l: "RUSSIA" },
            { n: "MGARR",	l: "MALTA" },
            { n: "MIAMI",	l: "UNITED STATES" },
            { n: "MICHILLA COVE",	l: "CHILE" },
            { n: "MICHIPICOTEN HARBOUR",	l: "CANADA" },
            { n: "MICROLIMANO",	l: "GREECE" },
            { n: "MICRONESIA (COUNTRY)",	l: "MICRONESIA" },
            { n: "MID LAKE ONTARIO DOWNBOUND",	l: "UNITED STATES" },
            { n: "MID LAKE ONTARIO UPBOUND",	l: "CANADA" },
            { n: "MIDDELBURG",	l: "NETHERLANDS" },
            { n: "MIDDLE ISLAND",	l: "UNITED STATES" },
            { n: "MIDDLE POINT",	l: "CANADA" },
            { n: "MIDDLEFART",	l: "DENMARK" },
            { n: "MIDDLESBROUGH",	l: "UNITED KINGDOM" },
            { n: "MIDDLETOWN",	l: "UNITED STATES" },
            { n: "MIDIA",	l: "ROMANIA" },
            { n: "MIDLAND",	l: "CANADA" },
            { n: "MIDVAAG",	l: "FAROE ISLANDS" },
            { n: "MIDWAY ISLANDS",	l: "UNITED STATES" },
            { n: "MIHARA",	l: "JAPAN" },
            { n: "MIIKE",	l: "JAPAN" },
            { n: "MIKAWA",	l: "JAPAN" },
            { n: "MILAKI",	l: "GREECE" },
            { n: "MILAZZO",	l: "ITALY" },
            { n: "MILFORD DOCKS",	l: "UNITED KINGDOM" },
            { n: "MILFORD HAVEN",	l: "UNITED KINGDOM" },
            { n: "MILFORD SOUND",	l: "NEW ZEALAND" },
            { n: "MILLHAVEN",	l: "CANADA" },
            { n: "MILNE INLET",	l: "CANADA" },
            { n: "MILNER BAY",	l: "AUSTRALIA" },
            { n: "MILOS ISLAND",	l: "GREECE" },
            { n: "MILWAUKEE",	l: "UNITED STATES" },
            { n: "MINA ABDULLA",	l: "KUWAIT" },
            { n: "MINA AL AHMADI",	l: "KUWAIT" },
            { n: "MINA AL BAKR",	l: "IRAQ" },
            { n: "MINA AL FAHAL",	l: "OMAN" },
            { n: "MINA AL ZOUR",	l: "KUWAIT" },
            { n: "MINA KHALID",	l: "UNITED ARAB EMIRATES" },
            { n: "MINA QABOOS",	l: "OMAN" },
            { n: "MINA RAYSUT",	l: "OMAN" },
            { n: "MINA SALMAN",	l: "BAHRAIN" },
            { n: "MINA SAQR",	l: "UNITED ARAB EMIRATES" },
            { n: "MINA SAUD",	l: "KUWAIT" },
            { n: "MINA SULMAN",	l: "BAHRAIN" },
            { n: "MINA ZAYED",	l: "UNITED ARAB EMIRATES" },
            { n: "MINAMATA",	l: "JAPAN" },
            { n: "MINATITLAN",	l: "MEXICO" },
            { n: "MINDELO",	l: "CAPE VERDE" },
            { n: "MINES SELEINE",	l: "CANADA" },
            { n: "MINICOY",	l: "INDIA" },
            { n: "MINLAGAS",	l: "PHILIPPINES" },
            { n: "MINNESOTA END",	l: "UNITED STATES" },
            { n: "MINNESOTA START",	l: "UNITED STATES" },
            { n: "MIPO",	l: "KOREA, REPUBLIC" },
            { n: "MIQUELON",	l: "SAINT PIERRE AND MIQUELON" },
            { n: "MIRAGOANE",	l: "HAITI" },
            { n: "MIRAMAR OIL TERMINAL",	l: "BRAZIL" },
            { n: "MIRI",	l: "MALAYSIA" },
            { n: "MISHIDU",	l: "CHINA" },
            { n: "MISHIMA-KAWANOE",	l: "JAPAN" },
            { n: "MISS R ABOVE MILE 88.5 PLUS",	l: "UNITED STATES" },
            { n: "MISS R BELOW MILE 88.5",	l: "UNITED STATES" },
            { n: "MISS R NONO",	l: "UNITED STATES" },
            { n: "MISS R SONO",	l: "UNITED STATES" },
            { n: "MISSION RIVER",	l: "CANADA" },
            { n: "MISSIPPI RIVER HOP",	l: "UNITED STATES" },
            { n: "MISSISSAUGA",	l: "CANADA" },
            { n: "MISSISSIPPI GULF OUTLET AND BC",	l: "UNITED STATES" },
            { n: "MISSISSIPPI GULF OUTLET AND GIWW-E",	l: "UNITED STATES" },
            { n: "MISSISSIPPI GULF OUTLET START",	l: "UNITED STATES" },
            { n: "MISSISSIPPI RIVER",	l: "UNITED STATES" },
            { n: "MISSISSIPPI RIVER GULF OUTLET",	l: "UNITED STATES" },
            { n: "MISSOURI END",	l: "UNITED STATES" },
            { n: "MISSOURI START",	l: "UNITED STATES" },
            { n: "MISTAKEN ISLAND",	l: "AUSTRALIA" },
            { n: "MISTLEY",	l: "UNITED KINGDOM" },
            { n: "MISUMI",	l: "JAPAN" },
            { n: "MISURATA",	l: "LIBYA" },
            { n: "MITAJIRI",	l: "JAPAN" },
            { n: "MITSUKOJIMA",	l: "JAPAN" },
            { n: "MITYLENE",	l: "GREECE" },
            { n: "MIYAKO",	l: "JAPAN" },
            { n: "MIYAZU",	l: "JAPAN" },
            { n: "MIZUSHIMA",	l: "JAPAN" },
            { n: "MJANESHOLMEN",	l: "NORWAY" },
            { n: "MJIMWEMA OIL TERMINAL",	l: "TANZANIA" },
            { n: "MKOANI",	l: "TANZANIA" },
            { n: "MO I RANA",	l: "NORWAY" },
            { n: "MOA",	l: "CUBA" },
            { n: "MOANDA OIL TERMINAL",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "MOBILE",	l: "UNITED STATES" },
            { n: "MOBILE BLAKELY ISLAND",	l: "UNITED STATES" },
            { n: "MOBILE ENTRANCE",	l: "UNITED STATES" },
            { n: "MOBILE RIVER AND MOBILE SHIP",	l: "UNITED STATES" },
            { n: "MOBILE RIVER, MOBILE SHIP, AND GIWW-E",	l: "UNITED STATES" },
            { n: "MOBILE RIVER, TOMBIGBEE, ALABAMA",	l: "UNITED STATES" },
            { n: "MOBILE SHIP CHANNEL AND THEODORE SHIP CHANNEL",	l: "UNITED STATES" },
            { n: "MOCAMEDES",	l: "ANGOLA" },
            { n: "MOCIMBOA",	l: "MOZAMBIQUE" },
            { n: "MODEC VENTURE",	l: "AUSTRALIA" },
            { n: "MOENGO",	l: "SURINAME" },
            { n: "MOERDIJK",	l: "NETHERLANDS" },
            { n: "MOGADISCIO",	l: "SOMALIA" },
            { n: "MOGADISHU",	l: "SOMALIA" },
            { n: "MOGPO",	l: "KOREA, REPUBLIC" },
            { n: "MOHAMMEDIA",	l: "MOROCCO" },
            { n: "MOIN",	l: "COSTA RICA" },
            { n: "MO-I-RANA",	l: "NORWAY" },
            { n: "MOJI",	l: "JAPAN" },
            { n: "MOJIAOSHANG",	l: "CHINA" },
            { n: "MOKHA",	l: "YEMEN" },
            { n: "MOKPO",	l: "KOREA, REPUBLIC" },
            { n: "MOLDE",	l: "NORWAY" },
            { n: "MOLDOVA NOUA",	l: "ROMANIA" },
            { n: "MOLFETTA",	l: "ITALY" },
            { n: "MOLLENDO",	l: "PERU" },
            { n: "MOLORE",	l: "INDONESIA" },
            { n: "MOLOTOVSK",	l: "RUSSIA" },
            { n: "MOMA",	l: "MOZAMBIQUE" },
            { n: "MOMBASA",	l: "KENYA" },
            { n: "MONA ISLAND",	l: "PUERTO RICO" },
            { n: "MONA PASSAGE",	l: "PUERTO RICO" },
            { n: "MONA PASSAGE EAST",	l: "PUERTO RICO" },
            { n: "MONA PASSAGE WEST",	l: "PUERTO RICO" },
            { n: "MONACO",	l: "MONACO" },
            { n: "MONACO (COUNTRY)",	l: "MONACO" },
            { n: "MONBETSU",	l: "JAPAN" },
            { n: "MONCTON",	l: "CANADA" },
            { n: "MONEO",	l: "NEW CALEDONIA" },
            { n: "MONEYPOINT",	l: "IRELAND" },
            { n: "MONFALCONE",	l: "ITALY" },
            { n: "MONGLA",	l: "BANGLADESH" },
            { n: "MONGSTAD",	l: "NORWAY" },
            { n: "MONI",	l: "CYPRUS" },
            { n: "MONOMEROS",	l: "COLOMBIA" },
            { n: "MONONGAHELA END",	l: "UNITED STATES" },
            { n: "MONOPOLI",	l: "ITALY" },
            { n: "MONROE",	l: "UNITED STATES" },
            { n: "MONROVIA",	l: "LIBERIA" },
            { n: "MONSTERAS",	l: "SWEDEN" },
            { n: "MONT LOUIS",	l: "CANADA" },
            { n: "MONTAGUE",	l: "CANADA" },
            { n: "MONTARA VENTURE TERMINAL",	l: "AUSTRALIA" },
            { n: "MONTEGO BAY",	l: "JAMAICA" },
            { n: "MONTEREAU",	l: "FRANCE" },
            { n: "MONTEREY",	l: "UNITED STATES" },
            { n: "MONTEVERDE",	l: "ECUADOR" },
            { n: "MONTEVIDEO",	l: "URUGUAY" },
            { n: "MONTGOMERY",	l: "UNITED STATES" },
            { n: "MONTOIR",	l: "FRANCE" },
            { n: "MONTREAL",	l: "CANADA" },
            { n: "MONTREAL SEAWAY",	l: "CANADA" },
            { n: "MONTROSE",	l: "UNITED KINGDOM" },
            { n: "MONTSERRAT (COUNTRY)",	l: "MONTSERRAT" },
            { n: "MOORBURG TERMINAL",	l: "GERMANY" },
            { n: "MOOREA",	l: "FRENCH POLYNESIA" },
            { n: "MOORETOWN",	l: "CANADA" },
            { n: "MOREHEAD CITY",	l: "UNITED STATES" },
            { n: "MOREHEAD CITY NC",	l: "UNITED STATES" },
            { n: "MORGAN CITY",	l: "UNITED STATES" },
            { n: "MORGANTOWN",	l: "UNITED STATES" },
            { n: "MORLAIX",	l: "FRANCE" },
            { n: "MORMUGAO",	l: "INDIA" },
            { n: "MORNOPO",	l: "INDONESIA" },
            { n: "MORO",	l: "INDONESIA" },
            { n: "MORO",	l: "INDONESIA" },
            { n: "MOROCCO (COUNTRY)",	l: "MOROCCO" },
            { n: "MOROMBE",	l: "MADAGASCAR" },
            { n: "MOROMBO",	l: "INDONESIA" },
            { n: "MORON",	l: "VENEZUELA" },
            { n: "MORONDAVA",	l: "MADAGASCAR" },
            { n: "MORONI",	l: "COMOROS" },
            { n: "MOROWALI",	l: "INDONESIA" },
            { n: "MORPHOU BAY",	l: "CYPRUS" },
            { n: "MORRISBURG",	l: "CANADA" },
            { n: "MORRISVILLE",	l: "UNITED STATES" },
            { n: "MORRO REDONDO",	l: "MEXICO" },
            { n: "MORROW",	l: "UNITED STATES" },
            { n: "MOSJOEN",	l: "NORWAY" },
            { n: "MOSKALVO",	l: "RUSSIA" },
            { n: "MOSS",	l: "NORWAY" },
            { n: "MOSS LANDING",	l: "UNITED STATES" },
            { n: "MOSSEL BAY",	l: "SOUTH AFRICA" },
            { n: "MOSTAGANEM",	l: "ALGERIA" },
            { n: "MOSTYN",	l: "UNITED KINGDOM" },
            { n: "MO'TA",	l: "JORDAN" },
            { n: "MOTRIL",	l: "SPAIN" },
            { n: "MOTUEKA",	l: "NEW ZEALAND" },
            { n: "MOTUI",	l: "INDONESIA" },
            { n: "MOTUKEA",	l: "PAPUA NEW GUINEA" },
            { n: "MOUDI TERMINAL",	l: "CAMEROON" },
            { n: "MOUEO",	l: "NEW CALEDONIA" },
            { n: "MOULMEIN",	l: "MYANMAR" },
            { n: "MOUNDROS",	l: "GREECE" },
            { n: "MOUNT MAUNGANUI",	l: "NEW ZEALAND" },
            { n: "MOURILYAN",	l: "AUSTRALIA" },
            { n: "MOVILLE",	l: "IRELAND" },
            { n: "MOZAMBIQUE (COUNTRY)",	l: "MOZAMBIQUE" },
            { n: "MOZAMBIQUE ISLAND",	l: "MOZAMBIQUE" },
            { n: "MT.AIRY",	l: "UNITED STATES" },
            { n: "MTL-BA",	l: "CANADA" },
            { n: "MTL-CRISTOBAL",	l: "CANADA" },
            { n: "MTL-GALVESTON",	l: "CANADA" },
            { n: "MTWARA",	l: "TANZANIA" },
            { n: "MUANDA",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "MUARA",	l: "BRUNEI" },
            { n: "MUARA BANGKONG",	l: "INDONESIA" },
            { n: "MUARA BANGKONG",	l: "INDONESIA" },
            { n: "MUARA BANGKONG ANCHORAGE",	l: "INDONESIA" },
            { n: "MUARA BANYUASIN",	l: "INDONESIA" },
            { n: "MUARA BERAU ANCHORAGE",	l: "INDONESIA" },
            { n: "MUARA JAWA ANCHORAGE",	l: "INDONESIA" },
            { n: "MUARA PANTAI",	l: "INDONESIA" },
            { n: "MUARA SABAK",	l: "INDONESIA" },
            { n: "MUARA SABAK",	l: "INDONESIA" },
            { n: "MUARA SATUI",	l: "INDONESIA" },
            { n: "MUBAREK TERMINAL",	l: "UNITED ARAB EMIRATES" },
            { n: "MUBARRAZ ISLAND",	l: "UNITED ARAB EMIRATES" },
            { n: "MUCURIPE",	l: "BRAZIL" },
            { n: "MUDA MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "MUDANYA",	l: "TURKEY" },
            { n: "MUELLES EL BOSQUE",	l: "COLOMBIA" },
            { n: "MUGARDOS",	l: "SPAIN" },
            { n: "MUHAMMAD BIN QASIM",	l: "PAKISTAN" },
            { n: "MUKAH",	l: "MALAYSIA" },
            { n: "MUKAH ANCHORAGE",	l: "MALAYSIA" },
            { n: "MUKAISHIMA",	l: "JAPAN" },
            { n: "MUKALLA",	l: "YEMEN" },
            { n: "MUKHO",	l: "KOREA, REPUBLIC" },
            { n: "MUKRAN",	l: "GERMANY" },
            { n: "MUL DWARKA",	l: "INDIA" },
            { n: "MULBERRY FORK END",	l: "UNITED STATES" },
            { n: "MULBERRY FORK START",	l: "UNITED STATES" },
            { n: "MULGRAVE",	l: "CANADA" },
            { n: "MUMBAI",	l: "INDIA" },
            { n: "MUNDRA",	l: "INDIA" },
            { n: "MUNGANNO POINT",	l: "AUSTRALIA" },
            { n: "MUNGUBA",	l: "BRAZIL" },
            { n: "MUNKSUND",	l: "SWEDEN" },
            { n: "MUNTOK",	l: "INDONESIA" },
            { n: "MURMANSK",	l: "RUSSIA" },
            { n: "MURORAN",	l: "JAPAN" },
            { n: "MUROS",	l: "SPAIN" },
            { n: "MUSAFFAH",	l: "UNITED ARAB EMIRATES" },
            { n: "MUSAFFAH CHANNEL",	l: "UNITED ARAB EMIRATES" },
            { n: "MUSAFFAH PORT",	l: "UNITED ARAB EMIRATES" },
            { n: "MUSCAT",	l: "OMAN" },
            { n: "MUSCAT-JAPAN",	l: "OMAN" },
            { n: "MUSHTERAK",	l: "JORDAN" },
            { n: "MUSKEGAN END",	l: "UNITED STATES" },
            { n: "MUSKEGAN START",	l: "UNITED STATES" },
            { n: "MUSKEGON",	l: "UNITED STATES" },
            { n: "MUSTOLA",	l: "FINLAND" },
            { n: "MUTINEER FPSO",	l: "AUSTRALIA" },
            { n: "MUTIS",	l: "PANAMA" },
            { n: "MUTSAMUDU",	l: "COMOROS" },
            { n: "MUTSU OGAWARA",	l: "JAPAN" },
            { n: "MUTSURE",	l: "JAPAN" },
            { n: "MUUGA-PORT OF TALLINN",	l: "ESTONIA" },
            { n: "MY A",	l: "VIETNAM" },
            { n: "MY PORT",	l: "FINLAND" },
            { n: "MY THO",	l: "VIETNAM" },
            { n: "MY THOI",	l: "VIETNAM" },
            { n: "MYANMAR (COUNTRY)",	l: "MYANMAR" },
            { n: "MYEIK",	l: "MYANMAR" },
            { n: "MYKONOS ISLAND",	l: "GREECE" },
            { n: "MYRTLE GROVE",	l: "UNITED STATES" },
            { n: "MYSTERY ISLAND",	l: "VANUATU" },
            { n: "N AMERICA",	l: "UNITED STATES" },
            { n: "N CHINA",	l: "CHINA" },
            { n: "N IRELAND",	l: "UNITED KINGDOM" },
            { n: "N JAPAN",	l: "JAPAN" },
            { n: "N SPAIN",	l: "SPAIN" },
            { n: "NA 200NM",	l: "UNITED STATES" },
            { n: "NAANTALI",	l: "FINLAND" },
            { n: "NACALA",	l: "MOZAMBIQUE" },
            { n: "NADAHAMA",	l: "JAPAN" },
            { n: "NADENDAL",	l: "FINLAND" },
            { n: "NADI",	l: "FIJI" },
            { n: "NADOR",	l: "MOROCCO" },
            { n: "NAERSNES",	l: "NORWAY" },
            { n: "NAESTVED",	l: "DENMARK" },
            { n: "NAFPLION",	l: "GREECE" },
            { n: "NAGA",	l: "PHILIPPINES" },
            { n: "NAGAHAMA",	l: "JAPAN" },
            { n: "NAGAPATTINAM",	l: "INDIA" },
            { n: "NAGASAKI",	l: "JAPAN" },
            { n: "NAGOYA",	l: "JAPAN" },
            { n: "NAHA",	l: "JAPAN" },
            { n: "NAIN",	l: "CANADA" },
            { n: "NAKAGUSUKU",	l: "JAPAN" },
            { n: "NAKAGUSUKU BAY",	l: "JAPAN" },
            { n: "NAKANOSEKI",	l: "JAPAN" },
            { n: "NAKETY",	l: "NEW CALEDONIA" },
            { n: "NAKHODKA",	l: "RUSSIA" },
            { n: "NAKNEK",	l: "UNITED STATES" },
            { n: "NAKSKOV",	l: "DENMARK" },
            { n: "NAM CAN",	l: "VIETNAM" },
            { n: "NAMIBE",	l: "ANGOLA" },
            { n: "NAMIBIA (COUNTRY)",	l: "NAMIBIA" },
            { n: "NAMIKATA",	l: "JAPAN" },
            { n: "NAMLEA",	l: "INDONESIA" },
            { n: "NAMPO",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "NAMSOS",	l: "NORWAY" },
            { n: "NANAIMO",	l: "CANADA" },
            { n: "NANAO",	l: "JAPAN" },
            { n: "NANCHANG",	l: "CHINA" },
            { n: "NANCHITAL",	l: "MEXICO" },
            { n: "NANDI",	l: "CHINA" },
            { n: "NANDUN",	l: "CHINA" },
            { n: "NANHUI",	l: "CHINA" },
            { n: "NANISIVIK",	l: "CANADA" },
            { n: "NANJING",	l: "CHINA" },
            { n: "NANJINGJIE",	l: "CHINA" },
            { n: "NANMEN",	l: "CHINA" },
            { n: "NANMENKOU",	l: "CHINA" },
            { n: "NANNING",	l: "CHINA" },
            { n: "NANORTALIK",	l: "GREENLAND" },
            { n: "NANQIAO",	l: "CHINA" },
            { n: "NANSHA",	l: "CHINA" },
            { n: "NANTERRE",	l: "FRANCE" },
            { n: "NANTES-ST NAZAIRE",	l: "FRANCE" },
            { n: "NANTICOKE",	l: "CANADA" },
            { n: "NANTONG",	l: "CHINA" },
            { n: "NANTUCKET HARBOR",	l: "UNITED STATES" },
            { n: "NANTUCKET LANBY",	l: "UNITED STATES" },
            { n: "NANUKU PASSAGE",	l: "FIJI" },
            { n: "NANWAN",	l: "CHINA" },
            { n: "NANXIANG",	l: "CHINA" },
            { n: "NANZHAN",	l: "CHINA" },
            { n: "NAOETSU",	l: "JAPAN" },
            { n: "NAOSHIMA",	l: "JAPAN" },
            { n: "NAPA NAPA",	l: "PAPUA NEW GUINEA" },
            { n: "NAPIER",	l: "NEW ZEALAND" },
            { n: "NAPLES",	l: "ITALY" },
            { n: "NARRA",	l: "PHILIPPINES" },
            { n: "NARSAQ",	l: "GREENLAND" },
            { n: "NARSSARSSUAQ",	l: "GREENLAND" },
            { n: "NARUTO KAIKYO",	l: "JAPAN" },
            { n: "NARVA JOESUU",	l: "ESTONIA" },
            { n: "NARVIK",	l: "NORWAY" },
            { n: "NARYAN-MAR",	l: "RUSSIA" },
            { n: "NASIPIT PORT",	l: "PHILIPPINES" },
            { n: "NASSAU",	l: "THE BAHAMAS" },
            { n: "NATAL",	l: "BRAZIL" },
            { n: "NATASHQUAN",	l: "CANADA" },
            { n: "NATIONAL CITY",	l: "UNITED STATES" },
            { n: "NAUPLIA",	l: "GREECE" },
            { n: "NAURU",	l: "NAURU" },
            { n: "NAURU (COUNTRY)",	l: "NAURU" },
            { n: "NAVARINO BAY",	l: "GREECE" },
            { n: "NAVEGANTES",	l: "BRAZIL" },
            { n: "NAVLAKHI",	l: "INDIA" },
            { n: "NAWILIWILI",	l: "UNITED STATES" },
            { n: "NAXI",	l: "CHINA" },
            { n: "NAZE",	l: "JAPAN" },
            { n: "NCSA",	l: "VENEZUELA" },
            { n: "NE AREA STS",	l: "URUGUAY" },
            { n: "NEA KARVALI",	l: "GREECE" },
            { n: "NEA MOUDHANIA",	l: "GREECE" },
            { n: "NEA PERAMOS",	l: "GREECE" },
            { n: "NEAPOLIS",	l: "GREECE" },
            { n: "NEATH",	l: "UNITED KINGDOM" },
            { n: "NECHES RIVER END",	l: "UNITED STATES" },
            { n: "NECOCHEA",	l: "ARGENTINA" },
            { n: "NEDERLAND",	l: "UNITED STATES" },
            { n: "NEEBISH ISLAND WEST",	l: "CANADA" },
            { n: "NEEDHAM'S POINT TANKER TMNL",	l: "BARBADOS" },
            { n: "NEEDLES",	l: "UNITED KINGDOM" },
            { n: "NEELTJE JANS",	l: "NETHERLANDS" },
            { n: "NEENDAKARA",	l: "INDIA" },
            { n: "NEGISHI",	l: "JAPAN" },
            { n: "NEGRITOS",	l: "PERU" },
            { n: "NEIAFU (VAVAU)",	l: "TONGA" },
            { n: "NEKA",	l: "IRAN" },
            { n: "NEKSO",	l: "DENMARK" },
            { n: "NELSON",	l: "NEW ZEALAND" },
            { n: "NELSON POINT",	l: "AUSTRALIA" },
            { n: "NEMRUT BAY",	l: "TURKEY" },
            { n: "NEMTAS",	l: "TURKEY" },
            { n: "NENANA",	l: "UNITED STATES" },
            { n: "NEPOUI",	l: "NEW CALEDONIA" },
            { n: "NEPTUNE TERMINAL",	l: "CANADA" },
            { n: "NESKAUPSTADUR",	l: "ICELAND" },
            { n: "NESSEBAR",	l: "BULGARIA" },
            { n: "NETHERLANDS (COUNTRY)",	l: "NETHERLANDS" },
            { n: "NETHERLANDS ANTILLES (COUNTRY)",	l: "NETHERLANDS ANTILLES" },
            { n: "NEUHAUS",	l: "GERMANY" },
            { n: "NEUSS",	l: "GERMANY" },
            { n: "NEUSTADT",	l: "GERMANY" },
            { n: "NEVELSK",	l: "RUSSIA" },
            { n: "NEW AMSTERDAM",	l: "GUYANA" },
            { n: "NEW BEDFORD",	l: "UNITED STATES" },
            { n: "NEW BERN NC",	l: "UNITED STATES" },
            { n: "NEW CALEDONIA (COUNTRY)",	l: "NEW CALEDONIA" },
            { n: "NEW CASTLE",	l: "AUSTRALIA" },
            { n: "NEW FREE PORT",	l: "UNITED ARAB EMIRATES" },
            { n: "NEW GLASGOW & TRENTON",	l: "CANADA" },
            { n: "NEW HAVEN",	l: "UNITED STATES" },
            { n: "NEW HOLLAND",	l: "UNITED KINGDOM" },
            { n: "NEW JERSEY",	l: "UNITED STATES" },
            { n: "NEW LIBERIA",	l: "UNITED STATES" },
            { n: "NEW LONDON",	l: "UNITED STATES" },
            { n: "NEW MANGALORE",	l: "INDIA" },
            { n: "NEW ORLEANS",	l: "UNITED STATES" },
            { n: "NEW PLYMOUTH",	l: "NEW ZEALAND" },
            { n: "NEW RICHMOND",	l: "CANADA" },
            { n: "NEW ROSS",	l: "IRELAND" },
            { n: "NEW SOUTH WALES",	l: "AUSTRALIA" },
            { n: "NEW TUTICORIN",	l: "INDIA" },
            { n: "NEW WESTMINSTER",	l: "CANADA" },
            { n: "NEW YORK",	l: "UNITED STATES" },
            { n: "NEW ZEALAND (COUNTRY)",	l: "NEW ZEALAND" },
            { n: "NEWARK",	l: "UNITED STATES" },
            { n: "NEWBURGH",	l: "UNITED KINGDOM" },
            { n: "NEWBURGH (NEW YORK)",	l: "UNITED STATES" },
            { n: "NEWCASTLE (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "NEWCASTLE (CANADA)",	l: "CANADA" },
            { n: "NEWCASTLE UPON TYNE",	l: "UNITED KINGDOM" },
            { n: "NEWHAVEN",	l: "UNITED KINGDOM" },
            { n: "NEWINGTON",	l: "UNITED STATES" },
            { n: "NEWLYN",	l: "UNITED KINGDOM" },
            { n: "NEWPORT (GWENT)",	l: "UNITED KINGDOM" },
            { n: "NEWPORT (ISLE OF WIGHT)",	l: "UNITED KINGDOM" },
            { n: "NEWPORT (OREGON)",	l: "UNITED STATES" },
            { n: "NEWPORT (RHODE ISLAND)",	l: "UNITED STATES" },
            { n: "NEWPORT NEWS",	l: "UNITED STATES" },
            { n: "NGANHURRA TERMINAL",	l: "AUSTRALIA" },
            { n: "NGHE TINH",	l: "VIETNAM" },
            { n: "NGHI SON",	l: "VIETNAM" },
            { n: "NGO",	l: "NEW CALEDONIA" },
            { n: "N'GOMA FPSO",	l: "ANGOLA" },
            { n: "NGQURA",	l: "SOUTH AFRICA" },
            { n: "NHA BE",	l: "VIETNAM" },
            { n: "NHA TRANG",	l: "VIETNAM" },
            { n: "NHAVA SHEEVA",	l: "INDIA" },
            { n: "NIANGNIANGMIAO",	l: "CHINA" },
            { n: "NIANYUWAN",	l: "CHINA" },
            { n: "NIANYUWEI",	l: "CHINA" },
            { n: "NICARAGUA (COUNTRY)",	l: "NICARAGUA" },
            { n: "NICARO",	l: "CUBA" },
            { n: "NICE",	l: "FRANCE" },
            { n: "NIEUW NICKERIE",	l: "SURINAME" },
            { n: "NIEUWPOORT",	l: "BELGIUM" },
            { n: "NIEW DIEP",	l: "NETHERLANDS" },
            { n: "NIGERIA (COUNTRY)",	l: "NIGERIA" },
            { n: "NIGERIAN WAITING AREA 200NM",	l: "NIGERIA" },
            { n: "NIGG OIL TERMINAL",	l: "UNITED KINGDOM" },
            { n: "NIIGATA",	l: "JAPAN" },
            { n: "NIIGATA-HIGASHI",	l: "JAPAN" },
            { n: "NIIHAMA",	l: "JAPAN" },
            { n: "NIJMEGEN",	l: "NETHERLANDS" },
            { n: "NIKA TERA",	l: "UKRAINE" },
            { n: "NIKISKI",	l: "UNITED STATES" },
            { n: "NIKOLAEV",	l: "UKRAINE" },
            { n: "NIKOLAEVSK ON AMUR",	l: "RUSSIA" },
            { n: "NIKOLAEVSKAYA",	l: "RUSSIA" },
            { n: "NING TE",	l: "CHINA" },
            { n: "NINGBO",	l: "CHINA" },
            { n: "NINGDE",	l: "CHINA" },
            { n: "NIPAH ANCHORAGE",	l: "INDONESIA" },
            { n: "NISHIHARA",	l: "JAPAN" },
            { n: "NISHTUN",	l: "YEMEN" },
            { n: "NITEROI",	l: "BRAZIL" },
            { n: "NIUAFO",	l: "TONGA" },
            { n: "NIUE (COUNTRY)",	l: "NIUE" },
            { n: "NIUE ISLAND",	l: "NIUE" },
            { n: "NIUPENG",	l: "CHINA" },
            { n: "NJAROVIK",	l: "ICELAND" },
            { n: "NJORD",	l: "NORWAY" },
            { n: "N'KOSSA TERMINAL",	l: "CONGO" },
            { n: "NOL",	l: "SWEDEN" },
            { n: "NOLA",	l: "UNITED STATES" },
            { n: "NOME",	l: "UNITED STATES" },
            { n: "NONG YAO TERMINAL",	l: "THAILAND" },
            { n: "NONOC",	l: "PHILIPPINES" },
            { n: "NORCO",	l: "UNITED STATES" },
            { n: "NORDENHAM",	l: "GERMANY" },
            { n: "NORDFJORDUR",	l: "ICELAND" },
            { n: "NORDHFJORDHUR",	l: "ICELAND" },
            { n: "NORFOLK",	l: "UNITED STATES" },
            { n: "NORFOLK ISLAND",	l: "AUSTRALIA" },
            { n: "NORFOLK VA",	l: "UNITED STATES" },
            { n: "NORMANTON",	l: "AUSTRALIA" },
            { n: "NORNE",	l: "NORWAY" },
            { n: "NORO",	l: "SOLOMON ISLANDS" },
            { n: "NOROCHCHOLEI",	l: "SRI LANKA" },
            { n: "NORRESUNDBY",	l: "DENMARK" },
            { n: "NORRKOPING",	l: "SWEDEN" },
            { n: "NORRSUNDET",	l: "SWEDEN" },
            { n: "NORSWORTHY",	l: "UNITED STATES" },
            { n: "NORTE CAPIXABA TERMINAL",	l: "BRAZIL" },
            { n: "NORTH BALABAC STRAIT",	l: "PHILIPPINES" },
            { n: "NORTH CAPE",	l: "NORWAY" },
            { n: "NORTH KINGSTOWN",	l: "UNITED STATES" },
            { n: "NORTH PULAU LAUT",	l: "INDONESIA" },
            { n: "NORTH PULAU LAUT COAL TERMINAL",	l: "INDONESIA" },
            { n: "NORTH STAR BUGT",	l: "GREENLAND" },
            { n: "NORTH SYDNEY",	l: "CANADA" },
            { n: "NORTH WEST CAPE",	l: "AUSTRALIA" },
            { n: "NORTH WEYMOUTH",	l: "UNITED STATES" },
            { n: "NORTH WOOLWICH",	l: "UNITED KINGDOM" },
            { n: "NORTHERN ENDEAVOR",	l: "AUSTRALIA" },
            { n: "NORTHERN SECA",	l: "UNITED KINGDOM" },
            { n: "NORTHFLEET",	l: "UNITED KINGDOM" },
            { n: "NORTHPORT",	l: "UNITED STATES" },
            { n: "NORTHVILLE",	l: "UNITED STATES" },
            { n: "NORWALK",	l: "UNITED STATES" },
            { n: "NORWAY (COUNTRY)",	l: "NORWAY" },
            { n: "NORWICH",	l: "UNITED KINGDOM" },
            { n: "NOSHIRO",	l: "JAPAN" },
            { n: "NOSY BE",	l: "MADAGASCAR" },
            { n: "NOUADHIBOU",	l: "MAURITANIA" },
            { n: "NOUAKCHOTT",	l: "MAURITANIA" },
            { n: "NOUMEA",	l: "NEW CALEDONIA" },
            { n: "NOVIY",	l: "RUSSIA" },
            { n: "NOVOROSSIYSK",	l: "RUSSIA" },
            { n: "NOVOROSSIYSK CPC",	l: "RUSSIA" },
            { n: "NOVOTALLINSKY",	l: "ESTONIA" },
            { n: "NOVSHEHR",	l: "IRAN" },
            { n: "NOW SHAHR",	l: "IRAN" },
            { n: "NUEVA GERONA",	l: "CUBA" },
            { n: "NUEVA PALMIRA",	l: "URUGUAY" },
            { n: "NUEVITAS",	l: "CUBA" },
            { n: "NUKU HIVA",	l: "FRENCH POLYNESIA" },
            { n: "NUKUALOFA",	l: "TONGA" },
            { n: "NUMAZU",	l: "JAPAN" },
            { n: "NUNUKAN",	l: "INDONESIA" },
            { n: "NUOTTASAARI",	l: "FINLAND" },
            { n: "NUSANTARA",	l: "INDONESIA" },
            { n: "NUSHAGAK BAY",	l: "UNITED STATES" },
            { n: "NUUK",	l: "GREENLAND" },
            { n: "NUWEIBAH",	l: "EGYPT" },
            { n: "NY HARBOR",	l: "UNITED STATES" },
            { n: "NY-ALESUND",	l: "SVALBARD" },
            { n: "NYANGA",	l: "GABON" },
            { n: "NYBORG",	l: "DENMARK" },
            { n: "NYHAMN",	l: "SWEDEN" },
            { n: "NYHAMNA",	l: "NORWAY" },
            { n: "NYKOBING (FALSTER)",	l: "DENMARK" },
            { n: "NYKOBING (MORS)",	l: "DENMARK" },
            { n: "NYKOBING (SJAELLAND)",	l: "DENMARK" },
            { n: "NYKOPING",	l: "SWEDEN" },
            { n: "NYNASHAMN",	l: "SWEDEN" },
            { n: "NYSTAD",	l: "FINLAND" },
            { n: "NYVARP",	l: "SWEDEN" },
            { n: "OAKHAM NESS",	l: "UNITED KINGDOM" },
            { n: "OAKHAMNESS",	l: "UNITED KINGDOM" },
            { n: "OAKLAND",	l: "UNITED STATES" },
            { n: "OAKVILLE",	l: "CANADA" },
            { n: "OAMARU",	l: "NEW ZEALAND" },
            { n: "OAXEN",	l: "SWEDEN" },
            { n: "OB RIVER",	l: "RUSSIA" },
            { n: "OBAN",	l: "UNITED KINGDOM" },
            { n: "OBBOLA",	l: "SWEDEN" },
            { n: "OBI ISLAND",	l: "INDONESIA" },
            { n: "OBIDOS",	l: "BRAZIL" },
            { n: "OCEAN CAY",	l: "THE BAHAMAS" },
            { n: "OCEAN FALLS",	l: "CANADA" },
            { n: "OCEAN ISLAND",	l: "KIRIBATI" },
            { n: "OCHAKIV",	l: "UKRAINE" },
            { n: "OCHO RIOS",	l: "JAMAICA" },
            { n: "OCOA BAY",	l: "DOMINICAN REPUBLIC" },
            { n: "OCRACOKE NC",	l: "UNITED STATES" },
            { n: "ODDA",	l: "NORWAY" },
            { n: "ODENSE",	l: "DENMARK" },
            { n: "ODESSA",	l: "UKRAINE" },
            { n: "ODUDU TERMINAL",	l: "NIGERIA" },
            { n: "OFFSHORE ABIDJAN",	l: "COTE D'IVOIRE" },
            { n: "OFFSHORE AIN SUKHNA",	l: "EGYPT" },
            { n: "OFFSHORE ARUBA",	l: "ARUBA" },
            { n: "OFFSHORE BATUMI",	l: "GEORGIA" },
            { n: "OFFSHORE BOMBAY",	l: "INDIA" },
            { n: "OFFSHORE COCHIN",	l: "INDIA" },
            { n: "OFFSHORE CUETA",	l: "SPAIN" },
            { n: "OFFSHORE CURACAO",	l: "CURACAO" },
            { n: "OFFSHORE DAKAR",	l: "SENEGAL" },
            { n: "OFFSHORE DELAWARE NO. 1",	l: "UNITED STATES" },
            { n: "OFFSHORE DELAWARE NO. 2",	l: "UNITED STATES" },
            { n: "OFFSHORE DIEGO GARCIA",	l: "MALDIVES" },
            { n: "OFFSHORE DUBAI",	l: "UNITED ARAB EMIRATES" },
            { n: "OFFSHORE FREEPORT (BAHAMAS)",	l: "THE BAHAMAS" },
            { n: "OFFSHORE FUJAIRAH",	l: "UNITED ARAB EMIRATES" },
            { n: "OFFSHORE GENTIL",	l: "GABON" },
            { n: "OFFSHORE GIBRALTAR",	l: "GIBRALTAR" },
            { n: "OFFSHORE IRAQ",	l: "IRAQ" },
            { n: "OFFSHORE JEDDAH",	l: "SAUDI ARABIA" },
            { n: "OFFSHORE KAKINADA",	l: "INDIA" },
            { n: "OFFSHORE KALUNDBORG",	l: "DENMARK" },
            { n: "OFFSHORE KAOHSIUNG",	l: "TAIWAN" },
            { n: "OFFSHORE KARIMUN LZ",	l: "INDONESIA" },
            { n: "OFFSHORE KAVKAZ",	l: "RUSSIA" },
            { n: "OFFSHORE KERCH",	l: "UKRAINE" },
            { n: "OFFSHORE KHOR FAKKAN",	l: "UNITED ARAB EMIRATES" },
            { n: "OFFSHORE LIMASSOL",	l: "CYPRUS" },
            { n: "OFFSHORE LOME",	l: "TOGO" },
            { n: "OFFSHORE LOS ANGELES",	l: "UNITED STATES" },
            { n: "OFFSHORE LUANDA",	l: "ANGOLA" },
            { n: "OFFSHORE MALTA",	l: "MALTA" },
            { n: "OFFSHORE MESAIEED",	l: "QATAR" },
            { n: "OFFSHORE PANAMA LZ",	l: "PANAMA" },
            { n: "OFFSHORE PASCAGOULA NO 2",	l: "UNITED STATES" },
            { n: "OFFSHORE PORT ELIZABETH",	l: "SOUTH AFRICA" },
            { n: "OFFSHORE PORT SAID",	l: "EGYPT" },
            { n: "OFFSHORE RAS AL HADD",	l: "OMAN" },
            { n: "OFFSHORE RODRIGUES ISLAND",	l: "MAURITIUS" },
            { n: "OFFSHORE SIKKA",	l: "INDIA" },
            { n: "OFFSHORE SKAW",	l: "DENMARK" },
            { n: "OFFSHORE TANJUNG PELAPAS",	l: "MALAYSIA" },
            { n: "OFFSHORE TSUSHIMA",	l: "JAPAN" },
            { n: "OFUNATO",	l: "JAPAN" },
            { n: "OGDEN POINT",	l: "CANADA" },
            { n: "OGDENSBURG",	l: "UNITED STATES" },
            { n: "OGHARA",	l: "NIGERIA" },
            { n: "OGISHIMA",	l: "JAPAN" },
            { n: "OGUENDJO TERMINAL",	l: "GABON" },
            { n: "OGUZHAN TERMINAL",	l: "TURKMENISTAN" },
            { n: "OHIO AND TENNESSEE",	l: "UNITED STATES" },
            { n: "OHIO RIVER END",	l: "UNITED STATES" },
            { n: "OHIO, ALLEGHENY, AND MONONGAHELA",	l: "UNITED STATES" },
            { n: "OHMANS",	l: "SWEDEN" },
            { n: "OISTINS BAY TANKER TERMINAL",	l: "BARBADOS" },
            { n: "OITA",	l: "JAPAN" },
            { n: "OKAREM",	l: "TURKMENISTAN" },
            { n: "OKHA",	l: "INDIA" },
            { n: "OKINAWA",	l: "JAPAN" },
            { n: "OKKYE",	l: "KOREA, REPUBLIC" },
            { n: "OKONO TERMINAL",	l: "NIGERIA" },
            { n: "OKORO TERMINAL",	l: "NIGERIA" },
            { n: "OKPO",	l: "KOREA, REPUBLIC" },
            { n: "OKRIKA",	l: "NIGERIA" },
            { n: "OKRUZHNOE",	l: "RUSSIA" },
            { n: "OKTYABRSKIY",	l: "UKRAINE" },
            { n: "OKWORI",	l: "NIGERIA" },
            { n: "OLAFSVIK",	l: "ICELAND" },
            { n: "OLBIA",	l: "ITALY" },
            { n: "OLD CITY HARBOUR-PORT OF TALLINN",	l: "ESTONIA" },
            { n: "OLD FREE PORT",	l: "UNITED ARAB EMIRATES" },
            { n: "OLD KILPATRICK",	l: "UNITED KINGDOM" },
            { n: "OLD RIVER-SAN JACINTO END",	l: "UNITED STATES" },
            { n: "OLDEN",	l: "NORWAY" },
            { n: "OLDENBURG",	l: "GERMANY" },
            { n: "OLDERSUM",	l: "GERMANY" },
            { n: "OLEN",	l: "NORWAY" },
            { n: "OLEUM",	l: "UNITED STATES" },
            { n: "OLGA",	l: "RUSSIA" },
            { n: "OLONGAPO",	l: "PHILIPPINES" },
            { n: "OLOWI TERMINAL",	l: "GABON" },
            { n: "OLTENITZA",	l: "ROMANIA" },
            { n: "OLYMPIA",	l: "UNITED STATES" },
            { n: "OMAEZAKI",	l: "JAPAN" },
            { n: "OMAN (COUNTRY)",	l: "OMAN" },
            { n: "OMASTRAND",	l: "NORWAY" },
            { n: "OMISALJ",	l: "CROATIA" },
            { n: "OMISHIMA",	l: "JAPAN" },
            { n: "OMNICO PORT",	l: "PHILIPPINES" },
            { n: "OMOA",	l: "HONDURAS" },
            { n: "ONAGAWA",	l: "JAPAN" },
            { n: "ONAHAMA",	l: "JAPAN" },
            { n: "ONEGA",	l: "RUSSIA" },
            { n: "ONEHUNGA",	l: "NEW ZEALAND" },
            { n: "ONNE",	l: "NIGERIA" },
            { n: "ONODA",	l: "JAPAN" },
            { n: "ONOMICHI",	l: "JAPAN" },
            { n: "ONRI",	l: "PHILIPPINES" },
            { n: "ONSAN",	l: "KOREA, REPUBLIC" },
            { n: "ONSLOW",	l: "AUSTRALIA" },
            { n: "ONTONAGON",	l: "UNITED STATES" },
            { n: "OPL COTONOU",	l: "BENIN" },
            { n: "OPL MALTA",	l: "MALTA" },
            { n: "OPL TRINIDAD",	l: "TRINIDAD AND TOBAGO" },
            { n: "OPORTO",	l: "PORTUGAL" },
            { n: "OPUA",	l: "NEW ZEALAND" },
            { n: "OQUENDO",	l: "PERU" },
            { n: "ORAN",	l: "ALGERIA" },
            { n: "ORANGE",	l: "UNITED STATES" },
            { n: "ORANJEMUND",	l: "NAMIBIA" },
            { n: "ORANJESTAD",	l: "ARUBA" },
            { n: "ORDU",	l: "TURKEY" },
            { n: "OREHOVED",	l: "DENMARK" },
            { n: "ORESUND",	l: "DENMARK" },
            { n: "ORIENTAL NC",	l: "UNITED STATES" },
            { n: "ORINOCO",	l: "VENEZUELA" },
            { n: "ORINOCO PILOT STATION",	l: "VENEZUELA" },
            { n: "ORISTANO",	l: "ITALY" },
            { n: "ORITKARI",	l: "FINLAND" },
            { n: "ORKANGER",	l: "NORWAY" },
            { n: "ORMOC",	l: "PHILIPPINES" },
            { n: "ORNSKOLDSVIK",	l: "SWEDEN" },
            { n: "ORO BAY",	l: "PAPUA NEW GUINEA" },
            { n: "ORRSKAR",	l: "SWEDEN" },
            { n: "ORSOVA",	l: "ROMANIA" },
            { n: "ORTH",	l: "GERMANY" },
            { n: "ORTONA",	l: "ITALY" },
            { n: "ORTVIKEN",	l: "SWEDEN" },
            { n: "OSAKA",	l: "JAPAN" },
            { n: "OSHAWA",	l: "CANADA" },
            { n: "OSHIMA",	l: "JAPAN" },
            { n: "OSKARSHAMN",	l: "SWEDEN" },
            { n: "OSLO",	l: "NORWAY" },
            { n: "OSTEND",	l: "BELGIUM" },
            { n: "OSTERMOOR",	l: "GERMANY" },
            { n: "OSTRAND",	l: "SWEDEN" },
            { n: "OSTRICA",	l: "UNITED STATES" },
            { n: "OSUMI STRAIT",	l: "JAPAN" },
            { n: "OSWEGO",	l: "UNITED STATES" },
            { n: "OTAGO HARBOUR",	l: "NEW ZEALAND" },
            { n: "OTAKE",	l: "JAPAN" },
            { n: "OTARU",	l: "JAPAN" },
            { n: "OTRANTO",	l: "ITALY" },
            { n: "OTTERBACKEN",	l: "SWEDEN" },
            { n: "OTTERHAM",	l: "UNITED KINGDOM" },
            { n: "OTWAY TERMINAL",	l: "CHILE" },
            { n: "OUACHITA-BLACK END",	l: "UNITED STATES" },
            { n: "OUACHITA-BLACK START",	l: "UNITED STATES" },
            { n: "OUDNA",	l: "TUNISIA" },
            { n: "OUINNE",	l: "NEW CALEDONIA" },
            { n: "OUISTREHAM",	l: "FRANCE" },
            { n: "OULU",	l: "FINLAND" },
            { n: "OUTEIRO",	l: "BRAZIL" },
            { n: "OUTSIDE EEZ",	l: "UNITED STATES" },
            { n: "OUVEA",	l: "NEW CALEDONIA" },
            { n: "OVENDO",	l: "GABON" },
            { n: "OWASE",	l: "JAPAN" },
            { n: "OWEN SOUND",	l: "CANADA" },
            { n: "OWENDO",	l: "GABON" },
            { n: "OXELOSUND",	l: "SWEDEN" },
            { n: "OXHAGA HARBOUR",	l: "SWEDEN" },
            { n: "OYO FPSO TERMINAL",	l: "NIGERIA" },
            { n: "OYONG TERMINAL",	l: "INDONESIA" },
            { n: "OZAMIZ",	l: "PHILIPPINES" },
            { n: "OZOL",	l: "UNITED STATES" },
            { n: "PAAMIUT",	l: "GREENLAND" },
            { n: "PACASMAYO",	l: "PERU" },
            { n: "PACHI",	l: "GREECE" },
            { n: "PACIFIC LIGHTERING AREA",	l: "UNITED STATES" },
            { n: "PADANG",	l: "INDONESIA" },
            { n: "PADANG TIKAR",	l: "INDONESIA" },
            { n: "PADANG TIKAR",	l: "INDONESIA" },
            { n: "PADSTOW",	l: "UNITED KINGDOM" },
            { n: "PAGAI",	l: "INDONESIA" },
            { n: "PAGBILAO",	l: "PHILIPPINES" },
            { n: "PAGERUNGAN",	l: "INDONESIA" },
            { n: "PAGO-PAGO",	l: "AMERICAN SAMOA" },
            { n: "PAIA INLET",	l: "PAPUA NEW GUINEA" },
            { n: "PAIMBOEUF",	l: "FRANCE" },
            { n: "PAIMPOL",	l: "FRANCE" },
            { n: "PAITA",	l: "PERU" },
            { n: "PAJARITOS",	l: "MEXICO" },
            { n: "PAKERA POINT",	l: "SOLOMON ISLANDS" },
            { n: "PAKISTAN (COUNTRY)",	l: "PAKISTAN" },
            { n: "PAL ECHO LIGHTERING AREA",	l: "UNITED STATES" },
            { n: "PALAMOS",	l: "SPAIN" },
            { n: "PALANCA TERMINAL",	l: "ANGOLA" },
            { n: "PALAU",	l: "ITALY" },
            { n: "PALAWAN OFFSHORE",	l: "PHILIPPINES" },
            { n: "PALAZZOLO DELLO STELLA",	l: "ITALY" },
            { n: "PALDISKI-PORT OF TALLINN",	l: "ESTONIA" },
            { n: "PALEMBANG",	l: "INDONESIA" },
            { n: "PALENQUE",	l: "DOMINICAN REPUBLIC" },
            { n: "PALERMO",	l: "ITALY" },
            { n: "PALHI",	l: "PHILIPPINES" },
            { n: "PALJASSAARE",	l: "ESTONIA" },
            { n: "PALM BEACH",	l: "UNITED STATES" },
            { n: "PALMA",	l: "SPAIN" },
            { n: "PALMAREJO",	l: "VENEZUELA" },
            { n: "PALMEIRA BAY",	l: "CAPE VERDE" },
            { n: "PALMONES",	l: "SPAIN" },
            { n: "PALO ALTO",	l: "CUBA" },
            { n: "PALOMPON",	l: "PHILIPPINES" },
            { n: "PALOPO",	l: "INDONESIA" },
            { n: "PALU",	l: "INDONESIA" },
            { n: "PALUA",	l: "VENEZUELA" },
            { n: "PALUAY PORT",	l: "THAILAND" },
            { n: "PAMANZI BAY",	l: "COMOROS" },
            { n: "PAMATACUAL",	l: "VENEZUELA" },
            { n: "PAMATACUALITO",	l: "VENEZUELA" },
            { n: "PAMFILLA",	l: "GREECE" },
            { n: "PAMPA MELCHORITA",	l: "PERU" },
            { n: "PAMPUS HARBOUR",	l: "SWEDEN" },
            { n: "PANAJI",	l: "INDIA" },
            { n: "PANAMA (COUNTRY)",	l: "PANAMA" },
            { n: "PANAMA CANAL",	l: "PANAMA" },
            { n: "PANAMA CITY",	l: "UNITED STATES" },
            { n: "PANAMA STS",	l: "PANAMA" },
            { n: "PANAMA-BALBOA",	l: "PANAMA" },
            { n: "PANAMA-COLON",	l: "PANAMA" },
            { n: "PANAREA",	l: "ITALY" },
            { n: "PANARUKAN",	l: "INDONESIA" },
            { n: "PANDORA PASSAGE",	l: "PAPUA NEW GUINEA" },
            { n: "PANGAI",	l: "TONGA" },
            { n: "PANGANI",	l: "TANZANIA" },
            { n: "PANGKAL BALAM",	l: "INDONESIA" },
            { n: "PANGKALAN SUSU",	l: "INDONESIA" },
            { n: "PANGKALANBUN",	l: "INDONESIA" },
            { n: "PANGNIRTUNG, NU",	l: "CANADA" },
            { n: "PANJANG",	l: "INDONESIA" },
            { n: "PANJIM",	l: "INDIA" },
            { n: "PANJIN",	l: "CHINA" },
            { n: "PANSIO",	l: "FINLAND" },
            { n: "PANTELLERIA",	l: "ITALY" },
            { n: "PANTOLOAN",	l: "INDONESIA" },
            { n: "PAPEETE",	l: "FRENCH POLYNESIA" },
            { n: "PAPENBURG",	l: "GERMANY" },
            { n: "PAPHOS",	l: "CYPRUS" },
            { n: "PAPUA NEW GUINEA (COUNTRY)",	l: "PAPUA NEW GUINEA" },
            { n: "PAR",	l: "UNITED KINGDOM" },
            { n: "PARA",	l: "BRAZIL" },
            { n: "PARADERO",	l: "VENEZUELA" },
            { n: "PARADIP",	l: "INDIA" },
            { n: "PARAINEN",	l: "FINLAND" },
            { n: "PARAMARIBO",	l: "SURINAME" },
            { n: "PARAMONGA",	l: "PERU" },
            { n: "PARANA",	l: "ARGENTINA" },
            { n: "PARANAGUA",	l: "BRAZIL" },
            { n: "PARANAM",	l: "SURINAME" },
            { n: "PARATI",	l: "BRAZIL" },
            { n: "PARE PARE",	l: "INDONESIA" },
            { n: "PARGAS",	l: "FINLAND" },
            { n: "PARINTINS",	l: "BRAZIL" },
            { n: "PARIS",	l: "FRANCE" },
            { n: "PARKER POINT",	l: "AUSTRALIA" },
            { n: "PAROIKIA",	l: "GREECE" },
            { n: "PAROS",	l: "GREECE" },
            { n: "PAROS ISLAND",	l: "GREECE" },
            { n: "PARRSBORO",	l: "CANADA" },
            { n: "PARRY SOUND",	l: "CANADA" },
            { n: "PARTINGTON",	l: "UNITED KINGDOM" },
            { n: "PASADENA",	l: "UNITED STATES" },
            { n: "PASAJES",	l: "SPAIN" },
            { n: "PASCAGOULA",	l: "UNITED STATES" },
            { n: "PASCAGOULA AND BAYOU CASOTTE",	l: "UNITED STATES" },
            { n: "PASCAGOULA CHANNEL AND GIWW-E",	l: "UNITED STATES" },
            { n: "PASCAGOULA CHANNEL END",	l: "UNITED STATES" },
            { n: "PASCAGOULA LZ",	l: "UNITED STATES" },
            { n: "PASCAGOULA LZ2",	l: "UNITED STATES" },
            { n: "PASCO",	l: "UNITED STATES" },
            { n: "PASIR GUDANG",	l: "MALAYSIA" },
            { n: "PASIR PANJANG",	l: "SINGAPORE" },
            { n: "PASKALLAVIK",	l: "SWEDEN" },
            { n: "PASPEBIAC",	l: "CANADA" },
            { n: "PASSAGE ISLAND",	l: "UNITED STATES" },
            { n: "PASSERO",	l: "ITALY" },
            { n: "PASSERO-GIB",	l: "GIBRALTAR" },
            { n: "PASTELILLO",	l: "CUBA" },
            { n: "PATACHE",	l: "CHILE" },
            { n: "PATAHOLM",	l: "SWEDEN" },
            { n: "PATENIEMI",	l: "FINLAND" },
            { n: "PATHEIN",	l: "MYANMAR" },
            { n: "PATILLOS COVE",	l: "CHILE" },
            { n: "PATMOS",	l: "GREECE" },
            { n: "PATRAS",	l: "GREECE" },
            { n: "PATTANI",	l: "THAILAND" },
            { n: "PAUILLAC",	l: "FRANCE" },
            { n: "PAULINA",	l: "UNITED STATES" },
            { n: "PAULSBORO",	l: "UNITED STATES" },
            { n: "PAVILOSTA",	l: "LATVIA" },
            { n: "PAYARDI ISLAND",	l: "PANAMA" },
            { n: "PAYSANDU",	l: "URUGUAY" },
            { n: "PAZFLOR TERMINAL",	l: "ANGOLA" },
            { n: "PC1",	l: "UNITED STATES" },
            { n: "PC2",	l: "UNITED STATES" },
            { n: "PC3",	l: "UNITED STATES" },
            { n: "PC4",	l: "UNITED STATES" },
            { n: "PEARL HARBOR",	l: "UNITED STATES" },
            { n: "PEBANE",	l: "MOZAMBIQUE" },
            { n: "PECEM",	l: "BRAZIL" },
            { n: "PECKET",	l: "CHILE" },
            { n: "PEDRA DE LUME",	l: "CAPE VERDE" },
            { n: "PEDREGAL",	l: "PANAMA" },
            { n: "PEEL",	l: "ISLE OF MAN" },
            { n: "PEEL SOUND",	l: "CANADA" },
            { n: "PEKANBARU",	l: "INDONESIA" },
            { n: "PELICAN",	l: "UNITED STATES" },
            { n: "PELINTUNG",	l: "INDONESIA" },
            { n: "PELOTAS",	l: "BRAZIL" },
            { n: "PELRA",	l: "INDONESIA" },
            { n: "PEMANCINGAN",	l: "INDONESIA" },
            { n: "PEMANGKAT",	l: "INDONESIA" },
            { n: "PEMBA",	l: "MOZAMBIQUE" },
            { n: "PEMBROKE",	l: "UNITED KINGDOM" },
            { n: "PENANG",	l: "MALAYSIA" },
            { n: "PENARA (TERMINAL)",	l: "MALAYSIA" },
            { n: "PENCO",	l: "CHILE" },
            { n: "PENDING",	l: "MALAYSIA" },
            { n: "PENETANGUISHENE",	l: "CANADA" },
            { n: "PENGERANG",	l: "MALAYSIA" },
            { n: "PENGERANG PORT",	l: "MALAYSIA" },
            { n: "PENGLAI",	l: "CHINA" },
            { n: "PENNESHAW",	l: "AUSTRALIA" },
            { n: "PENNINGTON",	l: "NIGERIA" },
            { n: "PENNS GROVE",	l: "UNITED STATES" },
            { n: "PENNSAUKEN",	l: "UNITED STATES" },
            { n: "PENRYN",	l: "UNITED KINGDOM" },
            { n: "PENSACOLA",	l: "UNITED STATES" },
            { n: "PENTECOSTE ISLAND",	l: "VANUATU" },
            { n: "PENTLAND FIRTH STRAIT",	l: "UNITED KINGDOM" },
            { n: "PENUBA",	l: "INDONESIA" },
            { n: "PENUELAS",	l: "PUERTO RICO" },
            { n: "PENZANCE",	l: "UNITED KINGDOM" },
            { n: "PEPEL",	l: "SIERRA LEONE" },
            { n: "PERAI",	l: "MALAYSIA" },
            { n: "PERAMA",	l: "GREECE" },
            { n: "PERAWANG",	l: "INDONESIA" },
            { n: "PEREGRINO FPSO",	l: "BRAZIL" },
            { n: "PERIM ISLAND",	l: "YEMEN" },
            { n: "PERNAMBUCO",	l: "BRAZIL" },
            { n: "PERNIS",	l: "NETHERLANDS" },
            { n: "PERPIGNAN",	l: "FRANCE" },
            { n: "PERTH (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "PERTH (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "PERTH AMBOY",	l: "UNITED STATES" },
            { n: "PERTIGALETE",	l: "VENEZUELA" },
            { n: "PERU (COUNTRY)",	l: "PERU" },
            { n: "PESARO",	l: "ITALY" },
            { n: "PESCARA",	l: "ITALY" },
            { n: "PETCHBURI TERMINAL",	l: "THAILAND" },
            { n: "PETERHEAD BAY HARBOUR",	l: "UNITED KINGDOM" },
            { n: "PETERHEAD HARBOURS",	l: "UNITED KINGDOM" },
            { n: "PETERSBURG",	l: "UNITED STATES" },
            { n: "PETIT COURONNE",	l: "FRANCE" },
            { n: "PETROCHINA MARINE TERMINAL",	l: "INDONESIA" },
            { n: "PETROPAVLOVSK-KAMCHATSKY",	l: "RUSSIA" },
            { n: "PETROZUATA SBM",	l: "VENEZUELA" },
            { n: "PEVEK",	l: "RUSSIA" },
            { n: "PG",	l: "OMAN" },
            { n: "PHALERON BAY",	l: "GREECE" },
            { n: "PHAN RANG",	l: "VIETNAM" },
            { n: "PHAN RANG",	l: "VIETNAM" },
            { n: "PHENIX CITY",	l: "UNITED STATES" },
            { n: "PHILADELPHIA",	l: "UNITED STATES" },
            { n: "PHILIPPEVILLE",	l: "ALGERIA" },
            { n: "PHILIPPINES (COUNTRY)",	l: "PHILIPPINES" },
            { n: "PHILIPSBURG",	l: "SINT MAARTEN (DUTCH PART)" },
            { n: "PHNOM-PENH",	l: "CAMBODIA" },
            { n: "PHU MY",	l: "VIETNAM" },
            { n: "PHU QUOC",	l: "VIETNAM" },
            { n: "PHUKET",	l: "THAILAND" },
            { n: "PIACAGUERA",	l: "BRAZIL" },
            { n: "PICHILINGUE",	l: "MEXICO" },
            { n: "PICTON",	l: "NEW ZEALAND" },
            { n: "PICTON, ON",	l: "CANADA" },
            { n: "PICTOU",	l: "CANADA" },
            { n: "PIETARSAARI",	l: "FINLAND" },
            { n: "PIKKALA",	l: "FINLAND" },
            { n: "PILLAU",	l: "RUSSIA" },
            { n: "PIMENTEL",	l: "PERU" },
            { n: "PINDHARA",	l: "INDIA" },
            { n: "PINDO",	l: "COLOMBIA" },
            { n: "PINE BEND, MN",	l: "UNITED STATES" },
            { n: "PINEY POINT",	l: "UNITED STATES" },
            { n: "PINGHU",	l: "CHINA" },
            { n: "PINKENBA",	l: "AUSTRALIA" },
            { n: "PIOMBINO",	l: "ITALY" },
            { n: "PIPAVAV",	l: "INDIA" },
            { n: "PIR PAU",	l: "INDIA" },
            { n: "PIRACY - GUINEA",	l: "GUINEA" },
            { n: "PIRACY - HIGH RISK AREA",	l: "SOMALIA" },
            { n: "PIRACY - JWC INDIAN OCEAN 2013",	l: "SOMALIA" },
            { n: "PIRACY - JWC INDIAN OCEAN 2015",	l: "SOMALIA" },
            { n: "PIRACY - SOMALIA",	l: "SOMALIA" },
            { n: "PIRACY - SOMALIA 250NM",	l: "SOMALIA" },
            { n: "PIRACY ROUTING INDIAN OCEAN",	l: "INDIA" },
            { n: "PIRACY ZONE EAST INDIAN OCEAN",	l: "MALDIVES" },
            { n: "PIRACY ZONE RED SEA NORTH",	l: "SAUDI ARABIA" },
            { n: "PIRAEUS",	l: "GREECE" },
            { n: "PIRAN",	l: "SLOVENIA" },
            { n: "PIRIAC",	l: "FRANCE" },
            { n: "PIRS EKINDZHILER",	l: "TURKEY" },
            { n: "PIRU BAY",	l: "INDONESIA" },
            { n: "PISAGUA",	l: "CHILE" },
            { n: "PISCO",	l: "PERU" },
            { n: "PITCAIRN ISLAND",	l: "PITCAIRN" },
            { n: "PITCAIRN ISLANDS (COUNTRY)",	l: "PITCAIRN" },
            { n: "PITEA",	l: "SWEDEN" },
            { n: "PITTSBURG",	l: "UNITED STATES" },
            { n: "PIZHOU",	l: "CHINA" },
            { n: "PLAJU & SUNGAI GERONG",	l: "INDONESIA" },
            { n: "PLAQUEMINE",	l: "UNITED STATES" },
            { n: "PLATONG",	l: "THAILAND" },
            { n: "PLOCE",	l: "CROATIA" },
            { n: "PLOMIN",	l: "CROATIA" },
            { n: "PLUMPER SOUND",	l: "CANADA" },
            { n: "PLUTO LNG",	l: "AUSTRALIA" },
            { n: "PLUTONIO",	l: "ANGOLA" },
            { n: "PLYMOUTH (MONTSERRAT)",	l: "MONTSERRAT" },
            { n: "PLYMOUTH (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "PLYMOUTH (USA)",	l: "UNITED STATES" },
            { n: "PLYMOUTH NC",	l: "UNITED STATES" },
            { n: "PMO",	l: "OMAN" },
            { n: "POD - SOVIKNES",	l: "NORWAY" },
            { n: "POHANG",	l: "KOREA, REPUBLIC" },
            { n: "POHNPEI",	l: "MICRONESIA" },
            { n: "POINT AYRE",	l: "UNITED KINGDOM" },
            { n: "POINT BARROW",	l: "UNITED STATES" },
            { n: "POINT CENTRAL",	l: "MAURITANIA" },
            { n: "POINT COMFORT",	l: "UNITED STATES" },
            { n: "POINT CRUZ",	l: "SOLOMON ISLANDS" },
            { n: "POINT FORTIN",	l: "TRINIDAD AND TOBAGO" },
            { n: "POINT LISAS",	l: "TRINIDAD AND TOBAGO" },
            { n: "POINT SAMSON",	l: "AUSTRALIA" },
            { n: "POINT TUPPER",	l: "CANADA" },
            { n: "POINT WELLS",	l: "UNITED STATES" },
            { n: "POINTE AUX BASQUES",	l: "CANADA" },
            { n: "POINTE BELLE DUNE",	l: "CANADA" },
            { n: "POINTE DES GALETS",	l: "REUNION" },
            { n: "POINTE DU DEPART",	l: "CAMBODIA" },
            { n: "POINTE NOIRE (CONGO)",	l: "CONGO" },
            { n: "POINTE NOIRE (CANADA)",	l: "CANADA" },
            { n: "POINTE SERAPHINE",	l: "SAINT LUCIA" },
            { n: "POINTE-A-PIERRE",	l: "TRINIDAD AND TOBAGO" },
            { n: "POINTE-A-PITRE",	l: "GUADELOUPE" },
            { n: "POINTE-AU-PIC",	l: "CANADA" },
            { n: "POINTE-NOIRE",	l: "CONGO" },
            { n: "POLAND (COUNTRY)",	l: "POLAND" },
            { n: "POLENG",	l: "INDONESIA" },
            { n: "POLICE",	l: "POLAND" },
            { n: "POLIPORT",	l: "TURKEY" },
            { n: "POLLOC",	l: "PHILIPPINES" },
            { n: "POLVO TERMINAL",	l: "BRAZIL" },
            { n: "POMALAA",	l: "INDONESIA" },
            { n: "POMORIE",	l: "BULGARIA" },
            { n: "PONCE",	l: "PUERTO RICO" },
            { n: "POND INLET, NU",	l: "CANADA" },
            { n: "PONDICHERRY",	l: "INDIA" },
            { n: "PONTA DA MADEIRA",	l: "BRAZIL" },
            { n: "PONTA DELGADA",	l: "PORTUGAL" },
            { n: "PONTA UBU",	l: "BRAZIL" },
            { n: "PONTAL DO PARANA",	l: "BRAZIL" },
            { n: "PONTIANAK",	l: "INDONESIA" },
            { n: "POOLE",	l: "UNITED KINGDOM" },
            { n: "PORBANDAR",	l: "INDIA" },
            { n: "PORI",	l: "FINLAND" },
            { n: "PORKKALA",	l: "FINLAND" },
            { n: "PORO",	l: "NEW CALEDONIA" },
            { n: "PORO POINT",	l: "PHILIPPINES" },
            { n: "PORONAYSK",	l: "RUSSIA" },
            { n: "PORSGRUNN",	l: "NORWAY" },
            { n: "PORT ALBERNI",	l: "CANADA" },
            { n: "PORT ALFRED",	l: "CANADA" },
            { n: "PORT ALICE",	l: "CANADA" },
            { n: "PORT ALLEN",	l: "UNITED STATES" },
            { n: "PORT ALLEN (LOUISIANA)",	l: "UNITED STATES" },
            { n: "PORT ALLEN ROUTE END",	l: "UNITED STATES" },
            { n: "PORT ALMA",	l: "AUSTRALIA" },
            { n: "PORT ALUCROIX",	l: "VIRGIN ISLANDS" },
            { n: "PORT ANGELES",	l: "UNITED STATES" },
            { n: "PORT ANTONIO",	l: "JAMAICA" },
            { n: "PORT ARECIBO",	l: "PUERTO RICO" },
            { n: "PORT ARTHUR (CANADA)",	l: "CANADA" },
            { n: "PORT ARTHUR (TEXAS)",	l: "UNITED STATES" },
            { n: "PORT ASKAIG",	l: "UNITED KINGDOM" },
            { n: "PORT AU PRINCE",	l: "HAITI" },
            { n: "PORT AUGUSTA",	l: "AUSTRALIA" },
            { n: "PORT AUTHORITY OF GUAYAQUIL (RIO GUAYAS)",	l: "ECUADOR" },
            { n: "PORT AUX BASQUES",	l: "CANADA" },
            { n: "PORT BLAIR",	l: "INDIA" },
            { n: "PORT BONYTHON",	l: "AUSTRALIA" },
            { n: "PORT BOTANY",	l: "AUSTRALIA" },
            { n: "PORT BOUET TANKER TERMINAL",	l: "COTE D'IVOIRE" },
            { n: "PORT BUSTAMENTE",	l: "JAMAICA" },
            { n: "PORT CANAVERAL",	l: "UNITED STATES" },
            { n: "PORT CARTIER",	l: "CANADA" },
            { n: "PORT CHALMERS",	l: "NEW ZEALAND" },
            { n: "PORT CHESTER HARBOR",	l: "UNITED STATES" },
            { n: "PORT CHOLLIN",	l: "CHILE" },
            { n: "PORT CLARENCE",	l: "UNITED KINGDOM" },
            { n: "PORT CLYDE",	l: "UNITED STATES" },
            { n: "PORT COLBORNE",	l: "CANADA" },
            { n: "PORT COVINGTON",	l: "UNITED STATES" },
            { n: "PORT DALHOUSIE",	l: "CANADA" },
            { n: "PORT DALRYMPLE",	l: "AUSTRALIA" },
            { n: "PORT DE BOUC",	l: "FRANCE" },
            { n: "PORT DE FEDALA",	l: "MOROCCO" },
            { n: "PORT DE L'AMITIE",	l: "MAURITANIA" },
            { n: "PORT DENARAU",	l: "FIJI" },
            { n: "PORT DICKSON",	l: "MALAYSIA" },
            { n: "PORT DOLOMITE",	l: "UNITED STATES" },
            { n: "PORT DOUGLAS",	l: "AUSTRALIA" },
            { n: "PORT EADS",	l: "UNITED STATES" },
            { n: "PORT EDWARD",	l: "CANADA" },
            { n: "PORT ELIZABETH",	l: "SOUTH AFRICA" },
            { n: "PORT ELLEN",	l: "UNITED KINGDOM" },
            { n: "PORT ESQUIVEL",	l: "JAMAICA" },
            { n: "PORT ETIENNE",	l: "MAURITANIA" },
            { n: "PORT EVERGLADES",	l: "UNITED STATES" },
            { n: "PORT GAMBLE",	l: "UNITED STATES" },
            { n: "PORT GENTIL",	l: "GABON" },
            { n: "PORT GILES",	l: "AUSTRALIA" },
            { n: "PORT GYSPUM",	l: "UNITED STATES" },
            { n: "PORT HARCOURT",	l: "NIGERIA" },
            { n: "PORT HARDY",	l: "CANADA" },
            { n: "PORT HARMON",	l: "CANADA" },
            { n: "PORT HAWKESBURY",	l: "CANADA" },
            { n: "PORT HEDLAND",	l: "AUSTRALIA" },
            { n: "PORT HENRY",	l: "UNITED KINGDOM" },
            { n: "PORT HENRY (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "PORT HOPE",	l: "CANADA" },
            { n: "PORT HUON",	l: "AUSTRALIA" },
            { n: "PORT HURON",	l: "UNITED STATES" },
            { n: "PORT IBRAHIM",	l: "EGYPT" },
            { n: "PORT INLAND",	l: "UNITED STATES" },
            { n: "PORT IRENE",	l: "PHILIPPINES" },
            { n: "PORT ISABEL",	l: "UNITED STATES" },
            { n: "PORT JACKSON",	l: "AUSTRALIA" },
            { n: "PORT JEFFERSON",	l: "UNITED STATES" },
            { n: "PORT JEROME",	l: "FRANCE" },
            { n: "PORT KAISER",	l: "JAMAICA" },
            { n: "PORT KALIORANG, EAST KALIMANTAN",	l: "INDONESIA" },
            { n: "PORT KAMSAR",	l: "GUINEA" },
            { n: "PORT KARMOEY",	l: "NORWAY" },
            { n: "PORT KELANG",	l: "MALAYSIA" },
            { n: "PORT KEMBLA",	l: "AUSTRALIA" },
            { n: "PORT KENNEDY",	l: "AUSTRALIA" },
            { n: "PORT KHALID",	l: "UNITED ARAB EMIRATES" },
            { n: "PORT KLANG",	l: "MALAYSIA" },
            { n: "PORT LAKI",	l: "GREECE" },
            { n: "PORT LAMONT",	l: "UNITED KINGDOM" },
            { n: "PORT LATTA",	l: "AUSTRALIA" },
            { n: "PORT LAVACA",	l: "UNITED STATES" },
            { n: "PORT LINCOLN",	l: "AUSTRALIA" },
            { n: "PORT LOUIS",	l: "MAURITIUS" },
            { n: "PORT LYAUTEY",	l: "MOROCCO" },
            { n: "PORT MAITLAND",	l: "CANADA" },
            { n: "PORT MANATEE",	l: "UNITED STATES" },
            { n: "PORT MARLBOROUGH",	l: "NEW ZEALAND" },
            { n: "PORT MCNEILL",	l: "CANADA" },
            { n: "PORT MEDWAY",	l: "CANADA" },
            { n: "PORT MELLON",	l: "CANADA" },
            { n: "PORT METHANIER",	l: "ALGERIA" },
            { n: "PORT MOODY",	l: "CANADA" },
            { n: "PORT MORESBY",	l: "PAPUA NEW GUINEA" },
            { n: "PORT NADOR",	l: "MOROCCO" },
            { n: "PORT NATAL",	l: "SOUTH AFRICA" },
            { n: "PORT NECHES",	l: "UNITED STATES" },
            { n: "PORT NEWARK",	l: "UNITED STATES" },
            { n: "PORT NOLLOTH",	l: "SOUTH AFRICA" },
            { n: "PORT OF SAN FERNANDO (PILOT STATION)",	l: "PHILIPPINES" },
            { n: "PORT OF SOUTH LOUISIANA",	l: "UNITED STATES" },
            { n: "PORT OF SPAIN",	l: "TRINIDAD AND TOBAGO" },
            { n: "PORT OKHA",	l: "INDIA" },
            { n: "PORT PENRHYN",	l: "UNITED KINGDOM" },
            { n: "PORT PHILLIP BAY",	l: "AUSTRALIA" },
            { n: "PORT PIRIE",	l: "AUSTRALIA" },
            { n: "PORT POLNOCNY",	l: "POLAND" },
            { n: "PORT PURCELL",	l: "BRITISH VIRGIN ISLANDS" },
            { n: "PORT QASIM",	l: "PAKISTAN" },
            { n: "PORT RASHID",	l: "UNITED ARAB EMIRATES" },
            { n: "PORT RAYSUT",	l: "OMAN" },
            { n: "PORT READING",	l: "UNITED STATES" },
            { n: "PORT REDI",	l: "INDIA" },
            { n: "PORT REFUGE",	l: "COCOS ISLANDS" },
            { n: "PORT REITZ",	l: "KENYA" },
            { n: "PORT RHOADES",	l: "JAMAICA" },
            { n: "PORT RICHMOND",	l: "UNITED STATES" },
            { n: "PORT ROYAL (JAMAICA)",	l: "JAMAICA" },
            { n: "PORT ROYAL (USA)",	l: "UNITED STATES" },
            { n: "PORT SAGUENAY",	l: "CANADA" },
            { n: "PORT SAID",	l: "EGYPT" },
            { n: "PORT SAINT LOUIS",	l: "MADAGASCAR" },
            { n: "PORT SAN LUIS",	l: "UNITED STATES" },
            { n: "PORT SCALDIA",	l: "NETHERLANDS" },
            { n: "PORT SLADE",	l: "UNITED KINGDOM" },
            { n: "PORT ST JOE",	l: "UNITED STATES" },
            { n: "PORT ST LOUIS DU RHONE",	l: "FRANCE" },
            { n: "PORT ST MARY",	l: "ISLE OF MAN" },
            { n: "PORT ST NICHOLAS",	l: "FRANCE" },
            { n: "PORT STANLEY",	l: "CANADA" },
            { n: "PORT STANLEY (CANADA)",	l: "CANADA" },
            { n: "PORT STANLEY (FALKLAND IS)",	l: "FALKLAND ISLANDS (ISLAS MALVINAS)" },
            { n: "PORT STANVAC",	l: "AUSTRALIA" },
            { n: "PORT SUDAN",	l: "SUDAN" },
            { n: "PORT SULPHUR",	l: "UNITED STATES" },
            { n: "PORT SULTAN QABOOS",	l: "OMAN" },
            { n: "PORT SUNLIGHT",	l: "UNITED KINGDOM" },
            { n: "PORT SUTTON",	l: "UNITED STATES" },
            { n: "PORT SWETTENHAM",	l: "MALAYSIA" },
            { n: "PORT TALBOT",	l: "UNITED KINGDOM" },
            { n: "PORT TAMPA",	l: "UNITED STATES" },
            { n: "PORT TARANAKI",	l: "NEW ZEALAND" },
            { n: "PORT TEWFIK",	l: "EGYPT" },
            { n: "PORT TOGA",	l: "FRANCE" },
            { n: "PORT TOWNSEND",	l: "UNITED STATES" },
            { n: "PORT TUDOR",	l: "KENYA" },
            { n: "PORT TUDY",	l: "FRANCE" },
            { n: "PORT VENDRES",	l: "FRANCE" },
            { n: "PORT VICTORIA",	l: "SEYCHELLES" },
            { n: "PORT VILA",	l: "VANUATU" },
            { n: "PORT VINCENT",	l: "AUSTRALIA" },
            { n: "PORT WALCOTT",	l: "AUSTRALIA" },
            { n: "PORT WASHINGTON",	l: "UNITED STATES" },
            { n: "PORT WELLER",	l: "CANADA" },
            { n: "PORT WENTWORTH",	l: "UNITED STATES" },
            { n: "PORT WILLIAMS",	l: "CHILE" },
            { n: "PORTAVOGIE",	l: "UNITED KINGDOM" },
            { n: "PORTBURY",	l: "UNITED KINGDOM" },
            { n: "PORT-DANIEL-GASCONS",	l: "CANADA" },
            { n: "PORTEL",	l: "BRAZIL" },
            { n: "PORTER BAY",	l: "AUSTRALIA" },
            { n: "PORTHMADOG",	l: "UNITED KINGDOM" },
            { n: "PORTICI",	l: "ITALY" },
            { n: "PORTIMAO",	l: "PORTUGAL" },
            { n: "PORTISHEAD",	l: "UNITED KINGDOM" },
            { n: "PORTLAND (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "PORTLAND (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "PORTLAND (USA-MAINE)",	l: "UNITED STATES" },
            { n: "PORTLAND (USA-OREGON)",	l: "UNITED STATES" },
            { n: "PORTNEUF",	l: "CANADA" },
            { n: "PORTO ABC NORTE",	l: "BRAZIL" },
            { n: "PORTO ALEGRE",	l: "BRAZIL" },
            { n: "PORTO AMBOIM",	l: "ANGOLA" },
            { n: "PORTO AMELIA",	l: "MOZAMBIQUE" },
            { n: "PORTO AZZURRO",	l: "ITALY" },
            { n: "PORTO BELO",	l: "MOZAMBIQUE" },
            { n: "PORTO BELO (BRAZIL)",	l: "BRAZIL" },
            { n: "PORTO CERVO",	l: "ITALY" },
            { n: "PORTO CORSINI",	l: "ITALY" },
            { n: "PORTO DA POVOA DE VARZIM",	l: "PORTUGAL" },
            { n: "PORTO DE VILA DO CONDE",	l: "PORTUGAL" },
            { n: "PORTO DI MALAMOCCO",	l: "ITALY" },
            { n: "PORTO DI VIBO VALENTIA MARINA",	l: "ITALY" },
            { n: "PORTO DO ACU",	l: "BRAZIL" },
            { n: "PORTO DOBELA",	l: "MOZAMBIQUE" },
            { n: "PORTO EMPEDOCLE",	l: "ITALY" },
            { n: "PORTO FOXI",	l: "ITALY" },
            { n: "PORTO GRANDE",	l: "CAPE VERDE" },
            { n: "PORTO MARGHERA",	l: "ITALY" },
            { n: "PORTO MARMOREO",	l: "ITALY" },
            { n: "PORTO NOGARO",	l: "ITALY" },
            { n: "PORTO NOVO",	l: "BENIN" },
            { n: "PORTO NUEVO",	l: "PUERTO RICO" },
            { n: "PORTO PEIXINHO",	l: "BRAZIL" },
            { n: "PORTO PRAIA",	l: "CAPE VERDE" },
            { n: "PORTO ROMANO",	l: "ALBANIA" },
            { n: "PORTO SACO",	l: "ANGOLA" },
            { n: "PORTO SAN LEONARDO",	l: "ITALY" },
            { n: "PORTO SANTO",	l: "PORTUGAL" },
            { n: "PORTO SANTO STEFANO",	l: "ITALY" },
            { n: "PORTO TORRES",	l: "ITALY" },
            { n: "PORTO TROMBETAS",	l: "BRAZIL" },
            { n: "PORTO VECCHIO",	l: "FRANCE" },
            { n: "PORTO VESME",	l: "ITALY" },
            { n: "PORTOBELO",	l: "PANAMA" },
            { n: "PORTOCEL",	l: "BRAZIL" },
            { n: "PORTOFERRAIO",	l: "ITALY" },
            { n: "PORTOFINO",	l: "ITALY" },
            { n: "PORTO-ILHA",	l: "BRAZIL" },
            { n: "PORTREE",	l: "UNITED KINGDOM" },
            { n: "PORTRUSH",	l: "UNITED KINGDOM" },
            { n: "PORTSMOUTH (DOMINICA)",	l: "DOMINICA" },
            { n: "PORTSMOUTH (NEW HAMPSHIRE)",	l: "UNITED STATES" },
            { n: "PORTSMOUTH (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "PORTSMOUTH (USA-HAMPTON ROADS)",	l: "UNITED STATES" },
            { n: "PORTSMOUTH (USA-NEW HAMPSHIRE)",	l: "UNITED STATES" },
            { n: "PORTUGAL (COUNTRY)",	l: "PORTUGAL" },
            { n: "PORVENIR",	l: "CHILE" },
            { n: "PORVOO",	l: "FINLAND" },
            { n: "POSEIDONIA",	l: "GREECE" },
            { n: "POSO",	l: "INDONESIA" },
            { n: "POSYET",	l: "RUSSIA" },
            { n: "POTAGANNISSING BAY",	l: "CANADA" },
            { n: "POTI",	l: "GEORGIA" },
            { n: "POUM",	l: "NEW CALEDONIA" },
            { n: "POWELL RIVER",	l: "CANADA" },
            { n: "POYA",	l: "NEW CALEDONIA" },
            { n: "POZOS COLORADOS",	l: "COLOMBIA" },
            { n: "POZZALLO",	l: "ITALY" },
            { n: "POZZUOLI",	l: "ITALY" },
            { n: "PRACHUAP PORT",	l: "THAILAND" },
            { n: "PRAI",	l: "MALAYSIA" },
            { n: "PRAIA DA VITORIA",	l: "PORTUGAL" },
            { n: "PRAIA FORMOSA",	l: "PORTUGAL" },
            { n: "PRAIA MOLE",	l: "BRAZIL" },
            { n: "PRESCOTT",	l: "CANADA" },
            { n: "PRESTON",	l: "UNITED KINGDOM" },
            { n: "PRESTON (CUBA)",	l: "CUBA" },
            { n: "PREVEZA",	l: "GREECE" },
            { n: "PRIGORODNOYE",	l: "RUSSIA" },
            { n: "PRIMORSK",	l: "RUSSIA" },
            { n: "PRINCE RUPERT",	l: "CANADA" },
            { n: "PRINCESS CAY",	l: "THE BAHAMAS" },
            { n: "PRINCESS ROYAL HARBOUR",	l: "AUSTRALIA" },
            { n: "PRINCIPE ISLAND",	l: "SAO TOME AND PRINCIPE" },
            { n: "PRINOS",	l: "GREECE" },
            { n: "PRIOLO",	l: "ITALY" },
            { n: "PRIOLO GARGALLO",	l: "ITALY" },
            { n: "PRIRAZLOMNAYA",	l: "RUSSIA" },
            { n: "PRIRAZLOMNAYA DRILLING RIG",	l: "RUSSIA" },
            { n: "PROBOLINGGO",	l: "INDONESIA" },
            { n: "PRODECO",	l: "COLOMBIA" },
            { n: "PROGRESO",	l: "MEXICO" },
            { n: "PROINTAL",	l: "INDONESIA" },
            { n: "PRONY BAY",	l: "NEW CALEDONIA" },
            { n: "PROPER BAY",	l: "AUSTRALIA" },
            { n: "PROPRIANO",	l: "FRANCE" },
            { n: "PROSTNESET",	l: "NORWAY" },
            { n: "PROTI ISLAND",	l: "GREECE" },
            { n: "PROVIDENCE",	l: "UNITED STATES" },
            { n: "PROVIDENCIALES",	l: "TURKS AND CAICOS ISLANDS" },
            { n: "PROVIDENIJA",	l: "RUSSIA" },
            { n: "PROVINCETOWN",	l: "UNITED STATES" },
            { n: "PSACHNA EVOIAS",	l: "GREECE" },
            { n: "PSLP1",	l: "NEW ZEALAND" },
            { n: "PSLP2",	l: "COOK ISLANDS" },
            { n: "PSLP3",	l: "PITCAIRN" },
            { n: "PSLP4",	l: "CHILE" },
            { n: "PSLP5",	l: "CHILE" },
            { n: "PSLP6",	l: "CHILE" },
            { n: "PSVM TERMINAL",	l: "ANGOLA" },
            { n: "PT. NOIRE",	l: "CONGO" },
            { n: "PUERTO ACEVEDO",	l: "ARGENTINA" },
            { n: "PUERTO ANGAMOS",	l: "CHILE" },
            { n: "PUERTO ARMUELLES",	l: "PANAMA" },
            { n: "PUERTO BAHIA",	l: "COLOMBIA" },
            { n: "PUERTO BARRIOS",	l: "GUATEMALA" },
            { n: "PUERTO BATADERO",	l: "DOMINICAN REPUBLIC" },
            { n: "PUERTO BAYOVAR",	l: "PERU" },
            { n: "PUERTO BELGRANO",	l: "ARGENTINA" },
            { n: "PUERTO BOLIVAR (COLOMBIA)",	l: "COLOMBIA" },
            { n: "PUERTO BOLIVAR (ECUADOR)",	l: "ECUADOR" },
            { n: "PUERTO BORIES",	l: "CHILE" },
            { n: "PUERTO BRISA S.A.",	l: "COLOMBIA" },
            { n: "PUERTO BUITRAGO",	l: "ARGENTINA" },
            { n: "PUERTO CABALLO OIL TERMINAL",	l: "SPAIN" },
            { n: "PUERTO CABELLO",	l: "VENEZUELA" },
            { n: "PUERTO CABEZAS",	l: "NICARAGUA" },
            { n: "PUERTO CASTILLA",	l: "HONDURAS" },
            { n: "PUERTO CHACABUCO",	l: "CHILE" },
            { n: "PUERTO CHIAPAS",	l: "MEXICO" },
            { n: "PUERTO CHICAMA",	l: "PERU" },
            { n: "PUERTO CORALLE",	l: "CHILE" },
            { n: "PUERTO CORRAL",	l: "CHILE" },
            { n: "PUERTO CORTES",	l: "HONDURAS" },
            { n: "PUERTO DE HENECAN",	l: "HONDURAS" },
            { n: "PUERTO DE HIERRO",	l: "VENEZUELA" },
            { n: "PUERTO DE LA HONDURA OIL TERMINAL",	l: "SPAIN" },
            { n: "PUERTO DE LA LUZ Y LAS PALMAS",	l: "SPAIN" },
            { n: "PUERTO DE SANTA MARIA",	l: "SPAIN" },
            { n: "PUERTO DEL ROSARIO",	l: "SPAIN" },
            { n: "PUERTO DRUMMOND",	l: "COLOMBIA" },
            { n: "PUERTO DUARTE",	l: "DOMINICAN REPUBLIC" },
            { n: "PUERTO ESCONDIDO",	l: "MEXICO" },
            { n: "PUERTO GALVAN",	l: "ARGENTINA" },
            { n: "PUERTO INGENIERO ROCCA",	l: "ARGENTINA" },
            { n: "PUERTO IRADIER",	l: "EQUATORIAL GUINEA" },
            { n: "PUERTO ITABO",	l: "DOMINICAN REPUBLIC" },
            { n: "PUERTO JOSE TERMINAL",	l: "VENEZUELA" },
            { n: "PUERTO LA CRUZ",	l: "VENEZUELA" },
            { n: "PUERTO LIBERTAD",	l: "MEXICO" },
            { n: "PUERTO LIMON",	l: "COSTA RICA" },
            { n: "PUERTO MACIAS",	l: "EQUATORIAL GUINEA" },
            { n: "PUERTO MADERO",	l: "MEXICO" },
            { n: "PUERTO MADRYN",	l: "ARGENTINA" },
            { n: "PUERTO MARTINS",	l: "ARGENTINA" },
            { n: "PUERTO MEXICO",	l: "MEXICO" },
            { n: "PUERTO MIRANDA",	l: "VENEZUELA" },
            { n: "PUERTO MOIN",	l: "COSTA RICA" },
            { n: "PUERTO MONTT",	l: "CHILE" },
            { n: "PUERTO MORELAS",	l: "MEXICO" },
            { n: "PUERTO NATALES",	l: "CHILE" },
            { n: "PUERTO NUEVO",	l: "COLOMBIA" },
            { n: "PUERTO ORDAZ",	l: "VENEZUELA" },
            { n: "PUERTO PADRE",	l: "CUBA" },
            { n: "PUERTO PARANA",	l: "ARGENTINA" },
            { n: "PUERTO PATACHE",	l: "CHILE" },
            { n: "PUERTO PERCY",	l: "CHILE" },
            { n: "PUERTO PLATA",	l: "DOMINICAN REPUBLIC" },
            { n: "PUERTO PRINCESA",	l: "PHILIPPINES" },
            { n: "PUERTO PRODECO",	l: "COLOMBIA" },
            { n: "PUERTO PROGRESO",	l: "MEXICO" },
            { n: "PUERTO QUEQUEN",	l: "ARGENTINA" },
            { n: "PUERTO QUETZAL",	l: "GUATEMALA" },
            { n: "PUERTO REAL",	l: "SPAIN" },
            { n: "PUERTO RICO (COUNTRY)",	l: "PUERTO RICO" },
            { n: "PUERTO RICO LZ",	l: "PUERTO RICO" },
            { n: "PUERTO ROSALES",	l: "ARGENTINA" },
            { n: "PUERTO SANDINO",	l: "NICARAGUA" },
            { n: "PUERTO SANTA MARIA",	l: "SPAIN" },
            { n: "PUERTO SOMOZA",	l: "NICARAGUA" },
            { n: "PUERTO SUCRE",	l: "VENEZUELA" },
            { n: "PUERTO TARAFA",	l: "CUBA" },
            { n: "PUERTO VALLARTA",	l: "MEXICO" },
            { n: "PUERTO VENTANAS",	l: "CHILE" },
            { n: "PUERTO VIEJO DE AZUA",	l: "DOMINICAN REPUBLIC" },
            { n: "PUERTO WILLIAMS",	l: "CHILE" },
            { n: "PUERTO ZUNIGA",	l: "COLOMBIA" },
            { n: "PUGWASH",	l: "CANADA" },
            { n: "PUHOS",	l: "FINLAND" },
            { n: "PULA",	l: "CROATIA" },
            { n: "PULANG PISAU",	l: "INDONESIA" },
            { n: "PULAU AYER CHAWAN",	l: "SINGAPORE" },
            { n: "PULAU BAAI",	l: "INDONESIA" },
            { n: "PULAU BATAM",	l: "INDONESIA" },
            { n: "PULAU BUKOM",	l: "SINGAPORE" },
            { n: "PULAU BUNYU",	l: "INDONESIA" },
            { n: "PULAU LAUT",	l: "INDONESIA" },
            { n: "PULAU MERLIMAU",	l: "SINGAPORE" },
            { n: "PULAU PESEK",	l: "SINGAPORE" },
            { n: "PULAU SAMBU",	l: "INDONESIA" },
            { n: "PULAU SEBAROK",	l: "SINGAPORE" },
            { n: "PULAU TIKKUS ISLAND",	l: "INDONESIA" },
            { n: "PULAU TIOMAN",	l: "MALAYSIA" },
            { n: "PULOT",	l: "PHILIPPINES" },
            { n: "PULUPANDAN",	l: "PHILIPPINES" },
            { n: "PUNTA ALVEAR",	l: "ARGENTINA" },
            { n: "PUNTA ANCLA",	l: "ARGENTINA" },
            { n: "PUNTA ARENAS",	l: "CHILE" },
            { n: "PUNTA ARENAS (ECUADOR)",	l: "ECUADOR" },
            { n: "PUNTA ARENAS LZ",	l: "ECUADOR" },
            { n: "PUNTA CAMACHO",	l: "VENEZUELA" },
            { n: "PUNTA CANA",	l: "DOMINICAN REPUBLIC" },
            { n: "PUNTA CARDON",	l: "VENEZUELA" },
            { n: "PUNTA CEBALLOS TANKER TERMINAL",	l: "SPAIN" },
            { n: "PUNTA CHUNGO",	l: "CHILE" },
            { n: "PUNTA CIGUENA",	l: "ARGENTINA" },
            { n: "PUNTA COLORADA",	l: "ARGENTINA" },
            { n: "PUNTA CUCHILLO",	l: "VENEZUELA" },
            { n: "PUNTA DE PALMAS",	l: "VENEZUELA" },
            { n: "PUNTA DEL ESTE",	l: "URUGUAY" },
            { n: "PUNTA EUROPA TERMINAL",	l: "EQUATORIAL GUINEA" },
            { n: "PUNTA GORDA",	l: "CUBA" },
            { n: "PUNTA ICOTEA",	l: "VENEZUELA" },
            { n: "PUNTA LOBITOS",	l: "PERU" },
            { n: "PUNTA LOYOLA",	l: "ARGENTINA" },
            { n: "PUNTA LUCERO TANKER TERMINAL",	l: "SPAIN" },
            { n: "PUNTA MORALES",	l: "COSTA RICA" },
            { n: "PUNTA PADRONES",	l: "CHILE" },
            { n: "PUNTA PATACHE",	l: "CHILE" },
            { n: "PUNTA PENNA",	l: "ITALY" },
            { n: "PUNTA PEREIRA",	l: "URUGUAY" },
            { n: "PUNTA PIEDRAS",	l: "VENEZUELA" },
            { n: "PUNTA QUILLA",	l: "ARGENTINA" },
            { n: "PUNTA RINCON",	l: "PANAMA" },
            { n: "PUNTA TOTORALILLO",	l: "CHILE" },
            { n: "PUNTA VENADO",	l: "MEXICO" },
            { n: "PUNTARENAS",	l: "COSTA RICA" },
            { n: "PUNTO FRANCO",	l: "ITALY" },
            { n: "PURFLEET",	l: "UNITED KINGDOM" },
            { n: "PUSAN",	l: "KOREA, REPUBLIC" },
            { n: "PUTIAN DONGWU",	l: "CHINA" },
            { n: "PUTTALAM",	l: "SRI LANKA" },
            { n: "PUTTALAM",	l: "SRI LANKA" },
            { n: "PUVIRNITUQ",	l: "CANADA" },
            { n: "PYARNU",	l: "ESTONIA" },
            { n: "PYEONGTAEK",	l: "KOREA, REPUBLIC" },
            { n: "PYLADES ANCHORAGE",	l: "CANADA" },
            { n: "PYLOS",	l: "GREECE" },
            { n: "PYRENEES",	l: "AUSTRALIA" },
            { n: "PYRMONT",	l: "AUSTRALIA" },
            { n: "PYTHAGORIO",	l: "GREECE" },
            { n: "QAANAAQ",	l: "GREENLAND" },
            { n: "QADIMAH",	l: "SAUDI ARABIA" },
            { n: "QALHAT",	l: "OMAN" },
            { n: "QAQORTOQ",	l: "GREENLAND" },
            { n: "QASIGIANNGUIT",	l: "GREENLAND" },
            { n: "QASR AHMED",	l: "LIBYA" },
            { n: "QATAR (COUNTRY)",	l: "QATAR" },
            { n: "QATAR OPL",	l: "QATAR" },
            { n: "QATAR STEEL JETTY",	l: "QATAR" },
            { n: "QEQERTARSUAQ",	l: "GREENLAND" },
            { n: "QIANWAN",	l: "CHINA" },
            { n: "QIDONG",	l: "CHINA" },
            { n: "QIJIAXIANG",	l: "CHINA" },
            { n: "QIKIQTARJUAQ, NU",	l: "CANADA" },
            { n: "QIMEN",	l: "CHINA" },
            { n: "QINGDAO",	l: "CHINA" },
            { n: "QINGPU",	l: "CHINA" },
            { n: "QINHUANGDAO",	l: "CHINA" },
            { n: "QINZHOU",	l: "CHINA" },
            { n: "QIONGZHOU STRAIT",	l: "CHINA" },
            { n: "QUA IBOE",	l: "NIGERIA" },
            { n: "QUANG BINH",	l: "VIETNAM" },
            { n: "QUANG NGAI",	l: "VIETNAM" },
            { n: "QUANGNINH",	l: "VIETNAM" },
            { n: "QUANZHOU",	l: "CHINA" },
            { n: "QUAQTAQ, QC",	l: "CANADA" },
            { n: "QUATSINO SOUND",	l: "CANADA" },
            { n: "QUEBEC",	l: "CANADA" },
            { n: "QUEBRACHO TERMINAL",	l: "ARGENTINA" },
            { n: "QUEEN CHARLOTTE STRAIT",	l: "CANADA" },
            { n: "QUEENBOROUGH",	l: "UNITED KINGDOM" },
            { n: "QUEEN'S PARK TANKER TERMINAL",	l: "GRENADA" },
            { n: "QUEENSTOWN",	l: "IRELAND" },
            { n: "QUELIMANE",	l: "MOZAMBIQUE" },
            { n: "QUELLON",	l: "CHILE" },
            { n: "QUEMCHI",	l: "CHILE" },
            { n: "QUEPOS",	l: "COSTA RICA" },
            { n: "QUEQUEN",	l: "ARGENTINA" },
            { n: "QUEZON",	l: "PHILIPPINES" },
            { n: "QUI NHON",	l: "VIETNAM" },
            { n: "QUILIANO TERMINAL",	l: "ITALY" },
            { n: "QUIMPER-CORNIGUEL",	l: "FRANCE" },
            { n: "QUINCY (MASSACHUSETTS)",	l: "UNITED STATES" },
            { n: "QUINFUQUENA TERMINAL",	l: "ANGOLA" },
            { n: "QUINTERO",	l: "CHILE" },
            { n: "QUOIN ISLAND",	l: "OMAN" },
            { n: "QUONSET POINT",	l: "UNITED STATES" },
            { n: "QUSEIR",	l: "EGYPT" },
            { n: "QUY NHON",	l: "VIETNAM" },
            { n: "RAAHE",	l: "FINLAND" },
            { n: "RABAT",	l: "MOROCCO" },
            { n: "RABAUL",	l: "PAPUA NEW GUINEA" },
            { n: "RABIGH",	l: "SAUDI ARABIA" },
            { n: "RABON GRANDE",	l: "MEXICO" },
            { n: "RACAHE",	l: "PHILIPPINES" },
            { n: "RACINE",	l: "UNITED STATES" },
            { n: "RADES",	l: "TUNISIA" },
            { n: "RADICATEL",	l: "FRANCE" },
            { n: "RAFINA",	l: "GREECE" },
            { n: "RAFNES",	l: "NORWAY" },
            { n: "RAGGED PT ANCHORAGE",	l: "UNITED STATES" },
            { n: "RAHJA",	l: "FINLAND" },
            { n: "RAIATEA",	l: "FRENCH POLYNESIA" },
            { n: "RAINIER",	l: "UNITED STATES" },
            { n: "RAMALLO",	l: "ARGENTINA" },
            { n: "RAMBA",	l: "INDONESIA" },
            { n: "RAMFJORDNES",	l: "NORWAY" },
            { n: "RAMSEY",	l: "ISLE OF MAN" },
            { n: "RAMSGATE",	l: "UNITED KINGDOM" },
            { n: "RAMSHALL",	l: "SWEDEN" },
            { n: "RAMSUND",	l: "NORWAY" },
            { n: "RANDERS",	l: "DENMARK" },
            { n: "RANGIROA",	l: "FRENCH POLYNESIA" },
            { n: "RANGOON",	l: "MYANMAR" },
            { n: "RANKIN INLET, NU",	l: "CANADA" },
            { n: "RANLISHAN",	l: "CHINA" },
            { n: "RANONG",	l: "THAILAND" },
            { n: "RAOPING",	l: "CHINA" },
            { n: "RAOS",	l: "SPAIN" },
            { n: "RAPASAARI",	l: "FINLAND" },
            { n: "RAPID BAY",	l: "AUSTRALIA" },
            { n: "RAROTONGA",	l: "COOK ISLANDS" },
            { n: "RAS AL GHAR",	l: "SAUDI ARABIA" },
            { n: "RAS AL HADD",	l: "OMAN" },
            { n: "RAS AL KHAFJI",	l: "SAUDI ARABIA" },
            { n: "RAS AL KHAIMAH",	l: "UNITED ARAB EMIRATES" },
            { n: "RAS AL KHAIR",	l: "SAUDI ARABIA" },
            { n: "RAS AL MA'AJJIZ TERMINAL",	l: "SAUDI ARABIA" },
            { n: "RAS AL MISHAB",	l: "SAUDI ARABIA" },
            { n: "RAS ALKATHEEB",	l: "YEMEN" },
            { n: "RAS AL-KHAIR",	l: "SAUDI ARABIA" },
            { n: "RAS BAHREGAN",	l: "IRAN" },
            { n: "RAS BUDRAN",	l: "EGYPT" },
            { n: "RAS EL HILAL",	l: "LIBYA" },
            { n: "RAS ES SIDER",	l: "LIBYA" },
            { n: "RAS GHARIB",	l: "EGYPT" },
            { n: "RAS HAFUN",	l: "SOMALIA" },
            { n: "RAS ISA MARINE TERMINAL",	l: "YEMEN" },
            { n: "RAS LAFFAN",	l: "QATAR" },
            { n: "RAS LANUF",	l: "LIBYA" },
            { n: "RAS SHUKHEIR",	l: "EGYPT" },
            { n: "RAS SUDR",	l: "EGYPT" },
            { n: "RAS TANURA",	l: "SAUDI ARABIA" },
            { n: "RAS ZUBBAYA",	l: "UNITED ARAB EMIRATES" },
            { n: "RASA",	l: "CROATIA" },
            { n: "RASCO HARBOUR",	l: "LIBYA" },
            { n: "RASTA",	l: "SWEDEN" },
            { n: "RATHMULLEN",	l: "IRELAND" },
            { n: "RATNAGIRI",	l: "INDIA" },
            { n: "RATTLESNAKE",	l: "UNITED STATES" },
            { n: "RAUBERGVIKA",	l: "NORWAY" },
            { n: "RAUFARHOFN",	l: "ICELAND" },
            { n: "RAUMA",	l: "FINLAND" },
            { n: "RAUSAND",	l: "NORWAY" },
            { n: "RAUTARUUKI",	l: "FINLAND" },
            { n: "RAVENNA",	l: "ITALY" },
            { n: "RAVENSBOURNE",	l: "NEW ZEALAND" },
            { n: "RAYONG TPI TERMINAL",	l: "THAILAND" },
            { n: "RECALADA",	l: "ARGENTINA" },
            { n: "RECALADA PILOT STATION",	l: "ARGENTINA" },
            { n: "RECIFE",	l: "BRAZIL" },
            { n: "RED BEACH",	l: "VIETNAM" },
            { n: "RED DOG",	l: "UNITED STATES" },
            { n: "RED END",	l: "UNITED STATES" },
            { n: "RED SEA",	l: "SAUDI ARABIA" },
            { n: "RED START",	l: "UNITED STATES" },
            { n: "REDCAR",	l: "UNITED KINGDOM" },
            { n: "REDCLIFFE BAY",	l: "UNITED KINGDOM" },
            { n: "REDI",	l: "INDIA" },
            { n: "REDONDA ISLAND",	l: "BRAZIL" },
            { n: "REDWOOD CITY",	l: "UNITED STATES" },
            { n: "REEDHAM",	l: "UNITED KINGDOM" },
            { n: "REGENCIA",	l: "BRAZIL" },
            { n: "REGGIO CALABRIA",	l: "ITALY" },
            { n: "REIHOKU",	l: "JAPAN" },
            { n: "REISUI",	l: "KOREA, REPUBLIC" },
            { n: "REKEFJORD",	l: "NORWAY" },
            { n: "REMBANG",	l: "INDONESIA" },
            { n: "REMPO",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "RENDSBURG",	l: "GERMANY" },
            { n: "RENGAT",	l: "INDONESIA" },
            { n: "RENI",	l: "UKRAINE" },
            { n: "RENSSELAER",	l: "UNITED STATES" },
            { n: "RENTOUJI",	l: "CHINA" },
            { n: "REPOSAARI",	l: "FINLAND" },
            { n: "REPULSE BAY, NU",	l: "CANADA" },
            { n: "REQUEJADA",	l: "SPAIN" },
            { n: "RESERVE",	l: "UNITED STATES" },
            { n: "RESOLUTE",	l: "CANADA" },
            { n: "RETHIMNON",	l: "GREECE" },
            { n: "REUNION",	l: "REUNION" },
            { n: "REUNION (COUNTRY)",	l: "REUNION" },
            { n: "REVAL",	l: "ESTONIA" },
            { n: "REVDANDA",	l: "INDIA" },
            { n: "REVERE",	l: "UNITED STATES" },
            { n: "REVITHOUSSA",	l: "GREECE" },
            { n: "REY MALABO",	l: "EQUATORIAL GUINEA" },
            { n: "REYDARFJORDUR",	l: "ICELAND" },
            { n: "REYKJAVIK",	l: "ICELAND" },
            { n: "RHODES",	l: "GREECE" },
            { n: "RHONE",	l: "FRANCE" },
            { n: "RIBADEO",	l: "SPAIN" },
            { n: "RICHARDS BAY",	l: "SOUTH AFRICA" },
            { n: "RICHBOROUGH PORT",	l: "UNITED KINGDOM" },
            { n: "RICHIBUCTO",	l: "CANADA" },
            { n: "RICHMOND (CALIFORNIA)",	l: "UNITED STATES" },
            { n: "RICHMOND (VIRGINIA)",	l: "UNITED STATES" },
            { n: "RIDHAM DOCK",	l: "UNITED KINGDOM" },
            { n: "RIDLEY ISLAND",	l: "CANADA" },
            { n: "RIGA",	l: "LATVIA" },
            { n: "RIJEKA",	l: "CROATIA" },
            { n: "RIJEKA DUBROVACKA",	l: "CROATIA" },
            { n: "RIMOUSKI",	l: "CANADA" },
            { n: "RINGASKIDDY",	l: "IRELAND" },
            { n: "RINGI COVE",	l: "SOLOMON ISLANDS" },
            { n: "RINGKOBING",	l: "DENMARK" },
            { n: "RIO BUENO",	l: "JAMAICA" },
            { n: "RIO CORDOBA",	l: "COLOMBIA" },
            { n: "RIO CULLEN MARINE TERMINAL",	l: "ARGENTINA" },
            { n: "RIO DE JANEIRO",	l: "BRAZIL" },
            { n: "RIO GALLEGOS",	l: "ARGENTINA" },
            { n: "RIO GRANDE (ARGENTINA)",	l: "ARGENTINA" },
            { n: "RIO GRANDE (BRAZIL)",	l: "BRAZIL" },
            { n: "RIO HAINA",	l: "DOMINICAN REPUBLIC" },
            { n: "RIO MARINA",	l: "ITALY" },
            { n: "RIO TUBA",	l: "PHILIPPINES" },
            { n: "RION",	l: "GREECE" },
            { n: "RISAVIKA",	l: "NORWAY" },
            { n: "RISDON",	l: "AUSTRALIA" },
            { n: "RISOR",	l: "NORWAY" },
            { n: "RISTIINA",	l: "FINLAND" },
            { n: "RIVER BLACKWATER",	l: "UNITED KINGDOM" },
            { n: "RIVER PLATE LIGHTERING AREAS",	l: "ARGENTINA" },
            { n: "RIVERHEAD TERMINAL",	l: "UNITED STATES" },
            { n: "RIZE",	l: "TURKEY" },
            { n: "RIZHAO",	l: "CHINA" },
            { n: "ROAD HARBOUR",	l: "BRITISH VIRGIN ISLANDS" },
            { n: "ROADTOWN",	l: "BRITISH VIRGIN ISLANDS" },
            { n: "ROBERTS BANK",	l: "CANADA" },
            { n: "ROBERTS BAY, NU",	l: "CANADA" },
            { n: "ROCHEFORT & TONNAY-CHARENTE",	l: "FRANCE" },
            { n: "ROCHESTER (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "ROCHESTER (USA)",	l: "UNITED STATES" },
            { n: "ROCK ISLAND BAY ENTRANCE",	l: "UNITED STATES" },
            { n: "ROCKHAMPTON",	l: "AUSTRALIA" },
            { n: "ROCKLAND",	l: "UNITED STATES" },
            { n: "ROCKPORT",	l: "UNITED STATES" },
            { n: "ROCKY POINT",	l: "JAMAICA" },
            { n: "RODBYHAVN",	l: "DENMARK" },
            { n: "RODMAN NAVAL STATION",	l: "PANAMA" },
            { n: "RODRIGUES ISLAND",	l: "MAURITIUS" },
            { n: "RODVIG",	l: "DENMARK" },
            { n: "ROEBOURNE",	l: "AUSTRALIA" },
            { n: "ROGNAN",	l: "NORWAY" },
            { n: "ROHUKULA",	l: "ESTONIA" },
            { n: "ROJA",	l: "LATVIA" },
            { n: "ROKKO ISLAND",	l: "JAPAN" },
            { n: "ROMANIA (COUNTRY)",	l: "ROMANIA" },
            { n: "RONEHAMN",	l: "SWEDEN" },
            { n: "RONG DOI",	l: "VIETNAM" },
            { n: "RONGCHENG",	l: "CHINA" },
            { n: "RONGQI",	l: "CHINA" },
            { n: "RONGSHAN",	l: "CHINA" },
            { n: "RONGXING",	l: "CHINA" },
            { n: "RONNE",	l: "DENMARK" },
            { n: "RONNEBY",	l: "SWEDEN" },
            { n: "RONNSKAR",	l: "SWEDEN" },
            { n: "ROOMASSAARE",	l: "ESTONIA" },
            { n: "RORVIK",	l: "NORWAY" },
            { n: "ROSARIO",	l: "ARGENTINA" },
            { n: "ROSARITO TERMINAL",	l: "MEXICO" },
            { n: "ROSAS",	l: "SPAIN" },
            { n: "ROSCOFF-BLOSCON",	l: "FRANCE" },
            { n: "ROSEAU",	l: "DOMINICA" },
            { n: "ROSPO DI MARE",	l: "ITALY" },
            { n: "ROSSLARE EUROPORT",	l: "IRELAND" },
            { n: "ROSTOCK",	l: "GERMANY" },
            { n: "ROSTOV-ON-DON",	l: "RUSSIA" },
            { n: "ROSYTH",	l: "UNITED KINGDOM" },
            { n: "ROTA",	l: "SPAIN" },
            { n: "ROTA PORT",	l: "TURKEY" },
            { n: "ROTHESAY",	l: "UNITED KINGDOM" },
            { n: "ROTTERDAM",	l: "NETHERLANDS" },
            { n: "ROTTERDAM ISLAND 6244NN",	l: "MADAGASCAR" },
            { n: "ROTT-HBG",	l: "NETHERLANDS" },
            { n: "ROUAD ISLAND",	l: "SYRIA" },
            { n: "ROUEN",	l: "FRANCE" },
            { n: "ROUGE RIVER ENTRANCE",	l: "UNITED STATES" },
            { n: "ROUTING CAN USG",	l: "UNITED STATES" },
            { n: "ROVIGO",	l: "ITALY" },
            { n: "ROVINJ",	l: "CROATIA" },
            { n: "ROWHEDGE",	l: "UNITED KINGDOM" },
            { n: "ROXAS (MINDANAO)",	l: "PHILIPPINES" },
            { n: "ROXAS CITY",	l: "PHILIPPINES" },
            { n: "ROYTTA",	l: "FINLAND" },
            { n: "ROZENBURG",	l: "NETHERLANDS" },
            { n: "ROZI",	l: "INDIA" },
            { n: "RUBY PRINCESS",	l: "VIETNAM" },
            { n: "RUDKOBING",	l: "DENMARK" },
            { n: "RUGAO",	l: "CHINA" },
            { n: "RUHRORT",	l: "GERMANY" },
            { n: "RUISBROEK",	l: "BELGIUM" },
            { n: "RUMOI",	l: "JAPAN" },
            { n: "RUNAVIK",	l: "FAROE ISLANDS" },
            { n: "RUNCORN",	l: "UNITED KINGDOM" },
            { n: "RUNDVIK",	l: "SWEDEN" },
            { n: "RUPERT INLET",	l: "CANADA" },
            { n: "RUSHBROOKE",	l: "IRELAND" },
            { n: "RUSSIA (COUNTRY)",	l: "RUSSIA" },
            { n: "RUSSIAN PACIFIC",	l: "RUSSIA" },
            { n: "RUWAIS",	l: "UNITED ARAB EMIRATES" },
            { n: "RYA HARBOUR",	l: "SWEDEN" },
            { n: "RYE",	l: "UNITED KINGDOM" },
            { n: "S AMERICA",	l: "BRAZIL" },
            { n: "S CHINA",	l: "CHINA" },
            { n: "S E SHOAL LIGHT",	l: "CANADA" },
            { n: "S JAPAN",	l: "JAPAN" },
            { n: "SA KY",	l: "VIETNAM" },
            { n: "SABANG",	l: "INDONESIA" },
            { n: "SABETTA",	l: "RUSSIA" },
            { n: "SABIC STEEL JETTY",	l: "SAUDI ARABIA" },
            { n: "SABINE",	l: "UNITED STATES" },
            { n: "SABINE ATMI",	l: "UNITED STATES" },
            { n: "SABINE NO 1 LZ",	l: "UNITED STATES" },
            { n: "SABINE NO 2 LZ",	l: "UNITED STATES" },
            { n: "SABINE PASS",	l: "UNITED STATES" },
            { n: "SABINE RIVER END",	l: "UNITED STATES" },
            { n: "SACRAMENTO",	l: "UNITED STATES" },
            { n: "SAEKI",	l: "JAPAN" },
            { n: "SAFAGA",	l: "EGYPT" },
            { n: "SAFI",	l: "MOROCCO" },
            { n: "SAGANOSEKI",	l: "JAPAN" },
            { n: "SAGAR",	l: "INDIA" },
            { n: "SAGAR ROAD",	l: "INDIA" },
            { n: "SAGINAW",	l: "UNITED STATES" },
            { n: "SAGUNTO",	l: "SPAIN" },
            { n: "SAHABAT",	l: "MALAYSIA" },
            { n: "SAHABAT PILOT STN OIL JETTY",	l: "MALAYSIA" },
            { n: "SAIDA",	l: "LEBANON" },
            { n: "SAIDO-ZAKI",	l: "JAPAN" },
            { n: "SAIGON",	l: "VIETNAM" },
            { n: "SAIKI",	l: "JAPAN" },
            { n: "SAIMAA CANAL",	l: "FINLAND" },
            { n: "SAINT JOHN",	l: "CANADA" },
            { n: "SAINT THOMAS-DE-KENT",	l: "CANADA" },
            { n: "SAINTE-ANNE-DES-MONTS",	l: "CANADA" },
            { n: "SAINT-OUEN",	l: "FRANCE" },
            { n: "SAIPAN ISLAND",	l: "NORTHERN MARIANA ISLANDS" },
            { n: "SAIQI",	l: "CHINA" },
            { n: "SAKAI",	l: "JAPAN" },
            { n: "SAKAIDE",	l: "JAPAN" },
            { n: "SAKAIMINATO",	l: "JAPAN" },
            { n: "SAKATA",	l: "JAPAN" },
            { n: "SAKHALIN",	l: "RUSSIA" },
            { n: "SAKHRI",	l: "INDIA" },
            { n: "SAKITO",	l: "JAPAN" },
            { n: "SAKRA BASIN",	l: "SINGAPORE" },
            { n: "SAKSKOEBING",	l: "DENMARK" },
            { n: "SAL ISLAND",	l: "CAPE VERDE" },
            { n: "SALACGRIVA",	l: "LATVIA" },
            { n: "SALADIN MARINE TERMINAL",	l: "AUSTRALIA" },
            { n: "SALALAH",	l: "OMAN" },
            { n: "SALAVERRY",	l: "PERU" },
            { n: "SALAWATI",	l: "INDONESIA" },
            { n: "SALAYA",	l: "INDIA" },
            { n: "SALDANHA BAY",	l: "SOUTH AFRICA" },
            { n: "SALEEF",	l: "YEMEN" },
            { n: "SALEM",	l: "UNITED STATES" },
            { n: "SALERNO",	l: "ITALY" },
            { n: "SALFORD",	l: "UNITED KINGDOM" },
            { n: "SALINA",	l: "BONAIRE" },
            { n: "SALINA CRUZ",	l: "MEXICO" },
            { n: "SALINAS",	l: "ECUADOR" },
            { n: "SALINETAS",	l: "SPAIN" },
            { n: "SALINOPOLIS",	l: "BRAZIL" },
            { n: "SALLUIT, QC",	l: "CANADA" },
            { n: "SALONA",	l: "CROATIA" },
            { n: "SALONICA",	l: "GREECE" },
            { n: "SALT ISLAND",	l: "UNITED KINGDOM" },
            { n: "SALT RIVER",	l: "JAMAICA" },
            { n: "SALTBURN",	l: "UNITED KINGDOM" },
            { n: "SALTEND",	l: "UNITED KINGDOM" },
            { n: "SALTPOND",	l: "GHANA" },
            { n: "SALUIT",	l: "CANADA" },
            { n: "SALVADOR",	l: "BRAZIL" },
            { n: "SAMANA",	l: "DOMINICAN REPUBLIC" },
            { n: "SAMANCO",	l: "PERU" },
            { n: "SAMANI",	l: "JAPAN" },
            { n: "SAMANI",	l: "JAPAN" },
            { n: "SAMARA",	l: "RUSSIA" },
            { n: "SAMARAI",	l: "PAPUA NEW GUINEA" },
            { n: "SAMARINDA",	l: "INDONESIA" },
            { n: "SAMBAS",	l: "INDONESIA" },
            { n: "SAMCHEOG HAN",	l: "KOREA, REPUBLIC" },
            { n: "SAMCHEON PO",	l: "KOREA, REPUBLIC" },
            { n: "SAMCHOK",	l: "KOREA, REPUBLIC" },
            { n: "SAMCHONPO",	l: "KOREA, REPUBLIC" },
            { n: "SAMIL",	l: "KOREA, REPUBLIC" },
            { n: "SAMOA (COUNTRY)",	l: "WESTERN SAMOA" },
            { n: "SAMOS",	l: "GREECE" },
            { n: "SAMPIT",	l: "INDONESIA" },
            { n: "SAMSO ISLAND",	l: "DENMARK" },
            { n: "SAMSUN",	l: "TURKEY" },
            { n: "SAN ANDRES ISLAND",	l: "COLOMBIA" },
            { n: "SAN ANTONIO",	l: "CHILE" },
            { n: "SAN ANTONIO ESTE",	l: "ARGENTINA" },
            { n: "SAN BENEDETTO DEL TRONTO",	l: "ITALY" },
            { n: "SAN BERNARD RIVER END",	l: "UNITED STATES" },
            { n: "SAN BERNARDINO STRAIT",	l: "PHILIPPINES" },
            { n: "SAN BLAS ISLANDS",	l: "PANAMA" },
            { n: "SAN CARLOS (MEXICO)",	l: "MEXICO" },
            { n: "SAN CARLOS (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "SAN CARLOS DE LA RAPITA",	l: "SPAIN" },
            { n: "SAN CIPRIAN",	l: "SPAIN" },
            { n: "SAN DIEGO",	l: "UNITED STATES" },
            { n: "SAN ESTEBAN DE PRAVIA",	l: "SPAIN" },
            { n: "SAN FELIU DE GUIXOLS",	l: "SPAIN" },
            { n: "SAN FELIX",	l: "VENEZUELA" },
            { n: "SAN FERNANDO (ARGENTINA)",	l: "ARGENTINA" },
            { n: "SAN FERNANDO (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "SAN FRANCISCO",	l: "UNITED STATES" },
            { n: "SAN GREGORIO",	l: "CHILE" },
            { n: "SAN ISIDRO",	l: "PHILIPPINES" },
            { n: "SAN JACINTO RIVER END",	l: "UNITED STATES" },
            { n: "SAN JOSE (GUATEMALA)",	l: "GUATEMALA" },
            { n: "SAN JOSE (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "SAN JUAN",	l: "PUERTO RICO" },
            { n: "SAN JUAN (PERU)",	l: "PERU" },
            { n: "SAN JUAN DE LA COSTA",	l: "MEXICO" },
            { n: "SAN JUAN DEL SUR",	l: "NICARAGUA" },
            { n: "SAN JULIAN",	l: "ARGENTINA" },
            { n: "SAN LORENZO (ECUADOR)",	l: "ECUADOR" },
            { n: "SAN LORENZO (HONDURAS)",	l: "HONDURAS" },
            { n: "SAN LORENZO (VENEZUELA)",	l: "VENEZUELA" },
            { n: "SAN LORENZO-SAN MARTIN",	l: "ARGENTINA" },
            { n: "SAN MARCOS",	l: "MEXICO" },
            { n: "SAN MARTIN",	l: "ARGENTINA" },
            { n: "SAN NICOLAS (ARGENTINA)",	l: "ARGENTINA" },
            { n: "SAN NICOLAS (ARUBA)",	l: "ARUBA" },
            { n: "SAN NICOLAS (GREECE)",	l: "GREECE" },
            { n: "SAN NICOLAS (PERU)",	l: "PERU" },
            { n: "SAN PEDRITO PORT",	l: "MEXICO" },
            { n: "SAN PEDRO (ARGENTINA)",	l: "ARGENTINA" },
            { n: "SAN PEDRO (IVORY COAST)",	l: "COTE D'IVOIRE" },
            { n: "SAN PEDRO DE MACORIS",	l: "DOMINICAN REPUBLIC" },
            { n: "SAN PEDRO HARBOR",	l: "UNITED STATES" },
            { n: "SAN REMO",	l: "ITALY" },
            { n: "SAN SALVADOR ISLAND",	l: "THE BAHAMAS" },
            { n: "SAN SEBASTIAN (CANARY IS)",	l: "SPAIN" },
            { n: "SAN SEBASTIAN (SPAIN)",	l: "SPAIN" },
            { n: "SAN SEBASTIAN BAY",	l: "ARGENTINA" },
            { n: "SAN VICENTE",	l: "CHILE" },
            { n: "SAN VITALE",	l: "ITALY" },
            { n: "SANBAIMEN",	l: "CHINA" },
            { n: "SANBING",	l: "CHINA" },
            { n: "SANBU",	l: "CHINA" },
            { n: "SANCANG",	l: "CHINA" },
            { n: "SANCHEZ",	l: "DOMINICAN REPUBLIC" },
            { n: "SAND POINT",	l: "UNITED STATES" },
            { n: "SANDAKAN",	l: "MALAYSIA" },
            { n: "SANDARNE",	l: "SWEDEN" },
            { n: "SANDEFJORD",	l: "NORWAY" },
            { n: "SANDHEADS",	l: "INDIA" },
            { n: "SANDNES",	l: "NORWAY" },
            { n: "SANDNESSJOEN",	l: "NORWAY" },
            { n: "SANDUSKY",	l: "UNITED STATES" },
            { n: "SANDVIKEN",	l: "SWEDEN" },
            { n: "SANDWICH",	l: "UNITED STATES" },
            { n: "SANDY BEACH",	l: "CANADA" },
            { n: "SANEGAON",	l: "INDIA" },
            { n: "SANGATTA",	l: "INDONESIA" },
            { n: "SANGHI",	l: "INDIA" },
            { n: "SANGHI",	l: "INDIA" },
            { n: "SANGI",	l: "PHILIPPINES" },
            { n: "SANGKULIRANG",    l: "INDONESIA"},
            { n: "SANIKILUAQ, NU",	l: "CANADA" },
            { n: "SANKESHU",	l: "CHINA" },
            { n: "SANS SOUCI",	l: "DOMINICAN REPUBLIC" },
            { n: "SANT' ANTIOCO",	l: "ITALY" },
            { n: "SANTA ANA",	l: "PHILIPPINES" },
            { n: "SANTA ANNA BAY",	l: "CURACAO" },
            { n: "SANTA APOLONIA",	l: "PORTUGAL" },
            { n: "SANTA BARBARA (CHILE)",	l: "CHILE" },
            { n: "SANTA BARBARA (USA)",	l: "UNITED STATES" },
            { n: "SANTA CATALINA GULF",	l: "UNITED STATES" },
            { n: "SANTA CLARA (BRAZIL)",	l: "BRAZIL" },
            { n: "SANTA CLARA (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "SANTA CRUZ (ARGENTINA)",	l: "ARGENTINA" },
            { n: "SANTA CRUZ (PHILIPPINES - 1)",	l: "PHILIPPINES" },
            { n: "SANTA CRUZ (PHILIPPINES - 2)",	l: "PHILIPPINES" },
            { n: "SANTA CRUZ DE LA PALMA",	l: "SPAIN" },
            { n: "SANTA CRUZ DE TENERIFE",	l: "SPAIN" },
            { n: "SANTA CRUZ DEL SUR",	l: "CUBA" },
            { n: "SANTA FE",	l: "ARGENTINA" },
            { n: "SANTA LUCIA",	l: "CUBA" },
            { n: "SANTA MARIA (CUBA)",	l: "CUBA" },
            { n: "SANTA MARIA (MEXICO)",	l: "MEXICO" },
            { n: "SANTA MARIA (PHILLIPINES)",	l: "PHILIPPINES" },
            { n: "SANTA MARIA BAY",	l: "CAPE VERDE" },
            { n: "SANTA MARTA",	l: "COLOMBIA" },
            { n: "SANTA PANAGIA BAY",	l: "ITALY" },
            { n: "SANTA ROSALIA",	l: "MEXICO" },
            { n: "SANTAN TERMINAL",	l: "INDONESIA" },
            { n: "SANTANA",	l: "BRAZIL" },
            { n: "SANTANDER",	l: "SPAIN" },
            { n: "SANTAREM",	l: "BRAZIL" },
            { n: "SANTIAGO DE CUBA",	l: "CUBA" },
            { n: "SANTO",	l: "VANUATU" },
            { n: "SANTO ANTONIO",	l: "SAO TOME AND PRINCIPE" },
            { n: "SANTO DOMINGO",	l: "DOMINICAN REPUBLIC" },
            { n: "SANTO NINO",	l: "PHILIPPINES" },
            { n: "SANTO TOMAS DE CASTILLA",	l: "GUATEMALA" },
            { n: "SANTOS",	l: "BRAZIL" },
            { n: "SANTURCE",	l: "SPAIN" },
            { n: "SANYA",	l: "CHINA" },
            { n: "SAO CRISTOVAO",	l: "BRAZIL" },
            { n: "SAO FRANCISCO DO SUL",	l: "BRAZIL" },
            { n: "SAO FRANCISCO DO SUL TERMINAL",	l: "BRAZIL" },
            { n: "SAO LUIS",	l: "BRAZIL" },
            { n: "SAO LUIZ DE MARANHAO",	l: "BRAZIL" },
            { n: "SAO SEBASTIAO",	l: "BRAZIL" },
            { n: "SAO TOME",	l: "SAO TOME AND PRINCIPE" },
            { n: "SAO TOME (COUNTRY)",	l: "SAO TOME AND PRINCIPE" },
            { n: "SAPELE",	l: "NIGERIA" },
            { n: "SAPIENTZA",	l: "GREECE" },
            { n: "SARANDE",	l: "ALBANIA" },
            { n: "SARATOV",	l: "RUSSIA" },
            { n: "SARIAYA",	l: "PHILIPPINES" },
            { n: "SARIKEI",	l: "MALAYSIA" },
            { n: "SARISEKI",	l: "TURKEY" },
            { n: "SARNIA",	l: "CANADA" },
            { n: "SARPSBORG",	l: "NORWAY" },
            { n: "SARROCH",	l: "ITALY" },
            { n: "SAS VAN GENT",	l: "NETHERLANDS" },
            { n: "SASA",	l: "PHILIPPINES" },
            { n: "SASEBO",	l: "JAPAN" },
            { n: "SASSNITZ",	l: "GERMANY" },
            { n: "SATHUPRADIT",	l: "THAILAND" },
            { n: "SATTAHIP",	l: "THAILAND" },
            { n: "SATUI",	l: "INDONESIA" },
            { n: "SAUDA",	l: "NORWAY" },
            { n: "SAUDARKROKUR",	l: "ICELAND" },
            { n: "SAUDI ARABIA (COUNTRY)",	l: "SAUDI ARABIA" },
            { n: "SAUGERTIES",	l: "UNITED STATES" },
            { n: "SAUGOR ROADS",	l: "INDIA" },
            { n: "SAULT STE MARIE-LOWER HARBOUR",	l: "CANADA" },
            { n: "SAULT STE MARIE-UPPER CDN CHANNEL ENTRANCE",	l: "CANADA" },
            { n: "SAULT STE. MARIE (MICHIGAN)",	l: "UNITED STATES" },
            { n: "SAULT STE.MARIE",	l: "CANADA" },
            { n: "SAVAII ISLAND",	l: "WESTERN SAMOA" },
            { n: "SAVANILLA",	l: "COLOMBIA" },
            { n: "SAVANNA LA MAR",	l: "JAMAICA" },
            { n: "SAVANNAH",	l: "UNITED STATES" },
            { n: "SAVANNAH GA",	l: "UNITED STATES" },
            { n: "SAVONA",	l: "ITALY" },
            { n: "SAVONA-VADO",	l: "ITALY" },
            { n: "SAVONLINNA",	l: "FINLAND" },
            { n: "SAVU SAVU",	l: "FIJI" },
            { n: "SAXI FPSO",	l: "ANGOLA" },
            { n: "SAXMAN",	l: "UNITED STATES" },
            { n: "SCALLOWAY",	l: "UNITED KINGDOM" },
            { n: "SCAPA FLOW",	l: "UNITED KINGDOM" },
            { n: "SCARBOROUGH (T & T)",	l: "TRINIDAD AND TOBAGO" },
            { n: "SCARBOROUGH (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "SCATARIE ISLAND",	l: "CANADA" },
            { n: "SCATERIE ISLAND",	l: "CANADA" },
            { n: "SCHEVENINGEN",	l: "NETHERLANDS" },
            { n: "SCHIEDAM",	l: "NETHERLANDS" },
            { n: "SCHLESWIG",	l: "GERMANY" },
            { n: "SCHOTTEGAT",	l: "CURACAO" },
            { n: "SCHULAU",	l: "GERMANY" },
            { n: "SCHWARZENHUTTEN",	l: "GERMANY" },
            { n: "SCRABSTER",	l: "UNITED KINGDOM" },
            { n: "SE ASIA",	l: "SINGAPORE" },
            { n: "SEA OF AZOV",	l: "UKRAINE" },
            { n: "SEA OF MARMARA",	l: "TURKEY" },
            { n: "SEAFORTH",	l: "UNITED KINGDOM" },
            { n: "SEAHAM",	l: "UNITED KINGDOM" },
            { n: "SEAL SANDS",	l: "UNITED KINGDOM" },
            { n: "SEARSPORT",	l: "UNITED STATES" },
            { n: "SEATTLE",	l: "UNITED STATES" },
            { n: "SEBUKU",	l: "INDONESIA" },
            { n: "SEBUKU ANCHORAGE",	l: "INDONESIA" },
            { n: "SECA - NORTH SEA",	l: "UNITED KINGDOM" },
            { n: "SECHELT",	l: "CANADA" },
            { n: "SEDEF",	l: "TURKEY" },
            { n: "SEEADLER HARBOUR",	l: "PAPUA NEW GUINEA" },
            { n: "SEINE BAY",	l: "FRANCE" },
            { n: "SEJINGKAT",	l: "MALAYSIA" },
            { n: "SEKISAKI PS",	l: "JAPAN" },
            { n: "SEKONDI",	l: "GHANA" },
            { n: "SEKUPANG",	l: "INDONESIA" },
            { n: "SELAATA",	l: "LEBANON" },
            { n: "SELAT JAILOLO",	l: "INDONESIA" },
            { n: "SELAT OMBAI",	l: "TIMOR-LESTE" },
            { n: "SELAT PANJANG",	l: "INDONESIA" },
            { n: "SELBY (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "SELBY (USA)",	l: "UNITED STATES" },
            { n: "SELDOVIA",	l: "UNITED STATES" },
            { n: "SELE STRAIT",	l: "INDONESIA" },
            { n: "SELETAR",	l: "SINGAPORE" },
            { n: "SELF'S POINT",	l: "AUSTRALIA" },
            { n: "SELKAMERI ENTRANCE",	l: "FINLAND" },
            { n: "SELMA",	l: "UNITED STATES" },
            { n: "SEMANGKA BAY",	l: "INDONESIA" },
            { n: "SEMARANG",	l: "INDONESIA" },
            { n: "SEMBAWANG",	l: "SINGAPORE" },
            { n: "SEME TERMINAL",	l: "BENIN" },
            { n: "SEMIRARA",	l: "PHILIPPINES" },
            { n: "SEMPORNA",	l: "MALAYSIA" },
            { n: "SENARI",	l: "MALAYSIA" },
            { n: "SENBOKU",	l: "JAPAN" },
            { n: "SENBONG",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "SENDAI",	l: "JAPAN" },
            { n: "SENEGAL (COUNTRY)",	l: "SENEGAL" },
            { n: "SENIPAH TERMINAL",	l: "INDONESIA" },
            { n: "SENJ",	l: "CROATIA" },
            { n: "SENZAKI",	l: "JAPAN" },
            { n: "SEOSAN",	l: "KOREA, REPUBLIC" },
            { n: "SEOUL",	l: "KOREA, REPUBLIC" },
            { n: "SEPANGAR BAY OIL TERMINAL",	l: "MALAYSIA" },
            { n: "SEPANJANG TERMINAL",	l: "INDONESIA" },
            { n: "SEPAT MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "SEPAT TERMINAL",	l: "MALAYSIA" },
            { n: "SEPEN",	l: "CROATIA" },
            { n: "SEPETIBA",	l: "BRAZIL" },
            { n: "SEPETIBA BAY",	l: "BRAZIL" },
            { n: "SEPETIBA TERMINAL",	l: "BRAZIL" },
            { n: "SEPT-ILES",	l: "CANADA" },
            { n: "SERBIA & MONTENEGRO (COUNTRY)",	l: "MONTENEGRO" },
            { n: "SERIA",	l: "BRUNEI" },
            { n: "SERIPHOS ISLAND",	l: "GREECE" },
            { n: "SERPENTINA TERMINAL",	l: "EQUATORIAL GUINEA" },
            { n: "SERUI",	l: "INDONESIA" },
            { n: "SES ATHERINOLAKKOS",	l: "GREECE" },
            { n: "SETE",	l: "FRANCE" },
            { n: "SETO INLAND SEA",	l: "JAPAN" },
            { n: "SETODA",	l: "JAPAN" },
            { n: "SETUBAL",	l: "PORTUGAL" },
            { n: "SEVASTOPOL",	l: "UKRAINE" },
            { n: "SEVEN ISLANDS",	l: "CANADA" },
            { n: "SEVEN ISLANDS BAY",	l: "CANADA" },
            { n: "SEVERODVINSK",	l: "RUSSIA" },
            { n: "SEVILLE",	l: "SPAIN" },
            { n: "SEVIVARA POINT",	l: "INDONESIA" },
            { n: "SEWALLS POINT",	l: "UNITED STATES" },
            { n: "SEWARD",	l: "UNITED STATES" },
            { n: "SEWAREN",	l: "UNITED STATES" },
            { n: "SEYBA PLAYA",	l: "MEXICO" },
            { n: "SEYCHELLES (COUNTRY)",	l: "SEYCHELLES" },
            { n: "SEYDISFJORDUR",	l: "ICELAND" },
            { n: "SFAX",	l: "TUNISIA" },
            { n: "SHA LUNG OIL TERMINAL",	l: "TAIWAN" },
            { n: "SHAHEZI",	l: "CHINA" },
            { n: "SHAHID BAHONAR",	l: "IRAN" },
            { n: "SHAHID BEHESHTI",	l: "IRAN" },
            { n: "SHAHID RAJAI",	l: "IRAN" },
            { n: "SHAJIAO B POWER PLANT TERMINAL",	l: "CHINA" },
            { n: "SHAKHTERSK",	l: "RUSSIA" },
            { n: "SHANDONG",	l: "CHINA" },
            { n: "SHANGHAI",	l: "CHINA" },
            { n: "SHANGYAO",	l: "CHINA" },
            { n: "SHANHAIGUAN",	l: "CHINA" },
            { n: "SHANNON ESTUARY PORTS",	l: "IRELAND" },
            { n: "SHANTOU",	l: "CHINA" },
            { n: "SHANWEI",	l: "CHINA" },
            { n: "SHANWEI POWER PLANT",	l: "CHINA" },
            { n: "SHANZHEN",	l: "CHINA" },
            { n: "SHAOBAGOU",	l: "CHINA" },
            { n: "SHARJAH",	l: "UNITED ARAB EMIRATES" },
            { n: "SHARJAH OFFSHORE TERMINAL",	l: "UNITED ARAB EMIRATES" },
            { n: "SHARK BAY",	l: "AUSTRALIA" },
            { n: "SHARM EL SHEIKH",	l: "EGYPT" },
            { n: "SHARPNESS AND GLOUCESTER",	l: "UNITED KINGDOM" },
            { n: "SHATIAN",	l: "CHINA" },
            { n: "SHAZUI",	l: "CHINA" },
            { n: "SHEBOYGAN",	l: "UNITED STATES" },
            { n: "SHEDIAC",	l: "CANADA" },
            { n: "SHEERNESS",	l: "UNITED KINGDOM" },
            { n: "SHEET HARBOUR",	l: "CANADA" },
            { n: "SHEKOU",	l: "CHINA" },
            { n: "SHELBURNE",	l: "CANADA" },
            { n: "SHELL HAVEN",	l: "UNITED KINGDOM" },
            { n: "SHEN-AO",	l: "TAIWAN" },
            { n: "SHENGJIN",	l: "ALBANIA" },
            { n: "SHENZHEN",	l: "CHINA" },
            { n: "SHERBRO ISLAND",	l: "SIERRA LEONE" },
            { n: "SHESKHARIS",	l: "RUSSIA" },
            { n: "SHIBUSHI",	l: "JAPAN" },
            { n: "SHIDAO",	l: "CHINA" },
            { n: "SHIDONGKOU",	l: "CHINA" },
            { n: "SHIHU",	l: "CHINA" },
            { n: "SHIJIU",	l: "CHINA" },
            { n: "SHIJIUSUO",	l: "CHINA" },
            { n: "SHIJUETOU",	l: "CHINA" },
            { n: "SHIKAMA",	l: "JAPAN" },
            { n: "SHILONG",	l: "CHINA" },
            { n: "SHIMANZI OIL TERMINAL",	l: "KENYA" },
            { n: "SHIMIZU",	l: "JAPAN" },
            { n: "SHIMONOSEKI",	l: "JAPAN" },
            { n: "SHIMOTSU",	l: "JAPAN" },
            { n: "SHINGU",	l: "JAPAN" },
            { n: "SHIN-SENDAI TERMINAL",	l: "JAPAN" },
            { n: "SHIOGAMA",	l: "JAPAN" },
            { n: "SHIP HARBOUR",	l: "CANADA" },
            { n: "SHIPPEGAN",	l: "CANADA" },
            { n: "SHIPU",	l: "CHINA" },
            { n: "SHIQIAO",	l: "CHINA" },
            { n: "SHIRASHIMA",	l: "JAPAN" },
            { n: "SHIROGANE",	l: "JAPAN" },
            { n: "SHIYAN",	l: "CHINA" },
            { n: "SHOAIBA",	l: "SAUDI ARABIA" },
            { n: "SHOREHAM",	l: "UNITED KINGDOM" },
            { n: "SHORTLAND HARBOUR",	l: "SOLOMON ISLANDS" },
            { n: "SHOTTON",	l: "UNITED KINGDOM" },
            { n: "SHUAIBA",	l: "KUWAIT" },
            { n: "SHUANGLOU",	l: "CHINA" },
            { n: "SHUANGQIAO",	l: "CHINA" },
            { n: "SHUI DONG",	l: "CHINA" },
            { n: "SHUNAN",	l: "JAPAN" },
            { n: "SHUQAYQ TERMINAL",	l: "SAUDI ARABIA" },
            { n: "SHUWAIKH",	l: "KUWAIT" },
            { n: "SIAIN",	l: "PHILIPPINES" },
            { n: "SIAM SEAPORT",	l: "THAILAND" },
            { n: "SIAN",	l: "CHINA" },
            { n: "SIBENIK",	l: "CROATIA" },
            { n: "SIBOLGA",	l: "INDONESIA" },
            { n: "SIBU",	l: "MALAYSIA" },
            { n: "SICILY",	l: "ITALY" },
            { n: "SIDI KERIR",	l: "EGYPT" },
            { n: "SIDON",	l: "LEBANON" },
            { n: "SIERGOU",	l: "CHINA" },
            { n: "SIERRA LEONE (COUNTRY)",	l: "SIERRA LEONE" },
            { n: "SIGERFJORD",	l: "NORWAY" },
            { n: "SIGLUFJORD",	l: "ICELAND" },
            { n: "SIHANOOKVILLE",	l: "CAMBODIA" },
            { n: "SIILINJARVI",	l: "FINLAND" },
            { n: "SIJING",	l: "CHINA" },
            { n: "SIKEA",	l: "SWEDEN" },
            { n: "SIKKA",	l: "INDIA" },
            { n: "SILIVRI",	l: "TURKEY" },
            { n: "SILLAMAE",	l: "ESTONIA" },
            { n: "SILLOTH",	l: "UNITED KINGDOM" },
            { n: "SILVER BAY",	l: "UNITED STATES" },
            { n: "SILVERTOWN",	l: "UNITED KINGDOM" },
            { n: "SIMONSTOWN",	l: "SOUTH AFRICA" },
            { n: "SIMRISHAMN",	l: "SWEDEN" },
            { n: "SINES",	l: "PORTUGAL" },
            { n: "SINGAPORE",	l: "SINGAPORE" },
            { n: "SINGAPORE (COUNTRY)",	l: "SINGAPORE" },
            { n: "SINGAPORE 15000",	l: "SINGAPORE" },
            { n: "SINGAPORE 16000",	l: "SINGAPORE" },
            { n: "SINGAPORE 17000",	l: "SINGAPORE" },
            { n: "SINGAPORE 18000",	l: "SINGAPORE" },
            { n: "SINGAPORE 19000",	l: "SINGAPORE" },
            { n: "SINGAPORE 20000",	l: "SINGAPORE" },
            { n: "SINGAPORE EASTERN OPL",	l: "SINGAPORE" },
            { n: "SINGAPORE STRAIT",	l: "SINGAPORE" },
            { n: "SINGAPORE WESTERN OPL",	l: "SINGAPORE" },
            { n: "SINGAPORE-H.K.",	l: "SINGAPORE" },
            { n: "SINGAPORE-JAPAN",	l: "SINGAPORE" },
            { n: "SINGKAWANG",	l: "INDONESIA" },
            { n: "SINOP",	l: "TURKEY" },
            { n: "SINT MICHIELSBAY",	l: "CURACAO" },
            { n: "SINTETE",	l: "INDONESIA" },
            { n: "SINUB",	l: "TURKEY" },
            { n: "SIPITANG",	l: "MALAYSIA" },
            { n: "SIRACUSA",	l: "ITALY" },
            { n: "SIRRI ISLAND",	l: "IRAN" },
            { n: "SIRTE",	l: "LIBYA" },
            { n: "SIRTICA TERMINAL",	l: "LIBYA" },
            { n: "SISIMIUT",	l: "GREENLAND" },
            { n: "SITKA",	l: "UNITED STATES" },
            { n: "SITRA",	l: "BAHRAIN" },
            { n: "SITTINGBOURNE",	l: "UNITED KINGDOM" },
            { n: "SITTWE",	l: "MYANMAR" },
            { n: "SJEVERNA",	l: "CROATIA" },
            { n: "SJURSOYA",	l: "NORWAY" },
            { n: "SKAALA",	l: "FAROE ISLANDS" },
            { n: "SKADOVSK",	l: "UKRAINE" },
            { n: "SKAELSKOR",	l: "DENMARK" },
            { n: "SKAERBAEK",	l: "DENMARK" },
            { n: "SKAGASTROND",	l: "ICELAND" },
            { n: "SKAGEN",	l: "DENMARK" },
            { n: "SKAGWAY",	l: "UNITED STATES" },
            { n: "SKALEVIK",	l: "NORWAY" },
            { n: "SKARAMANGA",	l: "GREECE" },
            { n: "SKAW",	l: "DENMARK" },
            { n: "SKAW",	l: "DENMARK" },
            { n: "SKAW OFFSHORE",	l: "DENMARK" },
            { n: "SKAW-BLACK SEA",	l: "GIBRALTAR" },
            { n: "SKAW-GIB",	l: "GIBRALTAR" },
            { n: "SKAW-MED",	l: "GIBRALTAR" },
            { n: "SKAW-PASSERO",	l: "GIBRALTAR" },
            { n: "SKELLEFTEA",	l: "SWEDEN" },
            { n: "SKELLEFTEHAMN",	l: "SWEDEN" },
            { n: "SKEPPSBRON",	l: "SWEDEN" },
            { n: "SKERJAFJORDUR",	l: "ICELAND" },
            { n: "SKIDEGATE",	l: "CANADA" },
            { n: "SKIEN",	l: "NORWAY" },
            { n: "SKIKDA",	l: "ALGERIA" },
            { n: "SKIPAVIK",	l: "NORWAY" },
            { n: "SKIVE",	l: "DENMARK" },
            { n: "SKJOLDEN",	l: "NORWAY" },
            { n: "SKOGBY",	l: "FINLAND" },
            { n: "SKOGHALL",	l: "SWEDEN" },
            { n: "SKOGN",	l: "NORWAY" },
            { n: "SKOLDVIK",	l: "FINLAND" },
            { n: "SKREDSVIK",	l: "SWEDEN" },
            { n: "SKUA VENTURE TERMINAL",	l: "AUSTRALIA" },
            { n: "SKUDENESHAVN",	l: "NORWAY" },
            { n: "SKULTE",	l: "LATVIA" },
            { n: "SKURU",	l: "FINLAND" },
            { n: "SKUTSKAR",	l: "SWEDEN" },
            { n: "SLAGEN",	l: "NORWAY" },
            { n: "SLAVYANKA",	l: "RUSSIA" },
            { n: "SLEEPING BEAR POINT",	l: "UNITED STATES" },
            { n: "SLEMMESTAD",	l: "NORWAY" },
            { n: "SLIGO",	l: "IRELAND" },
            { n: "SLITE",	l: "SWEDEN" },
            { n: "SLOEHAVEN",	l: "NETHERLANDS" },
            { n: "SLOVAAG",	l: "NORWAY" },
            { n: "SLOVAG",	l: "NORWAY" },
            { n: "SLOVENIA (COUNTRY)",	l: "SLOVENIA" },
            { n: "SLUISKIL",	l: "NETHERLANDS" },
            { n: "SMALKALDEN",	l: "SURINAME" },
            { n: "SMITH PT ANCHORAGE",	l: "UNITED STATES" },
            { n: "SMITH'S BLUFF",	l: "UNITED STATES" },
            { n: "SMYRNA",	l: "TURKEY" },
            { n: "SNAIL'S BAY",	l: "AUSTRALIA" },
            { n: "SNELL LOCK",	l: "UNITED STATES" },
            { n: "SNOHVIT",	l: "NORWAY" },
            { n: "SNUG COVE",	l: "AUSTRALIA" },
            { n: "SOCHI",	l: "RUSSIA" },
            { n: "SOCOTRA",	l: "YEMEN" },
            { n: "SOCOTRA (PASSAGE)",	l: "YEMEN" },
            { n: "SOCOTRA BYPASS",	l: "SOMALIA" },
            { n: "SODEGAURA",	l: "JAPAN" },
            { n: "SODERHAMN",	l: "SWEDEN" },
            { n: "SODERKOPING",	l: "SWEDEN" },
            { n: "SODERTALJE & SODERTALJE CANAL",	l: "SWEDEN" },
            { n: "SODESHI",	l: "JAPAN" },
            { n: "SOENDRE STROEMFJORD",	l: "GREENLAND" },
            { n: "SOERVAAG",	l: "FAROE ISLANDS" },
            { n: "SOGNDAL",	l: "NORWAY" },
            { n: "SOGOD",	l: "PHILIPPINES" },
            { n: "SOHAR",	l: "OMAN" },
            { n: "SOKCHO",	l: "KOREA, REPUBLIC" },
            { n: "SOKHNA",	l: "EGYPT" },
            { n: "SOLA",	l: "NORWAY" },
            { n: "SOLENZARA",	l: "FRANCE" },
            { n: "SOLIN",	l: "CROATIA" },
            { n: "SOLLER",	l: "SPAIN" },
            { n: "SOLMUNDEFJORD",	l: "FAROE ISLANDS" },
            { n: "SOLOMON ISLANDS (COUNTRY)",	l: "SOLOMON ISLANDS" },
            { n: "SOLVESBORG",	l: "SWEDEN" },
            { n: "SOMA",	l: "JAPAN" },
            { n: "SOMALIA (COUNTRY)",	l: "SOMALIA" },
            { n: "SOMBRA",	l: "CANADA" },
            { n: "SOMBRERO ISLAND",	l: "ANGUILLA" },
            { n: "SON DUONG",	l: "VIETNAM" },
            { n: "SONDERBORG",	l: "DENMARK" },
            { n: "SONG DOC FPSO",	l: "VIETNAM" },
            { n: "SONGJIANG",	l: "CHINA" },
            { n: "SONGJIN",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "SONGKHLA",	l: "THAILAND" },
            { n: "SONGO SONGO ISLAND",	l: "TANZANIA" },
            { n: "SONGQUHONGDAO",	l: "CHINA" },
            { n: "SONGXIA",	l: "CHINA" },
            { n: "SONGXIA (ZHEJIANG)",	l: "CHINA" },
            { n: "SORAKER",	l: "SWEDEN" },
            { n: "SOREL",	l: "CANADA" },
            { n: "SORONG",	l: "INDONESIA" },
            { n: "SOROOSH TERMINAL",	l: "IRAN" },
            { n: "SORRENTO",	l: "ITALY" },
            { n: "SORTLAND",	l: "NORWAY" },
            { n: "SOUDA BAY",	l: "GREECE" },
            { n: "SOUND",	l: "SWEDEN" },
            { n: "SOUND OF SLEAT",	l: "UNITED KINGDOM" },
            { n: "SOUR",	l: "LEBANON" },
            { n: "SOURIS",	l: "CANADA" },
            { n: "SOUSAKI",	l: "GREECE" },
            { n: "SOUSSE",	l: "TUNISIA" },
            { n: "SOUTH AFRICA (COUNTRY)",	l: "SOUTH AFRICA" },
            { n: "SOUTH BREWER",	l: "UNITED STATES" },
            { n: "SOUTH CHINA SEA",	l: "CHINA" },
            { n: "SOUTH LOCUST POINT",	l: "UNITED STATES" },
            { n: "SOUTH LOUISIANA PORT",	l: "UNITED STATES" },
            { n: "SOUTH PASS",	l: "UNITED STATES" },
            { n: "SOUTH PASS",	l: "UNITED STATES" },
            { n: "SOUTH PULAU LAUT",	l: "INDONESIA" },
            { n: "SOUTH QUEENSFERRY",	l: "UNITED KINGDOM" },
            { n: "SOUTH RIDING POINT",	l: "THE BAHAMAS" },
            { n: "SOUTH SABINE POINT",	l: "UNITED STATES" },
            { n: "SOUTH SHIELDS",	l: "UNITED KINGDOM" },
            { n: "SOUTH TEX LZ",	l: "UNITED STATES" },
            { n: "SOUTH WEST CAPE",	l: "NEW ZEALAND" },
            { n: "SOUTH WEST PASS",	l: "UNITED STATES" },
            { n: "SOUTH WEST POINT",	l: "UNITED STATES" },
            { n: "SOUTH WEST ROCKS",	l: "AUSTRALIA" },
            { n: "SOUTHAMPTON",	l: "UNITED KINGDOM" },
            { n: "SOUTHERN SECA",	l: "UNITED KINGDOM" },
            { n: "SOUTHPORT NC",	l: "UNITED STATES" },
            { n: "SOUTHTEX",	l: "UNITED STATES" },
            { n: "SOUTHWOLD",	l: "UNITED KINGDOM" },
            { n: "SOUTHWOLD SUEZMAX+",	l: "UNITED KINGDOM" },
            { n: "SOVGAVAN",	l: "RUSSIA" },
            { n: "SOYO",	l: "ANGOLA" },
            { n: "SOZOPOL",	l: "BULGARIA" },
            { n: "SPAIN (COUNTRY)",	l: "SPAIN" },
            { n: "SPAIN ATLANTIC",	l: "SPAIN" },
            { n: "SPAIN MED",	l: "SPAIN" },
            { n: "SPALATO",	l: "CROATIA" },
            { n: "SPALDING COVE",	l: "AUSTRALIA" },
            { n: "SPARROW'S POINT",	l: "UNITED STATES" },
            { n: "SPI SABINE LIGHT",	l: "UNITED STATES" },
            { n: "SPJELKAVIK",	l: "NORWAY" },
            { n: "SPLIT",	l: "CROATIA" },
            { n: "SPRAGGE",	l: "CANADA" },
            { n: "SPRING BAY",	l: "AUSTRALIA" },
            { n: "SPRING GARDEN TANKER TERMINAL",	l: "BARBADOS" },
            { n: "SPRINGDALE",	l: "CANADA" },
            { n: "SQUAMISH",	l: "CANADA" },
            { n: "SRECICA",	l: "CROATIA" },
            { n: "SRI LANKA (COUNTRY)",	l: "SRI LANKA" },
            { n: "SRIRACHA HARBOUR",	l: "THAILAND" },
            { n: "SRIRACHA OIL TERMINALS",	l: "THAILAND" },
            { n: "ST ANDREWS",	l: "CANADA" },
            { n: "ST ANNA",	l: "CURACAO" },
            { n: "ST ANNA BAY",	l: "CURACAO" },
            { n: "ST ANTHONY",	l: "CANADA" },
            { n: "ST BERNARD PORT",	l: "UNITED STATES" },
            { n: "ST CATHARINES",	l: "CANADA" },
            { n: "ST CHARLES, LA",	l: "UNITED STATES" },
            { n: "ST CHRISTOPHER",	l: "SAINT KITTS AND NEVIS" },
            { n: "ST CLAIR RIVER",	l: "UNITED STATES" },
            { n: "ST CROIX",	l: "VIRGIN ISLANDS" },
            { n: "ST CROIX END",	l: "UNITED STATES" },
            { n: "ST CROIX START",	l: "UNITED STATES" },
            { n: "ST DENIS",	l: "REUNION" },
            { n: "ST ELMO",	l: "UNITED STATES" },
            { n: "ST EUSTATIUS",	l: "SINT MAARTEN (DUTCH PART)" },
            { n: "ST FRANCISVILLE",	l: "UNITED STATES" },
            { n: "ST GEORGE",	l: "UNITED STATES" },
            { n: "ST GEORGE HARBOUR",	l: "GREECE" },
            { n: "ST GEORGE'S (BERMUDA)",	l: "BERMUDA" },
            { n: "ST GEORGE'S (GRENADA)",	l: "GRENADA" },
            { n: "ST HELENA ISLAND",	l: "SAINT HELENA" },
            { n: "ST HELENA ISLAND (COUNTRY)",	l: "SAINT HELENA" },
            { n: "ST HELENS",	l: "UNITED STATES" },
            { n: "ST HELIER",	l: "UNITED KINGDOM" },
            { n: "ST JAMES",	l: "UNITED STATES" },
            { n: "ST JOHANN",	l: "SWITZERLAND" },
            { n: "ST JOHN'S (ANTIGUA)",	l: "ANTIGUA AND BARBUDA" },
            { n: "ST JOHN'S (CANADA)",	l: "CANADA" },
            { n: "ST KITTS",	l: "SAINT KITTS AND NEVIS" },
            { n: "ST KITTS & NEVIS (COUNTRY)",	l: "SAINT KITTS AND NEVIS" },
            { n: "ST LAURENT DU MARONI",	l: "FRENCH GUIANA" },
            { n: "ST LAWRENCE",	l: "CANADA" },
            { n: "ST LAWRENCE SEAWAY",	l: "CANADA" },
            { n: "ST LUCIA",	l: "SAINT LUCIA" },
            { n: "ST LUCIA (COUNTRY)",	l: "SAINT LUCIA" },
            { n: "ST MAARTEN",	l: "SINT MAARTEN (DUTCH PART)" },
            { n: "ST MALO",	l: "FRANCE" },
            { n: "ST MARY'S (SCILLY ISLANDS)",	l: "UNITED KINGDOM" },
            { n: "ST MICHAEL",	l: "UNITED STATES" },
            { n: "ST MICHIEL'S BAY",	l: "CURACAO" },
            { n: "ST NAZAIRE",	l: "FRANCE" },
            { n: "ST NIKOLO",	l: "GREECE" },
            { n: "ST PAUL",	l: "UNITED STATES" },
            { n: "ST PAUL DE LUANDA",	l: "ANGOLA" },
            { n: "ST PETER PORT",	l: "CHANNEL ISLANDS" },
            { n: "ST PETERSBURG (RUSSIA)",	l: "RUSSIA" },
            { n: "ST PETERSBURG (USA)",	l: "UNITED STATES" },
            { n: "ST PIERRE",	l: "SAINT PIERRE AND MIQUELON" },
            { n: "ST PIERRE AND MIQUELON (COUNTRY)",	l: "SAINT PIERRE AND MIQUELON" },
            { n: "ST ROMUALD",	l: "CANADA" },
            { n: "ST ROSE",	l: "UNITED STATES" },
            { n: "ST THOMAS",	l: "VIRGIN ISLANDS" },
            { n: "ST TROPEZ",	l: "FRANCE" },
            { n: "ST VALERY-EN-CAUX",	l: "FRANCE" },
            { n: "ST VALERY-SUR-SOMME",	l: "FRANCE" },
            { n: "ST VINCENT",	l: "SAINT VINCENT AND THE GRENADINES" },
            { n: "ST VINCENT (COUNTRY)",	l: "SAINT VINCENT AND THE GRENADINES" },
            { n: "ST WANDRILLE",	l: "FRANCE" },
            { n: "ST. ALBANS",	l: "CANADA" },
            { n: "ST. CLAIR",	l: "UNITED STATES" },
            { n: "ST. JOHN",	l: "CANADA" },
            { n: "ST. JOSEPH",	l: "UNITED STATES" },
            { n: "ST. LAMBERT LOCK",	l: "CANADA" },
            { n: "ST. PAUL, MN",	l: "UNITED STATES" },
            { n: "ST. PIERRE",	l: "FRANCE" },
            { n: "STADE",	l: "GERMANY" },
            { n: "STADERSAND",	l: "GERMANY" },
            { n: "STADSGARDEN",	l: "SWEDEN" },
            { n: "STAG (NW AUST)",	l: "AUSTRALIA" },
            { n: "STAMFORD HARBOR",	l: "UNITED STATES" },
            { n: "STAMSUND",	l: "NORWAY" },
            { n: "STANLEY (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "STANLEY (FALKLAND ISLANDS)",	l: "FALKLAND ISLANDS (ISLAS MALVINAS)" },
            { n: "STANLOW",	l: "UNITED KINGDOM" },
            { n: "STAPELTON, NEW YORK",	l: "UNITED STATES" },
            { n: "STATFJORD",	l: "NORWAY" },
            { n: "STAVANGER",	l: "NORWAY" },
            { n: "STEENBANK ANCHORAGE",	l: "NETHERLANDS" },
            { n: "STEENKOOL",	l: "INDONESIA" },
            { n: "STEEP POINT",	l: "AUSTRALIA" },
            { n: "STEGE",	l: "DENMARK" },
            { n: "STEILENE",	l: "NORWAY" },
            { n: "STEINKJER",	l: "NORWAY" },
            { n: "STEINSHAMN",	l: "NORWAY" },
            { n: "STENUNGSUND",	l: "SWEDEN" },
            { n: "STEPHENVILLE",	l: "CANADA" },
            { n: "STEVNS PIER",	l: "DENMARK" },
            { n: "STEWART",	l: "CANADA" },
            { n: "STIGSNAESVAERKETS HAVN",	l: "DENMARK" },
            { n: "STILLERYD HARBOUR",	l: "SWEDEN" },
            { n: "STJORDAL",	l: "NORWAY" },
            { n: "ST-JOZEF CHANNEL",	l: "CANADA" },
            { n: "STOCKHOLM",	l: "SWEDEN" },
            { n: "STOCKTON",	l: "UNITED STATES" },
            { n: "STOCKVIK",	l: "SWEDEN" },
            { n: "STODHVARFJORDUR",	l: "ICELAND" },
            { n: "STOKMARKNES",	l: "NORWAY" },
            { n: "STONECUTTERS ISLAND",	l: "HONG KONG" },
            { n: "STONEPORT",	l: "UNITED STATES" },
            { n: "STONINGTON HARBOR",	l: "UNITED STATES" },
            { n: "STORA VIKA",	l: "SWEDEN" },
            { n: "STORD",	l: "NORWAY" },
            { n: "STORMSKAER",	l: "SWEDEN" },
            { n: "STORMSKAR OIL TERMINAL",	l: "SWEDEN" },
            { n: "STORNOWAY",	l: "UNITED KINGDOM" },
            { n: "STORSTREM",	l: "DENMARK" },
            { n: "STORUGNS",	l: "SWEDEN" },
            { n: "STRAHAN",	l: "AUSTRALIA" },
            { n: "STRAIT OF BELLE ISLE",	l: "CANADA" },
            { n: "STRAIT OF CANSO",	l: "CANADA" },
            { n: "STRAITS OF BAB-EL-MANDEB",	l: "YEMEN" },
            { n: "STRAITS OF FLORIDA",	l: "UNITED STATES" },
            { n: "STRAITS OF HORMUZ",	l: "OMAN" },
            { n: "STRALSUND",	l: "GERMANY" },
            { n: "STRANGFORD",	l: "UNITED KINGDOM" },
            { n: "STRANGNAS",	l: "SWEDEN" },
            { n: "STRANRAER",	l: "UNITED KINGDOM" },
            { n: "STRASBOURG",	l: "FRANCE" },
            { n: "STRATONI",	l: "GREECE" },
            { n: "STRAUMSVIK",	l: "ICELAND" },
            { n: "STRENDUR",	l: "FAROE ISLANDS" },
            { n: "STROEMSTAD",	l: "SWEDEN" },
            { n: "STROMNESS",	l: "UNITED KINGDOM" },
            { n: "STRUER",	l: "DENMARK" },
            { n: "STRYN",	l: "NORWAY" },
            { n: "STUBBEKOBING",	l: "DENMARK" },
            { n: "STUDSTRUP",	l: "DENMARK" },
            { n: "STUENG HAV",	l: "CAMBODIA" },
            { n: "STUGSUND",	l: "SWEDEN" },
            { n: "STURE",	l: "NORWAY" },
            { n: "STURGEON BAY",	l: "UNITED STATES" },
            { n: "STURGEON BAY CANAL EAST ENTRANCE",	l: "UNITED STATES" },
            { n: "STYBARROW VENTURE",	l: "AUSTRALIA" },
            { n: "STYKKISHOLMUR",	l: "ICELAND" },
            { n: "STYLIS",	l: "GREECE" },
            { n: "SU TU DEN",	l: "VIETNAM" },
            { n: "SUAKIN",	l: "SUDAN" },
            { n: "SUAL",	l: "PHILIPPINES" },
            { n: "SU-AO",	l: "TAIWAN" },
            { n: "SUAPE",	l: "BRAZIL" },
            { n: "SUAPE MARINE TERMINAL",	l: "BRAZIL" },
            { n: "SUBAIM",	l: "INDONESIA" },
            { n: "SUBA-NIPA",	l: "PHILIPPINES" },
            { n: "SUBIC BAY",	l: "PHILIPPINES" },
            { n: "SUDA BAY",	l: "GREECE" },
            { n: "SUDAN (COUNTRY)",	l: "SUDAN" },
            { n: "SUDESTE",	l: "BRAZIL" },
            { n: "SUDONG ANCHORAGE",	l: "SINGAPORE" },
            { n: "SUDR",	l: "EGYPT" },
            { n: "SUEZ",	l: "EGYPT" },
            { n: "SUEZ CANAL",	l: "EGYPT" },
            { n: "SUKHUMI",	l: "GEORGIA" },
            { n: "SUKKERTOPPEN",	l: "GREENLAND" },
            { n: "SUKRIER",	l: "ISRAEL" },
            { n: "SULAWESI",	l: "INDONESIA" },
            { n: "SULINA",	l: "ROMANIA" },
            { n: "SULINA CHANNEL",	l: "ROMANIA" },
            { n: "SULLOM VOE",	l: "UNITED KINGDOM" },
            { n: "SUMBE",	l: "ANGOLA" },
            { n: "SUMMERSIDE",	l: "CANADA" },
            { n: "SUND",	l: "FAROE ISLANDS" },
            { n: "SUNDA KELAPA",	l: "INDONESIA" },
            { n: "SUNDA STRAIT",	l: "INDONESIA" },
            { n: "SUNDERLAND",	l: "UNITED KINGDOM" },
            { n: "SUNDSVALL",	l: "SWEDEN" },
            { n: "SUNGAI GERONG",	l: "INDONESIA" },
            { n: "SUNGAI KOLAK",	l: "INDONESIA" },
            { n: "SUNGAI PAKNING",	l: "INDONESIA" },
            { n: "SUNGAI UDANG",	l: "MALAYSIA" },
            { n: "SUNGDONG SHIPYARD",	l: "KOREA, REPUBLIC" },
            { n: "SUNGER",	l: "IRAQ" },
            { n: "SUNNDALSORA",	l: "NORWAY" },
            { n: "SUNNY POINT TERMINAL",	l: "UNITED STATES" },
            { n: "SUNSHINE (LBC) BR",	l: "UNITED STATES" },
            { n: "SUPE",	l: "PERU" },
            { n: "SUPERIOR",	l: "UNITED STATES" },
            { n: "SUPSA MARINE TERMINAL",	l: "GEORGIA" },
            { n: "SUR",	l: "OMAN" },
            { n: "SURABAYA",	l: "INDONESIA" },
            { n: "SURALAYA",	l: "INDONESIA" },
            { n: "SURAT THANI",	l: "THAILAND" },
            { n: "SURIGAO",	l: "PHILIPPINES" },
            { n: "SURIGAO STRAIT",	l: "PHILIPPINES" },
            { n: "SURINAME (COUNTRY)",	l: "SURINAME" },
            { n: "SUSAK",	l: "CROATIA" },
            { n: "SUSAKI",	l: "JAPAN" },
            { n: "SUSOH",	l: "INDONESIA" },
            { n: "SUTTON BRIDGE",	l: "UNITED KINGDOM" },
            { n: "SUTUDDEN HARBOUR",	l: "SWEDEN" },
            { n: "SUVA",	l: "FIJI" },
            { n: "SUZHOU",	l: "CHINA" },
            { n: "SVANEKE",	l: "DENMARK" },
            { n: "SVANON",	l: "SWEDEN" },
            { n: "SVARTNES",	l: "NORWAY" },
            { n: "SVARTVIK",	l: "SWEDEN" },
            { n: "SVEA",	l: "NORWAY" },
            { n: "SVEAGRUVA",	l: "NORWAY" },
            { n: "SVEAGRUVA (SVALBARD)",	l: "NORWAY" },
            { n: "SVELGEN",	l: "NORWAY" },
            { n: "SVELVIK",	l: "NORWAY" },
            { n: "SVENDBORG",	l: "DENMARK" },
            { n: "SVENSKA BJORN",	l: "FINLAND" },
            { n: "SVETLY",	l: "RUSSIA" },
            { n: "SVIBY",	l: "ESTONIA" },
            { n: "SVOLVAER",	l: "NORWAY" },
            { n: "SW AREA STS",	l: "URUGUAY" },
            { n: "SW PASS",	l: "UNITED STATES" },
            { n: "SW PASS LZ",	l: "UNITED STATES" },
            { n: "SWANSBORO NC",	l: "UNITED STATES" },
            { n: "SWANSEA",	l: "UNITED KINGDOM" },
            { n: "SWATOW",	l: "CHINA" },
            { n: "SWEDEN (COUNTRY)",	l: "SWEDEN" },
            { n: "SWINEMUNDE",	l: "POLAND" },
            { n: "SWINOUJSCIE",	l: "POLAND" },
            { n: "SWITZERLAND (COUNTRY)",	l: "SWITZERLAND" },
            { n: "SYDAURANGER",	l: "NORWAY" },
            { n: "SYDNEY (AUSTRALIA)",	l: "AUSTRALIA" },
            { n: "SYDNEY (CANADA)",	l: "CANADA" },
            { n: "SYDNEY COVE",	l: "AUSTRALIA" },
            { n: "SYDNEY FENDER DROP POINT",	l: "AUSTRALIA" },
            { n: "SYDNEY LIGHTERING POINT",	l: "AUSTRALIA" },
            { n: "SYDNEY VLCC CUSTOMS CHECKPOINT",	l: "AUSTRALIA" },
            { n: "SYRA",	l: "GREECE" },
            { n: "SYRIA (COUNTRY)",	l: "SYRIA" },
            { n: "SYROS ISLAND",	l: "GREECE" },
            { n: "SZCZECIN",	l: "POLAND" },
            { n: "TABACO",	l: "PHILIPPINES" },
            { n: "TABANGAO",	l: "PHILIPPINES" },
            { n: "TABOGA ISLAND",	l: "PANAMA" },
            { n: "TABOGUILLA ISLAND",	l: "PANAMA" },
            { n: "TABONEO",	l: "INDONESIA" },
            { n: "TACHIBANA",	l: "JAPAN" },
            { n: "TACLOBAN",	l: "PHILIPPINES" },
            { n: "TACOMA",	l: "UNITED STATES" },
            { n: "TACONITE HARBOR",	l: "UNITED STATES" },
            { n: "TADOTSU",	l: "JAPAN" },
            { n: "TADOUSSAC",	l: "CANADA" },
            { n: "TADRI",	l: "INDIA" },
            { n: "TAEAN",	l: "KOREA, REPUBLIC" },
            { n: "TAESAN",	l: "KOREA, REPUBLIC" },
            { n: "TAFT",	l: "UNITED STATES" },
            { n: "TAGALOAN",	l: "PHILIPPINES" },
            { n: "TAGANITO",	l: "PHILIPPINES" },
            { n: "TAGANROG",	l: "RUSSIA" },
            { n: "TAGBILARAN",	l: "PHILIPPINES" },
            { n: "TAGONOURA",	l: "JAPAN" },
            { n: "TAHARA",	l: "JAPAN" },
            { n: "TAHAROA TERMINAL",	l: "NEW ZEALAND" },
            { n: "TAHKOLUOTO",	l: "FINLAND" },
            { n: "TAHSIS",	l: "CANADA" },
            { n: "TAHUNA",	l: "INDONESIA" },
            { n: "TAICANG",	l: "CHINA" },
            { n: "TAICANG WANFANG TERMINAL",	l: "CHINA" },
            { n: "TAICHUNG",	l: "TAIWAN" },
            { n: "TAIPALE",	l: "FINLAND" },
            { n: "TAIPEI",	l: "TAIWAN" },
            { n: "TAIPING",	l: "CHINA" },
            { n: "TAISHAN",	l: "CHINA" },
            { n: "TAIWAN (COUNTRY)",	l: "TAIWAN" },
            { n: "TAIXING",	l: "CHINA" },
            { n: "TAIZHOU",	l: "CHINA" },
            { n: "TAKAMATSU",	l: "JAPAN" },
            { n: "TAKAO",	l: "TAIWAN" },
            { n: "TAKASAGO",	l: "JAPAN" },
            { n: "TAKASU",	l: "JAPAN" },
            { n: "TAKEHARA",	l: "JAPAN" },
            { n: "TAKORADI",	l: "GHANA" },
            { n: "TAKU BAR",	l: "CHINA" },
            { n: "TAKULA TERMINAL",	l: "ANGOLA" },
            { n: "TAKUMA",	l: "JAPAN" },
            { n: "TA-KUNTAH",	l: "MEXICO" },
            { n: "TALAMONE",	l: "ITALY" },
            { n: "TALARA",	l: "PERU" },
            { n: "TALCAHUANO",	l: "CHILE" },
            { n: "TA-LIN-PU OFFSHORE OIL TMNL",	l: "TAIWAN" },
            { n: "TALISAYAN",	l: "PHILIPPINES" },
            { n: "TALISMAN MARINE TERMINAL",	l: "AUSTRALIA" },
            { n: "TALLABOA",	l: "PUERTO RICO" },
            { n: "TALLBACKEN",	l: "SWEDEN" },
            { n: "TALLINN",	l: "ESTONIA" },
            { n: "TALOYOAK, NU",	l: "CANADA" },
            { n: "TALTAL",	l: "CHILE" },
            { n: "TAMAN",	l: "RUSSIA" },
            { n: "TAMAN NORTH",	l: "RUSSIA" },
            { n: "TAMANO",	l: "JAPAN" },
            { n: "TAMASHIMA",	l: "JAPAN" },
            { n: "TAMATAVE",	l: "MADAGASCAR" },
            { n: "TAMMISAARI",	l: "FINLAND" },
            { n: "TAMPA",	l: "UNITED STATES" },
            { n: "TAMPA BAY ENTRANCE",	l: "UNITED STATES" },
            { n: "TAMPA-VERACRUZ",	l: "UNITED STATES" },
            { n: "TAMPICO",	l: "MEXICO" },
            { n: "TAN TAN",	l: "MOROCCO" },
            { n: "TANABE",	l: "JAPAN" },
            { n: "TANAH AMPO, BALI",	l: "INDONESIA" },
            { n: "TANAH KUNING",	l: "INDONESIA" },
            { n: "TANAH MERAH",	l: "INDONESIA" },
            { n: "TANAH PUTEH",	l: "MALAYSIA" },
            { n: "TANAMO",	l: "CUBA" },
            { n: "TANANGER",	l: "NORWAY" },
            { n: "TANAUAN",	l: "PHILIPPINES" },
            { n: "TANDAYAG",	l: "PHILIPPINES" },
            { n: "TANDOC",	l: "PHILIPPINES" },
            { n: "TANGA",	l: "TANZANIA" },
            { n: "TANGEN",	l: "NORWAY" },
            { n: "TANGER MED",	l: "MOROCCO" },
            { n: "TANGGU",	l: "CHINA" },
            { n: "TANGGUH",	l: "INDONESIA" },
            { n: "TANGIER",	l: "MOROCCO" },
            { n: "TANGKIANG",	l: "INDONESIA" },
            { n: "TANGSHAN",	l: "CHINA" },
            { n: "TANGYANG",	l: "CHINA" },
            { n: "TANIYAMA",	l: "JAPAN" },
            { n: "TANJIAHU",	l: "CHINA" },
            { n: "TANJONG BAKAU",	l: "INDONESIA" },
            { n: "TANJONG BERHALA",	l: "MALAYSIA" },
            { n: "TANJONG GELANG",	l: "MALAYSIA" },
            { n: "TANJONG KIDURONG",	l: "MALAYSIA" },
            { n: "TANJONG PAGAR",	l: "SINGAPORE" },
            { n: "TANJONG PENJURU",	l: "SINGAPORE" },
            { n: "TANJONG SALIRONG",	l: "BRUNEI" },
            { n: "TANJONG SULONG",	l: "MALAYSIA" },
            { n: "TANJUNG APIAPI",	l: "INDONESIA" },
            { n: "TANJUNG AYAM",	l: "MALAYSIA" },
            { n: "TANJUNG BAKAU",	l: "INDONESIA" },
            { n: "TANJUNG BALAI ASAHAN",	l: "INDONESIA" },
            { n: "TANJUNG BALAI KARIMUN",	l: "INDONESIA" },
            { n: "TANJUNG BARA",	l: "INDONESIA" },
            { n: "TANJUNG BARA COAL TERMINAL",	l: "INDONESIA" },
            { n: "TANJUNG BATU",	l: "INDONESIA" },
            { n: "TANJUNG BIN",	l: "MALAYSIA" },
            { n: "TANJUNG BRUAS",	l: "MALAYSIA" },
            { n: "TANJUNG BULI",	l: "INDONESIA" },
            { n: "TANJUNG BUYUT",	l: "INDONESIA" },
            { n: "TANJUNG EMAS",	l: "INDONESIA" },
            { n: "TANJUNG GEREM",	l: "INDONESIA" },
            { n: "TANJUNG JABUNG",	l: "INDONESIA" },
            { n: "TANJUNG JATI",	l: "INDONESIA" },
            { n: "TANJUNG KAMPEH",	l: "INDONESIA" },
            { n: "TANJUNG LANGSAT",	l: "MALAYSIA" },
            { n: "TANJUNG MANIS",	l: "MALAYSIA" },
            { n: "TANJUNG PANDAN",	l: "INDONESIA" },
            { n: "TANJUNG PELEPAS",	l: "MALAYSIA" },
            { n: "TANJUNG PEMANCINGAN",	l: "INDONESIA" },
            { n: "TANJUNG PERAK",	l: "INDONESIA" },
            { n: "TANJUNG PEUTANG",	l: "INDONESIA" },
            { n: "TANJUNG PINANG",	l: "INDONESIA" },
            { n: "TANJUNG PRIOK",	l: "INDONESIA" },
            { n: "TANJUNG REDEB",	l: "INDONESIA" },
            { n: "TANJUNG SABAU",	l: "INDONESIA" },
            { n: "TANJUNG SABAU ANCHORAGE",	l: "INDONESIA" },
            { n: "TANJUNG SEKONG",	l: "INDONESIA" },
            { n: "TANJUNG SELOR",	l: "INDONESIA" },
            { n: "TANJUNG SETAPA",	l: "MALAYSIA" },
            { n: "TANJUNG UBAN",	l: "INDONESIA" },
            { n: "TANSHOEI",	l: "TAIWAN" },
            { n: "TANTAWAN MARINE TERMINAL",	l: "THAILAND" },
            { n: "TANZANIA (COUNTRY)",	l: "TANZANIA" },
            { n: "TAP SHEK KOK",	l: "HONG KONG" },
            { n: "TAPATUAN",	l: "INDONESIA" },
            { n: "TARABULUS",	l: "LIBYA" },
            { n: "TARAFA",	l: "CUBA" },
            { n: "TARAHAN",	l: "INDONESIA" },
            { n: "TARAHAN TERMINAL",	l: "INDONESIA" },
            { n: "TARAKAN ISLAND",	l: "INDONESIA" },
            { n: "TARAKOHE",	l: "NEW ZEALAND" },
            { n: "TARANTO",	l: "ITALY" },
            { n: "TARAWA",	l: "KIRIBATI" },
            { n: "TARBERT",	l: "UNITED KINGDOM" },
            { n: "TARBERT ISLAND",	l: "IRELAND" },
            { n: "TARFAYA",	l: "MOROCCO" },
            { n: "TARJUN",	l: "INDONESIA" },
            { n: "TARRAGONA",	l: "SPAIN" },
            { n: "TARTOUS",	l: "SYRIA" },
            { n: "TASIILAQ",	l: "GREENLAND" },
            { n: "TASIUJAQ, QC",	l: "CANADA" },
            { n: "TASUCU",	l: "TURKEY" },
            { n: "TAU",	l: "NORWAY" },
            { n: "TAURANGA",	l: "NEW ZEALAND" },
            { n: "TAVSANCIL",	l: "TURKEY" },
            { n: "TAWAU",	l: "MALAYSIA" },
            { n: "TAWEELAH FAIRWAY BUOY",	l: "UNITED ARAB EMIRATES" },
            { n: "TAWI TAWI",	l: "PHILIPPINES" },
            { n: "TAWI WAWI",	l: "PHILIPPINES" },
            { n: "TAZERKA",	l: "TUNISIA" },
            { n: "TBILISI",	l: "GEORGIA" },
            { n: "TCHATAMBA MARINE TERMINAL",	l: "GABON" },
            { n: "TE GIAC TRANG TERMINAL",	l: "VIETNAM" },
            { n: "TEESPORT",	l: "UNITED KINGDOM" },
            { n: "TEESSIDE",	l: "UNITED KINGDOM" },
            { n: "TEGAL",	l: "INDONESIA" },
            { n: "TEIGNMOUTH",	l: "UNITED KINGDOM" },
            { n: "TEKIRDAG",	l: "TURKEY" },
            { n: "TEKKEKOY",	l: "TURKEY" },
            { n: "TEKNECIK",	l: "CYPRUS" },
            { n: "TELA",	l: "HONDURAS" },
            { n: "TELANG",	l: "INDONESIA" },
            { n: "TEL-AVIV",	l: "ISRAEL" },
            { n: "TELLICHERRY",	l: "INDIA" },
            { n: "TELOK AYER",	l: "INDONESIA" },
            { n: "TELOK RAMUNIA",	l: "MALAYSIA" },
            { n: "TELUK ANSON",	l: "MALAYSIA" },
            { n: "TELUK BAYUR",	l: "INDONESIA" },
            { n: "TELUK BEN",	l: "INDONESIA" },
            { n: "TELUK EWA",	l: "MALAYSIA" },
            { n: "TELUK INTAN",	l: "MALAYSIA" },
            { n: "TELUK KABUNG",	l: "INDONESIA" },
            { n: "TELUK RUBIAH",	l: "MALAYSIA" },
            { n: "TELUK SEMANGKA",	l: "INDONESIA" },
            { n: "TELUK TINOPO",	l: "INDONESIA" },
            { n: "TELUK TINOPO",	l: "INDONESIA" },
            { n: "TELUKBETONG",	l: "INDONESIA" },
            { n: "TEMA",	l: "GHANA" },
            { n: "TEMBIKAI MARINE TERMINAL",	l: "MALAYSIA" },
            { n: "TEMBILAHAN",	l: "INDONESIA" },
            { n: "TEMBLADORA",	l: "TRINIDAD AND TOBAGO" },
            { n: "TEMRYUK",	l: "RUSSIA" },
            { n: "TENERIFE",	l: "SPAIN" },
            { n: "TENES",	l: "ALGERIA" },
            { n: "TENNESSEE END",	l: "UNITED STATES" },
            { n: "TENN-TOM WW AND BLACK WARRIOR",	l: "UNITED STATES" },
            { n: "TENN-TOM WW AND TENNESSEE",	l: "UNITED STATES" },
            { n: "TEOUDIE",	l: "NEW CALEDONIA" },
            { n: "TEPRE TERMINAL",	l: "ECUADOR" },
            { n: "TERAINA HARBOUR",	l: "KIRIBATI" },
            { n: "TERMINAL AQUAVIARIO DE SANTANA",	l: "BRAZIL" },
            { n: "TERMINI IMERESE",	l: "ITALY" },
            { n: "TERMISA TERMINAL",	l: "BRAZIL" },
            { n: "TERMOLI",	l: "ITALY" },
            { n: "TERNATE",	l: "INDONESIA" },
            { n: "TERNEUZEN",	l: "NETHERLANDS" },
            { n: "TERRA NOVA BAY",	l: "ANTARCTICA" },
            { n: "TERRE-DE-HAUT",	l: "GUADELOUPE" },
            { n: "TETNEY TERMINAL",	l: "UNITED KINGDOM" },
            { n: "TEXADA ISLAND",	l: "CANADA" },
            { n: "TEXAS CITY",	l: "UNITED STATES" },
            { n: "TEXAS CITY CHANNEL END",	l: "UNITED STATES" },
            { n: "TEXEL",	l: "NETHERLANDS" },
            { n: "TEXPORT",	l: "UNITED STATES" },
            { n: "TG",	l: "MALAYSIA" },
            { n: "TG. PELEPAS STS ANCHORAGE",	l: "MALAYSIA" },
            { n: "THA SALA",	l: "THAILAND" },
            { n: "THA THONG",	l: "THAILAND" },
            { n: "THAI BINH",	l: "VIETNAM" },
            { n: "THAILAND (COUNTRY)",	l: "THAILAND" },
            { n: "THAMESHAVEN",	l: "UNITED KINGDOM" },
            { n: "THAMESPORT",	l: "UNITED KINGDOM" },
            { n: "THAMSHAVN",	l: "NORWAY" },
            { n: "THANG LONG DONG DO TERMINAL",	l: "VIETNAM" },
            { n: "THANH HOA",	l: "VIETNAM" },
            { n: "THASOS ISLAND",	l: "GREECE" },
            { n: "THATONG",	l: "THAILAND" },
            { n: "THE DALLES",	l: "UNITED STATES" },
            { n: "THE DOOR",	l: "UNITED STATES" },
            { n: "THE FLINT",	l: "SWEDEN" },
            { n: "THE QUOINS",	l: "OMAN" },
            { n: "THELWALL",	l: "UNITED KINGDOM" },
            { n: "THEODORE SHIP CHANNEL END",	l: "UNITED STATES" },
            { n: "THEODOSIA",	l: "UKRAINE" },
            { n: "THESSALON",	l: "CANADA" },
            { n: "THESSALONIKI",	l: "GREECE" },
            { n: "THEVENARD",	l: "AUSTRALIA" },
            { n: "THI VAI",	l: "VIETNAM" },
            { n: "THILAWA",	l: "MYANMAR" },
            { n: "THIO",	l: "NEW CALEDONIA" },
            { n: "THIRA",	l: "GREECE" },
            { n: "THIRD PORT",	l: "VIRGIN ISLANDS" },
            { n: "THIRUKKADAIYUR",	l: "INDIA" },
            { n: "THISTED",	l: "DENMARK" },
            { n: "THISVI",	l: "GREECE" },
            { n: "THORLAKSHOFN",	l: "ICELAND" },
            { n: "THORNE BAY",	l: "UNITED STATES" },
            { n: "THOROLD",	l: "CANADA" },
            { n: "THREE MILE CREEK",	l: "UNITED STATES" },
            { n: "THREE RIVERS",	l: "CANADA" },
            { n: "THULE",	l: "GREENLAND" },
            { n: "THUNDER BAY",	l: "CANADA" },
            { n: "THUNDERBOLT GA",	l: "UNITED STATES" },
            { n: "THURSDAY ISLAND",	l: "AUSTRALIA" },
            { n: "THURSO",	l: "UNITED KINGDOM" },
            { n: "THYBORON",	l: "DENMARK" },
            { n: "TIA JUANA",	l: "VENEZUELA" },
            { n: "TIAKA MARINE TERMINAL",	l: "INDONESIA" },
            { n: "TIANJIAAN",	l: "CHINA" },
            { n: "TIANJIN",	l: "CHINA" },
            { n: "TIANZHEN",	l: "CHINA" },
            { n: "TIEBAGHI",	l: "NEW CALEDONIA" },
            { n: "TIENIUPU",	l: "CHINA" },
            { n: "TIENTSIN",	l: "CHINA" },
            { n: "TIERRA COLORADA",	l: "PERU" },
            { n: "TIESHAN",	l: "CHINA" },
            { n: "TIKO",	l: "CAMEROON" },
            { n: "TIKSI",	l: "RUSSIA" },
            { n: "TILBURY",	l: "UNITED KINGDOM" },
            { n: "TILISAYAN",	l: "PHILIPPINES" },
            { n: "TIMARU",	l: "NEW ZEALAND" },
            { n: "TIMOR-LESTE (COUNTRY)",	l: "TIMOR-LESTE" },
            { n: "TIMSAH LAKE",	l: "EGYPT" },
            { n: "TIN CAN ISLAND",	l: "NIGERIA" },
            { n: "TINDALO",	l: "PHILIPPINES" },
            { n: "TIVAT",	l: "MONTENEGRO" },
            { n: "TIVERTON",	l: "UNITED STATES" },
            { n: "TIWAN",	l: "CHINA" },
            { n: "TJELDBERGODDEN",	l: "NORWAY" },
            { n: "TJILATJAP",	l: "INDONESIA" },
            { n: "TOAMASINA",	l: "MADAGASCAR" },
            { n: "TOBA",	l: "JAPAN" },
            { n: "TOBATA",	l: "JAPAN" },
            { n: "TOBRUK",	l: "LIBYA" },
            { n: "TOCOPILLA",	l: "CHILE" },
            { n: "TOENNING",	l: "GERMANY" },
            { n: "TOFTE",	l: "NORWAY" },
            { n: "TOGIAK BAY",	l: "UNITED STATES" },
            { n: "TOGLIATLI",	l: "RUSSIA" },
            { n: "TOGO (COUNTRY)",	l: "TOGO" },
            { n: "TOK BALI",	l: "MALAYSIA" },
            { n: "TOKACHI",	l: "JAPAN" },
            { n: "TOKAI",	l: "JAPAN" },
            { n: "TOKUSHIMA",	l: "JAPAN" },
            { n: "TOKUYAMA",	l: "JAPAN" },
            { n: "TOKYO",	l: "JAPAN" },
            { n: "TOLAGNARO",	l: "MADAGASCAR" },
            { n: "TOLEARA",	l: "MADAGASCAR" },
            { n: "TOLEDO (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "TOLEDO (USA)",	l: "UNITED STATES" },
            { n: "TOLIARA",	l: "MADAGASCAR" },
            { n: "TOLKKINEN-TOLKIS",	l: "FINLAND" },
            { n: "TOLLESBURY",	l: "UNITED KINGDOM" },
            { n: "TOLSTOI BAY",	l: "UNITED STATES" },
            { n: "TOLU",	l: "COLOMBIA" },
            { n: "TOMAKOMAI",	l: "JAPAN" },
            { n: "TOMBIGBEE AND TENN-TOM WW",	l: "UNITED STATES" },
            { n: "TOME",	l: "CHILE" },
            { n: "TOMIL HARBOUR",	l: "MICRONESIA" },
            { n: "TOMIOKA",	l: "JAPAN" },
            { n: "TOMOGASHIMA",	l: "JAPAN" },
            { n: "TONAWANDA",	l: "UNITED STATES" },
            { n: "TONDA",	l: "JAPAN" },
            { n: "TONG YEONG",	l: "KOREA, REPUBLIC" },
            { n: "TONGA (COUNTRY)",	l: "TONGA" },
            { n: "TONGHAE",	l: "KOREA, REPUBLIC" },
            { n: "TONGJIANG",	l: "CHINA" },
            { n: "TONGLING",	l: "CHINA" },
            { n: "TONGSHAN",	l: "CHINA" },
            { n: "TONGTANGWAN",	l: "CHINA" },
            { n: "TONGUE DW ANCHORAGE",	l: "UNITED KINGDOM" },
            { n: "TONGUE POINT",	l: "UNITED STATES" },
            { n: "TONGYEONG",	l: "KOREA, REPUBLIC" },
            { n: "TONNAY-CHARENTE",	l: "FRANCE" },
            { n: "TONSBERG",	l: "NORWAY" },
            { n: "TONTOUTA",	l: "NEW CALEDONIA" },
            { n: "TOPOLOBAMPO",	l: "MEXICO" },
            { n: "TOPPILA",	l: "FINLAND" },
            { n: "TOR",	l: "EGYPT" },
            { n: "TOR BAY HARBOUR",	l: "UNITED KINGDOM" },
            { n: "TORGUA",	l: "BRAZIL" },
            { n: "TORNIO",	l: "FINLAND" },
            { n: "TORONTO",	l: "CANADA" },
            { n: "TORONTO (PHILIPPINES)",	l: "PHILIPPINES" },
            { n: "TOROS",	l: "TURKEY" },
            { n: "TORQUAY",	l: "UNITED KINGDOM" },
            { n: "TORRE ANNUNZIATA",	l: "ITALY" },
            { n: "TORRE DEL GRECO",	l: "ITALY" },
            { n: "TORRES STRAIT",	l: "AUSTRALIA" },
            { n: "TORRES STRAIT & GREAT BARRIER REEF",	l: "AUSTRALIA" },
            { n: "TORREVIEJA",	l: "SPAIN" },
            { n: "TORSHAVN",	l: "FAROE ISLANDS" },
            { n: "TORTOLA",	l: "BRITISH VIRGIN ISLANDS" },
            { n: "TOTNES",	l: "UNITED KINGDOM" },
            { n: "TOTORALILLO",	l: "CHILE" },
            { n: "TOULON LA SEYNE",	l: "FRANCE" },
            { n: "TOWNSVILLE",	l: "AUSTRALIA" },
            { n: "TOYAMA",	l: "JAPAN" },
            { n: "TOYAMA-SHINKO",	l: "JAPAN" },
            { n: "TOYO THAI BIRTH",	l: "MYANMAR" },
            { n: "TOYOHASHI",	l: "JAPAN" },
            { n: "TRABZON",	l: "TURKEY" },
            { n: "TRACY",	l: "CANADA" },
            { n: "TRAMANDAI",	l: "BRAZIL" },
            { n: "TRANGISVAAG",	l: "FAROE ISLANDS" },
            { n: "TRANMERE",	l: "UNITED KINGDOM" },
            { n: "TRANMERE OIL TERMINAL",	l: "UNITED KINGDOM" },
            { n: "TRANOY",	l: "NORWAY" },
            { n: "TRANSLOADING AREA 1",	l: "UNITED ARAB EMIRATES" },
            { n: "TRANSLOADING AREA 2",	l: "UNITED ARAB EMIRATES" },
            { n: "TRAPANI",	l: "ITALY" },
            { n: "TRAVEMUNDE",	l: "GERMANY" },
            { n: "TRAVERSE CITY",	l: "UNITED STATES" },
            { n: "TREGUIER",	l: "FRANCE" },
            { n: "TRELLEBORG",	l: "SWEDEN" },
            { n: "TRENT WHARVES",	l: "UNITED KINGDOM" },
            { n: "TRENTON",	l: "UNITED STATES" },
            { n: "TRENTON CHANNEL ENTRANCE",	l: "UNITED STATES" },
            { n: "TRIABUNNA",	l: "AUSTRALIA" },
            { n: "TRIAL BAY",	l: "AUSTRALIA" },
            { n: "TRIESTE",	l: "ITALY" },
            { n: "TRINCOMALEE",	l: "SRI LANKA" },
            { n: "TRINIDAD",	l: "TRINIDAD AND TOBAGO" },
            { n: "TRINIDAD & TOBAGO (COUNTRY)",	l: "TRINIDAD AND TOBAGO" },
            { n: "TRINIDAD AND TOBAGO (COUNTRY)",	l: "TRINIDAD AND TOBAGO" },
            { n: "TRINIDAD OFFSHORE",	l: "TRINIDAD AND TOBAGO" },
            { n: "TRIPOLI (LEBANON)",	l: "LEBANON" },
            { n: "TRIPOLI (LIBYA)",	l: "LIBYA" },
            { n: "TRISTAN DA CUNHA",	l: "SAINT HELENA" },
            { n: "TRIVANDRUM",	l: "INDIA" },
            { n: "TROIS-RIVIERES",	l: "CANADA" },
            { n: "TROLLHATTE CANAL",	l: "SWEDEN" },
            { n: "TROMBETAS",	l: "BRAZIL" },
            { n: "TROMSDALEN",	l: "NORWAY" },
            { n: "TROMSO",	l: "NORWAY" },
            { n: "TRONDHEIM",	l: "NORWAY" },
            { n: "TROON",	l: "UNITED KINGDOM" },
            { n: "TRUBADUREN",	l: "SWEDEN" },
            { n: "TRUJILLO",	l: "PERU" },
            { n: "TRURO",	l: "UNITED KINGDOM" },
            { n: "TSAMKONG",	l: "CHINA" },
            { n: "TSIGELI",	l: "GREECE" },
            { n: "TSING YI ISLAND",	l: "HONG KONG" },
            { n: "TSINGTAO",	l: "CHINA" },
            { n: "TSU",	l: "JAPAN" },
            { n: "TSUEN WAN",	l: "HONG KONG" },
            { n: "TSUGARU STRAIT",	l: "JAPAN" },
            { n: "TSUKUMI",	l: "JAPAN" },
            { n: "TSUMATSUSAKA (MATSUSAKA)",	l: "JAPAN" },
            { n: "TSUMATSUSAKA (TSU)",	l: "JAPAN" },
            { n: "TSUNEISHI",	l: "JAPAN" },
            { n: "TSURUGA",	l: "JAPAN" },
            { n: "TUAPSE",	l: "RUSSIA" },
            { n: "TUAS",	l: "SINGAPORE" },
            { n: "TUBA",	l: "CHINA" },
            { n: "TUBAI",	l: "INDONESIA" },
            { n: "TUBAN",	l: "INDONESIA" },
            { n: "TUBANG",	l: "INDONESIA" },
            { n: "TUBARAO",	l: "BRAZIL" },
            { n: "TUBAY",	l: "PHILIPPINES" },
            { n: "TUBORG",	l: "DENMARK" },
            { n: "TUEN MUN",	l: "HONG KONG" },
            { n: "TUI FIELD",	l: "NEW ZEALAND" },
            { n: "TUKTOYAKTUK",	l: "CANADA" },
            { n: "TULAGI",	l: "SOLOMON ISLANDS" },
            { n: "TULCEA",	l: "ROMANIA" },
            { n: "TULEAR",	l: "MADAGASCAR" },
            { n: "TULJA TERMINAL",	l: "NIGERIA" },
            { n: "TUMACO",	l: "COLOMBIA" },
            { n: "TUMBLEDOWN DICK BAY",	l: "BONAIRE" },
            { n: "TUNA",	l: "INDIA" },
            { n: "TUNADALSHAMNEN",	l: "SWEDEN" },
            { n: "TUNAS DE ZAZA",	l: "CUBA" },
            { n: "TUNGPRONG PORT",	l: "THAILAND" },
            { n: "TUNIS",	l: "TUNISIA" },
            { n: "TUNISIA (COUNTRY)",	l: "TUNISIA" },
            { n: "TUOKOU",	l: "CHINA" },
            { n: "TUPAVUORI",	l: "FINLAND" },
            { n: "TURBO",	l: "COLOMBIA" },
            { n: "TURCOAIA",	l: "ROMANIA" },
            { n: "TURF POINT",	l: "CANADA" },
            { n: "TURKEY (COUNTRY)",	l: "TURKEY" },
            { n: "TURKMANBASHI",	l: "TURKMENISTAN" },
            { n: "TURKMENISTAN (COUNTRY)",	l: "TURKMENISTAN" },
            { n: "TURKS & CAICOS ISLANDS (COUNTRY)",	l: "TURKS AND CAICOS ISLANDS" },
            { n: "TURKS AND CAICOS ISLANDS (COUNTRY)",	l: "TURKS AND CAICOS ISLANDS" },
            { n: "TURKU",	l: "FINLAND" },
            { n: "TURNU MAGURELE",	l: "ROMANIA" },
            { n: "TUSCALOOSA",	l: "UNITED STATES" },
            { n: "TUSCAR ROCK",	l: "IRELAND" },
            { n: "TUSKAR LIGHT",	l: "IRELAND" },
            { n: "TUTICORIN",	l: "INDIA" },
            { n: "TUTUNCIFILIK",	l: "TURKEY" },
            { n: "TUVALU (COUNTRY)",	l: "TUVALU" },
            { n: "TUXPAN",	l: "MEXICO" },
            { n: "TUY HOA",	l: "VIETNAM" },
            { n: "TUY HOA",	l: "VIETNAM" },
            { n: "TUZLA",	l: "TURKEY" },
            { n: "TUZLA",	l: "TURKEY" },
            { n: "TVEDESTRAND",	l: "NORWAY" },
            { n: "TVOROYRI",	l: "FAROE ISLANDS" },
            { n: "TWILLINGATE",	l: "CANADA" },
            { n: "TWO HARBORS",	l: "UNITED STATES" },
            { n: "TWOFOLD BAY",	l: "AUSTRALIA" },
            { n: "TYBORON",	l: "DENMARK" },
            { n: "TYNE",	l: "UNITED KINGDOM" },
            { n: "TYR",	l: "LEBANON" },
            { n: "TYSSEDAL",	l: "NORWAY" },
            { n: "TZAREVO",	l: "BULGARIA" },
            { n: "U. BEAUHARNOIS LOCK",	l: "CANADA" },
            { n: "UBE",	l: "JAPAN" },
            { n: "UCHIURA",	l: "JAPAN" },
            { n: "UDANG NATUNA",	l: "INDONESIA" },
            { n: "UDDEBO",	l: "SWEDEN" },
            { n: "UDDEVALLA",	l: "SWEDEN" },
            { n: "UERDINGEN",	l: "GERMANY" },
            { n: "UETERSEN",	l: "GERMANY" },
            { n: "UGLEGORSK",	l: "RUSSIA" },
            { n: "UJINA",	l: "JAPAN" },
            { n: "UJUNG PANDANG",	l: "INDONESIA" },
            { n: "UKC",	l: "UNITED KINGDOM" },
            { n: "UK-GIB",	l: "GIBRALTAR" },
            { n: "UKISHIMA",	l: "JAPAN" },
            { n: "UKPOKITI MARINE TERMINAL",	l: "NIGERIA" },
            { n: "UKRAINE (COUNTRY)",	l: "UKRAINE" },
            { n: "ULEABORG",	l: "FINLAND" },
            { n: "ULEELHEUE",	l: "INDONESIA" },
            { n: "ULLAPOOL",	l: "UNITED KINGDOM" },
            { n: "ULSAN",	l: "KOREA, REPUBLIC" },
            { n: "ULSTEINVIK",	l: "NORWAY" },
            { n: "ULTRAFERTIL TERMINAL",	l: "BRAZIL" },
            { n: "ULVIK",	l: "NORWAY" },
            { n: "UMANAK",	l: "GREENLAND" },
            { n: "UMATILLA",	l: "UNITED STATES" },
            { n: "UMEA",	l: "SWEDEN" },
            { n: "UMIUJAQ",	l: "CANADA" },
            { n: "UMM AL NAR PETROLEUM PORT",	l: "UNITED ARAB EMIRATES" },
            { n: "UMM AL QAIWAIN",	l: "UNITED ARAB EMIRATES" },
            { n: "UMM QASR",	l: "IRAQ" },
            { n: "UMM SAID",	l: "QATAR" },
            { n: "UMUDA",	l: "PAPUA NEW GUINEA" },
            { n: "UNALASKA",	l: "UNITED STATES" },
            { n: "UNGGI HANG",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "UNIMAK",	l: "UNITED STATES" },
            { n: "UNITED ARAB EMIRATES (COUNTRY)",	l: "UNITED ARAB EMIRATES" },
            { n: "UNITED KINGDOM (COUNTRY)",	l: "UNITED KINGDOM" },
            { n: "UNITED STATES (COUNTRY)",	l: "UNITED STATES" },
            { n: "UNO",	l: "JAPAN" },
            { n: "UNYE",	l: "TURKEY" },
            { n: "UPERNAVIK",	l: "GREENLAND" },
            { n: "UPPER MISSISSIPPI END",	l: "UNITED STATES" },
            { n: "URAGA",	l: "JAPAN" },
            { n: "URANGAN",	l: "AUSTRALIA" },
            { n: "URINJ",	l: "CROATIA" },
            { n: "URUGUAY (COUNTRY)",	l: "URUGUAY" },
            { n: "URUGUAY NE LZ",	l: "URUGUAY" },
            { n: "URUGUAY SW LZ",	l: "URUGUAY" },
            { n: "US GULF LIGHTERING ZONES",	l: "UNITED STATES" },
            { n: "US INLAND WATERWAYS",	l: "UNITED STATES" },
            { n: "USA 25NM",	l: "UNITED STATES" },
            { n: "USAN FPSO",	l: "NIGERIA" },
            { n: "USEASTLP0",	l: "UNITED STATES" },
            { n: "USEASTLP1",	l: "UNITED STATES" },
            { n: "USEASTLP2",	l: "UNITED STATES" },
            { n: "USEASTLP3",	l: "UNITED STATES" },
            { n: "USEC",	l: "UNITED STATES" },
            { n: "USELESS LOOP",	l: "AUSTRALIA" },
            { n: "USG",	l: "UNITED STATES" },
            { n: "USHANT ISLAND",	l: "FRANCE" },
            { n: "USHUAIA",	l: "ARGENTINA" },
            { n: "USI CANAL END",	l: "UNITED STATES" },
            { n: "USIBA",	l: "BRAZIL" },
            { n: "USNH",	l: "UNITED STATES" },
            { n: "UST KAMCHATSK",	l: "RUSSIA" },
            { n: "UST-DUNAYSK",	l: "UKRAINE" },
            { n: "USTKA",	l: "POLAND" },
            { n: "UST-LUGA",	l: "RUSSIA" },
            { n: "USUKI",	l: "JAPAN" },
            { n: "UTANSJO",	l: "SWEDEN" },
            { n: "UTO",	l: "FINLAND" },
            { n: "UUMMANNAQ",	l: "GREENLAND" },
            { n: "UUSIKAUPUNKI",	l: "FINLAND" },
            { n: "UWAJIMA",	l: "JAPAN" },
            { n: "VAASA",	l: "FINLAND" },
            { n: "VACAMONTE",	l: "PANAMA" },
            { n: "VADA",	l: "ITALY" },
            { n: "VADHEIM",	l: "NORWAY" },
            { n: "VADINAR TERMINAL",	l: "INDIA" },
            { n: "VADO LIGURE",	l: "ITALY" },
            { n: "VADSO",	l: "NORWAY" },
            { n: "VAGUR",	l: "FAROE ISLANDS" },
            { n: "VAJA",	l: "SWEDEN" },
            { n: "VAKSDAL",	l: "NORWAY" },
            { n: "VALDEMARSVIK",	l: "SWEDEN" },
            { n: "VALDES ISLAND",	l: "CANADA" },
            { n: "VALDEZ",	l: "UNITED STATES" },
            { n: "VALDIVIA",	l: "CHILE" },
            { n: "VALENCIA",	l: "SPAIN" },
            { n: "VALENTIA",	l: "IRELAND" },
            { n: "VALINOKKAM",	l: "INDIA" },
            { n: "VALKOM",	l: "FINLAND" },
            { n: "VALLCARCA",	l: "SPAIN" },
            { n: "VALLEJO",	l: "UNITED STATES" },
            { n: "VALLETTA",	l: "MALTA" },
            { n: "VALLEYFIELD",	l: "CANADA" },
            { n: "VALLO",	l: "NORWAY" },
            { n: "VALLOY",	l: "NORWAY" },
            { n: "VALLVIK",	l: "SWEDEN" },
            { n: "VALPARAISO",	l: "CHILE" },
            { n: "VAN GOGH",	l: "AUSTRALIA" },
            { n: "VAN PHONG BAY",	l: "VIETNAM" },
            { n: "VANCOUVER (CANADA)",	l: "CANADA" },
            { n: "VANCOUVER (USA)",	l: "UNITED STATES" },
            { n: "VANCOUVER-BALBOA",	l: "CANADA" },
            { n: "VANCOUVER-VALPARAISO",	l: "CANADA" },
            { n: "VANERSBORG",	l: "SWEDEN" },
            { n: "VANIMO",	l: "PAPUA NEW GUINEA" },
            { n: "VANINO",	l: "RUSSIA" },
            { n: "VANNES",	l: "FRANCE" },
            { n: "VANUATU (COUNTRY)",	l: "VANUATU" },
            { n: "VARANDEY",	l: "RUSSIA" },
            { n: "VARANUS ISLAND TERMINAL",	l: "AUSTRALIA" },
            { n: "VARBERG",	l: "SWEDEN" },
            { n: "VARDO",	l: "NORWAY" },
            { n: "VARG",	l: "NORWAY" },
            { n: "VARKAUS HARBOURS",	l: "FINLAND" },
            { n: "VARNA",	l: "BULGARIA" },
            { n: "VARSTERVIK",	l: "SWEDEN" },
            { n: "VARTAHAMNEN",	l: "SWEDEN" },
            { n: "VASSILIKO",	l: "CYPRUS" },
            { n: "VASTERAS",	l: "SWEDEN" },
            { n: "VASTERVIK",	l: "SWEDEN" },
            { n: "VASTO",	l: "ITALY" },
            { n: "VATHI",	l: "GREECE" },
            { n: "VATIA POINT",	l: "FIJI" },
            { n: "VATIKA BAY",	l: "GREECE" },
            { n: "VATOMANDRY",	l: "MADAGASCAR" },
            { n: "VAVAU",	l: "INDONESIA" },
            { n: "VAVOUTO",	l: "NEW CALEDONIA" },
            { n: "VEDAVAGEN",	l: "NORWAY" },
            { n: "VEITSILUOTO",	l: "FINLAND" },
            { n: "VEJLE",	l: "DENMARK" },
            { n: "VELSEN",	l: "NETHERLANDS" },
            { n: "VENALUM TERMINAL",	l: "VENEZUELA" },
            { n: "VENEZUELA (COUNTRY)",	l: "VENEZUELA" },
            { n: "VENGURLA",	l: "INDIA" },
            { n: "VENICE",	l: "ITALY" },
            { n: "VENICE (USA)",	l: "UNITED STATES" },
            { n: "VENTERMINALES",	l: "VENEZUELA" },
            { n: "VENTSPILS",	l: "LATVIA" },
            { n: "VERACRUZ",	l: "MEXICO" },
            { n: "VERANO",	l: "PHILIPPINES" },
            { n: "VERAVAL",	l: "INDIA" },
            { n: "VERDAL",	l: "NORWAY" },
            { n: "VESTMANNA",	l: "FAROE ISLANDS" },
            { n: "VESTMANNAEYJAR",	l: "ICELAND" },
            { n: "VESTNES",	l: "NORWAY" },
            { n: "VIANA DO CASTELO",	l: "PORTUGAL" },
            { n: "VIBO VALENTIA",	l: "ITALY" },
            { n: "VICKSBURG",	l: "UNITED STATES" },
            { n: "VICTORIA",	l: "BRAZIL" },
            { n: "VICTORIA (CAMEROON)",	l: "CAMEROON" },
            { n: "VICTORIA (CANADA)",	l: "CANADA" },
            { n: "VICTORIA BARGE CANAL END",	l: "UNITED STATES" },
            { n: "VIDAIR",	l: "FAROE ISLANDS" },
            { n: "VIETNAM (COUNTRY)",	l: "VIETNAM" },
            { n: "VIEUX FORT",	l: "SAINT LUCIA" },
            { n: "VIGO",	l: "SPAIN" },
            { n: "VIHREASAARI",	l: "FINLAND" },
            { n: "VIIPURI",	l: "RUSSIA" },
            { n: "VIJAYDURG",	l: "INDIA" },
            { n: "VILA DO CONDE",	l: "BRAZIL" },
            { n: "VILA REAL DE SANTO ANTONIO",	l: "PORTUGAL" },
            { n: "VILANOVA I LA GELTRU",	l: "SPAIN" },
            { n: "VILLA CISNEROS (DAKHLA)",	l: "WESTERN SAHARA" },
            { n: "VILLA CONSTITUCION",	l: "ARGENTINA" },
            { n: "VILLA DO PORTO",	l: "PORTUGAL" },
            { n: "VILLAGARCIA DE AROSA",	l: "SPAIN" },
            { n: "VILLAGE COVE",	l: "UNITED STATES" },
            { n: "VILLANUEVA",	l: "PHILIPPINES" },
            { n: "VILLANUEVA Y GELTRU",	l: "SPAIN" },
            { n: "VILLARICOS",	l: "SPAIN" },
            { n: "VILLEFRANCHE",	l: "FRANCE" },
            { n: "VINAPU OIL TERMINAL",	l: "CHILE" },
            { n: "VINCENT FPSO",	l: "AUSTRALIA" },
            { n: "VINGA ISLAND",	l: "SWEDEN" },
            { n: "VINH CAM RANH",	l: "VIETNAM" },
            { n: "VINH CITY",	l: "VIETNAM" },
            { n: "VINH TAN ANCHORAGE",	l: "VIETNAM" },
            { n: "VIRGIN ISLANDS (BRITISH) (COUNTRY)",	l: "BRITISH VIRGIN ISLANDS" },
            { n: "VIRGIN ISLANDS (COUNTRY)",	l: "VIRGIN ISLANDS" },
            { n: "VIRGIN ISLANDS (US) (COUNTRY)",	l: "VIRGIN ISLANDS" },
            { n: "VIRGIN PASSAGE",	l: "VIRGIN ISLANDS" },
            { n: "VIRTSU",	l: "ESTONIA" },
            { n: "VIRU HARBOUR",	l: "SOLOMON ISLANDS" },
            { n: "VISAKHAPATNAM",	l: "INDIA" },
            { n: "VISBY",	l: "SWEDEN" },
            { n: "VISNES",	l: "NORWAY" },
            { n: "VITA",	l: "CUBA" },
            { n: "VITIAZ STRAIT",	l: "PAPUA NEW GUINEA" },
            { n: "VITINO",	l: "RUSSIA" },
            { n: "VITORIA",	l: "BRAZIL" },
            { n: "VITORIA-RIO GRANDE",	l: "BRAZIL" },
            { n: "VIVERO",	l: "SPAIN" },
            { n: "VIZAG",	l: "INDIA" },
            { n: "VLAARDINGEN",	l: "NETHERLANDS" },
            { n: "VLADIVOSTOK",	l: "RUSSIA" },
            { n: "VLISSINGEN",	l: "NETHERLANDS" },
            { n: "VLONE",	l: "ALBANIA" },
            { n: "VLORE",	l: "ALBANIA" },
            { n: "VOISEY 'S BAY",	l: "CANADA" },
            { n: "VOLDA",	l: "NORWAY" },
            { n: "VOLGOGRAD",	l: "RUSSIA" },
            { n: "VOLOS",	l: "GREECE" },
            { n: "VOLVE",	l: "NORWAY" },
            { n: "VOPNAFJORDUR",	l: "ICELAND" },
            { n: "VORDINGBORG",	l: "DENMARK" },
            { n: "VOSTOCHNYY",	l: "RUSSIA" },
            { n: "VOUDHIA BAY",	l: "GREECE" },
            { n: "VUDA POINT",	l: "FIJI" },
            { n: "VUNG ANG",	l: "VIETNAM" },
            { n: "VUNG TAU",	l: "VIETNAM" },
            { n: "VUNGRO",	l: "VIETNAM" },
            { n: "VUOKSEN TERMINAL",	l: "FINLAND" },
            { n: "VUOSAARI HARBOUR",	l: "FINLAND" },
            { n: "VYBORG",	l: "RUSSIA" },
            { n: "VYSOTSK",	l: "RUSSIA" },
            { n: "W MED",	l: "SPAIN" },
            { n: "WAALHAVEN",	l: "NETHERLANDS" },
            { n: "WADA",	l: "JAPAN" },
            { n: "WADAMISAKI",	l: "JAPAN" },
            { n: "WADDINGTON",	l: "UNITED STATES" },
            { n: "WADI FEIRAN",	l: "EGYPT" },
            { n: "WAGENINGEN",	l: "SURINAME" },
            { n: "WAIGANG",	l: "CHINA" },
            { n: "WAIGAOQIAO",	l: "CHINA" },
            { n: "WAIJA",	l: "SWEDEN" },
            { n: "WAINGAPU",	l: "INDONESIA" },
            { n: "WAISARISA",	l: "INDONESIA" },
            { n: "WAKAMATSU",	l: "JAPAN" },
            { n: "WAKAYAMA",	l: "JAPAN" },
            { n: "WAKE ISLAND",	l: "MARSHALL ISLANDS" },
            { n: "WAKKANAI",	l: "JAPAN" },
            { n: "WALA",	l: "VANUATU" },
            { n: "WALLA WALLA",	l: "UNITED STATES" },
            { n: "WALLACE BAY",	l: "MALAYSIA" },
            { n: "WALLAROO",	l: "AUSTRALIA" },
            { n: "WALLHAMN",	l: "SWEDEN" },
            { n: "WALLIS AND FUTUNA (COUNTRY)",	l: "WALLIS AND FUTUNA" },
            { n: "WALLIS ISLAND",	l: "WALLIS AND FUTUNA" },
            { n: "WALLSEND",	l: "UNITED KINGDOM" },
            { n: "WALNEY WINDFARM",	l: "UNITED KINGDOM" },
            { n: "WALU BAY",	l: "FIJI" },
            { n: "WALVIS BAY",	l: "NAMIBIA" },
            { n: "WAMSASI",	l: "INDONESIA" },
            { n: "WAN CHAI",	l: "HONG KONG" },
            { n: "WANDELAAR ANCHORAGE",	l: "BELGIUM" },
            { n: "WANDOO MARINE TERMINAL",	l: "AUSTRALIA" },
            { n: "WANGANUI",	l: "NEW ZEALAND" },
            { n: "WANGJIAQIAO",	l: "CHINA" },
            { n: "WANXIAN",	l: "CHINA" },
            { n: "WANZHA",	l: "CHINA" },
            { n: "WARNEMUNDE",	l: "GERMANY" },
            { n: "WARRENPOINT",	l: "UNITED KINGDOM" },
            { n: "WARRI",	l: "NIGERIA" },
            { n: "WARRINGTON",	l: "UNITED KINGDOM" },
            { n: "WARRNAMBOOL",	l: "AUSTRALIA" },
            { n: "WASHINGTON NC",	l: "UNITED STATES" },
            { n: "WASSANA",	l: "THAILAND" },
            { n: "WATCHET",	l: "UNITED KINGDOM" },
            { n: "WATERFORD",	l: "IRELAND" },
            { n: "WATSON ISLAND",	l: "CANADA" },
            { n: "WAUKEGAN",	l: "UNITED STATES" },
            { n: "WAVERLEY HARBOUR",	l: "NEW ZEALAND" },
            { n: "WAYAME",	l: "INDONESIA" },
            { n: "WC AFRICA",	l: "NIGERIA" },
            { n: "WC ITALY",	l: "ITALY" },
            { n: "WC LOCK 1",	l: "CANADA" },
            { n: "WC LOCK 2",	l: "CANADA" },
            { n: "WC LOCK 3",	l: "CANADA" },
            { n: "WC LOCK 4E",	l: "CANADA" },
            { n: "WC LOCK 4W",	l: "CANADA" },
            { n: "WC LOCK 5E",	l: "CANADA" },
            { n: "WC LOCK 5W",	l: "CANADA" },
            { n: "WC LOCK 6E",	l: "CANADA" },
            { n: "WC LOCK 6W",	l: "CANADA" },
            { n: "WC LOCK 7",	l: "CANADA" },
            { n: "WC LOCK 8",	l: "CANADA" },
            { n: "WC MEXICO",	l: "MEXICO" },
            { n: "WCCA",	l: "COSTA RICA" },
            { n: "WEASTE",	l: "UNITED KINGDOM" },
            { n: "WEDA",	l: "INDONESIA" },
            { n: "WEDEL",	l: "GERMANY" },
            { n: "WEEDON ISLAND",	l: "UNITED STATES" },
            { n: "WEI HAI",	l: "CHINA" },
            { n: "WEIFANG",	l: "CHINA" },
            { n: "WEIPA",	l: "AUSTRALIA" },
            { n: "WEITANG",	l: "CHINA" },
            { n: "WEIZHOU MARINE TERMINAL",	l: "CHINA" },
            { n: "WELLAND",	l: "CANADA" },
            { n: "WELLAND CANAL",	l: "CANADA" },
            { n: "WELLINGTON",	l: "NEW ZEALAND" },
            { n: "WELLS",	l: "UNITED KINGDOM" },
            { n: "WEMYSS BAY",	l: "UNITED KINGDOM" },
            { n: "WENCHANG",	l: "CHINA" },
            { n: "WENZHOU",	l: "CHINA" },
            { n: "WESERPORT",	l: "GERMANY" },
            { n: "WEST INDIES (COUNTRY)",	l: "ANGUILLA" },
            { n: "WEST LINAPACAN",	l: "PHILIPPINES" },
            { n: "WEST MEMPHIS",	l: "UNITED STATES" },
            { n: "WEST PEARL END",	l: "UNITED STATES" },
            { n: "WEST PEARL START",	l: "UNITED STATES" },
            { n: "WEST THURROCK",	l: "UNITED KINGDOM" },
            { n: "WESTERN POINT",	l: "YEMEN" },
            { n: "WESTERN PORT",	l: "AUSTRALIA" },
            { n: "WESTERN SAHARA (COUNTRY)",	l: "WESTERN SAHARA" },
            { n: "WESTERN SAMOA (COUNTRY)",	l: "WESTERN SAMOA" },
            { n: "WESTERNPORT",	l: "AUSTRALIA" },
            { n: "WESTON POINT",	l: "UNITED KINGDOM" },
            { n: "WESTPORT (IRELAND)",	l: "IRELAND" },
            { n: "WESTPORT (MALAYSIA)",	l: "MALAYSIA" },
            { n: "WESTPORT (NEW ZEALAND)",	l: "NEW ZEALAND" },
            { n: "WESTVILLE",	l: "UNITED STATES" },
            { n: "WESTWEGO",	l: "UNITED STATES" },
            { n: "WETAR STRAIT",	l: "TIMOR-LESTE" },
            { n: "WETE WETE",	l: "TANZANIA" },
            { n: "WEWAK",	l: "PAPUA NEW GUINEA" },
            { n: "WEYMOUTH (CANADA)",	l: "CANADA" },
            { n: "WEYMOUTH (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "WHAKAAROPAI TERMINAL",	l: "NEW ZEALAND" },
            { n: "WHALE COVE, NU",	l: "CANADA" },
            { n: "WHANGAREI",	l: "NEW ZEALAND" },
            { n: "WHIDDY ISLAND",	l: "IRELAND" },
            { n: "WHIFFEN HEAD",	l: "CANADA" },
            { n: "WHITBY",	l: "UNITED KINGDOM" },
            { n: "WHITE BAY",	l: "AUSTRALIA" },
            { n: "WHITE END",	l: "UNITED STATES" },
            { n: "WHITE START",	l: "UNITED STATES" },
            { n: "WHITEFISH POINT",	l: "UNITED STATES" },
            { n: "WHITEGATE",	l: "IRELAND" },
            { n: "WHITEHAVEN",	l: "UNITED KINGDOM" },
            { n: "WHITING, IN",	l: "UNITED STATES" },
            { n: "WHITMAN COUNTY",	l: "UNITED STATES" },
            { n: "WHITSTABLE",	l: "UNITED KINGDOM" },
            { n: "WHITSUNDAYS",	l: "AUSTRALIA" },
            { n: "WHITTIER",	l: "UNITED STATES" },
            { n: "WHYALLA",	l: "AUSTRALIA" },
            { n: "WIBORG",	l: "RUSSIA" },
            { n: "WICK",	l: "UNITED KINGDOM" },
            { n: "WICKHAM'S CAY",	l: "BRITISH VIRGIN ISLANDS" },
            { n: "WICKLOW",	l: "IRELAND" },
            { n: "WIDURI MARINE TERMINAL",	l: "INDONESIA" },
            { n: "WILHELMSHAVEN",	l: "GERMANY" },
            { n: "WILLEBROEK",	l: "BELGIUM" },
            { n: "WILLEMSTAD",	l: "CURACAO" },
            { n: "WILLIS ISLET",	l: "AUSTRALIA" },
            { n: "WILLOW SPRINGS",	l: "UNITED STATES" },
            { n: "WILMA",	l: "UNITED STATES" },
            { n: "WILMINGTON (USA-CALIFORNIA)",	l: "UNITED STATES" },
            { n: "WILMINGTON (USA-DELAWARE)",	l: "UNITED STATES" },
            { n: "WILMINGTON (USA-N CAROLINA)",	l: "UNITED STATES" },
            { n: "WILMINGTON NC",	l: "UNITED STATES" },
            { n: "WILSONS PROMONTORY",	l: "AUSTRALIA" },
            { n: "WINDAU",	l: "LATVIA" },
            { n: "WINDSOR",	l: "CANADA" },
            { n: "WINDWARD PASSAGE",	l: "CUBA" },
            { n: "WINTER INDIAN OCEAN",	l: "INDIA" },
            { n: "WINTER NORTH ATLANTIC",	l: "UNITED STATES" },
            { n: "WINTER NORTH PACIFIC",	l: "RUSSIA" },
            { n: "WINTER SOUTH ATLANTIC",	l: "ARGENTINA" },
            { n: "WINTER SOUTH PACIFIC",	l: "NEW ZEALAND" },
            { n: "WINTERPORT",	l: "UNITED STATES" },
            { n: "WISBECH",	l: "UNITED KINGDOM" },
            { n: "WISCASSET",	l: "UNITED STATES" },
            { n: "WISE BAY",	l: "CANADA" },
            { n: "WISMAR",	l: "GERMANY" },
            { n: "WITHNELL BAY",	l: "AUSTRALIA" },
            { n: "WIVENHOE",	l: "UNITED KINGDOM" },
            { n: "WLADYSLAWOWO",	l: "POLAND" },
            { n: "WOLGAST",	l: "GERMANY" },
            { n: "WOLLONGONG",	l: "AUSTRALIA" },
            { n: "WONSAN",	l: "KOREA, DEMOCRATIC REPUBLIC" },
            { n: "WOOD RIVER, IL",	l: "UNITED STATES" },
            { n: "WOODBRIDGE (NEW YORK)",	l: "UNITED STATES" },
            { n: "WOODBRIDGE BAY",	l: "DOMINICA" },
            { n: "WOODCHIPPER ISLAND",	l: "MAURITIUS" },
            { n: "WOODFIBRE",	l: "CANADA" },
            { n: "WOODLANDS",	l: "SINGAPORE" },
            { n: "WOOLWICH",	l: "UNITED KINGDOM" },
            { n: "WORKING AREA 85",	l: "TURKMENISTAN" },
            { n: "WORKINGTON",	l: "UNITED KINGDOM" },
            { n: "WORLDWIDE",	l: "NO COUNTRY" },
            { n: "WP 200 NM OFF NIGERIA",	l: "NIGERIA" },
            { n: "WP EAST OF AGALEGA ISLANDS",	l: "MAURITIUS" },
            { n: "WP EAST OF TOLAGNARO",	l: "MADAGASCAR" },
            { n: "WP NORTH EAST SEYCHELLES",	l: "SEYCHELLES" },
            { n: "WP RAS AL HADD",	l: "OMAN" },
            { n: "WP SAN VICENTE",	l: "PORTUGAL" },
            { n: "WP SOUTH EAST SEYCHELLES",	l: "SEYCHELLES" },
            { n: "WP SOUTH OF MADAGASCAR",	l: "MADAGASCAR" },
            { n: "WP WEST OF TROMELIN ISLANDS",	l: "FRANCE" },
            { n: "WRANGELL",	l: "UNITED STATES" },
            { n: "WRIGHTSVILLE NC",	l: "UNITED STATES" },
            { n: "WUHAN",	l: "CHINA" },
            { n: "WUHU",	l: "CHINA" },
            { n: "WUJIANG",	l: "CHINA" },
            { n: "WUMUTAN",	l: "CHINA" },
            { n: "WUTONG",	l: "CHINA" },
            { n: "WUXUE",	l: "CHINA" },
            { n: "WUZHOU",	l: "CHINA" },
            { n: "WYANDOTTE",	l: "UNITED STATES" },
            { n: "WYBURG",	l: "RUSSIA" },
            { n: "WYNDHAM",	l: "AUSTRALIA" },
            { n: "XEROS",	l: "CYPRUS" },
            { n: "XIABAISHI",	l: "CHINA" },
            { n: "XIAGANG",	l: "CHINA" },
            { n: "XIAHAI",	l: "CHINA" },
            { n: "XIAMEN",	l: "CHINA" },
            { n: "XIANGFAN",	l: "CHINA" },
            { n: "XIANGLUJIAO",	l: "CHINA" },
            { n: "XIANGTAN",	l: "CHINA" },
            { n: "XIAO HU DAO",	l: "CHINA" },
            { n: "XIAOCUO TERMINAL",	l: "CHINA" },
            { n: "XIAOPU",	l: "CHINA" },
            { n: "XIAOSHAN",	l: "CHINA" },
            { n: "XIAOSHANGANG",	l: "CHINA" },
            { n: "XIASHI",	l: "CHINA" },
            { n: "XIDI",	l: "CHINA" },
            { n: "XIEJIAWA",	l: "CHINA" },
            { n: "XIEWANG",	l: "CHINA" },
            { n: "XIGANG",	l: "CHINA" },
            { n: "XIHUQIAO",	l: "CHINA" },
            { n: "XIJIANG MARINE TERMINAL",	l: "CHINA" },
            { n: "XINCHANG",	l: "CHINA" },
            { n: "XINGANG",	l: "CHINA" },
            { n: "XINGANG (ANHUI PROVINCE)",	l: "CHINA" },
            { n: "XINGANG (GUANGDONG PROVINCE)",	l: "CHINA" },
            { n: "XINGHUA",	l: "CHINA" },
            { n: "XINHE",	l: "CHINA" },
            { n: "XINHUI",	l: "CHINA" },
            { n: "XINJIAN",	l: "CHINA" },
            { n: "XINQIAOHE",	l: "CHINA" },
            { n: "XINSHA",	l: "CHINA" },
            { n: "XINSHENWEI",	l: "CHINA" },
            { n: "XINSHI",	l: "CHINA" },
            { n: "XIONGJIAGOU",	l: "CHINA" },
            { n: "XITANG",	l: "CHINA" },
            { n: "XIUYU",	l: "CHINA" },
            { n: "XUZHOU",	l: "CHINA" },
            { n: "YABUCOA",	l: "PUERTO RICO" },
            { n: "YAFO",	l: "ISRAEL" },
            { n: "YAHATA",	l: "JAPAN" },
            { n: "YAKUTAT",	l: "UNITED STATES" },
            { n: "YALI ISLAND",	l: "GREECE" },
            { n: "YALOVA",	l: "TURKEY" },
            { n: "YALTA",	l: "UKRAINE" },
            { n: "YAMADA",	l: "JAPAN" },
            { n: "YAMATA",	l: "JAPAN" },
            { n: "YAMBA",	l: "AUSTRALIA" },
            { n: "YAMPI SOUND",	l: "AUSTRALIA" },
            { n: "YANAI",	l: "JAPAN" },
            { n: "YANBU",	l: "SAUDI ARABIA" },
            { n: "YANCHENG",	l: "CHINA" },
            { n: "YANDINA",	l: "SOLOMON ISLANDS" },
            { n: "YANGJIANG",	l: "CHINA" },
            { n: "YANGJING",	l: "CHINA" },
            { n: "YANGON",	l: "MYANMAR" },
            { n: "YANGPU",	l: "CHINA" },
            { n: "YANGSHAN",	l: "CHINA" },
            { n: "YANGWAN",	l: "CHINA" },
            { n: "YANGZHOU",	l: "CHINA" },
            { n: "YANTAI",	l: "CHINA" },
            { n: "YANTIAN",	l: "CHINA" },
            { n: "YAOPODU",	l: "CHINA" },
            { n: "YAP ISLAND",	l: "MICRONESIA" },
            { n: "YARIMCA",	l: "TURKEY" },
            { n: "YARMOUTH (CANADA)",	l: "CANADA" },
            { n: "YARMOUTH (MAINE)",	l: "UNITED STATES" },
            { n: "YARMOUTH (UNITED KINGDOM)",	l: "UNITED KINGDOM" },
            { n: "YATSUSHIRO",	l: "JAPAN" },
            { n: "YAU MA TEI",	l: "HONG KONG" },
            { n: "YAWATA",	l: "JAPAN" },
            { n: "YAWATAHAMA",	l: "JAPAN" },
            { n: "YAZOO END",	l: "UNITED STATES" },
            { n: "YAZOO START",	l: "UNITED STATES" },
            { n: "YAZUI",	l: "CHINA" },
            { n: "YEISK",	l: "RUSSIA" },
            { n: "YEMEN (COUNTRY)",	l: "YEMEN" },
            { n: "YENIKOY",	l: "TURKEY" },
            { n: "YEOSU",	l: "KOREA, REPUBLIC" },
            { n: "YERAKINI",	l: "GREECE" },
            { n: "YESILOVACIK",	l: "TURKEY" },
            { n: "YESILOVACIK PORT",	l: "TURKEY" },
            { n: "YETAGUN FSO",	l: "MYANMAR" },
            { n: "YETAGUN MARINE TERMINAL",	l: "MYANMAR" },
            { n: "YEYSK",	l: "RUSSIA" },
            { n: "YIBIN",	l: "CHINA" },
            { n: "YICHANG",	l: "CHINA" },
            { n: "YICHENG",	l: "CHINA" },
            { n: "YIJIAWAN",	l: "CHINA" },
            { n: "YINGKOU",	l: "CHINA" },
            { n: "YIXING",	l: "CHINA" },
            { n: "YIYANG",	l: "CHINA" },
            { n: "YIZHENG",	l: "CHINA" },
            { n: "YKSPIHLAJA",	l: "FINLAND" },
            { n: "YMUIDEN",	l: "NETHERLANDS" },
            { n: "YOHO TERMINAL",	l: "NIGERIA" },
            { n: "YOKKAICHI",	l: "JAPAN" },
            { n: "YOKOHAMA",	l: "JAPAN" },
            { n: "YOKOSE",	l: "JAPAN" },
            { n: "YOKOSHIMA",	l: "JAPAN" },
            { n: "YOKOSUKA",	l: "JAPAN" },
            { n: "YOMBO",	l: "CONGO" },
            { n: "YONGINMEN",	l: "CHINA" },
            { n: "YONGLI",	l: "CHINA" },
            { n: "YONKERS",	l: "UNITED STATES" },
            { n: "YORKTOWN",	l: "UNITED STATES" },
            { n: "YOSU",	l: "KOREA, REPUBLIC" },
            { n: "YOUDUN",	l: "CHINA" },
            { n: "YOUGHAL",	l: "IRELAND" },
            { n: "YOUJI",	l: "CHINA" },
            { n: "YOUNGHUNG",	l: "KOREA, REPUBLIC" },
            { n: "YSTAD",	l: "SWEDEN" },
            { n: "YUANJIANG",	l: "CHINA" },
            { n: "YUCATAN CHANNEL",	l: "UNITED STATES" },
            { n: "YUEYANG",	l: "CHINA" },
            { n: "YUHUAN",	l: "CHINA" },
            { n: "YUKALPETEN",	l: "MEXICO" },
            { n: "YUKON HARBOR",	l: "UNITED STATES" },
            { n: "YUMURTALIK BAY",	l: "TURKEY" },
            { n: "YUN-AN LPG TERMINAL",	l: "TAIWAN" },
            { n: "YUNG AN LNG TERMINAL",	l: "TAIWAN" },
            { n: "YURA",	l: "JAPAN" },
            { n: "YUUM KAK NAAB",	l: "MEXICO" },
            { n: "YUZHNO-KURILSK",	l: "RUSSIA" },
            { n: "YUZHNYY",	l: "UKRAINE" },
            { n: "ZAAFARANA TERMINAL",	l: "EGYPT" },
            { n: "ZAANDAM",	l: "NETHERLANDS" },
            { n: "ZAANSTAD",	l: "NETHERLANDS" },
            { n: "ZADAR",	l: "CROATIA" },
            { n: "ZAFIRO MARINE TERMINAL",	l: "EQUATORIAL GUINEA" },
            { n: "ZAHRANI TERMINAL",	l: "LEBANON" },
            { n: "ZAIRE ESTUARY",	l: "CONGO, DEMOCRATIC REPUBLIC" },
            { n: "ZAKINTHOS",	l: "GREECE" },
            { n: "ZAMBALES",	l: "PHILIPPINES" },
            { n: "ZAMBALES",	l: "PHILIPPINES" },
            { n: "ZAMBOANGA",	l: "PHILIPPINES" },
            { n: "ZANTE",	l: "GREECE" },
            { n: "ZANZIBAR CITY",	l: "TANZANIA" },
            { n: "ZARATE",	l: "ARGENTINA" },
            { n: "ZARUBINO",	l: "RUSSIA" },
            { n: "ZARZIS",	l: "TUNISIA" },
            { n: "ZASSNITZ",	l: "GERMANY" },
            { n: "ZAWIA TERMINAL",	l: "LIBYA" },
            { n: "ZEA",	l: "GREECE" },
            { n: "ZEA ISLAND",	l: "GREECE" },
            { n: "ZEBALLOS",	l: "CANADA" },
            { n: "ZEEBRUGGE",	l: "BELGIUM" },
            { n: "ZEIT BAY TERMINAL",	l: "EGYPT" },
            { n: "ZELENIKA",	l: "MONTENEGRO" },
            { n: "ZELZATE",	l: "BELGIUM" },
            { n: "ZHANGJIABU",	l: "CHINA" },
            { n: "ZHANGJIAGANG",	l: "CHINA" },
            { n: "ZHANGYAN",	l: "CHINA" },
            { n: "ZHANGZHOU",	l: "CHINA" },
            { n: "ZHANGZHU",	l: "CHINA" },
            { n: "ZHANJIANG",	l: "CHINA" },
            { n: "ZHAOQING",	l: "CHINA" },
            { n: "ZHAOXIANG",	l: "CHINA" },
            { n: "ZHAPU",	l: "CHINA" },
            { n: "ZHDANOV",	l: "UKRAINE" },
            { n: "ZHENGYANGHE",	l: "CHINA" },
            { n: "ZHENHAI",	l: "CHINA" },
            { n: "ZHENJIANG",	l: "CHINA" },
            { n: "ZHENJIANG",	l: "CHINA" },
            { n: "ZHENJIANG",	l: "CHINA" },
            { n: "ZHICHENG",	l: "CHINA" },
            { n: "ZHICHENG (ZHEJIANG PROVINCE)",	l: "CHINA" },
            { n: "ZHONGGANG",	l: "CHINA" },
            { n: "ZHONGSHAN",	l: "CHINA" },
            { n: "ZHOUPU (SHANGHAI)",	l: "CHINA" },
            { n: "ZHOUPU (ZHENJIANG PROVINCE)",	l: "CHINA" },
            { n: "ZHOUSHAN",	l: "CHINA" },
            { n: "ZHOUWEI",	l: "CHINA" },
            { n: "ZHUHAI",	l: "CHINA" },
            { n: "ZHUJIAJIAO",	l: "CHINA" },
            { n: "ZHUJING",	l: "CHINA" },
            { n: "ZHUZHOU",	l: "CHINA" },
            { n: "ZIERIKZEE",	l: "NETHERLANDS" },
            { n: "ZIGUINCHOR",	l: "SENEGAL" },
            { n: "ZIHUATANEJO",	l: "MEXICO" },
            { n: "ZILWAUKEE",	l: "UNITED STATES" },
            { n: "ZIMNICEA",	l: "ROMANIA" },
            { n: "ZIRKU ISLAND",	l: "UNITED ARAB EMIRATES" },
            { n: "ZONA COMUN",	l: "ARGENTINA" },
            { n: "ZONGULDAK",	l: "TURKEY" },
            { n: "ZORROZA",	l: "SPAIN" },
            { n: "ZOUK PORT",	l: "LEBANON" },
            { n: "ZOUK TERMINAL",	l: "LEBANON" },
            { n: "ZUARA",	l: "LIBYA" },
            { n: "ZUEITINA",	l: "LIBYA" },
            { n: "ZULUF",	l: "SAUDI ARABIA" },
            { n: "ZVEZDA SHIPYARD (BOLSHOY KAMEN)",	l: "RUSSIA" },
            { n: "ZWOLLE",	l: "NETHERLANDS" }
        ];
    return {
        getPorts: function() {
            return allPorts;
        }
    }
});