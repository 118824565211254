import moment from 'moment';
import Highcharts from 'highcharts';
import { routerApp, baseUrl, roundToPlaces, environmentEnabled } from '../../app.module';
import { CIIRatingClass } from '../services/fleetPerformanceGraph.service'

routerApp.factory('VoyagePlanService', ['$http',function($http) {
    var voyagePlanAPI = baseUrl + 'voyage_plans/';
    return {
        getVoyagePlan: function(voyagePlanId) {
            return $http({
                url: voyagePlanAPI + voyagePlanId,
                method: 'GET'
            });
        },
        updateVoyagePlan: function(vesselId, voyagePlanId, currentVoyage, simulatedSpeed, shouldSimulate, meanDraught, saveVoyageAfterSimulating) {
            return $http({
                url: voyagePlanAPI + vesselId,
                method: 'POST',
                data: {
                    meanDraught: meanDraught,
                    shouldSimulate: shouldSimulate,
                    voyagePlanId: voyagePlanId,
                    currentVoyage: currentVoyage,
                    simulatedSpeed: simulatedSpeed,
                    saveVoyage: saveVoyageAfterSimulating
                }
            });
        },
        getRoute: function(vesselId, departurePort, arrivalPort, draught, avoidSeca, avoidPiracy, departurePortLat, departurePortLong, arrivalPortLat, arrivalPortLong, blockedAreas) {
            return $http({
                url: baseUrl + 'voyage_plans/routes',
                method: 'GET',
                params: {
                    departurePort: departurePort,
                    arrivalPort: arrivalPort,
                    avoidSeca: avoidSeca,
                    avoidPiracy: avoidPiracy,
                    draught: draught,
                    vesselId: vesselId,
                    departurePortLat: departurePortLat,
                    departurePortLong: departurePortLong,
                    arrivalPortLat: arrivalPortLat,
                    arrivalPortLong: arrivalPortLong,
                    blockedAreas: blockedAreas.join(','),
                }
            })
        },

        getVoyagePlans: function(vesselId) {
            return $http({
                url: baseUrl + 'voyage_plans/vessels/' + vesselId,
                method: 'GET'
            });
        },

        deleteVoyagePlan: function(voyagePlanId) {
            return $http({
                url: voyagePlanAPI + voyagePlanId,
                method: 'DELETE'
            });
        },

        getVoyagePlanPredictionMetric: function(vesselId, voyagePlanId) {
            var url = voyagePlanAPI + vesselId + '/prediction_metric';
            if (voyagePlanId) { url += '?voyagePlanId=' + voyagePlanId; }
            return $http({
                url: url,
                method: 'GET'
            })
        }
    }
}]);
var map = null;
routerApp.controller('voyagePlanController', [ '$rootScope', '$scope', '$http', 'VoyagePlanService', 'PortService', 'notify', 'UtilityService',function($rootScope, $scope, $http, VoyagePlanService, PortService, notify, UtilityService) {

    $scope.warningMessages = {};
    var _dateFormat = 'DD/MM/YYYY HH:mm';
    var formatMoment = function(date) {
        if (date != undefined && date._isAMomentObject) {
            return date.format(_dateFormat);
        } else {
            return date;
        }
    }

    $scope.ports = PortService.getPorts();
    $rootScope.selectedMenu = 'voyage-plan';
    $scope.speeds = [
        {
            name: 'Eco Speed',
            speed: 5,
        },
        {
            name: 'Full Speed',
            speed: 10,
        },
        {
            name: 'Slow Steaming',
            speed: 12,
        },
        {
            name: 'Manual Speed',
            speed: null
        }
    ];

    $scope.getVoyagePlans = function() {
        VoyagePlanService.getVoyagePlans($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data.data;
            $scope.voyagePlans = data.voyagePlans;
        });
    }

    $scope.getVoyagePlans();

    $scope.getVoyagePlan = function(voyagePlanId) {
        $scope.loadingVoyagePlan = true;
        VoyagePlanService.getVoyagePlan(voyagePlanId).then(function(response) {
            var data = response.data.data;
            var currentVoyage = data.voyage;
            currentVoyage.eta = currentVoyage.eta && formatMoment(moment.utc(currentVoyage.eta));
            currentVoyage.etd = currentVoyage.etd && formatMoment(moment.utc(currentVoyage.etd));
            currentVoyage.laycan_date = currentVoyage.laycan_date && formatMoment(moment.utc(currentVoyage.laycan_date));
            currentVoyage.created_on = formatMoment(moment(currentVoyage.created_on));
            if (currentVoyage.eco_speed_simulation && currentVoyage.eco_speed_simulation.eta) { currentVoyage.eco_speed_simulation.eta = formatMoment(moment.utc(currentVoyage.eco_speed_simulation.eta)) }
            if (currentVoyage.full_speed_simulation && currentVoyage.full_speed_simulation.eta) { currentVoyage.full_speed_simulation.eta = formatMoment(moment.utc(currentVoyage.full_speed_simulation.eta)) }
            if (currentVoyage.slow_steaming_simulation && currentVoyage.slow_steaming_simulation.eta) { currentVoyage.slow_steaming_simulation.eta = formatMoment(moment.utc(currentVoyage.slow_steaming_simulation.eta)) }
            if (currentVoyage.manual_simulation && currentVoyage.manual_simulation.eta) { currentVoyage.manual_simulation.eta = formatMoment(moment.utc(currentVoyage.manual_simulation.eta)) }
            $scope.currentVoyage = currentVoyage;
            $scope.simulatedSpeed = currentVoyage.manual_simulation && currentVoyage.manual_simulation.speed;
            $scope.currentVoyage.prediction_metric = $scope.predictionAccuracySeries || currentVoyage.prediction_metric;
        });
    }

    $scope.saveVoyage = function() {
        $scope.simulateVoyage(true);
    }
    $scope.deleteCurrentVoyagePlan = function() {
        VoyagePlanService.deleteVoyagePlan($scope.currentVoyage._id).then(function(){ 
            $scope.createNewVoyagePlan();
            // refresh list of voyage plans
            $scope.getVoyagePlans();
        });
    }
    $scope.simulateVoyage = function(saveVoyageAfterSimulating) {
        if ($scope.currentVoyage != undefined) {
            if (saveVoyageAfterSimulating) {
                notify({message: 'Your voyage plan is being processed.', duration: 2000, classes: ['warning-notification']});
            }
            var firstSave = $scope.currentVoyage._id == undefined;
            VoyagePlanService.updateVoyagePlan($rootScope.selectedVessel.id, $scope.currentVoyage._id, $scope.currentVoyage, $scope.simulatedSpeed, $scope.voyagePlanForm && $scope.voyagePlanForm.$valid, $scope.currentVoyage.mean_draught, saveVoyageAfterSimulating).then(function(response) {
                var data = response.data;
                if (saveVoyageAfterSimulating && firstSave) {
                    $scope.voyagePlans.push($scope.currentVoyage);
                }
                if (data) {
                    var newVoyagePlan = data.data.newVoyagePlan;
                    if (newVoyagePlan._id) {
                        $scope.currentVoyage._id = newVoyagePlan._id;
                    }
                    // parse dates 
                    if (newVoyagePlan.eco_speed_simulation) {
                        newVoyagePlan.eco_speed_simulation.eta = formatMoment(moment.utc(newVoyagePlan.eco_speed_simulation.eta));
                        newVoyagePlan.eco_speed_simulation.voyage_end = formatMoment(moment.utc(newVoyagePlan.eco_speed_simulation.voyage_end));
                        newVoyagePlan.eco_speed_simulation.rating_class = newVoyagePlan.eco_speed_simulation.cii_rating ? CIIRatingClass[newVoyagePlan.eco_speed_simulation.cii_rating] : null;
                    }
                    if (newVoyagePlan.full_speed_simulation) {
                        newVoyagePlan.full_speed_simulation.eta = formatMoment(moment.utc(newVoyagePlan.full_speed_simulation.eta));
                        newVoyagePlan.full_speed_simulation.voyage_end = formatMoment(moment.utc(newVoyagePlan.full_speed_simulation.voyage_end));
                        newVoyagePlan.full_speed_simulation.rating_class = newVoyagePlan.full_speed_simulation.cii_rating ? CIIRatingClass[newVoyagePlan.full_speed_simulation.cii_rating] : null;
                    }
                    if (newVoyagePlan.slow_steaming_simulation) {
                        newVoyagePlan.slow_steaming_simulation.eta = formatMoment(moment.utc(newVoyagePlan.slow_steaming_simulation.eta));
                        newVoyagePlan.slow_steaming_simulation.voyage_end = formatMoment(moment.utc(newVoyagePlan.slow_steaming_simulation.voyage_end));
                        newVoyagePlan.slow_steaming_simulation.rating_class = newVoyagePlan.slow_steaming_simulation.cii_rating ? CIIRatingClass[newVoyagePlan.slow_steaming_simulation.cii_rating] : null;
                    }
                    if (newVoyagePlan.manual_simulation && newVoyagePlan.manual_simulation.eta) {
                        newVoyagePlan.manual_simulation.eta = formatMoment(moment.utc(newVoyagePlan.manual_simulation.eta));
                        newVoyagePlan.manual_simulation.voyage_end = formatMoment(moment.utc(newVoyagePlan.manual_simulation.voyage_end));
                        newVoyagePlan.manual_simulation.rating_class = newVoyagePlan.manual_simulation.cii_rating ? CIIRatingClass[newVoyagePlan.manual_simulation.cii_rating] : null;
                    }
                    // set speed simulations
                    $scope.currentVoyage.eco_speed_simulation = newVoyagePlan.eco_speed_simulation;
                    $scope.currentVoyage.full_speed_simulation = newVoyagePlan.full_speed_simulation;
                    $scope.currentVoyage.slow_steaming_simulation = newVoyagePlan.slow_steaming_simulation;
                    $scope.currentVoyage.manual_simulation = newVoyagePlan.manual_simulation;
                }
                if (saveVoyageAfterSimulating) {
                    notify({message: 'Succesfully updated voyage plan details!', duration: 2000, classes: ['ok-notification']});
                    $scope.getVoyagePlans();
                }
            }, function(response) {
                if (saveVoyageAfterSimulating) {
                    notify({message: 'Error updating voyage plan, please try again later.', duration: 2000, classes: ['bad-notification']});
                }
                
            });
        }   
    }
     
    // add validation for departure > today
    $scope.$watch('currentVoyage.etd', function(newValue, oldValue, scope) {
        var departureDate = moment(newValue, _dateFormat);
        // var todayDate = moment();
        var valid = true;
        // disable voyage etd after current day validation per Jacob request until we want to re-enable it again
        // if (departureDate && departureDate.isValid() && todayDate && todayDate.isValid() && scope.currentVoyage.status != 'in-use') {
        //     valid = todayDate < departureDate;
        // }
        if (departureDate && departureDate.isValid()) {
            scope.voyagePlanForm.departureDate.$setDirty();
        }
        if (scope.voyagePlanForm && scope.voyagePlanForm.arrivalDate) scope.voyagePlanForm.departureDate.$setValidity('after_today', valid);
    });

    // add validation for arrival > departure
    $scope.$watchGroup(['currentVoyage.etd', 'currentVoyage.eta'], function(newValues, oldValues, scope) {
        var departureDate = moment(newValues[0], _dateFormat);
        var arrivalDate = moment(newValues[1], _dateFormat);
        var valid = true;
        
        if (arrivalDate && arrivalDate.isValid() && departureDate && departureDate.isValid() && scope.currentVoyage.status != 'in-use') {
            valid = departureDate < arrivalDate;
        }
        if (arrivalDate && arrivalDate.isValid()) {
            scope.voyagePlanForm.arrivalDate.$setDirty();
        }
        if (scope.voyagePlanForm && scope.voyagePlanForm.arrivalDate) scope.voyagePlanForm.arrivalDate.$setValidity('after_departure', valid);
    });

    // add warning messages when laycan < arrival
    $scope.$watchGroup(['currentVoyage.laycan_date', 'currentVoyage.eta'], function(newValues, oldValues, scope) {
        var laycanDate = moment(newValues[0], _dateFormat);
        var arrivalDate = moment(newValues[1], _dateFormat);
        var valid = true;
        if (laycanDate && laycanDate.isValid()) {
            scope.voyagePlanForm.laycanDate.$setDirty();
        }
        if (arrivalDate && arrivalDate.isValid() && laycanDate && laycanDate.isValid()) {
            valid = laycanDate > arrivalDate;
        }
        if (valid) {
            $scope.warningMessages['laycan_date'] = null;
        } else {
            $scope.warningMessages['laycan_date'] = 'Laycan date is before arrival date.';
        }
    });

    $scope.$watch('currentVoyage.ballast_code', function(ballastCode, oldBallastCode, scope) {
        if (ballastCode == 'laden') {
            scope.voyagePlanForm.arrivalDate.$setValidity('after_departure', true);
        }
    });

    // when this variable is modified, send the entire report back for analysis
    $scope.$watchGroup([
        'simulatedSpeed',
        'currentVoyage.etd',
        'currentVoyage.eta',
        'currentVoyage.arrival_port',
        'currentVoyage.departure_port',
        'currentVoyage.laycan_date',
        'currentVoyage.mean_draught',
        'currentVoyage.total_distance',
        'currentVoyage.ballast_code',
        'currentVoyage.distance_in_eca',
        'currentVoyage.time_idle_non_eca',
        'currentVoyage.time_idle_eca',
        'currentVoyage.fuel_price_non_eca',
        'currentVoyage.fuel_price_eca',
        'currentVoyage.voyage_number',
    ], function(newValues, oldValues, scope) {
        if ($scope.voyagePlanForm && $scope.voyagePlanForm.simulatedSpeed && $scope.voyagePlanForm.simulatedSpeed.$invalid) {
            return;
        }
        $scope.simulateVoyage(false);
    });

    $scope.$watchGroup([
        'currentVoyage.departure_port',
        'currentVoyage.arrival_port',
        'currentVoyage.mean_draught',
        'currentVoyage.avoid_seca',
        'currentVoyage.avoid_piracy',
        'currentVoyage.departure_port_lat',
        'currentVoyage.departure_port_long',
        'currentVoyage.arrival_port_lat',
        'currentVoyage.arrival_port_long',
        'currentVoyage.blocked_areas',
    ], function(newValues, oldValues, scope) {
        var departurePort = newValues[0] || '';
        var arrivalPort = newValues[1] || '';
        var draught = newValues[2];
        var avoidSeca = newValues[3] || false;
        var avoidPiracy = newValues[4] || false;
        var departurePortLat = newValues[5] || '';
        var departurePortLong = newValues[6] || '';
        var arrivalPortLat = newValues[7] || '';
        var arrivalPortLong = newValues[8] || '';
        var blockedAreas = newValues[9] || [];
        if (departurePort != '' && arrivalPort != '') {
            VoyagePlanService.getRoute($rootScope.selectedVessel.id, departurePort, arrivalPort, draught, !!avoidSeca, avoidPiracy, departurePortLat, departurePortLong, arrivalPortLat, arrivalPortLong, blockedAreas)
            .then(function(response) {
                var routeData = response.data.data;
                var currentVoyage = $scope.currentVoyage;
                currentVoyage.total_distance = currentVoyage.total_distance && $scope.loadingVoyagePlan ? currentVoyage.total_distance : routeData.distance;
                currentVoyage.distance_in_eca = currentVoyage.distance_in_eca && $scope.loadingVoyagePlan ? currentVoyage.distance_in_eca : routeData.seca_distance;
                currentVoyage.coordinates = routeData.coordinates;
                currentVoyage.waypoints = routeData.waypoints;
                $scope.currentVoyage = currentVoyage;
                $scope.loadingVoyagePlan = false;
                // reset maps after each change in change
                if (map) {
                    var currentViewport = map.getViewport();
                    currentViewport.remove()

                    var mapTarget = map.getTarget(); 
                    var mapContainerParent = typeof mapTarget === "string" ? $("#" + mapTarget) : $(mapTarget);;
                    mapContainerParent.html('<div id="popover"></div>');
                }

                var planVoyageReportData = {};
                var voyageCoords = [];
                for (var i = 0; i < currentVoyage.coordinates.length; i++) {
                    voyageCoords.push({
                        'ship_lon': currentVoyage.coordinates[i]['geometry']['coordinates'][0],
                        'ship_lat': currentVoyage.coordinates[i]['geometry']['coordinates'][1],
                        'in_seca': currentVoyage.coordinates[i]['in_seca'],
                        'classification': currentVoyage.coordinates[i]['properties'] ? currentVoyage.coordinates[i]['properties']['class'] : null,
                        'label': currentVoyage.coordinates[i]['properties'] ? currentVoyage.coordinates[i]['properties']['value'] : null,
                    })
                }

                planVoyageReportData.coordinates = voyageCoords;

   
                $rootScope.drawMap(planVoyageReportData, {targetMapElementId: 'voyagePlanMap', labelElementId: 'popover' });
            })
        }
    })

    $scope.createNewVoyagePlan = function() {
        $scope.currentVoyage = {
            status: 'draft',
            created_by: $rootScope.username,
            created_on: formatMoment(moment()),
			time_idle_non_eca: 0,
			time_idle_eca: 0,
            fuel_price_non_eca: 425,
            fuel_price_eca: 475
        }
        $scope.simulatedSpeed = null;
        if ($scope.voyagePlanForm) {
            if ($scope.voyagePlanForm && $scope.voyagePlanForm.departureDate) $scope.voyagePlanForm.departureDate.$setPristine();
            if ($scope.voyagePlanForm && $scope.voyagePlanForm.arrivalDate) $scope.voyagePlanForm.arrivalDate.$setPristine();
        }
    }
    $scope.createNewVoyagePlan();

    $scope.getSpeedLabel = function(speed) {
        var speedToLabel = {
            eco_speed_simulation: 'Eco Speed',
            full_speed_simulation: 'Full Speed',
            slow_steaming_simulation: 'Slow Steaming',
            manual_simulation: 'Manual Speed'
        }
        return speedToLabel[speed] || '-';
    }

    $scope.markVoyageAsPlanned = function() {
        $scope.currentVoyage.status = 'in-use';
        $scope.saveVoyage();
    }

    $scope.predictionAccuracySeries = []

    var seaVoyages = []

    function getPredictionAccuracySeries(predictions) {
        var recentVoyageConsumptions = predictions;
        var predictionSeries = [];
        var actualSeries = []
        for (var i = 0; i < recentVoyageConsumptions.length; i++) {
            seaVoyages.push(recentVoyageConsumptions[i]['voyage_number'])
            predictionSeries.push({ y: recentVoyageConsumptions[i]['total_cons_predicted'], voyage_number: recentVoyageConsumptions[i]['voyage_number'], me_cons: recentVoyageConsumptions[i]['total_me_cons_predicted'] })
            actualSeries.push({ y: recentVoyageConsumptions[i]['total_fuel_consumption'], voyage_number: recentVoyageConsumptions[i]['voyage_number'], me_cons: recentVoyageConsumptions[i]['me_fuel_consumption'] })
        };

        return [{
            name: 'Total Cons Predicted',
            data: predictionSeries,
        },
        {
            name: 'Total Cons Actual',
            data: actualSeries
        }];
    }

    $scope.showChart = function() {
        var enable = environmentEnabled.toLowerCase();
        return enable == 'true';
    };

    if ($scope.showChart()){
        VoyagePlanService.getVoyagePlanPredictionMetric($rootScope.selectedVessel.id, $scope.currentVoyage._id).then(function(response) {
            
            var predictions = response.data.data.predictions;
            $scope.currentVoyage.prediction_metric = predictions;
            $scope.predictionAccuracySeries = predictions;

            $scope.drawPredictionAccuracyChart();
        });
    }

    function getDataLabel (predictedData, actualData) {

        var me_cons_actual = roundToPlaces(actualData.point.options.me_cons,1);
        var total_cons_actual = roundToPlaces(actualData.y,1);
        var me_cons_predicted = roundToPlaces(predictedData.point.options.me_cons, 1);
        var total_cons_predicted = roundToPlaces(predictedData.y, 1);
        var totalDiff = roundToPlaces(actualData.y - predictedData.y, 1);
        var totalPercentDiff = roundToPlaces((actualData.y - predictedData.y) / actualData.y * 100, 1);
    
        return '<table><tr><td padding:0">' + 'ME Cons Actual (MT)' + ': </td>' +
        '<td style="padding:0"><b>' + me_cons_actual.toFixed(1) + '</b></td></tr>' +
        '<tr><td padding:0">' + 'ME Cons Predicted (MT)' + ': </td>' +
        '<td style="padding:0"><b>' + me_cons_predicted.toFixed(1) + '</b></td></tr>' +
        '<tr><td padding:0">' + 'Total Cons Actual (MT)' + ': </td>' +
        '<td style="padding:0"><b>' + total_cons_actual.toFixed(1) + '</b></td></tr>' +
        '<tr><td padding:0">' + 'Total Cons Predicted (MT)' + ': </td>' +
        '<td style="padding:0"><b>' + total_cons_predicted.toFixed(1) + '</b></td></tr>' +
        '<tr><td padding:0">' + 'Total Difference (MT)' + ': </td>' +
        '<td style="padding:0"><b>' + totalDiff.toFixed(1) + '</b></td></tr>' +
        '<tr><td padding:0">' + 'Total Difference (%)' + ': </td>' +
        '<td style="padding:0"><b>' + totalPercentDiff.toFixed(1) + '</b></td></tr></table>';
    }

    $scope.drawPredictionAccuracyChart = function() {
        $scope.predictionAccuracyChart = Highcharts.chart('chart-prediction-accuracy', {
            chart: { type: 'bar' },
            title: { text: undefined },
            credits: { enabled: false },
            exporting: { enabled: false },
            tooltip: {
                formatter: function(){
                    return getDataLabel(this.points[0], this.points[1]);
                },
                shared: true,
                useHTML: true
            },
            xAxis: {
                categories: seaVoyages,
                title: { text: 'Tres Voyage ID' },
                labels: { enabled: true }
            },
            yAxis: {
                title: { text: 'Total Consumption (MT)' },
                min: 0,
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                x: 0,
                y: 0,
                floating: true,
                borderWidth: 1,
                shadow: true
            },
            plotOptions: {
                bar: { 
                    dataLabels: { 
                        enabled: true, 
                        style: { 
                            color: 'white', 
                            textOutline: '' 
                        } 
                    }, 
                    grouping: true 
                },
            },
            series: getPredictionAccuracySeries($scope.predictionAccuracySeries)
        });
    };

    $scope.sortBy = function(sortField) {
        $scope.reverse = ($scope.sortField === sortField) ? !$scope.reverse : false;
        $scope.sortField = sortField;
    };

    function downloalVoyagePlanExtract(response) {
        var url = window.URL.createObjectURL(new Blob([response.data], {type:'application/csv'}));
        var filename = "Voyage Plan - " + $rootScope.selectedVessel.name + '.csv';
        UtilityService.downloadData(url, filename);
    }

    $scope.extractVoyagePlan = function() {
        // used for downloading a voyage plan data extract, opens in same page
        var downloadUrl = baseUrl + 'voyage_plans/' + $scope.currentVoyage._id + '/extract';
        var options = {'responseType': 'blob'};
        $http.get(downloadUrl, options).then(downloalVoyagePlanExtract);
    }


}]);


