import routerApp from "../app.module";
import { getCacheBustedTemplate } from "../app.route";
import { HeatingOperation, HoseOperation, Hoses, IPortReport } from "../models/report";

type Operation = HoseOperation | HeatingOperation;

routerApp.directive('hoses', [function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/directives/hoses.html'),
        link: function(scope, _element, _attributes, _control) {
            let report: IPortReport = scope.report;
            if (!report.cargo.hoses) {
                report.cargo.hoses = new Hoses();
            }
            scope.report = report;
            scope.hose_on_operations = report.cargo.hoses.hose_on_operations;
            scope.hose_off_operations = report.cargo.hoses.hose_off_operations;
            scope.heating_operations = report.cargo.hoses.heating_operations;

            scope.addRow = (operations: HoseOperation[]) => {
                operations.push(new HoseOperation());
            };

            scope.addHeatingRow = () => {
                report.cargo.hoses.heating_operations.push(new HeatingOperation());
            }

            scope.deleteRow = (operations: Operation[], operation: Operation) => {
                let index = operations.indexOf(operation);
                operations.splice(index, 1);
            };
        }
    };
}]);
