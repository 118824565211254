import angular from 'angular';
import { routerApp } from '../app.module';
import { OperationType } from '../directives/tabs.directive';
import { generateBunkers } from '../utils/generate-bunkers';
import { generateConsumptions } from '../utils/generate-consumptions';

routerApp.controller('portReportController', ['$rootScope', '$scope', '$stateParams', '$http', '$timeout', 'ReportService', 'CargoService', 'notify' , 'VesselSpecificationService', function($rootScope, $scope, $stateParams, $http, $timeout, ReportService, CargoService, notify, VesselSpecificationService) {

    $rootScope.selectedLevels = ['vessel-reports', 'create-new-report', 'port-report'];

    if ($stateParams.report != undefined) {
        // if we came in here with a report parameter, we should use that report parameter
        // we should also use the 'viewReport' boolean to display or hide the needed report buttons
        $scope.report = $stateParams.report;
        $scope.viewReport = $stateParams.viewReport;

        if ($scope.viewReport) {
            $timeout(function() {
                $scope.$broadcast('validate-form', { tabs: ['operational', 'cargo', 'power', 'consumption', 'bunker', 'stock'] });
                $('.wrapper').scrollTop($stateParams.scrollLocation);
            }, 3000);
        }
    } else {
        // this just gets the newly edited report from the service, always gets it
        // as soon as this scope is initialized, so should assume that main controller
        // puts it there so that it's ready
        $scope.report = ReportService.getVesselReport();
        $scope.report._cls = "Report.PortReport";
        $scope.report.bunker.took_fuel_oil = true;
        $scope.report.bunker.reporting_fuel_type = true;
        // update service with report type
        ReportService.setVesselReport($scope.report);
        $rootScope.selectedLevels = ['vessel-reports', 'edit-report'];
    }

    $scope.$on('vessel-report-received', function() {
        if ($stateParams.viewReport == undefined || $stateParams.viewReport == false) {
            $scope.report = ReportService.getVesselReport();
            $scope.cargoes = CargoService.getCargoes($scope.selectedVessel);
            generateBunkers($scope.report);
            $scope.report.activeBDNFuels = ReportService.generateActiveBDNFromReport($scope.report);
            generateConsumptions($scope.report)
        }

        if ($scope.report.bdn_based_reporting) {
            $scope.enableBDNReporting($scope);
        }
    });

    // update average all fast draught values from all_fast_draught_fwd and all_fast_draught_aft
    $scope.$watchGroup(['report.operational.all_fast_draught_forward', 'report.operational.all_fast_draught_aft'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined && newValues[1] != undefined) {
            // update trim
            scope.report.operational.all_fast_trim = newValues[1] - newValues[0];
        }
    });

    // update average last line draught values from last_line_draught_fwd and last_line_draught_aft
    $scope.$watchGroup(['report.operational.last_line_draught_forward', 'report.operational.last_line_draught_aft'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined && newValues[1] != undefined) {
            // update trim
            scope.report.operational.last_line_trim = newValues[1] - newValues[0];
        }
    });

    $scope.report.cargo.bl_quantity_total = 0;
    $scope.$watch('report.cargo.bl_quantity', function(newValue, oldValue, scope) {
        var total = 0;
        for (var i = 0; i < newValue.length; i++) {
            if (!isNaN(newValue[i]) && newValue[i] != undefined) {
                total += newValue[i];
            }
        }
        scope.report.cargo.bl_quantity_total = total;
    }, true);

    $scope.cargoes = CargoService.getCargoes($scope.selectedVessel);

    $scope.initActiveTab = function() {
        if (['operational', 'cargo', 'power', 'consumptions', 'bunker', 'stock','cii'].indexOf($stateParams.activeTab) != -1) {
            return $stateParams.activeTab;
        } else if ($rootScope.selectedVessel.ciiReportingOnlyEnabled == true) {
            return 'cii';
        } else {
            return 'operational';
        }
    };

    var getReportFormScope = function() { return angular.element($("[name='reportForm']")).scope(); };

    $scope.$watchGroup(['report.cargo.operation_type', 'report.operational.finish', 'report.operational.cargo_documents_onboard', 'report.operational.start_discharging', 'report.operational.end_discharging', 'report.operational.commence'], function(newValues) {
        var operationType = newValues[0];

        if (!operationType) return;
        var loading = operationType == 'Loading';
        var discharging = operationType == 'Discharging';

        var reportForm = getReportFormScope().reportForm;
        var op = reportForm.operational;

        $scope.report.warningMessages['report.operational.commence'] = op.commence?.$$rawModelValue == undefined && loading ? 'Commence Load date should be reported' : null;
        $scope.report.warningMessages['report.operational.finish'] = op.finish?.$$rawModelValue == undefined && loading ? 'Finish Load date should be reported' : null;
        $scope.report.warningMessages['report.operational.cargo_documents_onboard'] = op.cargoDocs?.$$rawModelValue == undefined && loading ? 'Cargo Docs Onboard date should be reported' : null;

        $scope.report.warningMessages['report.operational.start_discharging'] = op.startDischarging?.$$rawModelValue == undefined && discharging ? 'Start Discharging date should be reported' : null;
        $scope.report.warningMessages['report.operational.end_discharging'] = op.endDischarging?.$$rawModelValue == undefined && discharging ? 'End Discharging date should be reported' : null;
    });

    $scope.$watch('report.cargo.operation_type', function(newValue, oldValue, scope) {
        
        if (newValue == OperationType.DRY_DOCK_REPAIR) {
            var reportForm = getReportFormScope().reportForm;
            if (reportForm['cargo'] != undefined) {
                $rootScope.disableTabValidation(reportForm, 'cargo')
            } else {
                // set delay because cargo reportForm isn't initialize immediately upon opening port report
                setTimeout(function() {
                    $rootScope.disableTabValidation(reportForm, 'cargo')
                }, 5000);
            }
            
        }
    })

    VesselSpecificationService.getVeslinkSuppliers($scope.selectedVessel).then((res) => {
        $scope.veslinkSuppliers = res.data.data;
    });
}]);
