import { routerApp, baseUrl } from '../app.module';
import angular from 'angular';

routerApp.factory('ManagementUsersService', ['$http', '$log', function($http: angular.IHttpService, $log: angular.ILogService) {
    return {
        getUsers: (clientId: string = null) => {
            return $http.get(`${baseUrl}v1/management/users/` + (clientId ? `?client_id=${clientId}` : ''));
        },
        getUser: (userId: string) => {
            return $http.get(`${baseUrl}v1/management/users/${userId}`);
        },
        createUser: (user: any) => {
            return $http.post(`${baseUrl}v1/management/users/`, user);
        },
        updateUser: (userId: string, user: any) => {
            return $http.put(`${baseUrl}v1/management/users/${userId}`, user);
        },
        deleteUser: (userId: string) => {
            return $http.delete(`${baseUrl}v1/management/users/${userId}`);
        }
    }
}]);