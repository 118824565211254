import { routerApp, baseUrl } from '../../app.module';
import moment from 'moment';

routerApp.controller('dataReportsController', ['$rootScope', '$scope', '$http', function($rootScope, $scope, $http) {
    
    $rootScope.selectedMenu = 'data-reports';

    $scope.selectAll = false;
    $scope.toggleAllVessels = function() {
        $scope.selectAll = !$scope.selectAll;
        if ($scope.selectAll) {
            $scope.selectAllVessels();
        } else {
            $scope.deselectAllVessels();
        }
    }

    // loads vessels to the vessel selection list so that they can be selected
    // for report generation
    $scope.loadVesselsCombo = function() {
        var reportVessels = [];
        if ($rootScope.all_vessels) {
            for (var i = 0; i < $rootScope.all_vessels.length; i++) {
                reportVessels.push({
                    name: $rootScope.all_vessels[i].name,
                    id: $rootScope.all_vessels[i].id
                });
            }
        }
        $scope.reportVessels = reportVessels;
    }
    $scope.loadVesselsCombo();
    $scope.$watch('vessels', function(newValues) {
        $scope.loadVesselsCombo();
    });

    // used to select all vessels in the vessel selection list
    $scope.selectAllVessels = function() {
        if ($scope.reportSettings == undefined) {
            $scope.reportSettings = {};
        }
        var vesselsList = [];
        for (var i = 0; i < $scope.reportVessels.length; i++) {
            vesselsList.push($scope.reportVessels[i].id);
        }
        $scope.reportSettings.vessels = vesselsList;
    }
    // used to deselect all vessels in the vessels selection list
    $scope.deselectAllVessels = function() {

        $scope.reportSettings.vessels = [];
    }

    $scope.requestReportExtract = function() {
        if ($scope.reportSettings && $scope.reportSettings.vessels
            && $scope.reportSettings.vessels.length > 0
            && (($scope.reportSettings.reportFrom && $scope.reportSettings.reportTo) || $scope.reportSettings.extractType == 'bunker_planning' || $scope.reportSettings.extractType == 'sea_cargo_charter' || $scope.reportSettings.extractType == 'sea_cargo_charter_debug')
            && $scope.reportSettings.email
        ) {
            var url = baseUrl + "extracts/vesselsExtract?username=" + $rootScope.username;

            $http({
                url: url,
                method: "POST",
                data: $scope.reportSettings
            }).then(function(response) {
                $("#reportModal").modal('show');
            });
        }

    }

    $scope.resetReportSettings = function() {
        $scope.reportSettings = {
            vessels: $scope.reportSettings.vessels,
            reportFrom: $scope.reportSettings.reportFrom,
            reportTo: $scope.reportSettings.reportTo
        }
    }

    // get user dropbox data
    $scope.dropboxDirectory = null;

    var dropboxFilesURL = baseUrl + "files/" + $rootScope.username;
    $http({
        url: dropboxFilesURL,
        method: "GET",
    }).then(function(response) {
        var responseObject = response.data.data;
        $scope.recentFiles = responseObject.recent_files;
        $scope.userFiles = responseObject.user_data;
        $scope.deliverables = responseObject.deliverables;
    });

    $scope.showUserDirectory = function(folderName) {
        $scope.dataReportTab = 'user-directory';
        $scope.files = $scope.dropboxDirectory[folderName];
    }

    $scope.displayFile = function(file) {
        $scope.fileName = file.name;
        var filePath = file.full_path;
        var fileDownloadUrl = baseUrl + "files/get_file";

        if (file.name.endsWith('.pdf')) {
            $("#filePreview").modal('show');

            $http({
                url: fileDownloadUrl,
                method: "POST",
                data: {
                    'path': filePath
                }
            }).then(function(response) {
                var data = response.data;
                var embed1 = document.getElementById('embed1');
                // @ts-ignore
                embed1.src = data.data + "&raw=1";
                $scope.fileFullPath = data.data;
            });
        } else {
            $http({
                url: fileDownloadUrl,
                method: "POST",
                data: {
                    'path': filePath,
                    'url_only': true
                }
            }).then(function(response) {
                var data = response.data;
                window.open(data.data);
            });
        }
    };

    $scope.downloadFile = function(file) {
        $scope.fileName = file.name;
        var filePath = file.full_path;
        var fileDownloadUrl = baseUrl + "files/get_file";

        $http({
            url: fileDownloadUrl,
            method: "POST",
            data: {
                'path': filePath,
                'url_only': true
            }
        }).then(function(response) {
            var data = response.data;
            window.open(data.data, '_self');
        });
    }

    var stringMatchesQuery = function(string, query) {
        string = string.toLowerCase();
        query = query.toLowerCase();
        var splits = query.split(' ');
        for (var i = 0; i < splits.length; i++) {
            if (string.indexOf(splits[i]) < 0) {
                return false;
            }
        }
        return true;
    }

    var recursiveSearch = function(tree, queryString) {
        var matchedElements = [];

        if (tree == undefined) {
            return matchedElements;
        }

        for (var i = 0; i < tree.length; i++) {
            var currentNode = tree[i];
            if (currentNode.last_modified && currentNode.name && stringMatchesQuery(currentNode.name, queryString)) {
                matchedElements.push(currentNode);
            } else {
                var subFiles = recursiveSearch(currentNode.files, queryString);
                matchedElements.push.apply(matchedElements, subFiles);
            }
        }
        return matchedElements;
    }
    $scope.recursiveSearch = recursiveSearch;
    $scope.searchResults = [];

    $scope.$watch('query.fileSearch', function(newValue, oldValue, scope) {
        if (newValue == undefined || newValue == '') {
            scope.searchResults = [];
        } else {
            scope.searchResults = recursiveSearch(scope.deliverables, newValue);
        }
    });

    $scope.searchMode = function() {
        return ($scope.query && $scope.query.fileSearch && $scope.query.fileSearch != '');
    }

    $scope.shouldShowVoyagePicker = function() {
        return $scope.reportSettings && ($scope.reportSettings.extractType == 'sea_cargo_charter' || $scope.reportSettings.extractType == 'sea_cargo_charter_debug')
            && $scope.reportSettings.seaCargoCharterExtractParamsType == 'byVoyageId'
    }

    $scope.showVoyagesLoadingSpinner = false;

    $scope.$watchGroup(['reportSettings.extractType', 'reportSettings.seaCargoCharterExtractParamsType', 'reportSettings.vessels'], function() {
        if ($scope.shouldShowVoyagePicker()) {
            let vesselIds = $scope.reportSettings.vessels;
            $scope.showVoyagesLoadingSpinner = true;
            $http.get(`${baseUrl}extracts/showExtractVoyageNumbers`, { params: { vessel_ids: vesselIds } })
                .then((res) => {
                    $scope.seaCargoCharterVoyages = res.data.data;
                    for (let voyage of $scope.seaCargoCharterVoyages) {
                        voyage.start_date = moment(voyage.start_date, 'DD/MM/YYYY HH:mm');
                        voyage.end_date = moment(voyage.end_date, 'DD/MM/YYYY HH:mm');
                        let vessel = $scope.reportVessels.find(v => v.id == voyage.vessel_id);
                        if (vessel) {
                            voyage.voyageName = `${vessel.name} - ${voyage.voyage_number} (${voyage.start_date.format('DD/MM/YYYY')} - ${voyage.end_date.format('DD/MM/YYYY')})`;
                        }
                    }
                    $scope.showVoyagesLoadingSpinner = false;
                });
        }
    });
}]);
