import { routerApp, baseUrl, enableOnPremise } from '../app.module';
import { httpRequestGenerator } from './utilities';

routerApp.factory('ModelsApiService', ['$rootScope' ,'$http', function($rootScope, $http) {
    var requestFunc = httpRequestGenerator(enableOnPremise, $http);
    return {
        getDisplacement: function(draught) {
            var url = baseUrl + $rootScope.selectedVessel.id + "/displacement?draught=" + draught;
            return requestFunc({
                url: url,
                method: "GET"
            });
        },
        getModelsForEngineLoad: function(mainEngineLoad) {
            var url = baseUrl + $rootScope.selectedVessel.id + "/models?input=" + mainEngineLoad;
            return requestFunc({
                url: url,
                method: "GET"
            });
        },
        getModelsForPower: function(speed, draught, trueWindForce, totalEnergy, meanTotalPower, trueWindSpeed) {
            var url = baseUrl + $rootScope.selectedVessel.id + "/power?"
                + "speed=" + speed
                + "&draught=" + draught
                + "&bf=" + trueWindForce
                + "&totalEnergy=" + totalEnergy
                + "&meanTotalPower=" + meanTotalPower
                + "&tws=" + trueWindSpeed;
            return requestFunc({
                url: url,
                method: "GET"
            })
        },
        getAllModels: function() {
            var url = baseUrl + $rootScope.selectedVessel.id + "/model_landscapes";
            return requestFunc({
                url: url,
                method: "GET"
            });
        },
        getTCRPMModel: function(tcrpm1, tcrpm2) {
            var url = baseUrl + $rootScope.selectedVessel.id + "/tcrpmPower";
            var data = {};
            if (tcrpm1 != undefined) {
                data.tcrpm1 = tcrpm1;
            }
            if (tcrpm2 != undefined) {
                data.tcrpm2 = tcrpm2;
            }
            return requestFunc({
                url: url,
                method: "GET",
                params: data
            });
        },
        getMeRpmModel: function(merpm) {
            return requestFunc({
                url: baseUrl + $rootScope.selectedVessel.id + "/meRPMPower",
                method: "GET",
                params: { merpm: merpm },
            });
        },

        getSensorData: function(vessel, report, customReportTo) {
            var vesselId = vessel.id;
            console.log('making api call for ' + vesselId);
            var data = { report: report };
            if (customReportTo) {
                data['customReportTo'] = customReportTo
            }
            return requestFunc({
                url: baseUrl + 'vessels/' + vesselId + "/sensor",
                method: "POST",
                data: data
            })
        }
    }
}]);
