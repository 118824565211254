import Highcharts from 'highcharts';
import { Color, routerApp, themePalette } from '../../app.module';

routerApp.controller('reportsController', ['$rootScope', '$scope', '$timeout', 'GraphService', 'SpeedPerformanceService', 'PageState', 
function($rootScope, $scope, $timeout, GraphService, SpeedPerformanceService, PageState) {

    $scope.pageState = new PageState();

    // trigger state for loading of data
    $scope.pageState.setLoadingData();

    $scope.dateRanges = ['10_days', '1_month', '3_month', 'ytd'];
    $scope.selectedDateRange = '3_month';
    $scope.setDashboardRange = function(range) {
        $scope.selectedDateRange = range;
        $scope.drawCharts();
        $scope.drawSpeedPerformance();
    }

    // trigger loaded data page state when all data is loaded
    $scope.$watchGroup(['processedData', 'kpiItems'], function(n, o, scope) {
        if (n[0] != undefined && n[1] != undefined) {
            scope.pageState.setDataLoaded();
        }
    });

    var variables = [
        // fuel consumption analysis
        // daily excess consumption
        'computed.excess_consumption_due_to_engine',
        'computed.excess_consumption_due_to_weather',
        'computed.excess_consumption_due_to_hull',
        // total consumption
        'consumption.total_me_consumption',
        'consumption.total_ae_consumption',
        'consumption.total_boiler_consumption',
        // me consumption / 24 hours
        'consumption.total_me_consumption_24',
        'consumption.total_ae_consumption_24',
        // speed performance & sfoc
        'computed.speed_performance',
        'computed.sfoc_lcv_corrected',
        'computed.power',
        'computed.model_sfoc',
        'computed.model_power',

        // engine parameters
        'engine.main_engines.tcrpm_reported',
        'engine.main_engines.exhaust_gas_temperature_reported',
        'engine.main_engines.scavenge_air_pressure_reported',
        // efficiencies
        'computed.co2_efficiency',
        'computed.sox_efficiency',
        'computed.nox_efficiency',
        // emissions
        'computed.co2_emissions',
        'computed.nox_emissions',
        'computed.sox_emissions',
        'computed.ch4_emissions',
        'computed.hc_emissions',
        'computed.pm_emissions',
        // operational
        'operational.time_in_eca',
        'report_number',
    ];

    $scope.drawCharts = function() {
        var dryDockings = null;
        if ($scope.processedData['dryDockings'] != undefined) {
            dryDockings = $scope.processedData['dryDockings'];
        }

        // excess consumption breakdown
        $scope.excessConsumptionDueToEngine = $scope.processedData[$scope.selectedDateRange]['excess_consumption_due_to_engine']
        $scope.excessConsumptionDueToWeather = $scope.processedData[$scope.selectedDateRange]['excess_consumption_due_to_weather']
        $scope.excessConsumptionDueToHull = $scope.processedData[$scope.selectedDateRange]['excess_consumption_due_to_hull']

        // consumption breakdown
        $scope.meConsumptionBreakdown = $scope.processedData[$scope.selectedDateRange]['total_me_consumption'];
        $scope.aeConsumptionBreakdown = $scope.processedData[$scope.selectedDateRange]['total_ae_consumption'];
        $scope.boilerConsumptionBreakdown = $scope.processedData[$scope.selectedDateRange]['total_boiler_consumption'];

        // main engine and aux engine consumption / 24 hours
        $scope.meConsumption24 = $scope.processedData[$scope.selectedDateRange]['total_me_consumption_24'];
        $scope.aeConsumption24 = $scope.processedData[$scope.selectedDateRange]['total_ae_consumption_24'];

        // speed performance
        $scope.speedPerformanceData = $scope.processedData[$scope.selectedDateRange]['speed_performance'];

        //report numbers
        var reportNumber = $scope.processedData[$scope.selectedDateRange]['report_number'];
        reportNumber = reportNumber.map(function(value,index) { return value[1]; });

        function mapReportNumbers(data) {
            var id = data.map(function(value,index) { return value[0]; });
            var points = data.map(function(value,index) { return value[1]; });

            var final = []
            for ( var i = 0; i < data.length; i++ ) {
                final.push( [ id[i], points[i], reportNumber[i] ] );
            }
            return final;
        }

        var speed_performance_lifetime_data = mapReportNumbers($scope.speedPerformanceData);

        // sfoc
        var actualSfoc = [];
        var modelSfoc = [];
        for (var i = 0; i < $scope.processedData[$scope.selectedDateRange]['sfoc_lcv_corrected'].length; i++) {
            var modelSfocValue = $scope.processedData[$scope.selectedDateRange]['model_sfoc'][i][1];
            var actualSfocValue = $scope.processedData[$scope.selectedDateRange]['sfoc_lcv_corrected'][i][1];

//            // actualSfoc.push([$scope.processedData[$scope.selectedDateRange]['power'][i][1], $scope.processedData[$scope.selectedDateRange]['sfoc'][i][1]]);
            actualSfoc.push({
                x: $scope.processedData[$scope.selectedDateRange]['power'][i][0],
                y: actualSfocValue,
            });
            modelSfoc.push({
                x: $scope.processedData[$scope.selectedDateRange]['power'][i][0],
                y: modelSfocValue
            });

        }
        // $scope.modelSfoc = $scope.processedData['sfocModel'];
        $scope.modelSfoc = modelSfoc;
        $scope.actualSfoc = actualSfoc;

        // engine performance
        var filterData = function(data) {
            // Filter out points with a value of 0, undefined, or null.
            var filtered = data.filter(function(point) { return !!point[1]; });

            // Filter out points that are out of a reasonable range (mean +/- SD * 2)
            var values = filtered.map(function(point) { return point[1]; });
            var sd = values.standardDeviation();
            var mean = values.mean();
            filtered = filtered.filter(function(point) {
                return Math.abs(mean - point[1]) <= (mean + sd * 2);
            });

            return filtered;
        };

        $scope.engineTCRPM = filterData($scope.processedData[$scope.selectedDateRange]['tcrpm_reported']);
        $scope.engineExhaustTemperature = filterData($scope.processedData[$scope.selectedDateRange]['exhaust_gas_temperature_reported']);
        $scope.scavengeAirPressure = filterData($scope.processedData[$scope.selectedDateRange]['scavenge_air_pressure_reported']);

        // env efficiency
        $scope.envEfficiency = filterData($scope.processedData[$scope.selectedDateRange]['co2_efficiency']);
        $scope.soxEfficiency = filterData($scope.processedData[$scope.selectedDateRange]['sox_efficiency']);
        $scope.noxEfficiency = filterData($scope.processedData[$scope.selectedDateRange]['nox_efficiency']);

        $scope.co2Emissions = $scope.processedData[$scope.selectedDateRange]['co2_emissions'];
        $scope.noxEmissions = $scope.processedData[$scope.selectedDateRange]['nox_emissions'];
        $scope.soxEmissions = $scope.processedData[$scope.selectedDateRange]['sox_emissions'];
        $scope.hcEmissions = $scope.processedData[$scope.selectedDateRange]['hc_emissions'];
        $scope.pmEmissions = $scope.processedData[$scope.selectedDateRange]['pm_emissions'];
        $scope.ch4Emissions = $scope.processedData[$scope.selectedDateRange]['ch4_emissions'];

        $scope.timeInECA = $scope.processedData[$scope.selectedDateRange]['time_in_eca'];

        $scope.pageState.setDataLoaded();

        var plotOptions = {
            line: {
                marker: {
                    enabled: true
                }
            },
        }
        var dateTimeLabelFormats = {
                millisecond: '%b %e',
                second: '%b %e',
                minute: '%b %e',
                hour: '%b %e',
                day: '%b %e',
                week: '%b %e',
                month: '%b %e',
                year: '%b %e'
        };


        //// consumptions

        // excess consumption breakdown
        // excess consumption
        $scope.excessConsumptionChart = Highcharts.chart('chart-excess-consumption', {
            chart: {
                zoomType: 'xy',
                type: 'column'
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Daily Excess Consumption (MT)"
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.1f}MT</b>'
            },
            xAxis: {
                dateTimeLabelFormats: {
                    minute: '%b %e',
                    hour: '%b %e %H:%M',
                    day: '%b %e'
                },
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Consumption (MT)'
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                    }
                }
            },
            legend: {
                enabled: true
            },
            credits: {
              enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [
                {
                    name: 'Excess Consumption Due to Weather',
                    // data: [6.2, 8.4, 8.5, 6, 10],
                    data: $scope.excessConsumptionDueToWeather,
                    color: themePalette.colors.DATA_16,
                    legendIndex: 2
                }, {
                    name: 'Excess Consumption Due to Hull',
                    // data: [4.1, 5, 6, 5.5, 4],
                    data: $scope.excessConsumptionDueToHull,
                    color: themePalette.colors.DATA_17,
                    legendIndex: 1
                }, {
                    name: 'Excess Consumption Due to Engine',
                    // data: [4.1, 5, 6, 5.5, 4],
                    data: $scope.excessConsumptionDueToEngine,
                    color: themePalette.colors.GRAPH_BLUE,
                    legendIndex: 1
                }
            ]
        });

        // main engine, aux engine, aux boiler consumption
        $scope.consumptionBreakdownChart = Highcharts.chart('chart-consumption-breakdown', {
            chart: {
                zoomType: 'xy',
                type: 'column'
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Consumption Breakdown (MT)"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                min: 0,
                title: {
                    enabled: true,
                    text: 'Consumption (MT)'
                },
                stackLabels: {
                    enabled: false
                }
            },
//            tooltip: {
//                headerFormat: '<b>{point.x}</b><br/>',
//                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
//            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                    }
                }
            },
            series: [{
                name: 'Boiler',
                // data: [6.2, 8.4, 8.5, 6, 10],
                data: $scope.boilerConsumptionBreakdown,
                color: themePalette.colors.DATA_16,
                legendIndex: 2
            }, {
                name: 'Auxiliary Engine',
                // data: [4.1, 5, 6, 5.5, 4],
                data: $scope.aeConsumptionBreakdown,
                color: themePalette.colors.DATA_17,
                legendIndex: 1
            }, {
                name: 'Main Engine',
                // data: [8.4, 14.8, 4.2, 14.4, 6.4],
                data: $scope.meConsumptionBreakdown,
                color: themePalette.colors.GRAPH_BLUE,
                legendIndex: 0
            }]
        });
        $timeout(function() {
            $scope.consumptionBreakdownChart.reflow();
        }, 0);

        // main engine and aux engines 24 hour consumptions
        Highcharts.chart('chart-me-consumption-24', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Main Engine Consumption / 24 hours (MT)"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Consumption (MT)'
                }
            },
            plotOptions: plotOptions,
            legend: {
                enabled: false
            },
            credits: {
              enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'ME Consumption',
                data: $scope.meConsumption24,
                width: 1,
                color: '#5195d0',
                negativeColor: '#a1bc39'
    //            negativeColor: '#1aaf54'
            }

            ]
        });
        Highcharts.chart('chart-ae-consumption-24', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Auxiliary Engine Consumption / 24 hours (MT)"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Consumption (MT)'
                }
            },
            plotOptions: plotOptions,
            legend: {
                enabled: false
            },
            credits: {
              enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'AE Consumption',
                data: $scope.aeConsumption24,
                width: 1,
                color: '#5195d0',
                negativeColor: '#a1bc39'
    //            negativeColor: '#1aaf54'
            }

            ]
        });

        $scope.drawSpeedPerformance = function() {
            $timeout(function () {
                $scope.speedPerformanceChart = SpeedPerformanceService.plotChart($scope.speedPerformanceData, dryDockings, speed_performance_lifetime_data, 'chart-speed-performance');

                $scope.sfocChart = Highcharts.chart('chart-sfoc', {
                    chart: {
                        zoomType: 'xy',
                        type: 'line'
                    },
                    title: {
                        text: null
                    },
                    subtitle: {
                        text: "SFOC Performance"
                    },
                    xAxis: {
                        startOnTick: true,
                        endOnTick: true,
                        dateTimeLabelFormats: {
                            minute: '%b %e',
                            hour: '%b %e %H:%M',
                            day: '%b %e',
                            week: '%b %e',
                            month: '%b %e'
                        },
                        type: 'datetime'
                    },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: 'SFOC (gm/kWhr)'
                        },
                        min: 120,
                        max: 300,
                        tickInterval: 30,
                    },
                    plotOptions: {
                        scatter: {
                            tooltip: {
                                enabled: true,
                                headerFormat: '<b>{series.name}</b><br>',
                                pointFormat: 'SFOC: {point.y} gm/kWhr'
                            }
                        },
                        spline: {
                            tooltip: {
                                enableMouseTracking: false,
                                enabled: false,
                                formatter: null,
                                pointFormatter: null
                            },
                            hover: {
                                enabled: false,
                                halo: {
                                    size: 0
                                }
                            },
                            marker: {
                                enabled: false
                            },
                            dataLabels: { enabled: false },
                            allowPointSelect: false,
                            animation: false,
                            animationLimit: 0,
                            enableMouseTracking: false,

                        }
                    },
                    legend: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    series: [{
                        name: 'SFOC Reported',
                        data: $scope.actualSfoc,
                        threshold: 178,
                        width: 1,
                        marker: {
                            enabled: true,
                            radius: 4,
                            symbol: 'circle'
                        }
                    }, {
                        name: 'SFOC Model Data',
                        data: $scope.modelSfoc,
                        marker: {
                            enabled: true,
                            radius: 4,
                            symbol: 'circle'
                        },
                        dashStyle: 'shortdot',
                        color: themePalette.colors.DATA_18,
                    }]
                });
            });
        }


        // engine efficiency
        Highcharts.chart('chart-tcrpm', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Turbocharger RPM"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'RPM'
                }
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'RPM',
                data: $scope.engineTCRPM,
                width: 3,
                color: '#5195d0',
                negativeColor: '#a1bc39'
                //            negativeColor: '#1aaf54'
            }

                    ]
        });
        Highcharts.chart('chart-exhaust-temperature', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Exhaust Temperature"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                type: 'datetime',
                dateTimeLabelFormats: dateTimeLabelFormats,
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Exhaust Temperature (°C)'
                }
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'Exhaust Temperature (°C)',
                data: $scope.engineExhaustTemperature,
                width: 1,
                color: '#5195d0',
                negativeColor: '#1aaf54'
            }

                    ]
        });
        Highcharts.chart('chart-scavenge-pressure', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Scavenge Air Pressure"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Scavenge Air Pressure (Bar)'
                }
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'Scavenge Air Pressure (Bar)',
                data: $scope.scavengeAirPressure,
                width: 1,
                color: '#5195d0',
                negativeColor: '#1aaf54'
            }

                    ]
        });

        // group efficiencies by month and plot them
        var groupByDatesSplitByECA = function(vars, ecaSeries) {
            var dateToValue = vars.reduce(function(res, value, index) {
                var timeInECA = ecaSeries[index][1];
                var category = timeInECA > 0 ? res['eca'] : res['non-eca'];
                var timestamp = new Date(value[0]);
                var timeBucket = new Date(timestamp.getFullYear(), timestamp.getMonth(), 1).getTime();
                if (category[timeBucket] == undefined) {
                    category[timeBucket] = 0;
                }
                category[timeBucket] += parseFloat(value[1]);
                return res;
            }, {
                'eca': {},
                'non-eca': {}
            });

            var ecaBucket = dateToValue['eca'];
            var nonEcaBucket = dateToValue['non-eca'];

            var keys = Object.keys(nonEcaBucket).sort((a, b) => parseFloat(a) - parseFloat(b));
            var ecaValues = [];
            var nonEcaValues = [];
            let returnKeys = [];
            for (var i = 0; i < keys.length; i++) {
                ecaValues.push(ecaBucket[keys[i]] || 0.0);
                nonEcaValues.push(nonEcaBucket[keys[i]] || 0.0)
                let returnKeyDate = new Date(parseFloat(keys[i]));
                returnKeys[i] = (returnKeyDate.getMonth() + 1) + '/' + returnKeyDate.getFullYear();
            }
            return [returnKeys, nonEcaValues, ecaValues];
        }

        var groupedco2Emissions = groupByDatesSplitByECA($scope.co2Emissions, $scope.timeInECA);
        var groupednoxEmissions = groupByDatesSplitByECA($scope.noxEmissions, $scope.timeInECA);
        var groupedsoxEmissions = groupByDatesSplitByECA($scope.soxEmissions, $scope.timeInECA);

        $scope.co2Monthly = Highcharts.chart('chart-co2-monthly', {
            chart: { type: 'column', },
            title: { text: null },
            legend: { enabled: true },
            credits: { enabled: false },
            exporting: { enabled: false },
            xAxis: {
                categories: groupedco2Emissions[0],
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'CO₂ Emissions (MT)'
                },
            },

            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y:.1f} MT'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                    }
                }
            },
            series: [
                {
                    name: 'Emissions in ECA',
                    data: groupedco2Emissions[2],
                    color: themePalette.colors.DATA_19,
                },
                {
                    name: 'Emissions outside ECA',
                    data: groupedco2Emissions[1],
                    color: themePalette.colors.DATA_20,
                }
            ]
        });
        $scope.noxMonthly = Highcharts.chart('chart-nox-monthly', {
            chart: { type: 'column', },
            title: { text: null },
            legend: { enabled: true },
            credits: { enabled: false },
            exporting: { enabled: false },
            xAxis: {
                categories: groupednoxEmissions[0],
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'NOx Emissions (MT)'
                },
            },

            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y:.1f} MT'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                    }
                }
            },
            series: [
                {
                    name: 'Emissions in ECA',
                    data: groupednoxEmissions[2],
                    color: themePalette.colors.DATA_19,
                },
                {
                    name: 'Emissions outside ECA',
                    data: groupednoxEmissions[1],
                    color: themePalette.colors.DATA_20,
                }
            ]
        });
        $scope.soxMonthly = Highcharts.chart('chart-sox-monthly', {
            chart: { type: 'column', },
            title: { text: null },
            legend: { enabled: true },
            credits: { enabled: false },
            exporting: { enabled: false },
            xAxis: {
                categories: groupedsoxEmissions[0],
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'SOx Emissions (MT)'
                },
            },

            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y:.1f} MT'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                    }
                }
            },
            series: [
                {
                    name: 'Emissions in ECA',
                    data: groupedsoxEmissions[2],
                    color: themePalette.colors.DATA_19,
                },
                {
                    name: 'Emissions outside ECA',
                    data: groupedsoxEmissions[1],
                    color: themePalette.colors.DATA_20,
                }
            ]
        });
        $timeout(function() {
            $scope.co2Monthly.reflow();
            $scope.noxMonthly.reflow();
            $scope.soxMonthly.reflow();
        }, 0);

        // env efficiency
        Highcharts.chart('chart-env-efficiency', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "CO₂ Efficiency"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Efficiency (g/T-NM)'
                },
                plotLines: [{
                    label: {
                        text: 'Average',
                        color: themePalette.colors.RED
                    },
                    color: themePalette.colors.YELLOW,
                    width: 2,
                    value: $scope.envEfficiency.map(function(point) { return point[1]; }).mean(),
                    dashStyle: 'dash'
                }]
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'CO₂ Efficiency (g/T-NM)',
                data: $scope.envEfficiency,
                width: 1,
                color: themePalette.colors.DATA_20,
                negativeColor: '#a1bc39'
                //            negativeColor: '#1aaf54'
            }

                    ]
        });
        Highcharts.chart('chart-nox-efficiency', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "NOX Efficiency"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                type: 'datetime',
                dateTimeLabelFormats: dateTimeLabelFormats,
            },
            plotOptions: plotOptions,
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Efficiency (g/T-NM)'
                },
                plotLines: [{
                    label: {
                        text: 'Average',
                        color: themePalette.colors.RED
                    },
                    color: themePalette.colors.YELLOW,
                    width: 2,
                    value: $scope.noxEfficiency.map(function(point) { return point[1]; }).mean(),
                    dashStyle: 'dash'
                }]
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'NOX Efficiency (g/T-NM)',
                data: $scope.noxEfficiency,
                width: 1,
                color: themePalette.colors.DATA_20,
                negativeColor: '#1aaf54'
            }

                    ]
        });
        Highcharts.chart('chart-sox-efficiency', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "SOX Efficiency"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Efficiency (g/T-NM)'
                },
                plotLines: [{
                    label: {
                        text: 'Average',
                        color: themePalette.colors.RED
                    },
                    color: themePalette.colors.YELLOW,
                    width: 2,
                    value: $scope.soxEfficiency.map(function(point) { return point[1]; }).mean(),
                    dashStyle: 'dash'
                }]
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'SOX Efficiency (g/T-NM)',
                data: $scope.soxEfficiency,
                width: 1,
                color: themePalette.colors.DATA_20,
                negativeColor: '#1aaf54'
            }]
        });

        // new emissions
        Highcharts.chart('chart-pm-emissions', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "PM Emissions"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'PM Emissions (g/T-NM)'
                },
                plotLines: [{
                    label: {
                        text: 'Average',
                        color: themePalette.colors.RED
                    },
                    color: themePalette.colors.YELLOW,
                    width: 2,
                    value: $scope.pmEmissions.map(function(point) { return point[1]; }).mean(),
                    dashStyle: 'dash'
                }]
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'PM Emissions (g/T-NM)',
                data: $scope.pmEmissions,
                width: 1,
                color: themePalette.colors.DATA_20,
                negativeColor: '#1aaf54'
            }]
        });
        Highcharts.chart('chart-hc-emissions', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "HC Emissions"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'HC Emissions (g/T-NM)'
                },
                plotLines: [{
                    label: {
                        text: 'Average',
                        color: themePalette.colors.RED
                    },
                    color: themePalette.colors.YELLOW,
                    width: 2,
                    value: $scope.hcEmissions.map(function(point) { return point[1]; }).mean(),
                    dashStyle: 'dash'
                }]
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'HC Emissions (g/T-NM)',
                data: $scope.hcEmissions,
                width: 1,
                color: themePalette.colors.DATA_20,
                negativeColor: '#1aaf54'
            }]
        });
        Highcharts.chart('chart-ch4-emissions', {
            chart: {
                zoomType: 'x',
            },
            title: {
                text: null
            },
            subtitle: {
                text: "CH4 Emissions"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                dateTimeLabelFormats: dateTimeLabelFormats,
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'CH4 Emissions (g/T-NM)'
                },
                plotLines: [{
                    label: {
                        text: 'Average',
                        color: themePalette.colors.RED
                    },
                    color: themePalette.colors.YELLOW,
                    width: 2,
                    value: $scope.ch4Emissions.map(function(point) { return point[1]; }).mean(),
                    dashStyle: 'dash'
                }]
            },
            plotOptions: plotOptions,
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'HC4 Emissions (g/T-NM)',
                data: $scope.ch4Emissions,
                width: 0.5,
                color: themePalette.colors.DATA_20,
                negativeColor: '#1aaf54'
            }]
        });
    }

    $timeout(function() {
        GraphService.getKPIData($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data.data;
            var kpiData = data[$scope.selectedDateRange];
            if (!kpiData) {
                $scope.pageState.setNoData();
                return;
            }
            $scope.kpiItems = buildKpiItems(kpiData);
            $scope.kpiTotalScore = kpiData['mileage'] + kpiData['speed_performance'] + kpiData['excess_consumption'] + kpiData['sfoc'] + kpiData['slip'] + kpiData['tcrpm'] + kpiData['scavenge_air_pressure'] + kpiData['turbine_inlet_pressure'] + kpiData['exhaust_temperature'] + kpiData['turbine_inlet_temperature']

            var monthlyKpi = data['monthlyKpi'];
            $scope.kpiChart = Highcharts.chart('chart-kpi-monthly', {
                chart: {
                    type: 'column',
                    events: {
                        load: function() {
                            $scope.series = [];
                            var chart = this;
                            $(chart.series).each(function (i, serie) {
                                $scope.series.push(serie);
                            });
                        }
                    }
                },
                title: {
                    text: null
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                xAxis: {
                    categories: monthlyKpi['dates']
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Total KPI Score'
                    },

                    plotLines: [{
                        text: 'Max Score',
                        color: themePalette.colors.RED, // Color value
                        dashStyle: 'dashed', // Style of the plot line. Default to solid
                        value: 100, // Value of where the line will appear
                        width: 2 // Width of the line

                    }],

                    stackLabels: {
                        enabled: true,
                        style: {
                            // fontWeight: 'bold',
                            color: $scope.isDarkTheme() ? Color.WHITE : (Highcharts.theme && Highcharts.theme.background2) || 'gray',
                        },

                        format: '{total:.1f}'
                    }
                },
                legend: {
                    enabled: false,
                    align: 'right',
                    x: -100,
                    verticalAlign: 'bottom',
                    layout: 'vertical',
                    y: 100,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y:.1f}<br/>Total: {point.stackTotal:.1f}'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false,
                        }
                    }
                },
                series: [
                    {
                        name: 'Mileage',
                        data: monthlyKpi['mileage'],
                        color: '#5195d0',
                    },
                    {
                        name: 'Speed Performance',
                        data: monthlyKpi['speed_performance'],
                        color: '#5195d0',
                    },
                    {
                        name: 'Excess Consumption',
                        data: monthlyKpi['excess_consumption'],
                        color: '#5195d0',
                    },
                    {
                        name: 'SFOC',
                        data: monthlyKpi['sfoc'],
                        color: '#5195d0',
                    },
                    {
                        name: 'Slip',
                        data: monthlyKpi['slip'],
                        color: '#5195d0',
                    },

                    {
                        name: 'Turbocharger RPM',
                        data: monthlyKpi['tcrpm'],
                        color: '#5195d0',
                    },
                    {
                        name: 'Scavenge Air Pressure',
                        data: monthlyKpi['scavenge_air_pressure'],
                        color: '#5195d0',
                    },
                    {
                        name: 'Turbine Inlet Pressure',
                        data: monthlyKpi['turbine_inlet_pressure'],
                        color: '#5195d0',
                    },
                    {
                        name: 'Exhaust Gas Temperature',
                        data: monthlyKpi['exhaust_temperature'],
                        color: '#5195d0',
                    },
                    {
                        name: 'Turbine Inlet Temperature',
                        data: monthlyKpi['turbine_inlet_temperature'],
                        color: '#5195d0',
                    },
                ]
            });
            $timeout(function() {
                $scope.kpiChart.reflow();
            }, 0);
        });
    }, 0);

    GraphService.getGraphsData($rootScope.selectedVessel.id, variables).then(function(response) {
        var data = response.data.data;
        var processedData = GraphService.getTimeSeriesData(data, $scope.dateRanges, variables);
        processedData.dryDockings = data.dryDockings;
        processedData.sfocModel = data.sfocModel;
        $scope.processedData = processedData;
        $scope.drawCharts();

        $scope.pageState.setDataLoaded();
        $scope.drawSpeedPerformance();
    });

    Highcharts.setOptions({
        colors: ['#5195d0', '#8ed0e7', '#256d9e', '#65717f', '#103258']
    });

    $rootScope.selectedMenu = 'reports';

    var buildKpiItems = function(kpiData) {
        return [
            {
                'kpi': 'Mileage',
                'unit': 'Nautical Miles / Metric Ton',
                'target': 20,
                'score': kpiData['mileage'],
                'bestInClass': kpiData['mileage']
            },
            {
                'kpi': 'Speed Performance',
                'unit': 'Percentage',
                'target': 20,
                'score': kpiData['speed_performance'],
                'bestInClass': kpiData['speed_performance']
            },
            {
                'kpi': 'Excess Consumption',
                'unit': 'Metric Tons',
                'target': 10,
                'score': kpiData['excess_consumption'],
                'bestInClass': kpiData['excess_consumption']
            },
            {
                'kpi': 'SFOC',
                'unit': 'Grams/Kilowatt - Hour',
                'target': 20,
                'score': kpiData['sfoc'],
                'bestInClass': kpiData['sfoc']
            },
            {
                'kpi': 'Slip',
                'unit': 'Percentage',
                'target': 5,
                'score': kpiData['slip'],
                'bestInClass': kpiData['slip']
            },
            {
                'kpi': 'Turbocharger RPM',
                'unit': 'Revolutions/Minute',
                'target': 5,
                'score': kpiData['tcrpm'],
                'bestInClass': kpiData['tcrpm']
            },
            {
                'kpi': 'Scavenge Air Pressure',
                'unit': 'Bar',
                'target': 5,
                'score': kpiData['scavenge_air_pressure'],
                'bestInClass': kpiData['scavenge_air_pressure']
            },
            {
                'kpi': 'Turbine Inlet Pressure',
                'unit': 'Bar',
                'target': 5,
                'score': kpiData['turbine_inlet_pressure'],
                'bestInClass': kpiData['turbine_inlet_pressure']
            },
            {
                'kpi': 'Exhaust Gas Temperature',
                'unit': 'Degrees Celsius',
                'target': 5,
                'score': kpiData['exhaust_temperature'],
                'bestInClass': kpiData['exhaust_temperature']
            },
            {
                'kpi': 'Turbine Inlet Temperature',
                'unit': 'Degrees Celsius',
                'target': 5,
                'score': kpiData['turbine_inlet_temperature'],
                'bestInClass': kpiData['turbine_inlet_temperature']
            }
        ];
    }

    $scope.series = [];
    $scope.kpiChecked = [true, true, true, true, true, true, true, true, true, true];
    $scope.kpiMaxScores = [20, 20, 10, 20, 5, 5, 5, 5, 5, 5];

    $scope.toggle = function(index) {
        var newValue = $scope.kpiChecked[index];
        if (newValue) {
            $scope.series[index].show();
        } else {
            $scope.series[index].hide();
        }

        // update max in y-axis
        var max = 0;
        for (var i = 0; i < $scope.kpiChecked.length; i++) {
            if ($scope.kpiChecked[i]) {
                max += $scope.kpiMaxScores[i];
            }
        }
        $scope.kpiChart.yAxis[0].setExtremes(0, max);
        var plotline = $scope.kpiChart.yAxis[0].plotLinesAndBands[0];
        plotline.options.value = max;
        plotline.svgElem.destroy();
        plotline.svgElem = undefined;
        plotline.render();
    }
}]);
