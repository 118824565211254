import { sumValues, showByDefault } from '../services/utilities';

export enum ValidationType {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error'
}
type TValidationType = 'info' | 'warning' | 'error';

export class ValidationResult {
    valid: boolean = true;
    type: TValidationType = 'info';
    message: string | null = null;
    path: string | null = null;

    constructor(valid, type, message, path) {
        this.valid = valid;
        this.type = type;
        this.message = message;
        this.path = path;
    }
};

var validation = function(){

    /** Helper Functions */ 
    var getInstalledPower = function(engines) {
        var installedPower = 0;
        if (engines == undefined) throw 'Missing argument: getInstalledPower';
        if (engines && engines.length == 0) {
            return installedPower;
        }
        
        for (var i = 0; i < engines.length; i++) {
            installedPower += engines[i].mcr;
        }
        return installedPower;
    }

    var getMaxTotalRunningHours = function(totalRunningHours, installedPower) {
        return Math.pow(10, -6) * totalRunningHours * installedPower * 1.2;
    };

    var getMaxMainEngineTotalRunningHours = function(totalRunningHours, installedPower) {
        return 250 * getMaxTotalRunningHours(totalRunningHours, installedPower);
    };

    var getMaxAuxEngineTotalRunningHours = function(totalRunningHours, installedPower) {
        return 300 * getMaxTotalRunningHours(totalRunningHours,installedPower);
    };

    var roundToPlaces = function(num, decimalPlaces) {
        if (decimalPlaces === undefined || decimalPlaces === null) {
            throw 'Missing argument: decimalPlaces';
        }
        return Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    };

    var auxEngDidNotRun = function(aeCons, calculatedMax) {return aeCons != 0 && calculatedMax == 0; };

    var aeConsMoreThanMax = function(aeCons, calculatedMax) {return aeCons >= calculatedMax && calculatedMax > 0; };

    var distanceBetweenTwoAngles = function(angleOne, angleTwo) { // shortesting distance between two angles
        var phi = Math.abs(angleOne - angleTwo) % 360;
        return phi > 180 ? 360 - phi : phi;
    }

// Validations Object
/** Moving watchGroup Validation logic here 
 * Design decision:
 * (1) maintain all validation logic at one location
 * (2) separate validation logic when we upgrade from AngularJS v1.X to modern FE framework
 * */ 
    class ValidationServiceClass {
        private static store: any = {};
        
        public setStore = (key: string, value: any): void => {
            if (!Object.assign) {
                ValidationServiceClass.store[key] = value;
                return;
            }
            var data = {};
            var storeTemp = ValidationServiceClass.store;
            data[key] = value;

            ValidationServiceClass.store = Object.assign({}, storeTemp, data);
        }
        public getStore (key) {
            return ValidationServiceClass.store[key];
        }

        public initialize(data) {
            let _setStore = this.setStore;
            Object.keys(data).forEach(function(key) {
                _setStore(key, data[key])
            })
        }

        public getAllValidations() {
            return validations;
        }

        public getValidations(name) {
            return validations[name];
        }
    }

    const validationServiceInstance = new ValidationServiceClass(); 

    var auxEngConsFields = [
        "report.consumption.aux_engine_hfo",
        "report.consumption.aux_engine_lfo",
        "report.consumption.aux_engine_mgo",
        "report.consumption.aux_engine_mdo",
        "report.consumption.aux_engine_b10lfo",
        "report.consumption.aux_engine_b10mgo",
        "report.consumption.aux_engine_biolfo",
        "report.consumption.aux_engine_biomgo",
        "report.consumption.aux_engine_ulsfo2020",
        "report.consumption.aux_engine_ulslfo2020",
        "report.consumption.aux_engine_ulsmdo2020",
        "report.consumption.aux_engine_ulsmgo2020",
        "report.consumption.aux_engine_vlsfo2020",
        "report.consumption.aux_engine_vlslfo2020",
        "report.consumption.aux_engine_lpgp",
        "report.consumption.aux_engine_lpgb",
        "report.consumption.aux_engine_lng",
        "report.consumption.aux_engine_methanol",
        "report.consumption.aux_engine_ethanol",
        "report.consumption.aux_engine_other"
    ];
    
    const getAuxEngineConsFields = (scope) => {
        if (scope.report && scope.report.bdn_based_reporting && scope.report.consumption.bdn_consumptions) {
            const length = scope.report.consumption.bdn_consumptions.length
            return [...Array(length)].map((_, i) => 'report.consumption.bdn_consumptions[' + i + '].aux_engine')
        }
        
        return auxEngConsFields
    }

    const getAuxEngConsLength = (scope) => {
        if (scope.report && scope.report.bdn_based_reporting && scope.report.consumption.bdn_consumptions) {
            return scope.report.consumption.bdn_consumptions.length
        }

        return auxEngConsFields.length
    }

    const auxEngConsFormPath = [
        'reportForm.consumptions.auxEngine',
        'reportForm.consumptions.auxEngineHFO',
        'reportForm.consumptions.auxEngineLFO',
        'reportForm.consumptions.auxEngineMDO',
        'reportForm.consumptions.auxEngineB10LFO',
        'reportForm.consumptions.auxEngineB10MGO',
        'reportForm.consumptions.auxEngineBioLFO',
        'reportForm.consumptions.auxEngineBioMGO',
        'reportForm.consumptions.auxEngineULSFO2020',
        'reportForm.consumptions.auxEngineULSLFO2020',
        'reportForm.consumptions.auxEngineULSMDO2020',
        'reportForm.consumptions.auxEngineULSMGO2020',
        'reportForm.consumptions.auxEngineVLSFO2020',
        'reportForm.consumptions.auxEngineVLSLFO2020',
        'reportForm.consumptions.auxEngineLPGP',
        'reportForm.consumptions.auxEngineLPGB',
        'reportForm.consumptions.auxEngineLNG',
        'reportForm.consumptions.auxEngineMethanol',
        'reportForm.consumptions.auxEngineEthanol',
        'reportForm.consumptions.auxEngineOther'
    ]

    const getAuxEngConsFormPath = (scope) => {
        if (scope.report && scope.report.bdn_based_reporting && scope.report.consumption.bdn_consumptions) {
            const bdnConsumptions =  scope.report.consumption.bdn_consumptions
            const bdnArray = bdnConsumptions.map(a => a.bdn_number).filter(bdnNumber => bdnNumber)
            return bdnArray.map((bdnNumber) => {
                return {'path': 'reportForm.consumptions.aux_engine_' + bdnNumber, 'erroKey': 'totalAECons'}
            })
        }
        
        return auxEngConsFormPath.map((path) => {
            return {'path': path, 'erroKey': 'totalAECons'}
        })
    }

    const validations = {
        'aux_engine_diff_rh_validation': {
            scopeRequired: true,
            watchVariables: (scope) => {
                return getAuxEngineConsFields(scope).concat(
                    [
                    'report.power.aux_engine_1_diff_rh',
                    'report.power.aux_engine_2_diff_rh',
                    'report.power.aux_engine_3_diff_rh',
                    'report.power.aux_engine_4_diff_rh',
                    'report._cls',
                    'form'
                ])
            },
            check: function(newValues, oldValues, scope) {
                var vesselSpecs = validationServiceInstance.getStore('vesselSpecs');
                if (!vesselSpecs || !vesselSpecs.engine || !vesselSpecs.engine.ae_engines) return;
                var endOfAuxConsIndex = getAuxEngConsLength(scope)
                var reportType = newValues[endOfAuxConsIndex + 4];
                var aeCons = sumValues(newValues.slice(0, endOfAuxConsIndex));
                var ae1rh = newValues[endOfAuxConsIndex];
                var ae2rh = newValues[endOfAuxConsIndex + 1];
                var ae3rh = newValues[endOfAuxConsIndex + 2];
                var ae4rh = newValues[endOfAuxConsIndex + 3];
                
                var totalRunningHours = sumValues([ae1rh, ae2rh, ae3rh, ae4rh]);
                var auxEngines = vesselSpecs.engine.ae_engines;
                var installedPower = getInstalledPower(auxEngines);
                var calculatedMax = getMaxAuxEngineTotalRunningHours(totalRunningHours, installedPower);
                calculatedMax = roundToPlaces(calculatedMax, 2);

                var valid = true;
                var type = 'info';
                var message = null;
                var path = 'report.consumption.aux_engine';
                var powerMachineryRhCounterEnabled = showByDefault(vesselSpecs.form, reportType, 'power.machinery_rh_counter', 'sections');
                var validAuxEngineConsumption = newValues.slice(0, endOfAuxConsIndex).filter(auxValue => auxValue != undefined && auxValue >= 0).length > 0;
                var auxFieldsAreRequired = auxEngConsFields
                    .concat(['report.consumption.aux_engine'])
                    .filter(auxField => vesselSpecs.form.fields[auxField] && vesselSpecs.form.fields[auxField].required).length > 0;
                var powerTabEnabled = vesselSpecs.form?.tabs?.power?.visible != false;

                if (powerTabEnabled && powerMachineryRhCounterEnabled && auxEngDidNotRun(aeCons, calculatedMax)) {
                    valid = false;
                    type = 'error';
                    message = 'Total AE Consumption should be equal to ' + calculatedMax + ', check consumptions or running hours.';
                } else if (powerTabEnabled && powerMachineryRhCounterEnabled && aeConsMoreThanMax(aeCons, calculatedMax)) {
                    valid = false;
                    type = 'error';
                    message = 'Total AE Consumption should be less than ' + calculatedMax + ', check consumptions or running hours.';
                } else if (powerTabEnabled && !powerMachineryRhCounterEnabled && aeCons < 0 || auxFieldsAreRequired && !validAuxEngineConsumption) {
                    valid = false;
                    type = 'error';
                    message = 'Total AE Consumption should be greater than or equal to 0.';
                }
                return new ValidationResult(
                    valid,
                    type,
                    message,
                    path
                );
            },
            // validation error paths to set to true / false base on check condition
            // path = `reportForm.<tab>.<fieldName>`
            formPath: (scope) => {
                return getAuxEngConsFormPath(scope)
            }            
        },
        'awd_wave_dir_diff': {
            watchVariables: [
                "report.position.relative_wind_direction",
                "report.position.wave_direction"
            ],
            check: function(newValues) {
                var awd = newValues[0];
                var waveDirection = newValues[1];

                var valid = true;
                var type = 'info';
                var message = null;
                var path = 'report.position.wave_direction';

                var isGreaterThanNinetyDeg = distanceBetweenTwoAngles(awd, waveDirection) > 90;
                if (isGreaterThanNinetyDeg) {
                    valid = true;
                    type = 'warning';
                    message = 'Apparent Wind Direction and Wave Direction differs by more 90 deg. Please check reported Apparent Wind and Wave Directions';
                }

                return new ValidationResult(
                    valid,
                    type,
                    message,
                    path
                );
            },
            
            formPath: [{path:'reportForm.position.observation.waveDirection', errorKey: 'waveDirection'}]
        },
        'stock_lo_aux': {
            watchVariables: [
                'report.stock.aux_engine_lube_oil', 
                'report.bunker.quantity_received_ae_lube_oil',
                'report.power.aux_engine_1_lo',
                'report.power.aux_engine_2_lo',
                'report.power.aux_engine_3_lo',
                'report.power.aux_engine_4_lo',
            ],
            check: function(newValues, oldValues, scope) {
                var multiplier = scope.stockThresholdMultiplier;
                if (newValues[0] == undefined || !scope.carryOverReport || !scope.carryOverReport.stock || multiplier == undefined) return;
            
                var auxEngineLubeOil = newValues[0];
                var aeLubeOilBunker = newValues[1] || 0;
                var aeLO1 = newValues[2] || 0;
                var aeLO2 = newValues[3] || 0;
                var aeLO3 = newValues[4] || 0;
                var aeLO4 = newValues[5] || 0;
                var prevAuxEngineLubeOil = scope.carryOverReport.stock.aux_engine_lube_oil || scope.report.stock.prev_aux_engine_lube_oil

                var valid = true;
                var type = 'info';
                var message = null;
                var path = 'report.stock.aux_engine_lube_oil';

                var expectedAeLOStock = prevAuxEngineLubeOil + aeLubeOilBunker - (aeLO1 + aeLO2 + aeLO3 + aeLO4);
                if (prevAuxEngineLubeOil && expectedAeLOStock !== auxEngineLubeOil) {
                    type = 'warning';
                    message = 'AE L.O. Stock should be ' + expectedAeLOStock;
                }

                return new ValidationResult(
                    valid,
                    type,
                    message,
                    path
                );
            },
            formPath: [{path:'reportForm.stock.auxEngineLubeOil', errorKey: 'auxEngineLubeOil'}]
        },
        'aux_engine_diff_rh_excess': {
            watchVariables: [
                'report.power.aux_engine_1_diff_rh', 
                'report.power.aux_engine_2_diff_rh', 
                'report.power.aux_engine_3_diff_rh', 
                'report.power.aux_engine_4_diff_rh',
                'report.operational.report_period', 
                'report.power.scrubber_in_use',
                'vesselSpecs'
        ],
            check: function(newValues, oldValues, scope) {
                var vesselSpecs = validationServiceInstance.getStore('vesselSpecs') || newValues[6] || scope.vesselSpecs;
                if (vesselSpecs == undefined || vesselSpecs.information == undefined) return;
                var aeRHs = newValues.slice(0, 4);
                var reportPeriod = newValues[4];
                var useScrubber = newValues[5];
                var hasScrubbers = vesselSpecs && vesselSpecs.information && vesselSpecs.information.has_scrubber;
        
                var aeRHsExceedsPeriod = aeRHs.sum() / reportPeriod > 1.2;
                var noScrubber = !hasScrubbers && aeRHsExceedsPeriod;
                var scruberInUse = hasScrubbers && useScrubber ? false : aeRHsExceedsPeriod;

                scope.enableExcessRHCause = noScrubber || scruberInUse;
                return;
            },
            formPath: []
        },
        'stock_caustic_soda': {
            watchVariables: [
                'report.stock.caustic_soda',
                'report.bunker.quantity_received_caustic_soda',
                'report.consumption.caustic_soda',
            ],
            check: function(newValues, oldValues, scope) {
                var multiplier = scope.stockThresholdMultiplier;
                if (newValues[0] == undefined || !scope.carryOverReport || !scope.carryOverReport.stock || multiplier == undefined) return;

                var stock = newValues[0];
                var bunker = newValues[1] || 0;
                var cons = newValues[2] || 0;
                var prevStock = scope.carryOverReport.stock.caustic_soda || scope.report.stock.prev_caustic_soda || 0;

                var valid = true;
                var type = 'info';
                var message = null;
                var path = 'report.stock.caustic_soda';

                var expectedStock = prevStock + bunker - cons;
                if (prevStock && expectedStock !== stock) {
                    type = 'warning';
                    message = 'Caustic Soda Stock should be ' + expectedStock;
                }

                return new ValidationResult(
                    valid,
                    type,
                    message,
                    path
                );
            },
            formPath: [{path:'reportForm.stock.causticSoda', errorKey: 'causticSoda'}]
        },
        'stock_urea_stock': {
            watchVariables: [
                'report.stock.urea_stock',
                'report.bunker.quantity_received_urea',
                'report.consumption.urea_consumption',
            ],
            check: function(newValues, oldValues, scope) {
                var multiplier = scope.stockThresholdMultiplier;
                if (newValues[0] == undefined || !scope.carryOverReport || !scope.carryOverReport.stock || multiplier == undefined) return;

                var stock = newValues[0];
                var bunker = newValues[1] || 0;
                var cons = newValues[2] || 0;
                var prevStock = scope.carryOverReport.stock.urea_stock || scope.report.stock.prev_urea_stock || 0;

                var valid = true;
                var type = 'info';
                var message = null;
                var path = 'report.stock.urea_stock';

                var expectedStock = prevStock + bunker - cons;
                if (prevStock && expectedStock !== stock) {
                    type = 'warning';
                    message = 'Urea Stock should be ' + expectedStock;
                }

                return new ValidationResult(
                    valid,
                    type,
                    message,
                    path
                );
            },
            formPath: [{path:'reportForm.stock.ureaStock', errorKey: 'ureaStock'}]
        },

        'mdg_power_meter_1': {
            watchVariables: [
                'report.power.mdg_power_meter_1_cur',
                'report.power.diesel_gen_1_diff_rh',
            ],
            check: function(newValues, oldValues, scope) {
                return mdgPowerMeterValidationForGenerator(1, newValues, scope)
            },
            formPath: [{path:'reportForm.power.mdgPowerMeter', errorKey: 'dieselGenPowerMeter1'}]
        },
        'mdg_power_meter_2': {
            watchVariables: [
                'report.power.mdg_power_meter_2_cur',
                'report.power.diesel_gen_2_diff_rh',
            ],
            check: function(newValues, oldValues, scope) {
                return mdgPowerMeterValidationForGenerator(2, newValues, scope)
            },
            formPath: [{path:'reportForm.power.mdgPowerMeter', errorKey: 'dieselGenPowerMeter2'}]
        },
        'mdg_power_meter_3': {
            watchVariables: [
                'report.power.mdg_power_meter_3_cur',
                'report.power.diesel_gen_3_diff_rh',
            ],
            check: function(newValues, oldValues, scope) {
                return mdgPowerMeterValidationForGenerator(3, newValues, scope)
            },
            formPath: [{path:'reportForm.power.mdgPowerMeter', errorKey: 'dieselGenPowerMeter3'}]
        },
        'mdg_power_meter_4': {
            watchVariables: [
                'report.power.mdg_power_meter_4_cur',
                'report.power.diesel_gen_4_diff_rh',
            ],
            check: function(newValues, oldValues, scope) {
                return mdgPowerMeterValidationForGenerator(4, newValues, scope)
            },
            formPath: [{path:'reportForm.power.mdgPowerMeter', errorKey: 'dieselGenPowerMeter4'}]
        },
        'mdg_power_meter_5': {
            watchVariables: [
                'report.power.mdg_power_meter_5_cur',
                'report.power.diesel_gen_5_diff_rh',
            ],
            check: function(newValues, oldValues, scope) {
                return mdgPowerMeterValidationForGenerator(5, newValues, scope)
            },
            formPath: [{path:'reportForm.power.mdgPowerMeter', errorKey: 'dieselGenPowerMeter5'}]
        },
        'mdg_power_meter_6': {
            watchVariables: [
                'report.power.mdg_power_meter_6_cur',
                'report.power.diesel_gen_6_diff_rh',
            ],
            check: function(newValues, oldValues, scope) {
                return mdgPowerMeterValidationForGenerator(6, newValues, scope)
            },
            formPath: [{path:'reportForm.power.mdgPowerMeter', errorKey: 'dieselGenPowerMeter6'}]
        },
    };

    
    return {
        init: ValidationServiceClass,
        // make helper functions public
        getInstalledPower: getInstalledPower,
        getMaxMainEngineTotalRunningHours: getMaxMainEngineTotalRunningHours,
        getMaxAuxEngineTotalRunningHours: getMaxAuxEngineTotalRunningHours,
        auxEngDidNotRun: auxEngDidNotRun,
        aeConsMoreThanMax: aeConsMoreThanMax,
        angleDistance: distanceBetweenTwoAngles,
    }
}();

const mdgPowerMeterValidationForGenerator = (index, newValues, scope) => {
    /**
     * Validation rule for each Diesel Generator Power Meter section in power tab
     * @param index - diesel generator number
     * @newValues - new values from scope watchGroup
     * @scope - local scope from angularjs
     */
    const [
        mdgPowerMeterCurReading,
        dieselGenDiffRh,
    ] = newValues;
    let valid = true;
    let type = ValidationType.INFO;
    let message = null;
    const dgIndex = index - 1;
    const path = `report.power.mdg_power_meter_${dgIndex + 1}_cur`;
    if (!scope.vessel_specfications || !scope.vessel_specfications.prime_mover || !scope.vessel_specfications.prime_mover.diesel_generators || !scope.vessel_specfications.prime_mover.diesel_generators[dgIndex]) return;
    let dgName = scope.vessel_specifications.prime_mover.diesel_generators[dgIndex].name;

    if (dieselGenDiffRh > 0 && (!mdgPowerMeterCurReading || mdgPowerMeterCurReading <= 0)) {
        valid = false;
        type = ValidationType.ERROR;
        message = `${dgName} Power Meter needs to be greater than previous reading`;
    } else if (mdgPowerMeterCurReading < 0) {
        valid = false;
        type = ValidationType.ERROR;
        message = `${dgName} Current Reading needs to be greater than 0`;
    }
    return new ValidationResult(
        valid,
        type,
        message,
        path
    );
}

const ValidationService = new validation.init();
export default ValidationService;

