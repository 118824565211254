import Highcharts from 'highcharts';
import moment from 'moment';
import { roundToPlaces, routerApp, themePalette } from '../../app.module';
import { arrayToCsv, downloadBlob } from '../../services/utilities';

routerApp.directive('drawFleetStatusWhen', ['$timeout', function($timeout) {
    function link($scope, $element, $attr) {
        $scope.$watch($attr.drawFleetStatusWhen, function(value) {
            if (value) {
                $timeout(function() {
                    $scope.drawFleetOpStatus();
                });
            }
        });
    }

    return {
        priority: -1,
        restrict: 'A',
        link: link
    };
}]);

routerApp.controller('voyageController', ['$rootScope', '$scope', '$state', 'VesselService', 'FleetService', 'VoyageService', 'TimezoneService',
    function($rootScope, $scope, $state, VesselService, FleetService, VoyageService, TimezoneService) {

    $rootScope.selectedMenu = 'voyage';

    $scope.options = {
        dateRange: 'ytd',
        reportFrom: null,
        reportTo: null,
        voyageIdType: VoyageService.getVoyageIdType() || { id: 'voyage_number', name: 'Voyage Number'}
    };

    $scope.dateRangeFrom = VoyageService.getDateRangeFrom();
    $scope.dateRangeTo = VoyageService.getDateRangeTo();

    $scope.voyageIdOptions = [
        {id: 'voyage_number', name: 'Voyage Number'},
        {id: 'tres_voyage_number', name: 'Navtor Voyage ID'}
    ]

    var dateRangeToLabel = {
        '10_days': '10 Days',
        'mtd': 'Month to Date',
        '1_month': '1 Month',
        '2_month': '2 Months',
        '3_month': '3 Months',
        'ytd': 'Past Year',
        'custom': 'Custom'
    };

    Array.prototype.sum = function() {
        return this.reduce(function(x, y) {
            return x + y;
        }, 0);
    };

    var vaRound = function(num) {
        var n = 1;
        return Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
    };

    var isNullOrWhitespace = function(s) {
        return s === null || (typeof s === 'string' && s.trim() === '');
    };

    $(document).mouseup(function(e) {
        var vesselSelectorContainer = $(".voyage-dropdown-menu > .dropdown-menu");
        var vesselSelectorLink = $(".voyage-dropdown-menu > a");
        var otherDropdowns = $('.dropdown-menu');
        var metricDateRangeLink = $('.metric-date-range > a');

        // @ts-ignore
        if (vesselSelectorLink.is(e.target)) {
            var closing = vesselSelectorContainer.parent().hasClass('open');
            if (closing) {
                reloadVoyages();
            };
            vesselSelectorContainer.parent().toggleClass('open');
        // @ts-ignore
        } else if (metricDateRangeLink.is(e.target)) {
            metricDateRangeLink.parent().toggleClass('open');
        // @ts-ignore
        } else if (!otherDropdowns.is(e.target) && otherDropdowns.has(e.target).length === 0) {
            var closing = vesselSelectorContainer.parent().hasClass('open');
            if (closing) {
                reloadVoyages();
            }
            closeDropdowns();
        }
    });

    $scope.$on('$stateChangeStart', function(e) {
        $(document).unbind('mouseup');
    });

    const DAYS_OF_FLEET_DATA = 10;

    $scope.selectedTimePeriod = 'ytd';

    var closeDropdowns = function() {
        $('.voyage-dropdown-menu').removeClass('open');
        $('.dropdown-menu').parent().removeClass('open');
    };

    $scope.changeDateRange = function(dateRange) {
        $scope.options.dateRange = dateRange;
        var now = new Date();
        var reportFrom = new Date();
        if (dateRange == '10_days') {
            $scope.options.reportTo = now;
            reportFrom.setDate(reportFrom.getDate() - 10);
            $scope.options.reportFrom = reportFrom;
            closeDropdowns();
        } else if (dateRange == '1_month') {
            $scope.options.reportTo = now;
            reportFrom.setDate(reportFrom.getDate() - 30);
            $scope.options.reportFrom = reportFrom;
            closeDropdowns();
        } else if (dateRange == '2_month') {
            $scope.options.reportTo = now;
            reportFrom.setDate(reportFrom.getDate() - 61);
            $scope.options.reportFrom = reportFrom;
            closeDropdowns();
        } else if (dateRange == '3_month') {
            $scope.options.reportTo = now;
            reportFrom.setDate(reportFrom.getDate() - 90);
            $scope.options.reportFrom = reportFrom;
            closeDropdowns();
        } else if (dateRange == 'mtd') {
            $scope.options.reportTo = now;
            $scope.options.reportFrom = new Date(now.getFullYear(), now.getMonth(), 1);
            closeDropdowns();
        } else if (dateRange == 'ytd') {
            $scope.options.reportTo = now;
            reportFrom.setDate(reportFrom.getDate() - 365);
            $scope.options.reportFrom = reportFrom;
            closeDropdowns();
        } else {
            $scope.options.reportTo = null;
            $scope.options.reportFrom = null;
        }
    };
    $scope.changeDateRange($scope.options.dateRange);
    var getDateRangeText = function(label, fromDate, toDate) {
        return label + ' (' + fromDate.toISOString().substring(0, 10) + ' - ' + toDate.toISOString().substring(0, 10) + ')';
    };
    var recalculateMetrics = function(reportFrom, reportTo) {
        VesselService.loadMetrics($rootScope.selectedVessel.id, reportFrom, reportTo).then(function(response) {
            VesselService.setMetrics(response.data.data);
            $scope.metrics = VesselService.getMetrics();
        }).catch(error => console.error(error));
    };
    $scope.$watchGroup(['options.reportTo', 'options.reportFrom'], function(values) {
        if ($scope.options.reportTo && $scope.options.reportFrom) {
            if ($scope.metrics === undefined && VesselService.getMetrics() !== null) {
                $scope.metrics = VesselService.getMetrics();
            } else {
                recalculateMetrics($scope.options.reportFrom, $scope.options.reportTo);
            }

            $scope.options.dateRangeText = getDateRangeText(
                dateRangeToLabel[$scope.options.dateRange],
                $scope.options.reportFrom,
                $scope.options.reportTo
            );
        }
    });
    $scope.$watch('customReportTo', function(value) {
        if (value) {
            $scope.options.reportTo = moment(value, 'DD/MM/YYYY HH:mm').toDate();
        }
    });
    $scope.$watch('customReportFrom', function(value) {
        if (value) {
            $scope.options.reportFrom = moment(value, 'DD/MM/YYYY HH:mm').toDate();
        }
    });

    $scope.getVoyages = VoyageService.getVoyages;

    var s = $scope;

    var showVoyages = function(from, to, voyageIdType) {
        // If from/to are null or whitespace, send null to loadVoyages.
        // Else, parse the date with momentjs.
        var fromDate = isNullOrWhitespace(from) ? null : moment(from, 'DD/MM/YYYY HH:mm');
        var toDate = isNullOrWhitespace(to) ? null : moment(to, 'DD/MM/YYYY HH:mm');

        VoyageService.loadVoyages(fromDate, toDate, voyageIdType.id).then(function(response) {
            VoyageService.setVoyages(response.data.data);
            if ((s.dateRangeFrom === null || s.dateRangeTo === null) && VoyageService.getVoyages().length > 0) {
                s.dateRangeFrom = moment(Math.min.apply(null, VoyageService.getVoyages().map(function(v) { return new Date(v.departure_date); }))).format('DD/MM/YYYY HH:mm');
                s.dateRangeTo = moment(Math.max.apply(null, VoyageService.getVoyages().map(function(v) { return new Date(v.arrival_date); }))).format('DD/MM/YYYY HH:mm');
            }
        });
    };

    var showVessels = function() {
        if (!$scope.vessels) {
            $scope.vessels = VesselService.getVessels();
        }

        if ($scope.vessels != undefined && VoyageService.getVoyages() === null) {
            showVoyages($scope.dateRangeFrom, $scope.dateRangeTo, $scope.options.voyageIdType);
        }

        if ((s.dateRangeFrom === null || s.dateRangeTo === null) && VoyageService.getVoyages() && VoyageService.getVoyages().length > 0) {
            s.dateRangeFrom = moment(Math.min.apply(null, VoyageService.getVoyages().map(function(v) { return new Date(v.departure_date); }))).format('DD/MM/YYYY HH:mm');
            s.dateRangeTo = moment(Math.max.apply(null, VoyageService.getVoyages().map(function(v) { return new Date(v.arrival_date); }))).format('DD/MM/YYYY HH:mm');
        }
    };
    showVessels();

    $scope.$on('vessel-data-received', function() {
        showVessels();
    });

    $scope.$watchGroup(['dateRangeFrom', 'dateRangeTo'], function(newValues, oldValues) {
        if ((oldValues[0] !== null || oldValues[1] !== null) &&
            (newValues[0] != oldValues[0] || newValues[1] != oldValues[1])) {
            reloadVoyages();
        }
        VoyageService.setDateRanges(newValues[0], newValues[1]);
    });

    $scope.openVesselDashboard = function(voyage) {
        $state.go('analytics.voyageAnalysisDetails');
        $rootScope.current_voyage = voyage;
    };

    $scope.pdf = true;

    $scope.drawFleetOpStatus = function() {
        var reportCountsByDate = $scope.fleetOperationalData;
        $scope.fleet = {
            anchor: [],
            maneuvering: [],
            port: [],
            sea: []
        };

        var dates = Object.keys(reportCountsByDate);
        var reportCounts = dates.map(function(key) { return reportCountsByDate[key]; });

        for (var i = 0; i < reportCounts.length; i++) {
            var counts = reportCounts[i];
            $scope.fleet['anchor'].push(counts['Report.AnchorReport'] || 0);
            $scope.fleet['maneuvering'].push(counts['Report.ManeuveringReport'] || 0);
            $scope.fleet['port'].push(counts['Report.PortReport'] || 0);
            $scope.fleet['sea'].push(counts['Report.SeaReport'] || 0);
        }

        $scope.fleetVesselCounts = Object.keys(reportCountsByDate).map(function(day) {
            var dayData = reportCountsByDate[day];
            return Object.keys(dayData).map(function(reportType) { return dayData[reportType]; }).sum();
        });
        $scope.maxVesselCount = Math.max.apply(null, $scope.fleetVesselCounts);

        $scope.fleetOperationalStatusChart = Highcharts.chart('chart-fleet-operational-status', {
            chart: {
                type: 'column'
            },
            title: { text: null },
            yAxis: {
                title: {
                    text: 'Vessels (%)'
                },
                max: 100,
                maxPadding: 0,
                endOnTick: false
            },
            credits: { enabled: false },
            exporting: { enabled: false },
            plotOptions: {
                column: {
                    stacking: 'normal'
                },
                series: { stacking: 'percent' }
            },
            tooltip: { formatter: function() {
                return this.x + '<br>' + this.series.name + ': <b>' + this.y + ' (' + vaRound(this.percentage) + '%)</b>';
            } },
            xAxis: {
                categories: dates,
                type: 'datetime',
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat('%d/%m/%Y', new Date(this.value));
                    }
                }
            },
            series: [
                {
                    name: 'Sea',
                    data: $scope.fleet['sea'],
                    color: themePalette.colors.SEA_REPORT_COLOR,
                },
                {
                    name: 'Port',
                    data: $scope.fleet['port'],
                    color: themePalette.colors.PORT_REPORT_COLOR,
                },
                {
                    name: 'Anchor/Drifting',
                    data: $scope.fleet['anchor'],
                    color: themePalette.colors.ANCHOR_REPORT_COLOR,
                },
                {
                    name: 'Maneuvering',
                    data: $scope.fleet['maneuvering'],
                    color: themePalette.colors.MANEUVER_REPORT_COLOR
                }
            ]
        });
    };

    if ($scope.fleetOperationalData == undefined && FleetService.getFleetOperationStatus() !== null) {
        $scope.fleetOperationalData = FleetService.getFleetOperationStatus();
    } else {
        FleetService.loadFleetOperationStatus($rootScope.username, DAYS_OF_FLEET_DATA).then(function(response) {
            FleetService.setFleetOperationStatus(response.data.data);
            $scope.fleetOperationalData = FleetService.getFleetOperationStatus();
        });
    }

    $scope.getChangeArrowColor = function(metric) {
        if (metric === undefined) return '';

        var colorClass = 'change-arrow-';
        var value = metric['weekly_delta'];

        if (value > 0) {
            colorClass += 'green';
        } else if (value === 0) {
            colorClass += 'yellow';
        } else if (value < 0) {
            colorClass += 'red';
        }

        return colorClass;
    };

    $scope.getChangeArrowAndValue = function(metric, swap) {
        if (metric === undefined) return '';

        var ret = '';
        var value = (swap && -metric['weekly_delta']) || metric['weekly_delta'];

        if (value > 0) {
            ret += '▲ ';
        } else if (value === 0) {
            ret += '▬ ';
        } else if (value < 0) {
            ret += '▼ ';
        }

        ret += Math.abs(value * 100).toFixed(1);

        return ret;
    };

    $scope.sortType = 'departure_date';
    $scope.sortReverse = true;

    var reloadVoyages = function() {
        showVoyages($scope.dateRangeFrom, $scope.dateRangeTo, $scope.options.voyageIdType);
    };

    $scope.allVessels = VoyageService.getAllVessels();

    $scope.isSelectAllSet = VoyageService.isSelectAllSet;

    $scope.toggleClass = function(key) {
        VoyageService.toggleVessel(key);
    };

    $scope.selectAll = VoyageService.selectAll;
    $scope.deselectAll = VoyageService.deselectAll;

    $scope.showPage = function() {
        // && true so that the function returns a boolean.
        return $scope.getVoyages() && $scope.metrics && $scope.fleetOperationalData && true;
    };

    $scope.$watch('options.voyageIdType', function(newValue, oldValue) {
        if (newValue != undefined && oldValue != undefined && newValue.id != oldValue.id) {
            reloadVoyages();
        }
        VoyageService.setVoyageIdType(newValue);
    });

    $scope.$watch('selectedVessel', function(vessel) {
        if (vessel) {
            VoyageService.deselectAll();
            VoyageService.toggleVessel(vessel.name);
            showVoyages(null, null, $scope.options.voyageIdType);
        }
    });

    $rootScope.formatVoyageDate = (date, tz): string => {
        var m = moment(date);
        var tzData = TimezoneService.getTimezones()[tz];
        if (tzData) {
            var offsetString = tzData.name.replace(/(\(.*?\)).*/, '$1');
            return m.format('DD/MM/YYYY HH:mm ') + offsetString;
        } else {
            return m.format('DD/MM/YYYY HH:mm');
        }
    };

    $scope.downloadVoyageList = () => {
        const voyages: Array<any> = VoyageService.getVoyages();
        const headers: Array<string> = [
            "Status",
            "Voyage #",
            "Vessel",
            "Date From",
            "Date To",
            "From",
            "To",
            "MT/24h",
            "CP Fuel Diff",
            "Distance",
            "Avg. Speed",
            "CP Speed Diff",
        ];
        const rows: Array<Array<string>> = voyages.map((voyage: any) => [
            voyage["status"],
            voyage["voyage_number"],
            voyage["vessel_name"],
            $rootScope.formatVoyageDate(voyage["departure_date"], voyage["departure_timezone"]),
            $rootScope.formatVoyageDate(voyage["arrival_date"], voyage["arrival_timezone"]),
            voyage["departure_port"],
            voyage["arrival_port"],
            roundToPlaces(voyage["fuel_24"], 2),
            roundToPlaces(voyage["cp_fuel_difference"], 2),
            roundToPlaces(voyage["total_observed_distance"], 2),
            roundToPlaces(voyage["average_observed_speed"], 2),
            roundToPlaces(voyage["cp_speed_difference"], 2),
        ]);
        const all: Array<Array<string>> = [headers].concat(rows);
        const csv = arrayToCsv(all);
        downloadBlob(csv, `voyage-list.csv`, 'text/csv;charset=utf-8;');
    };
}]);
