import { routerApp } from './app.module';
import './controllers';
import './services';
import './analytics';
import './directives';
import cacheBustVersions from './cacheBust';

// returns a template file with modified query string parameter to bust the
// cache when necessary
var getCacheBustedTemplate = function(templateFile) {
    if (cacheBustVersions != undefined && cacheBustVersions != undefined) {
        var bustTemplate = cacheBustVersions[templateFile];
        return bustTemplate != undefined ? bustTemplate : templateFile;
    } else {
        return templateFile;
    }
}

routerApp.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', function($stateProvider, $urlRouterProvider, $httpProvider) {
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    $urlRouterProvider.otherwise(function($injector, $location) {
        let isReloading;
        const path = $location.path();
        // added this hack to allow state.reload to work without redirecting to site/kpi
        // need to find out why state.reload resets $location path
        $injector.invoke(['$rootScope', function($rootScope) {
            isReloading = $rootScope && !!$rootScope.reloading && path == '';
            $rootScope.reloading = false;
          }]);
        if (!isReloading) {
            return '/';
        }        
    });

    $stateProvider
        .state('lobby', {
            url: '/',
            templateUrl: getCacheBustedTemplate('pages/lobby.html'),
            data: {
                roles: []
            },
            controller: 'lobbyController'
        })
        .state('login', {
            url: '/login',
            templateUrl: getCacheBustedTemplate('pages/login.html'),
            data: {
                roles: []
            },
            controller: 'loginController'
        })

        // main site page
        .state('site', {
            url: '/site',
            templateUrl: getCacheBustedTemplate('index2.html'),
            data: {
                roles: ['User']
            },
            params: {
                report: null,
                viewReport: null
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'mainController'
        })

        // crew dashboard pages
        .state('site.kpi', {
            url: '/kpi',
            templateUrl: getCacheBustedTemplate('pages/dashboard/kpi.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'kpiController'
        })
        .state('site.position', {
            url: '/position',
            templateUrl: getCacheBustedTemplate('pages/dashboard/position.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'positionController'
        })
        .state('site.summary', {
            url: '/summary',
            templateUrl: getCacheBustedTemplate('pages/dashboard/summary-stats.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'summaryStatsController'
        })
        .state('site.stock', {
            url: '/stock',
            templateUrl: getCacheBustedTemplate('pages/dashboard/stock-report.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'stockReportController'
        })

        // vessel reports
        .state('site.vesselPerformance', {
            url: '/vesselPerformance',
            templateUrl: getCacheBustedTemplate('pages/vessel-reports/vessel-performance.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'vesselPerformanceController'
        })
        .state('site.noonReport', {
            url: '/noonReport',
            templateUrl: getCacheBustedTemplate('pages/vessel-reports/noon-report.html'),
            data: {
                roles: ['User']
            },
            params: {
                report: null,
                viewReport: null
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'noonReportController'
        })
        .state('site.seaReport', {
            url: '/seaReport',
            templateUrl: getCacheBustedTemplate('pages/vessel-reports/sea-report.html'),
            data: {
                roles: ['User']
            },
            params: {
                report: null,
                viewReport: null,
                activeTab: null,
                scrollLocation: null,
            },
            resolve: {
                authorize: ['authorization', function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'seaReportController'
        })
        .state('site.portReport', {
            url: '/portReport',
            templateUrl: getCacheBustedTemplate('pages/vessel-reports/port-report.html'),
            data: {
                roles: ['User']
            },
            params: {
                report: null,
                viewReport: null,
                activeTab: null,
                scrollLocation: null,
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'portReportController'
        })
        .state('site.anchorReport', {
            url: '/anchorReport',
            templateUrl: getCacheBustedTemplate('pages/vessel-reports/anchor-report.html'),
            data: {
                roles: ['User']
            },
            params: {
                report: null,
                viewReport: null,
                activeTab: null,
                scrollLocation: null,
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'anchorReportController'
        })
        .state('site.maneuveringReport', {
            url: '/maneuveringReport',
            templateUrl: getCacheBustedTemplate('pages/vessel-reports/man-report.html'),
            data: {
                roles: ['User']
            },
            params: {
                report: null,
                viewReport: null,
                activeTab: null,
                scrollLocation: null,
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'manReportController'
        })
        .state('site.vesselSpecifications', {
            url: '/vesselSpecifications',
            templateUrl: getCacheBustedTemplate('pages/vessel-models/vessel-specification.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'vesselSpecificationsController'
        })
        .state('site.userSettings', {
            url: '/userSettings',
            templateUrl: getCacheBustedTemplate('pages/admin/user-settings.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'userSettingsController'
        })
        .state('site.clientSettings', {
            url: '/clientSettings',
            templateUrl: getCacheBustedTemplate('pages/admin/client-settings.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'clientSettingsController'
        })
        .state('site.modelsDashboard', {
            url: '/modelsDashboard',
            templateUrl: getCacheBustedTemplate('pages/vessel-models/models-dashboard.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize: ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'modelsDashboardController'
        })
        .state('site.meReport', {
            url: '/meReport',
            templateUrl: getCacheBustedTemplate('pages/engine-reports/me-performance.html'),
            data: {
                roles: ['User']
            },
            params: {
                engineReport: null,
                viewReport: null
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'mePerformanceController'
        })
        .state('site.aeReport', {
            url: '/aeReport',
            templateUrl: getCacheBustedTemplate('pages/engine-reports/ae-performance.html'),
            data: {
                roles: ['User']
            },
            params: {
                engineReport: null,
                viewReport: null
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'aePerformanceController'
        })
        .state('site.engineReports', {
            url: '/engineReports',
            templateUrl: getCacheBustedTemplate('pages/engine-reports/engine-reports.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'engineReportsController'
        })
        .state('site.offhireReports', {
            url: '/offhireReports',
            templateUrl: getCacheBustedTemplate('pages/offhire-reports/offhire-reports.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'offhireReportsController'
        })
        .state('site.offhireReport', {
            url: '/offhireReport',
            templateUrl: getCacheBustedTemplate('pages/offhire-reports/offhire-report.html'),
            data: {
                roles: ['User']
            },
            params: {
                offhireReport: null,
                viewReport: null
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'offhireReportsController'
        })
        .state('site.managementClients', {
            url: '/management/clients',
            templateUrl: getCacheBustedTemplate('pages/management/view-clients.html'),
            data: {
                roles: ['User']
            },
            params: {
                cameFromVessel:false,
                clientId:null
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'managementClientsController'
        })
        .state('site.managementUsers', {
            url: '/management/users',
            templateUrl: getCacheBustedTemplate('pages/management/view-users.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'managementUsersController'
        })
        .state('site.managementVessels', {
            url: '/management/vessel',
            templateUrl: getCacheBustedTemplate('pages/management/view-vessels.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'managementVesselsController'
        })

        // analytics app
        .state('analytics', {
            url: '/analytics',
            templateUrl: getCacheBustedTemplate('pages/analytics/index2.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'analyticsController'
        })
        .state('analytics.fleetTracker', {
            url: '/fleetTracker',
            templateUrl: getCacheBustedTemplate('pages/analytics/fleet-tracker.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'fleetController'
        })
        .state('analytics.fleetPerformance', {
            url: '/fleetPerformance',
            templateUrl: getCacheBustedTemplate('pages/analytics/fleet-performance.html'),
            controller: 'fleetPerformanceController'
        })
        .state('analytics.fuelTables', {
            url: '/fuelTables',
            templateUrl: getCacheBustedTemplate('pages/analytics/fuel-tables.html'),
            controller: 'fuelTablesController'
        })
        // dashboard
        .state('analytics.dashboard', {
            url: '/dashboard',
            templateUrl: getCacheBustedTemplate('pages/analytics/dashboard.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'dashboardController'
        })
        // voyage analysis
        .state('analytics.voyageAnalysis', {
            url: '/voyageAnalysis',
            templateUrl: getCacheBustedTemplate('pages/analytics/voyage-analysis.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'voyageController'
        })
        // voyage plan
        .state('analytics.voyagePlan', {
            url: '/voyagePlan',
            templateUrl: getCacheBustedTemplate('pages/analytics/voyage-plan.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'voyagePlanController'
        })
        // activity report
        .state('analytics.activityReport', {
            url: '/activityReport',
            templateUrl: getCacheBustedTemplate('pages/analytics/activity-report.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'activityReportController'
        })
        // activity report
        .state('analytics.activityReportCapital', {
            url: '/activityReportCapital',
            templateUrl: getCacheBustedTemplate('pages/analytics/activity-report-capital.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'activityReportCapitalController'
        })
        // eagle activity report
        .state('analytics.eagleActivityReport', {
            url: '/activityReportEagle',
            templateUrl: getCacheBustedTemplate('pages/analytics/activity-report-eagle.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'activityReportEagleController'
        })
        // osm activity report
        .state('analytics.gccActivityReport', {
            url: '/activityReportOSM',
            templateUrl: getCacheBustedTemplate('pages/analytics/activity-report-osm.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'activityReportOSMController'
        })
        // INSW activity report
        .state('analytics.inswActivityReport', {
            url: '/activityReportINSW',
            templateUrl: getCacheBustedTemplate('pages/analytics/activity-report-insw.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'activityReportINSWController'
        })
        // Tres activity report
        .state('analytics.tresActivityReport', {
            url: '/activityReportTres',
            templateUrl: getCacheBustedTemplate('pages/analytics/activity-report-tres.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'activityReportTresController'
        })
        .state('analytics.dataReports', {
            url: '/dataReports',
            templateUrl: getCacheBustedTemplate('pages/analytics/dataReports.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'dataReportsController'
        })
        // my reports
        .state('analytics.myReports', {
            url: '/myReports',
            templateUrl: getCacheBustedTemplate('pages/analytics/my-reports.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'reportsController'
        })
        // tableau analytics embed
        .state('analytics.executiveDashboard', {
            url: '/executiveDashboard',
            templateUrl: getCacheBustedTemplate('pages/analytics/tableau-analytics.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'tableauController'
        })
        // tableau analytics embed
        .state('analytics.realTimeAnalytics', {
            url: '/realTimeAnalytics',
            templateUrl: getCacheBustedTemplate('pages/analytics/tableau-analytics.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'tableauController'
        })
        // mrv reports
        .state('analytics.mrvReporting', {
            url: '/mrvReporting',
            templateUrl: getCacheBustedTemplate('pages/analytics/mrv-reporting.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'mrvReportingController'
        })
        .state('analytics.imoDcsReporting', {
            url: '/imoDcsReporting',
            templateUrl: getCacheBustedTemplate('pages/analytics/imo-dcs-reporting.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'imoDcsReportingController'
        })
        // takeaways timeline
        .state('analytics.tresTakeaways', {
            url: '/tresTakeaways',
            templateUrl: getCacheBustedTemplate('pages/analytics/tres-takeaways.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'takeawaysController'
        })
        // voyage analysis report
        .state('analytics.voyageAnalysisReport', {
            url: '/voyageAnalysisReport',
            templateUrl: getCacheBustedTemplate('pages/analytics/voyage-analysis-report.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'voyageAnalysisReportController'
        })
        .state('analytics.voyageAnalysisDetails', {
            url: '/voyageAnalysisDetails',
            templateUrl: getCacheBustedTemplate('pages/analytics/voyage-analysis-details.html'),
            data: {
                roles: ['User']
            },
            resolve: { authorize:   ['authorization',
                function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'voyageAnalysisDetailsController'
        });
    
    if (process.env.NODE_ENV == 'test') {
        /** 
         * for build:test:edge build. 
         * Go to site/test url and open dev console for testing
         * */ 
        $stateProvider
        .state('site.test', {
            url: '/test',
            templateUrl: getCacheBustedTemplate('pages/vessel-reports/test-report.html'),
            data: {
                roles: ['User']
            },
            resolve: {
                authorize: ['authorization', function(authorization) {
                    return authorization.authorize();
                }
            ]},
            controller: 'testController'
        });
    }
        
        


}]);

export { getCacheBustedTemplate };
