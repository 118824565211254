import { routerApp, analyticsUrl } from '../../app.module';

routerApp.factory('GraphService', ['$http', function($http) {

    var fixArrayDecimals = function(array, xAxis, decimals) {
        var digits = decimals;
        if (digits == undefined) {
            digits = 10;
        }
        for (var i = 0; i < array.length; i++) {
            array[i][1] = Math.round(array[i][1] * digits) / digits;
            if (xAxis) {
                array[i][0] = Math.round(array[i][0] * digits) / digits;
            }
        }
        return array;
    }

    var getTimeSeriesData = function(response, dateRanges, variables) {
        var data = {};
        for (var i = 0; i < dateRanges.length; i++) {
            var processedDateRangeData = {};
            var dateRangeData = response[dateRanges[i]];
            for (var j = 0; j < variables.length; j++) {
                var processedVariableData = [];

                var variableSplits = variables[j].split('.');
                var variableName = variableSplits[variableSplits.length - 1];

                var variablesData = dateRangeData[variableName];
                for (var k = 0; k < variablesData.length; k++) {
                    var timestamp = Math.floor(dateRangeData['timestamp'][k]/6000000)*6000000;

                    processedVariableData.push([timestamp, variablesData[k]]);
                }
                processedDateRangeData[variableName] = processedVariableData;
            }
            data[dateRanges[i]] = fixArrayDecimals(processedDateRangeData, false, 1000);
        }
        return data;
    }

    return {
        getGraphsData: function(vesselId, variables) {
            var url = analyticsUrl + vesselId + "/test?variables=" + variables.join();
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        getVoyageGraphsData: function(vesselId, variables) {
            var url = analyticsUrl + vesselId + "/voyageGraphs?variables=" + variables.join();
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        getKPIData: function(vesselId) {
            var url = analyticsUrl + vesselId + "/kpiBreakdown";
            return $http({
                url: url,
                method: "GET"
            });
        },
        getTimeSeriesData: getTimeSeriesData,
        getGraphsBetweenDates: function(vesselId, variables, after, before, report_types) {
            return $http.post(analyticsUrl + vesselId + "/variablesBetween", {
                    variables: variables.join(),
                    after: after,
                    before: before,
                    report_types: report_types && report_types.join()
            });
        },
        getAISBetweenDates: function(vesselId, after, before) {
            return $http({
                url: analyticsUrl + vesselId + "/aisBetween",
                params: {
                    after: after,
                    before: before,
                }
            });
        },
    };

}]);
