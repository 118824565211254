import { routerApp, analyticsUrl } from '../../app.module';

routerApp.factory('TakeawaysService', ['$http', function($http) {

    return {
        getTakeaways: function(vesselId) {
            var url = analyticsUrl + vesselId + "/tresTakeaways";
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        postTakeaway: function(vesselId, obj) {
            var url = analyticsUrl + vesselId + "/tresTakeaways";
            return $http({
                    url: url,
                    method: "POST",
                    data: obj
            });
        }
    }

}]);