import { routerApp } from '../../app.module';

routerApp.factory('UserClientService', function() {

    var clientWithAllViews = 'tres';

    return {
        // determines if we should show the view for a given user/client name.
        isViewForUser: function(user, clientName) {
            return user.client == clientName || user.client == clientWithAllViews || (user.secondaryClients != undefined && user.secondaryClients.indexOf(clientName) != -1);
        },

        // determines whether the user belongs to one of the client names
        // in the `clients` list.
        userBelongsToClients: function(user, clients) {
            if (user.client == clientWithAllViews) {
                return true;
            } else {
                for (var i = 0; i < clients.length; i++) {
                    if (user.client == clients[i]) {
                        return true;
                    }
                }
                return false;
            }
        },
    }

});
