import { routerApp } from '../app.module';
import { getCacheBustedTemplate } from '../app.route';

routerApp.directive('tankPressuresTemps', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/directives/tank-pressures-temps.html'),
        link: function(scope, element, attributes, control) {
        }
    };
});

routerApp.directive('tankGradePressuresTemps', ['VesselSpecificationService', function(VesselSpecificationService) {
    return {
        templateUrl: getCacheBustedTemplate('pages/directives/tank-grade-pressures-temps.html'),
        link: function(scope, element, attributes, control) {
            // add list of lpg tanks to store data
            scope.reportTab = attributes.reportTab;
            var vesselSpec = VesselSpecificationService.getSpecifications();
            var createLpgTankRows = function(tanks) {
                var tankRows = [];
                for (var i = 0; i < tanks.length; i++) {
                    tankRows.push(tanks[i]);
                }
                return tankRows;
            };
            scope.cargo_lpg_tanks = createLpgTankRows(vesselSpec.lpg_tanks);
            
        }
    };
}]);
