import Highcharts from 'highcharts';
import { Color, routerApp, themePalette } from '../../app.module';
import moment from 'moment';

routerApp.controller('fuelTablesController', ['$timeout', '$rootScope', '$scope', 'FuelTablesService', 'UtilityService', '$http', 
    function($timeout, $rootScope, $scope, FuelTablesService, UtilityService, $http) {

    $rootScope.selectedMenu = 'fuel-tables';

    $scope.barCharts = {};
    $scope.graphs = {};
    $scope.draughtOptions = {};
    $scope.fuelTable = {};
    $scope.sortReverse = true;

    $scope.bf = "1";
    $scope.propulsionChange = "0";

    $('#scroll-bar').on('scroll', function () {
        var totalWidth = $('#scroll-bar').prop("scrollWidth");
        var ratio = $(this).scrollLeft() / totalWidth;
        var topRightHeaderWidth = $('.right-content').prop("scrollWidth");
        var maxScrollLeft = topRightHeaderWidth * ratio;
        $('.right-content').scrollLeft(maxScrollLeft);
        $('.top-right-header').scrollLeft(maxScrollLeft);
    });

    // adds functionality to dropdowns
    $('.dropdown-draughts a').on('click', function (event) {
        $(this).parent().toggleClass('open');

    });

    $(document).mouseup(function(e) {
        var container = $(".dropdown-draughts");

        // @ts-ignore
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.dropdown-draughts').parent().removeClass('open');
        }
    });

    $scope.watchRegistered = false;
    $scope.registerWatchOnInputParams = function() {
        if ($scope.watchRegistered) {
            return;
        }
        $scope.$watchGroup(['propulsionChange', 'bf', 'dates.dashboardDateFrom', 'dates.dashboardDateTo'], function(newValues, oldValues) {
            if (newValues[0] == oldValues[0] && newValues[1] == oldValues[1] && newValues[2] == oldValues[2] && newValues[3] == oldValues[3])
                return;
            var propulsionChange = newValues[0];
            var fromDate = newValues[2];
            // bf and dateTo are reference inside these functions
            // so still need to watch for variable change
            getReportData(fromDate);
            $scope.loadModelData(propulsionChange);
        });
        $scope.watchRegistered = true;
    }

    // shows list of highcharts series
    var showAllSeries = function(allSeries) {
        for (var i = 0; i < allSeries.length; i++) {
            allSeries[i].show();
        }
    }
    // hides list of highcharts series
    var hideAllSeries = function(allSeries) {
        for (var i = 0; i < allSeries.length; i++) {
            allSeries[i].hide();
        }
    }

    // toggle fuel draught, this is used when the user toggled on/off a draught
    // from the list of draughts, a toggle on the fuel consumption graph also
    // applies to the dropdown on the power vs speed graph
    $scope.toggleFuelDraught = function(draughtIndex) {
        var isToggled = $scope.draughtOptions.fuelDraughts[draughtIndex].toggled;
        var newToggledValue = !isToggled;
        $scope.draughtOptions.fuelDraughts[draughtIndex].toggled = newToggledValue;
        var draughtSeries = $scope.draughtOptions.fuelDraughts[draughtIndex];

        if (newToggledValue) {
            if (draughtSeries.modelFuelSeries) { showAllSeries(draughtSeries.modelFuelSeries); }
            if (draughtSeries.reportedFuelSeries) { showAllSeries(draughtSeries.reportedFuelSeries); }
            if (draughtSeries.modelPowerSeries) { showAllSeries(draughtSeries.modelPowerSeries); }
            if (draughtSeries.reportedPowerSeries) { showAllSeries(draughtSeries.reportedPowerSeries); }
        } else {
            if (draughtSeries.modelFuelSeries) { hideAllSeries(draughtSeries.modelFuelSeries); }
            if (draughtSeries.reportedFuelSeries) { hideAllSeries(draughtSeries.reportedFuelSeries); }
            if (draughtSeries.modelPowerSeries) { hideAllSeries(draughtSeries.modelPowerSeries); }
            if (draughtSeries.reportedPowerSeries) { hideAllSeries(draughtSeries.reportedPowerSeries); }
        }
    }

    // groups series by draught, key1 is the xaxis key, key2 is the yaxis
    var groupByDraught = function(series, key1, key2) {
        var key1Series = series[key1];
        var key2Series = series[key2];
        var draughtSeries = series['average_draught']

        return draughtSeries.reduce(function(acc, draught, index) {
            acc[draught] = acc[draught] || {
                enabledForPowerGraph: true,
                enabledForConsumptionsGraph: true,
                series: []
            };
            acc[draught].series.push([
                key1Series[index],
                key2Series[index]
            ]);
            return acc;
        }, {});
    }

    // load reported data that is used on:
    // - fuel vs speed graph
    // - power vs speed graph
    // - fuel consumption analysis graph
    var getReportData = function(fromDate = undefined) {

        FuelTablesService.getGraphs($rootScope.selectedVessel.id, fromDate).then(function(response) {
            var data = response.data;
            var series = data.data;
            $scope.graphs.fuelGraphReportedData = groupByDraught(series, 'log_speed', 'total_me_consumption_24');
            $scope.graphs.powerGraphReportedData = groupByDraught(series, 'log_speed', 'power');
        });

        FuelTablesService.getFuelConsumptionAnalysis($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;
            var fuelConsumptionAnalysisData = data.data;
            var flowMeterConsumption = fuelConsumptionAnalysisData['flow_meter_consumption'];
            var meConsumption = fuelConsumptionAnalysisData['total_consumption'];
            var stockConsumption = fuelConsumptionAnalysisData['stock_consumption'];

            // calculate deviations in percent
            var meConsumptionPercent = 100;
            var stockConsumptionPercent = stockConsumption * 100 / meConsumption;
            var flowMeterConsumptionPercent = flowMeterConsumption * 100 / meConsumption;
            $scope.barCharts.consumptionsBarChart = {
                meConsumption: meConsumption,
                stockConsumption: stockConsumption,
                flowMeterConsumption: flowMeterConsumption,

                meConsumptionPercent: meConsumptionPercent,
                stockConsumptionPercent: stockConsumptionPercent,
                flowMeterConsumptionPercent: flowMeterConsumptionPercent
            }
        });
    }

    $scope.setFuelTableColumnWidths = function(forceSetWidths) {
        if (!$scope.fuelTable.draughts) return;

        var fullTableWidth = $('#full-table').width();
        var speedAndBaselinesWidth = $('table#speeds-and-cp-baselines').width();
        var minimumDataTableWidth = fullTableWidth - speedAndBaselinesWidth;
        var dataTableWidth = $('table#data-table').width();

        var numberOfDraughtColumns = $scope.fuelTable.draughts.length;
        var smallCellWidth = minimumDataTableWidth / numberOfDraughtColumns;

        if (smallCellWidth < 100) smallCellWidth = 100;

        if (dataTableWidth <= minimumDataTableWidth || forceSetWidths) {
            if (smallCellWidth) {
                // Set cell widths to fill space
                $('.right-content .small-cell').css('width', smallCellWidth + 'px');
                $('.top-right-header .small-cell').css('width', smallCellWidth + 'px');

                if (smallCellWidth == 100) {
                    $('#scroll-bar').show();
                } else {
                    // Remove scroll bar since the columns will fit the space exactly now
                    $('#scroll-bar').hide();
                }
            }
        }
    };

    $scope.loadModelData = function(propulsionChange = undefined) {
        var propulsionChange = $scope.propulsionChange;
        var bf = $scope.bf;
        var fromDate = $scope.dates ? $scope.dates.dashboardDateFrom : null;
        var toDate = $scope.dates ? $scope.dates.dashboardDateTo : null;
        FuelTablesService.getFuelTables($rootScope.selectedVessel.id, propulsionChange, bf, fromDate, toDate).then(function(response) {
            var data = response.data
            $scope.data = data.data;
            var fuelTable = data.data;

            // set up data for fuel table
            $scope.propulsionEfficiency = fuelTable.propulsionEfficiency;
            $scope.avgLCV = fuelTable.averageLCV;
            $scope.fuelTable.draughts = FuelTablesService.getDraughts(fuelTable.fuel_table);
            $scope.fuelTable.speedAndFuels = FuelTablesService.groupBySpeed(fuelTable.fuel_table, fuelTable.loaded_baselines, fuelTable.ballast_baselines);

            // set up data for fuel graph
            var fuelGraphModelData = FuelTablesService.getFuelGraphData(fuelTable.fuel_table);
            var fuelGraphDraughts = [];
            for (var i = 0; i < $scope.fuelTable.draughts.length; i++) {
                var draught = $scope.fuelTable.draughts[i];
                fuelGraphDraughts.push({
                    text: draught,
                    nDataPoints: 0,
                    toggled: false
                });
            }
            $scope.draughtOptions.fuelDraughts = fuelGraphDraughts;
            $scope.graphs.fuelGraphModelData = fuelGraphModelData;

            // set up data for power graph
            $scope.graphs.powerGraphModelData = FuelTablesService.getPowerGraphData(fuelTable.fuel_table);

            // set min speed used for both graphs
            $scope.graphs.minChartSpeed = FuelTablesService.getMinSpeed(fuelTable.fuel_table);
            $scope.graphs.maxChartSpeed = FuelTablesService.getMaxSpeed(fuelTable.fuel_table);

            $timeout($scope.setFuelTableColumnWidths);
            $(window).resize(function() {
                $scope.setFuelTableColumnWidths(true);
            });

            $scope.registerWatchOnInputParams();
        }, function(response) {
            // handle error
        });
    }

    $scope.$watch('graphs', function(graphs) {
        if (graphs != undefined && graphs.fuelGraphModelData != undefined && graphs.fuelGraphReportedData != undefined) {
            $timeout(drawConsumptionsChart, 750);
        }
        if (graphs != undefined && graphs.powerGraphModelData != undefined && graphs.powerGraphReportedData != undefined) {
            $timeout(drawPowerChart, 750);
        }
    }, true);
    $scope.$watch('barCharts', function(barCharts) {
        if (barCharts != undefined && barCharts.consumptionsBarChart) {
            $timeout(drawConsumptionsAnalysisBarChart, 750);
        }
    }, true);

    // load all the needed data with these two functions
    getReportData();
    $scope.loadModelData();

    // used to highlight a highcharts series, by dimming the other scatter plot
    // points and greying out model consumption lines, used for both fuel vs
    // speed graph and power vs speed graphs
    function highlight(series, index, hide) {
        $(series).each(function (i, serie) {
            if(serie.options.metaData.draughtIndex != index) {

                $.each(serie.data, function (k, data) {
                    if(data.series) {
                        data.series.graph && data.series.graph.attr("stroke", (hide ? "#D4D4D4": serie.color));
                        data.series.graph && data.series.graph.attr("fillColor", (hide ? "#D4D4D4": serie.color));

                        data.series.options.color = '#d4d4d4';
                        data.series.markerGroup && data.series.markerGroup.attr("opacity", (hide ? 0.3: 1));
                        data.series.markerGroup && data.series.markerGroup.attr("fillColor", (hide ? "#D4D4D4": serie.color));
                        data.series.markerGroup && data.series.markerGroup.attr("color", (hide ? "#D4D4D4": serie.color));
                    }
                });

            } else {
                serie.group.toFront();
                $.each(serie.data, function (k, data) {
                    if(data.series) {
                        data.series.graph && data.series.graph.attr("stroke", serie.color);
                    }
                });
            }
        });
    }

    // used to draw consumption chart on top left
    var drawConsumptionsChart = function() {
        var fuelSeries = [];
        var toggledDraughts = {};
        var fuelReportedGraphs = $scope.graphs.fuelGraphReportedData;

        // abstract series containing no data used for legend
        fuelSeries.push({
            type: 'scatter',
            id: 'reported-draught-abstract',
            showInLegend: true,
            name: 'Reported Data',
            metaData: {
                draughtIndex: 0,
                isModelSeries: true,
                shouldShowOnGraph: false,
            },
            visible: true,
            data: false,
            width: 1,
            color: Color.GRAPH_BLUE,
            zIndex: 2,
            marker: {
                enabled: true,
                radius: 4,
                symbol: 'circle'
            }
        });

        Object.entries(fuelReportedGraphs).forEach(([key, value]) => {
            let draught:number = +key;
            var draughtSeriesName = 'Reported Fuel for Draught ' + draught;
            var series = fuelReportedGraphs[draught].series;

            // for this draught, find the closest model draught we have
            var draughtDifferences = $scope.fuelTable.draughts.map(function(element) {
                return Math.abs(element - draught);
            }, 0);
            var draughtIndex = draughtDifferences.reduce(function(lowest, next, index) {
                return next < draughtDifferences[lowest] ? index : lowest;
            }, 0);
            $scope.draughtOptions.fuelDraughts[draughtIndex].toggled = true;
            toggledDraughts[draughtIndex] = true;

            fuelSeries.push({
//                linkedTo: 'reported-draught-abstract',
                showInLegend: false,
                name: draughtSeriesName,
                metaData: {
                    draughtIndex: draughtIndex,
                    isModelSeries: false,
                    shouldShowOnGraph: true,
                },
                visible: true,
                data: series,
                width: 1,
                color: Color.GRAPH_BLUE,
                zIndex: 2,
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol: 'circle'
                }
            });
        });

        /*
        var fuelModelGraphs = $scope.graphs.fuelGraphModelData;
        fuelSeries.push({
            type: 'spline',
            id: 'model-draught-abstract',
            name: 'Simulated Performance',
            metaData: {
                draughtIndex: 0,
                isModelSeries: true,
                shouldShowOnGraph: false,
            },
            visible: true,
            data: [],
            showInLegend: true,
            color: Color.DATA_4
        });
        
        for (let draughtIndex = 0; draughtIndex < fuelModelGraphs.length; draughtIndex++) {
            var draught = fuelModelGraphs[draughtIndex][0];
            var draughtSeriesName = 'Model Fuel for Draught ' + draught;
            var series = fuelModelGraphs[draughtIndex][1];

            var visible = toggledDraughts[draughtIndex] ? true : false;

            fuelSeries.push({
                name: 'Simulated Performance',
                metaData: {
                    draughtIndex: draughtIndex,
                    isModelSeries: true,
                    shouldShowOnGraph: false,
                },
                visible: false,
                data: series,
                showInLegend: false,
                color: Color.DATA_4,
                zIndex: 1,
                tooltip: {
                    enabled: false,
                    enableMouseTracking: false
                },
                hover: {
                    enabled: false
                },
                marker: {
                    enabled: false,
                    allowPointSelect: false,
                    enableMouseTracking: false,
                },
                regression: true,
                regressionSettings: {
//                    linkedTo: 'model-draught-abstract',
                    metaData: {
                        draughtIndex: draughtIndex,
                        isModelSeries: true,
                        shouldShowOnGraph: true,
                    },
                    hideInLegend: true,
                    visible: visible,
                    name: draughtSeriesName,
                    type: 'polynomial',
                    color: Color.DATA_4,
                    dashStyle: 'solid',
                    tooltip: {
                        enableMouseTracking: false,
                        enabled: false,
                        formatter: null,
                        pointFormatter: null
                    },
                    hover: {
                        enabled: false,
                        halo: {
                            size: 0
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    dataLabels: { enabled: false },
                    allowPointSelect: false,
                    animation: false,
                    animationLimit: 0,
                    enableMouseTracking: false,
                }
            });
        }
        */

        Highcharts.chart('consumption-graph', {
            chart: {
                zoomType: 'xy',
                type: 'scatter',
                events: {
                    load: function() {
                        var chart = this;
                        $(chart.series).each(function (i, serie) {
                            if (serie.options.metaData.shouldShowOnGraph && serie.options.metaData.draughtIndex != undefined) {
                                var seriesKey = serie.options.metaData.isModelSeries ? 'modelFuelSeries' : 'reportedFuelSeries';
                                if ($scope.draughtOptions.fuelDraughts[serie.options.metaData.draughtIndex][seriesKey] == undefined) {
                                    $scope.draughtOptions.fuelDraughts[serie.options.metaData.draughtIndex][seriesKey] = [];
                                }
                                $scope.draughtOptions.fuelDraughts[serie.options.metaData.draughtIndex][seriesKey].push(serie);
                            }
                        });
                    }
                }
            },
            title: {
                text: null
            },
            subtitle: {
                text: 'ME Cons (MT/24hrs)'
            },
            xAxis: {
                min: $scope.graphs.minChartSpeed,
                max: $scope.graphs.maxChartSpeed,
                tickInterval: 1,
                startOnTick: true,
                endOnTick: true,
                title: {
                    enabled: true,
                    text: 'Log Speed (kn)'
                },
                labels: {
                    format: '{value:.1f}'
                },
            },
            yAxis: {
                min: 0,
                tickInterval: 10,
                startOnTick: true,
                endOnTick: true,
                title: {
                    enabled: true,
                    text: 'ME Cons (MT/24hrs)'
                }
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                },
                scatter: {
                    tooltip: {

                        enabled: true,
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: 'ME Consumption: {point.y:.1f} MT/24hrs<br>Log Speed: {point.x:.1f} kn'
                    },
                    point: {
                        events: {
                            mouseOver: function() {
                                var serie = this.series;
                                highlight(this.series.chart.series, serie.options.metaData.draughtIndex, true);
                            },
                            mouseOut: function() {
                                var serie = this.series;
                                highlight(this.series.chart.series, serie.options.metaData.draughtIndex, false);
                            }
                        }
                    },
                    legendItemClick: function (e) {
                        return false;
                    }
                },
                spline: {
                    tooltip: {
                        enableMouseTracking: false,
                        enabled: false,
                        formatter: null,
                        pointFormatter: null
                    },
                    hover: {
                        enabled: false,
                        halo: {
                            size: 0
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    dataLabels: { enabled: false },
                    allowPointSelect: false,
                    animation: false,
                    animationLimit: 0,
                    enableMouseTracking: false,
                    legendItemClick: function () {
                        return false;
                    }
                }
            },
            legend: {
                enabled: true
            },
            credits: {
              enabled: false
            },
            exporting: {
                enabled: false
            },
            series: fuelSeries
        });
    }
    // used to draw consumption chart on top right
    var drawConsumptionsAnalysisBarChart = function() {
        var consumptionsChartData = $scope.barCharts.consumptionsBarChart;
        var series = [{
            name: 'Consumptions',
            data: [
                {
                    y: consumptionsChartData.meConsumption,
                    percent: consumptionsChartData.meConsumptionPercent
                }, {
                    y: consumptionsChartData.stockConsumption,
                    percent: consumptionsChartData.stockConsumptionPercent
                }
            ]
        }];
        var consumptions = [consumptionsChartData.meConsumption, consumptionsChartData.stockConsumption, consumptionsChartData.flowMeterConsumption];
        var minConsumption = consumptions.min() * 0.8;
        var maxConsumption = consumptions.max() * 1.2;

        Highcharts.chart('consumption-analysis-graph', {
            chart: {
                type: 'column'
            },
            title: {
                text: null,
            },
            subtitle: {
                text: 'Accumulated Fuel'
            },
            xAxis: {
                categories: [
                    'Total Reported',
                    'Stock Consumed',
                ],
                crosshair: true
            },
            yAxis: {
                min: minConsumption,
                max: maxConsumption,
                tickInterval: 100,
                title: {
                    text: 'Fuel (MT)'
                }
            },
            credits: { enabled: false },
            exporting: { enabled: false },
            legend: {
                enabled: false,
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.1f} MT</b><br/>Percent of Total Reported: <b>{point.percent:.1f}</b> %'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.percent:,.0f} %',
                        color: themePalette.colors.THEME_TEXT_COLOR,
                    },
                }
            },
            series: series
        });
    }
    // used to draw power chart on bottom of the page
    var drawPowerChart = function() {

        var powerSeries = [];

        var toggledDraughts = {};
        var powerReportedGraphs = $scope.graphs.powerGraphReportedData;
        powerSeries.push({
            name: 'Reported Data',
            id: 'reported-draught-abstract',
            metaData: {
                draughtIndex: 0,
                isModelSeries: false,
                shouldShowOnGraph: true,
            },
            data: [],
            width: 1,
            color: Color.GRAPH_BLUE,
            zIndex: 1,
            showInLegend: true,
        });

        Object.entries(powerReportedGraphs).forEach(([key, value]) => {
            let draught:number = +key;
            var draughtSeriesName = 'Reported Power for Draught ' + draught;
            var series = powerReportedGraphs[draught].series;

            // for this draught, find the closest model draught we have
            var draughtDifferences = $scope.fuelTable.draughts.map(function(element) {
                return Math.abs(element - draught);
            }, 0);
            var draughtIndex = draughtDifferences.reduce(function(lowest, next, index) {
                return next < draughtDifferences[lowest] ? index : lowest;
            }, 0);
            toggledDraughts[draughtIndex] = true;

            powerSeries.push({
                name: draughtSeriesName,
                metaData: {
                    draughtIndex: draughtIndex,
                    isModelSeries: false,
                    shouldShowOnGraph: true,
                },
                linkedTo: 'reported-draught-abstract',
                data: series,
                width: 1,
                color: Color.GRAPH_BLUE,
                zIndex: 1,
                showInLegend: false,
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol: 'circle'
                }
            });
        });

        /*
        var powerGraphs = $scope.graphs.powerGraphModelData;
        
        powerSeries.push({
            type: 'spline',
            id: 'model-draught-abstract',
            name: 'Simulated Performance',
            metaData: {
                draughtIndex: 0,
                isModelSeries: true,
                shouldShowOnGraph: false,
            },
            visible: true,
            data: [],
            showInLegend: true,
            color: Color.DATA_4
        });
        for (let draughtIndex = 0; draughtIndex < powerGraphs.length; draughtIndex++) {
           

            var draught = powerGraphs[draughtIndex][0];
            var draughtSeriesName = 'Power for Draught ' + draught;
            var series = powerGraphs[draughtIndex][1];

            var visible = toggledDraughts[draughtIndex] ? true : false;
            
            powerSeries.push({
                name: draughtSeriesName,
                metaData: {
                    draughtIndex: draughtIndex,
                    isModelSeries: true,
                    shouldShowOnGraph: false,
                },
                visible: false,
                data: series,
                showInLegend: false,
                color: Color.DATA_4,
                tooltip: {
                    enabled: false,
                    enableMouseTracking: false
                },
                hover: {
                    enabled: false
                },
                marker: {
                    enabled: false,
                    allowPointSelect: false,
                    enableMouseTracking: false,
                },
                regression: true,
                regressionSettings: {
                    visible: visible,
                    metaData: {
                        draughtIndex: draughtIndex,
                        isModelSeries: true,
                        shouldShowOnGraph: true,
                    },
                    linkedTo: 'model-draught-abstract',
                    hideInLegend: true,
                    name: draughtSeriesName,
                    type: 'polynomial',
                    color: Color.DATA_4,
                    dashStyle: 'solid',
                    tooltip: {
                        enableMouseTracking: false,
                        enabled: false,
                        formatter: null,
                        pointFormatter: null
                    },
                    hover: {
                        enabled: false,
                        halo: {
                            size: 0
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    dataLabels: { enabled: false },
                    allowPointSelect: false,
                    animation: false,
                    animationLimit: 0,
                    enableMouseTracking: false,
                }
            });
            
        }
        */

        Highcharts.chart('power-graph', {
            chart: {
                zoomType: 'xy',
                type: 'scatter',
                events: {
                    load: function() {
                        var chart = this;
                        $(chart.series).each(function (i, serie) {
                            if (serie.options.metaData.shouldShowOnGraph && serie.options.metaData.draughtIndex != undefined) {
                                var seriesKey = serie.options.metaData.isModelSeries ? 'modelPowerSeries' : 'reportedPowerSeries';
                                if ($scope.draughtOptions.fuelDraughts[serie.options.metaData.draughtIndex][seriesKey] == undefined) {
                                    $scope.draughtOptions.fuelDraughts[serie.options.metaData.draughtIndex][seriesKey] = [];
                                }
                                $scope.draughtOptions.fuelDraughts[serie.options.metaData.draughtIndex][seriesKey].push(serie);
                            }
                        });
                    }
                }
            },
            title: {
                text: null
            },
            subtitle: {
                text: 'Propulsion Power (kW)'
            },
            xAxis: {
                min: $scope.graphs.minChartSpeed,
                max: $scope.graphs.maxChartSpeed,
                tickInterval: 0.5,
                startOnTick: true,
                endOnTick: true,
                title: {
                    enabled: true,
                    text: 'Log Speed (kn)'
                },
                labels: {
                    format: '{value:.1f}'
                },
            },
            yAxis: {
                tickInterval: 2000,
                title: {
                    enabled: true,
                    text: 'Propulsion Power (kW)'
                }
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                },
                scatter: {
                    tooltip: {
                        enabled: true,
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: 'Power: {point.y:.1f} kW<br>Log Speed: {point.x:.1f} kn'
                    },
                    point: {
                        events: {
                            mouseOver: function() {
                                var serie = this.series;
                                highlight(this.series.chart.series, serie.options.metaData.draughtIndex, true);
                            },
                            mouseOut: function() {
                                var serie = this.series;
                                highlight(this.series.chart.series, serie.options.metaData.draughtIndex, false);
                            }
                        }
                    }
                },
                spline: {
                    tooltip: {
                        enableMouseTracking: false,
                        enabled: false,
                        formatter: null,
                        pointFormatter: null
                    },
                    hover: {
                        enabled: false,
                        halo: {
                            size: 0
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    dataLabels: { enabled: false },
                    allowPointSelect: false,
                    animation: false,
                    animationLimit: 0,
                    enableMouseTracking: false,

                }
            },
            legend: {
                enabled: true
            },
            credits: {
              enabled: false
            },
            exporting: {
                enabled: false
            },
            series: powerSeries
        });
    }

    // determines whether we should hide the loading bar to show the page, the
    // sequence is a bit weird since some requests are much faster than others
    // so may need to tweak this depending on production performance
    $scope.shouldShowPage = function() {
        return $scope.fuelTable.draughts || ($scope.graphs && (
            $scope.graphs.fuelGraphReportedData
            || $scope.graphs.powerGraphReportedData
            || $scope.graphs.fuelGraphModelData
            || $scope.graphs.powerGraphModelData
        )) || ($scope.barCharts && $scope.barCharts.consumptionsBarChart);
    }

    function downloadFuelExtract(response) {
        var url = window.URL.createObjectURL(new Blob([response.data], {type:'application/vnd.ms-excel'}));
        var filename = "NavFleet Performance Fuel Table - " + moment().format('YYYY-MM-DD hh_MM_SS') + '.xlsx';
        UtilityService.downloadData(url, filename);
    }

    // used for downloading a fuel table data extract, opens in same page
    $scope.downloadFuelTableExtract = function() {
        var propulsionChange = $scope.propulsionChange;
        var bf = $scope.bf;
        var fromDate = $scope.dates ? $scope.dates.dashboardDateFrom : null;
        var toDate = $scope.dates ? $scope.dates.dashboardDateTo : null;
        var downloadUrl = FuelTablesService.getFuelTableExtractUrl($rootScope.selectedVessel.id, propulsionChange, bf, fromDate, toDate);
        var options = {'responseType': 'blob'};
        $http.get(downloadUrl, options).then(downloadFuelExtract);
    }

    $('#scroll-bar').on('scroll', function () {
        var totalHeight = $('#scroll-bar').prop("scrollHeight");
        var ratio = $(this).scrollTop() / totalHeight;
        var rightTableHeight = $('#scrollable-table').prop("scrollHeight");
        var maxScrollTop = rightTableHeight * ratio;
        $('#scrollable-table').scrollTop(maxScrollTop);
    });
}]);
