import { routerApp, baseUrl, enableOnPremise } from '../app.module';
import angular from 'angular';
import moment from 'moment';
import { httpRequestGenerator } from './utilities';

routerApp.factory('VesselSpecificationService', ['$http', '$log', function($http, $log) {
    var requestFunc = httpRequestGenerator(enableOnPremise, $http);

    var parseFieldDate = function(field) {
        if (field != undefined && field._isAMomentObject) {
            return field;
        }
        if (field != undefined && field["$date"] != undefined) {
            // we must parse this and change it to UTC so that we can extract
            // the hours and date into a string in the right format, anything
            // is fine here as long as it gets displayed the same machine.
            // since we're showing both timezones and time on the page, we would
            // like to see the timezone of the vessel and not our timezone
            // since we don't care about our own timezone in the vessel's reports
            return moment(field["$date"]).utc();
        } else if (field != undefined && typeof field.valueOf() == "string") {
            return field;
        } else {
            return null;
        }
    }

    var _dateFormat = 'DD/MM/YYYY HH:mm';

    var formatMoment = function(date) {
        if (date != undefined && date._isAMomentObject) {
            return date.format(_dateFormat);
        } else {
            return date;
        }
    }

    var reportLogs;
    var specifications;
    return {
        setSpecifications: function(specs) {
            specifications = specs;
            if (specifications.engine == undefined) {
                specifications.engine = {
                    ae_engines: [],
                    me_engines: []
                }
            }

            if (specifications.prime_mover == undefined) {
                specifications.prime_mover = {
                    propulsion_motors: [],
                    diesel_generators: []
                }
            }

            if (specifications != undefined) {
                specifications.dry_docking = {};
            }

            // drydocking dates
            if (specifications != undefined && specifications.dry_dockings != undefined) {
                for (var i = 0; i < specifications.dry_dockings.length; i++) {
                    var dryDocking = specifications.dry_dockings[i];
                    var date = formatMoment(parseFieldDate(dryDocking.date));
                    specifications.dry_dockings[i].date = date;
                }
            }

            // email alerts
            if (specifications && specifications.email_alert && specifications.email_alert.emails) {
                specifications.email_alert.email_addresses = specifications.email_alert.emails.join("; ");
            }

            if (specifications && (!specifications.cp_baseline_details || specifications.cp_baseline_details.length == 0)) {
                specifications.cp_baseline_details = [{}];
            }

            if (specifications && specifications.cp_baseline_details) {
                angular.forEach(specifications.cp_baseline_details, function(detail) {
                    detail.id = detail["_id"] && (detail["_id"]["$oid"] || detail["_id"]);
                    detail.from_date = formatMoment(parseFieldDate(detail.from_date));
                    detail.to_date = formatMoment(parseFieldDate(detail.to_date));
                });
          }
        },
        getSpecifications: function() {
            return specifications;
        },
        setReportLogs: function(logs) {
            reportLogs = logs;

            // report log dates
            if (reportLogs != undefined) {
                for (var i = 0; i < reportLogs.length; i++) {
                    var reports = reportLogs[i];
                    var uploaddate = formatMoment(parseFieldDate(reports.upload_date));
                    var recalculateDate = formatMoment(parseFieldDate(reports.recalculated_since));
                    reportLogs[i].upload_date = uploaddate;
                    reportLogs[i].recalculated_since = recalculateDate;
                }
            }
        },
        getReportLogs: function() {
            return reportLogs;
        },
        buildFormConfigurationObject: function(formConfiguration) {
            var visibleFields = formConfiguration.visibleFields;
            var formConfigurationObject = {};
            for (var i = 0; i < visibleFields.length; i++) {
                formConfigurationObject[visibleFields[i]] = true;
            }
            var hiddenFields = formConfiguration.hiddenFields;
            for (var i = 0; i < hiddenFields.length; i++) {
                formConfigurationObject[hiddenFields[i]] = false;
            }
            return formConfigurationObject;
        },
        getVesselSpecifications: function(vesselId) {
            $log.debug("Loading Vessel Specifications");
            var url = baseUrl + "vessels/" + vesselId + "/vesselInformation";
            return requestFunc({
                url: url,
                method: "GET"
            });
        },
        getClassModelReports: function() {
            var url = baseUrl + "vessels/recalculationLogs";
            return requestFunc({
                    url: url,
                    method: "GET"
            });
        },
        getFormConfiguration: function(vesselId) {
            $log.debug("Loading Vessel Form Configuration");
            var url = baseUrl + "vessels/" + vesselId + "/formConfiguration";
            return requestFunc({
                    url: url,
                    method: "GET"
            });
        },
        getFlowMeterTemplates: function() {
            $log.debug("Loading flow meter templates");
            var url = baseUrl + "flow_meters";
            return $http({
                url: url,
                method: "GET",
            })
        },
        getTagListConfiguration: function(vesselId) {
            const url = `${baseUrl}vessels/${vesselId}/tag_configuration`;
            return requestFunc({
                url: url,
                method: "GET"
            });
        },
        updateTagListConfiguration: function(vesselId, data) {
            const url = `${baseUrl}vessels/${vesselId}/tag_configuration`;
            return $http({
                url: url,
                method: "POST",
                data: data
            });
        },
        updateVesselInformation: function(vesselId, data) {
            $log.debug("Updating vessel information, making POST call");
            var url = baseUrl + "vessels/" + vesselId + "/vesselInformation";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        updateEngineConfiguration: function(vesselId, engineData, primeMoverData) {
            $log.debug("Updating engine configuration, making POST request");
            var url = baseUrl + "vessels/" + vesselId + "/engineConfiguration";
            return $http({
                    url: url,
                    method: "POST",
                    data: {
                        engineData: engineData,
                        primeMoverData: primeMoverData
                    }
            });
        },
        updateFMConfiguration: function(vesselId, data) {
            $log.debug("Updating flow meter configuration, making POST request");
            var url = baseUrl + "vessels/" + vesselId + "/flowMeterConfiguration";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        updateFlowMeterInstallation: function(vesselId, mainFlowMeterInstallation, boilerFlowMeterInstallation) {
            $log.debug('Updating vessel flow meter installation');
            var url = baseUrl + "vessels/" + vesselId + "/flow_meter_installation";
            return $http({
                    url: url,
                    method: "POST",
                    data: {
                        mainFlowMeterInstallation: mainFlowMeterInstallation,
                        boilerFlowMeterInstallation: boilerFlowMeterInstallation,
                    }
            });
        },
        addClassModel: function(reportCheckBox, gasModel, file) {
            var form = new FormData();
            var currentdate = new Date();
            var datetime =  currentdate.getDate() + "/"
                            + (currentdate.getMonth()+1)  + "/"
                            + currentdate.getFullYear() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes();
            form.append('upload_date', datetime);
            form.append('recalculateReports', reportCheckBox);
            form.append('gasModel', gasModel)
            form.append('filedata', file, file.name)
            var url = baseUrl + "vessels/addClassModelReport";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        addClassModelReport: function(reportCheckBox, gasModel, recalculateDate, file) {
            var form = new FormData();
            var currentdate = new Date();
            var datetime =  currentdate.getDate() + "/"
                            + (currentdate.getMonth()+1)  + "/"
                            + currentdate.getFullYear() + " "
                            + currentdate.getHours() + ":"
                            + currentdate.getMinutes();
            if (reportCheckBox == true) {
                form.append('date', recalculateDate);
                form.append('upload_date', datetime);
                form.append('recalculateReports', reportCheckBox);
            }
            form.append('gasModel', gasModel)
            form.append('filedata', file, file.name)
            var url = baseUrl + "vessels/addClassModelReport";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },

        // makes api calls to backend
        addReportRecalculations: function(recalculateReportsFromDate, recalculateReportsToDate, recalculateReportCheckBox) {
            var dict = {
                'from_date': recalculateReportsFromDate,
                'to_date': recalculateReportsToDate,
                'recalculate_report': recalculateReportCheckBox
            };

            var url = baseUrl + "vessels/addReportRecalculations";
            console.log(dict)
            return $http({
                url: url,
                method: "POST",
                data: dict
        })
        
        },

        updateVesselModels: function(vesselId, data) {
            $log.debug("Updating vessel models, making POST request");
            var url = baseUrl + "vessels/" + vesselId + "/vesselModels";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        updateTankCapacity: function(vesselId, data) {
            $log.debug("Updating tank capacity, making POST request");
            var url = baseUrl + "vessels/" + vesselId + "/tankCapacity";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        addDryDocking: function(vesselId, data, files) {
            $log.debug("Updating dry docking, making POST request");

            var form = new FormData();
            Object.keys(data).forEach(function(key) {
                form.append(key, data[key]);
            });
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                form.append('file' + i, file, file.name);
            }

            var url = baseUrl + "vessels/" + vesselId + "/dryDocking";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        deleteDryDocking: function(vesselId, dryDockingId) {
            $log.debug("Deleting dry docking, making DELETE request");
            return $http.delete(baseUrl + "vessels/" + vesselId + "/dryDocking/" + dryDockingId);
        },
        updateDryDockings: function(vesselId, dryDockings) {
            return $http.post(baseUrl + "vessels/" + vesselId + "/dryDockings", {
                dryDockings: dryDockings
            });
        },
        uploadNewDryDockingReport: function(vesselId, dryDockingId, file) {
            var form = new FormData();
            form.append('filedata', file, file.name)
            var url = baseUrl + "vessels/" + vesselId + "/dryDockings/" + dryDockingId + "/reports";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        updateEmissionRates: function(vesselId, data) {
            var url = baseUrl + "vessels/" + vesselId + "/emissionRates";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        updateNoxEmissionRates: function(vesselId, data) {
            $log.debug("Updating NOx Emission rates, making POST request");
            var url = baseUrl + "vessels/" + vesselId + "/noxEmissionRates";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        updatePropellerConfiguration: function(vesselId, data) {
            $log.debug("Updating Propeller Configuration, making POST request");
            var url = baseUrl + "vessels/" + vesselId + "/propellerConfiguration";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        updateEmailAlert: function(vesselId, data) {
            $log.debug("Updating Email Alert Configuration, making POST request");
            var url = baseUrl + "vessels/" + vesselId + "/emailAlert";
            return $http({
                    url: url,
                    method: "POST",
                    data: data
            });
        },
        updateCpBaselineDetails: function(vesselId, cpBaselineDetails, files) {
            var form = new FormData();
            form.append('cpBaselineDetails', JSON.stringify(cpBaselineDetails));
            angular.forEach(files, function(file, i) {
                form.append('filedata' + i, file, file.name);
            });
            var url = baseUrl + "vessels/" + vesselId + "/cpBaselineDetails";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined },
            });
        },
        extractCPBaselines: function(vesselId, cpBaselineDetailId) {
            var url = baseUrl + "vessels/" + vesselId + "/cpBaselinesExtract/" + cpBaselineDetailId;
            return $http({
                url: url,
                method: "GET",
            });
        },
        downloadSampleCPBaselinesExtract: function(vesselId) {
            var url = baseUrl + "vessels/" + vesselId + "/sampleCpBaselinesExtract"
            return $http({
                url: url,
                method: "GET",
            });
        },
        updateVessels: function(file, client, generate_users) {
            var form = new FormData();
            form.append('filedata', file, file.name);
            form.append('generateUsers', generate_users);
            form.append('client', client);
            var url = baseUrl + "vessels/bulkUpload";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },

        uploadSpeedPowerModels: function(file) {
            var form = new FormData();
            form.append('filedata', file, file.name);
            var url = baseUrl + "vessels/speedPowerModels";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        uploadEngineModels: function(file) {
            var form = new FormData();
            form.append('filedata', file, file.name);
            var url = baseUrl + "vessels/engineModels";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        uploadDisplacementModels: function(file) {
            var form = new FormData();
            form.append('filedata', file, file.name);
            var url = baseUrl + "vessels/displacementModels";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        uploadTankCapacityPlans: function(file) {
            var form = new FormData();
            form.append('filedata', file, file.name);
            var url = baseUrl + "vessels/tankCapacityPlans";
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
		getVeslinkSuppliers: function(selectedVessel) {
			return $http.get(`${baseUrl}vessels/${selectedVessel.id}/veslinkSuppliers`);
		},
		reorderReports: function(selectedVessel, reportType) {
			return $http({
                url: `${baseUrl}vessels/${selectedVessel.id}/reorderReports`,
                method: 'POST',
                data: { 'type': reportType }
            })
		},
        uploadHistoricReportData: (file, email, datetime_format) => {
            var form = new FormData();
            form.append('filedata', file, file.name);
            form.append('email',email);
            form.append('datetime_format',datetime_format);
            return $http.post(`${baseUrl}vessels/uploadHistoricData`, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })

        },
    }

}]);
