import { routerApp, Color } from '../app.module';
import { getCacheBustedTemplate } from '../app.route';

routerApp.directive('loadingBar', function() {

    return {
        templateUrl: getCacheBustedTemplate('pages/directives/loading.html'),
        scope: {
            loadingText: '@',
        },
        link: function(scope, element, attributes, control) {

            // @ts-ignore
            var bar = new ProgressBar.Line('#loading-bar', {
              strokeWidth: 4,
              easing: 'easeInOut',
              duration: 10000,
              color: '#FFEA82',
              trailColor: '#eee',
              trailWidth: 1,
              svgStyle: {width: '100%', height: '100%'},
              from: {color: Color.SELECTION_GREEN },
              to: {color: Color.GREEN },
              step: function(state, bar) {
                bar.path.setAttribute('stroke', state.color);
              }
            });

            bar.animate(1.0);  // Number from 0.0 to 1.0
        }
    }
});

