import { routerApp } from '../app.module';
import { getCacheBustedTemplate } from '../app.route';

routerApp.directive('noData', function() {

    return {
        templateUrl: getCacheBustedTemplate('pages/directives/no-data.html'),
        scope: {},
        link: function(scope, element, attributes, control) {
            // animate or something
        }
    }
});
