import { routerApp, baseUrl, enableOnPremise } from '../app.module';
import { httpRequestGenerator } from './utilities';

routerApp.factory('OffhireReportService', ['$rootScope', '$http', function($rootScope, $http) {
    const requestFunc = httpRequestGenerator(enableOnPremise, $http);
    return {
        getReports: function() {
            var url = baseUrl + $rootScope.selectedVessel.id + '/offhireReports';
            return requestFunc({url: url});
        },
        getOffhireReport: function(reportId) {
            var url = baseUrl + $rootScope.selectedVessel.id + '/offhireReports/' + reportId;
            return requestFunc({url: url});
        },
        submitOffhireReport: function(report, closeReport) {
            var url = baseUrl + $rootScope.selectedVessel.id + '/offhireReports';
            return requestFunc({url: url, method: 'POST',data: report,
                params: {
                    closeReport: closeReport
                }
            });
        },
        getLastIncompleteOffhireReport: function() {
            var url = baseUrl + $rootScope.selectedVessel.id + '/lastIncompleteOffhireReport';
            return requestFunc({url: url});
        },
        deleteReport: function(reportId) {
            var url = baseUrl + $rootScope.selectedVessel.id + '/offhireReports/' + reportId;
            return requestFunc({url: url, method: 'DELETE'});
        },
        getIncidentTableData: function(client, reportFrom, reportTo) {
            var url = baseUrl + $rootScope.user.id + '/incidentTableData';
            return $http.get(url, {
                params: {
                    client: client,
                    reportFrom: reportFrom,
                    reportTo: reportTo
                }
            });
        }
    };
}]);
