export interface Option {
    name: string;
    value: string;
}

export const SensorOptions: Option[] = [
    {
        name: 'AIS',
        value: 'ais'
    },
    {
        name: 'Sensors',
        value: 'sensor'
    }
]

export const SupplyOptions: Option[] = [
    {
        name: 'Rate [kg/s]',
        value: 'rate'
    },
    {
        name: 'Counter [kg]',
        value: 'counter'
    }
] 

export class TagListConfiguration {
    draught_forward_enabled: boolean = false;
    draught_aft_enabled: boolean = false;
    cog_ais_enabled: boolean = false;
    cog_sensors_enabled: boolean = false;
    speed_ais_enabled: boolean = false;
    speed_sensors_enabled: boolean = false;
    position_ais_enabled: boolean = false;
    position_sensors_enabled: boolean = false;
    observed_distance_enabled: boolean = false;
    apparent_wind_speed_enabled: boolean = false;
    apparent_wind_direction_enabled: boolean = false;
    log_speed_enabled: boolean = false;
    sea_temp_enabled: boolean = false;
    me1_power_enabled: boolean = false;
    me2_power_enabled: boolean = false;
    me1_me2_power_enabled: boolean = false;
    me1_rpm_enabled: boolean = false;
    me1_prop_shaft_power_counter_enabled: boolean = false;
    ae1_power_enabled: boolean = false;
    ae2_power_enabled: boolean = false;
    ae3_power_enabled: boolean = false;
    ae4_power_enabled: boolean = false;
    ae5_power_enabled: boolean = false;
    ae6_power_enabled: boolean = false;
    shaft_pto_power_enabled: boolean = false;
    me1_rhs_enabled: boolean = false;
    me2_rhs_enabled: boolean = false;
    ae1_rhs_enabled: boolean = false;
    ae2_rhs_enabled: boolean = false;
    ae3_rhs_enabled: boolean = false;
    ae4_rhs_enabled: boolean = false;
    ae5_rhs_enabled: boolean = false;
    ae6_rhs_enabled: boolean = false;
    supply_me_diff_rate_enabled: boolean = false;
    supply_me_diff_counter_enabled: boolean = false;
    supply_ae_diff_rate_enabled: boolean = false;
    supply_ae_diff_counter_enabled: boolean = false;
    ab1_inlet_diff_enabled: boolean = false;
    ab2_inlet_diff_enabled: boolean = false;
    comp_boiler_inlet_diff_enabled: boolean = false;

    setNpeDefault(isDualEngine:boolean = false): void {
        this.draught_forward_enabled = true;
        this.draught_aft_enabled = true;
        this.cog_ais_enabled = true;
        this.cog_sensors_enabled = false;
        this.speed_ais_enabled = true;
        this.speed_sensors_enabled = false;
        this.position_ais_enabled = true;
        this.position_sensors_enabled = false;
        this.observed_distance_enabled = true;
        this.apparent_wind_speed_enabled = true;
        this.apparent_wind_direction_enabled = true;
        this.log_speed_enabled = true;
        this.sea_temp_enabled = true;
        this.me1_power_enabled = true;
        this.me2_power_enabled = isDualEngine ?? false;
        this.me1_me2_power_enabled = isDualEngine ?? false;;
        this.me1_rpm_enabled = true;
        this.me1_prop_shaft_power_counter_enabled = true;
        this.ae1_power_enabled = true;
        this.ae2_power_enabled = true;
        this.ae3_power_enabled = true;
        this.ae4_power_enabled = true;
        this.ae5_power_enabled = true;
        this.ae6_power_enabled = true;
        this.shaft_pto_power_enabled = true;
        this.me1_rhs_enabled = true;
        this.me2_rhs_enabled = true;
        this.ae1_rhs_enabled = true;
        this.ae2_rhs_enabled = true;
        this.ae3_rhs_enabled = true;
        this.ae4_rhs_enabled = true;
        this.ae5_rhs_enabled = true;
        this.ae6_rhs_enabled = true;
        this.supply_me_diff_rate_enabled = true;
        this.supply_me_diff_counter_enabled = false;
        this.supply_ae_diff_rate_enabled = true;
        this.supply_ae_diff_counter_enabled = false;
        this.ab1_inlet_diff_enabled = true;
        this.ab2_inlet_diff_enabled = true;
        this.comp_boiler_inlet_diff_enabled = true;
    }
}