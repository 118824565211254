import moment from 'moment';
import angular from 'angular';
import { routerApp, roundToPlaces, Color, themePalette } from '../../app.module';
import { BallastCondition, FPVessel, FPScope, FPAPIReport, FPAPIVessel, IFleetPerformanceGraphService, Point, DateRange, CIIRatingClass } from '../services/fleetPerformanceGraph.service';
import { calculateEffiencyRatio } from '../../services/utilities';

routerApp.controller('fleetPerformanceController', ['$rootScope', '$http', '$scope', '$timeout', 'UserService', 'FleetPerformanceGraphService', 'UtilityService', 'FleetPerformanceService', function($rootScope, $http, $scope: FPScope,  $timeout, UserService, FleetPerformanceGraphService: IFleetPerformanceGraphService, UtilityService, FleetPerformanceService) {

    $scope.fleetPerformanceDataCache = {};

    $scope.updateVesselClasses = function() {
        for (var vesselIndex = 0; vesselIndex < $scope.allData.length; vesselIndex ++) {
            var vessel = $scope.allData[vesselIndex];
            if ($scope.options.vesselClasses[vessel.vessel_class] === undefined) {
                $scope.options.vesselClassesList.push(vessel.vessel_class);
                $scope.options.vesselClasses[vessel.vessel_class] = true;
            }

            if ($scope.options.vesselsByClass[vessel.vessel_class] === undefined) {
                $scope.options.vesselsByClass[vessel.vessel_class] = [vessel];
            } else {
                $scope.options.vesselsByClass[vessel.vessel_class].push(vessel);
            }

            if (settings.selected_classes[vessel.vessel_class] === undefined || settings.selected_vessels[vessel.vessel_id] === undefined) {
                settings.selected_classes[vessel.vessel_class] = true;
                $scope.options.vessels[vessel.vessel_id] = true;
            } else {
                $scope.options.vesselClasses[vessel.vessel_class] = !!settings.selected_classes[vessel.vessel_class];
                $scope.options.vessels[vessel.vessel_id] = !!settings.selected_vessels[vessel.vessel_id];
            }
        }

        $scope.options.vesselClassesList.sort();
    };

    $scope.BallastCondition = BallastCondition;
    // date range enums used to display the text for the date range dropdown
    var dateRangeToLabel: Record<DateRange, string> = {
        '10_days': '10 Days',
        'mtd': 'Month to Date',
        '1_month': '1 Month',
        '2_month': '2 Months',
        '3_month': '3 Months',
        'year': 'Past Year',
        'ytd': 'Year to Date',
        'since_last_dry_docking': 'Since Last Dry Docking',
        'since_last_hull_cleaning': 'Since Last Hull Cleaning',
        'since_last_propeller_polishing': 'Since Last Propeller Polishing',
        'custom': 'Custom'
    };

    $('.fp-dropdown.dropdown a').on('click', function () {
        var div = $(this).parent();
        if (!div.hasClass('disabled')) {
            div.toggleClass('open');
        }
    });

    $(document).mouseup(function(e) {
        var container = $(".dropdown-menu");

        // if the target of the click isn't the container nor a descendant of the container
        // @ts-ignore
        if (!container.is(e.target) && container.has(e.target).length === 0)
        {
            closeDropdowns();
        }
    });

    $scope.$on('$stateChangeStart', function(e) {
        $(document).unbind('mouseup');
    });

    var closeDropdowns = function() {
        var closing = $('.dropdown-menu').parent().hasClass('open');
        $('.dropdown-menu').parent().removeClass('open');
        if (closing && Object.keys($scope.options.vesselClasses).length > 0) {
            updateUserSettings();
        }
    };

    // progress bars are annotated with min, max and current values. running
    // this function would normalize the progress bars so that they start at
    // min, end at max, and pushes the progress to the current value.
    $scope.normaliseProgressBars = function() {
        $('.progress-bar').each(function() {
            var min = parseFloat($(this).attr('aria-valuemin'));
            var max = parseFloat($(this).attr('aria-valuemax'));
            var now = parseFloat($(this).attr('aria-valuenow'));
            var size = (now - min) * 100 / (max - min);
            $(this).css('width', size +'%');
        });
    };

    $scope.areAllVesselsSelected = function() {
        return Object.values($scope.options.vessels).every(function(bool) { return !!bool; });
    };

    var settings = $scope.user.features.fleet_performance;

    $scope.options = {
        dateRange: settings.date_range,
        dateRangeText: undefined,
        reportFrom: undefined,
        reportTo: undefined,
        voyageLeg: settings.voyage_leg,
        vesselClasses: {},
        vesselClassesList: [],
        vesselsByClass: {},
        vessels: settings.selected_vessels || {},
        vesselClassLabel: 'All Classes',
        selectAll: false,
        bf: settings.bf,
        bfValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function(n) {
            return { value: n, selected: n === settings.bf };
        })
    };

    $scope.options.selectAll = $scope.areAllVesselsSelected();

    $scope.toggleBFMax = function(bf) {
        $scope.options.bfValues[$scope.options.bf - 1].selected = false;
        $scope.options.bfValues[bf - 1].selected = true;
        $scope.options.bf = bf;
        closeDropdowns();
    };

    // actions on voyage leg
    $scope.setVoyageLeg = function(voyageLeg) {
        $scope.options.voyageLeg = voyageLeg;
    };
    $scope.$watch('options.voyageLeg', function() {
        if ($scope.pageMetric) {
            $scope.recalculateData();
        }
        updateUserSettings();
    });

    $scope.chartsList = [];
    $scope.minDate = moment().add(-1, 'years').toDate();

    // actions on classes
    $scope.selectAll = function() {
        $scope.options.selectAll = true;
        $scope.setAllClasses(true);
        $scope.setAllVessels(true);
    };
    $scope.deselectAll = function() {
        $scope.options.selectAll = false;
        $scope.setAllClasses(false);
        $scope.setAllVessels(false);
    };
    $scope.setAllClasses = function(boolean) {
        for (var key in $scope.options.vesselClasses) {
            $scope.options.vesselClasses[key] = boolean;
        }
    };
    $scope.setAllVessels = function(boolean) {
        Object.keys($scope.options.vessels).forEach(function(id) {
            $scope.options.vessels[id] = boolean;
        });
    };
    $scope.toggleClass = function(key) {
        $scope.options.vesselClasses[key] = !$scope.options.vesselClasses[key];

        for (var i = 0; i < $scope.options.vesselsByClass[key].length; i++) {
            var v = $scope.options.vesselsByClass[key][i];
            $scope.options.vessels[v.vessel_id] = $scope.options.vesselClasses[key];
        }

        $scope.options.selectAll = $scope.areAllVesselsSelected();
    };
    $scope.$watch('options.vesselClasses', function() {
        var selectedClasses = [];
        for (var key in $scope.options.vesselClasses) {
            var set = $scope.options.vesselClasses[key];
            if (set) {
                selectedClasses.push(key);
            }
        }
        var nToggledClasses = selectedClasses.length;
        if (nToggledClasses == 0) {
            $scope.options.vesselClassLabel = 'No Classes Selected';
        } else if (nToggledClasses >= 1 && nToggledClasses <= 3) {
            $scope.options.vesselClassLabel = selectedClasses.join(", ");
        } else if (nToggledClasses > 3) {
            $scope.options.vesselClassLabel = nToggledClasses + ' Classes';
        }
        if (nToggledClasses == $scope.options.vesselClassesList.length) {
            $scope.options.vesselClassLabel = 'All Classes';
        }

        if ($scope.pageMetric) {
            $scope.recalculateData();
        }
    }, true);

    // actions on date range
    $scope.changeDateRange = function(dateRange) {
        $scope.options.dateRange = dateRange;
        var now = new Date();
        var reportFrom = new Date();

        switch (dateRange) {
            case '10_days':
                $scope.options.reportTo = now;
                reportFrom.setDate(reportFrom.getDate() - 10);
                $scope.options.reportFrom = reportFrom;
                closeDropdowns();
                break;
            case '1_month':
                $scope.options.reportTo = now;
                reportFrom.setDate(reportFrom.getDate() - 30);
                $scope.options.reportFrom = reportFrom;
                closeDropdowns();
                break;
            case '2_month':
                $scope.options.reportTo = now;
                reportFrom.setDate(reportFrom.getDate() - 61);
                $scope.options.reportFrom = reportFrom;
                closeDropdowns();
                break;
            case '3_month':
                $scope.options.reportTo = now;
                reportFrom.setDate(reportFrom.getDate() - 90);
                $scope.options.reportFrom = reportFrom;
                closeDropdowns();
                break;
            case 'mtd':
                $scope.options.reportTo = now;
                $scope.options.reportFrom = new Date(now.getFullYear(), now.getMonth(), 1);
                closeDropdowns();
                break;
            case 'year':
                $scope.options.reportTo = now;
                reportFrom.setDate(reportFrom.getDate() - 365);
                $scope.options.reportFrom = reportFrom;
                closeDropdowns();
                break;
            case 'ytd':
                $scope.options.reportTo = now;
                $scope.options.reportFrom = new Date(now.getFullYear(), 0, 1);
                closeDropdowns();
                break;
            case 'since_last_dry_docking':
            case 'since_last_hull_cleaning':
            case 'since_last_propeller_polishing':
                $scope.options.reportTo = now;
                $scope.options.reportFrom = null;
                closeDropdowns();
                break;
            case 'custom':
                if ($scope.customReportFrom) {
                    $scope.options.reportFrom = moment($scope.customReportFrom, 'DD/MM/YYYY HH:mm').toDate();
                }
                if ($scope.customReportTo) {
                    $scope.options.reportTo = moment($scope.customReportTo, 'DD/MM/YYYY HH:mm').toDate();
                }
                break;
            default:
                let val: never = dateRange;
                throw new Error(`Missing case statement in changeDateRange() for value: ${val}`);
        }
    }

    type EventType = 'dry_docking' | 'hull_cleaning' | 'propeller_polishing';

    let getMostRecentEventDate = (vessel: FPVessel, eventType: EventType): Date => {
        let ts = vessel.dry_dockings.filter(dd => !!dd[eventType]).map(dd => new Date(dd.date + 'Z')).max();
        let date = ts ? new Date(ts) : new Date().addDays(-365);
        return date;
    };

    let getFromDate = (vessel: FPVessel): Date => {
        switch ($scope.options.dateRange) {
            case 'since_last_dry_docking':
                return getMostRecentEventDate(vessel, 'dry_docking');
            case 'since_last_hull_cleaning':
                return getMostRecentEventDate(vessel, 'hull_cleaning');
            case 'since_last_propeller_polishing':
                return getMostRecentEventDate(vessel, 'propeller_polishing');
            case '10_days':
            case '1_month':
            case '2_month':
            case '3_month':
            case 'mtd':
            case 'year':
            case 'ytd':
            case 'custom':
                return $scope.options.reportFrom;
            default:
                let val: never = $scope.options.dateRange;
                throw new Error(`Missing case statement in getFromDate() for value: ${val}`);
        }
    };
    
    // set custom date period limit to 1 year by default
    let settingsReportFrom = settings.report_from && moment(settings.report_from);
    let settingsReportTo = settings.report_to && moment(settings.report_to);
    if (settingsReportFrom?.toDate() < $scope.minDate) settingsReportFrom = moment($scope.minDate);
    $scope.customReportFrom = settingsReportFrom && settingsReportFrom.format('DD/MM/YYYY HH:mm');
    $scope.customReportTo =  settingsReportTo && settingsReportTo.format('DD/MM/YYYY HH:mm');
    $scope.changeDateRange($scope.options.dateRange);

    var getDateRangeText = function(label: string, fromDate: Date, toDate: Date | null) {
        switch ($scope.options.dateRange) {
            case '10_days':
            case '1_month':
            case '2_month':
            case '3_month':
            case 'mtd':
            case 'year':
            case 'ytd':
            case 'custom':
                var fromDateString = moment(fromDate).format('YYYY-MM-DD');
                var toDateString = moment(toDate).format('YYYY-MM-DD');
                return label + ' (' + fromDateString + ' - ' + toDateString + ')';
            case 'since_last_dry_docking':
            case 'since_last_hull_cleaning':
            case 'since_last_propeller_polishing':
                return `${label} (variable dates)`;
            default:
                let val: never = $scope.options.dateRange;
                throw new Error(`Missing case statement in getFromDate() for value: ${val}`);
        }
    };

    $scope.$watchGroup(['options.reportTo', 'options.reportFrom'], function() {
        if ($scope.options.reportTo) {
            if ($scope.pageMetric) {
                $scope.recalculateData();
            }

            $scope.options.dateRangeText = getDateRangeText(
                dateRangeToLabel[$scope.options.dateRange],
                $scope.options.reportFrom,
                $scope.options.reportTo
            );
        }
    });

    $scope.$watch('customReportTo', function(value) {
        if (value && $scope.options.dateRange === 'custom') {
            $scope.options.reportTo = moment(value, 'DD/MM/YYYY HH:mm').toDate();
        }
    });
    $scope.$watch('customReportFrom', function(value, oldValue) {
        // let prevCustomReportFrom = oldValue && moment(oldValue, 'DD/MM/YYYY HH:mm').toDate();
        const minDate = $scope.minDate;
        // let prevReportFromValid = prevCustomReportFrom instanceof Date && !isNaN(prevCustomReportFrom.valueOf());
        if (value && value !== oldValue && $scope.options.dateRange === 'custom' ) {
            let reportFrom = moment(value, 'DD/MM/YYYY HH:mm').toDate();
            // Only fetch data when new data range goes back farther than current cache data
            const dateRangeChange = reportFrom < minDate;
            const numberOfVessels = $scope.fleetPerformance?.vessels?.length || 0;
            const currentDate = new Date();
            const duration = moment.duration(currentDate.getTime() - reportFrom.getTime()).asDays();
            if (dateRangeChange && duration > 366 && numberOfVessels > 100) {
                $scope.changeDateRange('year');
                $('#fleetPerformanceWarning').modal('show');
            } else if (dateRangeChange) {
                $scope.fleetPerformanceDataCache.gettingData = true;
                $scope.EEOIQuartilesSet = false;
                $scope.minDate = reportFrom;
               let vesselIds = [];
               let vessels = $scope.fleetPerformance && $scope.fleetPerformance.vessels || [];
               for (let i = 0; i < vessels.length; i++) {
                   vesselIds.push(vessels[i].vessel_id);
               }
               if (vesselIds.length == 0) vesselIds = null;
               $scope.fleetPerformance = null;
                FleetPerformanceService.getAggregateMetrics($rootScope.username, reportFrom, vesselIds).then(function(response) {
                    let res = response.data;
                    $scope.allData = res.data;
                    $scope.fleetPerformanceDataCache.gettingData = false;
                    $scope.updateVesselClasses();
                    $scope.updateMetricQuartiles();
                    $scope.options.reportFrom = reportFrom;
                })
            } else {
                $scope.options.reportFrom = reportFrom;
            }
        }
    });

    $scope.$watch('options.bf', function(newBF) {
        if (newBF) {
            $scope.recalculateData();
        }
    });

    $rootScope.selectedMenu = 'fleet-performance';

    $scope.fleetPerformanceMetricLabel = {
        c : 'ME Consumption / 24 Hours (MT)',
        s : 'Average Speed (Knots)',
        e : 'EEOI (g/MT.NM)',
        e_passenger : 'EEOI (g/passenger.NM)',
        p : 'Propulsion Efficiency (%)',
        aer : 'AER (g/DWT-NM)',
        cii: 'CII Difference (%)'
    }

    $scope.displayFleetPerformanceLabel = ( metric: string, vessels: FPVessel[] = []) => {
        if (metric !== 'e') {
            return $scope.fleetPerformanceMetricLabel[metric];
        }
      
        let vesselTypeSet = new Set(vessels.map(v => v.vessel_type));
        if (metric === 'e' && vesselTypeSet.has('cruise_passenger') && vesselTypeSet.size > 1) {
            return 'EEOI (g/MT.NM or g/passenger.NM)';
        } else if (metric === 'e' && vesselTypeSet.has('cruise_passenger')) {
            return $scope.fleetPerformanceMetricLabel['e_passenger'];
        } else {
            return $scope.fleetPerformanceMetricLabel[metric];
        }
    }


    let fleetPerformanceDashboardMetrics = [ 'c', 's', 'e', 'p', 'aer'];
    let fleetPerformanceDropdown = [
        'c',
        'me_excess_consumption',
        'tc',
        's',
        'e',
        'aer',
        'p',
        'sp',
        'nc',
        'sf_c',
        'sf_d',
        'tcrpm_deviation',
        'cp_consumption_deviation',
        'cp_speed_difference',
        'cii',
    ];

    const fleetMetricOperator = {
        'c': (valueChange) => valueChange > 0 ? 'red' : 'green' , 
        's': (valueChange) => valueChange < 0 ? 'red' : 'green' , 
        'e': (valueChange) => valueChange > 0 ? 'red' : 'green' , 
        'p': (valueChange) => valueChange < 0 ? 'red' : 'green' , 
        'aer':  (valueChange) => valueChange > 0 ? 'red' : 'green' , 
        'cii':  (valueChange) => valueChange < 0 ? 'red' : 'green' 
    };

    const showFpMetrics = (metrics) => {
        const fleetMetrics = $rootScope.features && $rootScope.features.fleet_performance && $rootScope.features.fleet_performance.fleet_metrics;
        let visibleMetrics = [];
        if (fleetMetrics && Object.keys(fleetMetrics).length > 0) {
            visibleMetrics = metrics.filter(metric => {
                let m = fleetMetrics[metric];
                if (!m || m && m.enabled) {
                    return metric;
                }
            });
            return visibleMetrics;
        }
        return metrics;
    };

    const setMetricSpacing = (metrics) => {
        let numberOfMetrics = metrics.length;
        return 'col-md-' + Math.round(12 / numberOfMetrics)
    };

    $scope.fleetPerformanceMetrics = showFpMetrics(fleetPerformanceDashboardMetrics);
    $scope.fleetPerformanceDropdownOptions = showFpMetrics(fleetPerformanceDropdown);
    $scope.fleetPerformanceMetricsClass = setMetricSpacing($scope.fleetPerformanceMetrics);
    $scope.fpMetricChangeClass = {
        c: 'green',
        s: 'green',
        e: 'green',
        p: 'green',
        aer: 'green',
        cii: 'green'
    };

    angular.forEach($scope.fleetPerformanceMetrics, function(m) {
        $scope.$watch('fleetPerformance.fleet.' + m + '_change', function(newValue) {
            if (newValue) {
                $scope.fpMetricChangeClass[m] = fleetMetricOperator[m](newValue);
            }
        })
    });
    var getMetricData = function(metricKey) {
        let reportFrom = $scope.options.reportFrom;
        const currentMinDate = moment($scope.minDate);
        if ($scope.fleetPerformanceDataCache[metricKey] && reportFrom >= currentMinDate.toDate()|| !$scope.allData) return;
        $scope.fleetPerformanceDataCache.gettingData = true;
        if (!reportFrom || currentMinDate.toDate() < reportFrom) {
            reportFrom = currentMinDate.toDate();
        }
        FleetPerformanceService.getMetricData($rootScope.username, metricKey, reportFrom).then(function(response) {
            var res = response.data;
            $scope.fleetPerformanceDataCache.gettingData = false;
            $scope.fleetPerformanceDataCache[metricKey] = res.data;

            // Merge it into allData using vessel_ids and timestamps (t)
            angular.forEach(res.data, function(vesselMetrics) {
                var vesselId = vesselMetrics.vessel_id;
                var metricSets = vesselMetrics.data;

                var vessel = $scope.allData.find(function(vessel) {
                    return vessel.vessel_id == vesselId;
                });

                if (vessel == undefined) { return; }

                angular.forEach(metricSets, function(metricSet) {
                    var t = metricSet.t;
                    var vesselMetricSet = vessel.data.find(function(metricSet) {
                        return metricSet.t == t;
                    });
                    if (vesselMetricSet) {
                        angular.forEach(Object.keys(metricSet), function(metric) {
                            if (metric != 't') {
                                vesselMetricSet[metric] = metricSet[metric];
                            }
                        });
                    } else {
                        vessel.data.push(metricSet);
                    }
                });
            });

            $scope.recalculateData();
            $scope.normaliseProgressBars();
        });
    };

    FleetPerformanceService.getAggregateMetrics($rootScope.username, moment($scope.minDate).toDate())
    .then(function(response) {
        var res = response.data;
        $scope.allData = res.data;
        $scope.updateVesselClasses();
        $scope.updateMetricQuartiles();
        $scope.$watch('pageMetric', function(metricKey) {
            if (!metricKey) return;

            $scope.fleetPerformance = null;
            if ($scope.fleetPerformanceDataCache.gettingData) return;

            // Disable/enable voyage leg buttons appropriately.
            if (['nc'].indexOf(metricKey) != -1) {
                $scope.setVoyageLeg(BallastCondition.Laden);
                $scope.disableLegButtonAll = true;
            } else {
                $scope.disableLegButtonAll = false;
            }
            if (['e'].indexOf(metricKey) != -1) {
                $scope.setVoyageLeg(BallastCondition.All);
                $scope.disableLegButtonLaden = true;
                $scope.disableLegButtonBallast = true;
                $scope.disableBfDropdown = true;
                $scope.changeDateRange('year');
            } else {
                $scope.disableLegButtonBallast = false;
                $scope.disableLegButtonLaden = false;
                $scope.disableBfDropdown = false;
            }
            if (['nc', 'e'].indexOf(metricKey) != -1) {
                $scope.disableLegButtonPartiallyLoaded = true;
            } else {
                $scope.disableLegButtonPartiallyLoaded = false;
            }

            if ($scope.fleetPerformanceDataCache[metricKey]) {
                // Cache already has data for this key, so just recalculateData()
                $scope.recalculateData();
                $scope.normaliseProgressBars();
            } else {
                // Cache didn't have the data, so get it
                getMetricData(metricKey);
            }
        });

    });

    // process colors
    var PROGRESS_BAR_GREY = 'progress-bar-grey';
    var PROGRESS_BAR_GOOD = 'progress-bar-good';
    var PROGRESS_BAR_WARNING = 'progress-bar-warning';
    var PROGRESS_BAR_DANGER = 'progress-bar-danger';

    // Total Consumption
    FleetPerformanceGraphService.buildMetric({
        key: 'tc',
        dataFilter: function(report) {
            return !report.arr;
        },
        options: { columnStacking: 'normal', },
        supplementalData: {
            'tcf_d': {
                name: 'Excess from Charter Party Fuel',
                type: 'column',
                width: 1,
                color: themePalette.colors.RED,
            },
            'tcf': {
                name: 'Charter Party Fuel',
                type: 'column',
                color: themePalette.colors.GRAPH_BLUE,
                fillOpacity: 0.3,
                marker: { enabled: false },
            },
        },
        tooltipDataKeys: {
            'max_total_cp_fuel': {
                name: 'Maximum Allowable Fuel',
                unit: 'MT/24 hrs'
            },
            'bc': {
                name: 'Ballast Condition',
                valueFormatter: function(value) { return value ? BallastCondition.Laden: BallastCondition.Ballast; },
            },
            'log_speed': {
                name: 'Log Speed',
                unit: 'kn',
            },
            's': {
                name: 'GPS Speed',
                unit: 'kn',
            },
            'mean_draught': {
                name: 'Mean Draught',
                unit: 'm',
            },
        },
        setTooltipsFunction: function(vessel) {
            vessel.displayData.tc_tooltip = Math.round(vessel.tc_avg * 10) / 10 + ' MT';
            var charterPartyFuel = getAverageFromKey(vessel.tcf, 1);
            if (charterPartyFuel > 0) {
                vessel.tcf_avg = Math.min(vessel.tc_avg, charterPartyFuel);
                vessel.displayData.tcfDiff = Math.max(vessel.tc_avg - charterPartyFuel, 0);
                if (vessel.displayData.tcfDiff > 0) {
                    vessel.displayData.tc_tooltip = 'Charter party instructed fuel consumption is ' + Math.round(charterPartyFuel * 10) / 10 + ' MT.';
                }
                vessel.displayData.tcf_tooltip = 'Average consumption is ' + Math.round(vessel.tc_avg * 10) / 10 + ' MT. Consumption is above the agreed on charter party fuel consumption by ' + Math.round(vessel.tcf_d_avg * 10) / 10 + ' MT, on average.';

            } else {
                vessel.tcf_avg = vessel.tc_avg;
                vessel.displayData.tcfDiff = 0;
                vessel.displayData.tc_tooltip = Math.round(vessel.tc_avg * 10) / 10 + ' MT';
            }
        },
    });

    // Consumption
    FleetPerformanceGraphService.buildMetric({
        key: 'c',
        dataFilter: function(report) {
            return !report.arr;
        },
        options: { columnStacking: 'normal', },
        supplementalData: {
            'cf_d': {
                name: 'Excess from Charter Party Fuel',
                type: 'column',
                width: 1,
                color: themePalette.colors.RED,
            },
            'cf': {
                name: 'Charter Party Fuel',
                type: 'column',
                color: themePalette.colors.GRAPH_BLUE,
                fillOpacity: 0.3,
                marker: { enabled: false },
            },
        },
        tooltipDataKeys: {
            'max_cp_fuel': {
                name: 'Maximum Allowable Fuel',
                unit: 'MT/24 hrs'
            },
            'bc': {
                name: 'Ballast Condition',
                valueFormatter: function(value) { return value ? BallastCondition.Laden: BallastCondition.Ballast; },
            },
            'log_speed': {
                name: 'Log Speed',
                unit: 'kn',
            },
            's': {
                name: 'GPS Speed',
                unit: 'kn',
            },
            'mean_draught': {
                name: 'Mean Draught',
                unit: 'm',
            },
        },
        setTooltipsFunction: function(vessel) {
            vessel.displayData.c_tooltip = Math.round(vessel.c_avg * 10) / 10 + ' MT';
            var charterPartyFuel = getAverageFromKey(vessel.cf, 1);
            if (charterPartyFuel > 0) {
                vessel.cf_avg = Math.min(vessel.c_avg, charterPartyFuel);
                vessel.displayData.cfDiff = Math.max(vessel.c_avg - charterPartyFuel, 0);
                if (vessel.displayData.cfDiff > 0) {
                    vessel.displayData.c_tooltip = 'Charter party instructed fuel consumption is ' + Math.round(charterPartyFuel * 10) / 10 + ' MT.';
                }
                vessel.displayData.cf_tooltip = 'Average consumption is ' + Math.round(vessel.c_avg * 10) / 10 + ' MT. Consumption is above the agreed on charter party fuel consumption by ' + Math.round(vessel.cf_d_avg * 10) / 10 + ' MT, on average.';

            } else {
                vessel.cf_avg = vessel.c_avg;
                vessel.displayData.cfDiff = 0;
                vessel.displayData.c_tooltip = Math.round(vessel.c_avg * 10) / 10 + ' MT';
            }
        },
    });

    // EEOI
    FleetPerformanceGraphService.buildMetric({
        key: 'e',
        colorToProgressClassFunction: function(vessel) {
            if (vessel.e_avg == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (vessel.e_avg < vessel.eeoi_quartiles.lower) {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            } else if (vessel.e_avg < vessel.eeoi_quartiles.upper) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            }
        },
        dataFilter: function(report) {
            return report[$scope.pageMetric] != 0 && report[$scope.pageMetric] > 0.5 && report[$scope.pageMetric] <= $scope.getEEOIMax(report.vessel);
        },
        options: {
            alwaysMatch: true,
            breakTrendLineOnDryDocking: true,
            drawTrendLine: true,
            lineWidth: 0,
            useClassBasedYMinMax: false,
            plotSupplementalData: false,
            name: 'EEOI, Past 6 Voyages',
        },
        plotLines: function(vessel) {
            return [{
                value: vessel.eeoi_quartiles.upper,
                color: themePalette.colors.RED,
                dashStyle: 'dot',
                width: 2,
                label: { text: '', },
            }, {
                value: vessel.eeoi_quartiles.lower,
                color: themePalette.colors.GREEN,
                dashStyle: 'dot',
                width: 2,
                label: { text: '', },
            }];
        },
        pushToNotMatched: function(report) {
            return report[$scope.pageMetric] > 0;
        },
        setTooltipsFunction: function(vessel) {
            const unitLabel = vessel.getMetricLabel('e');
            vessel.displayData.e_tooltip = Math.round(vessel.e_avg * 10) / 10 + ` ${unitLabel}`;
        },
        supplementalData: {
            'tres_voyage_number': {},
            'total_obs_distance': {
                name: 'Total Observed Distance',
                unit: 'NM',
            },
            'co2_emissions': {
                name: 'CO2 Emissions',
                unit: 'MT',
            },
            'total_consumption': {
                name: 'Total Consumption',
                unit: 'MT',
            },
            'cargo_quantity': {
                name: 'Cargo Quantity',
                unit: 'MT',
            },
        },
    });

    // AER
    FleetPerformanceGraphService.buildMetric({
        key: 'aer',
        colorToProgressClassFunction: function(vessel) {
            if (vessel.aer_avg == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (vessel.aer_avg < vessel.aer_quartiles.lower) {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            } else if (vessel.aer_avg < vessel.aer_quartiles.upper) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            }
        },
        dataFilter: function(report) {
            return !report.arr;
        },
        options: {
            alwaysMatch: true,
            breakTrendLineOnDryDocking: true,
            drawTrendLine: true,
            lineWidth: 0,
            useClassBasedYMinMax: false,
            plotSupplementalData: false,
            name: 'AER, Each Voyages',
        },
        plotLines: function(vessel) {
            return [{
                value: vessel.aer_quartiles.upper,
                color: '#AC0000',
                dashStyle: 'dot',
                width: 2,
                label: { text: '', },
            }, {
                value: vessel.aer_quartiles.lower,
                color: '#a1bc39',
                dashStyle: 'dot',
                width: 2,
                label: { text: '', },
            }];
        },
        supplementalData: {
            'tres_voyage_number': {},
            'total_obs_distance': {
                name: 'Total Observed Distance',
                unit: 'NM',
            },
            'co2_emissions': {
                name: 'CO2 Emissions',
                unit: 'MT',
            },
        },
    });

    // CII
    FleetPerformanceGraphService.buildMetric({
        key: 'cii',
        colorToProgressClassFunction: function(vessel) {
            if (vessel.cii_attained == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (vessel.cii_attained < vessel.cii_required_period * vessel.d1) {
                vessel.progressBarClass = CIIRatingClass.A;
            } else if (vessel.cii_attained >= vessel.cii_required_period * vessel.d1 && vessel.cii_attained < vessel.cii_required_period * vessel.d2) {
                vessel.progressBarClass = CIIRatingClass.B;
            } else if (vessel.cii_attained > vessel.cii_required_period * vessel.d2 && vessel.cii_attained < vessel.cii_required_period * vessel.d3) {
                vessel.progressBarClass = CIIRatingClass.C;
            } else if (vessel.cii_attained >= vessel.cii_required_period * vessel.d3 && vessel.cii_attained < vessel.cii_required_period * vessel.d4) {
                vessel.progressBarClass = CIIRatingClass.D;
            } else {
                vessel.progressBarClass = CIIRatingClass.E;
            }
        },
        setTooltipsFunction: function(vessel) {
            let rating;
            let value = 0;
            if (vessel.cii_attained < vessel.cii_required_period * vessel.d1) {
                rating = 'A';
                value = 1
            } else if (vessel.cii_attained >= vessel.cii_required_period * vessel.d1 && vessel.cii_attained < vessel.cii_required_period * vessel.d2) {
                rating = 'B';
                value = 2
            } else if (vessel.cii_attained >= vessel.cii_required_period * vessel.d2 && vessel.cii_attained < vessel.cii_required_period * vessel.d3) {
                rating = 'C';
                value = 3
            } else if (vessel.cii_attained >= vessel.cii_required_period * vessel.d3 && vessel.cii_attained < vessel.cii_required_period * vessel.d4) {
                rating = 'D';
                value = 4
            } else if (vessel.cii_attained > vessel.cii_required_period * vessel.d4) {
                rating = 'E';
                value = 5
            }
        
            vessel.displayData.progress_bar_cii_value = value;
            vessel.displayData.cii_tooltip = rating;
            vessel.cii_avg_period = vessel.ciiGraphData.map(([date, cii]) => [date, vessel.cii_attained]);       
        },
        dataFilter: function(report) {
            return !report.arr;
        },
        options: {
            alwaysMatch: true,
            breakTrendLineOnDryDocking: true,
            drawTrendLine: false,
            lineWidth: 2,
            useClassBasedYMinMax: false,
            plotSupplementalData: true,
            name: 'Attained CII, 90 Days',
            movingAverageByPeriod: 90,
            plotDynamicBands: true,
            color: themePalette.colors.CII_ATTAINED_LINE,
            yAxisName: 'Attained CII, 90 Days (g/MT.NM)'
        },
        plotDynamicBands: function(vessel) {
            let timeSeriesMovingAvg = vessel.ciiGraphData;
            let boundarySeries = [
                { name: 'A', color: themePalette.colors.CII_GREEN, data: []},
                { name: 'B', color: themePalette.colors.CII_LIGHT_GREEN, data: []},
                { name: 'C', color: themePalette.colors.CII_YELLOW, data: []},
                { name: 'D', color: themePalette.colors.CII_ORANGE, data: []},
                { name: 'E', color: themePalette.colors.CII_RED, data: []},
            ];
            let currentYearCII;
            timeSeriesMovingAvg.forEach(([date, cii]) => {
                let cii_required = vessel['cii_90_day_interval'][date]['cii_required'];
                if (cii_required > 0) {
                    currentYearCII = cii_required;
                }
                const superiorBoundary = vessel.d1 * currentYearCII;
                const lowerBoundary = vessel.d2 * currentYearCII;
                const upperBoundary = vessel.d3 * currentYearCII;
                const inferiorBoundary = vessel.d4 * currentYearCII;
                let minYValue = vessel.ciiGraphData.mapKey(1).min();
                let maxYValue = vessel.ciiGraphData.mapKey(1).max();
                if (minYValue > vessel.cii_attained) minYValue = vessel.cii_attained;
                if (maxYValue < vessel.cii_attained) maxYValue = vessel.cii_attained;
                let maxBoundary = inferiorBoundary  +  Math.abs(inferiorBoundary - upperBoundary);
                let minBoundary = superiorBoundary - Math.abs(lowerBoundary - superiorBoundary);
                if (maxYValue > maxBoundary ) {
                    maxBoundary = maxYValue *1.20 
                }
                if (minYValue < minBoundary) {
                    minBoundary = minYValue * 0.8;
                }
                
                if (currentYearCII) {
                    boundarySeries[0].data.push([date, minBoundary, superiorBoundary])
                    boundarySeries[1].data.push([date, superiorBoundary, lowerBoundary])
                    boundarySeries[2].data.push([date, lowerBoundary, upperBoundary])
                    boundarySeries[3].data.push([date, upperBoundary, inferiorBoundary])
                    boundarySeries[4].data.push([date, inferiorBoundary, maxBoundary])
                }
            })
            
            return boundarySeries;
        },
        tooltipDataKeys: {},
        supplementalData: {
            'cii_avg_period': {
                name: 'Attained CII, Period Avg.',
                unit: 'g/MT.NM',
                type: 'line',
                dashStyle: 'Dash',
                color: themePalette.colors.THEME_TEXT_COLOR,
                zIndex: 99,
                marker: { enabled: false, states: { hover: { enabled: false } }, radius: 2 },
                disableTooltip: true,
            }
        },
    });

    // Normalized Consumption
    FleetPerformanceGraphService.buildMetric({
        key: 'nc',
        plotLines: function(vessel) {
            return [{
                value: vessel.current_nc_reference_line_value,
                color: Color.YELLOW,
                dashStyle: 'shortdash',
                width: 2,
                label: {
                    text: 'Normalized Consumption Reference: ' + Math.round(vessel.current_nc_reference_line_value * 100) / 100 + ' MT/24 Hrs'
                }
            }];
        },
        setTooltipsFunction: function(vessel) {
            if (vessel.nc_avg == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (vessel.nc_avg >= vessel.current_nc_reference_line_value * 1.15) {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            } else if (vessel.nc_avg >= vessel.current_nc_reference_line_value * 1.1) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            }
        },
    });

    // Propulsion Efficiency
    FleetPerformanceGraphService.buildMetric({
        key: 'p',
        colorToProgressClassFunction: function(vessel) {
            if (vessel.p_avg == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (vessel.p_avg >= 95) {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            } else if (vessel.p_avg >= 85) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            }
        },
        options: {
            breakTrendLineOnDryDocking: true,
            breakTrendLineOnHullCleaning: true,
            drawTrendLine: true,
            enableTrendLineTooltip: true,
            lineWidth: 0,
        },
        tooltipDataKeys: {
            'log_speed': {
                name: 'Log Speed',
                unit: 'kn',
            },
            's': {
                name: 'GPS Speed',
                unit: 'kn',
            },
            'mean_draught': {
                name: 'Mean Draught',
                unit: 'm',
            },
            'me_consumption': {
                name: 'ME Consumption',
                unit: 'MT',
            },
        },
    });

    $scope.shouldShowMetric = function(metricKey) {
        return $scope.isInternalAdmin || !settings.fleet_metrics[metricKey] || settings.fleet_metrics[metricKey]?.enabled;
    };

    // Average Speed
    FleetPerformanceGraphService.buildMetric({
        key: 's',
        extraYAxes: [{
            min: 0,
            title: { text: 'Deviation from CP (Knots)' },
            opposite: true
        }],
        setTooltipsFunction: function(vessel) {
            vessel.displayData.s_tooltip = Math.round(vessel.s_avg * 10) / 10 + ' Knots';
            var cpSpeed = getAverageFromKey(vessel.cp, 1);
            vessel.displayData.cpDiff = Math.max(cpSpeed - vessel.s_avg, 0);
            if (vessel.displayData.cpDiff > 0) {
                vessel.displayData.cs_tooltip = 'Below average charter party speed by ' + Math.round(vessel.displayData.cpDiff * 10) / 10 + ' Knots, on average.';
            }
        },
        processReport: function(report, vessel) {
            var cpSpeedDiff = report.cp_d;
            if (cpSpeedDiff > 0) report.cp_d = 0; 
        },
        supplementalData: {
            'a': {
                name: 'AIS Average Speed',
                type: 'line',
                width: 1,
                color: Color.DATA_4,
                yAxis: 0,
                zIndex: 2,
                marker: {
                    enabled: true,
                    symbol: 'circle'
                },
                connectNulls: true,
            },
            'cp': {
                name: 'Charter Party Speed',
                type: 'column',
                color: Color.GRAPH_BLUE,
                fillOpacity: 0.3,
                width: 1,
                marker: { enabled: false },
                zIndex: 1,

            },
            'cp_d': {
                name: 'Charter Party Speed Shortfall',
                type: 'column',
                width: 1,
                color: Color.RED,
                yAxis: 1,
                zIndex: 2,
            },
        },
    });

    // SFOC
    FleetPerformanceGraphService.buildMetric({
        key: 'sf_c',
        dataFilter: function(report) {
            return report[$scope.pageMetric] >= 100 && report[$scope.pageMetric] <= 300;
        },
        options: {
            lineWidth: 0,
            movingAverage: 15,
        },
        xAxisPlotLines: function(vessel) {
            vessel.overhaulDates = vessel.dry_dockings
                .filter(function(dd) { return dd.me_over_haul; })
                .map(function(dd) { return new Date(dd.date); });
            return vessel.overhaulDates.map(function(date) {
                var text = 'Engine Overhaul: ' + date.toLocaleDateString();

                return {
                    color: Color.YELLOW,
                    dashStyle: 'solid',
                    width: 1,
                    value: date,
                    label: {
                        rotation: 0,
                        verticalAlign: 'bottom',
                        y: -6,
                        text: text,
                    }
                };
            });
        },
        supplementalData: {
            'sf_m': {
                name: 'SFOC Model',
                type: 'line',
                width: 1,
                color: Color.DATA_4,
                yAxis: 0,
                zIndex: 2,
            },
        },
    });

    // SFOC Deviation
    FleetPerformanceGraphService.buildMetric({
        key: 'sf_d',
        dataFilter: function(report) {
            return report[$scope.pageMetric] >= -100 && report[$scope.pageMetric] <= 100;
        },
        tooltipDataKeys: {
            'fuel_grade_in_use_main_engine': {
                name: 'Fuel Grade',
                unit: '',
            },
            'me_load': {
                name: 'ME Load',
                unit: '',
            },
            'sf_c': {
                name: 'SFOC Reported',
                unit: '',
            },
            'sf_m': {
                name: 'SFOC Model',
                unit: '',
            },
            'tank_name': {
                name: 'Tank Name',
                unit: '',
            },
            'bunker_port': {
                name: 'Bunker Port',
                unit: '',
            },
            'bunker_date': {
                name: 'Bunker Date',
                unit: '',
            },
        },
    });

    // Speed Performance
    FleetPerformanceGraphService.buildMetric({
        key: 'sp',
        colorToProgressClassFunction: function(vessel) {
            if (vessel.sp_avg >= 120) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (vessel.sp_avg >= 95) {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            } else if (vessel.sp_avg >= 85) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            }
        },
        dataFilter: function(report) {
            return report[$scope.pageMetric] >= 0 && report[$scope.pageMetric] <= 150;
        },
    });

    // T/C RPM Deviation
    FleetPerformanceGraphService.buildMetric({
        key: 'tcrpm_deviation',
        options: {
            chartType: 'scatter',
            drawTrendLine: true,
        },
    });

    // CP Consumption Deviation
    FleetPerformanceGraphService.buildMetric({
        key: 'cp_consumption_deviation',
        chartEffects: function(chart, vessel) {
            chart.addSeries({
                name: 'Reported Consumption Deviation',
                color: Color.DATA_4,
                type: 'column',
            });
            chart.addSeries({
                name: 'Average Deviation',
                color: Color.YELLOW,
                marker: { enabled: false, states: { hover: { enabled: false } } },
            });
        },
        colorToProgressClassFunction: function(vessel) {
            var meanDiff = vessel.cp_consumption_deviation_mean_diff;
            if (meanDiff == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (meanDiff <= 0) {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            } else if (meanDiff <= 0.05) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            }
        },
        dataFilter: function(report) { return !!report['matches_baseline_conditions']; },
        pushToNotMatched: function(report) { return !report['matches_baseline_conditions'] || !report['match']; },
        options: {
            chartType: 'column',
            color: Color.RED,
            grouping: false,
            ignoreBackendMatch: true,
            name: 'Excess Consumption',
            negativeColor: Color.DATA_4,
            notAnalyzedName: 'Bad Weather Consumption (Not Analyzed)',
            plotSupplementalData: false,
            threshold: 0,
            useClassBasedYMinMax: true,
        },
        plotOptions: {
            series: {
                events: { legendItemClick: function() { return false; } },
                grouping: false,
                groupPadding: 0.1,
            }
        },
        plotLines: function(vessel) {
            return [{
                value: vessel.cp_consumption_deviation_avg,
                color: Color.YELLOW,
                width: 2,
            }];
        },
        setTooltipsFunction: function(vessel) {
            var totalCpFuel = vessel.total_cp_fuel.mapKey(1);
            var hasCpFuel = function(_, i) { return !!totalCpFuel[i]; };
            var filteredTotalCpFuel = totalCpFuel.filter(hasCpFuel);
            var totalFuelCons = vessel.total_fuel_consumption.mapKey(1).filter(hasCpFuel);
            var reportPeriod = vessel.report_period.mapKey(1).filter(hasCpFuel);
            var cpConsHoursSum = filteredTotalCpFuel.map(function(cpCons, i) { return cpCons * reportPeriod[i]; }).sum();
            var cpConsHoursDiff = cpConsHoursSum
                ? totalFuelCons.map(function(cpCons, i) { return cpCons * reportPeriod[i]; }).sum() - cpConsHoursSum
                : undefined;
            var meanDiff = (cpConsHoursDiff / cpConsHoursSum) || undefined;
            vessel.cp_consumption_deviation_mean_diff_abs = meanDiff && Math.abs(meanDiff);
            vessel.cp_consumption_deviation_mean_diff = meanDiff;
            vessel.displayData.cp_consumption_deviation_tooltip = roundToPlaces(meanDiff * 100, 1) + '%';
            vessel.cp_consumption_deviation_avg = (cpConsHoursDiff / reportPeriod.sum()) || undefined;
        },
        supplementalData: {
            'matches_baseline_conditions': {},
            'total_fuel_24': {},
            'total_fuel_consumption': {},
            'report_period': {},
        },
        tooltipDataKeys: {
            'total_cp_fuel': {
                name: 'Charter Party Fuel',
                unit: 'MT/24hrs'
            },
            'max_total_cp_fuel': {
                name: 'Maximum Allowable Fuel',
                unit: 'MT/24 hrs'
            },
            'me_consumption': {
                name: 'ME Consumption',
                unit: 'MT/24hrs'
            },
            'wni_bf': {
                name: 'BF',
                unit: ''
            },
            'wni_wave_height': {
                name: 'Wave Height',
                unit: 'm'
            }
        }
    });

    // CP Speed Difference
    FleetPerformanceGraphService.buildMetric({
        key: 'cp_speed_difference',
        chartEffects: function(chart) {
            chart.addSeries({
                name: 'Speed Shortfall',
                color: Color.RED,
                type: 'column',
            });
            chart.addSeries({
                name: 'Average Speed Deviation',
                color: Color.YELLOW,
                marker: { enabled: false, states: { hover: { enabled: false } } },
            });
        },
        colorToProgressClassFunction: function(vessel) {
            var meanDiff = vessel.cp_speed_difference_mean_diff;
            if (meanDiff == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (meanDiff >= 0) {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            } else if (meanDiff >= -0.05) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            }
        },
        dataFilter: function(report) { return !!report['matches_baseline_conditions']; },
        pushToNotMatched: function(report) { return !report['matches_baseline_conditions'] || !report['match']; },
        options: {
            chartType: 'column',
            color: Color.DATA_4,
            grouping: false,
            ignoreBackendMatch: true,
            name: 'Performance Speed Deviation',
            negativeColor: Color.RED,
            notAnalyzedName: 'Bad Weather (Not Analyzed)',
            plotSupplementalData: false,
            threshold: 0,
            useClassBasedYMinMax: true,
        },
        plotOptions: {
            series: {
                events: { legendItemClick: function() { return false; } },
                grouping: false,
                groupPadding: 0.1,
            }
        },
        plotLines: function(vessel) {
            return [{
                value: vessel.cp_speed_difference_avg,
                color: Color.YELLOW,
                width: 2,
            }];
        },
        setTooltipsFunction: function(vessel) {
            var cpSpeed = vessel.cp_speed.mapKey(1);
            var perfSpeed = vessel.performance_speed.mapKey(1);
            var hasCpSpeedAndPerformanceSpeed = function(_, i) { return !!cpSpeed[i] && !!perfSpeed[i]; };
            var filteredCpSpeeds = cpSpeed.filter(hasCpSpeedAndPerformanceSpeed);
            var performanceSpeed = perfSpeed.filter(hasCpSpeedAndPerformanceSpeed);
            var reportPeriod = vessel.report_period.mapKey(1).filter(hasCpSpeedAndPerformanceSpeed);
            var cpSpeedHoursSum = filteredCpSpeeds.map(function(speed, i) { return speed * reportPeriod[i]; }).sum();
            //  Sum (CP Speed x report period) - Sum(Perf. Speed x report period)
            var cpSpeedHoursDiff = cpSpeedHoursSum
                ? performanceSpeed.map(function(speed, i) { return speed * reportPeriod[i]; }).sum() - cpSpeedHoursSum
                : undefined;
            // Above divided by Sum (CP Speed x report period)
            var meanDiff = cpSpeedHoursSum ? cpSpeedHoursDiff / cpSpeedHoursSum : undefined;
            vessel.cp_speed_difference_mean_diff = meanDiff;
            vessel.cp_speed_difference_mean_diff_abs = meanDiff && Math.abs(meanDiff);
            vessel.displayData.cp_speed_difference_tooltip = roundToPlaces(meanDiff * 100, 1) + '%';
            // Above divided by sum(report period hrs)
            vessel.cp_speed_difference_avg = cpSpeedHoursDiff / reportPeriod.sum() || undefined;
        },
        supplementalData: {
            'matches_baseline_conditions': {},
            'cp_speed': {},
            'report_period': {},
        },
        tooltipDataKeys: {
            'cp_speed': {
                name: 'Charter Party Speed',
                unit: 'knots'
            },
            'min_cp_speed': {
                name: 'Minimum Allowable Speed',
                unit: 'knots'
            },
            'performance_speed': {
                name: 'Performance Speed',
                unit: 'kn'
            },
            's': {
                name: 'GPS Speed',
                unit: 'kn'
            },
            'wni_bf': {
                name: 'BF',
                unit: ''
            },
            'wni_wave_height': {
                name: 'Wave Height',
                unit: 'm'
            }
        }
    });

    // ME Excess Consumption
    FleetPerformanceGraphService.buildMetric({
        key: 'me_excess_consumption',
        colorToProgressClassFunction: function(vessel) {
            if (vessel.me_excess_consumption_avg == undefined) {
                vessel.progressBarClass = PROGRESS_BAR_GREY;
            } else if (vessel.me_excess_consumption_avg <= 1) {
                vessel.progressBarClass = PROGRESS_BAR_GOOD;
            } else if (vessel.me_excess_consumption_avg < 3) {
                vessel.progressBarClass = PROGRESS_BAR_WARNING;
            } else {
                vessel.progressBarClass = PROGRESS_BAR_DANGER;
            }
        },
        dataFilter: function(report) {
            return report.propEfficiencySfocCorr >= 50 && report.propEfficiencySfocCorr <= 150;
        },  
        options: {
            chartType: 'spline',
            breakTrendLineOnDryDocking: true,
            breakTrendLineOnHullCleaning: true,
            drawTrendLine: true,
            hideUnmatchedByDefault: true,   
            movingAverage: 15,
        },
        plotOptions: {
            series: {
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
            },
            spline: {
                lineWidth: 0,
            }
        },
        processReport: function(report, vessel) {
            var sfocAllowance = Math.min(Math.max(report.sfoc_deviation || 0, 0), 10);
            var propEfficiencySfocCorr = (report.propulsion_efficiency || 0) + sfocAllowance;
            var reportDate = new Date(report.t);
            var meCons24_3MonthAvg = (vessel.c || [])
                .filter(function(point: Point) {
                    var pointDate = new Date(point[0]);
                    return pointDate >= reportDate.addDays(-365 / 4) && pointDate < reportDate;
                })
                .mapKey(1)
                .mean();
            var meExcessMt24 = meCons24_3MonthAvg * (100 - propEfficiencySfocCorr) / 100;
            report.sfocAllowance = sfocAllowance;
            report.propEfficiencySfocCorr = propEfficiencySfocCorr;
            report.meCons24_3MonthAvg = meCons24_3MonthAvg;
            report.me_excess_consumption = (meExcessMt24 || undefined);
        },
    });

    $scope.recalculateData = function() {
        if (!$scope.allData) return;
        console.log(`Recalculating data`);
        var metric = FleetPerformanceGraphService.getMetric($scope.pageMetric);
        var AGGREGATE_METRIC_KEYS = ['c', 'tc', 'e', 's', 'p', 'tres_voyage_number', 'co2_emissions', 'cargo_quantity', 'total_obs_distance'];
        var metricKeys = Object.keys(metric.supplementalData)
            .concat(Object.keys(metric.tooltipDataKeys))
            .concat(AGGREGATE_METRIC_KEYS);

        $scope.fleetPerformance = {
            vessels: [],
            fleet: {
                week1Stats: {},
                week2Stats: {},
                week1Emissions: 0,
                week1TransportWork: 0,
                week1DeadweightWork: 0,
                week2Emissions: 0,
                week2TransportWork: 0,
                week2DeadweightWork: 0,
            }
        };

        var lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        var twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        var week1Stats = [];
        var week2Stats = [];

        for (var vesselIndex = 0; vesselIndex < $scope.allData.length; vesselIndex ++) {
            let currentVessel: FPAPIVessel = $scope.allData[vesselIndex];
            currentVessel.current_nc_reference_line_value = $scope.options.voyageLeg == BallastCondition.Ballast
                ? currentVessel.ncb_reference
                : $scope.options.voyageLeg == BallastCondition.All ? null : currentVessel.nc_reference;
            // filter on vessel class, if that is set
            if (!$scope.options.vesselClasses[currentVessel.vessel_class]) {
                continue;
            }
            // filter on specific vessel, if that is set
            if (!$scope.options.vessels[currentVessel.vessel_id]) {
                continue;
            }


            let vessel = new FPVessel(currentVessel);
            let fromDate = getFromDate(vessel);

            for (var reportIndex = 0; reportIndex < currentVessel.data.length; reportIndex ++) {
                let report: FPAPIReport = currentVessel.data[reportIndex];
                report.vessel = vessel;
                vessel.all_reports.push(report);
                var reportDate = new Date(report.t);
                var reportFromDate = new Date(report.f);
                var reportFromTimestamp = reportFromDate.getTime();
                var reportTimestamp = reportDate.getTime();

                // PAGE FILTERS
                var backendMatch = report['match'] || metric.options.ignoreBackendMatch;
                var isWeek1Report = lastWeek.getTime() < reportTimestamp;
                var isWeek2Report = twoWeeksAgo.getTime() < reportTimestamp && reportTimestamp < lastWeek.getTime();
                var emissions = report.co2_emissions || 0;
                var cii_emissions = report.cii_co2_emissions || 0;
                var transportWork = vessel.getTransportWork(report);
                var deadweightWork = (currentVessel.dwt || 0) * (report.total_obs_distance || 0);
                var cii_required = (report.cii_required || 0);
                if (isWeek1Report) {
                    $scope.fleetPerformance.fleet.week1Emissions += emissions;
                    $scope.fleetPerformance.fleet.week1TransportWork += transportWork;
                    $scope.fleetPerformance.fleet.week1DeadweightWork += deadweightWork;
                }
                if (isWeek2Report) {
                    $scope.fleetPerformance.fleet.week2Emissions += emissions;
                    $scope.fleetPerformance.fleet.week2TransportWork += transportWork;
                    $scope.fleetPerformance.fleet.week2DeadweightWork += deadweightWork;
                }
                // date range filter
                if (($scope.options.reportTo && $scope.options.reportTo.getTime() < reportTimestamp)
                 || (fromDate && fromDate.getTime() > reportTimestamp && $scope.pageMetric != 'cii') 
                 || (fromDate && fromDate.getTime() > reportFromTimestamp && $scope.pageMetric === 'cii' )) {
                    continue;
                }
                // Needed for fleet-wide EEOI calculation. Needs to be after date filters but before BF and ballast/laden filter.
                vessel.date_range_emissions += emissions;
                vessel.date_range_transport_work += transportWork;
                vessel.date_range_deadweight_work += deadweightWork;
  
                // CII - exclude reports with no distance travel value or dwt either because its port report or dwt wasn't entered in.
                // update cii capacity from backend and change to cii_distance  for distance
                vessel.date_range_cii_emissions += cii_emissions;
                var ciiDeadWeightWork = (currentVessel.capacity_correction_factor || 1) * (currentVessel.cii_capacity || 0) * (report.cii_distance || 0)
                var report_cii_attained = calculateEffiencyRatio(emissions, ciiDeadWeightWork);
                if (report_cii_attained != null) {
                    vessel.date_range_cii_required.push(cii_required);
                }
                var cii_distance = report.cii_distance || 0;
                vessel.date_range_distance += cii_distance;
                // voyage leg filter
                if ($scope.options.voyageLeg && $scope.options.voyageLeg != BallastCondition.All && $scope.options.voyageLeg != report['ballast_condition']) {
                    continue;
                }
                // BF filter
                if ($scope.options.bf && $scope.options.bf < report.bf) {
                    continue;
                }
                // Week-over-week analysis
                if (isWeek1Report && backendMatch) {
                    week1Stats.push(report);
                }
                if (isWeek2Report && backendMatch) {
                    week2Stats.push(report);
                }

                metric.processReport(report, vessel);

                vessel['report_number'].push([reportTimestamp, report['report_number']]);

                if ((backendMatch && metric.dataFilter(report)) || metric.options.alwaysMatch) {
                    // MAIN METRICS
                    vessel.timeseries.push([reportTimestamp, report[metric.key]]);

                } else {
                    if (metric.pushToNotMatched(report)) {
                        vessel.notMatchedSeries.push([reportTimestamp, report[metric.key]]);
                        vessel['report_number_not_matched'].push([reportTimestamp, report['report_number']]);
                    }
                }
                // Add SUPPLEMENTAL METRICS, TOOLTIP METRICS, and AGGREGATE METRICS
                angular.forEach(metricKeys, function(key) {
                    (vessel[key] = vessel[key] || []).push([reportTimestamp, report[key]]);
                });
            }

            // VESSEL MEANS
            if (metric.options.useMaxForProgressBars) {
                vessel[metric.key + '_avg'] = getMaxFromKey(vessel.timeseries, 1);
            } else {
                vessel[metric.key + '_avg'] = getAverageFromKey(vessel.timeseries, 1);
            }
            angular.forEach(AGGREGATE_METRIC_KEYS, function(key) {
                vessel[key + '_avg'] = getAverageFromKey(vessel[key], 1);
            });
            angular.forEach(Object.keys(metric.supplementalData), function(key) {
                vessel[key + '_avg'] = getAverageFromKey(vessel[key], 1);
            });
            
            if (['e', 'aer'].indexOf(metric.key) != -1) {
                var metricLabel = 'date_range_' + vesselListLabels[metric.key].toLocaleLowerCase();
                var date_range_work = metric.key == 'e' ? vessel.date_range_transport_work : vessel.date_range_deadweight_work;
                vessel[metricLabel] = calculateEffiencyRatio(vessel.date_range_emissions, date_range_work);
                vessel[metric.key + '_avg'] = vessel[metricLabel];
            }

            if (metric.key == 'cii') {
                let ciiRquiredSum = vessel.date_range_cii_required.sum();
                let ciiRequiredCount = vessel.date_range_cii_required.length;
                vessel.cii_attained = calculateEffiencyRatio(vessel.date_range_cii_emissions, vessel.capacity_correction_factor * vessel.cii_capacity * vessel.date_range_distance);
                vessel.cii_required_period = ciiRequiredCount > 0 ? ciiRquiredSum / ciiRequiredCount : undefined;
                vessel[metric.key + '_avg'] = vessel.cii_required_period > 0 ? (vessel.cii_attained - vessel.cii_required_period) / vessel.cii_required_period * 100 : undefined;
            }

            // SORT ALL SERIES
            vessel.timeseries.sort(function(a, b) { return a[0] - b[0]; });
            vessel.notMatchedSeries.sort(function(a, b) { return a[0] - b[0]; });
            angular.forEach(metricKeys, function(key) {
                (vessel[key] = vessel[key] || []).sort(function(a, b) { return a[0] - b[0]; });
            });

            // CII Aggregate Graph Data
            if (metric.options?.movingAverageByPeriod > 0) {
                var intervalLength = metric.options?.movingAverageByPeriod || 14;
                const intervalKey = metric.key + '_' + intervalLength + '_day_interval';
                vessel[intervalKey] = {};
             
                let co2_emission_sum = 0;
                let total_distance_sum = 0;
                let cii_required;
                let numberOfDays = 0;
                let startTimeStamp;
                let startDateReportIndex;
                vessel.all_reports.sort((a, b) => new Date(a.t).getTime() - new Date(b.t).getTime())

                // calculate simple cii attained avg for first interval (n) days to avoid initial fluctation on cii graph
                const firstReportDateTime = vessel.all_reports.length > 0 && vessel.all_reports[0].t && new Date(vessel.all_reports[0].t);
                const endOfFirstIntervalReportTimestamp = firstReportDateTime && firstReportDateTime.addDays(intervalLength).getTime();
                let firstIntervalSumObsDistance = 0;
                let firstIntervalSumCo2Emissions = 0;
                let firstIntervalCiiAttained = 0;
                
                // calculate moving cii moving avg
                for (let i = 0; i < vessel.all_reports.length ;i++) {
                    let report = vessel.all_reports[i];
                    let reportTimestamp = (new Date(report.t)).getTime();
                    if (reportTimestamp > endOfFirstIntervalReportTimestamp) {
                        break;
                    }
                    firstIntervalSumObsDistance += report.cii_distance || 0;
                    firstIntervalSumCo2Emissions += report.cii_co2_emissions || 0;
                    firstIntervalCiiAttained = calculateEffiencyRatio(firstIntervalSumCo2Emissions, (vessel.capacity_correction_factor || 1) * vessel.cii_capacity * firstIntervalSumObsDistance)
                }
                
                // calculate moving cii moving avg
                for (let i = 0; i < vessel.all_reports.length ;i++) {
                    let report = vessel.all_reports[i];
                    let reportTimestamp = (new Date(report.t)).getTime();
                    let reportFromTimestamp = (new Date(report.f)).getTime();
                    // include reports outside the date range to ensure starting reports calculates moving avg
                    if (reportTimestamp > $scope.options.reportTo.getTime() || reportTimestamp < fromDate.addDays(-intervalLength).getTime() ) {
                        continue;
                    }
                    co2_emission_sum += report.cii_co2_emissions || 0;
                    total_distance_sum += report.cii_distance || 0;
                    cii_required = cii_required ? cii_required : report.cii_required;
                    if (!startTimeStamp) {
                        startTimeStamp = reportTimestamp;
                        startDateReportIndex = i;
                    } else {
                        numberOfDays = moment(reportTimestamp).diff(moment(startTimeStamp), 'days');
                    }
                    // 'pop' the data that's now on the left of the window of the moving average
                    // by deducting co2 emissions and total obs distance of the report that's right 
                    if (numberOfDays > intervalLength) {
                        startTimeStamp = moment(reportTimestamp).add(-intervalLength, 'days').unix() * 1000;
                        while ((new Date(vessel.all_reports[startDateReportIndex].t)).getTime() <= startTimeStamp) {
                            co2_emission_sum -= vessel.all_reports[startDateReportIndex].cii_co2_emissions || 0;
                            total_distance_sum -= vessel.all_reports[startDateReportIndex].cii_distance || 0;
                            startDateReportIndex++;
                        }
                    }
                    total_distance_sum = total_distance_sum < 0 ? 0 : total_distance_sum;
                    co2_emission_sum = co2_emission_sum < 0 ? 0 : co2_emission_sum;
                    let ciiAttained = reportTimestamp <= endOfFirstIntervalReportTimestamp ? firstIntervalCiiAttained : calculateEffiencyRatio(co2_emission_sum, (vessel.capacity_correction_factor || 1) * vessel.cii_capacity * total_distance_sum);
                    if (reportTimestamp <= $scope.options.reportTo.getTime() && reportFromTimestamp >= fromDate.getTime() ) {
                        vessel[intervalKey][reportTimestamp] = {
                            co2_emission: report.co2_emissions,
                            total_obs_distance: report.total_obs_distance,
                            cii_attained: ciiAttained,
                            cii_required: report.cii_required,
                        }
                    }
                }
            
                Object.keys(vessel[intervalKey]).sort((a, b)=> parseInt(a) - parseInt(b)).map(function(date) {
                        let cii_attained = vessel[intervalKey][date]['cii_attained']
                        vessel.ciiGraphData.push([parseInt(date), cii_attained])
                })
            }

            // EEOI & AER GRAPHS
            // Zip the necessary metrics (tres_voyage_number, co2, cargo, datetime)
            var zipped = vessel.all_reports.map(function(report) {
                return {
                    tres_voyage_number: report.tres_voyage_number,
                    co2_emissions: report.co2_emissions,
                    cargo_quantity: report.cargo_quantity,
                    total_obs_distance: report.total_obs_distance,
                    datetime: report.t,
                    dwt_obs_dist: currentVessel.dwt * report.total_obs_distance,
                    fuel: report.fuel,
                    number_of_passengers: report.number_of_passengers,
                };
            });
            // Filter for reports with voyage number
            var filtered = zipped.filter(function(el) { return !!el.tres_voyage_number; });
            // Group by tres voyage number. Number only, not SEA/PORT.
            vessel.voyages = filtered.groupBy(function(el) { return el.tres_voyage_number && el.tres_voyage_number.replace(/.*?(\d+)$/, '$1'); });
            // Sort on voyage number
            vessel.voyages.sort(function(a, b) { return a[0].tres_voyage_number.replace(/.*?(\d+)$/, '$1') - b[0].tres_voyage_number.replace(/.*?(\d+)$/, '$1'); });
            var eeoiAverages = [];
            var allVoyageEmissions = [];
            var allVoyageTransportWorks = [];
            var NUM_OF_VOYAGES_IN_ROLLING_AVG = 6;
            for (var i = 0; i < vessel.voyages.length; i++) {
                var voyageReports = vessel.voyages[i];
                var voyageEmissions = voyageReports.map(function(report) { return report.co2_emissions || 0; }).sum();
                var voyageCargoQuantity = voyageReports.map(function(report) { return report.cargo_quantity || 0; }).sum();
                var voyageTotalObsDistance = voyageReports.map(function(report) { return report.total_obs_distance || 0; }).sum();
                var voyageTransportWork = voyageReports.map(function(report) { return vessel.getTransportWork(report); }).sum();
                var voyageEEOI = calculateEffiencyRatio(voyageEmissions, voyageTransportWork);
                var voyageEndDate = voyageReports.mapKey('datetime').map((dateStr: string) => moment.utc(dateStr).toDate().getTime()).max();
                eeoiAverages.push(voyageEEOI);
                allVoyageEmissions.push(voyageEmissions);
                allVoyageTransportWorks.push(voyageTransportWork);
                var previousSixEmissionsSum = allVoyageEmissions.slice(Math.max(i - NUM_OF_VOYAGES_IN_ROLLING_AVG + 1, 0), i+1).sum();
                var previousSixTransportWorksSum = allVoyageTransportWorks.slice(Math.max(i - NUM_OF_VOYAGES_IN_ROLLING_AVG + 1, 0), i+1).sum();
                var previousSixAverage = calculateEffiencyRatio(previousSixEmissionsSum, previousSixTransportWorksSum);

                // AER
                var voyageDeadWork = voyageReports.map(function(report) { return report.dwt_obs_dist || 0; }).sum();
                var voyageAER = calculateEffiencyRatio(voyageEmissions, voyageDeadWork);
                var totalFuelConsumption = voyageReports.map(function(report) { return report.total_consumption || 0; }).sum();
                vessel.voyagesByEndDates[voyageEndDate] = {
                    tres_voyage_number: voyageReports[0].tres_voyage_number,
                    co2_emissions: voyageEmissions,
                    cargo_quantity: voyageCargoQuantity,
                    total_obs_distance: voyageTotalObsDistance,
                    transport_work: voyageTransportWork,
                    previousSixEmissionsSum: previousSixEmissionsSum,
                    previousSixTransportWorksSum: previousSixTransportWorksSum,
                    previousSixAverage: previousSixAverage,
                    fuel_consumption: totalFuelConsumption,
                };
                // date range filter
                if (($scope.options.reportTo && voyageEndDate <= $scope.options.reportTo)
                    && ($scope.options.reportFrom && voyageEndDate >= $scope.options.reportFrom)) {
                    vessel.eeoiGraphData.push([voyageEndDate, previousSixAverage]);
                    vessel.aerGraphData.push([voyageEndDate, voyageAER]);
                }

            }

            // first several voyages should all have the same eeoi to account for the rolling average.
            // https://support.navtor.com/a/tickets/1158242
            let idx = Math.max(0, Math.min(vessel.eeoiGraphData.length - 1, NUM_OF_VOYAGES_IN_ROLLING_AVG - 1))
            if (idx > 0) {
                let firstEeoiAverage = vessel.eeoiGraphData[idx][1];
                for (let i = 0; i < Math.min(vessel.eeoiGraphData.length, NUM_OF_VOYAGES_IN_ROLLING_AVG); i++) {
                    vessel.eeoiGraphData[i][1] = firstEeoiAverage;
                }
            }

            metric.setTooltipsFunction(vessel);
            metric.colorToProgressClassFunction(vessel);
            $scope.fleetPerformance.vessels.push(vessel);
        }

        // FLEET MEAN FOR AGGREGATE METRICS
        angular.forEach(AGGREGATE_METRIC_KEYS, function(key) {
            $scope.fleetPerformance.fleet[key] = getAverageFromKey($scope.fleetPerformance.vessels, key + '_avg');
        });
        $scope.fleetPerformance.fleet['cii'] = getAverageFromKey($scope.fleetPerformance.vessels, 'cii' + '_avg');
        // FLEET-WIDE EEOI
        $scope.fleetPerformance.fleet.emissions = 0;
        $scope.fleetPerformance.fleet.transportWork = 0;
        $scope.fleetPerformance.fleet.deadweightWork = 0;
        angular.forEach($scope.fleetPerformance.vessels, function(vessel) {
            $scope.fleetPerformance.fleet.emissions += (vessel.date_range_emissions || 0);
            $scope.fleetPerformance.fleet.transportWork += (vessel.date_range_transport_work || 0);
            $scope.fleetPerformance.fleet.deadweightWork += (vessel.date_range_deadweight_work || 0);
        });
        $scope.fleetPerformance.fleet['e'] = calculateEffiencyRatio($scope.fleetPerformance.fleet.emissions, $scope.fleetPerformance.fleet.transportWork);
        $scope.fleetPerformance.fleet['week1EEOI'] = calculateEffiencyRatio($scope.fleetPerformance.fleet.week1Emissions, $scope.fleetPerformance.fleet.week1TransportWork);
        $scope.fleetPerformance.fleet['week2EEOI'] = calculateEffiencyRatio($scope.fleetPerformance.fleet.week2Emissions, $scope.fleetPerformance.fleet.week2TransportWork);
        $scope.fleetPerformance.fleet['aer'] = calculateEffiencyRatio($scope.fleetPerformance.fleet.emissions, $scope.fleetPerformance.fleet.deadweightWork);
        $scope.fleetPerformance.fleet['week1AER'] = calculateEffiencyRatio($scope.fleetPerformance.fleet.week1Emissions, $scope.fleetPerformance.fleet.week1DeadweightWork);
        $scope.fleetPerformance.fleet['week2AER'] = calculateEffiencyRatio($scope.fleetPerformance.fleet.week2Emissions, $scope.fleetPerformance.fleet.week2DeadweightWork);


        // FLEET-WIDE MIN/MAX
        if (metric.key == 'cp_consumption_deviation' || metric.key == 'cp_speed_difference' || metric.key == 'cii') {
            $scope.fleetPerformance.fleet['min_' + metric.key] = getMinFromKey($scope.fleetPerformance.vessels, metric.key + '_mean_diff_abs') * 80 / 100;
            $scope.fleetPerformance.fleet['max_' + metric.key] = getMaxFromKey($scope.fleetPerformance.vessels, metric.key + '_mean_diff_abs') * 120 / 100;
        } else {
            let minValue = getMinFromKey($scope.fleetPerformance.vessels, metric.key + '_avg');
            let maxValue = getMaxFromKey($scope.fleetPerformance.vessels, metric.key + '_avg');
            let range = Math.abs(maxValue - minValue);
            minValue = minValue - Math.abs(range * 5 / 200);
            maxValue = maxValue + Math.abs(range * 5 / 100);
            $scope.fleetPerformance.fleet['min_' + metric.key] = minValue;
            $scope.fleetPerformance.fleet['max_' + metric.key] = maxValue;
        }

        // WEEK-OVER-WEEK ANALYSIS
        angular.forEach(['c', 'tc', 's', 'p'], function(key) {
            $scope.fleetPerformance.fleet.week1Stats[key] = getAverageFromKey(week1Stats, key);
            $scope.fleetPerformance.fleet.week2Stats[key] = getAverageFromKey(week2Stats, key);
            var new_key = key + '_change';
            var deviation = 0;
            if ($scope.fleetPerformance.fleet.week2Stats[key] > 0) {
                deviation = ($scope.fleetPerformance.fleet.week1Stats[key] - $scope.fleetPerformance.fleet.week2Stats[key]) * 100 / $scope.fleetPerformance.fleet.week2Stats[key];
            }
            $scope.fleetPerformance.fleet[new_key] = deviation;
        });
        var eeoiDeviation = $scope.fleetPerformance.fleet.week2EEOI
            && ($scope.fleetPerformance.fleet.week1EEOI - $scope.fleetPerformance.fleet.week2EEOI) * 100 / $scope.fleetPerformance.fleet.week2EEOI;
        var aerDeviation = $scope.fleetPerformance.fleet.week2AER
            && ($scope.fleetPerformance.fleet.week1AER - $scope.fleetPerformance.fleet.week2AER) * 100 / $scope.fleetPerformance.fleet.week2AER;
        $scope.fleetPerformance.fleet['e_change'] = eeoiDeviation;
        $scope.fleetPerformance.fleet['aer_change'] = aerDeviation;

        $timeout(function() {
            $scope.normaliseProgressBars();
        });
    };

    var getMinFromKey = function(series, key) {
        var min = 999999;
        for (var i = 0; i < series.length; i++) {
            var value = series[i][key];
            if (series[i] != undefined && isFinite(value) && !isNaN(value) && value < min) {
                min = value;
            }
        }
        return min;
    };
    var getMaxFromKey = function(series, key) {
        var max = -99999;
        for (var i = 0; i < series.length; i++) {
            var value = series[i][key];
            if (series[i] != undefined && isFinite(value) && !isNaN(value) && value > max) {
                max = value;
            }
        }
        return max;
    };

    var getAverageFromKey = function(series, key) {
        if (!series) return undefined;

        var sum = 0;
        var count = 0;
        for (var i = 0; i < series.length; i++) {
            if (series[i] != undefined && series[i][key] != undefined) {
                sum += series[i][key];
                count += 1;
            }
        }

        if (count > 0) {
            return sum / count;
        } else {
            return undefined;
        }
    };

    
    var savedMetric = $scope.shouldShowMetric(settings.selected_metric || 's') ? settings.selected_metric || 's' : $scope.fleetPerformanceDropdownOptions && $scope.fleetPerformanceDropdownOptions.length > 0 && $scope.fleetPerformanceDropdownOptions[0] || 's';
    $scope.pageMetric = savedMetric;
    getMetricData($scope.pageMetric);

    var vesselListUnits = {
        s: 'Knots',
        k: '',
        tc: 'MT/24 Hrs',
        c: 'MT/24 Hrs',
        e: 'g/MT.NM',
        aer: 'g/DWT-NM',
        m: 'NM/MT',
        p: '%',
        sp: '%',
        nc: 'MT/24 Hrs',
        sf_c: 'g/kWh',
        sf_d: '%',
        tcrpm_deviation: '%',
        cp_consumption_deviation: 'MT/24 Hrs',
        cp_speed_difference: 'Knots',
        me_excess_consumption: 'MT/24 Hrs',
        cii: '%',
    };
    var vesselListLabels = {
        s: 'Avg. Speed',
        k: 'KPI Score',
        tc: 'Total Consumption',
        c: 'ME Consumption',
        e: 'EEOI',
        aer: 'AER',
        m: 'Mileage',
        p: 'Propulsion Efficiency',
        sp: 'Speed Performance',
        nc: 'Normalized Consumption',
        sf_c: 'SFOC',
        sf_d: 'SFOC Dev.',
        tcrpm_deviation: 'T/C RPM Deviation',
        cp_consumption_deviation: 'CP Consumption Deviation',
        cp_speed_difference: 'CP Speed Difference',
        me_excess_consumption: 'ME Excess Consumption',
        cii: 'CII Difference',
    };
    $scope.vesselListLabels = vesselListLabels;
    var vesselListValueLabels = {
        s: 'Average Speed (Knots) and Charter Party Speed Deviation',
        k: 'KPI Score',
        tc: 'Total Consumption (MT/24 Hrs) and Excess from CP',
        c: 'ME Consumption (MT/24 Hrs) and Excess from CP',
        e: 'EEOI (g/MT.NM)',
        aer: 'AER (g/DWT-NM)',
        m: 'Mileage (NM/MT) and change from model fuel',
        p: 'Propulsion Efficiency (%)',
        sp: 'Speed Performance (%)',
        nc: 'Norm. ME Cons. (MT/24 Hrs)',
        sf_c: 'SFOC (g/kWr)',
        sf_d: 'SFOC Dev. (%)',
        tcrpm_deviation: 'T/C RPM Deviation (%)',
        cp_consumption_deviation: 'CP Consumption Deviation (%)',
        cp_speed_difference: 'CP Speed Difference (%)',
        me_excess_consumption: 'ME Excess Consumption (MT/24 Hrs)',
        cii: 'CII Rating',
    };

    $scope.vesselList = {
        label: vesselListLabels[savedMetric],
        valueLabel: vesselListValueLabels[savedMetric],
        key: savedMetric,
        orderKey: savedMetric + '_avg',
        unit: vesselListUnits[savedMetric],
        reverse: true,
    };

    $scope.setListKey = function(key) {
        var shouldReverse = ($scope.vesselList.orderKey === key) ? !$scope.vesselList.reverse : true;
        $scope.vesselList = {
            label: vesselListLabels[key],
            valueLabel: vesselListValueLabels[key],
            key: key,
            reverse: shouldReverse,
            unit: vesselListUnits[key],
            orderKey: key + '_avg',
        };
        $scope.pageMetric = key;
        $timeout(function() {
            $scope.normaliseProgressBars();
        });
        updateUserSettings();
    };
    $scope.setOrderKey = function(key) {
        var shouldReverse = ($scope.vesselList.orderKey === key) ? !$scope.vesselList.reverse : false;
        $scope.vesselList.reverse = shouldReverse;
        $scope.vesselList.orderKey = key;
        updateUserSettings();
    };

    $scope.waitAndDrawVesselGraph = function(vessel) {
        $timeout(function() {
            FleetPerformanceGraphService.drawVesselGraph(vessel, $scope);
        }, 500);
    };

    var updateUserSettings = function() {
        if (Object.keys($scope.options.vesselClasses).length > 0) {
            UserService.updateFleetPerformanceSettings($scope.user.id, {
                dateRange: $scope.options.dateRange,
                voyageLeg: $scope.options.voyageLeg,
                bf: $scope.options.bf,
                reportFrom: moment($scope.customReportFrom, 'DD/MM/YYYY HH:mm'),
                reportTo: moment($scope.customReportTo, 'DD/MM/YYYY HH:mm'),
                selectedClasses: $scope.options.vesselClasses,
                selectedVessels: $scope.options.vessels,
                selectedMetric: $scope.vesselList.key,
                selectedMetricReverse: $scope.vesselList.reverse
            }).then(function(response) {
                var res = response.data;
                $scope.user.features = res.features;
            });
        }
    };

    $scope.getVesselClassCSSClass = function(vesselClass) {
        var safeVesselClass = $scope.makeSafeForCSS(vesselClass);
        return 'vessel-menu-' + safeVesselClass;
    };

    $scope.makeSafeForCSS = function(input) {
        // https://stackoverflow.com/a/7627603/1279369
        // Replace anything in `input` that is not an alphanumeric character:
        // Replace space with hyphen
        // Replace symbols with their ascii hex value
        // Lowercase all letters
        return input?.trim().replace(/[^a-z0-9]/g, function(s) {
            var c = s.charCodeAt(0);
            if (c == 32) return '-';
            if (c >= 65 && c <= 90) return s.toLowerCase();
            return '__' + ('000' + c.toString(16)).slice(-4);
        });
    };

    $scope.showVesselToggles = function(cls) {
        var el = $('.vessel-menu-' + cls);
        el.show();
    };

    $scope.hideVesselToggles = function(cls) {
        var el = $('.vessel-menu-' + cls);
        el.hide();
    };

    $scope.toggleVessel = function(vesselId, cls) {
        $scope.options.vessels[vesselId] = !$scope.options.vessels[vesselId];
        if ($scope.options.vessels[vesselId] === true) {
            $scope.options.vesselClasses[cls] = true;
        }
        $scope.options.selectAll = $scope.areAllVesselsSelected();
    };

    $scope.$watch('options.vessels', function() {
        if ($scope.pageMetric) {
            $scope.recalculateData();
        }
    }, true);

    // logic to toggle expand/collapse of all vessel charts
    $scope.showingAllCharts = false;
    $scope.toggleExpandAll = function() {
        if (!$scope.showingAllCharts) {
            $timeout(function() {
                $('.fleet-performance-vessel > .collapsed').click();
            });
        } else {
            $timeout(function() {
                $('.fleet-performance-vessel > .row:not(.collapsed)').click();
            });
        }
        $scope.showingAllCharts = !$scope.showingAllCharts;
    };


    $scope.EEOIQuartilesSet = false;

    $scope.getEEOIMax = function(vessel) {
        var gccClientId = '59271458941636628c3713fd';
        return vessel.client_id == gccClientId ? 1000 : 20;
    };

    $scope.updateMetricQuartiles = function() {
        if ($scope.EEOIQuartilesSet) return;

        for (var vesselIndex = 0; vesselIndex < $scope.allData.length; vesselIndex++) {
            var currentVessel = $scope.allData[vesselIndex];
            var vesselSize = Math.round(currentVessel.dwt / 10000) * 10000;
            currentVessel.vesselSize = vesselSize;
        }
        for (var vesselIndex = 0; vesselIndex < $scope.allData.length; vesselIndex++) {
            var currentVessel = $scope.allData[vesselIndex];
            var similarVessels = $scope.allData.filter(function(v) { return v.vesselSize == currentVessel.vesselSize && v.vessel_type == currentVessel.vessel_type; });
            var sortedEEOIs = similarVessels.map(function(v) { return v.data; }).flatten().map(function(d) { return d.e; }).filter(function(eeoi) { return !!eeoi && eeoi < $scope.getEEOIMax(currentVessel); }).sort();
            var upperQuartileIndex = sortedEEOIs.length / 4 * 3;
            var lowerQuartileIndex = sortedEEOIs.length / 4;
            var upperQuartileValue = [sortedEEOIs[Math.floor(upperQuartileIndex)], sortedEEOIs[Math.ceil(upperQuartileIndex)]].mean();
            var lowerQuartileValue = [sortedEEOIs[Math.floor(lowerQuartileIndex)], sortedEEOIs[Math.ceil(lowerQuartileIndex)]].mean();

            currentVessel.eeoi_quartiles = {
                upper: currentVessel.eeoi_bot || upperQuartileValue,
                lower: currentVessel.eeoi_top || lowerQuartileValue
            };

            var aerUpperQuartileValue = currentVessel.aer_bot;
            var aerLowerQuartileValue = currentVessel.aer_top;
            if (currentVessel.aer_bot == undefined) {
                aerUpperQuartileValue = roundToPlaces(similarVessels.map(function(v) { return v.aer_bot; }).filter(aer => !!aer).mean(), 1);
            }
            if (currentVessel.aer_top == undefined) {
                aerLowerQuartileValue = roundToPlaces(similarVessels.map(function(v) { return v.aer_top; }).filter(aer => !!aer).mean(), 1);
            }
            
            currentVessel.aer_quartiles = {
                upper: aerUpperQuartileValue,
                lower: aerLowerQuartileValue,
            }

        }

        $scope.EEOIQuartilesSet = true;
    };


}]);
