import './activityReportCapital.controller';
import './activityReport.controller';
import './activityReportEagle.controller';
import './activityReportINSW.controller';
import './activityReportOSM.controller';
import './activityReportTres.controller';
import './dashboard.controller';
import './dataReports.controller';
import './fleet.controller';
import './fleetPerformance.controller';
import './fuelTables.controller';
import './imoDcsReporting.controller';
import './main.controller';
import './mrvReporting.controller';
import './reports.controller';
import './tableau.controller';
import './takeaways.controller';
import './voyageAnalysisDetails.controller';
import './voyageAnalysisReport.controller';
import './voyage.controller';
import './voyagePlan.controller';
