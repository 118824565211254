import { routerApp } from '../app.module';

routerApp.factory('CalculationService', function() {

    var getMileageContribution = function(mileageDeviation) {
        if (mileageDeviation <= -0.5) {
            return 0;
        } else if (mileageDeviation < -0.2) {
            return 60 * mileageDeviation + 30;
        } else if (mileageDeviation < 0) {
            return 10 * mileageDeviation + 20;
        } else {
            return 20;
        }
    };
    var getSpeedPerformanceContribution = function(speedPerformanceDeviation) {
        if (speedPerformanceDeviation > 0.5) {
            return 0;
        } else {
            var value = -40 * speedPerformanceDeviation + 20;
            value = Math.min(value, 20);
            value = Math.max(value, 0);
            return value;
        }
    };
    var getExcessConsumptionContribution = function(excessConsumptionDeviation) {
        var value = (-2/3) * excessConsumptionDeviation + 10;
        value = Math.min(value, 10);
        value = Math.max(value, 0);
        return value;
    };
    var getSFOCContribution = function(sfocDeviation) {
        if (sfocDeviation < 0) {
            return 20;
        } else if (sfocDeviation < 0.05) {
            return -100 * sfocDeviation + 20;
        } else if (sfocDeviation < 0.15) {
            return -150 * sfocDeviation + 22.5;
        } else {
            return 0;
        }
    };
    var getSlipContribution = function(slipDeviation) {
        if (slipDeviation < 0) {
            return 5;
        } else if (slipDeviation < 0.5) {
            return -10 * slipDeviation + 5;
        } else {
            return 0;
        }
    };
    var getTCRPMContribution = function(tcrpmDeviation) {
        if (tcrpmDeviation < -0.25) {
            return 0;
        } else if (tcrpmDeviation < -0.05) {
            return 25 * tcrpmDeviation + 6.25;
        } else if (tcrpmDeviation < 0.05) {
            return 5;
        } else if (tcrpmDeviation < 0.25) {
            return -25 * tcrpmDeviation + 6.25;
        } else {
            return 0;
        }
    };
    var getScavengeAirPressureContribution = function(scavengeAirPressureDeviation) {
        if (scavengeAirPressureDeviation < -0.25) {
            return 0;
        } else if (scavengeAirPressureDeviation < -0.05) {
            return 25 * scavengeAirPressureDeviation + 6.25;
        } else if (scavengeAirPressureDeviation < 0.05) {
            return 5;
        } else if (scavengeAirPressureDeviation < 0.25) {
            return -25 * scavengeAirPressureDeviation + 6.25;
        } else {
            return 0;
        }
    };
    var getTurbineInletPressureContribution = function(turbineInletPressureDeviation) {
        if (turbineInletPressureDeviation < -0.25) {
            return 0;
        } else if (turbineInletPressureDeviation < -0.05) {
            return 25 * turbineInletPressureDeviation + 6.25;
        } else if (turbineInletPressureDeviation < 0.05) {
            return 5;
        } else if (turbineInletPressureDeviation < 0.25) {
            return -25 * turbineInletPressureDeviation + 6.25;
        } else {
            return 0;
        }
    };
    var getExhaustTemperatureContribution = function(exhaustTemperatureDeviation) {
        if (exhaustTemperatureDeviation < -0.25) {
            return 0;
        } else if (exhaustTemperatureDeviation < -0.05) {
            return 25 * exhaustTemperatureDeviation + 6.25;
        } else if (exhaustTemperatureDeviation < 0.05) {
            return 5;
        } else if (exhaustTemperatureDeviation < 0.25) {
            return -25 * exhaustTemperatureDeviation + 6.25;
        } else {
            return 0;
        }
    };
    var getTurbineInletTemperatureContribution = function(turbineInletTemperatureDeviation) {
        if (turbineInletTemperatureDeviation < -0.25) {
            return 0;
        } else if (turbineInletTemperatureDeviation < -0.05) {
            return 25 * turbineInletTemperatureDeviation + 6.25;
        } else if (turbineInletTemperatureDeviation < 0.05) {
            return 5;
        } else if (turbineInletTemperatureDeviation < 0.25) {
            return -25 * turbineInletTemperatureDeviation + 6.25;
        } else {
            return 0;
        }
    };

    return {
        getMileage: function(observedDistance, fuel) {
            if (fuel != 0) {
                return observedDistance / fuel;
            } else {
                return 0;
            }
        },
        getCO2Emissions: function(hshfo, lshfo, hsmdo, lsmdo, hsmgo, lsmgo) {
            // those are hardcoded emission rates that apply to each MT of specified
            // the result is expressed in MT
            return 3.114 * (hshfo + lshfo) + 3.206 * (hsmdo + lsmdo + hsmgo + lsmgo);
        },
        getSpeedPerformance: function(reportedPower, modelPower) {
            if (reportedPower != 0) {
                return modelPower * 100 / reportedPower;
            } else {
                return 0;
            }
        },
        getSlip: function(observedDistance, engineDistance) {
            if (engineDistance != 0) {
                return (engineDistance - observedDistance)  * 100 / engineDistance;
            } else {
                return 0;
            }
        },
        getExcessConsumption: function(reportedConsumption, idealConsumption) {
            return reportedConsumption - idealConsumption;
        },
        calculateDeviation: function(reported, model) {
            if (model != 0) {
                return (reported - model) / model;
            } else {
                return 0;
            }
        },

        // KPI contributions
        getMileageContribution: getMileageContribution,
        getSpeedPerformanceContribution: getSpeedPerformanceContribution,
        getExcessConsumptionContribution: getExcessConsumptionContribution,
        getSFOCContribution: getSFOCContribution,
        getSlipContribution: getSlipContribution,
        getTCRPMContribution: getTCRPMContribution,
        getScavengeAirPressureContribution: getScavengeAirPressureContribution,
        getTurbineInletPressureContribution: getTurbineInletPressureContribution,
        getExhaustTemperatureContribution: getExhaustTemperatureContribution,
        getTurbineInletTemperatureContribution: getTurbineInletTemperatureContribution,

        // Global KPI
        getKPI: function(mileageDeviation, speedPerformanceDeviation, excessConsumptionDeviation, sfocDeviation,
            slipDeviation, tcrpmDeviation, scavengeAirPressureDeviation, turbineInletPressureDeviation,
            exhaustTemperatureDeviation, turbineInletTemperatureDeviation) {

            var mileageContribution = getMileageContribution(mileageDeviation);
            var speedContribution = getSpeedPerformanceContribution(speedPerformanceDeviation);
            var excessConsumptionContribution = getExcessConsumptionContribution(excessConsumptionDeviation);
            var sfocContribution = getSFOCContribution(sfocDeviation);
            var slipContribution = getSlipContribution(slipDeviation);
            var tcrpmContribution = getTCRPMContribution(tcrpmDeviation);
            var scavengeAirPressureContribution = getScavengeAirPressureContribution(scavengeAirPressureDeviation);
            var turbineInletPressureContribution = getTurbineInletPressureContribution(turbineInletPressureDeviation);
            var exhaustTemperatureContribution = getExhaustTemperatureContribution(exhaustTemperatureDeviation);
            var turbineInletTemperatureContribution = getTurbineInletTemperatureContribution(turbineInletTemperatureDeviation);

            return mileageContribution + speedContribution + excessConsumptionContribution + sfocContribution
                + slipContribution + tcrpmContribution + scavengeAirPressureContribution + turbineInletPressureContribution
                + exhaustTemperatureContribution + turbineInletTemperatureContribution;
        }

    }
});
