import moment from 'moment';
import { routerApp } from '../app.module';
import { mainFlowMeterHasFuelAndGas, boilerFlowMeterHasFuelAndGas, autoPopulateMainFlowMeterTags, autoPopulateBoilerFlowMeterTags } from '../services/utilities';
import { numberOfMainEngineOptions, numberOfAuxEngineOptions, numberOfDieselGenOptions, numberOfAuxBoilerOptions, propellerTypes, nCylinders, nTurbochargers} from '../models/vessel'

routerApp.controller('vesselSpecificationsController', ['VesselSpecificationService', '$rootScope', '$scope', 'notify', function(VesselSpecificationService, $rootScope, $scope, notify) {

    $scope.vessel_specifications = VesselSpecificationService.getSpecifications();
    $scope.report_logs = VesselSpecificationService.getReportLogs();

    $rootScope.selectedLevels = ['vessel-specifications'];

    $scope.specificationTab = 'cpBaselines';

    $scope.$on('vessel-specifications-received', function() {
        $scope.vessel_specifications = VesselSpecificationService.getSpecifications();
    });

    $scope.$on('class-model-reports-received', function() {
        $scope.report_logs = VesselSpecificationService.getReportLogs();
    });

    $scope.numberOfMainEngineOptions = numberOfMainEngineOptions;
    $scope.numberOfAuxEngineOptions = numberOfAuxEngineOptions;
    $scope.numberOfDieselGenOptions = numberOfDieselGenOptions;
    $scope.numberOfAuxBoilerOptions = numberOfAuxBoilerOptions;
    $scope.propellerTypes = propellerTypes;
    $scope.nCylinders = nCylinders;
    $scope.nTurbochargers = nTurbochargers;
    
    $scope.emissionFuelGrades = [
        { value: 'hfo', label: 'HFO' },
        { value: 'lfo', label: 'LFO' },
        { value: 'mgo', label: 'MGO' },
        { value: 'mdo', label: 'MDO' },
        { value: 'b10lfo', label: 'B10LFO' },
        { value: 'b10mgo', label: 'B10MGO' },
        { value: 'biolfo', label: 'BioLFO' },
        { value: 'biomgo', label: 'BioMGO' },
        { value: 'ulsfo2020', label: 'ULSFO2020' },
        { value: 'ulslfo2020', label: 'ULSLFO2020' },
        { value: 'ulsmdo2020', label: 'ULSMDO2020' },
        { value: 'ulsmgo2020', label: 'ULSMGO2020' },
        { value: 'vlsfo2020', label: 'VLSFO2020' },
        { value: 'vlslfo2020', label: 'VLSLFO2020' },
        { value: 'lpgp', label: 'LPGP' },
        { value: 'lpgb', label: 'LPGB' },
        { value: 'lng', label: 'LNG' },
        { value: 'methanol', label: 'Methanol' },
        { value: 'ethanol', label: 'Ethanol' },
        { value: 'other', label: 'Other' },
    ];
    
    $scope.emissionNames = [
        { value: 'co2', label: 'CO₂ (MT)' },
        { value: 'hc', label: 'HC (MT)' },
        { value: 'pm', label: 'PM (MT)' },
        { value: 'sox', label: 'SOx (MT)' },
        { value: 'nox', label: 'NOx (MT)' },
        { value: 'ch4', label: 'CH4 (MT)' },
        { value: 'n2o', label: 'N2O (MT)' },
    ];

    $scope.iceClassNotations = [
        {value: null, label: '-'},
        {value: '1A_SUPER', label: '1A Super'},
        {value: '1A', label: '1A'},
        {value: '1B', label: '1B'},
        {value: '1C', label: '1C'},
        {value: 'PC_1', label: 'PC(1)'},
        {value: 'PC_2', label: 'PC(2)'},
        {value: 'PC_3', label: 'PC(3)'},
        {value: 'PC_4', label: 'PC(4)'},
        {value: 'PC_5', label: 'PC(5)'},
        {value: 'PC_6', label: 'PC(6)'},
        {value: 'PC_7', label: 'PC(7)'},
    ];

    $scope.iceClassNotations = [
        {value: null, label: '-'},
        {value: '1A_SUPER', label: '1A Super'},
        {value: '1A', label: '1A'},
        {value: '1B', label: '1B'},
        {value: '1C', label: '1C'},
        {value: 'PC_1', label: 'PC(1)'},
        {value: 'PC_2', label: 'PC(2)'},
        {value: 'PC_3', label: 'PC(3)'},
        {value: 'PC_4', label: 'PC(4)'},
        {value: 'PC_5', label: 'PC(5)'},
        {value: 'PC_6', label: 'PC(6)'},
        {value: 'PC_7', label: 'PC(7)'},
    ];

    $scope.$watch('vessel_specifications', function(newValue, oldValue, scope) {
        if (!scope.vessel_specifications.engine) return;
        scope.vessel_specifications.engine.me_indices = [];
        for (var i = 0; i < scope.vessel_specifications.engine.number_of_main_engines; i++) {
            $scope.vessel_specifications.engine.me_indices.push(i);
        }
        scope.vessel_specifications.engine.ae_indices = [];
        for (var i = 0; i < scope.vessel_specifications.engine.number_of_aux_engines; i++) {
            $scope.vessel_specifications.engine.ae_indices.push(i);
        }
        scope.vessel_specifications.engine.boiler_indices = [];
        for (var i = 0; i < scope.vessel_specifications.engine.number_of_aux_boilers; i++) {
            $scope.vessel_specifications.engine.boiler_indices.push(i);
        }
        if (!scope.vessel_specifications.prime_mover) return;
        scope.vessel_specifications.prime_mover.pm_indices = [];
        for (var i = 0; i < scope.vessel_specifications.prime_mover.number_of_prime_movers; i++) {
            $scope.vessel_specifications.prime_mover.pm_indices.push(i);
        }
        scope.vessel_specifications.prime_mover.dg_indices = [];
        for (var i = 0; i < scope.vessel_specifications.prime_mover.number_of_diesel_generators; i++) {
            $scope.vessel_specifications.prime_mover.dg_indices.push(i)
        }
    });

    $scope.$watch('vessel_specifications.prime_mover.number_of_prime_movers', function(newValue, oldValue, scope) {
        if (!scope.vessel_specifications.prime_mover) return;
        scope.vessel_specifications.prime_mover.pm_indices = [];
        for (var i = 0; i < scope.vessel_specifications.prime_mover.number_of_prime_movers; i++) {
            $scope.vessel_specifications.prime_mover.pm_indices.push(i);
        }
    });

    $scope.$watch('vessel_specifications.prime_mover.number_of_diesel_generators', function(newValue, oldValue, scope) {
        if (!scope.vessel_specifications.prime_mover) return;
        scope.vessel_specifications.prime_mover.dg_indices = [];
        for (var i = 0; i < scope.vessel_specifications.prime_mover.number_of_diesel_generators; i++) {
            $scope.vessel_specifications.prime_mover.dg_indices.push(i);
        }
    });

    $scope.$watch('selectedMainFlowMeterInstallationClass', function(newValue, oldValue, scope) {
        $scope.mainFlowMeterHasFuelAndGas = mainFlowMeterHasFuelAndGas(newValue);
        $scope.autoPopulateMainFlowMeterTags = autoPopulateMainFlowMeterTags(newValue);
    });

    $scope.$watch('selectedBoilerFlowMeterInstallationClass', function(newValue, oldValue, scope) {
        $scope.boilerFlowMeterHasFuelAndGas = boilerFlowMeterHasFuelAndGas(newValue);
        $scope.autoPopulateBoilerFlowMeterTags = autoPopulateBoilerFlowMeterTags(newValue);
    });

    var showSuccessMessage = function() {
        notify({message: "Vessel configuration saved successfully", duration: 2000, classes: ['ok-notification']});
    }
    var showErrorMessage = function() {
        notify({message: "Error updating vessel information, please try again later", duration: 2000, classes: ['bad-notification']});
    }

    $scope.submitVesselInformation = function() {
        $scope.vessel_specifications.information.veslink = $scope.vessel_specifications.veslink;
        VesselSpecificationService.updateVesselInformation($scope.selectedVessel.id, $scope.vessel_specifications.information).then(function() {
            // set rootscope variables
            $rootScope.selectedVessel.name = $scope.vessel_specifications.information.name;
            showSuccessMessage();
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
        }, function() {
            showErrorMessage();
        });
    }

    $scope.submitEngineConfiguration = function() {
        VesselSpecificationService.updateEngineConfiguration($scope.selectedVessel.id, $scope.vessel_specifications.engine, $scope.vessel_specifications.prime_mover).then(function() {
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
            showSuccessMessage();
        }, function() {
            showErrorMessage();
        });
    }

    $scope.submitFMConfigurationDeprecated = function() {
        VesselSpecificationService.updateFMConfiguration($scope.selectedVessel.id, $scope.vessel_specifications.fm_configuration).then(function() {
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
            showSuccessMessage();
        }, function() {
            showErrorMessage();
        });
    }

    $scope.submitFMConfiguration = function() {
        console.log('submitting new flow meter configuration')
    }

    $scope.addClassModelReport = function() {
        var files = [];
        var gas_model = false;
        $('#uploadMEPerformanceModels').children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                files.push(this.files[0]);
            }
        });
        $('#uploadMEPerformanceModelsFuelOil').children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                files.push(this.files[0]);
            }
        });
        $('#uploadMEPerformanceModelsGas').children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                gas_model = true;
                files.push(this.files[0]);
            }
        });

        if (files.length>0) {
            for (var i=0;i<files.length;i++) {
                if ($scope.reportCheckBox.checked==true) {
                    notify({message: 'Please wait, reports are being processed', duration: 5000, classes: ['warning-notification']});
    
                    VesselSpecificationService.addClassModelReport($scope.reportCheckBox.checked, gas_model, $scope.report_logs.recalculate_reports.date, files[i]).then(function(res) {
                        var response = res.data;
                        var newReport = response;
    
                        var formattedRecalculatedSince = moment(newReport.recalculated_since["$date"]).utc().format('DD/MM/YYYY HH:mm');
                        newReport.recalculated_since = formattedRecalculatedSince;
                        $scope.recalculated_since = formattedRecalculatedSince
    
                        var formattedDate = moment(newReport.upload_date["$date"]).utc().format('DD/MM/YYYY HH:mm');
                        newReport.upload_date = formattedDate;
    
                        $scope.classModels = newReport.classes
                        $scope.classCount = newReport.classes_count
                        $scope.upload_date = newReport.upload_date
    
                        VesselSpecificationService.setReportLogs($scope.report_logs);
    
                        $scope.refreshClassModelReports();
                        showSuccessMessage();
                        $("#engineModelsUpdated").modal('show');
                    }, function() {
                        showErrorMessage();
                    });
                } else {
                    notify({message: 'Please wait, models are being uploaded', duration: 5000, classes: ['warning-notification']});
                    VesselSpecificationService.addClassModel($scope.reportCheckBox.checked, gas_model, files[i]).then(function(res) {
                        var response = res.data;
                        var newReport = response;
    
                        var formattedDate = moment(newReport.upload_date["$date"]).utc().format('DD/MM/YYYY HH:mm');
                        newReport.upload_date = formattedDate;
    
                        $scope.classModels = newReport.classes
                        $scope.classCount = newReport.classes_count
                        $scope.upload_date = newReport.upload_date
    
                        VesselSpecificationService.setReportLogs($scope.report_logs);
    
                        $scope.refreshClassModelReports();
                        showSuccessMessage();
                        $("#engineModelsUpdated").modal('show');
                    }, function() {
                        showErrorMessage();
                    });
    
                }
            }
            
        }
    }
    var list_of_all_vessels = new Array;
    for (var i=0; i<$rootScope.vessels.length; i++) {
        list_of_all_vessels.push($rootScope.vessels[i].id)
    }
    $scope.list_of_vessels = list_of_all_vessels
    $scope.switchClient = function(vessel) {
        $scope.selectedClient = vessel.client_name;
        var list_of_vessels = new Array;
        for (var i=0; i<$rootScope.vessels.length; i++) {
            if ($rootScope.vessels[i].client_name==$scope.selectedClient) {
                list_of_vessels.push($rootScope.vessels[i].id)
            }
        }
        $scope.list_of_vessels = list_of_vessels
    }

    /**
     * Flow meter logic
     */

    // load flow meter configurtion templates
    VesselSpecificationService.getFlowMeterTemplates().then(function(response) {
        let data = response.data.data;
        $scope.mainFlowMeterTemplates = data.main_fm_templates;
        $scope.boilerFlowMeterTemplates = data.boiler_fm_templates;
        $scope.mainFlowMeterTemplatesMap = {};
        $scope.mainFlowMeterTemplates.forEach(element => {
            $scope.mainFlowMeterTemplatesMap[element._cls] = element;
        });
        $scope.boilerFlowMeterTemplatesMap = {};
        $scope.boilerFlowMeterTemplates.forEach(element => {
            $scope.boilerFlowMeterTemplatesMap[element._cls] = element;
        });

        let vesselSpecs = VesselSpecificationService.getSpecifications();
        if (vesselSpecs.main_flow_meter_installation && vesselSpecs.boiler_flow_meter_installation) {
            // if vessel doesn't have a flow meter configuration set, set it to the first template
            console.log('updating to vessels flow meter configuration');
            $scope.selectedMainFlowMeterInstallation = vesselSpecs.main_flow_meter_installation;
            $scope.selectedBoilerFlowMeterInstallation = vesselSpecs.boiler_flow_meter_installation;
            let selectedMainFMClass = $scope.selectedMainFlowMeterInstallation._cls;
            let selectedBoilerFMClass = $scope.selectedBoilerFlowMeterInstallation._cls;

            // update description and type attributes from the templates
            // since these are not saved on the objects
            // todo: should we save these?
            let mainFlowMeterInstallationTemplate = $scope.mainFlowMeterTemplatesMap[selectedMainFMClass];
            $scope.selectedMainFlowMeterInstallation.description = mainFlowMeterInstallationTemplate.description;
            $scope.selectedMainFlowMeterInstallation.type = mainFlowMeterInstallationTemplate.type;
            let boilerFlowMeterInstallationTemplate = $scope.boilerFlowMeterTemplatesMap[selectedBoilerFMClass];
            $scope.selectedBoilerFlowMeterInstallation.description = boilerFlowMeterInstallationTemplate.description;
            $scope.selectedBoilerFlowMeterInstallation.type = boilerFlowMeterInstallationTemplate.type;
        } else {
            // initialize the flow meter configuration to the first template we find
            console.log('defaultint to the first flow meter configuration');
            $scope.selectedMainFlowMeterInstallation = $scope.mainFlowMeterTemplates[0];
            $scope.selectedBoilerFlowMeterInstallation = $scope.boilerFlowMeterTemplates[0];
        }
        $scope.selectedMainFlowMeterInstallationClass = $scope.selectedMainFlowMeterInstallation._cls;
        $scope.selectedBoilerFlowMeterInstallationClass = $scope.selectedBoilerFlowMeterInstallation._cls;
    });
    /**
     * Updates selected main flow meter object from template based on modified flow meter class
     * @param selectedClass: class of the flow meter
     * @returns null
     */
    $scope.updateSelectedMainFlowMeterInstallation = function(selectedClass) {
        if (!selectedClass) {
            return;
        }
        let mainFlowMeterInstallationTemplate = $scope.mainFlowMeterTemplatesMap[selectedClass];
        $scope.selectedMainFlowMeterInstallation = mainFlowMeterInstallationTemplate;
    }
    $scope.updateSelectedBoilerFlowMeterInstallation = function(selectedClass) {
        if (!selectedClass) {
            return;
        }
        let boilerFlowMeterInstallationTemplate = $scope.boilerFlowMeterTemplatesMap[selectedClass];
        $scope.selectedBoilerFlowMeterInstallation = boilerFlowMeterInstallationTemplate;
    }

    /**
     * Updates both main and boiler flow meter installations.
     */
    $scope.updateFlowMeterInstallation = function() {
        VesselSpecificationService.updateFlowMeterInstallation($scope.selectedVessel.id, $scope.selectedMainFlowMeterInstallation, $scope.selectedBoilerFlowMeterInstallation).then(function() {
            notify({message: 'Successfully edited flow meter installation for vessel.', duration: 5000, classes: ['ok-notification']})
        });
    }
    if ($scope.recalculate_report_checkbox == undefined) {
        $scope.recalculate_report_checkbox = {};
    }
    $scope.btnReset = function(vessel) {
        $scope.selectedClient = null;
        $scope.list_of_vessels = list_of_all_vessels
    }

    $scope.checkedReportsCount = 0;
    $scope.reportRecalcDisabled=true;
    $scope.submitRecalcRequestDisabled = function(event, checkboxChecked) {
        if (event.target.checked || checkboxChecked){
            $scope.checkedReportsCount+=1;
        }
        else {
            $scope.checkedReportsCount-=1;
        }
        if ($scope.checkedReportsCount>0){
            $scope.reportRecalcDisabled=false;
            
        }
        else {
            $scope.reportRecalcDisabled=true;
        }
    }

    $scope.selectRow = function(event,vessel_id) {
        var checkBox = event?.target?.parentElement?.firstElementChild?.firstElementChild;
        if (checkBox != null) checkBox.checked = !checkBox.checked;
        $scope.submitRecalcRequestDisabled(event,checkBox.checked)
        if (checkBox.checked) $scope.recalculate_report_checkbox[vessel_id] = true;
        else $scope.recalculate_report_checkbox[vessel_id] = false
    }

    $scope.selectAllReportRecalc = false;
    $scope.recalculateAllVesselReports = function(list_of_vessels) {
        $scope.selectAllReportRecalc = !$scope.selectAllReportRecalc;
        if ($scope.selectAllReportRecalc) {
            $scope.selectAllVesselReports(list_of_vessels);
        } else {
            $scope.deselectAllVesselReports(list_of_vessels);
        }
    }
    $scope.selectAllVesselReports = function(list_of_vessels) {
        $scope.checkedReportsCount = 0;
        for (var i = 0; i < list_of_vessels.length; i++) {

            $scope.recalculate_report_checkbox[list_of_vessels[i]] = true;
            $scope.checkedReportsCount+=1;
        }
        if ($scope.checkedReportsCount>0){
            $scope.reportRecalcDisabled=false;
            
        }
        else {
            $scope.reportRecalcDisabled=true;
        }
    }
    
    $scope.deselectAllVesselReports = function(list_of_vessels) {
        for (var i = 0; i < list_of_vessels.length; i++) {

            $scope.recalculate_report_checkbox[list_of_vessels[i]] = false;
            
        }
        $scope.checkedReportsCount=0;
        if ($scope.checkedReportsCount>0){
            $scope.reportRecalcDisabled=false;
            
        }
        else {
            $scope.reportRecalcDisabled=true;
        }
    }

    $scope.addReportRecalculations = function() {
        VesselSpecificationService.addReportRecalculations($scope.recalculate_reports.from_date, $scope.recalculate_reports.to_date, $scope.recalculate_report_checkbox).then(function() {
            notify({message: 'Report Recalculation Request Successfully Submitted. Reports are now pending recalculation.', duration: 5000, classes: ['ok-notification']})
        });
    }
    

    $scope.submitVesselModels = function() {
        VesselSpecificationService.updateVesselModels($scope.selectedVessel.id, $scope.vessel_specifications.models).then(function() {
            showSuccessMessage();
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
        }, function() {
            showErrorMessage();
        });
    }

    $scope.submitTankCapacity = function() {
        VesselSpecificationService.updateTankCapacity($scope.selectedVessel.id, $scope.vessel_specifications.tanks).then(function() {
            showSuccessMessage();
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
        }, function() {
            showErrorMessage();
        });
    }

    $scope.addDryDocking = function() {
        notify({message: 'Please wait, your dry docking is being processed', duration: 5000, classes: ['warning-notification']});

        var files = [];
        $('#dryDockingReportUpload').children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                files.push(this.files[0]);
            }
        });

        VesselSpecificationService.addDryDocking($scope.selectedVessel.id, $scope.vessel_specifications.dry_docking, files).then(function(response) {
            var res = response.data;
            // Parse date of response
            var newDryDocking = res;
            var formattedDate = moment(newDryDocking.date["$date"]).utc().format('DD/MM/YYYY HH:mm');
            newDryDocking.date = formattedDate;

            // Clear the form of the submitted dry docking on success
            $scope.vessel_specifications.dry_docking = {};
            $scope.areAllDryDockCheckboxesSelected = false;
            $('#dryDockingReportUpload').html('<input type="file" id="uploadDryDockingReport" style="padding-top: 5px" onchange="angular.element(this).scope().addDryDockingReport(this)" />');
            // Add submitted dry docking to list
            $scope.vessel_specifications.dry_dockings.unshift(newDryDocking);

            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
            showSuccessMessage();
        }, function() {
            showErrorMessage();
        });
    };

    $scope.addDryDockingReport = function(element) {
        if (element.files.length > 0) {
            $(element).after('<input type="file" id="uploadDryDockingReport" style="padding-top: 5px" onchange="angular.element(this).scope().addDryDockingReport(this)" />');
        } else {
            $(element).remove();
        }
    };

    $scope.updateDryDockingReport = function(element) {
        var file = element.files[0];
        var dryDockingId = element.getAttribute('data-dry-docking-id');
        if (!(file && dryDockingId)) return;
        notify({message: 'Please wait, your dry docking report is being processed', duration: 5000, classes: ['warning-notification']});
        VesselSpecificationService.uploadNewDryDockingReport($scope.selectedVessel.id, dryDockingId, file).then(function() {
            $scope.refreshVesselSpecifications();
            showSuccessMessage();
        }, function() {
            showErrorMessage();
        });
    };

    $scope.updateAllDryDockings = function() {
        VesselSpecificationService.updateDryDockings($scope.selectedVessel.id, $scope.vessel_specifications.dry_dockings).then(function() {
            $scope.refreshVesselSpecifications();
            showSuccessMessage();
        }, function() {
            showErrorMessage();
        });
    };

    $scope.validateEvents = function() {
        // Return true if and only if all dry dockings are valid.

        if (!$scope.vessel_specifications.dry_dockings) return false;

        for(var i = 0; i < $scope.vessel_specifications.dry_dockings.length; i++) {
            var dd = $scope.vessel_specifications.dry_dockings[i];
            if (!dd.date) {
                return false;
            }
        }

        return true;
    };

    $scope.confirmDryDockingDeletion = function(dryDocking) {
        console.log('deleting dry dock');
        $scope.dryDockingToDelete = dryDocking;
        $("#deleteDryDockingWarning").modal('show');
    }

    $scope.deleteDryDocking = function(dryDockingId) {
        VesselSpecificationService.deleteDryDocking($scope.selectedVessel.id, dryDockingId).then(function() {
            notify({message: 'Dry docking successfully deleted', duration: 2000, classes: ['ok-notification']});
            $scope.vessel_specifications.dry_dockings = $scope.vessel_specifications.dry_dockings.filter(function(dd) { return dd._id['$oid'] != dryDockingId });
        }, function() {
            notify({message: 'Error updating vessel information, please try again later', duration: 2000, classes: ['bad-notification']});
        });
    };

    $scope.toggleAllDryDockCheckboxes = function($event) {
        var checked = $event.currentTarget.checked;

        if (checked) {
            $scope.vessel_specifications.dry_docking.hull_cleaning = checked;
            $scope.vessel_specifications.dry_docking.propeller_polishing = checked;
            $scope.vessel_specifications.dry_docking.me_over_haul = checked;
        }
    };

    $scope.updateEmissionRates = function() {
        VesselSpecificationService.updateEmissionRates($scope.selectedVessel.id, $scope.vessel_specifications.emissions).then(function() {
            showSuccessMessage();
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
        }, function() {
            showErrorMessage();
        });
    }

    $scope.updateNoxEmissionRates = function() {
        VesselSpecificationService.updateNoxEmissionRates($scope.selectedVessel.id, $scope.vessel_specifications.nox).then(function() {
            showSuccessMessage();
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
        }, function() {
            showErrorMessage();
        });
    }

    $scope.updatePropellerConfiguration = function() {
        VesselSpecificationService.updatePropellerConfiguration($scope.selectedVessel.id, $scope.vessel_specifications.propeller).then(function() {
            $rootScope.selectedVessel.hasControllablePropeller = $scope.vessel_specifications.propeller.propeller_type == 'controllable';
            showSuccessMessage();
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);

        }, function() {
            showErrorMessage();
        });
    }

    $scope.updateEmailsAlert = function() {
        var emailAddresses = $scope.vessel_specifications.email_alert.email_addresses.split(";");
        var addressesList = [];
        for (var i = 0; i < emailAddresses.length; i++) {
            var address = emailAddresses[i].trim();
            if (address && address != "") {
                addressesList.push(address);
            }
        }

        if (addressesList.length > 0) {
            $scope.vessel_specifications.email_alert.emails = emailAddresses;
            VesselSpecificationService.updateEmailAlert($scope.selectedVessel.id, $scope.vessel_specifications.email_alert).then(function() {
                showSuccessMessage();
                VesselSpecificationService.setSpecifications($scope.vessel_specifications);
            }, function() {
                showErrorMessage();
            });
        } else {
            showErrorMessage();
        }

    }

    $scope.updateCpBaselineDetails = function() {
        var files = [];
        $('.cpBaselinesFile').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                files.push(this.files[0]);
            } else {
                files.push(new File([], "NOFILE"));
            }
        });

        VesselSpecificationService.updateCpBaselineDetails($scope.selectedVessel.id, $scope.vessel_specifications.cp_baseline_details, files).then(function(res) {
            var response = res.data;
            showSuccessMessage();
            $scope.vessel_specifications.cp_baseline_details = response.data.cp_baseline_details;
            VesselSpecificationService.setSpecifications($scope.vessel_specifications);
        }, function() {
            showErrorMessage();
        });
    };

    $scope.$watchGroup(['vessel_specifications.information.has_torsionmeter', 'vessel_specifications.information.has_torque_meter'], function(newValues, oldValues) {
        var oldHasKWHCounter = !!oldValues[0];
        var newHasKWHCounter = !!newValues[0];
        var oldHasTorqueMeter = !!oldValues[1];
        var newHasTorqueMeter = !!newValues[1];

        // When check box "Has kWh Counter" is selected this should default mark "Has Torque Meter" as well:
        // The Torque Meter is prerequisite for having kWh counter.
        if (!oldHasKWHCounter && newHasKWHCounter) {
            $scope.vessel_specifications.information.has_torque_meter = true;
        } else if (oldHasTorqueMeter && !newHasTorqueMeter) {
            // The converse of the above
            $scope.vessel_specifications.information.has_torsionmeter = false;
        }
    });

    $scope.$watch('vessel_specifications.engine.number_of_main_engines', function(newValues, oldvalues, scope) {
        var numberOfmainEngines = newValues;
        if (numberOfmainEngines && numberOfmainEngines > 1) {
            scope.vessel_specifications.propeller.propeller_type = 'controllable';
        }
    })

    $scope.cpBaselineDetailLength = function() {
        return $scope.vessel_specifications && $scope.vessel_specifications.cp_baseline_details
            ? $scope.vessel_specifications.cp_baseline_details.length
            : 1;
    };
    $scope.getActiveBaselineDetail = function() {
        if (!$scope.vessel_specifications) return;
        var now = new Date();
        for (var i = 0; i < $scope.vessel_specifications.cp_baseline_details.length; i++) {
            var detail = $scope.vessel_specifications.cp_baseline_details[i];
            if (detail.from_date <= now && now < detail.to_date) {
                return detail;
            }
        }
        return null;
    };
    $scope.cpBaselineDetailIndex = $scope.getActiveBaselineDetail() || $scope.cpBaselineDetailLength();
    $scope.nextCpBaselineDetail = function() {
        $scope.cpBaselineDetailIndex = Math.min(
            $scope.cpBaselineDetailIndex + 1,
            $scope.cpBaselineDetailLength()
        );
    };
    $scope.prevCpBaselineDetail = function() {
        $scope.cpBaselineDetailIndex = Math.max($scope.cpBaselineDetailIndex - 1, 1);
    };
    $scope.getCpBaselineDetail = function(index) {
        return $scope.vessel_specifications && $scope.vessel_specifications.cp_baseline_details[index - 1];
    };
    $scope.addNewCpBaselineDetail = function() {
        $scope.vessel_specifications.cp_baseline_details.push({});
        $scope.cpBaselineDetailIndex = $scope.cpBaselineDetailLength();
    };
    $scope.removeCpBaselineDetail = function() {
        $scope.vessel_specifications.cp_baseline_details.splice($scope.cpBaselineDetailIndex - 1, 1);
        $scope.nextCpBaselineDetail();
    };
    $scope.setCpBaselineDetailIndex = function(i) {
        $scope.cpBaselineDetailIndex = i;
    };
    
    $scope.correctVesselReportNumbers = function() {
        VesselSpecificationService.reorderReports($scope.selectedVessel, $scope.reportNumberCorrection.reportType)
        .then(function() {
            notify({message: 'Report numbers have been corrected.', duration: 5000, classes: ['ok-notification']})
        })
        .catch(error => {
            notify({message: 'Error updating report numbers, please try again later', duration: 5000, classes: ['bad-notification']})
        });
    };

    $scope.uploadHistoric = function() {
        var files = [];
        $('#historicDataFile').children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                files.push(this.files[0]);
            }
        });

        $scope.uploadingData = true;
        VesselSpecificationService.uploadHistoricReportData(files[0], $scope.user.email, $scope.upload_report_data.date_format).then(function(response) {
            $scope.uploadingData = false;
            notify({
                message: 'Successfully sent report data to be uploaded', 
                duration: 2000, 
                classes: ['ok-notification']
            });
        }, function(res) {
            $scope.uploadingData = false;
        });
    };

}]);
