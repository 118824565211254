export interface IReport {
    [key: string]: any, // Allow other properties
}

export class HoseOperation {
    timestamp: string;
    ifo: number;
    lsf: number;
    lsg: number;
    mgo: number;
}

export class HeatingOperation {
    comment: string;
    ifo: number;
    mgo: number;
}

export class Hoses {
    operation_type?: string;
    terminal?: string;
    description?: string;
    hose_on_operations: HoseOperation[] = [];
    hose_off_operations: HoseOperation[] = [];
    heating_operations: HeatingOperation[] = [];
}

export interface CargoTab {
    hoses: Hoses,
    [key: string]: any, // Allow other properties
}

export interface IPortReport extends IReport {
    cargo?: CargoTab,
}
