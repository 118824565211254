import moment from 'moment';
import angular from 'angular';
import { routerApp, reportRequestTimeout, enableHybrid, enableOnPremise } from '../app.module';
import hybridService from './../services/hybrid/index';
import testService from './../services/testing/index';

routerApp.controller('enginePerformanceController', ['$rootScope', '$scope', '$state', '$timeout', 'ReportService', 'ReportsApiService', 'notify', 'OfflineDatabase',
    function($rootScope, $scope, $state, $timeout, ReportService, ReportsApiService, notify, OfflineDatabase) {

    $scope.backToNewReport = function() {
        var report = ReportService.getEngineReport();
        var targetState = ReportService.getEngineReportView(report);

        $timeout(function() {
             $state.go(targetState, { engineReport: undefined, viewReport: false }, { reload: true, notify: true });
        });
    }

    $scope.cancelReport = function() {
        $('.modal-backdrop').remove();
        var cancelMessage = 'Deleted current report!';
        if ($scope.engine_report.id == undefined) {
            notify({message: cancelMessage, duration: 2000, classes: ['ok-notification']});
            if (enableHybrid || enableOnPremise) {
                OfflineDatabase.deleteEngineReportInProgress($scope.engine_report, $rootScope.selectedVessel.id).then(function(){
                    $scope.engine_report = ReportService.createNewEngineReport();
                    ReportService.setEngineReport($scope.engine_report);
                    var targetState = ReportService.getEngineReportView($scope.engine_report)
                    $state.go(targetState, {}, {reload: true, notify: true});
                    $rootScope.hasIncompleteMeEngineReport = false;
                    $rootScope.hasIncompleteAeEngineReport = false;
                })
            } else {
                $scope.engine_report = ReportService.createNewEngineReport();
                ReportService.setEngineReport($scope.engine_report);
                $state.go('site.meReport', {}, {reload: true, notify: true});
            }
            
        } else {
            ReportsApiService.deleteEngineReport($scope.selectedVessel.id, $scope.engine_report.id)
            .then(function(response) {
                if (enableHybrid || enableOnPremise) {
                    return OfflineDatabase.deleteEngineReportInProgress($scope.engine_report, $rootScope.selectedVessel.id)
                    .then(function(offlineResponse) {
                        return response;
                    }); 
                }
                return response;
            }).then(function(response) {
                notify({message: cancelMessage, duration: 2000, classes: ['ok-notification']});
                $scope.engine_report = ReportService.createNewEngineReport();
                ReportService.setEngineReport($scope.engine_report);
                var targetState = ReportService.getEngineReportView($scope.engine_report)
                $state.go(targetState, {}, {reload: true, notify: true});
                $rootScope.hasIncompleteMeEngineReport = false;
                $rootScope.hasIncompleteAeEngineReport = false;
            }).catch(function() {
                // Online but cannot connect server for some reason
                if(!enableHybrid || enableHybrid && OfflineDatabase.isOnline() || enableOnPremise && OfflineDatabase.isOnline()) {
                    notify({message: 'Error deleting report, please try again in a bit', duration: 2000, classes: ['bad-notification']});
                } else if (enableHybrid || enableOnPremise) {
                // No internet connection to remove engine report
                    OfflineDatabase.deleteEngineReportInProgress($scope.engine_report, $rootScope.selectedVessel.id).then(function(){
                        $scope.engine_report = ReportService.createNewEngineReport();
                        ReportService.setEngineReport($scope.engine_report);
                        var targetState = ReportService.getEngineReportView($scope.engine_report)
                        $state.go(targetState, {}, {reload: true, notify: true});
                    })
                }
            })
        }
    }

    $scope.updateRATab = function() {

        if ($scope.reportForm.$invalid) { return; }

        $rootScope.activeTab = 'results';
    };

    // update report period from report from and report to times
    $scope.$watchGroup(['engine_report.details.report_from', 'engine_report.details.report_to'], function(newValues, oldValues, scope) {
        if (!$scope.engine_report.details) return;

        if (newValues[0] != undefined && newValues[1] != undefined) {
            var fromMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
            var toMoment = moment(newValues[1], 'DD/MM/YYYY HH:mm');
            var duration = moment.duration(toMoment.diff(fromMoment));
            // @ts-ignore
            let durationHours = duration.asHours() + (toMoment.isDST() - fromMoment.isDST());
            $scope.engine_report.details.report_period = Math.max(Math.round(durationHours * 100) / 100, 0);
        } else {
            $scope.engine_report.details.report_period = 0;
        }

        if ($scope.reportForm.details.reportTo) {
            $scope.reportForm.details.reportTo.$setValidity('invalid', (fromMoment <= toMoment && $scope.engine_report.details.report_period <= 6 && $scope.engine_report.details.report_period >= 0) ? true : false);
        }
    });

    $scope.reportMoreThanOneWeekOld = function(engineReport) {
        var reportTo = moment(engineReport.details.report_to, 'DD/MM/YYYY HH:mm');
        var oneWeekAgo = moment().subtract(7, 'd');
        var reportCompleted = engineReport.status == 'completed';
        return engineReport.details.report_to && reportTo.isValid() && reportTo < oneWeekAgo && reportCompleted;
    };

    $scope.validAndUnableToFill = function() {
        return $scope.engine_report.unable_to_fill_report && !$scope.reportForm.details.reportFrom.$invalid && !$scope.reportForm.details.reportTo.$invalid;
    }
}]);

routerApp.controller('mePerformanceController', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'ReportService', 'CalculationService', 'VesselSpecificationService', 'ModelsApiService', 'EngineReportService', 'notify' , 'OfflineDatabase',
    function($rootScope, $scope, $state, $stateParams, $timeout, ReportService, CalculationService, VesselSpecificationService, ModelsApiService, EngineReportService, notify, OfflineDatabase) {

    $scope.warningMessages = {};

    $scope.selectedLevels = ['engine-reports', 'create-new-engine-report', 'me-report'];

    $scope.engines = [];

    $scope.setEngines = function() {
        if ($scope.isDieselElectricPropulsion()) {
            $scope.engines = $scope.vessel_specifications
                && $scope.vessel_specifications.prime_mover
                && $scope.vessel_specifications.prime_mover.propulsion_motors
                || [];
        } else {
            $scope.engines = $scope.vessel_specifications
                && $scope.vessel_specifications.engine
                && $scope.vessel_specifications.engine.me_engines
                || [];
        }
    };

    $scope.getEngine = function(engineIndex: number) {
        return $scope.engines[engineIndex];
    };

    $scope.refreshCylinderAndTurbochargerIndices = function(engineIndex) {
        $scope.setEngines();
        let meEngine = $scope.getEngine(engineIndex);
        if (meEngine) {
            if (meEngine.n_cylinders != undefined) {
                // cylinder indices
                $scope.cylinderIndices = [];
                for (var i = 0; i < meEngine.n_cylinders; i++) {
                    $scope.cylinderIndices.push(i);
                }
            }
            if (meEngine.n_turbochargers != undefined) {
                // cylinder indices
                $scope.turbochargerIndices = [];
                for (var i = 0; i < meEngine.n_turbochargers; i++) {
                    $scope.turbochargerIndices.push(i);
                }
            }
        } else {
            $scope.cylinderIndices = [0, 1, 2];
            $scope.turbochargerIndices = [0, 1, 2];
        }
    }

    $rootScope.mainEngines = [];
    $scope.refreshMainEngines = function() {
        let engines = $scope.engines;
        if (engines) {
            var mainEngines = [];
            for (var i = 0; i < engines.length; i++) {
                var indexSelection = i + 1;
                mainEngines.push({
                    index: i,
                    make: 'Main Engine ' + indexSelection + ': ' + ($scope.getEngine(i).make || '')
                });
            }
            $rootScope.mainEngines = mainEngines;
        }
    }

    $scope.propulsionMotors = [];
    $scope.refreshPropulsionMotors = function() {
        if ($scope.vessel_specifications != undefined) {
            var propulsionMotors = [];
            for (var i = 0; i < $scope.vessel_specifications.prime_mover.propulsion_motors.length; i++) {
                var indexSelection = i + 1;
                propulsionMotors.push({
                    index: i,
                    make: 'Propulsion Motor ' + indexSelection + ': ' + $scope.vessel_specifications.prime_mover.propulsion_motors[i].make
                });
            }
            $scope.propulsionMotors = propulsionMotors;
        }
    }

    // get specification that sets main engines dropdown and settings
    $scope.vessel_specifications = VesselSpecificationService.getSpecifications();
    $scope.refreshCylinderAndTurbochargerIndices(0);
    $scope.refreshMainEngines();
    $scope.refreshPropulsionMotors();

    $scope.$on('vessel-specifications-received', function() {
        $scope.vessel_specifications = VesselSpecificationService.getSpecifications();
        $scope.refreshCylinderAndTurbochargerIndices(0);
        $scope.refreshMainEngines();
        $scope.refreshPropulsionMotors();
    });


    $scope.$watch('engine_report.details.main_engine', function(newValue, oldValue, scope) {
        $scope.refreshCylinderAndTurbochargerIndices(0);
    });

    if ($stateParams.engineReport != undefined) {
        // if we came in here with a report parameter, we should use that report parameter
        // we should also use the 'viewReport' boolean to display or hide the needed report buttons
        $scope.engine_report = $stateParams.engineReport;
        if ($scope.engine_report["_id"]) $scope.engine_report.id = $scope.engine_report["_id"]["$oid"];
        $scope.viewReport = $stateParams.viewReport;
    } else {
        // this gets the newly edited report from the service, always gets it as
        // soon as this scope is initialized, so should assume that main controller
        // puts it there so that it's ready.
        $scope.engine_report = ReportService.getEngineReport();
        $scope.engine_report._cls = "EngineReport.MEReport";
        $scope.engine_report.report_number = ReportService.getMEReportNumber();
        // update service with report type
        ReportService.setEngineReport($scope.engine_report);
    }

    $scope.$on('engine-report-received', function() {
        if ($stateParams.viewReport == undefined || $stateParams.viewReport == false) {
            $scope.engine_report = ReportService.getEngineReport();
        }
    });

    var valuesDefined = function(newValues) {
        var allValuesDefined = true;
        angular.forEach(newValues, function(value) {
            if (value == undefined) {
                allValuesDefined = false;
            }
        });
        return allValuesDefined;
    };

    // initialize
    $scope.submittingReport = false;

    // submit report
    $scope.submitMeReport = function(closeReport) {

        // check if there any reports that are completed offline engine reports
        // that need to sync before sending any new reports to server
        // prevents server receiving reports out of order
        if ((enableHybrid || enableOnPremise) && !OfflineDatabase.canSyncNewEngineReport()) {
            notify({message: 'Saving engine report in local cache.', duration: 5000, classes: ['warning-notification']});
            $scope.saveEngineReportToLocalDb($scope.engine_report, closeReport)
            .then(()=> {
                $scope.refreshOfflineEngineReports().then(function(){
                    if (closeReport == true) {
                        $state.go('site.engineReports');
                    }
                });
            });
            return;
        }
        var options = enableHybrid || enableOnPremise ? { timeout: reportRequestTimeout * 1000 } : {};
        $scope.submittingReport = true;
        return EngineReportService.submitMEReport($scope.engine_report, closeReport, options)
        .then(function(response, status, headers, config) {
            var data = response.data;
            notify({message: 'Successfully saved main engine report!', duration: 2000, classes: ['ok-notification']});
            $scope.engine_report.id = data.data.report_id;
            $scope.submittingReport = false;

            if ((enableHybrid || enableOnPremise) && closeReport) {
                OfflineDatabase.removeEngineReport($scope.engine_report).then(()=> {
                    return hybridService.serverMetaGet($rootScope.selectedVessel.id).then(offlineVesselData => {
                        var updatedVesselData = offlineVesselData ? Object.assign({}, offlineVesselData) : {vesselId: $rootScope.selectedVessel.id};
                        updatedVesselData.hasIncompleteEngineReport = false;
                        updatedVesselData.meReportNumber = data.data.report_number;
                        return hybridService.serverMetaUpdate(updatedVesselData);
                    })
                });
            }

            // redirect to report performance page if the report is closed
            if (closeReport) {
                $rootScope.hasIncompleteMeEngineReport = false;
                $scope.refreshEngineReports(function() {
                    $state.go('site.engineReports');
                });
                // because we're closing the report, create a new one, put it on the service
                ReportService.setMEReportNumber(data.data.report_number);
                var newReport = ReportService.createNewEngineReport();
                ReportService.setEngineReport(newReport);
            } else {
                if((enableHybrid || enableOnPremise) && $scope.engine_report.status != 'completed') {
                    $scope.saveEngineReportToLocalDb($scope.engine_report, false)
                }
                $scope.refreshEngineReports();
                $scope.refreshLastIncompleteEngineReport();
            }
        }, function (response, status, header, config) {
            $scope.submittingReport = false;
            if (status == 403) {
                notify({message: 'This report has already been submitted.', duration: 5000, classes: ['warning-notification']});
            } else if (enableHybrid || enableOnPremise) {
                $scope.saveEngineReportToLocalDb($scope.engine_report, closeReport)
                .then(()=> {
                    $scope.refreshOfflineEngineReports().then(function(){
                        if (closeReport == true) {
                            $state.go('site.engineReports');
                        }
                    });
                });
            } else {
                notify({message: 'Error saving main engine report, please try again later.', duration: 5000, classes: ['bad-notification']});
            }
        });
    }

    // update report period from report from and report to times
    $scope.$watchGroup(['engine_report.details.report_from', 'engine_report.details.report_to'], function(newValues, oldValues, scope) {
        if ($scope.engine_report.details == undefined) {
            $scope.engine_report.details = {};
        }

        if (newValues[0] != undefined && newValues[1] != undefined) {
             var fromMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
             var toMoment = moment(newValues[1], 'DD/MM/YYYY HH:mm');
             var duration = moment.duration(toMoment.diff(fromMoment));
             let durationHours = duration.asHours();
             $scope.engine_report.details.report_period = Math.max(Math.round(durationHours * 100) / 100, 0);
        } else {
            $scope.engine_report.details.report_period = 0;
        }
    });

    // compute cylinder units averages
    $scope.$watch('engine_report.cylinder_units.cyl', function(newValue, oldValue, scope) {
        // define averages
        var exhaust_temperature = 0;
        var p_max = 0;
        var p_comp = 0;
        var fuel_pump_index = 0;
        var p_indicated = 0;
        var cw_outlet_temperature = 0;
        var piston_cooling_temperature = 0;

        let counter = 0;

        var cyl = newValue;
        angular.forEach(cyl, function(value) {
            if (value.exhaust_temperature != undefined) {
                exhaust_temperature += value.exhaust_temperature;
            }
            if (value.p_max != undefined) {
                p_max += value.p_max;
            }
            if (value.p_comp != undefined) {
                p_comp += value.p_comp;
            }
            if (value.fuel_pump_index != undefined) {
                fuel_pump_index += value.fuel_pump_index;
            }
            if (value.p_indicated != undefined) {
                p_indicated += value.p_indicated;
            }
            if (value.cw_outlet_temperature != undefined) {
                cw_outlet_temperature += value.cw_outlet_temperature;
            }
            if (value.piston_cooling_temperature != undefined) {
                piston_cooling_temperature += value.piston_cooling_temperature;
            }
            counter += 1;
        });

        if (counter > 0) {
            exhaust_temperature = exhaust_temperature / counter;
            p_max = p_max / counter;
            p_comp = p_comp / counter;
            fuel_pump_index = fuel_pump_index / counter;
            p_indicated = p_indicated / counter;
            cw_outlet_temperature = cw_outlet_temperature / counter;
            piston_cooling_temperature = piston_cooling_temperature / counter;
        }
        $scope.engine_report.cylinder_units.computed = {};
        $scope.engine_report.cylinder_units.computed.exhaust_temperature = exhaust_temperature;
        $scope.engine_report.cylinder_units.computed.p_max = p_max;
        $scope.engine_report.cylinder_units.computed.p_comp = p_comp;
        $scope.engine_report.cylinder_units.computed.fuel_pump_index = fuel_pump_index;
        $scope.engine_report.cylinder_units.computed.p_indicated = p_indicated;
        $scope.engine_report.cylinder_units.computed.cw_outlet_temperature = cw_outlet_temperature;
        $scope.engine_report.cylinder_units.computed.piston_cooling_temperature = piston_cooling_temperature;
        $scope.engine_report.cylinder_units.computed.p_rise = p_max - p_comp;
    }, true);

    // compute turbocharging averages
    $scope.$watch('engine_report.turbocharging.turbo', function(newValue, oldValue, scope) {
        // define averages
        var pressure_drop_across_air_filter = 0;
        var pressure_drop_across_air_cooler = 0;
        var air_temperature_before_cooler = 0;
        var air_temperature_after_cooler = 0;
        var rpm = 0;
        var exhaust_temperature_before_tc = 0;
        var exhaust_temperature_after_tc = 0;

        let counter = 0;

        var turbos = newValue;
        angular.forEach(turbos, function(value) {
            if (value.pressure_drop_across_air_filter != undefined) {
                pressure_drop_across_air_filter += value.pressure_drop_across_air_filter;
            }
            if (value.pressure_drop_across_air_cooler != undefined) {
                pressure_drop_across_air_cooler += value.pressure_drop_across_air_cooler;
            }
            if (value.air_temperature_before_cooler != undefined) {
                air_temperature_before_cooler += value.air_temperature_before_cooler;
            }
            if (value.air_temperature_after_cooler != undefined) {
                air_temperature_after_cooler += value.air_temperature_after_cooler;
            }
            if (value.rpm != undefined) {
                rpm += value.rpm;
            }
            if (value.exhaust_temperature_before_tc != undefined) {
                exhaust_temperature_before_tc += value.exhaust_temperature_before_tc;
            }
            if (value.exhaust_temperature_after_tc != undefined) {
                exhaust_temperature_after_tc += value.exhaust_temperature_after_tc;
            }
            counter += 1;
        });

        if (counter > 0) {
            pressure_drop_across_air_filter = pressure_drop_across_air_filter / counter;
            pressure_drop_across_air_cooler = pressure_drop_across_air_cooler / counter;
            air_temperature_before_cooler = air_temperature_before_cooler / counter;
            air_temperature_after_cooler = air_temperature_after_cooler / counter;
            rpm = rpm / counter;
            exhaust_temperature_before_tc = exhaust_temperature_before_tc / counter;
            exhaust_temperature_after_tc = exhaust_temperature_after_tc / counter;
        }
        scope.engine_report.turbocharging.computed = {};
        scope.engine_report.turbocharging.computed.pressure_drop_across_air_filter = pressure_drop_across_air_filter;
        scope.engine_report.turbocharging.computed.pressure_drop_across_air_cooler = pressure_drop_across_air_cooler;
        scope.engine_report.turbocharging.computed.air_temperature_before_cooler = air_temperature_before_cooler;
        scope.engine_report.turbocharging.computed.air_temperature_after_cooler = air_temperature_after_cooler;
        scope.engine_report.turbocharging.computed.rpm = rpm;
        scope.engine_report.turbocharging.computed.exhaust_temperature_before_tc = exhaust_temperature_before_tc;
        scope.engine_report.turbocharging.computed.exhaust_temperature_after_tc = exhaust_temperature_after_tc;
    }, true);

    // mcr model values for results tab
    var modelsRequestTimer = false;
    var delay = 2000;
    $scope.$watchGroup(['engine_report.details.effective_power', 'vessel_specifications.engine.max_electrical_power'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined) {
            if (modelsRequestTimer) {
                $timeout.cancel(modelsRequestTimer);
            }

            var mcrPercent = 100;
            if (newValues[1] != undefined && newValues[1] != 0) {
                mcrPercent = newValues[0] * 100 / newValues[1];
            }

            $scope.engine_report.load_percent_mcr = mcrPercent;
            modelsRequestTimer = $timeout(function() {

                ModelsApiService.getModelsForEngineLoad(mcrPercent).then(function(response, status, headers, config) {
                    var data = response.data;
                    if (scope.engine_report.computed == undefined) {
                        scope.engine_report.computed = {};
                    }
                    scope.engine_report.computed.blower_inlet_temperature_model = data.blowerInletTemperature;
                    scope.engine_report.computed.air_cooler_pressure_drop_model = data.airCoolerPressureDrop;
                    scope.engine_report.computed.air_filter_pressure_drop_model = data.airFilterPressureDrop;
                    scope.engine_report.computed.exhaust_gas_temperature_model = data.exhaustGasTemperatureCylOut;
                    scope.engine_report.computed.scavenge_air_temperature_model = data.scavengeAirTemperature;
                    scope.engine_report.computed.scavenge_air_pressure_model = data.scavengingAirPressure;
                    scope.engine_report.computed.tcrpm_model = data.tcRPM;
                    scope.engine_report.computed.turbine_back_pressure_model = data.turbineBackPressure;
                    scope.engine_report.computed.turbine_inlet_pressure_model = data.turbineInletPressure;
                    scope.engine_report.computed.turbine_inlet_temperature_model = data.turbineInletTemperature;
                    scope.engine_report.computed.fuel_pump_index_model = data.fuelPumpIndex;
                    scope.engine_report.computed.p_comp_model = data.pComp;
                    scope.engine_report.computed.p_max_model = data.pMax;
                    scope.engine_report.computed.p_rise_model = data.pMax - data.pComp;
                }, function(response, status, header, config) {
                    var data = response.data;
                    console.log(data);
                });

            }, delay);
        }
    });

    $scope.$watchGroup(['engine_report.details.kwh_start', 'engine_report.details.kwh_end', 'engine_report.details.report_period'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined && newValues[1] != undefined && newValues[2] != 0) {
            var kwh_start = newValues[0] || 0;
            var kwh_end = newValues[1] || 0;
            var report_period = newValues[2];
            $scope.engine_report.details.avg_power = (kwh_end - kwh_start) / (report_period);
        } else {
            $scope.engine_report.details.avg_power = null;
        }
    });

    // compute indicated sfoc lcv corrected
    $scope.$watchGroup(['engine_report.details.fuel_consumption',
                        'engine_report.details.lcv',
                        'engine_report.details.indicated_power',
                        'engine_report.details.report_period'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            var fuel_consumption = newValues[0];
            var lcv = newValues[1];
            var indicated_power = newValues[2];
            var report_period = newValues[3];
            if (report_period == 0 || indicated_power == 0) {
                return;
            }
            if ($scope.engine_report.computed == undefined) {
                $scope.engine_report.computed = {};
            }
            $scope.engine_report.computed.indicated_sfoc_lcv_corrected = 1000000 * (fuel_consumption * lcv) / (indicated_power * report_period * 42700);
        }
    });

    // compute sfoc lcv corrected
    $scope.$watchGroup(['engine_report.details.fuel_consumption',
                        'engine_report.details.lcv',
                        'engine_report.details.effective_power',
                        'engine_report.details.report_period'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            var fuel_consumption = newValues[0];
            var lcv = newValues[1];
            var effective_power = newValues[2];
            var report_period = newValues[3];
            if (report_period == 0 || effective_power == 0) {
                return;
            }
            if ($scope.engine_report.computed == undefined) {
                $scope.engine_report.computed = {};
            }
            $scope.engine_report.computed.sfoc_lcv_corrected = 1000000 * (fuel_consumption * lcv) / (effective_power * report_period * 42700);
        }
    });

    // compute sfoc blower inlet temperature
    $scope.$watchGroup(['engine_report.computed.sfoc_lcv_corrected',
                        'engine_report.computed.blower_inlet_temperature_model',
                        'engine_report.details.lo_temp_tc_blower_inlet'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            var sfoc_lcv_corrected = newValues[0];
            var model_blower_inlet_temp = newValues[1];
            var lo_temp_tc_blower_inlet = newValues[2];
            if ($scope.engine_report.computed == undefined) {
                $scope.engine_report.computed = {};
            }
            $scope.engine_report.computed.sfoc_blower_inlet_temperature = sfoc_lcv_corrected * (model_blower_inlet_temp - lo_temp_tc_blower_inlet) * 0.0002;
        }
    });

    // compute sfoc blower inlet pressure
    $scope.$watchGroup(['engine_report.computed.sfoc_lcv_corrected',
                        'engine_report.details.barometric_pressure',
                        'engine_report.turbocharging.computed.pressure_drop_across_air_filter'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            var sfoc_lcv_corrected = newValues[0];
            var model_blower_inlet_temp = newValues[1];
            var pressure_drop_across_air_filter = newValues[2];
            if ($scope.engine_report.computed == undefined) {
                $scope.engine_report.computed = {};
            }
            $scope.engine_report.computed.sfoc_blower_inlet_pressure = (sfoc_lcv_corrected * (model_blower_inlet_temp - (pressure_drop_across_air_filter * 0.0981)) - 1000) * 0.0002;
        }
    });

    // compute sfoc scavenge air temperature
    $scope.$watchGroup(['engine_report.computed.sfoc_lcv_corrected',
                        'engine_report.computed.scavenge_air_pressure_model',
                        'engine_report.details.scavenge_air_temperature'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            var sfoc_lcv_corrected = newValues[0];
            var model_scavenge_air_temperature = newValues[1];
            var scavenge_air_temperature = newValues[2];
            if ($scope.engine_report.computed == undefined) {
                $scope.engine_report.computed = {};
            }
            $scope.engine_report.computed.sfoc_scavenge_air_temperature = sfoc_lcv_corrected * (model_scavenge_air_temperature - scavenge_air_temperature) * 0.0006;
        }
    });

    // compute sfoc thrust bearing loss
    $scope.$watchGroup(['engine_report.computed.sfoc_lcv_corrected'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            var sfoc_lcv_corrected = newValues[0];
            if ($scope.engine_report.computed == undefined) {
                $scope.engine_report.computed = {};
            }
            $scope.engine_report.computed.sfoc_thrust_bearing_loss = sfoc_lcv_corrected * (1 - (1/1.01));
        }
    });

    // compute sfoc ISO corrected
    $scope.$watchGroup(['engine_report.computed.sfoc_lcv_corrected',
                        'engine_report.computed.sfoc_blower_inlet_temperature',
                        'engine_report.computed.sfoc_blower_inlet_pressure',
                        'engine_report.computed.sfoc_scavenge_air_temperature',
                        'engine_report.computed.sfoc_thrust_bearing_loss'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            if ($scope.engine_report.computed == undefined) {
                $scope.engine_report.computed = {};
            }
            $scope.engine_report.computed.sfoc_iso_corrected = newValues[0] + newValues[1] + newValues[2] + newValues[3] + newValues[4];
        }
    });

    // calculate deviations
    $scope.$watchGroup(['engine_report.details.lo_temp_tc_blower_inlet',
                       'engine_report.computed.blower_inlet_temperature_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.blower_inlet_temperature_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.details.turbine_inlet_pressure',
                       'engine_report.computed.turbine_inlet_pressure_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.turbine_inlet_pressure_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.details.turbine_inlet_pressure',
                       'engine_report.computed.turbine_inlet_pressure_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.turbine_inlet_pressure_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.turbocharging.computed.pressure_drop_across_air_cooler',
                        'engine_report.computed.air_cooler_pressure_drop_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.air_cooler_pressure_drop_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.details.scavenge_air_temperature',
                        'engine_report.computed.scavenge_air_temperature_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.scavenge_air_temperature_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.details.scavenge_air_pressure',
                        'engine_report.computed.scavenge_air_pressure_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.scavenge_air_pressure_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.details.turbine_back_pressure',
                        'engine_report.computed.turbine_back_pressure_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.turbine_back_pressure_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.turbocharging.computed.exhaust_temperature_before_tc',
                        'engine_report.computed.turbine_inlet_temperature_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.turbine_inlet_temperature_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.cylinder_units.computed.p_comp',
                        'engine_report.computed.p_comp_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.p_comp_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.cylinder_units.computed.p_max',
                        'engine_report.computed.p_max_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.p_comp_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });
    $scope.$watchGroup(['engine_report.cylinder_units.computed.p_rise',
                        'engine_report.computed.p_rise_model'], function(newValues, oldValues, scope) {
        if (valuesDefined(newValues)) {
            scope.engine_report.computed.p_comp_deviation = CalculationService.calculateDeviation(newValues[0], newValues[1]);
        };
    });

    $scope.turbochargerIndices = [0, 1, 2];
    $scope.cylinderIndices = [0, 1, 2, 3, 4, 5];

    $scope.$watch('engine_report.details.lo_temp_tc_blower_inlet', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.lo_temp_tc_blower_inlet'] = (newValue < 15 || newValue > 65)
            ? 'Blower Inlet Temperature should be between 15 and 65°C'
            : undefined;
    });

    $scope.$watch('engine_report.details.turbine_back_pressure', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.turbine_back_pressure'] = (newValue < 10 || newValue > 500)
            ? 'Turbine Back Pressure should be between 10 and 500 mmH₂O'
            : undefined;
    });

    $scope.$watch('engine_report.details.barometric_pressure', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.barometric_pressure'] = (newValue < 900 || newValue > 1100)
            ? 'Barometric pressure should be between 900 and 1100 mBar'
            : undefined;
    });

    $scope.turbochargerIndices.forEach(function(i) {
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].pressure_drop_across_air_filter', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].pressure_drop_across_air_filter'] = (newValue < 0 || newValue > 200)
                ? 'Air Filter Pressure Drop should be between 0 and 200 mmH₂O'
                : undefined;
        });
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].pressure_drop_across_air_cooler', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].pressure_drop_across_air_cooler'] = (newValue < 0 || newValue > 350)
                ? 'Air Cooler Pressure Drop should be between 0 and 350°C'
                : undefined;
        });
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].rpm', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].rpm'] = (newValue < 1000 || newValue > 21000)
                ? 'TC RPM should be between 1000 and 21000'
                : undefined;
        });
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].air_temperature_before_cooler', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].air_temperature_before_cooler'] = (newValue < 100 || newValue > 200)
                ? 'Air Temperature Before Cooler should be between 100 and 200°C'
                : undefined;
        });
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_before_tc', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_before_tc'] = (newValue < 150 || newValue > 520)
                ? 'Exhaust Temperature Before T/C should be between 150 and 520°C'
                : undefined;
        });
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_after_tc', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_after_tc'] = (newValue < 150 || newValue > 460)
                ? 'Exhaust Temperature Before T/C should be between 150 and 460°C'
                : undefined;
        });
    });

    $scope.$watch('engine_report.details.air_inlet_temperature', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.air_inlet_temperature'] = (newValue < 10 || newValue > 70)
            ? 'Air Inlet Temperature should be between 10 and 70°C'
            : undefined;
    });

    $scope.$watch('engine_report.details.scavenge_air_pressure', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.scavenge_air_pressure'] = (newValue < 0 || newValue > 3)
            ? 'Scavenge Air Pressure should be between 0 and 3 Bar'
            : undefined;
    });

    $scope.$watch('engine_report.details.scavenge_air_temperature', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.scavenge_air_temperature'] = (newValue < 25 || newValue > 60)
            ? 'Scavenge Air Temperature should be between 25 and 60°C'
            : undefined;
    });

    $scope.$watch('engine_report.details.turbine_inlet_pressure', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.turbine_inlet_pressure'] = (newValue < 0 || newValue > 3)
            ? 'Turbine Inlet Pressure should be between 0 and 3 Bar'
            : undefined;
    });

    $scope.cylinderIndices.forEach(function(i) {
        $scope.$watch('engine_report.cylinder_units.cyl[' + i + '].exhaust_temperature', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.cylinder_units.cyl[' + i + '].exhaust_temperature'] = (newValue < 150 || newValue > 480)
                ? 'Exhaust Gas Temperature should be between 150 and 480°C'
                : undefined;
        });
    });

    $scope.$watch('engine_report.details.temp_before_air_cooler', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.temp_before_air_cooler'] = (newValue < 20 || newValue > 60)
            ? 'CW Temp Before Air Cooler should be between 20 and 60°C'
            : undefined;
    });

    $scope.$watch('engine_report.details.temp_after_air_cooler', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.temp_after_air_cooler'] = (newValue < 5 || newValue > 80)
            ? 'CW Temp After Air Cooler should be between 5 and 80°C'
            : undefined;
    });

    $scope.$watchGroup(['engine_report.details.fuel_consumption', 'engine_report.details.effective_power', 'engine_report.details.report_period'], function(newValues, oldValues, scope) {
        var fuelCons = newValues[0];
        var effectivePower = newValues[1];
        var reportPeriod = newValues[2];
        var minValue = 150 * Math.pow(10, -6) * effectivePower * reportPeriod;
        var maxValue = 300 * Math.pow(10, -6) * effectivePower * reportPeriod;
        $scope.warningMessages['engine_report.details.fuel_consumption'] = (fuelCons < minValue || fuelCons > maxValue)
            ? 'Fuel Consumption should be between ' + minValue + ' and ' + maxValue
            : undefined;
    });

    $scope.$watch('engine_report.details.viscosity_at_40', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.viscosity_at_40'] = (newValue < 10 || newValue > 1000)
            ? 'Viscosity should be between 10 and 1000 cSt'
            : undefined;
    });

    $scope.$watch('engine_report.details.fuel_oil_pressure', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.fuel_oil_pressure'] = (newValue < 0 || newValue > 10)
            ? 'Fuel Oil Pressure should be between 0 and 10 Bar'
            : undefined;
    });

    $scope.$watch('engine_report.details.fuel_oil_temperature', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.fuel_oil_temperature'] = (newValue < 10 || newValue > 160)
            ? 'Fuel Oil Temperature should be between 10 and 160°C'
            : undefined;
    });

    $scope.$watch('engine_report.unable_to_fill_report', function(newValue) {
        var reportFormScope = angular.element($('[name="reportForm"]')).scope();
        if (reportFormScope) {
            var reportForm = reportFormScope.reportForm;
        } else {
            return;
        }

        if (newValue) {
            angular.forEach(['details', 'cylinder', 'turbocharging'], function(tab) {
                if (reportForm[tab] != undefined) {
                    reportForm[tab].$pristine = true;
                    angular.forEach(reportForm[tab], function(control) {
                        if (typeof control === 'object' && control.hasOwnProperty('$modelValue')) {
                            var alwaysRequired = ['reportFrom', 'reportTo'].indexOf(control.$name) != -1;
                            if (!alwaysRequired) {
                                control.$pristine = true;
                                control.$invalid = false;
                            } else {
                                control.$pristine = false;
                            }
                        }
                    });
                }
            });
        } else {
            angular.forEach(['details', 'cylinder', 'turbocharging'], function(tab) {
                if (reportForm[tab] != undefined) {
                    angular.forEach(reportForm[tab], function(control) {
                        if (typeof control === 'object' && control.hasOwnProperty('$modelValue')) {
                            control.$validate();
                        }
                    });
                }
            });
        }
    });
    if (process.env.NODE_ENV == 'test' && testService) {
       /**
         * Add all service and scopes to test framework to test mePerformance.controller async methods
         * Test Scripts are added back into scope and used in site/test 
         * Test Scripts are assign to button in test-report.html
         */
        const ts = testService.TestFrameWork.getInstance({meControllerScope: $scope});
        $scope.testMeReportSave = ts.testMeReportSave;
        $scope.testMeReportSubmit = ts.testMeReportSubmit;
        $scope.testMeReportDelete = ts.testMeReportDelete;
    }

}]);
