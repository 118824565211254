import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import angular from 'angular';
import { routerApp, roundToPlaces, fuelGradeMapping, Color, themePalette } from '../../app.module';

HighchartsMore(Highcharts)

Highcharts.setOptions({
    global: {
        // Unintuitively, need to set this to false in order for dates to be
        // displayed in UTC. Setting to false will display dates "as-is" (no
        // timezone conversion), and dates from API are now in UTC.
        useUTC: false
    }
});

export type DateRange = '10_days' | 'mtd' | '1_month' | '2_month' | '3_month'| 'year' | 'ytd' | 'custom' | 'since_last_dry_docking' | 'since_last_hull_cleaning' | 'since_last_propeller_polishing';
export type DateStringDDMMYYYHHmm = string;
export type FPAPIVessel = any;
export type MongoId = string;
export type PageMetric = string;
export type Point = [number, any];
export type VesselClass = string;
export type VesselId = MongoId;

export enum BallastCondition {
    All = 'all',
    Laden = 'laden',
    Ballast = 'ballast',
    PartiallyLoaded = 'partially_loaded'
};

export enum CIIRatingClass {
    A = 'progress-rating-a',
    B = 'progress-rating-b',
    C = 'progress-rating-c',
    D = 'progress-rating-d',
    E = 'progress-rating-e',
};
export enum CIIRatingColor {
    A = '#60A13D',
    B = '#9FCB2E',
    C = '#E6D220',
    D = '#EB6A52',
    E = '#EE402F',
}

interface BFValue {
    value: number;
    selected: boolean;
}

interface FPOptions {
    bf: number;
    bfValues: BFValue[];
    dateRange: DateRange;
    dateRangeText: string;
    reportFrom: Date;
    reportTo: Date | null;
    selectAll: boolean;
    vesselClasses: Record<VesselClass, boolean>;
    vesselClassesList: VesselClass[];
    vesselClassLabel: string;
    vessels: Record<VesselId, boolean>;
    vesselsByClass: Record<VesselClass, any[]>;
    voyageLeg: BallastCondition;
}

interface FPData {
    vessels: FPVessel[];
    fleet: Record<string, any>;
}

interface VesselList {
    label: string;
    valueLabel: string;
    key: string;
    orderKey: string;
    unit: string;
    reverse: boolean;
}

export interface FPScope {
    BallastCondition: typeof BallastCondition;
    allData: any[];
    chartsList: any[];
    customReportFrom: DateStringDDMMYYYHHmm;
    customReportTo: DateStringDDMMYYYHHmm;
    disableBfDropdown: boolean;
    disableLegButtonAll: boolean;
    disableLegButtonBallast: boolean;
    disableLegButtonLaden: boolean;
    disableLegButtonPartiallyLoaded: boolean;
    EEOIQuartilesSet: boolean;
    fleetPerformance: FPData;
    fleetPerformanceMetricLabel: Record<string, any>;
    fleetPerformanceMetrics: any[];
    fleetPerformanceMetricsClass: string;
    fpMetricChangeClass:  Record<string, any>;
    fleetPerformanceDropdownOptions: any[];
    vesselListLabels: Record<string, string>;
    fleetPerformanceDataCache: Record<string, any>;
    minDate: Date;
    options: FPOptions;
    pageMetric: PageMetric;
    showingAllCharts: boolean;
    vesselList: VesselList;

    areAllVesselsSelected: () => boolean;
    deselectAll: () => void;
    changeDateRange: (dateRange: DateRange) => void;
    displayFleetPerformanceLabel: (metric: string, vessels: FPVessel[]) => void;
    getEEOIMax: (v: FPVessel) => number;
    getVesselClassCSSClass: (vesselClass: VesselClass) => string;
    makeSafeForCSS: (input: string) => string;
    normaliseProgressBars: () => void;
    recalculateData: () => void;
    selectAll: () => void;
    setAllClasses: (b: boolean) => void;
    setAllVessels: (b: boolean) => void;
    setListKey: (key: string) => void;
    hideVesselToggles: (cls: VesselClass) => void;
    toggleVessel: (vesselId: VesselId, cls: VesselClass) => void;
    setOrderKey: (key: string) => void;
    toggleExpandAll: () => void;
    setVoyageLeg: (voyageLeg: BallastCondition) => void;
    showVesselToggles: (cls: VesselClass) => void;
    toggleBFMax: (bf: number) => void;
    toggleClass: (key: VesselClass) => void;
    updateVesselClasses: () => void;
    updateMetricQuartiles: () => void;
    waitAndDrawVesselGraph: (vessel: FPVessel, scope: FPScope) => void;
    shouldShowMetric: (metricKey: string) => boolean;
    isEagleUser: boolean;
    isInternalAdmin: boolean;
    isRioTintoUser: boolean;
    isGCCUser: boolean;
    isSmyrilUser: boolean;
    isciiReportingUser: boolean;

    $on: any;
    user: any;
    $watch: any;
    $watchGroup: any;
}

export interface FPAPIReport {
    vessel: FPVessel,
    [key: string]: any, // Allow other properties
}

interface DryDocking {
    _id: MongoId;
    date: string;
    dry_docking: boolean;
    hull_cleaning: boolean;
    propeller_polishing: boolean;
    me_over_haul: boolean;
}

export class FPVessel {
    a: Point[] = [];
    aer_avg: number = 0;
    aer_quartiles: any = {};
    aerGraphData: any[] = [];
    all_reports: FPAPIReport[] = [];
    bunker_date: Point[] = [];
    bunker_port: Point[] = [];
    c: Point[] = [];
    c_avg = 0;
    cargo_quantity: Point[] = [];
    cf: Point[] = [];
    cf_avg = 0;
    cf_d_avg = 0;
    cii_attained = 0;
    cii_required_period = 0;
    cii_avg = 0;
    cii_avg_period: Point[] = [];
    cii_capacity = 0;
    capacity_correction_factor = 1;
    cii_mean_diff_abs = 0;
    ciiGraphData: Point[] = [];
    client_id: string;
    co2_emissions: Point[] = [];
    cp: Point[] = [];
    cp_consumption_deviation_avg = 0;
    cp_consumption_deviation_mean_diff = 0;
    cp_consumption_deviation_mean_diff_abs = 0;
    cp_speed: Point[] = [];
    cp_speed_difference_avg = 0;
    cp_speed_difference_mean_diff = 0;
    cp_speed_difference_mean_diff_abs = 0;
    current_nc_reference_line_value: number;
    d1: number;
    d2: number;
    d3: number;
    d4: number;
    date_range_aer = 0;
    date_range_cii_required = [];
    date_range_distance = 0;
    date_range_eeoi = 0;
    date_range_emissions = 0;
    date_range_cii_emissions = 0;
    date_range_transport_work = 0;
    date_range_deadweight_work = 0;
    displayData: Record<string, any> = {};
    dry_dockings: DryDocking[] = [];
    e: Point[] = [];
    e_avg = 0;
    eeoi: number;
    eeoiGraphData: any[] = [];
    eeoi_quartiles: any = {};
    fuel_grade_in_use_main_engine: Point[] = [];
    me_excess_consumption_avg = 0;
    me_load: Point[] = [];
    movingAverageData: any[][] = [];
    nc_avg = 0;
    nc_reference: number;
    ncb_reference: number;
    notMatchedSeries: Point[] = [];
    overhaulDates: Date[];
    p: Point[] = [];
    p_avg = 0;
    performance_speed: Point[] = [];
    progressBarClass: string;
    report_number: Point[] = [];
    report_number_not_matched: Point[] = [];
    report_period: Point[] = [];
    s: Point[] = [];
    s_avg = 0;
    sf_c: Point[] = [];
    sf_m: Point[] = [];
    sp_avg = 0;
    speed_performance_lifetime_data: [number, number, number][];
    speed_performance_not_matched_data: [number, number, number][];
    tank_name: Point[] = [];
    tc: Point[] = [];
    tc_avg = 0;
    tcf: Point[] = [];
    tcf_avg = 0;
    tcf_d_avg = 0;
    timeseries: Point[] = [];
    timestampsToReportNumbers: {};
    total_cp_fuel: Point[] = [];
    total_fuel_consumption: Point[] = [];
    total_obs_distance: Point[] = [];
    tres_voyage_number: Point[] = [];
    vessel_class: string;
    vessel_type: string;
    vessel_id: string;
    vessel_name: string;
    voyages: any[] = [];
    voyagesByEndDates: Record<any, any> = {};

    constructor(currentVessel: FPAPIVessel) {
        this.client_id = currentVessel.client_id;
        this.eeoi = currentVessel.eeoi;
        this.eeoi_quartiles = currentVessel.eeoi_quartiles;
        this.aer_quartiles = currentVessel.aer_quartiles;
        this.dry_dockings = currentVessel.dry_dockings || [];
        this.vessel_class = currentVessel.vessel_class;
        this.vessel_type = currentVessel.vessel_type;
        this.vessel_id = currentVessel.vessel_id;
        this.vessel_name = currentVessel.vessel_name;
        this.nc_reference = currentVessel.nc_reference;
        this.ncb_reference = currentVessel.ncb_reference;
        this.cii_capacity = currentVessel.cii_capacity;
        this.current_nc_reference_line_value = currentVessel.current_nc_reference_line_value;
        this.d1 = currentVessel.d1;
        this.d2 = currentVessel.d2;
        this.d3 = currentVessel.d3;
        this.d4 = currentVessel.d4;
        this.capacity_correction_factor = currentVessel.capacity_correction_factor;
    }

    getTransportWork(report: FPAPIReport) {
        if (this.vessel_type === 'cruise_passenger') {
            return  (report.number_of_passengers || 0) * (report.total_obs_distance || 0);
        } else {
            return  (report.cargo_quantity || 0) * (report.total_obs_distance || 0);
        }
    }

    getMetricLabel(metricKey: string) {
        var vesselListUnits = {
            s: 'Knots',
            k: '',
            tc: 'MT/24 Hrs',
            c: 'MT/24 Hrs',
            e: 'g/MT.NM',
            aer: 'g/DWT-NM',
            m: 'NM/MT',
            p: '%',
            sp: '%',
            nc: 'MT/24 Hrs',
            sf_c: 'g/kWh',
            sf_d: '%',
            tcrpm_deviation: '%',
            cp_consumption_deviation: 'MT/24 Hrs',
            cp_speed_difference: 'Knots',
            me_excess_consumption: 'MT/24 Hrs',
            cii: '%',
            transport_work: 'MT*NM'
        };

        if(this.vessel_type === 'cruise_passenger') {
            if(metricKey === 'e') {
                return 'g/passenger.NM';
            }
            if (metricKey === 'transport_work') {
                return 'passenger.NM';
            }
            return vesselListUnits[metricKey];

        } else {
            return vesselListUnits[metricKey];
        }
    }
};

class FPMetricOptions {
    // Always match. Never add to the "not matched" series, just add all
    // data points to the main series.
    alwaysMatch? = false;

    // Default chart type. Changing this will break the shared tooltips.
    chartType? = 'line';

    color?: string = undefined;

    // Split series and break trend lines on specified events. Only does some
    // thing when drawTrendLine is true.
    breakTrendLineOnDryDocking? = false;
    breakTrendLineOnHullCleaning? = false;

    columnStacking?: string = undefined;

    // Draws a linear regression trendline
    drawTrendLine? = false;
    // Enables tooltips on trendlines
    enableTrendLineTooltip? = false;

    // Hide the unmatched (not analyzed) series by default.
    hideUnmatchedByDefault? = false;

    grouping? = false;

    // Whether to ignore the 'match' parameter sent by the backend
    // when filtering reports.
    ignoreBackendMatch? = false;

    lineWidth?: number = undefined;

    // Plot a line of a moving average for the last N number of reports
    movingAverage? = undefined;
    // Plot a line of a moving average for N days interval
    movingAverageByPeriod? = undefined;

    name? = undefined;
    negativeColor? = undefined;
    notAnalyzedName? = undefined;

    // works in conjunction with metric.plotDynamicBand
    plotDynamicBands? = false;
    plotSupplementalData? = true;
    regressionColor? = undefined;
    yAxisName? = undefined
    threshold? = undefined;
    useClassBasedYMinMax? = true;
    useMaxForProgressBars? = false;
}

export class FPMetric {
    // Main key for the metric
    key: string;

    // Sets vessel.progressBarClass, a CSS class to color the progress bar.
    colorToProgressClassFunction? = (_vessel: FPVessel): void => {};

    // Used to edit the chart after instantiating it with Highcharts
    chartEffects? = (_chart: any, _vessel: FPVessel): void => {};

    // Only add data to the main metric timeseries when this function returns true.
    dataFilter? = (_report: FPAPIReport) => true;

    // Extra y axes to be put onto the chart
    extraYAxes?: any[] = [];
    extraSeries?: any[] = [];

    // Function that take the vessel obj and returns an array of plotLine objects.
    plotLines? = (_vessel: FPVessel) => [];
    xAxisPlotLines? = (_vessel: FPVessel) => [];

    // Custom function to return an array to plot customize arearange graph on y axis bands
    plotDynamicBands? = (_vessel: FPVessel) => [];
    // Misc options
    options? = new FPMetricOptions();

    // If the report doesn't match, this function decides if it is
    // pushed to the not matched series (true by default).
    pushToNotMatched? = (_report: FPAPIReport) => true;

    // Gets merged into the chart's plotOptions like:
    // $.extend(true, defaultPlotOptions, metric.plotOptions).
    plotOptions?: object = {};

    // use to calculate new data from each report and vessel
    processReport? = (_report: FPAPIReport, _vessel: FPVessel): void => {};

    // Assign tooltips to the vessel obj
    setTooltipsFunction? = (_vessel: FPVessel): void => {};

    // Other data that gets put on the chart next to the main metric
    // data An object where the properties are the keys to the data from
    // the vessel object. The values are options for the Highcharts
    // series. { cf: { name: 'Charter Party Fuel' } } will draw a
    // charter party fuel graph on the chart.
    supplementalData?: object = {};

    // Keys are keys to the data on the vessel object as in
    // supplementalData. Values are objects that have properties: name,
    // unit, and valueFormatter (optional). The values for these keys
    // will only be in the tooltips, not displayed as graphs on the
    // chart.
    tooltipDataKeys?: object = {};
};

interface yAxis {
    min: number,
    max?: number,
    title?: {
        text?: string
    },
    plotLines: any[],
    visible?: boolean,
    tickInterval?: number,
}

export interface IFleetPerformanceGraphService {
    buildMetric: (metric: FPMetric) => FPMetric;
    drawVesselGraph: (vessel: FPVessel, fpScope: FPScope) => void;
    getMetric: (metricKey: string) => FPMetric;
}

routerApp.filter('withVesselUnit', function() {
    return function(input, vessel: FPVessel, key) {
        if (angular.isUndefined(input) || input === null || input === '') {
            return "-";
        }
        let unit = vessel.getMetricLabel(key);

        return input + " " + unit;
    }
});

routerApp.factory('FleetPerformanceGraphService', ['SpeedPerformanceService', function(SpeedPerformanceService: any) {

    var metrics = {};

    var getMetric = function(key: string): FPMetric {
        var metric = metrics[key];
        if (metric) {
            return metric;
        } else {
            throw 'Unknown metric for key: "' + key + '". Use buildMetric() first!';
        }
    };

    var buildMetric = function(metric: FPMetric): FPMetric {
        // `vessel` here refers to the vessel object that is populated by data in the recalculateData function.
        // `report` is the report object returned in the API call.

        var newMetric = Object.assign({}, new FPMetric(), metric);
        newMetric.options = Object.assign({}, new FPMetric().options, newMetric.options);

        // Always add report_number to the tooltip.
        newMetric.tooltipDataKeys['report_number'] =  { name: 'Report Number' };

        metrics[metric.key] = newMetric;
        return newMetric;
    };

    var drawVesselGraph = function(vessel: FPVessel, fpScope: FPScope) {
        var metric = metrics[fpScope.pageMetric];
        var metricSeriesName = metric.options.name || fpScope.vesselList.label || '';
        var notAnalyzedName = metric.options.notAnalyzedName || metricSeriesName + ' (Not Analyzed)' || '';

        var dateTimeLabelFormats = {
            millisecond: '%b %e',
            second: '%b %e',
            minute: '%b %e',
            hour: '%b %e',
            day: '%b %e',
            week: '%b %e',
            month: '%b %e',
            year: '%b %e'
        };
        var timeseriesValues = vessel.timeseries;
        var notMatchedSeriesValues = vessel.notMatchedSeries;
        var notMatchedSeriesObject = {
            data: notMatchedSeriesValues,
            name: notAnalyzedName,
            zIndex: -1,
            marker: {
                enabled: true,
                symbol: 'circle'
            },
            color: Color.NEUTRAL,
            // type: metric.options.chartType,
            type: 'scatter',
            radius: 2,
            visible: !metric.options.hideUnmatchedByDefault,
        };

        // Get all Y values for this specific vessel's class in one array.
        let vesselsInClass = fpScope.fleetPerformance.vessels.filter((v: FPVessel) => v.vessel_class == vessel.vessel_class);
        var allYValuesForVesselClass = vesselsInClass.map((v: FPVessel) => [v.timeseries.mapKey(1), v.notMatchedSeries.mapKey(1)])
            .flatten()
            .filter((val: any) => val != undefined && val != null);
        // todo: occasionally we have supplemental metrics using the same yaxis chart values, we want to make sure these supplemental
        // metrics influence the axis ranges
        if (metric.key == 's') {
            allYValuesForVesselClass = vesselsInClass.map((v: FPVessel) => [
                v.timeseries.mapKey(1),
                v.notMatchedSeries.mapKey(1),
                v.a.mapKey(1), // include ais speed
            ])
            .flatten()
            .filter((val: any) => val != undefined && val != null);
        }

        // SET Y-MIN/Y-MAX
        var classYMax = undefined;
        var classYMin = undefined;

        if (metric.options.useClassBasedYMinMax) {
            classYMax = Math.max.apply(null, allYValuesForVesselClass);
            classYMin = Math.min.apply(null, allYValuesForVesselClass);
            // Round up or down to the nearest five
            classYMin = parseInt((classYMin - 5) / 5 as any) * 5;
            classYMax = parseInt((Math.floor(classYMax) + 5) / 5 as any) * 5;

            // Clamp by 3 SDs away from mean
            let mean = allYValuesForVesselClass.mean();
            let sd = allYValuesForVesselClass.standardDeviation();
            let meanPlus3sd = mean + (sd * 3);
            let meanMinus3sd = mean - (sd * 3);
            classYMax = Math.min(classYMax, meanPlus3sd);
            classYMin = Math.min(classYMin, meanMinus3sd);

            if (fpScope.pageMetric == 's') {
                // Cap ymax at 30 kn for speed
                classYMax = Math.min(classYMax, 30);
            }
            var getEEOIMax = function(vessel: FPVessel) {
                var gccClientId = '59271458941636628c3713fd';
                if (metric.key == 'e') {
                    return vessel.client_id == gccClientId ? 1000 : 10;
                }
                return undefined;
            };
            classYMax = getEEOIMax(vessel) || classYMax;
        }

        var yAxis: yAxis[] = [{
            min: 0,
            title: {
                text: metric.options?.yAxisName ? metric.options?.yAxisName : metricSeriesName + ' (' + fpScope.vesselList.unit + ')'
            },
            plotLines: [],
            visible: undefined,
        }];
        var xAxisPlotLines = [];

        var chartType = metric.options.chartType;

        var xMin = undefined;
        var xMax = undefined;

        var pointFormatterFunction = function() {
            var series = this.series;
            var value = roundToPlaces(this.y, 1);
            var unit = vessel.getMetricLabel(fpScope.vesselList.key)
            var tooltipMetricKey = Object.keys(metric.tooltipDataKeys).find(function(key) { return metric.tooltipDataKeys[key].name == series.name; });

            if (tooltipMetricKey) {
                var tooltip = metric.tooltipDataKeys[tooltipMetricKey];
                if (tooltip.valueFormatter) {
                    value = tooltip.valueFormatter(this.y);
                }
                unit = tooltip.unit || '';
            }

            var makeTooltipRowHTML = function(name: string, value: any, unit=undefined, colorOverride: string = null) {
                var color = <string>Color.BLACK;
                if (!themePalette.isDarkTheme()) {
                    color = value >= 0 ? series.color : series.userOptions && series.userOptions.negativeColor;
                }
                if (colorOverride) {
                    color = colorOverride;
                }
                color = color;
                return'<tr><td style="color:' + color + ';padding:0">' + name + ': </td>' +
                    '<td style="color:' + Color.BLACK + ';padding:0">' + value + (unit && unit[0] === '%' ? '' : ' ') + (unit || '') + '</td></tr>';
            };

            var tooltipHTML = '';
            if (['e', 'aer'].indexOf(metric.key) != -1 && series.name.indexOf('Trend') == -1) {
                if (vessel.voyagesByEndDates[this.x] != undefined) {
                    var voyageNumber = vessel.voyagesByEndDates[this.x].tres_voyage_number && vessel.voyagesByEndDates[this.x].tres_voyage_number.replace(/.*?(\d+)$/, '$1');
                    tooltipHTML += makeTooltipRowHTML(series.name, value, unit);
                    tooltipHTML += makeTooltipRowHTML('Tres Voyage Number', voyageNumber);
                    tooltipHTML += makeTooltipRowHTML('CO2', roundToPlaces(vessel.voyagesByEndDates[this.x].co2_emissions, 1), 'MT');
                    tooltipHTML += makeTooltipRowHTML('Transport Work', roundToPlaces(vessel.voyagesByEndDates[this.x].transport_work, 1), vessel.getMetricLabel('transport_work'));
                    // tooltipHTML += makeTooltipRowHTML('Cargo Quantity', roundToPlaces(vessel.voyagesByEndDates[this.x].cargo_quantity, 1), 'MT');
                    tooltipHTML += makeTooltipRowHTML('Observed Distance', roundToPlaces(vessel.voyagesByEndDates[this.x].total_obs_distance, 1), 'NM');
                    if (metric.key == 'aer' && vessel.voyagesByEndDates[this.x].fuel_consumption) {
                        tooltipHTML += makeTooltipRowHTML('Fuel Consumption', roundToPlaces(vessel.voyagesByEndDates[this.x].fuel_consumption, 1), 'MT');
                    }
                }
            } else if (series.type != 'arearange' && series.userOptions.disableTooltip != true) {
                tooltipHTML = makeTooltipRowHTML(series.name, value, unit);
            }

            if (metric.key == 'cii' && series.name.indexOf('Trend') == -1 && series.name.indexOf('Report Number') == -1 && series.type != 'arearange' && series.userOptions.disableTooltip != true) {
                const numOfDays = metric.options.movingAverageByPeriod;
                const vessel_cii_variable = 'cii_' + numOfDays + '_day_interval';
                if (vessel[vessel_cii_variable][this.x] != undefined) {
                    let cii_required = vessel[vessel_cii_variable][this.x].cii_required;
                    tooltipHTML = makeTooltipRowHTML(series.name, roundToPlaces(this.y, 2), 'g/MT.NM', '#1b2a36');
                    tooltipHTML += makeTooltipRowHTML('Attained CII, Period Avg', roundToPlaces(vessel.cii_attained, 2), 'g/MT.NM', '#1b2a36');
                    tooltipHTML += makeTooltipRowHTML('Required CII', roundToPlaces(cii_required, 2), 'g/MT.NM', '#1b2a36');
                    tooltipHTML += makeTooltipRowHTML('Difference Required CII', roundToPlaces((this.y - cii_required)/cii_required * 100, 1), ' %', '#1b2a36');
                    tooltipHTML += makeTooltipRowHTML('CO2', roundToPlaces(vessel[vessel_cii_variable][this.x].co2_emission, 1), 'MT', '#1b2a36');
                    tooltipHTML += makeTooltipRowHTML('Distance', roundToPlaces(vessel[vessel_cii_variable][this.x].total_obs_distance, 2), 'NM', '#1b2a36');
                }
            }

            if (series.name.indexOf('Trend') != -1 && metric.options.enableTrendLineTooltip) {
                // Add trend line tooltips
                var startPoint = series.data[0];
                var endPoint = series.data[series.data.length - 1];
                var yDiff = endPoint.y - startPoint.y;
                var days = (endPoint.x - startPoint.x) / 1000 / 60 / 60 / 24;
                var slopePer30Days = yDiff / days * 30;

                tooltipHTML += makeTooltipRowHTML('Trend Slope', roundToPlaces(slopePer30Days, 1), '% per 30d');
                tooltipHTML += makeTooltipRowHTML('Trend Start Date', moment(new Date(startPoint.x)).format('DD-MM-YYYY'));
                tooltipHTML += makeTooltipRowHTML('Trend Start Value', roundToPlaces(startPoint.y, 1), '%');
                tooltipHTML += makeTooltipRowHTML('Trend End Date', moment(new Date(endPoint.x)).format('DD-MM-YYYY'));
                tooltipHTML += makeTooltipRowHTML('Trend End Value', roundToPlaces(endPoint.y, 1), '%');
            }

            return tooltipHTML;
        };

        // MAIN METRIC SERIES
        var series = [];
        var dryDockings = vessel.dry_dockings;
        var splitDates = dryDockings.map(function(dd: any) { return new Date(dd.date); });
        var splitSeries = SpeedPerformanceService.splitSeriesByDates(timeseriesValues, splitDates);
        angular.forEach(splitSeries, function(data, i) {
            // Add a trend line to each series
            var graphNumber = splitSeries.length > 1 ? ' ' + (i + 1) : '';
            var s = {
                data: data,
                type: chartType,
                color: metric.options.color || themePalette.isLightTheme() && Color.BLACK || Color.GRAPH_BLUE,
                threshold: metric.options.threshold,
                negativeColor: metric.options.negativeColor,
                name: metricSeriesName + graphNumber,
                connectNulls: true,
                zIndex: 10,
                marker: { enabled: true, symbol: 'circle' },
                id: 'timeseries',
                regression: undefined,
                regressionSettings: undefined,
            };
            if (metric.options.drawTrendLine) {
                s.regression = true;
                s.regressionSettings = {
                    name: metricSeriesName + ' Trend' + graphNumber,
                    type: 'linear',
                    dashStyle: 'Dash',
                    color: metric.options.regressionColor || themePalette.colors.DATA_18,
                    hover: { enabled: true, halo: { size: 0 } },
                    marker: { enabled: false, states: { hover: { enabled: false } } },
                    allowPointSelect: false,
                    animation: true,
                    animationLimit: 0,
                    enableMouseTracking: false,
                    zIndex: 101,
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormatter: pointFormatterFunction,
                        footerFormat: '</table>',
                        shared: false,
                        useHTML: true
                    },
                };
            }
            series.push(s);
        });
        xAxisPlotLines = xAxisPlotLines.concat(SpeedPerformanceService.buildPlotLines(dryDockings));

        // EEOI && AER MAIN METRIC SERIES
        var eeoiYMax = null;
        var eeoiYMin = null;
        
        if (['e', 'aer', 'cii'].indexOf(fpScope.vesselList.key) != -1) {
            var effiencyListLabel = {'e': 'eeoi', 'aer': 'aer', 'cii': 'cii'}
            var vesselAttribute = effiencyListLabel[fpScope.vesselList.key];
            var data = vessel[vesselAttribute + 'GraphData'];
            if (['e', 'aer'].indexOf(fpScope.vesselList.key) != -1) {
                eeoiYMax =  Math.max(data.mapKey(1).max(), vessel[vesselAttribute +'_quartiles'].upper);
                eeoiYMin = Math.max(Math.min(data.mapKey(1).min(), vessel[vesselAttribute +'_quartiles'].lower), 0);
            }
            

            series = [{
                color: metric.options.color || themePalette.colors.THEME_TEXT_COLOR,
                threshold: metric.options.threshold,
                negativeColor: metric.options.negativeColor,
                name: metricSeriesName,
                connectNulls: true,
                zIndex: 10,
                marker: { enabled: true, symbol: 'circle' },
                data: data,
                regression: undefined,
                regressionSettings: undefined,
            }];
            if (metric.options.drawTrendLine) {
                series[0].regression = true;
                series[0].regressionSettings = {
                    name: metricSeriesName + ' Trend',
                    type: 'linear',
                    dashStyle: 'Dash',
                    color: metric.options.regressionColor || themePalette.colors.DATA_18,
                    hover: { enabled: true, halo: { size: 0 } },
                    marker: { enabled: false, states: { hover: { enabled: false } } },
                    allowPointSelect: false,
                    animation: true,
                    animationLimit: 0,
                    enableMouseTracking: false,
                    zIndex: 101,
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormatter: pointFormatterFunction,
                        footerFormat: '</table>',
                        shared: false,
                        useHTML: true
                    },
                };
            }
        }
        // Plot bands
        if (metric.options.plotDynamicBands) {
            let plotBandSeries = metric.plotDynamicBands(vessel)
            plotBandSeries.forEach(function(bandSeries) {
                var dynamicBandSeries = {
                    name: bandSeries.name,
                    color: bandSeries.color,
                    type: 'arearange',
                    data: bandSeries.data,
                    label: { enabled: true },
                    tooltip: { enabled: false, },
                    hover: { enabled: false, halo: { size: 0 } },
                    marker: { enabled: false, states: { hover: { enabled: false, } } },
                    states: { hover: { enabled: false, lineWidthPlus: 0, } },
                    animation: false,
                    animationLimit: 0,
                    showInLegend: true,
                    zIndex: 0,
                };
                series.push(dynamicBandSeries);
            })
        }

        // NOT MATCHED SERIES
        if (!metric.options.alwaysMatch && ['e', 'aer'].indexOf(metric.key) == -1) {
            series.push(notMatchedSeriesObject);
        }

        // SUPPLEMENTAL SERIES
        if (metric.options.plotSupplementalData) {
            angular.forEach(Object.keys(metric.supplementalData), function(key) {
                var supplementalSeries = metric.supplementalData[key];
                var defaults = {
                    data: vessel[key],
                    zIndex: 2
                };
                series.push(Object.assign({}, defaults, supplementalSeries));
            });
        }

        // Y AXES
        angular.forEach(metric.extraYAxes, function(axis) {
            yAxis.push(axis);
        });
        // Push hidden tooltip yAxis
        yAxis.push({
            visible: false,
            min: undefined,
            title: { text: undefined },
            plotLines: undefined,
        });

        // TOOLTIP DATA SERIES
        if (['e', 'aer', 'cii'].indexOf(metric.key) == -1) {
            angular.forEach(Object.keys(metric.tooltipDataKeys), function(key) {
                var tooltipSeries = {
                    name: metric.tooltipDataKeys[key].name,
                    color: Color.GRAPH_BLUE,
                    chart: { type: 'line' },
                    data: vessel[key],
                    label: { enabled: false },
                    tooltip: { enabled: true, },
                    hover: { enabled: false, halo: { size: 0 } },
                    marker: { enabled: false, states: { hover: { enabled: false, } } },
                    states: { hover: { enabled: false, lineWidthPlus: 0, } },
                    animation: false,
                    animationLimit: 0,
                    showInLegend: false,
                    yAxis: yAxis.length - 1,
                };
                series.push(tooltipSeries);
            });
        }

        // PLOT LINES
        yAxis[0].plotLines = yAxis[0].plotLines.concat(metric.plotLines(vessel));
        xAxisPlotLines = xAxisPlotLines.concat(metric.xAxisPlotLines(vessel));

        // MOVING AVERAGE
        if (metric.options.movingAverage > 0) {
            vessel.movingAverageData = timeseriesValues.map(function(point, i) {
                var previousNelements = timeseriesValues.slice(Math.max(i - metric.options.movingAverage, 0), i);
                var values = previousNelements.mapKey(1);
                var avg = values.mean();
                return [point[0], avg];
            });
            series.push({
                color: Color.GRAPH_BLUE,
                data: vessel.movingAverageData,
                hover: { enabled: false, halo: { size: 0 } },
                name: 'Moving ' + metric.options.movingAverage + '-Report Average',
                marker: { enabled: false, states: { hover: { enabled: false } } },
                zIndex: 100,
                lineWidth: 2,
            });
        }

        // SFOC Deviation Graph
        if (fpScope.vesselList.key == 'sf_d') {
            vessel.overhaulDates = vessel.dry_dockings
                .filter(function(dd: any) { return dd.me_over_haul; })
                .map(function(dd: any) { return new Date(dd.date); });

            series = [];
            yAxis = [{
                min: classYMin,
                max: classYMax,
                title: {
                    text: 'SFOC Model Deviation (%)'
                },
                plotLines: []
            }];

            var colors = [Color.GRAPH_BLUE, Color.DATA_1, Color.DATA_2, Color.DATA_3, Color.DATA_4, Color.DATA_5];
            var fuelGradeInUseMainEngineByTimestamp = vessel.fuel_grade_in_use_main_engine.reduce(function(acc, val) {
                if (val[1]) {
                    acc[val[0]] = val[1];
                }
                return acc;
            }, {});
            var grouped = vessel.timeseries.groupBy(function(pt: Point) { return fuelGradeInUseMainEngineByTimestamp[pt[0]]; });

            angular.forEach(grouped, function(group) {
                if (!group.some((pt: Point) => pt[1])) return;
                var color = colors.shift();
                var fuelGradeDisplay = fuelGradeMapping[fuelGradeInUseMainEngineByTimestamp[group[0][0]]];
                var makeRow = function(label: string, value: any, unit='') {
                    var unitText = value != undefined && unit ? ' ' + unit : '';
                    if (label || value) {
                        return '<tr><td style="color:' + color + ';padding:0">' + (label || '') + ': </td>' +
                            '<td style="color:' + Color.NEUTRAL + ';padding:0"><b>' + (value || '-') + unitText + '</b></td></tr>';
                    } else {
                        return '<tr></tr>';
                    }
                };
                series.push({
                    data: group,
                    name: fuelGradeDisplay + ' ME SFOC Dev. (LCV 42700)',
                    type: 'scatter',
                    width: 1,
                    color: color,
                    zIndex: 2,
                    marker: {
                        symbol: 'circle'
                    },
                    tooltip: {
                        headerFormat: '<span style="font-weight: bold;">' + fuelGradeDisplay + '</span><table>',
                        pointFormatter: function() {
                            var xCoord = this.x;
                            var sfocDev = vessel.timeseries.find(function(pt) { return pt[0] == xCoord; })[1];
                            var reportedSfoc = vessel.sf_c.find(function(pt) { return pt[0] == xCoord; })[1];
                            var modelSfoc = vessel.sf_m.find(function(pt) { return pt[0] == xCoord; })[1];
                            var meLoad = vessel.me_load.find(function(pt) { return pt[0] == xCoord; })[1];
                            var tankName = vessel.tank_name.find(function(pt) { return pt[0] == xCoord; })[1];
                            var bunkerPort = vessel.bunker_port.find(function(pt) { return pt[0] == xCoord; })[1];
                            var bunkerDateStr = vessel.bunker_date.find(function(pt) { return pt[0] == xCoord; })[1];
                            var bunkerDateFormatted = bunkerDateStr ? moment(bunkerDateStr).format('DD/MM/YYYY HH:mm') : '-';

                            return [
                                makeRow('SFOC Dev.', sfocDev, '%'),
                                makeRow('Reported', reportedSfoc, 'g/kWh'),
                                makeRow('Model', modelSfoc, 'g/kWh'),
                                makeRow('ME Load', meLoad, '%'),
                                makeRow('Tank', tankName),
                                makeRow('Bunker Port', bunkerPort),
                                makeRow('Bunker Date', bunkerDateFormatted),
                            ].join('');
                        },
                        footerFormat: '</table>'
                    },
                    regression: true,
                    regressionSettings: {
                        name: fuelGradeDisplay + ' Linear (ME SFOC Dev. (LCV 42700))',
                        type: 'linear',
                        dashStyle: 'Dash',
                        color: color,
                        tooltip: {
                            enableMouseTracking: true,
                            enabled: true,
                            headerFormat: '<span style="font-weight: bold;">Trend</span><table>',
                            pointFormatter: function() {
                                var series = this.series;

                                var startSfoc = series.yData[0];
                                var startDate = new Date(series.xData[0]);
                                var endSfoc = series.yData[series.yData.length - 1];
                                var endDate = new Date(series.xData[series.xData.length - 1]);
                                var sfocDiff = endSfoc - startSfoc;
                                var daysDiff = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24;
                                var thirtyDaysDiff = sfocDiff / daysDiff * 30;

                                return [
                                    makeRow('SFOC Dev. / 30 Day', roundToPlaces(thirtyDaysDiff, 1), '%'),
                                    makeRow('Start Date', startDate.toLocaleDateString()),
                                    makeRow('Start SFOC Dev', roundToPlaces(startSfoc, 1), '%'),
                                    makeRow('End Date', endDate.toLocaleDateString()),
                                    makeRow('End SFOC Dev', roundToPlaces(endSfoc, 1), '%'),
                                ].join('');
                            },
                            footerFormat: '</table>',
                            split: true
                        },
                        hover: {
                            enabled: true,
                            halo: {
                                size: 0
                            }
                        },
                        marker: {
                            enabled: false,
                            states: { hover: { enabled: false } }
                        },
                        allowPointSelect: false,
                        animation: true,
                        animationLimit: 0,
                        enableMouseTracking: false,
                    }
                });
            });

            xAxisPlotLines = vessel.overhaulDates.map(function(date) {
                var text = 'Engine Overhaul: ' + date.toLocaleDateString();

                return {
                    color: Color.YELLOW,
                    dashStyle: 'solid',
                    width: 1,
                    value: date,
                    label: {
                        rotation: 0,
                        verticalAlign: 'bottom',
                        y: -6,
                        text: text,
                    }
                };
            });
        }

        // Enforce a minimum classYMin of 0 for all metrics except deviations
        if (yAxis[0]) {
            if (['s', 'sf_d', 'tcrpm_deviation', 'cp_consumption_deviation', 'cp_speed_difference'].indexOf(fpScope.vesselList.key) != -1) {
                yAxis[0].min = classYMin;
                yAxis[0].max = classYMax;
            } else {
                yAxis[0].min = eeoiYMin || (classYMin ? Math.max(classYMin, 0) : classYMin);
                yAxis[0].max = eeoiYMax || (classYMax);
            }
            angular.forEach(yAxis, function(axis) {
                var yDiff = classYMax - classYMin;
                if (yDiff > 75) {
                    axis.tickInterval = 20;
                } else if (yDiff > 25) {
                    axis.tickInterval = 10;
                } else if (yDiff > 5) {
                    axis.tickInterval = 5;
                } else {
                    axis.tickInterval = 1;
                }
            });
        }

        // Speed Performance Graphs
        if (fpScope.vesselList.key == 'sp') {
            var chartId = 'metric-breakdown-' + vessel.vessel_id;
            vessel.timestampsToReportNumbers = vessel.report_number.reduce(function(obj, point) { obj[point[0]] = point[1]; return obj; }, {});

            let mapReportNumbers = (data: Point[]): [number, number, number][] => {
                return data.map(function(dataPoint: Point) {
                    var reportNumber = vessel.timestampsToReportNumbers[dataPoint[0]];
                    return [dataPoint[0], dataPoint[1], reportNumber];
                });
            }

            vessel.speed_performance_lifetime_data = mapReportNumbers(timeseriesValues);
            vessel.speed_performance_not_matched_data = mapReportNumbers(notMatchedSeriesValues);

            newChart = SpeedPerformanceService.plotChart(
                timeseriesValues,
                vessel.dry_dockings,
                vessel.speed_performance_lifetime_data,
                chartId,
                {
                    chart: {
                        zoomType: 'xy',
                        marginTop: 80,
                        type: 'scatter',
                    },
                    subtitle: {
                        align: 'left',
                        text: 'Speed Performance (%)'
                    }
                }
            );
            newChart.addSeries({
                data: vessel.speed_performance_not_matched_data,
                keys: ['x','y','reportNumber'],
                name: notAnalyzedName,
                zIndex: -1,
                marker: {
                    enabled: true,
                    symbol: 'circle'
                },
                color: Color.NEUTRAL,
                type: 'scatter',
                radius: 3,
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>'
                },
            });
        } else {
            var newChart = Highcharts.chart('metric-breakdown-' + vessel.vessel_id, {
                chart: {
                    zoomType: 'xy',
                    marginTop: 80,
                    alignTicks: false,
                },
                title: { text: null },
                subtitle: {
                    align: 'left',
                    text: metricSeriesName,
                },
                xAxis: {
                    min: xMin,
                    max: xMax,
                    startOnTick: true,
                    endOnTick: true,
                    type: 'datetime',
                    dateTimeLabelFormats: dateTimeLabelFormats,
                    plotLines: xAxisPlotLines,
                },
                yAxis: yAxis,
                legend: { enabled: true },
                credits: { enabled: false },
                exporting: {
                    buttons: {
                        exportButton: {
                            enabled: false
                        },
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormatter: pointFormatterFunction,
                    footerFormat: '</table>',
                    shared: !metric.options.enableTrendLineTooltip,
                    useHTML: true
                },
                plotOptions: $.extend(true, {
                    series: {
                        connectNulls: false,
                        states: { hover: { lineWidthPlus: metric.options.lineWidth == 0 ? 0 : 1, } },
                    },
                    line: {
                        lineWidth: metric.options.lineWidth != undefined ? metric.options.lineWidth : 2,
                        states: { hover: { lineWidthPlus: metric.options.lineWidth == 0 ? 0 : 1, } },
                    },
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        stacking: metric.options.columnStacking,
                        grouping: metric.options.grouping,
                    }
                }, metric.plotOptions),
                states: { hover: { lineWidthPlus: metric.options.lineWidth == 0 ? 0 : 1, } },
                series: series.concat(metric.extraSeries)
            });
        }

        // HIDE TOOLTIP SERIES GRAPHS
        angular.forEach(Object.keys(metric.tooltipDataKeys), function(key) {
            var seriesName = metric.tooltipDataKeys[key].name;
            var series = newChart.series.find(function(s: any) { return s.name == seriesName; });
            if (series) {
                series.graph && series.graph.hide();
                series.group && series.group.hide();
            }
        });
        // LINE WIDTH
        if (metric.options.lineWidth == 0) {
            newChart.options.states.hover.lineWidthPlus = 0;
        }

        // CHART EFFECTS
        metric.chartEffects(newChart, vessel);

        fpScope.chartsList.push(newChart);
    };

    return {
        drawVesselGraph: drawVesselGraph,
        buildMetric: buildMetric,
        getMetric: getMetric,
    };
}]);
