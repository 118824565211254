import Highcharts from 'highcharts';
import moment from 'moment';
import angular from 'angular';
import { routerApp, analyticsUrl, roundToPlaces, setTankGroupColors, getMEPerformanceData, themePalette } from '../../app.module';

routerApp.controller('dashboardController', ['$rootScope', '$scope', '$http', '$timeout', 'TrafficSystemService', 'PageState', 
    function($rootScope, $scope, $http, $timeout, TrafficSystemService, PageState) {

    $rootScope.selectedMenu = 'dashboard';
    $scope.pageState = new PageState();

    $scope.dateRanges = ['last_report', '10_days', '1_month', '2_month', '3_month', 'ytd'];
    $scope.setDashboardRange = function(range) {
        $scope.selectedDateRange = range;
        $scope.refreshDashboard();
    }
    $scope.selectedDateRange = '3_month';

    // trigger state for loading of data
    $scope.pageState.setLoadingData();

    // trigger setting of states
    $scope.$watchGroup(['allData', 'vesselParticulars', 'mePerformance'], function(n, o, scope) {
        if (n[0] != undefined && n[1] != undefined && n[2] != undefined) {
            scope.pageState.setDataLoaded();
        }
    });

    $scope.n_idle_reports = 15;
    $scope.n_sea_reports = 15;

    // get dashboard data
    $http({
        url: analyticsUrl + $rootScope.selectedVessel.id + "/dashboardData",
        method: "GET",
        params: {
            n_idle_reports: $scope.n_idle_reports,
            n_sea_reports: $scope.n_sea_reports,
        }
    }).then(function(response) {
        var data = response.data;

        $scope.allData = data.data;
        $scope.tankDetails = data.data.tank_details;
        $scope.dashboardData = $scope.allData['breakdown'][$scope.selectedDateRange];

        if ($scope.allData && $scope.allData['breakdown'] && $scope.allData['breakdown'][$scope.selectedDateRange]) {
            var kpiValue = $scope.allData['breakdown'][$scope.selectedDateRange]['kpi'];
            var kpiColor = TrafficSystemService.getKPIColor(kpiValue);
            $scope.drawStatsKnob("#kpiKnob", $scope.allData['breakdown'][$scope.selectedDateRange]['kpi'], true, kpiColor);
        } else {
            $scope.pageState.setNoData();
        }

    });
    // get vessel performance
    $http({
        url: analyticsUrl + $rootScope.selectedVessel.id + "/test?variables=consumption.total_me_consumption_24",
        method: "GET"
    }).then(function(response) {
        var data = response.data;
        $scope.meData = data.data;
        var dateRange = $scope.selectedDateRange;
        if ($scope.meData && $scope.meData[dateRange]) {
            $scope.mePerformance = getMEPerformanceData($scope.meData[dateRange]);
        }
    });
    // get vessel particulars
    $http({
        url: analyticsUrl + $rootScope.selectedVessel.id + "/vesselParticulars",
        method: "GET"
    }).then(function(response) {
        var data = response.data;
        $scope.vesselParticulars = data.data;
    });

    $scope.disableFuelTypeItem = function (index) {
        return parseInt(index) === 0 && $scope.tankSpecificGrades.length > 1
    }

    $scope.$watchGroup(['dashboardData', 'mePerformance', 'dashboardData.tankDetails'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined && newValues[1] != undefined) {
            $timeout(function() {
                setTankGroupColors($scope.tankDetails.tanks);
                $scope.percentDaysAtSea = Math.round($scope.tankDetails.percent_report_period.sea_reports * 100);
                $scope.percentGrades = {}
                var adjustedSpecificTotal = 0

                $scope.tankDetails.specific_grades.types.forEach((tankGrade) => {
                    if ($scope.percentGrades[tankGrade] === undefined) {
                        $scope.percentGrades[tankGrade] = {
                            adjustedIdle: false,
                            adjustedSea: false,
                            percent: false
                        }
                    }
                    $scope.percentGrades[tankGrade]['adjustedSea'] = $scope.tankDetails.specific_grades.sea_consumption_rate[tankGrade] * $scope.percentDaysAtSea / 100;
                    $scope.percentGrades[tankGrade]['adjustedIdle'] = $scope.percentDaysAtSea < 100 ? $scope.tankDetails.specific_grades.idle_consumption_rate[tankGrade] * (100 - $scope.percentDaysAtSea) / 100 : 0;
                    adjustedSpecificTotal += ($scope.percentGrades[tankGrade]['adjustedSea'] + $scope.percentGrades[tankGrade]['adjustedIdle'])
                })

                $scope.tankDetails.specific_grades.types.forEach((tankGrade) => {
                    $scope.percentGrades[tankGrade]['percent'] = adjustedSpecificTotal != 0 ? Math.round(($scope.percentGrades[tankGrade]['adjustedIdle'] + $scope.percentGrades[tankGrade]['adjustedSea']) / adjustedSpecificTotal * 100) : 0;
                })
                scope.drawMePerformance();
            });
        }
    });

    $scope.refreshDashboard = function() {
        // refresh numbers up top
        $scope.dashboardData = $scope.allData['breakdown'][$scope.selectedDateRange];

        var kpiValue = $scope.allData['breakdown'][$scope.selectedDateRange]['kpi'];
        var kpiColor = TrafficSystemService.getKPIColor(kpiValue);
        $scope.drawStatsKnob("#kpiKnob", kpiValue, true, kpiColor);

        if ($scope.selectedDateRange == 'last_report') {
            $scope.mePerformance = [];
            var lastIndex = $scope.meData['3_month']['timestamp'].length - 1;
            if (lastIndex > 0) {
                $scope.mePerformance = [
                    [
                        $scope.meData['3_month']['timestamp'][lastIndex],
                        $scope.meData['3_month']['total_me_consumption_24'][lastIndex]
                    ]
                ];
            }
        } else {
            $scope.mePerformance = getMEPerformanceData($scope.meData[$scope.selectedDateRange]);
        }

        $scope.drawMePerformance();
    }
    $scope.drawStatsKnob = function(element, value, double, fgColor) {
        $(element).knob({
            'readOnly': true,
            'draw': function() {
                // @ts-ignore
                $(this.i)
                    .css('font-size', '32px')
                    .css('font-weight', 'normal')
                    .css('font-family', 'inherit')
                    .css('margin-top', '38px');
                if (double != undefined && double == true) {
                    this.cursorExt = 0.3;

                    // @ts-ignore
                    var a = this.arc(this.cv)  // Arc
                        , pa                   // Previous arc
                        , r = 1;

                    // @ts-ignore
                    this.g.lineWidth = this.lineWidth;
                    // @ts-ignore
                    pa = this.arc(this.v);
                    // @ts-ignore
                    this.g.lineWidth = 5;
                    // @ts-ignore
                    this.g.beginPath();
                    // @ts-ignore
                    this.g.strokeStyle = r ? this.o.fgColor : this.fgColor ;

                    // @ts-ignore
                    this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, pa.s, pa.e, pa.d);
                    // @ts-ignore
                    this.g.stroke();
                }
            },
            'fgColor': fgColor
        });
        $(element).val(value).trigger('change');
        $(element).trigger(
            'configure',
            {
                'fgColor': fgColor,
                'inputColor': fgColor
            }
        );
    };

    $scope.calculateTankForecast = function() {
        if (!$scope.tankDetails) return;

        var getDaysUntilEmpty = function(currentStock, rate24, otherTanksInUse) {
            var days = 0;
            otherTanksInUse.map(function(t) { t.tempStock = t.rob; });
            while (currentStock > 0.001 && rate24 > 0.001) {
                var perTankRate = rate24 / (otherTanksInUse.length + 1);
                var fuelConsumed = Math.min(currentStock, perTankRate);
                currentStock -= fuelConsumed;
                angular.forEach(otherTanksInUse, function(t) { t.tempStock -= perTankRate; });
                var daysConsumed = fuelConsumed / perTankRate;
                days += daysConsumed;
                otherTanksInUse = otherTanksInUse.filter(function(t) { return t.tempStock > 0.001; });
            }
            return days;
        };

        var specificAllTanks = []
        $scope.tankDetails.specific_grades.types.forEach((fuelType) => {
            var currentTanks = $scope.tankDetails.tanks.filter(function (tank) { return tank.fuel_grade === fuelType });
            currentTanks.sort(function (a, b) { return a.order_of_use > b.order_of_use ? 1 : -1; });
            specificAllTanks = specificAllTanks.concat(currentTanks)
        })

        $scope.tankDetails.tanks = specificAllTanks;

        var daysOfFuel = {};
        $scope.consumption_rates = { eca: 0, non_eca: 0 };

        $scope.tankDetails.specific_grades.types.forEach((type) => {
            $scope.consumption_rates[type] = 0
        })

        var START_DATE = new Date();
        START_DATE.setHours(12);
        START_DATE.setMinutes(0);
        START_DATE.setSeconds(0);

        var isMainFuelTank = function(tank) {
            return !tank.name.match(/SERV|SETT|OVERFLOW/i);
        };

        var addedInUseTankDays = false;
        $scope.fuelTypeToECA = {}
        angular.forEach($scope.tankDetails.tanks, (tank) => {
            var seaConsumptionTotals = 0
            var idleConsumptionTotals = 0
            $scope.tankDetails.specific_grades.types.forEach((type) => {
                seaConsumptionTotals += $scope.tankDetails.specific_grades.sea_consumption_rate[type]
                idleConsumptionTotals += $scope.tankDetails.specific_grades.idle_consumption_rate[type]
            })
            var seaConsRate = seaConsumptionTotals * ($scope.percentGrades[tank.fuel_grade].percent / 100);
            var idleConsRate = idleConsumptionTotals * ($scope.percentGrades[tank.fuel_grade].percent / 100);

            var allConsRate = idleConsRate * ((100 - $scope.percentDaysAtSea) / 100) + seaConsRate * ($scope.percentDaysAtSea / 100);
            var otherTanksInUse = tank.in_use ? $scope.tankDetails.tanks.filter(function (t) { return t != tank && t.in_use && t.fuel_grade.startsWith(tank.fuel_grade.slice(0, 2)); }) : [];
            var days_until_empty = getDaysUntilEmpty(tank.rob, allConsRate, otherTanksInUse);
            tank.days_until_empty = days_until_empty;
            var days_at_sea_until_empty = getDaysUntilEmpty(tank.rob, seaConsRate, otherTanksInUse);

            tank.seaConsRate = seaConsRate;
            tank.idleConsRate = idleConsRate;
            tank.consumption_rate = allConsRate;

            if (isMainFuelTank(tank)) {
                $scope.consumption_rates[tank.fuel_grade] = roundToPlaces(allConsRate, 1);


                var ecaType = tank.sulphur_after_bunkering > 0.1 ? 'non_eca' : 'eca';
                $scope.fuelTypeToECA[tank.fuel_grade] = ecaType;
            }

            if (tank.consumption_rate !== 0 && tank.percent_filled >= 2) {
                if (tank.in_use) {
                    if (!addedInUseTankDays) {
                        var days = otherTanksInUse.concat([tank]).map(function (t) { return t.days_until_empty; }).max();
                        daysOfFuel[tank.fuel_grade.slice(0, 2)] += (days || 0);
                        addedInUseTankDays = true;
                    }
                } else {
                    daysOfFuel[tank.fuel_grade.slice(0, 2)] += (days_until_empty || 0);
                }
                tank.expected_date_empty = moment(START_DATE.addDays(tank.in_use ? days_until_empty : daysOfFuel[tank.fuel_grade.slice(0, 2)])).format('DD-MM-YYYY');
                tank.days_at_sea_until_empty = days_at_sea_until_empty;
            } else {
                tank.days_until_empty = NaN;
                tank.expected_date_empty = '-';
                tank.days_at_sea_until_empty = NaN;
            }
        })

        $scope.tankDetails.specific_grades.types.forEach((fuelType) => {
            $scope.consumption_rates[$scope.fuelTypeToECA[fuelType]] += $scope.consumption_rates[fuelType]
        })

        var getXIntercept = function(data) {
            var point1 = data[data.length - 2];
            var point2 = data[data.length - 1];
            if (!point1 && !point2) return;

            var x1 = point1[0];
            var y1 = point1[1];
            var x2 = point2[0];
            var y2 = point2[1];
            var m = (y2 - y1) / (x2 - x1);
            var b = y1 - (m * x1);
            var xInt = -b / m;
            return xInt;
        };

        $scope.tankSpecificGrades = $scope.tankDetails.specific_grades.types
        $scope.tankForecastSeries = []

        $scope.tankSpecificGrades.forEach((tankGrade) => {
            var date = START_DATE;
            var gradeTanks = $scope.tankDetails.tanks.filter(function (tank) { return tank.fuel_grade === tankGrade });
            var hasGradeTanks = gradeTanks.length > 0
            gradeTanks.sort(function (a, b) { return a.order_of_use > b.order_of_use ? 1 : -1; });

            var tempForecastDetails = {
                name: $scope.formatFuelGrade(tankGrade),
                data: [],
                marker: { symbol: 'circle', enabled: true, radius: 2 },
            }

            var totalStock = $scope.tankDetails.specific_grades.total_stock[tankGrade] || 0
            while (totalStock > 0.001 && gradeTanks[0].consumption_rate > 0.001) {
                tempForecastDetails.data.push([date.getTime(), roundToPlaces(totalStock, 1)]);
                date = date.addDays(1);
                var cons_rate = moment($scope.tankDetails.eta) > moment(date) ? gradeTanks[0].seaConsRate : gradeTanks[0].consumption_rate;
                totalStock -= hasGradeTanks ? cons_rate : 0;
            }
            if (totalStock < 0 && tempForecastDetails.data.length > 1) {
                tempForecastDetails.data.push([getXIntercept(tempForecastDetails.data), 0]);
            } else {
                tempForecastDetails.data.push([date.getTime(), roundToPlaces(totalStock, 1)]);
            }

            $scope.tankForecastSeries.push(tempForecastDetails)

        })
    };

    $scope.$watch('percentGrades', function () {
        if ($scope.tankSpecificGrades && $scope.tankSpecificGrades.length > 1) {
            var otherGradesPercentTotal = 0
            $scope.tankSpecificGrades.forEach((item, index) => {
                if (index > 0) {
                    otherGradesPercentTotal += $scope.percentGrades[item].percent
                }
            })
            $scope.percentGrades[$scope.tankSpecificGrades[0]].percent = Math.round(100 - otherGradesPercentTotal)
        }

        $scope.calculateTankForecast();
        $scope.drawTankForecast();
    }, true);

    $scope.$watch('percentDaysAtSea', function () {
        $scope.calculateTankForecast();
        $scope.drawTankForecast();
    });

    // draw sfoc graph
    $scope.drawMePerformance = function() {
        $scope.mePerformanceChart = Highcharts.chart('main-engine-consumption-container', {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: null,
            },
            subtitle: {
                text: "ME Consumption / 24 hours"
                // text: "Norient Pool Point Reference Comparison"
            },
            xAxis: {
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat('%b %e', this.value);
                    }
                },
                type: 'datetime'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Main Engine Consumption (MT)'
                }
            },
            legend: {
                enabled: false
            },
            credits: {
              enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'Consumption',
                data: $scope.mePerformance,
                threshold: 0.85,
                width: 1,
                color: '#5195d0',
                negativeColor: 'rgb(240, 192, 0)'
            }]
        });

        $scope.calculateTankForecast();
        $scope.drawTankForecast();

        $timeout(function() {
            $scope.mePerformanceChart.reflow();
            $scope.tankForecastChart.reflow();
        }, 0);
    };

    $scope.drawTankForecast = function() {
        if (!$scope.tankForecastSeries) return;

        var lastDataPointDate = $scope.tankForecastSeries.map(function(series) { return series.data.map(function(data) { return data[0]; }); }).flatten().max();

        $scope.tankForecastChart = Highcharts.chart('chart-tank-forecast', {
            chart: { type: 'line' },
            title: { text: null, },
            credits: { enabled: false },
            exporting: { enabled: false },
            subtitle: { text: 'ROB Development (MT)' },
            yAxis: {
                title: {
                    enabled: true,
                    text: 'Fuel ROB (MT)'
                },
                min: 0,
            },
            xAxis: {
                max: Math.min(moment().add(6, 'months').toDate().getTime(), lastDataPointDate),
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat('%b %e', this.value);
                    }
                },
                type: 'datetime',
                plotLines: [{
                    color: '#f0c000',
                    dashStyle: 'solid',
                    width: 1,
                    value: $scope.tankDetails.eta ? moment($scope.tankDetails.eta).utc(true).toDate().getTime() : undefined,
                    label: {
                        rotation: 0,
                        verticalAlign: 'bottom',
                        y: -6,
                        text: "ETA: " + moment($scope.tankDetails.eta).format('DD/MM/YYYY'),
                    }
                }], 
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                xDateFormat: '%b %e, %Y',
                pointFormatter: function() {
                    var totalValue = roundToPlaces(this.y, 1);
                    return '<tr><td style="color:' + this.series.color + ';padding:0">' + this.series.name + ': </td>' +
                        '<td style="padding:0"><b>' + totalValue + ' MT</b></td></tr>';
                },
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            colors: [
                themePalette.colors.DATA_11,
                themePalette.colors.DATA_12,
                themePalette.colors.DATA_13,
                themePalette.colors.DATA_14,
                themePalette.colors.DATA_15,
                themePalette.colors.DATA_6
            ],
            series: $scope.tankForecastSeries,
        });
    };

    $scope.$watchGroup(['n_sea_reports', 'n_idle_reports'], function(newValues, oldValues) {
        if (newValues[0] != oldValues[0] || newValues[1] != oldValues[1]) {
            $http({
                url: analyticsUrl + $rootScope.selectedVessel.id + "/dashboardData",
                method: "GET",
                params: {
                    n_idle_reports: $scope.n_idle_reports,
                    n_sea_reports: $scope.n_sea_reports,
                }
            }).then(function(response) {
                var data = response.data;
                $scope.tankDetails = data.data.tank_details;
            });
        }
    });

    $scope.formatFuelGrade = function(fuelGrade) {
        // E.g., 'hshfo' => 'HS HFO'
        return fuelGrade.toUpperCase().replace(/^(HS|LS|ULS)(...?)/, '$1 $2');
    };

}]);
