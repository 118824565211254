import Highcharts from 'highcharts';
import { routerApp } from '../app.module';

routerApp.controller('modelsDashboardController', [ '$rootScope', '$scope', '$http', '$timeout', 'ModelsApiService',function($rootScope, $scope, $http, $timeout, ModelsApiService) {

    console.log("Initializing: Models Dashboard");
    ModelsApiService.getAllModels().then(function(response) {
        // var data = response.data;
        var data = [];
        for (let i = 0; i<20; i++) {

            data.push([i, 0.035 * i * i + 5]);
        }
        Highcharts.chart('power-consumption', {
            title: {
                text: 'Power Consumption at 8m Draught',
                x: -20 //center
            },
            subtitle: {
                text: null
            },
            credits: {

                enabled: false
            },
                xAxis: {
                title: {
                    text: 'Speed (Knots)'
                },
                tickInterval: 1
            },
            yAxis: {
                title: {
                    text: 'Power Consumption (kW)'
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }],
                max: 40
            },
            tooltip: {
                valueSuffix: ',000 kW'
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Power Consumption',
                data: data
            }]
        });

    data = [];
        for (let i = 8; i<20; i+=0.5) {


            data.push(0.15 * i * i + 5);
        }
    Highcharts.chart('fuel-consumption', {
        title: {
            text: 'Fuel Consumption at 8m Draught',
            x: -20 //center
        },
        subtitle: {
            text: null
        },
        credits: {

        	enabled: false
        },
			xAxis: {
            title: {
                text: 'Speed (Knots)'
            },
            tickInterval: 1,
            min: 0,
            max: 22
        },
        yAxis: {
            title: {
                text: 'Fuel Consumption (MT)'
            },
            plotLines: [{
                value: 0,
                width: 1,
                color: '#808080'
            }],
            max: 100
        },
        tooltip: {
            valueSuffix: 'MT'
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Fuel Consumption',
            data: data
        }]
    });


    }, function(error) {
        console.log(error);
    });

}]);
