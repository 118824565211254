import Highcharts from 'highcharts';
import { routerApp, roundToPlaces, fuelGradeMapping, newFuelGradeMapping, themePalette } from '../app.module';
import $ from 'jquery';
import { showByDefault } from '../services/utilities';

type TStatsKnobOption = {
    // numerical value shown in knob and use to calculate length of arc
    value: number,
    
    // add 2nd arc on knob
    double: boolean, 

    // set knob color
    fgColor: string,

    // overrides KPI numerical value
    altValue?: number | string,
}

const combineFuelGradeMap = {...fuelGradeMapping, ...newFuelGradeMapping};

routerApp.controller('kpiController', ['ReportService','VesselSpecificationService', '$rootScope', '$scope', '$timeout', 'TrafficSystemService', 'SpeedPerformanceService', 'CrewDashboardGraphService', function(ReportService, VesselSpecificationService, $rootScope, $scope, $timeout, TrafficSystemService, SpeedPerformanceService, CrewDashboardGraphService) {

    $scope.vesselSpecs = VesselSpecificationService.getSpecifications();
    // 'last_report', '10_days', '1_month', '2_month', '3_month', '6_month', 'year_to_date', 'past_year'
    $scope.dashboardSetting = '6_month';
    $scope.setDashboardRange = function(range: string) {
        $scope.dashboardSetting = range;
    }
    TrafficSystemService.updateColors({ 
        green : themePalette.colors.GREEN  ,
        yellow : themePalette.colors.YELLOW  ,
        red : themePalette.colors.RED  ,
        grey : themePalette.colors.GREY  
    })

    $rootScope.selectedLevels = ['crew-dashboard'];

    $rootScope.dashboardReady = false;

    $scope.showByDefault = function(path, formPartType) {
        return showByDefault(VesselSpecificationService.getSpecifications().form,null,path,formPartType)
    };

    $scope.drawStatsKnob = function(element, option: TStatsKnobOption) {
        let { value, double, fgColor, altValue } = option;
        if (value > 100) {
            value = Math.round(value);
        }
        const maxValue = Math.round(value > 100 ? value * 1.15 : 100);
        $(element).knob({
            'readOnly': true,
            'max':  maxValue,
            parse: function (v) {
                return parseFloat(v);
            },
            'draw': function() {
                // @ts-ignore
                $(this.i)
                    .css('font-size', '36px')
                    .css('font-weight', 'normal')
                    .css('font-family', 'inherit')
                    .css('margin-top', '38px');
                if (double != undefined && double == true) {
                    this.cursorExt = 0.3;

                    // @ts-ignore
                    var a = this.arc(this.cv)  // Arc
                    , pa                   // Previous arc
                    , r = 1;

                    // @ts-ignore
                    this.g.lineWidth = this.lineWidth;
                    // @ts-ignore
                    pa = this.arc(this.v);
                    // @ts-ignore
                    this.g.lineWidth = 5;
                    // @ts-ignore
                    this.g.beginPath();
                    // @ts-ignore
                    this.g.strokeStyle = r ? this.o.fgColor : this.fgColor ;

                    // @ts-ignore
                    this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, pa.s, pa.e, pa.d);
                    // @ts-ignore
                    this.g.stroke();
                }
            },
            'fgColor': fgColor,
            'format': function(value) {
                if (altValue != undefined) {
                    return altValue;
                }
                return value;
            }
        });

        var inputColor = $scope.isDarkTheme() ? '#fff': fgColor;
        $(element).trigger(
            'configure',
            {
                'max': maxValue,
                'fgColor': fgColor,
                'inputColor': inputColor
            }
        );
        
        // knob only accepts numbers in .val. so to accept text, we need trigger change first for any numerical values
        // then override val again without triggering change.
        if (altValue != undefined) {
            $(element).val(value).trigger('change');
            $(element).val(altValue); 
        } else {
            $(element).val(value).trigger('change');
        } 
    };

    $scope.createDashboard = function (data) {
        $timeout(() => {
            console.log('CREATE DAHSBOPARD TRIGGERED', data)
            console.dir($scope)
            if (data == undefined) return;
            // picks the date range based on which option is selected
            var dryDockings = data['dryDockings'];
            data = data['breakdown'][$scope.dashboardSetting];
            $scope.drawStatsKnob("#knob1", {value: data.mileage, double: false, fgColor: TrafficSystemService.getFuelColor(Math.abs(data.fuel_deviation))});
            $scope.drawStatsKnob("#knob2", {value: data.propulsion_efficiency, double: false, fgColor: TrafficSystemService.getPropulsionEfficiencyColor(Math.abs(data.propulsion_efficiency))});
            $scope.drawStatsKnob("#knob3", {value: data.speed_performance, double: false, fgColor: TrafficSystemService.getSpeedPerformanceColor(data.speed_performance)});
            $scope.drawStatsKnob("#knob4", {value: data.co2_emissions, double: false, fgColor: TrafficSystemService.getFuelColor(Math.abs(data.fuel_deviation))});
            
            const knob6Value = (data?.cii_rating != null && data?.cii_rating != '') ? 100 : 0;
            $scope.drawStatsKnob("#knob6",  {value: knob6Value, double: false, fgColor: TrafficSystemService.getCIIRatingColor(data.cii_rating), altValue: data.cii_rating});
            $rootScope.dashboardReady = true;

            var dateFormat = {
                dateTimeLabelFormats: {
                    millisecond: '%b %e',
                    second: '%b %e',
                    minute: '%b %e',
                    hour: '%b %e',
                    day: '%b %e',
                    week: '%b %e',
                    month: '%b %e',
                    year: '%b %e'
                },
                type: 'datetime',
                tickPixelInterval: 75
            }

            var sfocChartType = "scatter";
            var mileageChartType = "scatter";

            var reportNumber = data.report_number.map(function(value) { return value[1]; });
            var meReportedCons = data.fuel_series.map(function(value) { return value[1]; });
            var meModelCons = data.model_fuel.map(function(value) { return value[1]; });
            var meSfocLcvCorr = data.sfoc_lcv_corrected.map(function(value) { return value[1]; });
            var meModelSfoc = data.model_sfoc.map(function(value) { return value[1]; });
            var fuelGradeInUseMainEngine = data.fuel_grade_in_use_main_engine.map(function(value) { return value[1]; });
            var tankName = data.name.map(function(value) { return value[1] || '-'; });
            var bunkerDate = data.bunker_date.map(function(value) { return value[1] || '-'; });
            var bunkerPort = data.bunker_port.map(function(value) { return value[1] || '-'; });
            let co2Emissions = data.co2_emissions_lifetime.map(function(value) { return value[1] || 0; });
            let totalObsDistance = data.total_obs_distance_lifetime.map(function(value) { return value[1] || 0; });
            let ciiRequired = data.cii_required_lifetime.map(function(value) { return value[1] || null });
            let avgCIIAttained = data.cii_required_lifetime.map(function(value) { return data.attained_cii; });
            let avg_cii_attained_lifetime = data.cii_required_lifetime.map(function(value) { return [value[0], data.attained_cii]  });
            let ciiLimit1 = data.cii_limit_1.map(function(value) { return value[1] });
            let ciiLimit2 = data.cii_limit_2.map(function(value) { return value[1] });
            let ciiLimit3 = data.cii_limit_3.map(function(value) { return value[1] });
            let ciiLimit4 = data.cii_limit_4.map(function(value) { return value[1] });
        

            function mapReportNumbers(data) {
                var id = data.map(function(value,index) { return value[0]; });
                var points = data.map(function(value,index) { return value[1]; });

                var final = [];
                for ( var i = 0; i < data.length; i++ ) {
                    final.push([id[i], points[i], reportNumber[i], meReportedCons[i], meModelCons[i], meSfocLcvCorr[i], meModelSfoc[i], fuelGradeInUseMainEngine[i], tankName[i], bunkerDate[i], bunkerPort[i]]);
                }
                return final;
            }

            const mapData = (data, additionalDataList) => {
                let datetime = data.map(function(value,index) { return value[0]; });
                let points = data.map(function(value,index) { return value[1]; });
                let final = [];

                for ( var i = 0; i < data.length; i++ ) {
                    let row = [datetime[i], points[i]];
                    for (let j = 0; j < additionalDataList.length; j++) {
                        row.push(additionalDataList[j][i])
                    }
                    final.push(row);
                }
                return final;
            }

            var sfoc_lifetime_data = mapReportNumbers(data.sfoc_lifetime);
            var propulsion_efficiency_data = mapReportNumbers(data.propulsion_efficiency_lifetime);
            var speed_performance_lifetime_data = mapReportNumbers(data.speed_performance_lifetime);
            let cii_attained_lifetime_data = mapData(data.cii_attained_lifetime, [ciiRequired, avgCIIAttained, co2Emissions, totalObsDistance]);
            // var colors = [Color.GRAPH_BLUE, Color.DATA_2, Color.DATA_3, Color.DATA_4, Color.DATA_5, Color.DATA_5];
            var colors = [themePalette.colors.GRAPH_BLUE, themePalette.colors.DATA_2, themePalette.colors.DATA_3, themePalette.colors.DATA_4, themePalette.colors.DATA_5, themePalette.colors.DATA_5];
            var groupedSfoc = sfoc_lifetime_data.groupBy(function(pt) { return pt[7]; });

            var sfocSeries = groupedSfoc.map(function(points) {
                var color = colors.shift();
                var fuelGradeDisplay = combineFuelGradeMap[points[0][7]];
                return {
                    name: fuelGradeDisplay + ' ME SFOC LCV Corr. Dev. (%)',
                    data: points,
                    keys: ['x','y','reportNumber', 'meReportedCons', 'meModelCons', 'meSfocLcvCorr', 'meModelSfoc', 'fuelGradeInUseMainEngine', 'tankName', 'bunkerDate', 'bunkerPort'],
                    width: 1,
                    color: color,
                    marker: {
                        radius: 3,
                        symbol: 'circle'
                    },
                    regression: true,
                    regressionSettings: {
                        name: fuelGradeDisplay + " ME SFOC LCV Corr. Dev. (%) Trend",
                        type: 'linear',
                        color: color,
                        dashStyle: 'Dash',
                        tooltip: {
                            enableMouseTracking: false,
                            enabled: false,
                            formatter: null,
                            pointFormatter: null
                        },
                        hover: {
                            enabled: false,
                            halo: {
                                size: 0
                            }
                        },
                        marker: {
                            enabled: false
                        },
                        dataLabels: { enabled: false },
                        allowPointSelect: false,
                        animation: false,
                        animationLimit: 0,
                        enableMouseTracking: false,
                    },
                };
            });

            Highcharts.chart('sfoc-container', {
                chart: {
                    zoomType: 'x',
                    type: sfocChartType,
                    backgroundColor: themePalette.colors.THEME_BLUE,
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: dateFormat,
                yAxis: {
                    title: {
                        text: 'ME SFOC LCV Corr. Dev. (%)'
                    }
                },
                plotOptions: {
                    scatter: {
                        tooltip: {
                            "xDateFormat": '%Y-%m-%d',
                            dateTimeLabelFormats: {
                                millisecond: '%b %e',
                                second: '%b %e',
                                minute: '%b %e',
                                hour: '%b %e',
                                day: '%b %e'
                            },
                        },
                    },
                    line: {
                        marker: {
                            enabled: true
                        }
                    },
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                tooltip: {

                    pointFormatter: function() {
                        var meSfocLcvCorr = this.meSfocLcvCorr;
                        var meModelSfoc = this.meModelSfoc;
                        var tooltips = [
                            { name: 'Report Number', value: this.reportNumber },
                            { name: 'ME SFOC LCV Corr. (g/kWh)', value: roundToPlaces(meSfocLcvCorr, 1) },
                            { name: 'ME Model SFOC (g/kWh)', value: roundToPlaces(meModelSfoc, 1) },
                            { name: 'Difference (g/kWh)', value: roundToPlaces(meSfocLcvCorr - meModelSfoc, 1) },
                            { name: this.series.name, value: roundToPlaces(this.y, 1) + '%' },
                        ];
                        if (this.tankName) tooltips.push({ name: 'Tank', value: this.tankName });
                        if (this.bunkerPort) tooltips.push({ name: 'Bunker Port', value: this.bunkerPort });
                        if (this.bunkerDate) tooltips.push({ name: 'Bunker Date', value: this.bunkerDate });

                        // Concats tooltips in the format: `<b>{name}:</b> {value}<br/>`
                        return tooltips.reduce(function(acc, tt) { return acc + '<b>' + tt.name + ':</b> ' + tt.value + '<br/> '; }, '');
                    }
                },
                series: sfocSeries
            });

            $scope.speedPerformanceChart = SpeedPerformanceService.plotChart(data.speed_performance_lifetime, dryDockings, speed_performance_lifetime_data, 'speed-container');

            // CII Chart
            const plotLines = SpeedPerformanceService.buildPlotLines(dryDockings);
            CrewDashboardGraphService.plotCIIChart(
                'cii-container',
                cii_attained_lifetime_data,
                avg_cii_attained_lifetime,
                [
                    ciiLimit1,
                    ciiLimit2,
                    ciiLimit3,
                    ciiLimit4,
                ],
                plotLines
            );

            Highcharts.chart('propulsion-efficiency', {
                chart: {
                    zoomType: 'x',
                    type: mileageChartType,
                    backgroundColor: themePalette.colors.THEME_BLUE,
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {...dateFormat, plotLines: plotLines},
                yAxis: {
                    title: {
                        text: 'Propulsion Efficiency (%)'
                    }
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: true
                        }
                    },
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                tooltip: {
                    pointFormatter: function() {
                        var tooltips = [
                            { name: 'Report Number', value: this.reportNumber },
                            { name: this.series.name, value: roundToPlaces(this.y, 1) + '%' },
                        ];
                        // Concats tooltips in the format: `<b>{name}:</b> {value}<br/>`
                        return tooltips.reduce(function(acc, tt) { return acc + '<b>' + tt.name + ':</b> ' + tt.value + '<br/> '; }, '');
                    }
                },
                series: [{
                    name: 'Propulsion Efficiency (%)',
                    data: propulsion_efficiency_data,
                    keys: ['x','y','reportNumber'],
                    width: 1,
                    color: themePalette.colors.GRAPH_BLUE,
                    marker: {
                        radius: 3
                    },
                    regression: true,
                    regressionSettings: {
                        name: "Propulsion Efficiency (%) Trend",
                        type: 'linear',
                        color: themePalette.colors.GRAPH_BLUE,
                        dashStyle: 'Dash',
                        tooltip: {
                            enableMouseTracking: false,
                            enabled: false,
                            formatter: null,
                            pointFormatter: null
                        },
                        hover: {
                            enabled: false,
                            halo: {
                                size: 0
                            }
                        },
                        marker: {
                            enabled: false
                        },
                        dataLabels: { enabled: false },
                        allowPointSelect: false,
                        animation: false,
                        animationLimit: 0,
                        enableMouseTracking: false,
                    },
                }]
            });
        }, 100)
        
    }

    $scope.$watch('dashboardSetting', function(newValue, oldValue, scope) {
        var dashboardData = ReportService.getDashboardData();
        scope.createDashboard(dashboardData);
    });

    $scope.$on('dashboard-data-received', function() {
        var dashboardData = ReportService.getDashboardData();
        $scope.createDashboard(dashboardData);
    });

    var dashboardData = ReportService.getDashboardData();
    if (dashboardData != undefined) {
        $scope.createDashboard(dashboardData);
    }


}]);
