import { routerApp } from '../app.module';
import hybridService from './hybrid';

routerApp.factory('OfflineDatabase', ['$rootScope', function($rootScope) {

    var enableOnline = true;
    var corsRequestSet = {};
    var offlineReports = [];
    var offlineEngineReports = [];
    var cacheBustFilesCount;
    var corsRequestCount; 
    // create dynamic data request set to compare to
    // data request cache to ensure app works offline
    function dataRequestSet (vesselId, username){
        var partialKeys = [
            'carryOverData',
            'dashboardData',
            'engineReports',
            'vesselInformation',
            'reports',
            'lastIncompleteReport',
            'formConfiguration',
            'lastIncompleteOffhireReport',
            'lastIncompleteEngineReport',
            'offhireReports',
            'vessels',
            // 'recalculationLogs'
        ];
        var requestSet = {};
        if (vesselId && username) {
            for (var i = 0; i < partialKeys.length; i++) {
                var partial = partialKeys[i];
                switch(partial) {
                    case 'vessels':
                        requestSet['/' + username + '/' + partial] = false;
                        break;
                    case 'recalculationLogs':
                        requestSet['/' + 'vessels/' + partial] = false;
                        break;
                    case 'formConfiguration':
                    case 'vesselInformation':
                        requestSet['/' + 'vessels/' + vesselId + '/' + partial] = false
                        break;
                    case 'reports':
                    case 'dashboardData':
                    case 'carryOverData':
                    case 'engineReports':
                    case 'lastIncompleteEngineReport':
                    case 'lastIncompleteOffhireReport':
                    case 'lastIncompleteReport':
                    case 'offhireReports':
                        requestSet['/' + vesselId + '/' + partial] = false  
                        break;      
                }
            }
        }
        return requestSet;
    }
    // initialize database connection and schema
    return {
        isOnline: function() {
            if (navigator && navigator.onLine && enableOnline) {
                return true;
            } else {
                return false;
            }
        },
        getReportKey: function(report) {
            return report.operational.report_from + '-' + report.operational.timezone;
        },
        getEngineReportKey: function(report) {
            return report._cls + '-' + report.creation_time;
        },
        getOffhireReportKey: function(report) {
            return report.report_from + '-' + report.timezone;
        },
        // removes report object from IndexDB using the report's key
        removeReport: function(report) {
            var reportKey =  this.getReportKey(report);
            return hybridService.serverReportsRemove(reportKey).then(function(deletedReportKey) {

                // update list of offline reports
                var updatedOfflineReports = $rootScope.offlineReports;
                if (deletedReportKey) {
                    updatedOfflineReports = updatedOfflineReports.filter(function(report) { 
                        if (report.reportKey !== deletedReportKey) {return report}
                    })
                }
                $rootScope.offlineReports = updatedOfflineReports;
            });
        },
        removeEngineReport: function(report) {
            var reportKey = this.getEngineReportKey(report);
            var engineReportsList = {
                'EngineReport.MEReport': 'offline_me_reports',
                'EngineReport.AEReport': 'offline_ae_reports'
            }
            return hybridService.serverEngineReportsRemove(reportKey).then(function(deletedKey) {
                
                var updatedOfflineEngReports = $rootScope.offline_me_reports;
                
                var reportType = report._cls;
                updatedOfflineEngReports = $rootScope[engineReportsList[reportType]];
   
                if (deletedKey) {
                    updatedOfflineEngReports = updatedOfflineEngReports.filter(function(report) {
                        if (report.reportKey !== deletedKey) {return report}
                    })
                }

                $rootScope[engineReportsList[reportType]] = updatedOfflineEngReports;
            })
        },
        removeOffhireReport: function(report) {
            var reportKey = this.getOffhireReportKey(report);
            return hybridService.serverOffhireReportsRemove(reportKey).then(function(deletedReportKey) {
                  // update list of offline reports
                var updatedOfflineReports = $rootScope.offlineOffhireReports;
                if (deletedReportKey) {
                    updatedOfflineReports = updatedOfflineReports.filter(function(report) { 
                        if (report.reportKey !== deletedReportKey) return report;
                    })
                }
                $rootScope.offlineReports = updatedOfflineReports;
            })
        },
        // removes report object from IndexDB and updates vessel meta data
        deleteReportInProgress: function(report, vesselId) {
            return this.removeReport(report).then(function() {
                return hybridService.serverMetaGet(vesselId).then(function(offlineVesselData) {
                    if (report.status == 'started' && offlineVesselData) {
                        offlineVesselData.hasIncompleteVesselReport = false;
                        return hybridService.serverMetaUpdate(offlineVesselData)
                    }
                });
            })
        },
        deleteEngineReportInProgress: function(report, vesselId) {
            return this.removeEngineReport(report).then(function() {
                return hybridService.serverMetaGet(vesselId).then(function(offlineVesselData) {
                    if (report.status == 'started' && offlineVesselData) {
                        offlineVesselData.hasIncompleteEngineReport = false;
                        return hybridService.serverMetaUpdate(offlineVesselData)
                    }
                });
            })
        },
        deleteOffhireReportInProgress: function(report,vesselId) {
            return this.removeOffhireReport(report)
            .then(() => hybridService.serverMetaGet(vesselId))
            .then(offlineVesselData => {
                if (report.status == 'started' && offlineVesselData) {
                    offlineVesselData.hasIncompleteOffhireReport = false;
                    return hybridService.serverMetaUpdate(offlineVesselData)
                }
            })
        },
        // When online display offline reports that haven't synced to server or are synced but different status from server 
        manageDisplayOfflineReports: function(offlineReports, syncedReports) {
            var syncedReportNumbers = syncedReports && syncedReports.length > 0 ? syncedReports.reduce(function(acc, next, id) { acc[next.reportNumber] = next.status; return acc }, {}) : {};
            if (this.isOnline()) {
                return offlineReports.filter(function(report) { 
                    return syncedReportNumbers[report.report.report_number] == undefined || 
                    syncedReportNumbers[report.report.report_number] && syncedReportNumbers[report.report.report_number] != report.report.status  
                })
            }
            return offlineReports;
        },
        manageOnlineReportsDisplay: function(offlineReports, syncedReports) {
            var offlineReportNumbers = offlineReports && offlineReports.length > 0 ? 
                offlineReports.reduce(function(acc, next, id) { 
                    acc[next.report.report_number] = next.report.status; 
                    return acc 
                }, {}) : {};
            // when offline only show reports in online section that do not match any offline reports in progress
            var onlineReportFilterWhileOffline = function(report) {
                return offlineReportNumbers[report.reportNumber] == undefined || report.status !== 'started'
            };
            // when online only show reports in online section that have synced with server and reports that have matching completed status
            var onlineReportFilterWhileOnline = function(report) { 
                return (offlineReportNumbers[report.reportNumber] == undefined || offlineReportNumbers[report.reportNumber] 
                    && offlineReportNumbers[report.reportNumber] == report.status) 
                };
            
            var onlineReportFilter = this.isOnline() ? onlineReportFilterWhileOnline : onlineReportFilterWhileOffline;
    
            return syncedReports.filter(onlineReportFilter)
        },

        // updates list of offline reports for this service
        setOfflineReports: function(_offlineReports) {
            offlineReports = _offlineReports;
        },
        setOfflineEngineReports: function(engineReports) {
            offlineEngineReports = engineReports;
        },
        getOfflineReports: function() {
            return offlineReports;
        },
        // returns true if the browser is allowed to sync a new report to the server
        // directly through the save and submit buttons. this checks the list of offline
        // reports, and if there are any reports that are still pending sync in 'ready'
        // mode, this will return false, otherwise returns true.
        canSyncNewReport: function() {
            return offlineReports.filter(function(report) {
                return report.reportStatus == 'ready'
            }).length == 0;
        },
        canSyncNewEngineReport: function() {
            return offlineEngineReports.filter(function(report) {
                return report.reportStatus == 'ready'
            }).length == 0;
        },
        initializeDataRequestRef: function(vesselId, username) {
            corsRequestSet = dataRequestSet(vesselId, username);
        },
        getCorsRequestSet: function() {
            return corsRequestSet;
        },
        verifyApi: function(url) {
            var u = new URL(url);
            var pn = u.pathname;

            if (corsRequestSet.hasOwnProperty(pn)) {
                corsRequestSet[pn] = true;
                return true;
            }
        },
        isApiCached: function() { // MIGHT NOT NEED
            for (var k in corsRequestSet) {
                if (!corsRequestSet[k]) {
                    return false;
                }
            }
            return true;
        },
        setOnlineConnection: function(status) {
            enableOnline = status;
        },
        getOfflineMEReports: function(engineReports) {
            return engineReports.filter(function(report) {
                if (report.type == 'EngineReport.MEReport') { return report; }
            });
        },
        getOfflineAEReports: function(engineReports) {
            return engineReports.filter(function(report) {
                if (report.type == 'EngineReport.AEReport') { return report; }
            });
        },
        setCacheBustFilesCount: function(value) {
            cacheBustFilesCount = value;
        },
        setCorsRequestCount: function(value) {
            corsRequestCount = value;
        },
        getCacheBustFilesCount: function() {
            return cacheBustFilesCount;
        },
        getCorsRequestCount: function() {
            return corsRequestCount;
        },
        getTotalCacheCount: function() {
            return cacheBustFilesCount + corsRequestCount;
        }
    };
}]);
