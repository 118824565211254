import angular from 'angular';
import moment from 'moment';
import { SensorData, debounce } from '../analytics/services/sensor.service';
import { baseUrl, enableHybrid, enableOnPremise, removeEmptyValues, reportRequestTimeout, routerApp } from '../app.module';
import hybridService from '../services/hybrid';
import testService from '../services/testing/index';
import { customFetch, httpRequestGenerator, showByDefault } from '../services/utilities';
import ValidationService from '../services/validation';
import { fuelGrades } from '../services/fuelTypes';
import { TankStock } from '../models/TankStock';
import { createBdnConsumptionObj } from '../utils/generate-consumptions';
import _ from 'lodash';
import { parseBdnObjects } from '../utils/parse-bdn-objects';
import { generateBunkers } from '../utils/generate-bunkers';
import { generateConsumptions } from '../utils/generate-consumptions';
import { getDefaultValues } from '../utils/get-default-values';

routerApp.controller('reportController', ['$rootScope', '$scope', '$http', '$timeout', '$state', 'VesselSpecificationService', 'ReportService', 'ReportsApiService', 'ModelsApiService', 'CalculationService', 'notify', 'TrafficSystemService', 'PortService', 'TimezoneService', 'UtilityService', 'OfflineDatabase', 'AutoSaveService',
    function($rootScope, $scope, $http, $timeout, $state, VesselSpecificationService, ReportService, ReportsApiService, ModelsApiService, CalculationService, notify, TrafficSystemService, PortService, TimezoneService, UtilityService, OfflineDatabase, AutoSaveService) {

    $scope.ports = PortService.getPorts();
    $scope.backupReport = {};
    $scope.report.warningMessages = {};
    $scope.transferTankOptions = [];
      
    // re-initialize sensor data object for the report just opened
    SensorData.initialize();

    $scope.vessel_specifications = VesselSpecificationService.getSpecifications();
    var getAuxEngineIndices = function(specs) {
        var indices = [];
        if (specs == undefined || specs.engine == undefined) return indices;
        var number = specs.engine.number_of_aux_engines;
        for (var i = 0; i < number; i++) {
            indices.push(i);
        }
        return indices;
    }
     if ($scope.vessel_specifications) {
       $scope.transferTankOptions = [ ...$scope.vessel_specifications.tanks]
     }

    if ($scope.vessel_specifications) {
        $scope.auxEngineIndices = getAuxEngineIndices($scope.vessel_specifications);
    }

    $scope.reportIsComplete = function() {
        return $scope.report.status == 'completed';
    }

    $scope.enableReloadSensorData = function() {
        let specs = $scope.vessel_specifications;
        if (!specs || !specs.navbox_tags)
            return false;
        return enableOnPremise && specs.navbox_tags.length > 0
    }

    /** Everything on the results and analysis tab */
    $scope.updateRATab = function(redirect) {

        if ((!$scope.reportIsComplete() && $scope.reportForm.$invalid) || !$scope.isOfReportType($scope.report, 'Report.SeaReport') || !$rootScope.onlineStatus.online) { return; }

        notify({message: 'Report is being analyzed, this may take a few seconds', duration: 2000, classes: ['warning-notification']});

        ReportsApiService.modelReport($rootScope.selectedVessel.id, $scope.report).then(function(response) {
            var result = response.data;
            $scope.report.computed = result;

            // average speed
            $scope.report.computed.average_speed = 0;
            if ($scope.report.position.observed_speed > 0) {
                $scope.report.computed.average_speed = $scope.report.position.observed_speed;
            }

            var report = $scope.report;

            // get FM/stock consumption deviations
            var meFMDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.fm_me_consumption, report.computed.me_consumption)) * 100;
            var aeFMDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.fm_ae_consumption, report.computed.ae_consumption)) * 100;
            var mdgPortFMDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.fm_mdg_port_consumption, report.computed.total_mdg_port_consumption)) * 100;
            var mdgStarboardFMDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.fm_mdg_starboard_consumption, report.computed.total_mdg_starboard_consumption)) * 100;
            var hdgFMDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.fm_hdg_consumption, report.computed.total_hdg_consumption)) * 100;
            var totalFMDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.fm_total_consumption, report.computed.me_consumption + report.computed.ae_consumption)) * 100;
            var totalFMDGDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.fm_total_consumption, report.computed.total_mdg_port_consumption + report.computed.total_mdg_starboard_consumption + report.computed.total_hdg_consumption)) * 100;
            var totalStockDeviation = Math.abs(CalculationService.calculateDeviation(report.computed.stock_total_consumption, report.computed.total_consumption)) * 100;

            // set performance metrics colors
            var powerDeviationColor = TrafficSystemService.getRADeviationColor(Math.abs($scope.report.computed.power_deviation));
            var reportedDeviationColor = TrafficSystemService.getRADeviationColor(Math.abs($scope.report.computed.reported_power_deviation));
            var tcrpmDeviationColor = TrafficSystemService.getRADeviationColor(Math.abs($scope.report.computed.tcrpm_power_deviation));
            var fuelDeviationColor = TrafficSystemService.getRADeviationColor(Math.abs($scope.report.computed.fuel_deviation));
            var sfocDeviationColor = TrafficSystemService.getRADeviationColor($scope.report.computed.sfoc_deviation);
            var speedPerformanceColor = TrafficSystemService.getSpeedPerformanceColor(Math.abs($scope.report.computed.speed_performance));
            var mileageDeviationColor = TrafficSystemService.getRADeviationColor(Math.abs($scope.report.computed.fuel_deviation));
            var cylOilDeviationColor = TrafficSystemService.getRADeviationColor(Math.abs($scope.report.computed.cyl_oil_consumption_deviation));

            // set FM/stock deviation colors
            $scope.meFMDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(meFMDeviation) };
            $scope.aeFMDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(aeFMDeviation) };
            $scope.mdgPortFMDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(mdgPortFMDeviation) };
            $scope.mdgStarboardFMDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(mdgStarboardFMDeviation) };
            $scope.hdgFMDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(hdgFMDeviation) };
            $scope.totalFMDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(totalFMDeviation) };
            $scope.totalFMDGDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(totalFMDGDeviation) };
            $scope.totalStockDeviationStyle = {'color': TrafficSystemService.getFuelConsumptionDeviationColor(totalStockDeviation) };

            if ($scope.shouldAnalyzeReport) {
                $scope.powerStyle = {'background-color': powerDeviationColor};
                $scope.fuelStyle = {'background-color': fuelDeviationColor};
                $scope.sfocStyle = {'background-color':  sfocDeviationColor};
                $scope.speedStyle = {'background-color': speedPerformanceColor};

                $scope.powerDeviationStyle = {'color': powerDeviationColor};
                $scope.reportedPowerDeviationStyle = {'color': reportedDeviationColor};
                $scope.tcrpmPowerDeviationStyle = {'color': tcrpmDeviationColor};
                $scope.fuelDeviationStyle = {'color': fuelDeviationColor};
                $scope.sfocDeviationStyle = {'color': sfocDeviationColor};
                $scope.mileageDeviationStyle = {'color': mileageDeviationColor};
                $scope.cylOilDeviationStyle = {'color': cylOilDeviationColor};
            } else {
                var invalidColor = TrafficSystemService.getInvalidColor();
                $scope.powerStyle = {'background-color': invalidColor};
                $scope.reportedPowerDeviationStyle = {'color': invalidColor};
                $scope.tcrpmPowerDeviationStyle = {'color': invalidColor};
                $scope.fuelStyle = {'background-color': invalidColor};
                $scope.sfocStyle = {'background-color':  invalidColor};
                $scope.speedStyle = {'background-color': invalidColor};

                $scope.powerDeviationStyle = {'color': invalidColor};
                $scope.fuelDeviationStyle = {'color': invalidColor};
                $scope.sfocDeviationStyle = {'color': invalidColor};
                $scope.mileageDeviationStyle = {'color': invalidColor};
                $scope.cylOilDeviationStyle = {'color': invalidColor};
            }

            $rootScope.activeTab = 'results';
        }, function(data) {
            console.log('there was an error');
        });

    }

      $scope.shouldShowShorePowerKwh = (): boolean => {
        if ($scope.isSeaReport($scope.report)) return false
        if (!$scope.report.power.shore_power_is_used) return false

        return true
    }

    $scope.shouldAnalyzeReport = true;
    $scope.$watchGroup(['report.operational.report_period', 'report.position.true_wind_force', 'report.computed.me_load'], function(n, o, scope) {
        if (n[0] != undefined && n[1] != undefined) {
            var reportPeriod = n[0];
            var trueWindForce = n[1];

            if (scope.report.position.observations && removeEmptyValues(scope.report.position.observations).length > 0) {
                var count = 1;
                var trueWindForceSum = n[1];
                for (var i = 0; i < removeEmptyValues(scope.report.position.observations).length; i++) {
                    if (scope.report.position.observations[i] && scope.report.position.observations[i].true_wind_force != undefined) {
                        trueWindForceSum += scope.report.position.observations[i].true_wind_force;
                        count += 1;
                    }
                }
                trueWindForce = trueWindForceSum / count;
            }

            var meLoadValid = (n[2] == undefined) || (n[2] >= 25);
            $scope.shouldAnalyzeReport = (reportPeriod >= 18 && trueWindForce <= 6 && meLoadValid);
        }
    });

    $scope.validationMessages = {};

    // update average draught values from draught_fwd and draught_aft
    var displacementRequestTimer = false;
    var displacementDelay = 2000;
    $scope.$watchGroup(['report.operational.draught_forward', 'report.operational.draught_aft'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined && newValues[1] != undefined) {
            // update average draught used for analysis
            scope.report.operational.draught = (newValues[1] + newValues[0]) / 2;
            // update trim
            scope.report.operational.trim = newValues[1] - newValues[0];

            if (displacementRequestTimer) {
                $timeout.cancel(displacementRequestTimer);
            }

            displacementRequestTimer = $timeout(function() {
                // use the value of the computed draught to get the value for the displacement
                ModelsApiService.getDisplacement(scope.report.operational.draught).then(function(response) {
                    var data = response.data;
                    scope.report.operational.displacement = parseFloat(data.displacement);
                }, function(data, status, header, config) {
                    // should do something, doing nothing
                });
            }, displacementDelay);

        }
    });

    $scope.$watchGroup(['report.operational.report_to', 'report.operational.etd'], function(newValues, oldValues, scope) {
        var toMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
        var etd = moment(newValues[1], 'DD/MM/YYYY HH:mm');
        if ($scope.reportForm.operational && $scope.reportForm.operational.etd && scope.showByDefault('report.operational.etd')) {
            if (newValues[1]) {
                $scope.reportForm.operational.$setValidity('etd_invalid', (etd >= toMoment) ? true : false);
            } else {
                $scope.reportForm.operational.$setValidity('etd_invalid', true);
            }
        } else {
            $scope.reportForm.operational.$setValidity('etd_invalid', true);
        }
    });

    // update report period from report from and report to times
    $scope.$watchGroup(['report.operational.report_from', 'report.operational.report_to', 'report.operational.timezone'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined && newValues[1] != undefined) {
             var fromMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
             var toMoment = moment(newValues[1], 'DD/MM/YYYY HH:mm');
             var duration = moment.duration(toMoment.diff(fromMoment));
             let toDST = toMoment.isDST() ? 1 : 0;
             let fromDST = fromMoment.isDST() ? 1 : 0;
             let durationHours = duration.asHours() + (toDST - fromDST);

             if (scope.report.operational.previous_timezone != undefined && scope.report.operational.timezone != undefined) {
                try {
                    var diff = scope.allTimezones[scope.report.operational.timezone].offset - scope.allTimezones[scope.report.operational.previous_timezone].offset;
                    durationHours -= diff;
                } catch(err) {

                }
             }
             $scope.report.operational.report_period = Math.max(Math.round(durationHours * 100) / 100, 0);
        } else {
            $scope.report.operational.report_period = 0;
        }

        if ($scope.reportForm.operational) {
            let maxReportPeriod = $scope.isSmyrilVessel() ? 72 : 36;
            $scope.maxReportPeriod = maxReportPeriod;
            $scope.reportForm.operational.$setValidity('report_to_invalid', (($scope.report.operational.report_period > 0 && (($scope.isSeaReport($scope.report) && $scope.report.operational.report_period <= maxReportPeriod)) || !$scope.isSeaReport($scope.report)) && ($scope.report.operational.report_to != '')) ? true : false);
        }
    });

    $scope.$watch('report.operational.report_to', function(newValue, oldValue, scope) {
        var report_from_date = moment($scope.report.operational.report_from, 'DD/MM/YYYY HH:mm');
        var new_report_to_date = moment(newValue, 'DD/MM/YYYY HH:mm');
        if (scope.report.engine && scope.report.engine.main_engines) {
            var main_engines = $scope.report.engine.main_engines;
            for (var i = 0; i<main_engines.length; i++) {
                if ($scope.report.engine.main_engines[i].reading_date == undefined || $scope.report.engine.main_engines[i].reading_date == "" || moment($scope.report.engine.main_engines[i].reading_date, 'DD/MM/YYYY HH:mm') > new_report_to_date || moment($scope.report.engine.main_engines[i].reading_date, 'DD/MM/YYYY HH:mm') < report_from_date) {
                $scope.report.engine.main_engines[i].reading_date = newValue;
                }
            }
        }
        if ($scope.report.stock.fuel_water_stock_date == undefined || $scope.report.stock.fuel_water_stock_date == "" || moment($scope.report.stock.fuel_water_stock_date, 'DD/MM/YYYY HH:mm') > new_report_to_date || moment($scope.report.stock.fuel_water_stock_date, 'DD/MM/YYYY HH:mm') < report_from_date) {
            $scope.report.stock.fuel_water_stock_date = newValue;
            }
        if ($scope.report.stock.lube_datetime == undefined || $scope.report.stock.lube_datetime == "" || moment($scope.report.stock.lube_datetime, 'DD/MM/YYYY HH:mm') > new_report_to_date || moment($scope.report.stock.lube_datetime, 'DD/MM/YYYY HH:mm') < report_from_date) {
            $scope.report.stock.lube_datetime = newValue;
            }
        if ($scope.report.position.observation == undefined || $scope.report.position.observation == "" || moment($scope.report.position.observation, 'DD/MM/YYYY HH:mm') > new_report_to_date || moment($scope.report.position.observation, 'DD/MM/YYYY HH:mm') < report_from_date) {
            $scope.report.position.observation = newValue;
        }
    });

    // update engine tab main engine instant power in sea report when observation (reading_date) field changes.
    if ($scope.report.engine && $scope.report.engine.main_engines) {
        let engineReadingDatesWatchGroup = [];
        let engineSensorFieldToUpdate = [];
        let sensorFlagEnabled = $scope.features?.reporting?.sensor_enabled;
        angular.forEach($scope.report.engine.main_engines, function(engine, index) {
            let engineReadingDate = 'report.engine.main_engines[' + index + '].reading_date';
            engineReadingDatesWatchGroup.push(engineReadingDate);
            let mainEngineInstantPower = 'engine.main_engines[' + index + '].main_engine_instant_power';
            engineSensorFieldToUpdate.push(mainEngineInstantPower);    
            $scope.$watch(engineReadingDate, function(newValue, oldValue, scope) {
                if(newValue != oldValue && sensorFlagEnabled) {
                    debounceGetSensorData({paths: [mainEngineInstantPower], customReportTo: newValue});
                }
            })        
        })
    }
    

    // calculate time in ECA based on reporting period and enter/exit eca timestamps, and in_eca flag
    $scope.$watchGroup([
        'report.operational.enter_eca',
        'report.operational.exit_eca',
        'report.operational.report_period',
        'report.operational.report_from',
        'report.operational.report_to',
    ], function(newValues, oldValues, scope) {
        let [enterECA, exitECA, reportPeriod, reportFrom, reportTo] = newValues;
        let enterECAValid = enterECA != undefined && enterECA != '';
        let exitECAValid = exitECA != undefined && exitECA != '';

        let reportFromMoment = moment(reportFrom, 'DD/MM/YYYY HH:mm');
        let reportToMoment = moment(reportTo, 'DD/MM/YYYY HH:mm');
        let enterECAMoment = moment(enterECA, 'DD/MM/YYYY HH:mm');
        let exitECAMoment = moment(exitECA, 'DD/MM/YYYY HH:mm');
        let prevInECA = $scope.report.operational.previously_in_eca == true;
        let timeInECA = 0;
        let duration = null;

        // calculate time spent in ECA
        if ((enterECAValid || exitECAValid) && (reportFrom && reportTo)) {
            if (!prevInECA) {
                // If not previously in ECA, we can either enter and not
                // leave before report_to (no exit_eca entered), in which case
                // the exitECAMoment is equal to report_to; or enter and then leave
                // before report_to, in which case exitECA is valid.
                if (!exitECAValid) {
                    duration = moment.duration(reportToMoment.diff(enterECAMoment));
                    scope.report.operational.in_eca = true;
                } else {
                    // Add ternary expr below to handle special case where
                    // previously_in_eca is undefined and only the exit_eca is
                    // inputted.
                    duration = moment.duration(exitECAMoment.diff(enterECAValid ? enterECAMoment : reportFromMoment));
                    scope.report.operational.in_eca = false;
                }
            } else {
                // If previously in ECA, we can either exit and not re-enter (no
                // enter_eca entered), in which case the enterECAMoment is report_from
                // and exitECAMoment is exit_eca; or exit and then re-enter, in which
                // case there are two different ECA periods.
                if (!enterECAValid) {
                    duration = moment.duration(exitECAMoment.diff(reportFromMoment));
                    scope.report.operational.in_eca = false;
                } else {
                    let duration1 = moment.duration(exitECAMoment.diff(reportFromMoment));
                    let duration2 = moment.duration(reportToMoment.diff(enterECAMoment));
                    duration = moment.duration(duration1.asMilliseconds() + duration2.asMilliseconds());
                    scope.report.operational.in_eca = true;
                }
            }

            timeInECA = Math.max(duration.asHours(), 0);
            scope.report.operational.time_in_eca = UtilityService.round(timeInECA, 2);
        } else if (scope.report.operational.previously_in_eca) {
            scope.report.operational.time_in_eca = UtilityService.round(reportPeriod, 2);
            scope.report.operational.in_eca = true;
        } else {
            scope.report.operational.time_in_eca = 0;
            scope.report.operational.in_eca = false;
        }
    });

    $scope.$watchGroup(['report.operational.next_port', 'report.operational.previous_next_port'], function(newValues, oldValues, scope) {
        if (newValues[1] && $scope.report._cls == 'Report.SeaReport') {
            var next_port = newValues[0];
            var previous_next_port = newValues[1];
            $scope.report.warningMessages['report.operational.next_port'] = previous_next_port != next_port ? { message: "Next port value does not match previous report's entry of " + previous_next_port + "."} : null;
        }
    });

    $scope.$watchGroup(['report.operational.report_to', 'report.operational.eta', 'report.operational.previous_eta', 'report.operational.awaiting_orders'], function(newValues, oldValues, scope) {
        var toMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
        var eta = moment(newValues[1], 'DD/MM/YYYY HH:mm');
        var awaitingOrders = !!newValues[3];

        // check if ETA is after Report To timestamp
        var etaValid = ($scope.report._cls == 'Report.SeaReport' || $scope.isManReport($scope.report)) && $scope.reportForm.operational.eta && eta >= toMoment;
        etaValid = etaValid || awaitingOrders;
        if ($scope.reportForm && $scope.reportForm.operational && $scope.reportForm.operational.eta) $scope.reportForm.operational.eta.$setValidity('invalid', etaValid);

        // if ETA is after Report To timestamp, check if within 24 hours of previous reported ETA
        if (etaValid && newValues[2]) {
            var previous_eta = moment(newValues[2], 'DD/MM/YYYY HH:mm');
            var duration = moment.duration(eta.diff(previous_eta));
            let durationHours = Math.abs(duration.asHours());

            $scope.report.warningMessages['report.operational.eta'] = durationHours > 24 ? { message: 'Reported ETA is not within 24 hours from previously reported ETA'} : null;
        }
    });

    $scope.$watchGroup(['report.operational.finish_with_engines', 'report.operational.anchor_heaved'], function(newValues, oldValues, scope) {
        if (newValues[0] != undefined && newValues[1] != undefined) {
            var fromMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
            var toMoment= moment(newValues[1], 'DD/MM/YYYY HH:mm');
            var duration = moment.duration(toMoment.diff(fromMoment));
            let durationHours = duration.asHours();
            $scope.report.operational.total_time_of_anchor = Math.max(Math.round(durationHours * 100) / 100, 0);
        } else {
            $scope.report.operational.total_time_of_anchor = 0;
        }
    });

    $scope.$watchGroup([
        'report.power.main_engine_1_power',
        'report.power.main_engine_1_diff_rh',
        'report.power.main_engine_2_power',
        'report.power.main_engine_2_diff_rh',
        'report.power.main_engine_1_shaft_power_meter_difference',
        'report.power.shaft_power',
        'report.power.shaft_rh'
    ], function(newValues, oldValues, scope) {

        var dualEngineVessel = $rootScope.selectedVessel.dualEngine;

        var mainEngine1Power = newValues[0] || 0;
        var mainEngine1RH = newValues[1] || 0;
        var mainEngine2Power = newValues[2] || 0;
        var mainEngine2RH = newValues[3] || 0;
        var shaftPowerMeterKWHRs = newValues[4] || 0;
        var shaftPower = newValues[5] || 0;
        var shaftRH = newValues[6] || 0;

        var totalKWHRs = 0;
        var totalRH = 0;
        if (mainEngine1Power && mainEngine1RH) {
            totalKWHRs += mainEngine1Power * mainEngine1RH;
            totalRH += mainEngine1RH;
        }
        if (mainEngine2Power && mainEngine2RH) {
            totalKWHRs += mainEngine2Power * mainEngine2RH;
            totalRH += mainEngine2RH;
        }
        if (dualEngineVessel && shaftPowerMeterKWHRs) {
            totalKWHRs = shaftPowerMeterKWHRs;
        }

        var totalEnergy = totalKWHRs;
        if (dualEngineVessel) {
            var shaftGeneratorKWHRs = shaftPower * shaftRH;
            totalEnergy = totalEnergy * 1.03 + shaftGeneratorKWHRs * 1.04;

        }

        var meanTotalPower = 0;
        if (totalRH > 0) {
            meanTotalPower = totalEnergy / totalRH;
        }

        if ($scope.report.power == null) {
            $scope.report.power = {}
        }
        $scope.report.power.total_energy = totalEnergy;
        $scope.report.power.mean_total_power = meanTotalPower;
    });

    const requestFunc = httpRequestGenerator(enableOnPremise, $http);

    $scope.filterReport = () => {
        const filtered = {
            ...$scope.report, 
            bunker: {
                ...$scope.report.bunker,
                bdn_based_reporting_bunkered_fuels: $scope.report.bunker.bdn_based_reporting_bunkered_fuels?.filter((a) => {
                    return !!a.fuel_grade
                })
            },
            consumption: {
                ...$scope.report.consumption,
                bdn_consumptions: $scope.report.consumption.bdn_consumptions.map((a) => {
                    if (a.bdn_number) return a
                }).filter(b => b)
            }
        }
        return filtered
    }

    $scope.submittingReport = false;

    $scope.saveReport = function(submit, autoSave) {
        $scope.validateTab($scope.reportForm, $rootScope.activeTab);

        const filteredReport = $scope.filterReport()

        if ((enableHybrid || enableOnPremise) && !OfflineDatabase.canSyncNewReport()) {
            if ($scope.autoSaving != true) notify({message: 'Saving report in local cache.', duration: 5000, classes: ['warning-notification']});
            $scope.saveReportToLocalDatabase(filteredReport, submit);
            return;
        }
        var wasPreviouslySynced = !!filteredReport.id;
        var toBeSubmit = submit != undefined && submit == true;
        var url = baseUrl + $rootScope.selectedVessel.id + "/reports";
        if (wasPreviouslySynced) {
            url += "/" + filteredReport.id;
        }
        if (toBeSubmit) {
            url += "&submit=true";
        }
        var reportSubmissionPromise;
        var options = (enableHybrid || enableOnPremise) ? {timeout: reportRequestTimeout * 1000} : {};
        $scope.submittingReport = true;

        if (!wasPreviouslySynced) {
            reportSubmissionPromise = requestFunc({url, method: 'POST', data: filteredReport, ...options}) //$http.post(url, $scope.report, options);
        } 
        if (wasPreviouslySynced) {
            reportSubmissionPromise = requestFunc({url, method: 'PUT', data: filteredReport, ...options}) // $http.put(url, $scope.report, options);
        }
        
        AutoSaveService.setAutosaveStatus(autoSave);

        // handle saved / updated report
        var isDraftReport = filteredReport.status == 'started' || filteredReport.status == 'started' && !submit;
        reportSubmissionPromise = reportSubmissionPromise.then(function (response, status, headers, config) {
            $scope.submittingReport = false;
            var data = response.data;
            var report_id = data.data.report_id;
            
            var receivedUpdatedReport = isDraftReport && !!data.data.report;
            var updatedReport = data?.data?.report ? JSON.parse(data.data.report) : filteredReport;
        
            if (!wasPreviouslySynced) {
                filteredReport.id = data.data.report_id;
                $rootScope.hasIncompleteVesselReport = true;
                // update the report number for when we create a new report once
                // we're done with editing/submitting this report
                var report_number = data.data.report_number;
                ReportService.setVesselReportNumber(report_number);
            }
            if (isDraftReport) {
                $rootScope.hasIncompleteVesselReport = true;
                updatedReport.id = report_id;
                ReportService.setVesselReport(updatedReport);
            }
            if ((enableHybrid || enableOnPremise) && isDraftReport) {
                $scope.saveReportToLocalDatabase(updatedReport, submit);
            }
             // if return report exist, then replace existing report
            if (receivedUpdatedReport) {
                $scope.refreshMergeReport(ReportService.getVesselReport());
            }
     
            // hide save notification if autosave is enabled on hybrid app
            if (!enableHybrid || enableHybrid && $scope.autoSaving != true) {
                notify({message: 'Created and successfully saved new report!', duration: 2000, classes: ['ok-notification']});
            }            
            $scope.refreshVesselReports();
            $scope.autoSaving = false;
        }, function (data, status, header, config) {
            $scope.submittingReport = false;
            $scope.autoSaving = false;
            if (status == 409) {
                notify({message: 'This report has already been submitted.', duration: 2000, classes: ['warning-notification']});
                $scope.refreshVesselReports(function() {
                    $state.go('site.vesselPerformance');
                });
                return;
            }
            if ((enableHybrid || enableOnPremise)) {
                // if this request fails, add report to submission queue right away so that it is handled in the background
                if (isDraftReport) {
                    $rootScope.hasIncompleteVesselReport = true;
                    ReportService.setVesselReport(filteredReport);
                }
                if (wasPreviouslySynced && $scope.autoSaving != true) notify({message: 'Saving report in local cache.', duration: 5000, classes: ['warning-notification']});
                $scope.saveReportToLocalDatabase(filteredReport, submit);
            } else {
                notify({message: 'Error saving report, please try again later.', duration: 2000, classes: ['bad-notification']});
            }
        });
    }


    $scope.editReport = function() {
        var editMessage = 'Report is ready for editing';
        var url = baseUrl + $rootScope.selectedVessel.id + "/reports/" + $scope.report.id + "/edit";
        var method = "PUT";
        requestFunc({
            method: method,
            url: url
        }).then(function(response) {
            notify({message: editMessage, duration: 2000, classes: ['ok-notification']});
        }, function() {
            notify({message: 'Error, please try again later.', duration: 2000, classes: ['bad-notification']});
        });
        $rootScope.activeTab = 'operational';
    }


    $scope.cancelReport = function() {
        var cancelMessage = 'Deleted report in progress';
        $('.modal-backdrop').remove();
        if ($scope.report.id == undefined) { // report not connected to server yet

            if ((enableHybrid || enableOnPremise)) {
                OfflineDatabase.deleteReportInProgress($scope.report, $rootScope.selectedVessel.id).then(function() {
                    $scope.report = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
                    ReportService.setVesselReport($scope.report);
                    $rootScope.hasIncompleteVesselReport = false;
                    $scope.$broadcast('vessel-reports-list');
                    $state.go('site.vesselPerformance');
                });
            } else {
                $scope.report = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
                ReportService.setVesselReport($scope.report);
                $rootScope.hasIncompleteVesselReport = false;
            }
        } else { //report has been saved to server
            var url = baseUrl + $rootScope.selectedVessel.id + "/reports/" + $scope.report.id;
            var reportId = $scope.report.id;
            requestFunc({url, method: 'DELETE'})
            .then(function(response) {
                var res = response.data;

                $scope.report = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
                ReportService.setVesselReport($scope.report);
                ReportService.setVesselReportNumber(res.data.new_report_number);
                ReportService.removeReportFromList(reportId);

                if ((enableHybrid || enableOnPremise)) {
                    // always delete draft from localdb
                    OfflineDatabase.deleteReportInProgress($scope.report, $rootScope.selectedVessel.id).then(function(){
                        notify({message: cancelMessage, duration: 2000, classes: ['ok-notification']});
                        // remove the report from the vessel performance reports list
                        // and make sure the view updates its report list from the service
                        $scope.$broadcast('vessel-reports-list');
                        $state.go('site.vesselPerformance');
                        $rootScope.hasIncompleteVesselReport = false;
                    })
                } else {
                    notify({message: cancelMessage, duration: 2000, classes: ['ok-notification']});
                    // remove the report from the vessel performance reports list
                    // and make sure the view updates its report list from the service
                    $scope.$broadcast('vessel-reports-list');
                    $state.go('site.vesselPerformance');
                    $rootScope.hasIncompleteVesselReport = false;
                }       
            }, function() {
                // Online but cannot connect server for some reason
                if(!(enableHybrid || enableOnPremise) || (enableHybrid || enableOnPremise) && OfflineDatabase.isOnline()) {
                    notify({message: 'Error deleting report, please try again later.', duration: 2000, classes: ['bad-notification']});
                } else if ((enableHybrid || enableOnPremise)) {
                    // No internet connection so remove offline report
                    OfflineDatabase.deleteReportInProgress($scope.report, $rootScope.selectedVessel.id).then(function() {
                        $scope.report = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
                        ReportService.setVesselReport($scope.report);
                        $rootScope.hasIncompleteVesselReport = false;
                        $scope.$broadcast('vessel-reports-list');
                        $state.go('site.vesselPerformance');
                    });
                }
            })
        }
    }

    $scope.resyncReport = function() {
        var url = baseUrl + $rootScope.selectedVessel.id + "/reports/" + $scope.report.id + "/reSync";
        $scope.syncingReport = true;
        $http.post(url).then(function(response) {
            notify({message: 'Report succesfully re-synced!', duration: 2000, classes: ['ok-notification']});
            $scope.syncingReport = false;
        }, function() {
            notify({message: 'Error re-syncing report, please try again later.', duration: 2000, classes: ['bad-notification']});
            $scope.syncingReport = false;
        });
    }

    $scope.approveReport = function() {
        var url = baseUrl + $rootScope.selectedVessel.id + "/reports/" + $scope.report.id + "/approve";
        $scope.approvingReport = true;
        $http.post(url).then(function(res) {
            notify({message: 'Report is approved!', duration: 2000, classes: ['ok-notification']});
            $scope.approvingReport = false;
            $state.go('site.vesselPerformance');
        }, function() {
            notify({message: 'Error approving report, please try again later.', duration: 2000, classes: ['bad-notification']});
            $scope.approvingReport = false;
        });
    }

    $scope.shouldRemindSensorDataReload = function () {
        if (!enableOnPremise)
            return false;

        let reportTo = TimezoneService.dtWithTz($scope.report.operational.report_to, $scope.report.operational.timezone);
        let latestSensorDataUpdatedTime = TimezoneService.dtFromISOString($scope.sensorDataLastUpdatedAt);
        const reminderDurationForSensorDataRefreshInMinutes = 20;

        let difference = 0;
        if (latestSensorDataUpdatedTime) {
            difference = reportTo.diff(latestSensorDataUpdatedTime, 'minutes');
        }
        return difference > reminderDurationForSensorDataRefreshInMinutes;
    }

    $scope.submitReport = function(_report, submitType) {
        var canForceSubmit = $scope.features && $scope.features.reporting && $scope.features.reporting.delete_enabled || enableOnPremise;
        if ($scope.reportForm.$invalid && !canForceSubmit) {
            angular.forEach($scope.reportForm.operational.$error, function(controls, errorName) {
                angular.forEach(controls, function(control) {
                    control.$pristine = false;
                });
            });
            notify({message: 'Report is not complete!', duration: 2000, classes: ['bad-notification']});
        } else {
            const filteredReport = $scope.filterReport()

            if ((enableHybrid || enableOnPremise) && !OfflineDatabase.canSyncNewReport()) {
                notify({message: 'Saving report in local cache.', duration: 5000, classes: ['warning-notification']});
                $scope.saveReportToLocalDatabase(filteredReport, true);
                return;
            }

            var requestOption = {} as any;
            if (filteredReport.id == undefined) {
                requestOption.method = "POST";
                requestOption.url = baseUrl + $rootScope.selectedVessel.id + "/reports?submit=true" + `&submitType=${submitType || ''}`;
            } else {
                requestOption.method = "PUT";
                requestOption.url = baseUrl + $rootScope.selectedVessel.id + "/reports/" + filteredReport.id + "?submit=true" + `&submitType=${submitType || ''}`;
            }
            if ((enableHybrid || enableOnPremise)) {
                requestOption.timeout = reportRequestTimeout * 1000;
            }

            requestOption.data = filteredReport;
            $scope.submittingReport = true;
            requestFunc(requestOption).then(function(response) {
                var data = response.data;
                $scope.submittingReport = false;
                filteredReport.id = data.data.report_id;
                if ((enableHybrid || enableOnPremise)) {
                    // remove from offline reports if it exist
                    OfflineDatabase.removeReport(filteredReport);
                }
                // update the report number for when we create a new report once
                // we're done with editing/submitting this report
                var report_number = data.data.report_number;
                ReportService.setVesselReportNumber(report_number);

                notify({message: 'Report Submitted Successfully!', duration: 2000, classes: ['ok-notification']});

                // create a new report, put it on the service and set it on the scope
                var carryForwardReport = filteredReport;
                $scope.carryOverReport = carryForwardReport;
                $scope.report  = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
                ReportService.setVesselReportNumber(report_number);
                ReportService.updateVesselReportWithCarryForwardReport(ReportService.getVesselReport(), carryForwardReport);
                $scope.$broadcast('vessel-report-received', { carryOverReport: $scope.report });
                $rootScope.hasIncompleteVesselReport = false;

                ReportsApiService.getReports($scope.selectedVessel.id).then(function(response) {
                    var data = response.data;
                    ReportService.setVesselReportsList(data);
                    $scope.$broadcast('vessel-reports-list');
                    $state.go('site.vesselPerformance');
                    $rootScope.hasIncompleteVesselReport = false;
                });

                if ((enableHybrid || enableOnPremise)) {
                    hybridService.serverMetaUpdate({
                        vesselId: $rootScope.selectedVessel.id,
                        reportNumber: report_number,
                        carryForwardReport: carryForwardReport,
                        hasIncompleteVesselReport: false
                    })
                }
            }, function(data, status, headers, config) {
                $scope.submittingReport = false;
                if (status == 409) {
                    // nothing to do or wait for here, redirect to vessel reports list
                    notify({message: 'This report has already been submitted.', duration: 2000, classes: ['warning-notification']});
                    $scope.refreshVesselReports(function() {
                        $state.go('site.vesselPerformance');
                    });
                } else if ((enableHybrid || enableOnPremise)) {
                    // request failed which could be a server error or a bad connection,
                    // save report in submission queue so that it is handled in the background
                    notify({message: 'Submitting report to local cache.', duration: 5000, classes: ['warning-notification']});
                    $scope.saveReportToLocalDatabase($scope.report, true);
                } else {
                    notify({message: 'Error Saving Report, please try again later.', duration: 2000, classes: ['bad-notification']});
                }
            });
        }
    };

    $scope.updateVesselMetaData = function(vesselId, report, submit) {
        var hasIncompleteVesselReport = !report.status || report.status == '' || report.status == 'started';
            return hybridService.serverMetaGet(vesselId).then(function(offlineVesselData) {
                var updatedVesselData = offlineVesselData ? Object.assign({}, offlineVesselData) : {vesselId: vesselId};
                var report_number = report.report_number;
                if (submit == true) {
                    // if report is ready to be submitted, 
                    // register a new-report sync event and handle post submission logic
    
                    // trigger a registration sync
                    hybridService.syncReportsServiceWorker('new-report');
                    report_number = report.report_number + 1;
                    
                    updatedVesselData.carryForwardReport = report;

                } else {
                    // if report has already been submitted and we're just making changes to it
                    var readyToBeSaved = report.status == 'completed';
                    // trigger a registration sync
                    if (readyToBeSaved) {
                        hybridService.syncReportsServiceWorker('new-report');
                    }

                    updatedVesselData.incompleteVesselReport = report;
                }

                
                updatedVesselData.hasIncompleteVesselReport = hasIncompleteVesselReport;
                updatedVesselData.reportNumber = report_number;
                return updatedVesselData;
            }).then(updatedVesselData => {
                return hybridService.serverMetaUpdate(updatedVesselData).then(function(response) {

                    if (submit == true) {
                        notify({message: 'Report added to submission queue!', duration: 2000, classes: ['ok-notification']});
    
                        // update report number
                        ReportService.setVesselReportNumber(updatedVesselData.reportNumber);
    
                        // create a new report, put it on the service and set it on the scope
                        var carryForwardReport = report;
                        $scope.carryOverReport = carryForwardReport;
                        $scope.report  = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
    
                        // update new report data based on report just submitted
                        ReportService.updateVesselReportWithCarryForwardReport(ReportService.getVesselReport(), carryForwardReport, false);
                        $scope.$broadcast('vessel-report-received', { carryOverReport: $scope.report });
                        $scope.$broadcast('vessel-reports-list');
    
                    } else {
                        if ($scope.autoSaving != true) notify({message: 'Report saved locally, will sync automatically when ready!', duration: 5000, classes: ['ok-notification']});
                    }
                    $rootScope.hasIncompleteVesselReport = hasIncompleteVesselReport;
                    $scope.autoSaving = false;
                    $scope.unsaveChanges = false;
                })
            })
    }

    $scope.saveReportToLocalDatabase = function(report, submit) {
        var reportKey = OfflineDatabase.getReportKey(report);
        var vesselId = $rootScope.selectedVessel.id;
        var url = baseUrl + vesselId + "/reports";
        var reportStatus = 'draft';
        if (submit == true) {
            // submit indicates that the report is ready to be sent as soon as new-report runs
            // otherwise, it just saves it with a report status of 'draft'
            url += "?submit=true";
            reportStatus = 'ready';
            report.status = 'completed';
        }
        if (report.status == 'completed') {
            reportStatus = 'ready';
        }
        var method = "POST";
        var body = report;
        return hybridService.serverReportsUpdate({
            reportKey: reportKey,
            url: url,
            method: method,
            body: JSON.stringify(body),
            type: 'report',
            vesselId: vesselId,
            username: $rootScope.username,
            report: report,
            reportStatus: reportStatus
        }).then(function(doc) {
            return $scope.updateVesselMetaData(vesselId, report, submit);
        }).then(function() {
                // once save is complete, refresh list of offline reports
            return $scope.refreshOfflineReports().then(function() {
                // only redirect to vessel reports list in case report has been submitted
                if (submit == true) {
                    $state.go('site.vesselPerformance');
                }
            });
        }).catch(errorHandler.saveReportToLocalDatabase);
    }

    const errorHandler = {
        saveReportToLocalDatabase: function (error) {
            if (error == 409) {
                $state.go('site.vesselPerformance');
                notify({message: 'Error Saving Report, report already completed.', duration: 5000, classes: ['bad-notification']});
            } else {
                notify({message: 'Error Saving Report, please try again later.', duration: 2000, classes: ['bad-notification']});
            }
        }
    };
   
    // get latest merge report
    $scope.getUpdatedReport = function() {
        AutoSaveService.setAutosaveStatus(false);
        var report = $scope.report;
        if (enableOnPremise) {
            hybridService.serverReportsGet(OfflineDatabase.getReportKey(report)).then(offlineReport => {
                report = offlineReport?.report;
                $scope.openReport(report, $scope.activeTab, true);
            });
            return;
        }
        $scope.openReport(report, $scope.activeTab, true);
    };

    $scope.refreshMergeReport = function(report) {
        // recalculate and revalidate fields
        var activeTab = $scope.activeTab;
        var targetState = ReportsApiService.getReportView(report);
        let params = { report: report, activeTab: activeTab, viewReport: true}
        if ($scope.autoSaving) {
            params['scrollLocation'] = $('.wrapper').scrollTop();
        }
        $state.go(targetState, params, {reload: targetState});
        if ($scope.autoSaving != true) {
            $scope.$emit('modal-message', { modalId: 'reportMerge'});
        }
    }

    // get ra data
    $scope.refreshRAData = function() {
        ReportsApiService.getRAData($rootScope.selectedVessel.id, $scope.report.id).then(function(response) {
            var data = response.data;
            if (!$scope.report.historical_data) {
                $scope.report.historical_data = {};
            }
            $scope.report.historical_data.kpis = data.kpis;
            $scope.report.historical_data.consumptions = data.consumptions;
        }, function(error) {
            console.log(error);
        });
    }
    $scope.refreshRAData();

    $scope.$on('vessel-specifications-received', function() {
        $scope.vessel_specifications = VesselSpecificationService.getSpecifications();       
    });

    $scope.datepickerShowing = false;
    $scope.$on('datepicker-show', function(event, args) {
        $scope.datepickerShowing = args.datepickerShowing;
    });

    $scope.$watch('vessel_specifications', function(newValue, oldValue, scope) {
        scope.auxEngineIndices = getAuxEngineIndices(newValue);
    }, true);

    $scope.$on('validate-form', function(event, args) {
        angular.forEach(args.tabs, function(tab) {
            if (moment($scope.report.operational.report_to, 'DD/MM/YYYY HH:mm') <= moment().subtract(2, 'weeks') && $scope.report.status == 'completed') {
                $scope.report.warningMessages = {};
                if ($scope.reportForm) {
                    $scope.reportForm.warningMessages = {};
                    angular.forEach(args.tabs, function(tab) {
                        $scope.disableTabValidation($scope.reportForm, tab);
                    });
                }
            } else {
                $scope.validateTab($scope.reportForm, tab);
            }
        });
    });

    // functionality for uploading BDN figure documents in bunker tabs, this
    // applies to Anchor and Port reports.
    $scope.uploadBDNFile = function(element) {
        var file = element.files[0];
        var form = new FormData();
        // var xhr = new XMLHttpRequest();

        form.append('BDNType', element.getAttribute('data-bdn-type'));
        form.append('filedata', file, file.name);

        // todo: make sure to re-add progress bar functionality once we upgrade
        // angular versions.
        // var progressBar = $('#uploadBDNProgressBar');
        // var result = $('#uploadBDNResult');
        // xhr.upload.onprogress = (function(file) {
        //     return function(e) {
        //         $scope.$apply(function() {
        //             progressBar.show();
        //             var percentCompleted = Math.round(e.loaded / e.total * 100);
        //             if (percentCompleted == 100) {
        //                 progressBar.hide();
        //                 result.text('Complete! Getting download link..');
        //             } else {
        //                 progressBar.attr('value', percentCompleted);
        //             }
        //         });
        //     };
        // })(file);

        // xhr.onreadystatechange = function() {
        //     if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
        //         var res = JSON.parse(xhr.responseText);
        //         $scope.report.bunker.upload_fuel_bdn_filename = res['data']['filename'];
        //         $scope.report.bunker.upload_fuel_bdn_dropbox_url = res['data']['dropbox_url'];
        //         $scope.$apply();
        //     }
        // };

        // xhr.open('POST', );
        // xhr.send(form);

        notify({message: 'Please wait, your BDN file is being processed', duration: 5000, classes: ['warning-notification']});

        var url = baseUrl + $scope.selectedVessel.id + '/reports/' + $scope.report.id + '/bdnUpload';
        const uploadBDNPromise = enableOnPremise ? customFetch({ url, body: form, method: 'POST' }) : $http.post(url, form, { transformRequest: angular.identity, headers: { 'Content-Type': undefined }});
        
        uploadBDNPromise.then(function(response) {
            var res = response.data;
            if (res.data.bdn_type === 'fuelBDN') {
                $scope.report.bunker.fuel_bdn_files = res.data.bdn_files;
                notify({message: 'BDN was successfully uploaded!', duration: 2000, classes: ['ok-notification']});
            } else if (res.data.bdn_type === 'lubeBDN') {
                $scope.report.bunker.lube_bdn_files = res.data.bdn_files;
                notify({message: 'BDN was successfully uploaded!', duration: 2000, classes: ['ok-notification']});
            } else {
                notify({message: 'Unknown BDN type: ' + res.data.bdn_type, duration: 2000, classes: ['bad-notification']});
            }
        }, function() {
            notify({message: 'Error uploading file, please try again later', duration: 2000, classes: ['bad-notification']});
        });
    };


    $scope.uploadCargoHeatingLog = function(element) {
        var file = element.files[0];
        var form = new FormData();

        form.append('filedata', file, file.name);

        notify({message: 'Please wait, your cargo heating log is being processed', duration: 5000, classes: ['warning-notification']});

        var url = baseUrl + $scope.selectedVessel.id + '/reports/' + $scope.report.id + '/cargoHeatingUpload';
        const uploadCargoPromise = enableOnPremise ? customFetch({ url, body: form, method: 'POST' }) : $http.post(url, form, { transformRequest: angular.identity, headers: { 'Content-Type': undefined }});

        uploadCargoPromise.then(function(response) {
            var res = response.data;
            $scope.report.consumption.cargo_heating_logs = res.data.cargo_heating_logs;
            notify({message: 'Cargo heating log was successfully uploaded!', duration: 2000, classes: ['ok-notification']});
        }, function() {
            notify({message: 'Error uploading file, please try again later', duration: 2000, classes: ['bad-notification']});
        });
    };

    $scope.uploadPortOperationLog = function(element) {
        var file = element.files[0];
        var form = new FormData();

        form.append('filedata', file, file.name);

        notify({message: 'Please wait, your port operation log is being processed', duration: 5000, classes: ['warning-notification']});

        var url = baseUrl + $scope.selectedVessel.id + '/reports/' + $scope.report.id + '/portOperationUpload';
        const uploadPortOperationLogPromise = enableOnPremise ? customFetch({ url, body: form, method: 'POST' }) : $http.post(url, form, { transformRequest: angular.identity, headers: { 'Content-Type': undefined }});

        uploadPortOperationLogPromise.then(function(response) {
            var res = response.data;
            $scope.report.consumption.port_operation_logs = res.data.port_operation_logs;
            notify({message: 'Port operation log was successfully uploaded!', duration: 2000, classes: ['ok-notification']});
        }, function() {
            notify({message: 'Error uploading file, please try again later', duration: 2000, classes: ['bad-notification']});
        });
    };

    $scope.deleteCargoHeatingLog = function(cargoHeatingLog) {
        var url = baseUrl + $scope.selectedVessel.id + '/reports/' + $scope.report.id + '/cargoHeatingUpload';
        requestFunc({
            url, 
            method: 'DELETE',
            params: { dropbox_url: cargoHeatingLog.dropbox_url }
        }).then(function(response) {
            var res = response.data;
            $scope.report.consumption.cargo_heating_logs = res.data.cargo_heating_logs;
            notify({message: 'Cargo heating log was successfully deleted!', duration: 2000, classes: ['ok-notification']});
        }, function() {
            notify({message: 'Error deleting cargo heating log', duration: 2000, classes: ['bad-notification']});
        });
    };

    $scope.deletePortOperationLog = function(portOperationLog) {
        var url = baseUrl + $scope.selectedVessel.id + '/reports/' + $scope.report.id + '/portOperationUpload';
        requestFunc({
            url, 
            method: 'DELETE',
            params: { dropbox_url: portOperationLog.dropbox_url }
        }).then(function(response) {
            var res = response.data;
            $scope.report.consumption.port_operation_logs = res.data.port_operation_logs;
            notify({message: 'Port operation log was successfully deleted!', duration: 2000, classes: ['ok-notification']});
        }, function() {
            notify({message: 'Error deleting port operation log', duration: 2000, classes: ['bad-notification']});
        });
    };

    $scope.deleteBDNFile = function(BDNFile, BDNType) {
        var url = baseUrl + $scope.selectedVessel.id + '/reports/' + $scope.report.id + '/bdnUpload';
        requestFunc({
            url, 
            method: 'DELETE',
            params: {
                dropbox_url: BDNFile.dropbox_url,
                BDNType: BDNType
            }
        }).then(function(response) {
            var res = response.data;
            if (res.data.bdn_type === 'fuelBDN') {
                $scope.report.bunker.fuel_bdn_files = res.data.bdn_files;
                notify({message: 'BDN was successfully deleted!', duration: 2000, classes: ['ok-notification']});
            } else if (res.data.bdn_type === 'lubeBDN') {
                $scope.report.bunker.lube_bdn_files = res.data.bdn_files;
                notify({message: 'BDN was successfully deleted!', duration: 2000, classes: ['ok-notification']});
            } else {
                notify({message: 'Unknown BDN type: ' + res.data.bdn_type, duration: 2000, classes: ['bad-notification']});
            }
        }, function() {

        });
    };


        $scope.$watchGroup(['report.power.shore_power_is_used', 'report.power.shaft_power', 'report.power.aux_engine_1_diff_rh', 'report.power.aux_engine_2_diff_rh', 'report.power.aux_engine_3_diff_rh', 'report.power.aux_engine_4_diff_rh','report.power.shaft_generator_pto_rh','report.power.shaft_generator_pti_rh', 'form'], function(newValues) {
        var shorePower = newValues[0];
        var shaftPower = newValues[1];
        var ae1rh = newValues[2] || 0;
        var ae2rh = newValues[3] || 0;
        var ae3rh = newValues[4] || 0;
        var ae4rh = newValues[5] || 0;
        var aeRhTotal = ae1rh + ae2rh + ae3rh + ae4rh;
        var ptoRH = newValues[6] || 0;
        var ptiRH = newValues[7] || 0;
        var pto_pti_RH = ptoRH + ptiRH;
        var auxRhEnabled = $scope.showByDefault('power.machinery_rh_counter', 'sections')
        var form = newValues[8] || {};
        var powerTabEnabled = form?.tabs?.power?.visible != false;
        const noShoreOrShaftPower = !(shorePower || shaftPower > 0);
        const hadAeRh = aeRhTotal > 0;
        const noPtoPtiRh = pto_pti_RH == 0;
        const requireForAasen = $scope.isAasenVessel() && ($scope.isPortReport($scope.report) || $scope.isAnchorReport($scope.report));
        $scope.shouldRequireAEFields = (noShoreOrShaftPower || hadAeRh || requireForAasen) && noPtoPtiRh && (auxRhEnabled || requireForAasen) && powerTabEnabled;
    });

    $scope.$on('vessel-report-received', function() {
        if ($scope.report && $scope.report.status != 'completed') {
            // reduce network request for edge build
            if (enableOnPremise) return;
            ReportsApiService.getPreviousCoordinates($rootScope.selectedVessel.id, $scope.report.id).then(function(response) {
                var data = response.data;
                $scope.previousCoordinates = data.data;
            });
        }
    });
    
    $scope.$on('vessel-report-received', function() {
        if ($scope.report.bdn_based_reporting) {
            $scope.enableBDNReporting();
        }
        $scope.report.activeBDNFuels = ReportService.generateActiveBDNFromReport($scope.report);
    });

    $scope.getPreviousCoordinates = function() {
        if ($scope.report.position.observations && removeEmptyValues($scope.report.position.observations).length > 0) {
            var index = $scope.observationIndex - 2;
            var previousObservations = $scope.report.position.observations;
            if (index >= 0) { return previousObservations[index]; }
            else { return $scope.previousCoordinates; }
        } else {
            return $scope.previousCoordinates;
        }
    };

    $scope.vesselCrossedDateLine = false;

    var coordinateAttributes = ['report.position.ship_lat_hours', 'report.position.ship_lat_minutes', 'report.position.ship_lat_seconds', 'report.position.ship_lat_direction',
                                'report.position.ship_lon_hours', 'report.position.ship_lon_minutes', 'report.position.ship_lon_seconds', 'report.position.ship_lon_direction'];

    $scope.$watchGroup(coordinateAttributes.concat(['observationIndex']), function(newValues) {
        var prevCoords = $scope.getPreviousCoordinates();
        if (!prevCoords) {
            $scope.vesselCrossedDateLine = false;
        } else {
            if ($scope.report.position.observations && removeEmptyValues($scope.report.position.observations).length > 0 && ($scope.observationIndex <= removeEmptyValues($scope.report.position.observations).length)) {
                var currCoords = $scope.report.position.observations[$scope.observationIndex - 1]
                var currentLonDirection = currCoords['ship_lon_direction'];
            } else {
                var currentLonDirection = $scope.report.position.ship_lon_direction;
            }
            var prevLonHours = prevCoords.ship_lon_hours;
            var prevLonDirection = prevCoords.ship_lon_direction;
            $scope.vesselCrossedDateLine = currentLonDirection != prevLonDirection && prevLonHours > 90;
        }
    });

    $scope.coordinatesAddWarning = function(newValues) {
        var prevCoords = $scope.getPreviousCoordinates();
        if (!prevCoords || $scope.report._cls === 'Report.PortReport') return;

        if (!$scope.report.warningMessages) {
            $scope.report.warningMessages = {};
        }

        if ($scope.report.position.observations && removeEmptyValues($scope.report.position.observations).length > 0 && ($scope.observationIndex <= removeEmptyValues($scope.report.position.observations).length)) {
            var currCoords = $scope.report.position.observations[$scope.observationIndex - 1]
            var lat = hmsdToDegrees(currCoords['ship_lat_hours'], currCoords['ship_lat_minutes'], currCoords['ship_lat_seconds'], currCoords['ship_lat_direction']);
            var lon = hmsdToDegrees(currCoords['ship_lon_hours'], currCoords['ship_lon_minutes'], currCoords['ship_lon_seconds'], currCoords['ship_lon_direction']);
        } else {
            var lat = hmsdToDegrees(newValues[0], newValues[1], newValues[2], newValues[3]);
            var lon = hmsdToDegrees(newValues[4], newValues[5], newValues[6], newValues[7]);
        }

        // If the previous longitude direction is different from the current
        // one, and the previous longitude hours were greater than 90, than the
        // vessel almost certainly crossed the international date line. In which
        // case, disable the validation.
        var crossedDateline = newValues[9];

        var prevLat = hmsdToDegrees(prevCoords.ship_lat_hours, prevCoords.ship_lat_minutes, prevCoords.ship_lat_seconds, prevCoords.ship_lat_direction);
        var prevLon = hmsdToDegrees(prevCoords.ship_lon_hours, prevCoords.ship_lon_minutes, prevCoords.ship_lon_seconds, prevCoords.ship_lon_direction);

        var latLonDistance = getDistanceFromLatLonIn(prevLat, prevLon, lat, lon);
        var observedDistance = newValues[8];
        var addWarning = latLonDistance && observedDistance && (latLonDistance > 1.15 * observedDistance) && !crossedDateline;
        $scope.report.warningMessages['report.position.ship_lat_hours'] = addWarning ? { message: 'Calculated distance from position in previous report is much greater than the entered observed distance' } : undefined;
        $scope.report.warningMessages['report.position.ship_lon_hours'] = addWarning ? { message: 'Calculated distance from position in previous report is much greater than the entered observed distance' } : undefined;
    }

    $scope.$watchGroup(coordinateAttributes.concat(['report.position.observed_distance', 'vesselCrossedDateLine', 'observationIndex']), function(newValues) {
        if ($scope.previousCoordinates == undefined) {
            ReportsApiService.getPreviousCoordinates($rootScope.selectedVessel.id, $scope.report.id).then(function(response) {
                var data = response.data;
                $scope.previousCoordinates = data.data;
                $scope.coordinatesAddWarning(newValues);
            });
        } else {
            $scope.coordinatesAddWarning(newValues);
        }
    });

    var hmsdToDegrees = function(hours, minutes, seconds, direction) {
        if (hours == undefined || minutes == undefined || seconds == undefined || direction == undefined) return undefined;

        var deg = hours + minutes / 60 + seconds / 3600;
        if (direction === 'S' || direction === 'W') {
            deg = deg * -1;
        }
        return deg;
    };

    var getDistanceFromLatLonIn = function(lat1,lon1,lat2,lon2) {
        // https://stackoverflow.com/a/27943/1279369
        var deg2rad = function(deg) {
            return deg * (Math.PI/180);
        };
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1);
        var a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d * 0.539957; // convert to nm
    };

    $scope.uploadReport = function(element) {
        var file = element.files[0];
        if (!file) return;
        var form = new FormData();

        form.append('filedata', file, file.name);

        notify({message: 'Please wait, your report is being processed', duration: 2000, classes: ['warning-notification']});

        ReportsApiService.uploadReport($scope.selectedVessel.id, form).then(function(response) {
            var res = response.data;
            notify({message: 'Report was successfully uploaded!', duration: 2000, classes: ['ok-notification']});
            $('#upload-excel-report').modal('hide');
            var report = res.data.report;
            var reportType = res.data.report_type;

            ReportsApiService.getCarryOverData($rootScope.selectedVessel.id).then(function(response) {
                var carryOverReport = response.data;
                if (carryOverReport != null) {
                    $scope.carryOverReport = carryOverReport;
                    ReportService.updateVesselReportWithCarryForwardReport(ReportService.getVesselReport(), carryOverReport);
                }
                ReportService.initializeUploadReport(report, reportType);
                $scope.refreshRAData();
                $scope.report = ReportService.getVesselReport();
                $scope.$broadcast('validate-form', { tabs: ['operational', 'position', 'power', 'engine', 'consumptions', 'stock'] });
                $('.wrapper').scrollTop(0);
            });

        }, function() {
            notify({message: 'Error uploading file, please try again later', duration: 2000, classes: ['bad-notification']});
        });

        element.value = null;
    };

    $scope.shouldRequireFreshWater = function() {
        return $scope.report.power && $scope.report.power.main_engine_1_diff_rh > 12 && !$scope.report.consumption.fwg_non_operational;
    };

    $scope.stockThresholdMultiplier = 1.05;
    ValidationService.setStore('stockThresholdMultiplier', $scope.stockThresholdMultiplier );

    $scope.stockThresholdDisplay = function() {
        var n = Math.round(($scope.stockThresholdMultiplier - 1) * 100);

        if (n >= 0) {
            return ' + ' + n + '%';
        } else {
            return ' - ' + n + '%';
        }
    };

    $scope.getStockThreshold = function(stock, bunker) {
        return stock * $scope.stockThresholdMultiplier + bunker;
    };

    // TODO: (80109)
    $scope.shouldHaveMinimum = function(grade) {
        var currentStock = $scope.report.stock[grade] || 0;
        if (!$scope.carryOverReport || !$scope.carryOverReport.stock) return false;
        var previousStock = $scope.carryOverReport.stock[grade] || 0;

        // If current stock > previous stock then respective bunker field must be > 0
        if (currentStock > $scope.getStockThreshold(previousStock)) {
            return true;
        } else {
            // If current stock <= previous stock, there is no minimum
            return false;
        }
    };

    const bunkeringFields = ['hshfo', 'lshfo', 'hsmdo', 'ulsfo', 'lsmdo', 'hsmgo', 'lsmgo', 'fresh_water', 'distilled_water', 'caustic_soda', 'urea', 'lube_oil_system', 'gasoline'];
    const bunkeringFieldsToValidate = [...bunkeringFields, ...Object.keys(fuelGrades)];
    $scope.showBunkerStockWarning = function(grade, quantityField) {
        if ($scope.shouldRequireBunkerFields(grade) &&bunkeringFieldsToValidate.indexOf(grade) != -1) {
            if ($scope.report.bunker[quantityField] === undefined || $scope.report.bunker[quantityField] === "") {
                var gradeName = '';
                if (grade == 'fresh_water') {
                    gradeName = 'Fresh Water';
                } else if (grade == 'distilled_water') {
                    gradeName = 'Distilled Water';
                } else if (grade == 'caustic_soda') {
                    gradeName = 'Caustic Soda';
                } else if (grade == 'urea') {
                    gradeName = 'Urea';
                } else if (grade == 'lube_oil') {
                    gradeName = 'Lube Oil';
                } else if (grade == 'gasoline') {
                    gradeName = 'Gasoline';
                } else {
                    gradeName = grade.toUpperCase();
                }
                return 'Quantity Ordered ' + gradeName + ' should be entered';
            }
        }

        // If current stock > previous stock then respective bunker field must be > 0
        if ($scope.shouldHaveMinimum(grade)) {
            var quantity = $scope.report.bunker[quantityField] || 0;
            if (quantity > 0) {
                return false;
            } else {
                return grade.toUpperCase() + ' should be > 0 since the stock value increased from the previous report';
            }
        } else {
            return false;
        }
    };

    $scope.$watch('report.stock.tank_based_reporting', function(newValue, oldValue, scope) {
        if (newValue != undefined && newValue == false) {
            angular.forEach(['hshfo', 'lshfo', 'ulsfo', 'hsmdo', 'lsmdo', 'hsmgo', 'lsmgo'], function(grade) {
                var totalTankStockFieldName = 'tank' + grade.toUpperCase();
                if (scope.reportForm && scope.reportForm.stock && scope.reportForm.stock.tankReporting && scope.reportForm.stock.tankReporting[totalTankStockFieldName]) {
                    scope.reportForm.stock.tankReporting[totalTankStockFieldName].$setValidity('inaccurate_bunkers', true);
                }
            });
        }
    });

    $scope.$watchGroup(['report.operational.displacement', 'report.operational.ballast_water', 'vesselSpecs.information.displacement_at_scantling_draught', 'vesselSpecs.information.dwt', 'report.operational.fixed_ballast', 'report.operational.constant_weight', 'report.operational.weight'], function(newValues, oldValues, scope) {
        if (scope.isSeaReport(scope.report) || scope.isManReport(scope.report)) {
            // currently not needed. Will re-enable when requested to turn on cargo weight min/max warning
            // var fixedBallast = newValues[4];
            // var constantWeight = newValues[5];
            // var displacement = newValues[0] + fixedBallast + constantWeight;
            // var ballast = newValues[1];
            // var lightShip = newValues[2] - newValues[3];
            // var cargoWeight = newValues[6]
            // var cargo_max = (displacement - lightShip) * 1.05;
            // scope.report.warningMessages['cargo_max'] = cargoWeight >= cargo_max ? 'Cargo weight must be ≤ ' + cargo_max.toFixed(2) + ' MT' : undefined;
            // if (scope.report.stock.total_stock_fuel != undefined) {
            //     var fuel = scope.report.stock.total_stock_fuel;
            // }
            // var cargo_min = (displacement - lightShip - fuel - ballast - fixedBallast - constantWeight) * 0.9;
            // scope.report.warningMessages['cargo_min'] = cargoWeight <= cargo_min ? 'Cargo weight must be ≥ ' + cargo_min.toFixed(2) + ' MT' : undefined;
        }
    });

    $scope.shouldDisableCargoWeight = function() {
      if ($scope.role != 'shore_user') {
        return false;
      }
      
      return !(['Report.PortReport', 'Report.AnchorReport'].includes($scope.report._cls) || 
      $scope.report.operational.operating_code == 8 ||  
      $scope.report.operational.in_sts_voyage === true);
  };

    $scope.$watchGroup(['report.stock.lube_oil_system', 'report.bunker.quantity_received_me_lube_oil_system'], function(newValues) {
        if (newValues[0] == undefined || !$scope.carryOverReport || !$scope.carryOverReport.stock) return;
        if (!$scope.report.warningMessages) $scope.report.warningMessages = {};
        var bunker = newValues[1] || 0;

        if (newValues[0] > $scope.getStockThreshold($scope.carryOverReport.stock.lube_oil_system, bunker)) {
            $scope.report.warningMessages['report.stock.lube_oil_system'] = { message: 'ME lube oil system must be less than the previous stock of ' + $scope.carryOverReport.stock.lube_oil_system + $scope.stockThresholdDisplay() + ' and ≥ 0 Liters' };
        } else {
            $scope.report.warningMessages['report.stock.lube_oil_system'] = null;
        }
    });

    $scope.$watchGroup(['report.stock.lube_oil_spare', 'report.bunker.quantity_received_me_lube_oil_spare'], function(newValues) {
        if (newValues[0] == undefined || !$scope.carryOverReport || !$scope.carryOverReport.stock) return;
        if (!$scope.report.warningMessages) $scope.report.warningMessages = {};
        var bunker = newValues[1] || 0;

        if (newValues[0] > $scope.getStockThreshold($scope.carryOverReport.stock.lube_oil_spare, bunker)) {
            $scope.report.warningMessages['report.stock.lube_oil_spare'] = { message: 'ME lube oil spare must be less than the previous stock of ' + $scope.carryOverReport.stock.lube_oil_spare + $scope.stockThresholdDisplay() + ' and ≥ 0 Liters' };
        } else {
            $scope.report.warningMessages['report.stock.lube_oil_spare'] = null;
        }
    });

    $scope.$watchGroup(['report.stock.cyl_oil_low_bn', 'report.bunker.quantity_received_cylinder_oil_low_bn'], function(newValues) {
        if (newValues[0] == undefined || !$scope.carryOverReport || !$scope.carryOverReport.stock) return;
        if (!$scope.report.warningMessages) $scope.report.warningMessages = {};
        var bunker = newValues[1] || 0;

        if (newValues[0] > $scope.getStockThreshold($scope.carryOverReport.stock.cyl_oil_low_bn, bunker)) {
            $scope.report.warningMessages['report.stock.cyl_oil_low_bn'] = { message: 'Low BN Cylinder oil must be less than the previous stock of ' + $scope.carryOverReport.stock.cyl_oil_low_bn + $scope.stockThresholdDisplay() + ' and ≥ 0 Liters' };
        } else {
            $scope.report.warningMessages['report.stock.cyl_oil_low_bn'] = null;
        }
    });

    $scope.$watchGroup(['report.power.shaft_generator_pto_rh', 'report.power.shaft_generator_pto_power','report.power.shaft_motor_pti_rh'], function(newValues, oldValues, scope) {
        if (newValues[0] == undefined) return;
        if (!scope.report.warningMessages) scope.report.warningMessages = {};
        var ptoRH = newValues[0] || 0;
        var ptoPower = newValues[1] || 0;

        if (ptoPower>0) {
            scope.report.warningMessages['report.power.shaft_generator_pto_rh'] = { message: 'PTO Shaft Generator running hours should be between 0 and ' + scope.shaftGeneratorPtoRHMax().toFixed(2) };
            scope.PTOtooltipClass = 'tooltip-warning'
            if (ptoRH > scope.shaftGeneratorPtoRHMax().toFixed(2)) scope.PTOtooltipClass = 'tooltip-error'
        }
        
    });

    $scope.$watchGroup(['report.power.shaft_motor_pti_rh','report.power.shaft_motor_pti_power','report.power.shaft_generator_pto_rh'], function(newValues, oldValues, scope) {
        if (newValues[0] == undefined) return;
        if (!scope.report.warningMessages) scope.report.warningMessages = {};
        var ptiRH = newValues[0] || 0;
        var ptiPower = newValues[1] || 0;

        if (ptiPower>0) {
            scope.report.warningMessages['report.power.shaft_motor_pti_rh'] = { message: 'PTI Shaft Motor running hours should be between 0 and ' + scope.shaftMotorPtiRHMax().toFixed(2) };
            scope.PTItooltipClass = 'tooltip-warning'
            if (ptiRH > scope.shaftMotorPtiRHMax().toFixed(2)) scope.PTItooltipClass = 'tooltip-error'
        }
        
    });

    $scope.$watchGroup(['report.stock.cyl_oil', 'report.bunker.quantity_received_cylinder_oil'], function(newValues) {
        if (newValues[0] == undefined || !$scope.carryOverReport || !$scope.carryOverReport.stock) return;
        if (!$scope.report.warningMessages) $scope.report.warningMessages = {};
        var bunker = newValues[1] || 0;

        if (newValues[0] > $scope.getStockThreshold($scope.carryOverReport.stock.cyl_oil, bunker)) {
            $scope.report.warningMessages['report.stock.cyl_oil'] = { message: 'High BN Cylinder oil must be less than the previous stock of ' + $scope.carryOverReport.stock.cyl_oil + $scope.stockThresholdDisplay() + ' and ≥ 0 Liters' };
        } else {
            $scope.report.warningMessages['report.stock.cyl_oil'] = null;
        }
    });

    $scope.$watchGroup(['report.stock.turbo_oil', 'report.bunker.quantity_received_turbo_oil'], function(newValues) {
        if (newValues[0] == undefined || !$scope.carryOverReport || !$scope.carryOverReport.stock) return;
        if (!$scope.report.warningMessages) $scope.report.warningMessages = {};
        var bunker = newValues[1] || 0;

        if (newValues[0] > $scope.getStockThreshold($scope.carryOverReport.stock.turbo_oil, bunker)) {
            $scope.report.warningMessages['report.stock.turbo_oil'] = { message: 'Turbo oil must be less than the previous stock of ' + $scope.carryOverReport.stock.turbo_oil + $scope.stockThresholdDisplay() + ' and ≥ 0 Liters' };
        } else {
            $scope.report.warningMessages['report.stock.turbo_oil'] = null;
        }
    });

    $scope.$watchGroup(['report.stock.hydraulic_oil', 'report.bunker.quantity_received_hydraulic_oil'], function(newValues) {
        if (newValues[0] == undefined || !$scope.carryOverReport || !$scope.carryOverReport.stock) return;
        if (!$scope.report.warningMessages) $scope.report.warningMessages = {};
        var bunker = newValues[1] || 0;

        if (newValues[0] > $scope.getStockThreshold($scope.carryOverReport.stock.hydraulic_oil, bunker)) {
            $scope.report.warningMessages['report.stock.hydraulic_oil'] = { message: 'Hydraulic oil must be less than the previous stock of ' + $scope.carryOverReport.stock.hydraulic_oil + $scope.stockThresholdDisplay() + ' and ≥ 0 Liters' };
        } else {
            $scope.report.warningMessages['report.stock.hydraulic_oil'] = null;
        }
    });

    $scope.$watchGroup(['report.stock.other_oil', 'report.bunker.quantity_received_other_oil'], function(newValues) {
        if (newValues[0] == undefined || !$scope.carryOverReport || !$scope.carryOverReport.stock) return;
        if (!$scope.report.warningMessages) $scope.report.warningMessages = {};
        var bunker = newValues[1] || 0;

        if (newValues[0] > $scope.getStockThreshold($scope.carryOverReport.stock.other_oil, bunker)) {
            $scope.report.warningMessages['report.stock.other_oil'] = { message: 'Other_Oil oil must be less than the previous stock of ' + $scope.carryOverReport.stock.other_oil + $scope.stockThresholdDisplay() + ' and ≥ 0 Liters' };
        } else {
            $scope.report.warningMessages['report.stock.other_oil'] = null;
        }
    });

    $scope.isFirstReport = function() {
        return !$scope.report.report_number || $scope.report.report_number === 1
    }

      $scope.onShorePowerHoursMin = () => {
        const isShorePowerUsed = $scope.report && $scope.report.power && $scope.report.power.shore_power_is_used
        if (isShorePowerUsed) {
            const hours = $scope.report.power.shore_power_hours || 0
            $scope.reportForm.power.runningHours.shorePowerHours?.$setValidity(
                'shorePowerHours',
                hours > 0
            )
        }
        if (!$scope.report.power.shore_power_is_used) {
          $scope.reportForm.power.runningHours.shorePowerHours?.$setValidity(
            'shorePowerHours',
            null
          )
          $scope.reportForm.power.runningHours.shorePowerHours?.$setPristine()
        }
        
        return 0
    }

      $scope.onShorePowerKwhMin = () => {
        const isShorePowerUsed = $scope.report && $scope.report.power && $scope.report.power.shore_power_is_used
        if (isShorePowerUsed) {
            const kwh = $scope.report.power.shore_power_kwh || 0
            $scope.reportForm.power.runningHours.shorePowerKwh?.$setValidity(
                'shorePowerKwh',
                kwh > 0
            )
        }
        if (!$scope.report.power.shore_power_is_used) {
          $scope.reportForm.power.runningHours.shorePowerKwh?.$setValidity(
            'shorePowerKwh',
            null
          )
          $scope.reportForm.power.runningHours.shorePowerKwh?.$setPristine()
        }
        return 0
    }

    $scope.getME1RunningHourMin = function() {
        if ($scope.report && $scope.report.power) {
            var meDiff = $scope.report.power.main_engine_1_diff_rh;
            var validity = true;
            if ($scope.isOfReportType($scope.report, 'Report.SeaReport')) {
                if ($scope.selectedVessel.dualEngine) {
                    meDiff = $scope.report.power.main_engine_1_diff_rh + $scope.report.power.main_engine_2_diff_rh;
                }
                if (meDiff < $scope.report.operational.report_period - 4) {
                    validity = false;
                }
                if ($scope.reportForm && $scope.reportForm.power && $scope.reportForm.power.runningHours && $scope.reportForm.power.runningHours.mainEngine1CurRH) {
                    $scope.reportForm.power.runningHours.mainEngine1CurRH.$setValidity('runningHourValidation', validity);
                }
                return ($scope.report.power.main_engine_1_power > 0 && $scope.report.operational.report_period > 4)
                    ? $scope.report.power.main_engine_1_prev_rh + $scope.report.operational.report_period - 4
                    : $scope.report.power.main_engine_1_prev_rh;
            } else {
                if ($scope.reportForm && $scope.reportForm.power && $scope.reportForm.power.runningHours && $scope.reportForm.power.runningHours.mainEngine1CurRH) {
                    $scope.reportForm.power.runningHours.mainEngine1CurRH.$setValidity('runningHourValidation', validity);
                }
                return $scope.report.power.main_engine_1_power > 0 ? $scope.report.power.main_engine_1_prev_rh + 0.1: $scope.report.power.main_engine_1_prev_rh;
            }
        }
    };
    $scope.getME1RunningHourMax = function() {
        if ($scope.report && $scope.report.power) return $scope.report.power.main_engine_1_prev_rh + $scope.report.operational.report_period + 2;
    };
    $scope.getME2RunningHourMin = function() {
        if ($scope.report && $scope.report.power) {
            var meDiff = $scope.report.power.main_engine_1_diff_rh + $scope.report.power.main_engine_2_diff_rh;
            var validity = true;
            if ($scope.isOfReportType($scope.report, 'Report.SeaReport')) {

                if (meDiff < $scope.report.operational.report_period - 4) {
                    validity = false;
                }
                if ($scope.reportForm && $scope.reportForm.power && $scope.reportForm.power.runningHours && $scope.reportForm.power.runningHours.mainEngine2CurRH) {
                    $scope.reportForm.power.runningHours.mainEngine2CurRH.$setValidity('runningHourValidation', validity);
                }
                return ($scope.report.power.main_engine_2_power > 0 && $scope.report.operational.report_period > 4)
                    ? $scope.report.power.main_engine_2_prev_rh + $scope.report.operational.report_period - 4
                    : $scope.report.power.main_engine_2_prev_rh;
            } else {
                if ($scope.reportForm && $scope.reportForm.power && $scope.reportForm.power.runningHours && $scope.reportForm.power.runningHours.mainEngine2CurRH) {
                    $scope.reportForm.power.runningHours.mainEngine2CurRH.$setValidity('runningHourValidation', validity);
                }
                return $scope.report.power.main_engine_2_power > 0 ? $scope.report.power.main_engine_2_prev_rh + 0.1: $scope.report.power.main_engine_2_prev_rh;
            }
        }
    };
    $scope.getME2RunningHourMax = function() {
        if ($scope.report && $scope.report.power) return $scope.report.power.main_engine_2_prev_rh + $scope.report.operational.report_period + 2;
    };

    $scope.isMainEngineRunning = function(mainEngineIndex) {
        if (mainEngineIndex == 0) {
            return $scope.report.power.main_engine_1_diff_rh > 0;
        } else if (mainEngineIndex == 1) {
            return $scope.report.power.main_engine_2_diff_rh > 0;
        } else {
            return false;
        }
    }

    $scope.formatObservationDate = function(observation) {
        var dt = TimezoneService.dtWithTz(observation.observation, observation.observation_timezone || $scope.report.operational.timezone);
        return dt && dt._isAMomentObject ? dt.format('DD/MM/YYYY HH:mm Z') : '';
    };

    $scope.isKuokuyoClass = function() {
        return $scope.vessel_specifications && $scope.vessel_specifications.information.vessel_class == 'Kuokuyo';
    };

    $scope.isNotAnchorReport = function() {
        return !$scope.isAnchorReport($scope.report);
    };

    $scope.vesselTookBunkers = function() {
        return !!$scope.report.bunker.took_fuel_oil || !!$scope.report.bunker.took_lube_oil;
    };

    $scope.vesselDeBunkered = function() {
        return !!$scope.report.bunker.debunkered;
    };
    $scope.vesselDebunkered = function() {
        return $scope.report.bunker && $scope.report.bunker.debunkered;
    };

    $scope.shouldRequireBunkerFields = function(grade) {
        // Don't need to require bunker fields if vessel didn't take bunkers
        if (!$scope.vesselTookBunkers() || !grade) {
            return false;
        }

        // Require a value to be entered into at least one quantity_received field
        var allEmpty = ['hshfo', 'lshfo', 'ulsfo', 'hsmdo', 'lsmdo', 'hsmgo', 'lsmgo', 'fresh_water', 'distilled_water', 'caustic_soda', 'urea'].every(function(grade) {
            var qtyReceived = $scope.report.bunker['quantity_received_' + grade];
            return qtyReceived == undefined || qtyReceived == '';
        });
        if (allEmpty) return true;

        // If there's a value entered into the grade's qty received field, then
        // require the other fields as well.
        var qtyReceived = $scope.report.bunker['quantity_received_' + grade];
        if (qtyReceived != undefined && qtyReceived != '') {
            return true;
        }

        return false;
    };

    $timeout(function() {
        var reportFormElement = $("[name='reportForm']");
        var getReportFormScope = function() { return angular.element(reportFormElement).scope(); };

        // Warning message if field is empty
        angular.forEach([
            { modelPath: 'report.position.air_temperature',                         getFormObject: function(reportForm) { return reportForm.position && reportForm.position.observation && reportForm.position.observation.airTemperature; } },
            { modelPath: 'report.position.sea_temperature',                         getFormObject: function(reportForm) { return reportForm.position && reportForm.position.observation && reportForm.position.observation.seaTemperature; } },
            { modelPath: 'report.position.observed_distance_for_total_sea_passage', getFormObject: function(reportForm) { return reportForm.position && reportForm.position.observation && reportForm.position.observation.observedDistanceForTotalSeaPassage; }, enabledFor: ['Report.SeaReport'] },
            { modelPath: 'report.position.remaining_distance',                      getFormObject: function(reportForm) { return reportForm.position && reportForm.position.observation && reportForm.position.observation.remainingDistance; },                  enabledFor: ['Report.SeaReport'] },
            { modelPath: 'report.position.propeller_rpm',                           getFormObject: function(reportForm) { return reportForm.position && reportForm.position.observation && reportForm.position.observation.propellerRPM; },                       enabledFor: ['Report.SeaReport'] },
            { modelPath: 'report.position.trip_counter_gps',                        getFormObject: function(reportForm) { return reportForm.position && reportForm.position.observation && reportForm.position.observation.tripCounterGps; },                     enabledFor: ['Report.SeaReport'] },
            { modelPath: 'report.position.trip_counter_log',                        getFormObject: function(reportForm) { return reportForm.position && reportForm.position.observation && reportForm.position.observation.tripCounterLog; },                     enabledFor: ['Report.SeaReport'] },
            { modelPath: 'report.power.main_engine_1_shaft_power_meter_counter',    getFormObject: function(reportForm) { return reportForm.power && reportForm.power.mainEngine1ShaftPowerMeterCounter; } },
            { modelPath: 'report.power.avg_tcrpm_1',                                getFormObject: function(reportForm) { return reportForm.power && reportForm.power.avgTCRPM; } },
            { modelPath: 'report.power.avg_tcrpm_2',                                getFormObject: function(reportForm) { return reportForm.power && reportForm.power.avgTCRPM2; } },
            { modelPath: 'report.power.aux_boiler_cur_rh',                          getFormObject: function(reportForm) { return reportForm.power && reportForm.power.runningHours && reportForm.power.runningHours.auxBoilerCurRH; } },
            { modelPath: 'report.power.aux_boiler_2_cur_rh',                        getFormObject: function(reportForm) { return reportForm.power && reportForm.power.runningHours && reportForm.power.runningHours.auxBoiler2CurRH; } },
            { modelPath: 'report.power.main_engine_comp_1_cur_rh',                  getFormObject: function(reportForm) { return reportForm.power && reportForm.power.runningHours && reportForm.power.runningHours.mainAir1CurRH; } },
            { modelPath: 'report.power.main_engine_comp_2_cur_rh',                  getFormObject: function(reportForm) { return reportForm.power && reportForm.power.runningHours && reportForm.power.runningHours.mainAir2CurRH; } },
            { modelPath: 'report.power.main_engine_comp_3_cur_rh',                  getFormObject: function(reportForm) { return reportForm.power && reportForm.power.runningHours && reportForm.power.runningHours.mainAir3CurRH; } },
            { modelPath: 'report.power.main_engine_comp_4_cur_rh',                  getFormObject: function(reportForm) { return reportForm.power && reportForm.power.runningHours && reportForm.power.runningHours.mainAir4CurRH; } },
            { modelPath: 'report.power.booster_unit_hfo_cur_rh',                    getFormObject: function(reportForm) { return reportForm.power && reportForm.power.boosterUnitHFO; } },
            { modelPath: 'report.power.booster_unit_gas_oil_cur_rh',                getFormObject: function(reportForm) { return reportForm.power && reportForm.power.boosterUnitGasOil; } },
            { modelPath: 'report.power.main_engine_flow_cur_rh',                    getFormObject: function(reportForm) { return reportForm.power && reportForm.power.mainEngineFlow; } },
            { modelPath: 'report.power.main_engine_flow_inlet_cur_rh',              getFormObject: function(reportForm) { return reportForm.power && reportForm.power.mainEngineInlet; } },
            { modelPath: 'report.power.common_flow_hfo_cur_rh',                     getFormObject: function(reportForm) { return reportForm.power && reportForm.power.mainEngineHFO; } },
            { modelPath: 'report.power.main_engine_flow_do_cur_rh',                 getFormObject: function(reportForm) { return reportForm.power && reportForm.power.mainEngineDo; } },
            { modelPath: 'report.power.main_engine_flow_outlet_cur_rh',             getFormObject: function(reportForm) { return reportForm.power && reportForm.power.mainEngineOutletFlow; } },
            { modelPath: 'report.power.common_flow_mgo_cur_rh',                     getFormObject: function(reportForm) { return reportForm.power && reportForm.power.mainEngineMGOFlow; } },
            { modelPath: 'report.power.aux_engine_flow_cur_rh',                     getFormObject: function(reportForm) { return reportForm.power && reportForm.power.auxEngineFlow; } },
            { modelPath: 'report.power.aux_engine_flow_inlet_cur_rh',               getFormObject: function(reportForm) { return reportForm.power && reportForm.power.auxEngineInlet; } },
            { modelPath: 'report.power.aux_engine_flow_hfo_cur_rh',                 getFormObject: function(reportForm) { return reportForm.power && reportForm.power.auxEngineHFO; } },
            { modelPath: 'report.power.aux_engine_flow_do_cur_rh',                  getFormObject: function(reportForm) { return reportForm.power && reportForm.power.auxEngineDo; } },
            { modelPath: 'report.power.aux_engine_flow_outlet_cur_rh',              getFormObject: function(reportForm) { return reportForm.power && reportForm.power.auxEngineFlowOutlet; } },
            { modelPath: 'report.power.aux_boiler_flow_cur_rh',                     getFormObject: function(reportForm) { return reportForm.power && reportForm.power.boilerFlow; } },
            { modelPath: 'report.power.aux_boiler_2_flow_cur_rh',                   getFormObject: function(reportForm) { return reportForm.power && reportForm.power.boiler2Flow; } },
            { modelPath: 'report.power.composite_boiler_flow_cur_rh',               getFormObject: function(reportForm) { return reportForm.power && reportForm.power.boiler3Flow; } },
            { modelPath: 'report.power.cylinder_lube_oil_cur',                      getFormObject: function(reportForm) { return reportForm.power && reportForm.power.cylinderLubeOil; } },
            { modelPath: 'report.power.aux_boiler_flow_inlet_cur_rh',               getFormObject: function(reportForm) { return reportForm.power && reportForm.power.boilerFlowInlet; } },
            { modelPath: 'report.power.aux_boiler_flow_outlet_cur_rh',              getFormObject: function(reportForm) { return reportForm.power && reportForm.power.boilerFlowOutlet; } },
            { modelPath: 'report.power.composite_boiler_cur_rh',                    getFormObject: function(reportForm) { return reportForm.power && reportForm.power.auxBoiler3CurRH; } },
            { modelPath: 'report.power.shaft_cur_rh',                               getFormObject: function(reportForm) { return reportForm.power && reportForm.power.shaftCurRH; } },
            { modelPath: 'report.power.turbo_power',                                getFormObject: function(reportForm) { return reportForm.power && reportForm.power.turboPower; } },
            { modelPath: 'report.stock.sludge',                                     getFormObject: function(reportForm) { return reportForm.stock && reportForm.stock.oilSludge; } },
            { modelPath: 'report.engine.main_engines[0].fuel_pump_index_reported',  getFormObject: function(reportForm) { return reportForm.engine && reportForm.engine.fuelPumpIndex0; },                                   fieldName: 'Fuel Pump Index (Main Engine 1)', enabledFor: ['Report.SeaReport']},
            { modelPath: 'report.engine.main_engines[1].fuel_pump_index_reported',  getFormObject: function(reportForm) { return reportForm.engine && reportForm.engine.fuelPumpIndex1; },                                   fieldName: 'Fuel Pump Index (Main Engine 2)', enabledFor: ['Report.SeaReport']},
            { modelPath: 'report.operational.anchored',                             getFormObject: function(reportForm) { return reportForm.operational && reportForm.operational.anchored; },                                    enabledFor: ['Report.AnchorReport'] },
            { modelPath: 'report.operational.anchor_heaved',                        getFormObject: function(reportForm) { return reportForm.operational && reportForm.operational.anchorHeaved; },                                enabledFor: ['Report.AnchorReport'] },
            { modelPath: 'report.operational.remaining_distance',                   getFormObject: function(reportForm) { return reportForm.operational && reportForm.operational.remainingDistance; } },
            { modelPath: 'report.operational.etd',                                  getFormObject: function(reportForm) { return reportForm.operational && reportForm.operational.etd; } },
            { modelPath: 'report.operational.all_fast',                             getFormObject: function(reportForm) { return reportForm.operational && reportForm.operational.allFast; } },
            { modelPath: 'report.operational.standby_engines',                      getFormObject: function(reportForm) { return reportForm.operational && reportForm.operational.standbyEngines; } },
            { modelPath: 'report.operational.cosp',                                 getFormObject: function(reportForm) { return reportForm.operational && reportForm.operational.cosp; },                                        enabledFor: ['Report.SeaReport'] },
        ], function(field) {
            $scope.$watch(field.modelPath, function() {
                // If field.enabledFor, enable the warning only for the
                // specified report types. Else, it's enabled by default for
                // all report types.
                if (field.enabledFor) {
                    var shouldEnable = field.enabledFor.some(function(reportType) {
                        return $scope.isOfReportType($scope.report, reportType);
                    });
                    if (!shouldEnable) return;
                }

                // Get the display name of the field or build it from the
                // attribute name of the model path.
                var modelName = field.fieldName || field.modelPath.replace(/report\..*?\./, '').replace(/_/g, ' ').replace(/\b\w/g, function(l){ return l.toUpperCase(); });

                // Return if the specified form object doesn't exist.
                var formObject = field.getFormObject(getReportFormScope().reportForm);
                if (!formObject) return;

                // Use $$rawModelValue to test against undefined because if
                // the value is invalid then the regular model value is also
                // undefined. We want to test if the input field is empty,
                // not test if the model is undefined.
                var modelValue = formObject.$$rawModelValue;
                $scope.report.warningMessages[field.modelPath] = modelValue != undefined ? null : modelName + ' should be reported';
            });
        });

        $scope.$watchGroup(['report.position.wave_height', 'report.position.true_wind_speed'], function(newValues) {
            var waveHeight = newValues[0];
            var tws = newValues[1];

            var waveHeightCalc = 1.726E-04 * Math.pow(tws, 3) + 1.723E-03 * Math.pow(tws, 2) + 2.503E-01 * tws - 1.602E-01;

            var invalid = Math.abs(waveHeight - waveHeightCalc) > 2;
            $scope.report.warningMessages['report.position.wave_height'] = invalid ? 'Wave Height does not correspond with True Wind Speed. Please check that Wind Speed and Wave Height are correct' : undefined;
        });

        $scope.$watchGroup(['report.engine.main_engines[0].main_engine_instant_power', 'report.power.main_engine_1_power', 'report.operational.operating_code'], function(newValues) {
            var instantPower = newValues[0];
            var mePower = newValues[1];
            var operatingCode = newValues[2];
            var normalCrusingCode = '2';
            if (instantPower == undefined || mePower == undefined || operatingCode == undefined) return;

            var min = Math.floor(mePower * 0.97);
            var max = Math.ceil(mePower * 1.03);
            var invalid = operatingCode == normalCrusingCode && (instantPower < min || instantPower > max);
            var message = 'Instantaneous Power should be within 3% of reported Main Engine Power (' + min + '-' + max + 'kW)';
            $scope.report.warningMessages['report.engine.main_engines[0].main_engine_instant_power'] = invalid ? message : null;
        });

    });

    var initializeReport = function(report) {
        if (report.stock == undefined) report.stock = {};
        var defaultNumberOfTransfers = 4;
        report.stock.tank_transfers = report.stock.tank_transfers || [];
        angular.forEach(report.stock.tank_transfers, function(tankTransfer) {
            if (tankTransfer.from_tank && tankTransfer.from_tank['$oid']) {
                tankTransfer.from_tank = tankTransfer.from_tank['$oid'];
            }
            if (tankTransfer.to_tank && tankTransfer.to_tank['$oid']) {
                tankTransfer.to_tank = tankTransfer.to_tank['$oid'];
            }
        });
        var missingTransfers = defaultNumberOfTransfers - report.stock.tank_transfers.length;
        for (var i = 0; i < missingTransfers; i++) {
            report.stock.tank_transfers.push({});
        }
    };
    initializeReport($scope.report);

    $scope.shouldRequireCapitalFields = function() {
        return false;
    };

    $scope.disableFieldValidation = function(ngModelPath, formGroupEl) {
        // get field path
        var queryString = '[ng-model="' + ngModelPath + '"]';
        var queryResult = $(queryString);
        var nameAttribute = queryResult.attr('name');
        if (nameAttribute == undefined) {
            return;
        }
        var parentForms = queryResult.parents('ng-form');
        var parentFormNames = parentForms.map(function(key, value) { return value.getAttribute('name'); });

        // get form element
        var formElement = $scope;
        for (var i = parentFormNames.length - 2; i >= 0; i--) {
            formElement = formElement[parentFormNames[i]];
            if (formElement == undefined) {
                return;
            }
        }
        var control = formElement[nameAttribute];
        if (control == undefined) {
            return;
        }

        if (formElement[nameAttribute] != undefined) {
            formElement[nameAttribute].$pristine = false;
            var control = formElement[nameAttribute];
            if (typeof control === 'object' && control.hasOwnProperty('$error')) {
                control.$error = {};
            }
            if (typeof control === 'object' && control.hasOwnProperty('$modelValue')) {
                control.$validators = {};
                control.$pristine = true;
                control.$valid = true;
                control.$invalid = false;
            }
        }
    }

    $rootScope.disableTabValidation = function(reportForm, tab) {
        if (!reportForm) return
        if (reportForm[tab] != undefined) {
            reportForm[tab].$pristine = false;
            angular.forEach(reportForm[tab], function(control) {
                if (typeof control === 'object' && control.hasOwnProperty('$error')) {
                    control.$error = {};
                }
                if (typeof control === 'object' && control.hasOwnProperty('$modelValue')) {
                    control.$validators = {};
                    control.$pristine = true;
                    control.$valid = true;
                    control.$invalid = false;
                }
            });

            if (tab === 'position') {
                $scope.disableTabValidation(reportForm['position'], 'observation');
            }

            if (tab === 'power') {
                $scope.disableTabValidation(reportForm['power'], 'runningHours');
            }
            if (tab == 'cargo') {
                reportForm[tab].$error = {};
                reportForm[tab].$valid = true;
                reportForm[tab].$invalid = false;
            }
        }
    };


    $scope.initializeFormFields = function() {
        var form = VesselSpecificationService.getSpecifications().form;
        $scope.form = form;

        if (form && form.tabs && form.tabs['engine'] && form.tabs['engine'].visible == false) {
            $scope.disableTabValidation($scope.reportForm, 'engine');
            $scope.reportForm.engine = {};
        }

        if (form && form.tabs && form.tabs['power'] && form.tabs['power'].visible == false) {
            $scope.disableTabValidation($scope.reportForm, 'power');
            $scope.reportForm.power = {};
        }

        if (form && form.tabs && form.tabs['cii'] && form.tabs['cii'].visible) {
            setTimeout(function() {
                $scope.disableTabValidation($scope.reportForm, 'operational');
                $scope.reportForm.operational = {};
                $scope.disableTabValidation($scope.reportForm, 'position');
                $scope.reportForm.position = {};
                $scope.disableTabValidation($scope.reportForm, 'cargo');
                $scope.reportForm.cargo = {};
                $scope.disableTabValidation($scope.reportForm, 'power');
                $scope.reportForm.power = {};
                $scope.disableTabValidation($scope.reportForm, 'engine');
                $scope.reportForm.engine = {};
                $scope.disableTabValidation($scope.reportForm, 'consumptions');
                $scope.reportForm.consumptions = {};
                $scope.disableTabValidation($scope.reportForm, 'bunker');
                $scope.reportForm.bunker = {};
                $scope.disableTabValidation($scope.reportForm, 'stock');
                $scope.reportForm.stock = {};
            }, 5000);
            
        }
    };
    $timeout(function() { $scope.initializeFormFields(); }, 1000);

    $scope.$on('vessel-specifications-received', function(event, args) {
        $timeout(function() { $scope.initializeFormFields(); }, 1000);
    });

    $scope.$watch('report.operational.voyage_number', function(currentVoyageNumber) {
        if ($scope.isRioTintoVessel() && currentVoyageNumber) {
            var currentYear = parseInt(moment().format('YY'), 10);

            var currentVoyageCounter = parseInt(currentVoyageNumber.slice(2), 10);
            var previousVoyageNumber = $scope.carryOverReport.operational.voyage_number;
            var previousVoyageYear = previousVoyageNumber.length == 6 ? parseInt(previousVoyageNumber.slice(2, 4)) : parseInt(previousVoyageNumber.slice(0, 2));
            var previousVoyageCounter = parseInt(previousVoyageNumber.slice(previousVoyageNumber.length - 2), 10);

            var valid = null;
            var message = null;
            if (currentVoyageNumber && currentVoyageNumber == previousVoyageNumber) {
                // A voyage number same as the previous is always valid.
                valid = true;
            } else if (!currentVoyageNumber.match('^' + currentYear + "\\d{2}$")) {
                // Voyage number should otherwise always be current year followed by two digits.
                valid = false;
                message = 'Voyage Number should be four digits, where the first two are the current year and the last two are the voyage count. (Previous voyage was: ' + previousVoyageNumber + ')';
            } else if (currentYear == previousVoyageYear) {
                // If current year = previous year, counter should be equal or one greater.
                valid = currentVoyageCounter == previousVoyageCounter || currentVoyageCounter == previousVoyageCounter + 1;
                message = 'Voyage Number should be four digits, where the first two are the current year and the last two are the voyage count. (Previous voyage was: ' + previousVoyageNumber + ')';
            } else {
                // If current year > previous year, counter should equal 1
                valid = currentVoyageCounter == 1;
                message = 'New year, Voyage Number should be same as previous (' + previousVoyageNumber + ') or ' + (currentYear + "01");
            }
            $scope.reportForm.operational.voyageNumber.errorMessage = message;
            $scope.reportForm.operational.voyageNumber.$setValidity('voyageNumberFormat', valid);
        }
    });

    // adds validation to make sure Enter ECA value is within reporting period
    $scope.$watchGroup([
        'report.operational.report_from',
        'report.operational.report_to',
        'report.operational.enter_eca',
        'report.operational.exit_eca'
    ], function(newValues, oldValues, scope) {
        let [reportFrom, reportTo, enterECA, exitECA] = newValues;
        let prevInECA = $scope.report.operational.previously_in_eca == true;
        if (scope.reportForm && scope.reportForm.operational && scope.reportForm.operational.enterECA) {
            if (reportFrom != undefined && reportTo != undefined && enterECA != undefined && enterECA != '') {
                reportFrom = moment(reportFrom, 'DD/MM/YYYY HH:mm');
                reportTo = moment(reportTo, 'DD/MM/YYYY HH:mm');
                enterECA = moment(enterECA, 'DD/MM/YYYY HH:mm');
                exitECA = moment(exitECA, 'DD/MM/YYYY HH:mm');
                let valid = (!prevInECA && reportFrom <= enterECA && reportTo >= enterECA)
                    || (prevInECA && reportFrom <= enterECA && reportTo >= enterECA && enterECA >= exitECA);
                scope.reportForm.operational.enterECA.$setValidity('enter_eca_invalid', valid);
            } else {
                scope.reportForm.operational.enterECA.$setValidity('enter_eca_invalid', true);
            }
        }
    })

    // adds validation to make sure Exit ECA value is within reporting period
    $scope.$watchGroup([
        'report.operational.report_from',
        'report.operational.report_to',
        'report.operational.enter_eca',
        'report.operational.exit_eca'
    ], function(newValues, oldValues, scope) {
        let [reportFrom, reportTo, enterECA, exitECA] = newValues;
        let prevInECA = $scope.report.operational.previously_in_eca == true;
        if (scope.reportForm && scope.reportForm.operational && scope.reportForm.operational.exitECA) {
            if (reportFrom != undefined && reportTo != undefined && exitECA != undefined && exitECA != '') {
                let reportFromMoment = moment(reportFrom, 'DD/MM/YYYY HH:mm');
                let reportToMoment = moment(reportTo, 'DD/MM/YYYY HH:mm');
                let enterECAMoment = moment(enterECA, 'DD/MM/YYYY HH:mm');
                let exitECAMoment = moment(exitECA, 'DD/MM/YYYY HH:mm');
                let valid = (prevInECA && reportFromMoment <= exitECAMoment && reportToMoment >= exitECAMoment)
                    || (!prevInECA && reportFromMoment <= exitECAMoment && reportToMoment >= exitECAMoment && (exitECAMoment >= enterECAMoment || (!enterECA && $scope.report.operational.previously_in_eca == undefined)));
                scope.reportForm.operational.exitECA.$setValidity('exit_eca_invalid', valid);
            } else {
                scope.reportForm.operational.exitECA.$setValidity('exit_eca_invalid', true);
            }
        }
    })

    $scope.showByDefault = function(path, formPartType) {
        return showByDefault($scope.form,$scope.report._cls,path,formPartType)
    };

    type TFormPartType = 'fields' | 'sections' | 'tabs';
    $scope.hideByDefault = function(path: string, formPartType: TFormPartType) {
        if (!$scope.form) return false;
        const formPart = $scope.form[formPartType || 'fields'][path];
        if (!formPart) {
            return false;
        } else if (formPart.for_report_types && (formPart.for_report_types.length == 0 || formPart.for_report_types.indexOf($scope.report._cls) != -1)) {
            // Check if rule applies for current report type. It applies if the report type is in `for_report_types`, or
            // if the `for_report_types` list is empty (meaning the rule applies for all report types). If it does
            // apply, then return visibility.
            return !!formPart.visible;
        } else {
            // Hide by default: return false if no rule applies.
            return false;
        }
    };

    $scope.requireByDefault = function(path: string, formPartType: string) {
        if (!$scope.form) return true;
        var formPart = $scope.form[formPartType || 'fields'][path];

        if (!formPart) {
            // Require by default: return true if formPart doesn't exist (which means no rule exists for it).
            return true;
        } else if (formPart.for_report_types && (formPart.for_report_types.length == 0 || formPart.for_report_types.indexOf($scope.report._cls) != -1)) {
            // Check if rule applies for current report type. It applies if the report type is in `for_report_types`, or
            // if the `for_report_types` list is empty (meaning the rule applies for all report types). If it does
            // apply, then return requirement.
            return !!formPart.required && !!formPart.visible;
        } else {
            // Require by default: return true if no rule applies.
            return true;
        }
    }

    $scope.showEnterECA = function() {
        // vessel is:
        // * not in ECA OR
        // * has not previously reported that it has entered/exited ECA
        if (!$scope.report || !$scope.report.operational) return;
        let prevInECA = $scope.report.operational.previously_in_eca == true;
        var reportTo = moment($scope.report.operational.report_to, 'DD/MM/YYYY HH:mm');
        var exitECA = moment($scope.report.operational.exit_eca, 'DD/MM/YYYY HH:mm');
        return !prevInECA || exitECA < reportTo;
    }

    $scope.showExitECA = function() {
        // vessel is:
        // * in ECA OR
        // * has not previously reported that it has entered/exited ECA
        if (!$scope.report || !$scope.report.operational) return;
        let prevInECA = $scope.report.operational.previously_in_eca == true;
        var reportTo = moment($scope.report.operational.report_to, 'DD/MM/YYYY HH:mm');
        var enterECA = moment($scope.report.operational.enter_eca, 'DD/MM/YYYY HH:mm');
        return $scope.report.operational.previously_in_eca == undefined || prevInECA || enterECA < reportTo;
    }

    /** Sensor data - run this to get real time data into the reporting fields.  
     * @param {GetSensorDataOptions} - optional configuration for how & which sensor fields are updated
    */
    interface GetSensorDataOptions {
        override?: boolean; // update all sensor fields with latest sensor data
        paths?: string[]; // specific sensor paths to update; skip all other fields. e.g. "['engine.main_engines[0].reading_date']"
        customReportTo?: string; // specify end date for sensor data instead of default operational.report_to field
    }

    $scope.setBunkerRows = (rows) => {
        $scope.report.bunker.bdn_based_reporting_bunkered_fuels = rows;
    }

    $scope.getStockTankObjects = () => {
        const stockTankObjects = []
        $scope.report.bunker.bdn_based_reporting_bunkered_fuels.forEach((tank) => {
            if (tank instanceof TankStock) {
                stockTankObjects.push(tank)
            } else {
                stockTankObjects.push(new TankStock(tank))
            }
        })
        return stockTankObjects
    }

    $scope.addBunker = () => {
        $scope.setBunkerRows([
            ...$scope.report.bunker.bdn_based_reporting_bunkered_fuels,
            new TankStock()
        ])

        parseBdnObjects($scope.getStockTankObjects())
    }

    $scope.removeBunker = (bunker: TankStock) => {
        $scope.report.bunker.bdn_based_reporting_bunkered_fuels = $scope.report.bunker.bdn_based_reporting_bunkered_fuels.filter(a => a.bdn_number !== bunker.bdn_number)
        parseBdnObjects($scope.getStockTankObjects())
    }

    $scope.removeBunkerByIndex = (index) => {
        $scope.report.bunker.bdn_based_reporting_bunkered_fuels = $scope.report.bunker.bdn_based_reporting_bunkered_fuels
            .filter((_, i) => i != index);
        parseBdnObjects($scope.getStockTankObjects())
    }

    $scope.onFuelGradeChange = (bunker: TankStock) => {
        bunker.setFuelGrade(bunker.fuel_grade)
        parseBdnObjects($scope.getStockTankObjects())
    }

    $scope.getSensorData = function( { override = false, paths = [], customReportTo = null }: GetSensorDataOptions = {}) {

        let fromTimeZone = $scope.report.operational.previous_timezone != undefined ? $scope.report.operational.previous_timezone: $scope.report.operational.timezone;
        let fromDate = TimezoneService.dtWithTz($scope.report.operational.report_from, fromTimeZone).toISOString();
        let toDate = TimezoneService.dtWithTz($scope.report.operational.report_to, $scope.report.operational.timezone).toISOString();

        // create variable to hold data loading progress for vessels with tags
        if ($scope.vessel_specifications?.navbox_tags && $scope.vessel_specifications.navbox_tags.length > 0) {
            $scope.isSensorDataLoaded = [false];
        } else {
            return;
        }

        hybridService?.serverTrackPointsQuery(fromDate, toDate)
            .then((navboxTrackResponse: Array<object>) => {
                let sensorData = SensorData.getInstanceFromNavboxTrackResponse(navboxTrackResponse, VesselSpecificationService.getSpecifications().navbox_tags);
                sensorData.attributeNavboxTagsToReport($scope.report, override, paths, $rootScope);
                $scope.sensorData = sensorData;

                //change loading progress after response received
                $scope.isSensorDataLoaded = [true];

                // downsample to show on UI
                $scope.downsampledTrackpoints = SensorData.downsample(navboxTrackResponse, 'hour');
                $scope.sensorDataLastUpdatedAt = sensorData.getMinOfLastUpdatedSensorDataTime();

                // last saved (drafted) report, changed sensor properties (carry forward value fields are neglected) 
                $scope.report.lastUpdatedSensorData = sensorData.updatedAttributes;
            })
            .catch(function () {
                    $scope.isSensorDataLoaded = [true];
            });
        
    };

    // debounce function to make sure it doesn't get called more than once at any given second
    const debounceGetSensorData = debounce($scope.getSensorData, 1000);    

    $scope.reloadSensorData = function() {
        $scope.getSensorData({override: true});
    };
    
    $scope.$on('updateSelectedSensorTag', function (event, selectedTagPath) {

        let fromTimeZone = $scope.report.operational.previous_timezone != undefined ? $scope.report.operational.previous_timezone: $scope.report.operational.timezone;
        let fromDate = TimezoneService.dtWithTz($scope.report.operational.report_from, fromTimeZone).toISOString();
        let toDate = TimezoneService.dtWithTz($scope.report.operational.report_to, $scope.report.operational.timezone).toISOString();

        let selectedTag: any;
        if (selectedTagPath === 'report.position') {
            selectedTag = { tag_name: 'position'}
        } else {
            selectedTag = SensorData.getNavboxTagFromReportPath(selectedTagPath,$scope.sensorData.navboxTags);
        };
        $scope.isSensorDataLoaded = [false, true];

        hybridService
            .serverTrackPointsQueryByTagName(selectedTag.tag_name, fromDate, toDate)
            .then((navboxTagResponse: Array<object>) => {
                let sensorData = SensorData.getInstance();
                sensorData.setSelectedNavboxTagResponse(navboxTagResponse);
                sensorData.attributeNavboxTagsToReport($scope.report, true, selectedTagPath, $rootScope);
                $scope.sensorData = sensorData;
                
                $scope.isSensorDataLoaded = [true, false];
                $scope.sensorDataLastUpdatedAt = sensorData.getMinOfLastUpdatedSensorDataTime();
            })
            .catch(function () {
                $scope.isSensorDataLoaded = [true, false];
            });
    });

    $scope.$watchGroup(['report.operational.report_to', 'report.operational.timezone'], function(newValue, oldValue, scope){
        // Conditions for running:
        // 1) Report is not completed
        // 2) User features.reporting.sensor_enabled is set to True
        // 3) Required fields for sensor data are not empty

        let sensorFlagEnabled = scope.features?.reporting?.sensor_enabled || true;
        let isAdminUser = $scope.isAdmin();
        let reportInProgress = scope.report.status != 'completed';
        let hasRequiredFields = !!(scope.report.operational && scope.report.operational.report_to && scope.report.operational.report_from && scope.report.operational.previous_timezone && scope.report.operational.timezone);
        if (sensorFlagEnabled && scope && scope.report && (reportInProgress || isAdminUser) && hasRequiredFields) {
            // fetch sensor data only for ongoing reports
           if(reportInProgress && scope.isValidReportPeriod()) {
                debounceGetSensorData();
            } else {
                scope.isSensorDataLoaded = [true];
            }
        }
    });

    // Validate reporting period against datetime and report type
    $scope.isValidReportPeriod = function () {
        
        let reportToDate = new Date(TimezoneService.dtWithTz($scope.report.operational.report_to, $scope.report.operational.timezone).toISOString()).getTime();
        let reportFromDate = new Date(TimezoneService.dtWithTz($scope.report.operational.report_from, $scope.report.operational.timezone).toISOString()).getTime();
        let maxReportPeriod = $scope.isSmyrilVessel() ? 72 : 36;

        if (!isNaN(reportToDate) && !isNaN(reportFromDate)) {
            let dateDifference = reportToDate - reportFromDate;
            let differenceInHrs = dateDifference / (1000 * 60 * 60);
            return (differenceInHrs > 0 && (($scope.isSeaReport($scope.report) && differenceInHrs <= maxReportPeriod)) || !$scope.isSeaReport($scope.report));
        }
        return false;
    };

    $scope.$on('update-report-sensor', function() {
        // $scope.sensorData.attributeToReport($scope.report, true)
    });

    $scope.showSensorDataModal = function() {
        let sensorFlagEnabled = $scope.features?.reporting?.sensor_enabled;
        let attributionEnabled = $scope.vessel_specifications?.sensor?.attribution_enabled;
        let isOnline = enableHybrid ? OfflineDatabase.isOnline() : true;
        if(sensorFlagEnabled && attributionEnabled && $scope.report && $scope.report.status != 'completed' && isOnline) {
            $scope.$emit('modal-message', {modalId: 'refreshSensorData'});
        }
    };

    $scope.showSensorDataModal();

    $scope.$watchGroup(['pageState.state', 'modals'], function(newValues, oldValues, scope) {
        // only load modal after report has finish loading to avoid backdrop errors
        let modals = newValues[1];
        if (newValues[0] == 'data_loaded' && modals && modals.length > 0) {
            $('#reportModal').modal('show');
        } else if (newValues[0] == 'data_loaded' && modals && modals.length == 0) {
            $('#reportModal').modal('hide');
            $('.modal-backdrop').remove();
        }
    });

    $scope.maxTCRPM = function() {
        if ($scope.selectedVessel.dualEngine) {
            return 25000;
        }
        else if ($scope.selectedVessel.class == 'Type 176') {
            return 30000;
        }
        else return 20000;
    }

    /* auto save feature */
    var reportSaveTimer = false;
    var reportSaveDelay = 5000;
    AutoSaveService.resetChanges();

    $scope.reporting_fuel_type_clicked = function(evt) {
        if ($scope.report.bunker.reporting_fuel_type) {
            evt.preventDefault()
        }
    }

    $scope.setDefaultValues = (tank) => {
        const {
            co2_eq_wtt,
            cf_co2,
            cf_ch4,
            cf_n2o,
            c_slip
        } = getDefaultValues(tank.fuel_grade)

        tank.co2_eq_wtt = co2_eq_wtt
        tank.cf_co2 = cf_co2
        tank.cf_ch4 = cf_ch4
        tank.cf_n2o = cf_n2o
        tank.c_slip = c_slip
    }

    $scope.getStockTanks = () => {
        const stockTanks = $scope.report.stock.tanks
        const specTanks = $scope.vesselSpecs?.tanks
        if (!specTanks || !stockTanks) return []

        return specTanks.map(a => stockTanks[a.tank_id['$oid']])
    }

    $scope.reporting_bdn_clicked = function(evt) {
        evt.preventDefault()
        if ($scope.report.bdn_based_reporting) {
            return
        }

        $scope.getStockTanks().forEach(a => $scope.setDefaultValues(a))

        $('#first-time-bdn-warning').modal('toggle')
    }

    $scope.reportingBdnChanged = function(evt) {
        $scope.report.bunker.reporting_fuel_type = false
    }

    $scope.onInputFocus = function(path: string, value: any) {
        if (typeof path != 'string') return;
        AutoSaveService.setOldValue(value);
    }
      
      $scope.clearStockTank = function (id) {
        const preTransferStock = _.cloneDeep(ReportService.getPreTransferStock())
        const tanks = $scope.report.stock.tanks
        tanks[id] = preTransferStock[id]
      }

      $scope.removeTransferByIndex = function ($index) {
        const transfer = $scope.report.stock.tank_transfers[$index]
        const fromId = transfer.from_tank?.tank_id['$oid']
        const toId = transfer.to_tank?.tank_id['$oid']

        if (fromId != undefined) {
          $scope.clearStockTank(fromId)
        }
        if (toId != undefined) {
          $scope.clearStockTank(toId)
        }

        transfer.from_tank = null
        transfer.to_tank = null
        transfer.quantity = null
        transfer.bdn = null
      }
      

    /**
     * Note: Input fields in position-observations directives must use these parameters
     * because ng-change isn't being triggered, so we need to get the values from input element directly
     * instead of ngModel with ngInputChange directive
     * @param: value - input value 
     * @param: path - ngModel path: string
     */
    // TODO: update any fields with autosave issue with new OnInputChange
    $scope.onInputChange = function(path:string = null, value:any = null) {
        if (value && path) {
            AutoSaveService.setNewValue(value);
            AutoSaveService.setChangedInputField(path);
        }
        $scope.unsaveChanges = AutoSaveService.hasUnsavedChanges() || !$scope.reportIsSaved($scope.report);
        if ($scope.vessel_specifications?.auto_save != true) return;
        $timeout.cancel(reportSaveTimer);
        $scope.autoSaving = false;
    }
    
    // when user leave input field check if value has change
    $scope.onInputBlur = function(path: string, value: any) {
        if (typeof path != 'string') return;

        let reportModelName = path;
        let changedInputField = AutoSaveService.getChangedInputField(reportModelName);
        if (!changedInputField) {
            AutoSaveService.setNewValue(value);
        } 

        if (AutoSaveService.hasChanged()) {
            changedInputField = AutoSaveService.getChangedInputField(reportModelName);
            if (changedInputField != undefined) {
                const prevOldValue = changedInputField.oldValue;
                if (changedInputField.newValue == prevOldValue) {
                    AutoSaveService.removeChangedInputField(reportModelName); 
                } else {
                    AutoSaveService.setChangedInputField(reportModelName)
                }
            } else {
                AutoSaveService.setChangedInputField(reportModelName)
            }
        }
        
        let enableAutoSave = (enableHybrid || enableOnPremise) && $scope.vessel_specifications?.auto_save && !$scope.reportIsComplete() && $scope.reportIsSaved($scope.report);
        if (AutoSaveService.hasUnsavedChanges() && enableAutoSave) {
            $scope.unsaveChanges = true;
            $scope.autoSaving = true;
            $timeout.cancel(reportSaveTimer);
            reportSaveTimer = $timeout(function() {
                $scope.saveReport(false, true)
                AutoSaveService.resetChanges();
            }, reportSaveDelay)
        }
    }

    /* report auto saved status */
    $scope.reportSaved;
    $scope.reportIsSaved = function (report) {
        // check if report has been saved offline or saved on server at all
        // when first opening a report to set correct saved status
        if ($scope.reportSaved) return $scope.reportSaved;

        var reportKey = OfflineDatabase.getReportKey(report)
        var offlineReports = OfflineDatabase.getOfflineReports()
        offlineReports = offlineReports ? offlineReports.filter(offlineReport => {
            if (offlineReport.reportKey == reportKey) {
                return offlineReport;
            }
        }) : [];
        var savedOffline = offlineReports.length > 0;
        var savedOnServer = !!report.id;
        return savedOffline || savedOnServer;
    }

    /* initialize report save status */
    $scope.autoSaving = false;
    $scope.unsaveChanges = true;
    $scope.reportSaved = $scope.reportIsSaved($scope.report)
    if ($scope.reportSaved) {
        $scope.autoSaving = false;
        $scope.unsaveChanges = false;
    }

    $scope.shaftGeneratorPtoRHMax = function() {
        var ptiRH = $scope.report.power.shaft_motor_pti_rh || 0;
        if (ptiRH == 0 && $scope.report.power.main_engine_1_diff_rh != undefined) {
            return Math.ceil($scope.report.power.main_engine_1_diff_rh);
        }
        else return Math.ceil($scope.report.operational.report_period - ptiRH);
    }

    $scope.shaftMotorPtiRHMax = function() {
        var ptoRH = $scope.report.power.shaft_generator_pto_rh || 0;
        return Math.ceil($scope.report.operational.report_period - ptoRH);
    }
    
    $scope.validAndUnableToFill = function() {
        return $scope.report && $scope.report.unable_to_fill_report && $scope.report.unable_to_fill_report_issue && $scope.report.unable_to_fill_report_reason && !$scope.reportForm.operational.reportFrom.$invalid && !$scope.reportForm.operational.reportTo.$invalid;
    };

    const unableToFillReportIssueOptions: Record<string, string> = {
        '': '',
        'opertional': 'Operational Tab',
        'cargo': 'Cargo Details Tab',
        'position': 'Position and Weather Tab',
        'power': 'Power Tab',
        'engine': 'Engine Tab',
        'consumption': 'Consumption Tab',
        'stock': 'Stock Tab',
        'bunker': 'Bunker Tab',
        'other': 'Other Fields and Validations Issues',
    };

    $scope.unableToFillReportIssueOptions = unableToFillReportIssueOptions;

    if (process.env.NODE_ENV == 'test' && testService) {
        /**
         * Add all service and scopes to test framework to test report.controller async methods
         * Test Scripts are added back into scope and used in site/test 
         * Test Scripts are assign to button in test-report.html
         */
        const ts = testService.TestFrameWork.getInstance({rootScope:$rootScope, scope: $scope, OfflineDatabase, ReportService, hybridService, errorHandler});

        $rootScope.fillInVesselReport = function(report, newReport) {
            report = Object.assign({}, report, newReport);
            $scope.report = report;
        };

        $scope.testSave = ts.testSave;
        $scope.testSubmit = ts.testSubmit;
        $scope.testDelete = ts.testDelete;
        $scope.testViewReport = ts.testViewReport;
        $scope.testMergeReport = ts.testMergeReport;
        $scope.testGetUpdate = ts.testGetUpdate;
    }

    $scope.$watch("report.operational.port_name", (portName) => {
        if (portName) {
            $scope.report.operational.dnv_port_name = portName;
        }
    });

    // check for changes with the isSensorDataLoaded variable when fetching data
    if (enableOnPremise) {
        $scope.$watch("isSensorDataLoaded", function(value: boolean[]) {
            let hasSensorData = (value ?? [])[0] ?? true;
            let isSelectedTag = (value ?? [])[1] ?? false;
            let atReportLevel = $rootScope.selectedLevels[1] == ("edit-report" || "create-new-report");

            $scope.loadingModal = !hasSensorData;
            $rootScope.loadingModalMessage = isSelectedTag ? "Sensor data is being retrieved ..." : (atReportLevel ? "Auto-populating report fields ..." : "Sensor data is being retrieved ...");
        });
    }

    $scope.$watch("loadingModal", function(value) {
        if (value) {
            $("#loadingModal").modal('show');
        } else {
            $("#loadingModal").modal('hide');
        }
    });

    $scope.isPositiveNumber = (n = 0) => {
        if (n === null) return false

        return (n >= 0)
    }

    $scope.isFirstBdnValid = () => {
        return $scope.getStockTanks().reduce((prev, curr) => {
            if (!prev) return false
            if (!$scope.isPositiveNumber(curr.co2_eq_wtt)) return false
            if (!$scope.isPositiveNumber(curr.cf_co2)) return false
            if (!$scope.isPositiveNumber(curr.cf_ch4)) return false
            if (!$scope.isPositiveNumber(curr.cf_n2o)) return false
            if (!$scope.isPositiveNumber(curr.c_slip)) return false

            return true
        }, true)
    }

    $scope.enableBDNReporting = function(isFromModal = false) {
        if ($scope.isFirstBdnValid()) {
            $('#first-time-bdn-warning').modal('hide')
          
            if (isFromModal === true) {
               $scope.report.consumption = {}
            }
           
            $scope.report.bunker.reporting_fuel_type = false
            $scope.report.bdn_based_reporting = true
            ReportService.addBdnNumbers($scope);
            ReportService.setPreTransferStock($scope.report.stock.tanks)
            $scope.report.activeBDNFuels = ReportService.generateActiveBDNFromReport($scope.report);
            generateConsumptions($scope.report);
            generateBunkers($scope.report);
        }
    }
    $scope.resetForm = function() {
       $scope.reportForm.$pristine = true
    }

    $scope.getBdnList = () => {
        return [
            ...$scope.report.bunker.bdn_based_reporting_bunkered_fuels.filter(a => a.bdn).map(b => b.bdn)
        ]
    }

    $scope.isBDNEnabled = function() {
      return $scope.report.bdn_based_reporting;
    }

    $scope.addNewConsumption = function() {
        $scope.report.consumption.bdn_consumptions = [
            ...$scope.report.consumption.bdn_consumptions,
            createBdnConsumptionObj()
        ]
    }

    $scope.removeConsumptionByIndex = (index: number) => {
        $scope.report.consumption.bdn_consumptions = 
            $scope.report.consumption.bdn_consumptions.filter((_, i: number) => i != index);
    }

    $scope.getConsumptionValueFromTank = function(bdnNumber, field) {
        const tankStockObj = $scope.report.activeBDNFuels.find((tankStock) => {
            return tankStock.bdn_number === bdnNumber
        });
        if (!tankStockObj) return

        return tankStockObj[field];
    }

    $scope.getIndexFromBdn = function(bdnNumber: string) {
        const bdnCons = $scope.report.consumption.bdn_consumptions
        return bdnCons.findIndex(obj => obj.bdn_number === bdnNumber)
    }

    $scope.setConsValuesFromTank = (index: number) => {
        if (!$scope.report.activeBDNFuels) {
            $scope.populateActiveBDNFuels()
        }
        const bdnNumber = $scope.report.consumption.bdn_consumptions[index].bdn_number
        $scope.report.consumption.bdn_consumptions[index].lcv = $scope.getConsumptionValueFromTank(bdnNumber, 'lcv_before_bunkering')
        $scope.report.consumption.bdn_consumptions[index].sulphur = $scope.getConsumptionValueFromTank(bdnNumber, 'sulphur_before_bunkering')
        $scope.report.consumption.bdn_consumptions[index].density = $scope.getConsumptionValueFromTank(bdnNumber, 'density_before_bunkering')
        const fuelGrade = $scope.getConsumptionValueFromTank(bdnNumber, 'fuel_grade')
        if (fuelGrade) {
            $scope.report.consumption.bdn_consumptions[index].fuel_grade = fuelGrade.toUpperCase()
        }
    }

    $scope.onConsumptionBdnChange = (bdnNumber: string) => {
        const index = $scope.getIndexFromBdn(bdnNumber)
        if (!bdnNumber) {
            $scope.report.consumption.bdn_consumptions[index] = createBdnConsumptionObj()
            return
        }
        
        $scope.setConsValuesFromTank(index)
    }

    $scope.getFieldMin = (fieldName: string, bdnNumber: string) => {
        return $scope[fieldName + '_' + bdnNumber + '_min']
    }

    $scope.getFieldMax = (fieldName: string, bdnNumber: string) => {
        return $scope[fieldName + '_' + bdnNumber + '_max']
    }

    $scope.populateActiveBDNFuels  = function() {
        $scope.report.activeBDNFuels = ReportService.generateActiveBDNFromReport($scope.report);
    }

    $scope.isBDNBioFuel  = function() {
        if (!$scope.report.bdn_based_reporting) return false

        const tankStock = $scope.report.bunker.bdn_based_reporting_bunkered_fuels
        if (!tankStock) return false

        for (let i = 0; i < tankStock.length; i++) {
            if (tankStock[i].is_bio_fuel) {
                return true
            }
        }

        return false
    }
}]);
