import Highcharts from 'highcharts';
import { routerApp, analyticsUrl, baseUrl,  getMEPerformanceData, roundToPlaces, themePalette } from '../../app.module';
import Style from 'ol/style/style';
import Stroke from 'ol/style/stroke';
import Icon from 'ol/style/icon';
import Map from 'ol/map';
import Tile from 'ol/layer/tile';
import LayerVector from 'ol/layer/vector';
import XYZ from 'ol/source/xyz';
import OSM from 'ol/source/osm';
import SourceVector from 'ol/source/vector';
import Point from 'ol/geom/point';
import LineString from 'ol/geom/linestring';
import interaction from 'ol/interaction';
import control from 'ol/control';
import proj from 'ol/proj';
import View from 'ol/view';
import Feature from 'ol/feature';
import OverLay from 'ol/overlay';
// import fetch & promise polyfill together to work on older browsers
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { ScriptService } from '../services/script.service';
import { redirectToModule } from '../../services/utilities';

// @ts-ignore
routerApp.controller('analyticsController', ['$rootScope', '$scope', '$http', '$state', 'VesselService', 'UserClientService', 'VoyageService', 'notify', 'VesselSpecificationService', '$timeout', 'principal', 'ReportService',
function($rootScope, $scope, $http, $state, VesselService, UserClientService, VoyageService, notify, VesselSpecificationService, $timeout, principal, ReportService) {
    $rootScope.app = "analytics";
    $rootScope.buildTheme = themePalette.getCurrentTheme();

    $rootScope.vesselName = "Atlantic Gemini";
    $rootScope.selectedMenu = "dashboard";

    notify.config({startTop: 60, maximumOpen: 3, position: 'right'});

    if ($rootScope.vessels == undefined) {
        var url = baseUrl + $rootScope.username + "/vessels";
        $http({
            url: url,
            method: "GET",
            data: { username: $rootScope.username }
        }).then(function(response) {
            var allVessels = response.data.data;
            $rootScope.all_vessels = allVessels;
            var active_vessels = new Array;
            for (var i = 0; i<allVessels.length;i++) {
                if (allVessels[i].disabled!=true) {
                    active_vessels.push(allVessels[i])
                }
            }
            $rootScope.vessels = active_vessels;
        });
    }
    ScriptService.loadManyScripts(document, ['js/tableau-2.min.js', 'js/tableau-2.2.2.min.js']);
    // load js file synchronous to avoid dependency error
    // @ts-ignore
    if (!window.L) {
        const leafletScriptElement = ScriptService.loadJsScript(document, 'https://unpkg.com/leaflet@1.4.0/dist/leaflet.js');
        leafletScriptElement.onload = () => {
            // @ts-ignore
            if (!window.windyInit) {
                const windyScriptElement = ScriptService.loadJsScript(document, 'https://api.windy.com/assets/map-forecast/libBoot.js');
                windyScriptElement.onload = () => {
                    // @ts-ignore
                    if (window.L) {
                        ScriptService.loadJsScript(document, '../../js/Leaflet.fullscreen.min.js');
                    }
                }
            }
           
        }
    }


    // check if current user is eagle
    $scope.isEagleUser = UserClientService.isViewForUser($rootScope.user, 'eagle');
    $scope.isRioTintoUser = UserClientService.isViewForUser($rootScope.user, 'riotinto');
    $scope.isGCCUser = $rootScope.user.client == 'osm';
    $scope.isSmyrilUser = ($rootScope.user.client == 'smyril' || $rootScope.user.client == 'matson');
    $scope.isciiReportingUser = $rootScope.user.features?.cii_reporting?.enabled == true;

    // determines which Daily Activity Report views should be available for the user
    $scope.navigation = {
        // daily activity reports
        dailyActivityReportEagle: UserClientService.isViewForUser($rootScope.user, 'eagle'),
        dailyActivityReportDiamondS: UserClientService.isViewForUser($rootScope.user, 'diamonds'),
        dailyActivityReportOSM: UserClientService.isViewForUser($rootScope.user, 'osm'),
        dailyActivityReportINSW: UserClientService.isViewForUser($rootScope.user, 'insw'),
        dailyActivityReportTres: UserClientService.isViewForUser($rootScope.user, 'tres') || UserClientService.isViewForUser($rootScope.user, 'demo'),
        dailyActivityReportCapital: UserClientService.isViewForUser($rootScope.user, 'capital')
    }
    $scope.navigation.dailyActivityReport = (
        $scope.navigation.dailyActivityReportEagle
        || $scope.navigation.dailyActivityReportDiamondS
        || $scope.navigation.dailyActivityReportOSM
        || $scope.navigation.dailyActivityReportINSW
        || $scope.navigation.dailyActivityReportTres
        || $scope.navigation.dailyActivityReportCapital
    ) && ($rootScope.user.username != 'demo');

    VesselService.loadVessels($rootScope.username).then(function(response) {
        var data = response.data;
        VesselService.setVessels(data.data);
        VoyageService.initializeVessels(data.data, $rootScope.selectedVessel);
        $rootScope.$broadcast('vessel-data-received');
    });

    $scope.selectedDateRange = '3_month';

    $scope.isDarkTheme = function() {
        return themePalette.getCurrentTheme() == 'skin-navfleet';
    };

    $scope.isLightTheme = function() {
        return themePalette.getCurrentTheme() == 'skin-blue';
    };

    $scope.isDieselElectricPropulsion = function() {
        return $scope.vesselSpecs?.information?.prime_mover_type == 'diesel_electric_propulsion'
    }

    $scope.useStyle = function(applyStyle, style) {
        if (applyStyle) return style;
    };

    // get vessel performance
    $http({
        url: analyticsUrl + $rootScope.selectedVessel.id + "/test?variables=consumption.total_me_consumption_24",
        method: "GET"
    }).then(function(response) {
        var data = response.data;
        $scope.meData = data.data;

        $scope.mePerformance = getMEPerformanceData($scope.meData[$scope.selectedDateRange]);
        $timeout(function() {
            $rootScope.loadingModal = false;
        }, 1000);
    });

    // get vessel particulars
    $http({
        url: analyticsUrl + $rootScope.selectedVessel.id + "/vesselParticulars",
        method: "GET"
    }).then(function(response) {
        var data = response.data;
        $scope.vesselParticulars = data.data;
    });

    $scope.isSelectedVessel = function(vessel) {
        return vessel.id != undefined && vessel.id == $rootScope.selectedVessel.id
    }

    // this gets called when the user clicks on a new vessel from the vessel
    // list, we set initialized data to false so that all the data gets
    // refreshed for the new $rootScope.selectedVessel.id value
    $scope.switchVessel = function(vessel) {
        if (!$scope.isSelectedVessel(vessel)) {
            $rootScope.dashboardReady = false;
            $rootScope.loadingModalUsed = false;
            $rootScope.initializedData = false;
            $rootScope.lastReportWasSeaReport = false;
            $rootScope.lastReportWasPortReport = false;
          
            ReportService.clearService();

            $rootScope.selectedVessel = vessel;
          
            ReportService.setVesselReportNumber(vessel.reportNumber);
            
            $rootScope.hasIncompleteVesselReport = vessel.hasIncompleteVesselReport;

            $rootScope.reloading = true;
            if ($state.current.url == '/voyageAnalysisDetails') {
                $state.go('analytics.voyageAnalysis', { }, { reload: true, location: true });
            } else {
                $state.reload();
            }
        }
    };

    $scope.goToReporting = function() {
      if (!$scope.features) return;
        let reportingModuleUrl = redirectToModule($scope.features, 'crew_dashboard');
        $state.go(reportingModuleUrl);
    }

    $scope.goToNavfleet = function() {
        window.open('https://navfleet.navtor.com/', '_blank');
    }

    $scope.logout = function() {
        fetch(baseUrl + 'logout', {
            method: 'POST',
            credentials: 'include',
        }).then(function(response) {
            $timeout(function() {
                principal.logout()
                $state.go('login');
            }, 2000);
        })
    }

     $scope.switchTheme = function() {
        if (themePalette.isDarkTheme()) {
            $rootScope.buildTheme = 'skin-blue';
            themePalette.setTheme('skin-blue');
        } else {
            $rootScope.buildTheme = 'skin-navfleet';
            themePalette.setTheme('skin-navfleet');
        }
        $state.reload();
    }

    // Fixes plotLine labels
    // https://github.com/highcharts/highcharts/issues/8477#issuecomment-424353401
    Highcharts.wrap(Highcharts.Axis.prototype, 'getPlotLinePath', function(proceed) {
        var path = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        if (path) {
            path.flat = false;
        }
        return path;
    });
    // Highcharts global settings for dark mode
    Highcharts.setOptions({
        chart: {
            backgroundColor: themePalette.colors.CHART_BG,
        },
        title: {
            style: {
                color: themePalette.colors.THEME_TEXT_COLOR,
                // font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
            }
        },
        subtitle: {
            style: {
                color: themePalette.colors.THEME_TEXT_COLOR,
            }
        },
        xAxis: {
            // gridLineColor: Color.WHITE,
            // gridLineWidth: 1,
            lineColor: themePalette.colors.CHART_LINE_COLOR,
            tickColor: themePalette.colors.CHART_LINE_COLOR,
            labels: {
                style: {
                    color: themePalette.colors.THEME_TEXT_COLOR,
                    font: `11px ${themePalette.fonts.FONT_FAMILY}`
                }
            },
            title: {
                style: {
                    color: themePalette.colors.CHART_AXIS_TITLE_COLOR,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    fontFamily: themePalette.fonts.FONT_FAMILY
        
                }            
            }
        },
        yAxis: {
            gridLineColor: themePalette.colors.CHART_GRID_LINE_COLOR,
            lineColor: themePalette.colors.CHART_LINE_COLOR,
            lineWidth: 1,
            tickColor: themePalette.colors.CHART_LINE_COLOR,
            labels: {
                style: {
                    color: themePalette.colors.THEME_TEXT_COLOR,
                    font: `11px ${themePalette.fonts.FONT_FAMILY}`
                }
            },
            title: {
                style: {
                    color: themePalette.colors.CHART_AXIS_TITLE_COLOR,
                    fontWeight: themePalette.fonts.BOLD,
                    fontSize: '12px',
                    fontFamily: themePalette.fonts.FONT_FAMILY
                }            
            }
        },
        legend: {
            itemHoverStyle: {
                color: themePalette.colors.CHART_LEGEND_HIGHLIGHT,
            },
            itemStyle: {
                color: themePalette.colors.THEME_TEXT_COLOR,
                fontWeight: themePalette.fonts.LEGEND_WEIGHT
            }
        }
    });

    $scope.isGCCVessel = function() {
        return $scope.selectedVessel.company == 'Gram Car Carriers';
    };

    $scope.isEBVessel = function() {
        return $scope.selectedVessel.company == 'Eagle Bulk Shipping, Inc';
    };

    // refreshes vessel specification objects
    $scope.refreshVesselSpecifications = function() {
        VesselSpecificationService.getVesselSpecifications($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;
            VesselSpecificationService.setSpecifications(data);
            $scope.$broadcast('vessel-specifications-received', {});
            var vesselSpecs = VesselSpecificationService.getSpecifications();
            $scope.vesselSpecs = vesselSpecs;
        });
    }
    $scope.refreshVesselSpecifications();
 
    function styleFunction() {
        return [
            new Style({ 
                image: new Icon(({
                    // opacity: 1,
                    src: 'img/ship_current_position.png',
                    scale: 0.10,
                    anchor: [0.5, 1]
                }))
            })
        ];
    }
  

    var departureIconStyle = new Style({
        image: new Icon(({
            opacity: 1,
            src: 'img/ship_departure_icon.png',
            scale: 0.10,
            anchor: [0.5, 1]
        }))
    });

    var iconStyle = new Style({
        image: new Icon({
            src: 'img/position.png'
        })
    });

    var iconWayPointStyle = new Style({
        image: new Icon({
            src: 'img/position.png',
            opacity: 0.01
        })
    });

    $scope.highlightedIconStyle = new Style({
        image: new Icon({
            src: 'img/position_highlighted.png'
        })
    });

    $scope.secaIconStyle = new Style({
        image: new Icon({
            src: 'img/position_seca.png'
        })
    });

    // commented out until used for later
    // var shipIconStyle = new Style({
    //     image: new Icon(({
    //         opacity: 1,
    //         src: 'img/ship.svg',
    //         //        src: 'https://cdn3.iconfinder.com/data/icons/flat-artistic-common-2/32/point_red-128.png'
    //     }))
    // });


    var formatTemplate = function(row, lat, long) {
        var label = row['label'];
        var classification = row['classification'];
        var dateString = row['report_to'] ? (new Date(row['report_to'])).toLocaleDateString() : null;

        var html = '<div>';
        html += label ? '<div style=\"text-align: center; font-weight: bold\">' + label + '</div>' : '';
        html += classification ? '<div>Route Detail: ' + row['classification'] + '</div>' : '';
        html += dateString ? '<div style=\"text-align: center; font-weight: bold\">' + dateString + '</div>' : '';
        html += row['eta'] ? '<div>ETA: ' + new Date(row['eta']).toLocaleDateString() + '</div>' : '';
        html += row['observed_speed']  ? '<div>Speed: ' + row['observed_speed'] + ' kn</div>' : '';
        html += row['total_me_consumption_24'] ? '<div>MT/24h: ' + row['total_me_consumption_24'] + '</div>' : '';
        html += (lat && long) ? '<div>Pos: ' + roundToPlaces(lat,1) + ', ' + roundToPlaces(long,1) + '</div>' : '';
        html += '</div>';
        return html;
    };

    var map;

    function setCenter(long, lat) {
        map.getView().setCenter(proj.transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        // map.getView().setZoom(5);
    }


    $rootScope.drawMap = function(voyageData, options) {
        var targetMapElementId = options.targetMapElementId;
        var labelElementId = options.labelElementId;
        var isVoyageAnalysis = targetMapElementId == 'voyageMap';
        if (typeof targetMapElementId !== 'string' || typeof labelElementId !== 'string') {
            console.error('drawMap: missing parameter');
            throw Error;
        }
        $('.ol-viewport').remove(); // remove any existing map to avoid duplicates
        $('#' + targetMapElementId).append(`<div id='${labelElementId}'></div>`) // reset map popup

        var routeData = voyageData.coordinates;
        map = new Map({
            layers: [
                new Tile({
                    source: new OSM()
                }),
                new Tile({
                    source: new XYZ({
                        url: 'http://t1.openseamap.org/seamark/{z}/{x}/{y}.png'
                    })
                })
            ],
            interactions: interaction.defaults({mouseWheelZoom:false}),
            target: targetMapElementId,
            controls: control.defaults({
                attributionOptions: ({
                    collapsible: false
                }) 
            }),
            view: new View({
                center: [0, 0],
                zoom: 2
            })
        });

        var path = [];
        var lastPosition = [];
        var departurePosition = [];
        var coords = [];

        function resolveLongitude(lon) {
            if (Math.abs(lon) >= 180) {
                if (lon > 180) {
                    lon -= 360
                } else if (lon < -180) {
                    lon += 360
                }
            }
            return lon;
          }
        
        for (var i = 0; i < routeData.length; i++) {
            var latitude = routeData[i]['ship_lat'];
            var longitude = routeData[i]['ship_lon'];
            var inSeca = routeData[i]['in_seca'];
            if (latitude == null || longitude == null) continue;
            var iconFeature = new Feature({
                geometry: new Point(proj.transform([resolveLongitude(longitude), latitude], 'EPSG:4326', 'EPSG:3857')),
                content: formatTemplate(routeData[i], latitude, longitude),
                population: 4000,
                rainfall: 500,
                latitude: latitude,
                longitude: resolveLongitude(longitude),
                inSeca: inSeca ? true : false,
            });

            if (i == 0) {
                departurePosition.push(iconFeature);
            } else if (i == routeData.length - 1) {
                lastPosition.push(iconFeature);
                setCenter(longitude, latitude);

            } else {
                path.push(iconFeature);
            }
            coords.push([longitude, latitude, inSeca]);
        }


        var vectorSource = new SourceVector({
            features: path //add an array of features
        });
        var lastPositionVectorSource = new SourceVector({
            features: lastPosition //add an array of features
        });
        var departurePositionVectorSource = new SourceVector({
            features: departurePosition
        });

        var waypointPositionVectorSource = new SourceVector({
            features: path
        })

        var vectorLayer = new LayerVector({
            source: vectorSource,
            style: iconStyle
        });
        var lastPositionLayer = new LayerVector({
            source: lastPositionVectorSource,
            style: styleFunction()
        });
        var departurePositionLayer = new LayerVector({
            source: departurePositionVectorSource,
            style: departureIconStyle
        });

        var waypointPositionLayer = new LayerVector({
            source: waypointPositionVectorSource,
            style: iconWayPointStyle
        })

        if (isVoyageAnalysis) {
            map.addLayer(vectorLayer);
        } else {
            map.addLayer(waypointPositionLayer);
        }
        
        map.addLayer(lastPositionLayer);
        map.addLayer(departurePositionLayer);

        if (path.length > 0) {
            map.getView().fit(vectorSource.getExtent(), { maxZoom: 5 });
        }

        var lines = [];

        for (var i = 0; i < coords.length; i++) {
            var prev = coords[i - 1];
            var curr = coords[i];

            if (i != 0 && Math.abs(prev[0] - curr[0]) > 180) {
                var x1 = prev[0] - (180 * Math.sign(prev[0]));
                var x2 = curr[0] - (180 * Math.sign(curr[0]));
                var y1 = prev[1];
                var y2 = curr[1];
                var m = (y1 - y2) / (x1 - x2);
                var newY = m * -x1 + y1;

                var newCoord1 = [180 * Math.sign(prev[0]), newY];
                var newCoord2 = [180 * Math.sign(curr[0]), newY];

                var coords1 = coords.slice(0, i);
                coords1.push(newCoord1);
                lines.push(coords1);

                var coords2 = coords.slice(i, coords.length);
                coords2.splice(0, 0, newCoord2);
                
                lines.push(coords2);
            } else if (Math.abs(curr[0]) >= 180) {
            
                var lon = resolveLongitude(curr[0]);
                
                var newCoord = [lon, curr[1], curr[2]]
                if (Math.abs(curr[0]) > 180 && Math.abs(prev[0]) <= 180) {
                    newCoord[3] = true
                }
                 
                lines.push(newCoord);
            }else {
                lines.push(coords[i]);
            }
        }

        var features = [];
        if (isVoyageAnalysis) {
            var lineString = new LineString(lines);
            // transform to EPSG:3857
            lineString.transform('EPSG:4326', 'EPSG:3857');

            // create the feature
            var feature = new Feature({
                geometry: lineString,
                name: 'Line'
            });

            features.push(feature);
        
            var lineStyle = new Style({
                stroke: new Stroke({
                    color: '#ac0000',
                    width: 2,
                    lineDash: [0.1, 5]
                })
            });
            var source = new SourceVector({
                features: features
            });
            var vector = new LayerVector({
                source: source,
                style: [lineStyle]
            });
            map.addLayer(vector);
            
        } else  {
            var segmentCoordinates = [];
            var segmentIndex = 0;
            var prevCoordInSeca = lines[0] && lines[0][2];
            for (var i = 0; i < lines.length; i++) {
                var segmentList = segmentCoordinates[segmentIndex] || [];
                let isInSeca = lines[i][2] ? true : false;
          
                if (i != 0 && prevCoordInSeca != isInSeca) {
                    segmentIndex++;
                    segmentList = [];
                    // when entering/exiting seca, add duplicate seca point to non seca route 
                    // to overlap the lines drawn and avoid line gaps on map
                    if (prevCoordInSeca && !isInSeca) {
                        segmentList.push([lines[i - 1][0], lines[i - 1][1], prevCoordInSeca])
                    }
    
                    if (!prevCoordInSeca && isInSeca) {
                        segmentCoordinates[segmentIndex -1].push([lines[i - 1][0], lines[i - 1][1], isInSeca])
                    }
                    segmentList.push([lines[i - 1][0], lines[i - 1][1], isInSeca])
                    segmentCoordinates[segmentIndex] = segmentList;
                } else if (lines[i].length > 3) {
                    segmentIndex++;
                    segmentList = [];
                    segmentList.push([lines[i][0], lines[i][1], isInSeca]);
                    segmentCoordinates[segmentIndex] = segmentList;
                } else {
                    segmentList.push([lines[i][0], lines[i][1], isInSeca]);
                    segmentCoordinates[segmentIndex] = segmentList;
                }
                prevCoordInSeca = isInSeca;
            }

            for (var i = 0; i < segmentCoordinates.length; i++) {
                features = [];
                let isInSeca = segmentCoordinates[i][1] || segmentCoordinates[i][0];
                isInSeca = isInSeca[2];
                var lineSegment = new LineString(segmentCoordinates[i]);
                // transform to EPSG:3857
                lineSegment.transform('EPSG:4326', 'EPSG:3857');
                // create the feature
                var feature = new Feature({
                    geometry: lineSegment,
                    name: 'Line'
                });
                features.push(feature);
    
                var source = new SourceVector({
                    features: features
                });
    
                var strokeStyle = isInSeca ? {
                    color: '#4bbf68',
                    width: 2,
                } : {
                    color: '#ac0000',
                    width: 2,
                    lineDash: [0.1, 5]
                }
                var lineSegmentStyle = new Style({
                    stroke: new Stroke(strokeStyle)
                });
    
                var otherVector = new LayerVector({
                    source: source,
                    style: [lineSegmentStyle]
                });
                map.addLayer(otherVector);
            }
        } 

        var element = document.getElementById(labelElementId);

        var popup = new OverLay({
            element: element,
            positioning: 'bottom-center',
            stopEvent: false
        });
        map.addOverlay(popup);

        // display popup on hover
        map.on('pointermove', function(evt){
            var feature = map.forEachFeatureAtPixel(
                evt.pixel,
                function(feature, layer) {
                    return feature;
                }
            );

            if (feature) {
                var geometry = feature.getGeometry();
                var coord = geometry.getCoordinates();
                popup.setPosition(coord);
                $(element).attr('data-content', feature && feature.get('content'));
                $(element).popover({
                    'placement': 'top',
                    'html': true
                });
                $(element).popover('show');
            } else {
                $(element).popover('destroy');
            }
        });

        var target = map.getTarget();
        var jTarget = typeof target === "string" ? $("#" + target) : $(target);
        // change mouse cursor when over marker
        $(map.getViewport()).on('mousemove', function (e) {
            var pixel = map.getEventPixel(e.originalEvent);
            var hit = map.forEachFeatureAtPixel(pixel, function (feature, layer) {
                return true;
            });
            if (hit) {
                jTarget.css("cursor", "pointer");
            } else {
                jTarget.css("cursor", "");
            }
        });
    }

    $scope.$watch("loadingModal", function(value) {
        if (value) {
            $("#loadingModal").modal('show');
        } else {
            $("#loadingModal").modal('hide');
        }
    });

}]);
