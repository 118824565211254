// TODO: move enum Color here to create master list of theme color? or import from less variables
enum Color {
    // main theme
    THEME_BLUE = '#091320',
    GRAPH_BLUE = '#34A0C7',
    SELECTION_GREEN = '#196363',

    // traffic light system
    RED = '#ee402f',
    YELLOW = '#f7a153',
    GREEN = '#00816b',
    
    // shades
    WHITE = '#fff',
    WHITE_HIGHLIGHT = '#aaa',
    LIGHT_GRAY = '#444',
    MEDIUM_GRAY = '#666666',
    GREY = '#777777',
    SILVER = '#cccccc',

    NEUTRAL = '#989898',
    BLACK = '#000000',

    // DARK THEME - data
    DATA_1 = '#50CBAA',
    DATA_2 = '#9FBC2E',
    DATA_3 = '#7500BA',
    DATA_4 = '#2955C6',
    DATA_5 = '#E6D220',
    DATA_6 = '#C423BF',

    // LIGHT THEME -data 
    // DATA_1 = '#50CBAA',
    DATA_7 = '#ED7D31',
    DATA_9 = '#ECEE9A',
    DATA_10 = '#192836',
    DATA_11 = '#65717f',

    // CII Rating colors
    DARK_GREEN = '#60A13D',
    LIGHT_GREEN = '#9FCB2E',
    
    SEA_BLUE = '#5195d0',
    PORT_SKY_BLUE = '#99D6EA',
    ANCHOR_GREEN = '#96BC33',
    MANEUVER_YELLOW_GREEN = '#ECEE9A',
}

const colorThemes = {
    'skin-navfleet': {
        // main theme
        THEME_BLUE : '#091320',
        GRAPH_BLUE : '#34A0C7',
        SELECTION_GREEN : Color.SELECTION_GREEN,

        // chart
        CHART_BG: Color.THEME_BLUE,
        THEME_TEXT_COLOR: Color.WHITE,
        CHART_AXIS_TITLE_COLOR: Color.WHITE,
        CHART_LINE_COLOR: Color.WHITE,
        CHART_GRID_LINE_COLOR: Color.LIGHT_GRAY,
        CHART_LEGEND_HIGHLIGHT: Color.WHITE_HIGHLIGHT,

        // traffic light system
        RED : '#ee402f',
        YELLOW : '#f7a153',
        GREEN : '#00816b',
        GREY : "#777777",

        // shades
        WHITE : '#fff',
        WHITE_HIGHLIGHT : '#aaa',
        LIGHT_GRAY : '#444',
        
        // data
        DATA_1 : '#50CBAA',
        DATA_2 : '#9FBC2E',
        DATA_3 : '#7500BA',
        DATA_4 : '#2955C6',
        DATA_5 : '#E6D220',
        DATA_6 : '#C423BF',

        // tank forecast
        DATA_11 : Color.DATA_1,
        DATA_12 : Color.DATA_2,
        DATA_13 : Color.DATA_3,
        DATA_14 : Color.DATA_4,
        DATA_15 : Color.DATA_5,

        // advance analytics
        DATA_16 : Color.DATA_2,
        DATA_17 : Color.DATA_3,
        DATA_18 : Color.DATA_4,
        DATA_19 : Color.DATA_2,
        DATA_20 : Color.GRAPH_BLUE,

        // CII Rating colors
        CII_GREEN : Color.DARK_GREEN,
        CII_LIGHT_GREEN : Color.LIGHT_GREEN,
        CII_YELLOW : '#E6D220',
        CII_ORANGE : '#EB6A52',
        CII_RED : '#E91300',
        CII_ATTAINED_LINE: Color.DATA_4,

        // voyage analysis
        SEA_REPORT_COLOR : Color.GRAPH_BLUE,
        PORT_REPORT_COLOR : Color.DATA_1,
        ANCHOR_REPORT_COLOR : Color.DATA_2,
        MANEUVER_REPORT_COLOR : Color.DATA_5,

        // voyage analysis details
        CHARTER_PARTY_DATA: Color.DATA_2,
        AVG_PORT_CALL_DATA: Color.DATA_4,
        PORT_CONSUMPTION_DATA: Color.DATA_2,
        ANCHOR_CONSUMPTION_DATA: Color.DATA_1,

        SPEED_AIS_DATA: Color.DATA_4,

        
    },
    'skin-blue': {
        // main theme
        THEME_BLUE : '#fff',
        GRAPH_BLUE : '#5195d0',
        SELECTION_GREEN : '#1a1a1a',
        CHART_BG: Color.WHITE,
        THEME_TEXT_COLOR: Color.BLACK,
        CHART_AXIS_TITLE_COLOR: Color.MEDIUM_GRAY,
        CHART_LINE_COLOR: '#e6e6e6',
        CHART_GRID_LINE_COLOR: '#e6e6e6',
        CHART_LEGEND_HIGHLIGHT: Color.WHITE_HIGHLIGHT,

         // traffic light system
        RED : '#ac0000',
        YELLOW : '#f0c000',
        GREEN : '#a1bc39',
        GREY : "#909090",

        // shades
        WHITE : '#fff',
        WHITE_HIGHLIGHT : '#aaa',
        LIGHT_GRAY : '#444',

        // data
        DATA_1 : '#50CBAA',
        DATA_2 : '#ED7D31',
        DATA_3 : '#99D6EA',
        DATA_4 : '#ECEE9A',
        DATA_5 : '#192836',
        DATA_6 : '#65717f',

        // tank forecast
        DATA_11 : '#1132F3',
        DATA_12 : '#97a1a9',
        DATA_13 : '#8ed0e7',
        DATA_14 : '#B7C1FB',
        DATA_15 : Color.SEA_BLUE,

         // advance analytics
        DATA_16 : Color.SILVER,
        DATA_17 : '#595959',
        DATA_18 : '#103c68',
        DATA_19 : '#eb7d3c',
        DATA_20 : '#1132f3',

        // CII Rating colors
        CII_GREEN : Color.DARK_GREEN,
        CII_LIGHT_GREEN : Color.LIGHT_GREEN,
        CII_YELLOW : '#E6D220',
        CII_ORANGE : '#EB6A52',
        CII_RED : '#E91300',
        CII_ATTAINED_LINE: Color.SILVER,

        // voyage analysis
        SEA_REPORT_COLOR : Color.SEA_BLUE,
        PORT_REPORT_COLOR : Color.PORT_SKY_BLUE,
        ANCHOR_REPORT_COLOR : Color.ANCHOR_GREEN,
        MANEUVER_REPORT_COLOR : Color.MANEUVER_YELLOW_GREEN,

        // voyage analysis details
        CHARTER_PARTY_DATA: Color.DATA_10,
        AVG_PORT_CALL_DATA: '#103C68',
        PORT_CONSUMPTION_DATA: Color.ANCHOR_GREEN,
        ANCHOR_CONSUMPTION_DATA: Color.PORT_SKY_BLUE,

        SPEED_AIS_DATA: '#123',
    }
} 

const fontThemes = {
    'skin-navfleet': {
        FONT_FAMILY: 'Trebuchet MS, Verdana, sans-serif',
        BOLD: 'bold',
        LEGEND_WEIGHT: 'light',
    },
    'skin-blue': {
        FONT_FAMILY: 'Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif',
        BOLD: null,
        LEGEND_WEIGHT: 'bold',
    }
};

class ThemeService {
    theme: string = 'skin-navfleet';
    colors = colorThemes['skin-navfleet'];
    fonts = fontThemes['skin-navfleet'];

    getCurrentTheme(): string {
        return this.theme;
    };

    isDarkTheme(): boolean {
        return this.theme == 'skin-navfleet';
    }
    
    isLightTheme(): boolean {
        return this.theme != 'skin-navfleet'
    }

    setTheme(theme: string): void {
        this.theme = theme;
        this.colors = colorThemes[theme];
        this.fonts = fontThemes[theme];
    };
}

const themePalette = new ThemeService()

export {
    themePalette,
    Color
}