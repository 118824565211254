import { routerApp, baseUrl, enableHybrid } from '../app.module';
import hybridService from '../services/hybrid/index';
import { CACHE_NAME } from '../services/utilities';

routerApp.controller('userSettingsController', ['UserSettingsService', 'VesselSpecificationService', '$rootScope', '$scope', '$http', 'notify',
    function(UserSettingsService, VesselSpecificationService, $rootScope, $scope, $http, notify) {

    $rootScope.selectedLevels = ['user-settings'];

    $scope.userSettingsTab = 'passwordReset';

    // default to 15 %
    $rootScope.change_in_consumption_deviation_from_model_threshold = 15;

    $scope.submitDisabled = false;
    var showSuccessMessage = function() {
        notify({message: "Settings are updated!", duration: 2000, classes: ['ok-notification']});
        $scope.submitDisabled = false;
    }
    var showErrorMessage = function(message = undefined) {
        notify({message: message || "Error updating user settings, please try again later.", duration: 2000, classes: ['bad-notification']});
        $scope.submitDisabled = false;
    }

    var showUserSuccessMessage = function() {
        notify({message: "User Created!", duration: 2000, classes: ['ok-notification']});
        $scope.submitDisabled = false;
    }
    var showUserErrorMessage = function() {
        notify({message: "Username taken, please choose another one.", duration: 2000, classes: ['bad-notification']});
        $scope.submitDisabled = false;
    }

    $scope.resetPassword = function() {
        $scope.submitDisabled = true;
        UserSettingsService.resetPassword($rootScope.user.id, $scope.currentPassword, $scope.newPassword)
            .then(function() {
                showSuccessMessage();
                $scope.currentPassword = null;
                $scope.newPassword = null;
                $scope.newPasswordConfirmation = null;
            }).catch(error => {
                if (error.status == 401) {
                    showErrorMessage('Error resetting user (401). Please check your current and new password and try again.');
                } else {
                    showErrorMessage();
                }
                
            });
    };

    $scope.createUser = function() {
        $scope.submitDisabled = true;

        UserSettingsService.createUser($scope.firstName, $scope.lastName, $scope.userName, $scope.emailAddress, $scope.userForm.role, $scope.userForm.client, $scope.userForm.vessels)
            .then(function(response) {
                var res = response.data + '';
                if (res == 'true') {
                    showUserErrorMessage()
                } else {
                    var userCred = res.split(',');
                    $scope.userCredentials = userCred;
                    $("#userCreated").modal('show');
                    showUserSuccessMessage();
                }
            }, showErrorMessage);
    };

    // get clients for dropdown
    $http({
        url: baseUrl + 'clients/',
        method: 'GET',
    }).then(function(response) {
        $scope.clientsList = response.data.data;
    });

    // get a new list of vessels when a client is selected
    $scope.$watch('userForm.client', function(newValue, oldValue, scope) {
        if (newValue) {
            var url = baseUrl + 'clients/' + newValue + '/vessels';
            $http.get(url).then(function(response) {
                $scope.clientVessels = response.data.data;
                if ($scope.userForm) {
                    $scope.userForm.vessels = {};
                }
                for (var i = 0; i < $scope.clientVessels.length; i++) {
                    var vessel = $scope.clientVessels[i];
                    $scope.userForm.vessels[vessel.id] = true;
                }
            });
        } else {
            $scope.clientVessels = [];
        }
    });

    $scope.$watch('userForm.vessels', function(newValue, oldValue, scope) {
        $scope.vessels_count = Object.keys($scope.userForm.vessels).reduce(function(total, current_key) { if($scope.userForm.vessels[current_key] == true) { total+= 1 }; return total;}, 0);
    }, 1);


    $scope.getFile = function(elementId) {
        var files = [];
        $('#' + elementId).children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                files.push(this.files[0]);
            }
        });
        return files[0];
    };

    // vessel specifications update
    $scope.bulkUpdateVessels = function() {
        var files = [];
        $('#vesselSpecsFile').children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                files.push(this.files[0]);
            }
        });

        $scope.uploadingData = true;
        VesselSpecificationService.updateVessels(files[0], $scope.bulk_upload && $scope.bulk_upload.client, $scope.bulk_upload && $scope.bulk_upload.generate_users).then(function(response) {
            $scope.uploadingData = false;
            var vesselsCount = response.data.data.vessels;
            notify({
                message: vesselsCount + ' vessels updated succesfully!', 
                duration: 2000, 
                classes: ['ok-notification']
            });
        }, function(res) {
            $scope.uploadingData = false;
            showErrorMessage(res.data);
        });
    }

    $scope.uploadSpeedPowerModels = function() {
        var file = $scope.getFile('speedPowerModels');
        $scope.uploadingData = true;
        VesselSpecificationService.uploadSpeedPowerModels(file).then(function(response) {
            $scope.displayModalWithData(response.data.data);
        }).catch(function(response) { showErrorMessage(); }).finally(function() { $scope.uploadingData = false; });

    }

    // upload engine models
    $scope.uploadEngineModels = function() {
        var file = $scope.getFile('engineModels');
        $scope.uploadingData = true;
        VesselSpecificationService.uploadEngineModels(file).then(function(response) {
            $scope.displayModalWithData(response.data.data);
        }).catch(function(response) { showErrorMessage(); }).finally(function() { $scope.uploadingData = false; });
    }

    // upload displacement models
    $scope.uploadDisplacementModels = function() {
        var file = $scope.getFile('displacementModels');
        $scope.uploadingData = true;
        VesselSpecificationService.uploadDisplacementModels(file).then(function(response) {
            $scope.displayModalWithData(response.data.data);
        }).catch(function(response) { showErrorMessage(); }).finally(function() { $scope.uploadingData = false; })
    }

    // upload tank capacity plans
    $scope.uploadTankCapacityPlans = function() {
        var file = $scope.getFile('tankCapacityPlan');
        $scope.uploadingData = true;
        VesselSpecificationService.uploadTankCapacityPlans(file).then(function(response) {
            $scope.displayModalWithData(response.data.data);
        }).catch(function(response) { showErrorMessage(); }).finally(function() { $scope.uploadingData = false; })
    }

    $scope.displayModalWithData = function(responseData, message) {
        $scope.opMessage = message;
        $scope.opCount = responseData.updated;
        $scope.opModels = responseData.models;
        $("#opsModal").modal('show');
    }

    // email alert settings

    // define report types to modal label mapping
    $scope.reportTypeToMetadata = {
        sea_report: {
            label: 'sea report',
            allReportsKey: 'all_sea_reports',
        },
        port_report: {
            label: 'port report',
            allReportsKey: 'all_port_reports',
        },
        anchor_report: {
            label: 'anchor report',
            allReportsKey: 'all_anchor_reports',
        },
        maneuvering_report: {
            label: 'maneuvering report',
            allReportsKey: 'all_maneuvering_reports',
        },
        offhire_report: {
            label: 'offhire report',
            allReportsKey: 'all_offhire_reports',
        },
        sp_out_of_range: {
            label: 'speed performance out of range',
            allReportsKey: 'all_sp_out_of_range_reports'
        },
        sfoc_out_of_range: {
            label: 'SFOC out of range',
            allReportsKey: 'all_sfoc_out_of_range_reports'
        },
        pe_out_of_range: {
            label: 'propulsion efficiency out of range',
            allReportsKey: 'all_pe_out_of_range_reports'
        },
        cp_fuel_out_of_range: {
            label: 'consumption deviation from CP ',
            allReportsKey: 'all_cp_fuel_out_of_range_reports'
        },
        cp_speed_deviation: {
            label: 'speed deviation from CP',
            allReportsKey: 'all_cp_speed_deviation_reports'
        },
        consumption_above_105_percent_cp_fuel: {
            label: 'consumption above 105% cp fuel',
            allReportsKey: 'all_consumption_above_105_percent_cp_fuel_reports'
        },
        consumption_below_95_percent_cp_fuel: {
            label: 'consumption below 95% cp fuel',
            allReportsKey: 'all_consumption_below_95_percent_cp_fuel_reports'
        },
        change_in_consumption_deviation_from_model: {
            label: 'change in consumption deviation from model is more than []%',
            allReportsKey: 'all_change_in_consumption_deviation_from_model',
        },
        speed_shortfall: {
            label: 'speed shortfall',
            allReportsKey: 'all_speed_shortfall_reports'
        },
        sfoc_model_deviation: {
            label: 'SFOC deviation from model',
            allReportsKey: 'all_sfoc_model_deviation_reports',
        },
        sp_model_deviation: {
            label: 'speed performance deviation from model',
            allReportsKey: 'all_sp_model_deviation_reports',
        },
        power_model_deviation: {
            label: 'power deviation from model',
            allReportsKey: 'all_power_model_deviation_reports',
        },
        fuel_model_deviation: {
            label: 'fuel deviation from model',
            allReportsKey: 'all_fuel_model_deviation_reports',
        },
        tcrpm_model_deviation: {
            label: 'TCRPM deviation from model',
            allReportsKey: 'all_tcrpm_model_deviation_reports',
        },
        sca_temp_out_of_range: {
            label: 'scavenge temperature out of range',
            allReportsKey: 'all_sca_temp_out_of_range_reports',
        },
        blower_temp_out_of_range: {
            label: 'blower temperature out of range',
            allReportsKey: 'all_blower_temp_out_of_range_reports',
        },
        exh_temp_out_of_range: {
            label: 'exhaust temperature out of range',
            allReportsKey: 'all_exh_temp_out_of_range_reports',
        },
        idle_status: {
            label: 'idle status',
            allReportsKey: 'all_idle_status_reports',
        },
        minimum_safe_bunker: {
            label: 'minimum safe bunker',
            allReportsKey: 'all_minimum_safe_bunker_reports',
        },

        long_anchorage: {
            label: 'long anchorage',
            allReportsKey: 'all_long_anchorage_reports'
        },
        bunker_and_stock: {
            label: 'bunker and stock alert',
            allReportsKey: 'all_bunker_and_stock_reports'
        },
        arrivals: {
            label: 'arrival',
            allReportsKey: 'all_arrivals_reports'
        },
        departures: {
            label: 'departure',
            allReportsKey: 'all_departures_reports'
        },
        cargo_load_discharge: {
            label: 'cargo load/discharge',
            allReportsKey: 'all_cargo_load_discharge_reports'
        },
        ais_stoppages: {
            label: 'vessel stoppage',
            allReportsKey: 'all_ais_stoppages'
        },
        port_operation_logs: {
            label: 'port operation logs',
            allReportsKey: 'all_port_operation_logs'
        }
    };

    // copy vessels from the top dropdown (user vessels) to the
    // emailSettingsVessels array on this module, this will be used to fill in
    // the vessels table. when that's done, load user email settings to get the
    // vessels and fill in which checkboxes should be checked
    $scope.emailSettingsVessels = [];
    $scope.$watch('vessels', function(n) {
        if (n != undefined) {
            var emailSettingsVessels = [];
            for (var i = 0; i < n.length; i++) {
                emailSettingsVessels.push(n[i]);
            }
            $scope.emailSettingsVessels = emailSettingsVessels;

            UserSettingsService.getEmailSettings($rootScope.user.id).then(function(response) {
                var data = response.data;
                if (!data.vessels) {
                    return;
                }
                $scope.emailAlertsEnabled = data.enabled;
                $scope.emailAlertsAddress = data.email_address;
                if (data.change_in_consumption_deviation_from_model_threshold) {
                    $scope.change_in_consumption_deviation_from_model_threshold = data.change_in_consumption_deviation_from_model_threshold;
                }
                var vessels = data.vessels;
                for (var i = 0; i < $scope.emailSettingsVessels.length; i++) {
                    var vesselId = $scope.emailSettingsVessels[i].id;
                    var vesselSettings = vessels[vesselId];
                    if (vesselSettings != undefined) {
                        var reportTypes = Object.keys($scope.reportTypeToMetadata);
                        for (var j = 0; j < reportTypes.length; j++) {
                            var reportType = reportTypes[j];
                            $scope.emailSettingsVessels[i][reportType] = vesselSettings[reportType];
                        }

                    }
                }
            });
        }
    });

    // used to update checkbox to true/false on all vessels
    $scope.toggleAllForEmail = function(reportType, enabled) {
        for (var i = 0; i < $scope.emailSettingsVessels.length; i++) {
            var emailSettingsVessel = $scope.emailSettingsVessels[i];
            emailSettingsVessel[reportType] = enabled;
        }
    }

    // enable watching header flags to check/uncheck boxes for all vessel rows
    var enableHeaderCheckboxes = function() {
        $scope.$watch('features.email_alerts.all_sea_reports', function(enabled) {
            $scope.toggleAllForEmail('sea_report', enabled);
        });
        $scope.$watch('features.email_alerts.all_port_reports', function(enabled) {
            $scope.toggleAllForEmail('port_report', enabled);
        });
        $scope.$watch('features.email_alerts.all_anchor_reports', function(enabled) {
            $scope.toggleAllForEmail('anchor_report', enabled);
        });
        $scope.$watch('features.email_alerts.all_maneuvering_reports', function(enabled) {
            $scope.toggleAllForEmail('maneuvering_report', enabled);
        });
        $scope.$watch('features.email_alerts.all_offhire_reports', function(enabled) {
            $scope.toggleAllForEmail('offhire_report', enabled);
        });
        $scope.$watch('features.email_alerts.all_sp_out_of_range_reports', function(enabled) {
            $scope.toggleAllForEmail('sp_out_of_range', enabled);
        });
        $scope.$watch('features.email_alerts.all_sfoc_out_of_range_reports', function(enabled) {
            $scope.toggleAllForEmail('sfoc_out_of_range', enabled);
        });
        $scope.$watch('features.email_alerts.all_pe_out_of_range_reports', function(enabled) {
            $scope.toggleAllForEmail('pe_out_of_range', enabled);
        });
        $scope.$watch('features.email_alerts.all_cp_fuel_out_of_range_reports', function(enabled) {
            $scope.toggleAllForEmail('cp_fuel_out_of_range', enabled);
        });
        $scope.$watch('features.email_alerts.all_cp_speed_deviation_reports', function(enabled) {
            $scope.toggleAllForEmail('cp_speed_deviation', enabled);
        });
        $scope.$watch('features.email_alerts.all_change_in_consumption_deviation_from_model', function(enabled) {
            $scope.toggleAllForEmail('change_in_consumption_deviation_from_model', enabled);
        });
        $scope.$watch('features.email_alerts.all_consumption_above_105_percent_cp_fuel_reports', function(enabled) {
            $scope.toggleAllForEmail('consumption_above_105_percent_cp_fuel', enabled);
        });
        $scope.$watch('features.email_alerts.all_consumption_below_95_percent_cp_fuel_reports', function(enabled) {
            $scope.toggleAllForEmail('consumption_below_95_percent_cp_fuel', enabled);
        });
        $scope.$watch('features.email_alerts.all_speed_shortfall_reports', function(enabled) {
            $scope.toggleAllForEmail('speed_shortfall', enabled);
        });
        $scope.$watch('features.email_alerts.all_sfoc_model_deviation_reports', function(enabled) {
            $scope.toggleAllForEmail('sfoc_model_deviation', enabled);
        });
        $scope.$watch('features.email_alerts.all_sp_model_deviation_reports', function(enabled) {
            $scope.toggleAllForEmail('sp_model_deviation', enabled);
        });
        $scope.$watch('features.email_alerts.all_power_model_deviation_reports', function(enabled) {
            $scope.toggleAllForEmail('power_model_deviation', enabled);
        });
        $scope.$watch('features.email_alerts.all_fuel_model_deviation_reports', function(enabled) {
            $scope.toggleAllForEmail('fuel_model_deviation', enabled);
        });
        $scope.$watch('features.email_alerts.all_tcrpm_model_deviation_reports', function(enabled) {
            $scope.toggleAllForEmail('tcrpm_model_deviation', enabled);
        });
        $scope.$watch('features.email_alerts.all_sca_temp_out_of_range_reports', function(enabled) {
            $scope.toggleAllForEmail('sca_temp_out_of_range', enabled);
        });
        $scope.$watch('features.email_alerts.all_blower_temp_out_of_range_reports', function(enabled) {
            $scope.toggleAllForEmail('blower_temp_out_of_range', enabled);
        });
        $scope.$watch('features.email_alerts.all_exh_temp_out_of_range_reports', function(enabled) {
            $scope.toggleAllForEmail('exh_temp_out_of_range', enabled);
        });
        $scope.$watch('features.email_alerts.all_idle_status_reports', function(enabled) {
            $scope.toggleAllForEmail('idle_status', enabled);
        });
        $scope.$watch('features.email_alerts.all_minimum_safe_bunker_reports', function(enabled) {
            $scope.toggleAllForEmail('minimum_safe_bunker', enabled);
        });

        $scope.$watch('features.email_alerts.all_long_anchorage_reports', function(enabled) {
            $scope.toggleAllForEmail('long_anchorage', enabled);
        });
        $scope.$watch('features.email_alerts.all_bunker_and_stock_reports', function(enabled) {
            $scope.toggleAllForEmail('bunker_and_stock', enabled);
        });
        $scope.$watch('features.email_alerts.all_arrivals_reports', function(enabled) {
            $scope.toggleAllForEmail('arrivals', enabled);
        });
        $scope.$watch('features.email_alerts.all_departures_reports', function(enabled) {
            $scope.toggleAllForEmail('departures', enabled);
        });
        $scope.$watch('features.email_alerts.all_cargo_load_discharge_reports', function(enabled) {
            $scope.toggleAllForEmail('cargo_load_discharge', enabled);
        });
        $scope.$watch('features.email_alerts.all_ais_stoppages', function(enabled) {
            $scope.toggleAllForEmail('ais_stoppages', enabled);
        });
        $scope.$watch('features.email_alerts.all_port_operation_logs', function(enabled) {
            $scope.toggleAllForEmail('port_operation_logs', enabled);
        });
    };
    enableHeaderCheckboxes();

    // generate the dictionary that needs to be sent to the backend to update
    // email alert settings
    $scope.updateEmailSettings = function() {
        $scope.submitDisabled = true;

        var userVesselSettingsResult = {};
        for (var i = 0; i < $scope.emailSettingsVessels.length; i++) {
            var vesselSettings = $scope.emailSettingsVessels[i];
            var userVesselSetting = {};
            var hasKeys = false;
            var reportTypes = Object.keys($scope.reportTypeToMetadata);
            for (var j = 0; j < reportTypes.length; j++) {
                var reportType = reportTypes[j];
                if (vesselSettings[reportType]) {
                    userVesselSetting[reportType] = true;
                    hasKeys = true;
                }
            }
            if (hasKeys) {
                userVesselSettingsResult[vesselSettings.id] = userVesselSetting;
            }
        }
        var userEmailSettings = {
            enabled: $scope.emailAlertsEnabled,
            email_address: $scope.emailAlertsAddress,
            vessels: userVesselSettingsResult,
            change_in_consumption_deviation_from_model_threshold: $scope.change_in_consumption_deviation_from_model_threshold,
        }

        UserSettingsService.updateEmailSettings($rootScope.user.id, userEmailSettings)
            .then(showSuccessMessage, showErrorMessage);
    }

    $scope.countVessels = function(reportTypeKey) {
        var count = 0;
        for (var i = 0; i < $scope.emailSettingsVessels.length; i++) {
            var vesselSettings = $scope.emailSettingsVessels[i];
            if (vesselSettings[reportTypeKey]) {
                count += 1;
            }
        }
        return count;
    }

    $scope.isHybrid = enableHybrid;

    $scope.clearIndexedDB = function() {
        
        var promises = [
            hybridService.serverUsersClear(),
            hybridService.serverMetaClear(),
            hybridService.serverProgressClear(),
            hybridService.serverReportsClear(),
            hybridService.serverEngineReportsClear(),
            hybridService.serverOffhireReportsClear(),
        ];

        $scope.goOnlineMode();
    
        Promise.all(promises).then(function() {
            console.log('clear indexeddb')
            notify({message: "Data cleared. Please refresh browser", duration: 5000, classes: ['ok-notification']});
        }).catch(function(error) {
            console.error(error)
            notify({message: "Something went wrong. Please refresh browser", duration: 2000, classes: ['bad-notification']});
        })
    };

    $scope.clearCache = function() {
        hybridService.messageActiveServiceWorker({
            type: 'SET_TIMEOUT',
            payload: { status: false}
        });
        hybridService.serverProgressUpdate({
            count: 'count',
            cacheCount: 0,  
            apiRequestCount: 0,
            cacheBusterHasCached: false
        })
        $scope.goOnlineMode();

        window.caches.keys().then(cacheNames => {
            let foundCache = cacheNames.filter(cacheName => cacheName == CACHE_NAME);
            if (foundCache.length == 1) {
                window.caches.delete(foundCache[0])
                .then(results => {
                    console.log(results)
                })
                .catch(error => {
                    console.error(error);
                    notify({message: "Something went wrong. Please refresh browser", duration: 2000, classes: ['bad-notification']});
                })
            }
            
        })
    }
}]);
