import { routerApp, baseUrl } from '../app.module';

routerApp.factory('UserSettingsService', ['$http', '$log',function($http, $log) {
    return {
        resetPassword: function(userId, currentPassword, newPassword) {
            $log.debug('Resetting password for user.');
            var url = baseUrl + "users/" + userId + "/reset_password";
            return $http({
                url: url,
                method: 'POST',
                data: {
                    currentPassword: currentPassword,
                    newPassword: newPassword
                }
            });
        },

        createUser: function(firstName, lastName, userName, emailAddress, role, client, vessels) {
            $log.debug('Creating user.');
            var name = firstName + ' ' + lastName;
            var url = baseUrl + "users/create_user";
            return $http({
                url: url,
                method: 'POST',
                data: {
                    name: name,
                    userName: userName,
                    emailAddress: emailAddress,
                    role: role,
                    client: client,
                    vessels: vessels
                }
            });
        },

        /* email alert settings page */
        getEmailSettings: function(userId) {
            $log.debug("Loading user settings.");
            var url = baseUrl + "users/" + userId + "/emailAlertSettings";
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        updateEmailSettings: function(userId, settings) {
            $log.debug("Updating user email settings.");
            var url = baseUrl + "users/" + userId + "/emailAlertSettings";
            return $http({
                    url: url,
                    method: "POST",
                    data: settings
            });
        },
    }

}]);
