export class User
{
    _id?: string;
    username: string;
    name: string;
    disabled: boolean;
    role: string;
    features: Features;
    password_hash: string;
    auth_token: string;
    client: string;
    email: string;
    navbox_id: string;
    vesselInfo: any[];
    vessels: {};


    constructor(user: any, creating: boolean) {
        if (creating) {
            this.name = null;
            this.username = null;
            this.role = null;
            this.disabled = false;
            this.features = new Features({});
            this.password_hash = null;
            this.auth_token = null;
            this.client = null;
            this.email = null;
            this.navbox_id = null;
            this.vesselInfo = [];
            this.vessels = {};
        } else {
            this._id = user._id;
            this.name = user.name;
            this.email = user.email;
            this.username = user.username;
            this.disabled = user.disabled;   
            this.features = new Features(user.features);
            this.role = user.role;
            this.password_hash = user.password_hash;
            this.auth_token = user.auth_token;
            this.client = user.client;
            this.navbox_id = user.navbox_id;
            this.vesselInfo = user.vesselInfo;
            this.vessels = user.vessels;
        }
    }
}

export class Features {
    advanced_analytics?: IFeature;
    data_reports?: IFeature;
    email_alerts?: IFeature;
    engine_reporting?: IFeature;
    fuel_tables?: IFeature;
    fleet_performance?: IFeature;
    fleet_tracker?: IFeature;
    offhire_reporting?: IFeature;
    mrv?: IFeature;
    navfleet?: IFeature;
    tableau?: IFeature;
    reporting?: IFeature;
    vessel_dashboard?: IFeature;
    voyage_analysis?: IFeature;
    voyage_planning?: IFeature;
    crew_dashboard?: IFeature;
    analytics_directory?: IFeature;
    analytics_redirect?: IFeature;

    constructor(features) {
        this.advanced_analytics = new Feature(
            FeatureSet.AdvancedAnalytics, 
            features.hasOwnProperty('advanced_analytics') ? features.advanced_analytics.enabled : false
        );
        this.email_alerts = new Feature(
            FeatureSet.Reporting, 
            features.hasOwnProperty('reporting') ? features.reporting.enabled : false
        );
        this.data_reports = new Feature(
            FeatureSet.DataReports, 
            features.hasOwnProperty('data_reports') ? features.data_reports.enabled : false
        );
        this.engine_reporting = new Feature(
            FeatureSet.EngineReporting, 
            features.hasOwnProperty('engine_reporting') ? features.engine_reporting.enabled : false
        );
        this.fuel_tables = new Feature(
            FeatureSet.FuelTables, 
            features.hasOwnProperty('fuel_tables') ? features.fuel_tables.enabled : false
        );
        this.fleet_performance = new Feature(
            FeatureSet.FleetPerformance,
            features.hasOwnProperty('fleet_performance') ? features.fleet_performance.enabled : false
        );
        this.fleet_tracker = new Feature(
            FeatureSet.FleetTracker, 
            features.hasOwnProperty('fleet_tracker') ? features.fleet_tracker.enabled : false
        );
        this.offhire_reporting = new Feature(
            FeatureSet.OffhireReporting, 
            features.hasOwnProperty('offhire_reporting') ? features.offhire_reporting.enabled : false
        );
        this.mrv = new Feature(
            FeatureSet.Mrv, 
            features.hasOwnProperty('mrv') ? features.mrv.enabled : false
        );
        this.navfleet = new Feature(
            FeatureSet.NavFleet, 
            features.hasOwnProperty('navfleet') ? features.navfleet.enabled : false
        );
        this.tableau = new Feature(
            FeatureSet.Tableau, 
            features.hasOwnProperty('tableau') ? features.tableau.enabled : false);
        this.reporting = new Feature(
            FeatureSet.Reporting, 
            features.hasOwnProperty('reporting') ? features.reporting.enabled : false
        );
        this.vessel_dashboard = new Feature(
            FeatureSet.VesselDashboard, 
            features.hasOwnProperty('vessel_dashboard') ? features.vessel_dashboard.enabled : false
        );
        this.voyage_analysis = new Feature(
            FeatureSet.VoyageAnalysis, 
            features.hasOwnProperty('voyage_analysis') ? features.voyage_analysis.enabled : false
        );

        this.voyage_planning = new Feature(
            FeatureSet.VoyagePlanning, 
            features.hasOwnProperty('voyage_planning') ? features.voyage_planning.enabled : false
        );
        this.analytics_directory = new Feature(
            FeatureSet.AnalyticsDirectory, 
            features.hasOwnProperty('analytics_directory') ? features.analytics_directory.enabled : false
        );
        this.analytics_redirect = new Feature(
            FeatureSet.AnalyticsRedirect, 
            features.hasOwnProperty('analytics_redirect') ? features.analytics_redirect.enabled : false);

    }
}

export class Feature implements IFeature {
    _cls: string;
    enabled: boolean;

    constructor(cls: string, enabled: boolean) {
        this._cls = cls;
        this.enabled = enabled;
    }
}

export interface IFeature {
    _cls: string;
    enabled: boolean;
}

enum FeatureSet {
    Reporting = 'ReportingFeature',
    OffhireReporting = 'OffhireReporting',
    EngineReporting = 'EngineReporting',
    EmailAlerts = 'EmailAlertsFeature',
    NavFleet = 'NavFleetFeature',
    FleetPerformance = 'FleetPerformanceFeature',
    FleetTracker = 'FleetTrackerFeature',
    VoyageAnalysis = 'VoyageAnalysisFeature',
    VoyagePlanning = 'VoyagePlanningFeature',
    FuelTables = 'FuelTablesFeature',
    AnalyticsDirectory = 'AnalyticsDirectoryFeature',
    AnalyticsRedirect = 'AnalyticsRedirectFeature',
    Mrv = 'MRVFeature',
    Tableau = 'TableauFeature',
    DataReports = 'DataReportsFeature',
    AdvancedAnalytics = 'AdvancedAnalyticsFeature',
    VesselDashboard = 'VesselDashboardFeature',
    VesselSettings = 'VesselSettingsFeature'
}