import { routerApp, enableOnPremise } from '../app.module';
import { getCacheBustedTemplate } from '../app.route';
import { TagListConfiguration, SensorOptions, SupplyOptions } from '../models/tag-list-configuration';
import { autoPopulateMainFlowMeterTags, autoPopulateBoilerFlowMeterTags } from '../services/utilities';
import  hybridService  from '../services/hybrid';

routerApp.directive('tagListConfiguration', ['VesselSpecificationService', '$q', 'notify', function(VesselSpecificationService, $q, notify) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-models/tag-list-configuration.html'),
        scope: {
            vesselId: '=',
            vesselSpecifications: '='
        },
        link: function(scope) {
            scope.tooltipMessage = 'The enable/disable process depends on the internet connectivity of the vessel. It might take maximum of 30 minutes until the functionality is in place (Considering that the vessels has internet connection)';
            scope.sensorOptions = SensorOptions;
            scope.supplyOptions = SupplyOptions;

            function fetchTagListConfiguration(vesselId: string) {
                return VesselSpecificationService.getTagListConfiguration(vesselId).then(function(response) {
                    return response.data.data;
                });
            };

            function fetchVesselInformation(vesselId: string) {
                return VesselSpecificationService.getVesselSpecifications(vesselId).then(function(response) {
                    return response.data;
                });
            };

            $q.all([fetchTagListConfiguration(scope.vesselId), fetchVesselInformation(scope.vesselId)]).then(function(responses) {
                if (responses && responses.length === 2) {
                    let configuration: TagListConfiguration = responses[0];
                    let vesselSpecifications = responses[1];
                    scope.vesselSpecifications = vesselSpecifications;
                    scope.init(configuration);
                }
            }).catch(function(error) {
                notify({message: 'Error retrieving tag list/vessel information, try again in a bit!', duration: 2000, classes: ['bad-notification']});
            });

            scope.isMainSuppliesAutoPopulated = function() {
                const mainFlowMeterInstallation = scope.vesselSpecifications?.main_flow_meter_installation?._cls;
                if (!mainFlowMeterInstallation)
                    return false;
                return autoPopulateMainFlowMeterTags(mainFlowMeterInstallation);
            };

            scope.isBoilerSuppliesAutoPopulated = function() {
                const boilerFlowMeterInstallation = scope.vesselSpecifications?.boiler_flow_meter_installation?._cls;
                if (!boilerFlowMeterInstallation)
                    return false;
                return autoPopulateBoilerFlowMeterTags(boilerFlowMeterInstallation);
            };

            scope.isDualMEConfiguration = () => (scope.vesselSpecifications?.engine?.number_of_main_engines ?? 0) > 1;

            scope.init = function(configuration: TagListConfiguration) {
                if (configuration) {
                    scope.isTagListConfigured = true;
                    scope.tagListConfiguration = configuration;
                    scope.setMultiSelections(configuration);
                }
                else {
                    scope.tagListConfiguration = new TagListConfiguration();
                }
            };

            scope.setMultiSelections = function(configuration: TagListConfiguration) {        
                scope.courseEnabled = configuration.cog_ais_enabled || configuration.cog_sensors_enabled;
                if (configuration.cog_ais_enabled)
                    scope.selectedCourse = SensorOptions[0].value;
                if (configuration.cog_sensors_enabled)
                    scope.selectedCourse = SensorOptions[1].value;
                
                scope.speedEnabled = configuration.speed_ais_enabled || configuration.speed_sensors_enabled;
                if (configuration.speed_ais_enabled)
                    scope.selectedSpeed = SensorOptions[0].value;
                if (configuration.speed_sensors_enabled)
                    scope.selectedSpeed = SensorOptions[1].value;
                
                scope.positionEnabled = configuration.position_ais_enabled || configuration.position_sensors_enabled;    
                if (configuration.position_ais_enabled)
                    scope.selectedPosition = SensorOptions[0].value;
                if (configuration.position_sensors_enabled)
                    scope.selectedPosition = SensorOptions[1].value;
    
                scope.supplyMEDiffEnabled = configuration.supply_me_diff_counter_enabled || configuration.supply_me_diff_rate_enabled; 
                if (configuration.supply_me_diff_counter_enabled)
                    scope.selectedSupplyMEDiff = SupplyOptions[1].value;
                if (configuration.supply_me_diff_rate_enabled)
                    scope.selectedSupplyMEDiff = SupplyOptions[0].value;
    
                scope.supplyAEDiffEnabled = configuration.supply_ae_diff_counter_enabled || configuration.supply_ae_diff_rate_enabled; 
                if (configuration.supply_ae_diff_counter_enabled)
                    scope.selectedSupplyAEDiff = SupplyOptions[1].value;
                if (configuration.supply_ae_diff_rate_enabled)
                    scope.selectedSupplyAEDiff = SupplyOptions[0].value;
            };

            scope.onEnableTagListChange = function() {
                if(scope.isTagListConfigured) {
                    let configuration = scope.tagListConfiguration ?? new TagListConfiguration();
                    configuration.setNpeDefault();
                    scope.tagListConfiguration = configuration;
                }
                else {
                    scope.tagListConfiguration = new TagListConfiguration();
                }
                scope.setMultiSelections(scope.tagListConfiguration);
            };

            scope.onEnableCourseChange = function() {
                scope.selectedCourse = scope.courseEnabled ? SensorOptions[0].value : null;
                scope.onCourseOptionChange();
            };

            scope.onEnableSpeedChange = function() {
                scope.selectedSpeed = scope.speedEnabled ? SensorOptions[0].value : null;
                scope.onSpeedOptionChange();
            };

            scope.onEnablePositionChange = function() {
                scope.selectedPosition = scope.positionEnabled ? SensorOptions[0].value : null;
                scope.tagListConfiguration.observed_distance_enabled = scope.positionEnabled;
                scope.onPositionOptionChange();
            };

            scope.onEnableSupplyMEDiffChange = function() {
                scope.selectedSupplyMEDiff = scope.supplyMEDiffEnabled ? SupplyOptions[0].value : null;
                scope.onSupplyMEDiffOptionChange();
            };

            scope.onEnableSupplyAEDiffChange = function() {
                scope.selectedSupplyAEDiff = scope.supplyAEDiffEnabled ? SupplyOptions[0].value : null;
                scope.onSupplyAEDiffOptionChange();
            };

            scope.onCourseOptionChange = function() {
                scope.tagListConfiguration.cog_ais_enabled = scope.selectedCourse === SensorOptions[0].value;
                scope.tagListConfiguration.cog_sensors_enabled = scope.selectedCourse === SensorOptions[1].value;
                scope.courseEnabled = scope.tagListConfiguration.cog_ais_enabled || scope.tagListConfiguration.cog_sensors_enabled;
            };

            scope.onSpeedOptionChange = function() {
                scope.tagListConfiguration.speed_ais_enabled = scope.selectedSpeed === SensorOptions[0].value;
                scope.tagListConfiguration.speed_sensors_enabled = scope.selectedSpeed === SensorOptions[1].value;
                scope.speedEnabled = scope.tagListConfiguration.speed_ais_enabled || scope.tagListConfiguration.speed_sensors_enabled;
            };

            scope.onPositionOptionChange = function() {
                scope.tagListConfiguration.position_ais_enabled = scope.selectedPosition === SensorOptions[0].value;
                scope.tagListConfiguration.position_sensors_enabled = scope.selectedPosition === SensorOptions[1].value;
                scope.positionEnabled = scope.tagListConfiguration.position_ais_enabled || scope.tagListConfiguration.position_sensors_enabled;
            };

            scope.onSupplyMEDiffOptionChange = function() {
                scope.tagListConfiguration.supply_me_diff_rate_enabled = scope.selectedSupplyMEDiff === SupplyOptions[0].value;
                scope.tagListConfiguration.supply_me_diff_counter_enabled = scope.selectedSupplyMEDiff === SupplyOptions[1].value;
                scope.supplyMEDiffEnabled = scope.tagListConfiguration.supply_me_diff_rate_enabled || scope.tagListConfiguration.supply_me_diff_counter_enabled;
            };

            scope.onSupplyAEDiffOptionChange = function() {
                scope.tagListConfiguration.supply_ae_diff_rate_enabled = scope.selectedSupplyAEDiff === SupplyOptions[0].value;
                scope.tagListConfiguration.supply_ae_diff_counter_enabled = scope.selectedSupplyAEDiff === SupplyOptions[1].value;
                scope.supplyAEDiffEnabled = scope.tagListConfiguration.supply_ae_diff_rate_enabled || scope.tagListConfiguration.supply_ae_diff_counter_enabled;
            };
            
            scope.onDualMainEngineOptionChange = function() {
                if (scope.tagListConfiguration.me1_me2_power_enabled) {
                    scope.tagListConfiguration.me1_power_enabled = true;
                    scope.tagListConfiguration.me2_power_enabled = true;
                } else {
                    scope.tagListConfiguration.me1_power_enabled = false;
                    scope.tagListConfiguration.me2_power_enabled = false;
                }
            };

            scope.updateTagListConfiguration = function() {
                if (enableOnPremise) {
                    hybridService.serverTagConfigurationUpdate(scope.vesselId, scope.tagListConfiguration).then(function(response) {
                        notify({message: 'Tag list successfully updated', duration: 2000, classes: ['ok-notification']});
                    }).catch(function(error) {
                        notify({message: 'Something went wrong while updating the tag list', duration: 2000, classes: ['bad-notification']});
                    });
                }
                else {
                    VesselSpecificationService.updateTagListConfiguration(scope.vesselId, scope.tagListConfiguration).then(function(response) {
                        notify({message: 'Tag list successfully updated', duration: 2000, classes: ['ok-notification']});
                    }).catch(function(error) {
                        notify({message: 'Something went wrong while updating the tag list', duration: 2000, classes: ['bad-notification']});
                    });
                }           
            };
        }
    }
}]);