import { routerApp, baseUrl } from '../app.module';
import angular from 'angular';

routerApp.factory('ManagementVesselsService', ['$http', '$log', function($http: angular.IHttpService, $log: angular.ILogService) {
    return {
        getVessels: (clientId: string = null) => {
            return $http.get(`${baseUrl}v1/management/vessel/` + (clientId ? `?client_id=${clientId}` : ''));
        },
        getVessel: (vesselId: string) => {
            return $http.get(`${baseUrl}v1/management/vessel/${vesselId}`);
        },
        createVessel: (vessel: any) => {
            return $http.post(`${baseUrl}v1/management/vessel/create`, vessel);
        },
        updateVessel: (vesselId: string, user: any) => {
            return $http.put(`${baseUrl}v1/management/vessel/update/${vesselId}`, user);
        },
        disableVessel: (vesselId: string, user: any) => {
            return $http.put(`${baseUrl}v1/management/vessel/disable/${vesselId}`, user);
        },
        retrieveNavtorID: (vesselId: string, vessel: any) => {
            return $http.put(`${baseUrl}v1/management/vessel/retrieveNavtorID/${vesselId}`, vessel);
        },
        updateEngineModel: (vesselId: string, data: any, data_type: any) => {
            if (data_type == 'file') {
                var form = new FormData();
                form.append('filedata', data, data.name);
                return $http.post(`${baseUrl}v1/management/vessel/updateEngineModel/${vesselId}`, form, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                })
            }
            else if (data_type == 'dict') {
                return $http.post(`${baseUrl}v1/management/vessel/updateEngineModel/${vesselId}`, data, {
                })
            }
        }
    }
}]);