import { routerApp, analyticsUrl } from '../../app.module';

routerApp.factory('FleetService', ['$http', function($http) {
    var fleetData = null;
    var baselineData = null;
    var fleetOperationalData = null;

    return {
        getFleet: function(username) {
            var url = analyticsUrl + "fleetPositions?username=" + username;
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        getBaselineAnalysis: function(username) {
            var url = analyticsUrl + "fleetBaselineAnalysis?username=" + username;
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        setFleetData: function(data) {
            fleetData = data;
        },
        setBaselineAnalysisData: function(data) {
            baselineData = data;
        },
        getFleetData: function() {
            return fleetData;
        },
        getBaselineAnalysisData: function() {
            return baselineData;
        },
        loadFleetOperationStatus: function(username, days) {
            var url = analyticsUrl + 'vessels/fleet_operational_status?username=' + username;
            if (days) {
                url += '&days=' + days;
            }
            return $http({
                url: url
            });
        },
        getFleetOperationStatus: function() {
            return fleetOperationalData;
        },
        setFleetOperationStatus: function(data) {
            fleetOperationalData = data;
        }
    };
}]);
