import { routerApp } from '../app.module';

routerApp.factory('errorHandlerService', ['notify', function(notify) {
    /**
    Design Goals:
    1. Allow exceptions from one part of the application to be handled elsewhere - or possibly multiple places (i.e. 'display error notification to user', 
    2. Provide central management of common error conditions - i.e. log to server, display notification to user, redirect to login.
    3. Allow exceptions to be thrown from controllers, directives, services etc.    
     
     */
    // Default values
    var defaultDuration = 2000;
    
    var log = function(args) {console.log(args);return};
    var errorlog = function(args) {console.error(args);return};
    var badNotify = function(notifyConfig, callback){
        if (notifyConfig.duration) {
            defaultDuration = notifyConfig.duration;
        }
        notify({message: notifyConfig.message, duration: defaultDuration, classes: ['bad-notification']});
        if (callback && typeof callback === 'function') {
            return callback();
        }
    };

    var warnNotify = function(notifyConfig, callback){
        if (notifyConfig.duration) {
            defaultDuration = notifyConfig.duration;
        }
        notify({message: notifyConfig.message, duration: defaultDuration, classes: ['warning-notification']});
        if (callback && typeof callback === 'function') {
            return callback();
        }
    };
    var okNotify = function(notifyConfig, callback){
        if (notifyConfig.duration) {
            defaultDuration = notifyConfig.duration;
        }
        notify({message: notifyConfig.message, duration: defaultDuration, classes: ['ok-notification']});
        if (callback && typeof callback === 'function') {
            return callback();
        }
    };
    return {
        // Browser logging
        log: log,
        errorlog: errorlog,
        // Notifying User error
        badNotify: badNotify,
        warnNotify: warnNotify,
        okNotify: okNotify,
        cacheErrorHandler: function(args) {
            var config = {
                message: 'Data could not be stored for offline use. Please refresh browser', 
                duration: this.defaultDuration, 
                classes: ['bad-notification']
            };
            badNotify(config, function(){
                errorlog('Issue with browser cache:');
                log(args);
            });
        },
        localDbErrorHandler: function(args) {
            errorlog("There's been a transaction error with indexedDb");
            log(args);
        }
  
        // TODO: Server Logging
        // TODO: maybe redirects
    };
}]);
