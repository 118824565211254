import { routerApp, baseUrl } from '../app.module';

routerApp.factory('PositionApiService', ['$rootScope', '$http', function($rootScope, $http) {
    var positions;
    return {
        loadPositions: function(id) {
            var url = baseUrl + id + "/positions";
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        setPositions: function(data) {
            positions = data;
        },
        getPositions: function() {
            return positions;
        }
    }
}]);