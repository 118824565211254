import angular from 'angular';
import { routerApp, analyticsUrl, themePalette } from '../../app.module';
declare let L; // cannot use leaflet typescript since its an external unpkg library so declared L here

routerApp.controller('fleetController', ['$rootScope', '$scope', '$state', '$http', 'FleetService', 'VesselService', 'ReportService', 'ReportsApiService', 'notify', 
    function($rootScope, $scope, $state, $http, FleetService, VesselService, ReportService, ReportsApiService, notify) {

    $rootScope.selectedMenu = 'tracker';
    $rootScope.selectedVoyage = {};
    $rootScope.selectedVesselName = '';

    $scope.drawFleetData = function() {
        if ($scope.fleetData) {
            // Initialize active properties, activating only selectedVessel to start.
            for (var i = 0; i < $scope.fleetData.length; i++) {
                $scope.fleetData[i].active = $scope.fleetData[i].imo == $scope.selectedVessel.imo;
            }

            $scope.drawMap($scope.fleetData);
        }
    };

    $rootScope.$on('fleet-data-received', function(data) {
        $scope.fleetData = FleetService.getFleetData();
        if ($scope.baselineAnalysis)
            $scope.drawFleetData();
    });

    $rootScope.$on('baseline-analysis-received', function(data) {
        var baselineAnalysis = FleetService.getBaselineAnalysisData();
        var operationToOrder = {
            Sea: 0,
            Port: 1,
            Anchor: 2,
            Arrival: 3,
            Departure: 4,
            Maneuvering: 5
        };
        for (var i = 0; i < baselineAnalysis.length; i++) {
            baselineAnalysis[i]['operationOrder'] = operationToOrder[baselineAnalysis[i]['operation']];
        }

        $scope.baselineAnalysis = baselineAnalysis;

        if ($scope.fleetData)
            $scope.drawFleetData();
    });


    $scope.$on("$destroy", function() {
        $rootScope.windyInitialized = false;
    });

    function oppositeSigns(valueOne, valueTwo) {
        return Math.sign(valueOne) !== Math.sign(valueTwo)
    }

    $scope.drawMap = function(fleetData) {
        if (!$rootScope.windyInitialized) {
            $rootScope.windyInitialized = true;
            var selectedVessel = $scope.fleetData.find(function(v) { return v.imo == $scope.selectedVessel.imo; });
            if (!window.copy_of_W) {
                window.copy_of_W = Object.assign({}, window.W);
            }
            if (window.W.windyBoot) {
                window.W = Object.assign({}, window.copy_of_W);
            }
            // @ts-ignore
            windyInit({ key: 'qeL5T3oshpayPIvgAnxMy1zWXs3g7f0i' }, function(windyAPI) {
                $rootScope.windyAPI = windyAPI;
                var map = windyAPI.map;

                map.addControl(new L.Control.Fullscreen({ position: 'topright'}));
                map.on('click', function(ev) {
                    $(document.getElementById('position-information')).css('visibility', 'hidden');
                });
                map.options.minZoom = 2;
                map.setMinZoom(2);
                angular.forEach(fleetData, function(vessel) {
                    vessel.map = map;
                    var pos = vessel.positions[0];
                    if (pos) {
                        var vesselBaselineAnalysis = $scope.baselineAnalysis.find(function(v) { return v.vessel_name == vessel.name; });
                        var numberOfBadKPIs = (vesselBaselineAnalysis && vesselBaselineAnalysis.bad_kpis) || 0;
                        var color : string = $scope.getKPISummaryColor(numberOfBadKPIs);
                        var vesselMarkerLayer = L.circleMarker([pos.lat, pos.lon], { radius: 6, stroke: true, color: 'black', fill: true, fillColor: color, fillOpacity: 1, weight: 2, zIndexOffset: 9999 });
                        vessel.markerLayer = vesselMarkerLayer;
                        vessel.allLayers = L.featureGroup([vesselMarkerLayer]);
                        vessel.allLayers.on('click', function(ev) {
                            L.DomEvent.stopPropagation(ev);
                            $rootScope.selectedVoyage = vessel.voyage;
                            $rootScope.selectedVesselName = vessel.name;
                            $(document.getElementById('position-information')).css('visibility', 'visible');
                            $scope.$apply();
                        });
                        vessel.visible = false;

                        vesselMarkerLayer.bindTooltip(vessel.name, { permanent: true, offset: L.point(-5, 0), direction: 'left' });

                        vessel.allLayers.on('mouseover', function() {
                            vesselMarkerLayer.setRadius(8);
                        });
                        vessel.allLayers.on('mouseout', function() {
                            vesselMarkerLayer.setRadius(6);
                        });

                        vessel.markerLayer.on('click', function(ev) {
                            L.DomEvent.stopPropagation(ev);

                            if (vessel.coords) {
                                $scope.togglePathLayer(vessel);
                            } else {
                                $http({
                                    url: analyticsUrl + 'vesselPosition',
                                    method: 'GET',
                                    params: { imo: vessel.imo }
                                }).then(function(response) {
                                    var data = response.data;
                                    var positions = data.data[0].positions.slice(0, -1);
                                    vessel.coords = positions.map(function(p) { return [p.lat, p.lon]; });
                                    
                                    let splitCoordsAtAntiMeridian = [];
                                    let segmentIndex = 0;
                                    let prevCoords = [];
                                    for(let i = 0; i < vessel.coords.length; i++) {
                                        let segmentList = splitCoordsAtAntiMeridian[segmentIndex] || [];
                                        let currrentCoords = vessel.coords[i];
                                        if (prevCoords && prevCoords.length > 1 && oppositeSigns(prevCoords[1], currrentCoords[1])) {
                                            
                                            // draw overlap lines connecting segments
                                            segmentIndex++;
                                            splitCoordsAtAntiMeridian[segmentIndex] = [prevCoords, currrentCoords];
                                            
                                            segmentList = [];
                                            segmentList.push([currrentCoords[0], currrentCoords[1]]);
                                            segmentIndex++;
                                            splitCoordsAtAntiMeridian[segmentIndex] = segmentList;

                                        } else {
                                            segmentList.push([currrentCoords[0], currrentCoords[1]]);
                                            splitCoordsAtAntiMeridian[segmentIndex] = segmentList;
                                        }
                                        prevCoords = vessel.coords[i];
                                    }
                                    var vesselPathLayer = L.polyline(splitCoordsAtAntiMeridian, {
                                        color: 'black',
                                        weight: 4,
                                    });
                                    var pathFeatureGroup = L.featureGroup([vesselPathLayer]);
                                    vessel.pathFeatureGroup = pathFeatureGroup;
                                    pathFeatureGroup.on('click', function(ev) {
                                        L.DomEvent.stopPropagation(ev);
                                    });

                                    var coordCircleGroup = L.featureGroup();
                                    pathFeatureGroup.addLayer(coordCircleGroup);
                                    angular.forEach(splitCoordsAtAntiMeridian, function(coords) {
                                        angular.forEach(coords, function(coord) {
                                            var coordCircle = L.circleMarker([coord[0], coord[1]], { radius: 3, stroke: true, color: 'black', fill: true, fillColor: '#a4a4a4', fillOpacity: 1, weight: 1 });
                                            coordCircleGroup.addLayer(coordCircle);
                                        });
                                    });

                                    vessel.allLayers.addLayer(pathFeatureGroup);
                                    vessel.pathVisible = true;

                                    vessel.allLayers.on('mouseover', function() {
                                        vesselPathLayer.setStyle({ weight: 4 });
                                    });
                                    vessel.allLayers.on('mouseout', function() {
                                        vesselPathLayer.setStyle({ weight: 2 });
                                    });

                                    vessel.markerLayer.bringToFront();
                                }, function(response) {
                                    var data = response.data;
                                    console.log('Error pulling vessel positions.', data);
                                    notify({message: 'No data for ' + vessel.name, duration: 2000, classes: ['warning-notification']});
                                });
                            }
                        });
                    }
                });

                if (selectedVessel) {
                    $scope.toggleAllLayers(selectedVessel);
                    var initialPosition = selectedVessel.positions[0];
                    if (initialPosition) {
                        map.flyTo([initialPosition.lat, initialPosition.lon]);
                    }
                }
            });
        }
    };

    $scope.togglePathLayer = function(vessel) {
        if (vessel.pathVisible) {
            vessel.allLayers.removeLayer(vessel.pathFeatureGroup);
            vessel.pathVisible = false;
        } else {
            vessel.allLayers.addLayer(vessel.pathFeatureGroup);
            vessel.pathVisible = true;
        }
        vessel.markerLayer.bringToFront();
    };

    $scope.toggleAllLayers = function(vessel) {
        if (vessel.visible) {
            vessel.allLayers.removeFrom(vessel.map);
            vessel.visible = false;
        } else {
            vessel.allLayers.addTo(vessel.map);
            vessel.visible = true;
        }
    };

    $scope.showAllLayers = function(vessel) {
        if (!vessel.visible) {
            vessel.allLayers.addTo(vessel.map);
            vessel.visible = true;
        }
    };

    $scope.hideAllLayers = function(vessel) {
        if (vessel.visible) {
            vessel.allLayers.removeFrom(vessel.map);
            vessel.visible = false;
        }
    };

    $scope.getKPISummaryColorStyle = function(numberOfBadKPIs) {
        var green = themePalette.colors.GREEN;
        var yellow = themePalette.colors.YELLOW;
        var red = themePalette.colors.RED;
        let color: string = red;
        if (numberOfBadKPIs <= 1) {
            color = green;
        } else if (numberOfBadKPIs <= 2) {
            color = yellow;
        } else {
            color = red;
        }
        return {'color': color};
    };

    $scope.getKPISummaryColor = function(numberOfBadKPIs) {
        var green = themePalette.colors.GREEN;
        var yellow = themePalette.colors.YELLOW;
        var red = themePalette.colors.RED;

        if (numberOfBadKPIs <= 1) {
            return green;
        } else if (numberOfBadKPIs <= 2) {
            return yellow;
        } else {
            return red;
        }
    };

    // get fleet tracker baseline analysis data
    var metricsWithDeviation = {
        vessel_class: false,
        vessel_name: false,
        operation: false,
        bad_kpi: false,
        sfoc: true,
        main_engine_1_power: true,
        total_me_consumption: true,
        total_me_consumption_model: true,
        total_ae_consumption: true,
        total_boiler_consumption: true,
    };

    $scope.sortFieldType = 'd';
    $scope.metricShown = 'indicators';
    $scope.getBaselineDeviationColor = function(deviation, lessIsGood) {
        var green = themePalette.colors.GREEN;
        var yellow = themePalette.colors.YELLOW;
        var red = themePalette.colors.RED;
        let color: string = red;

        if (lessIsGood && deviation < 0) {
            color = green;
        } else if (deviation == undefined || (deviation >= -10 && deviation <= 10)) {
            color = green;
        } else if (deviation >= -15 && deviation <= 15) {
            color = yellow;
        } else {
            color = red;
        }

        return {
            'color': color
        };
    };

    $scope.sortBy = function(sortField) {
        $scope.reverse = ($scope.sortField === sortField) ? !$scope.reverse : false;
        $scope.sortField = sortField;
        if (metricsWithDeviation[sortField]) {
            $scope.sortFieldWithKey = sortField + '.' + $scope.sortFieldType;
        } else {
            $scope.sortFieldWithKey = sortField;
        }

    };

    $scope.absOrderBy= function(metric) {
        var value = metric[$scope.sortField];
        if (metricsWithDeviation[$scope.sortField]) {
            value = value[$scope.sortFieldType];
        }

        var flip = 1;
        if ($scope.reverse) {
            flip = -1;
        }

        if (typeof value == 'number') {
            return flip * Math.abs(value);
        } else {
            return value;
        }
    };

    $scope.sortBy('bad_kpis');
    $scope.reverse = true;

    // opens the selected performance report from the view reports view
    $scope.openReport = function(entry) {
        var vesselId = entry['vessel_id']['$oid'];
        var vessel = VesselService.getVesselFromVesselList($rootScope.vessels, vesselId);

        if (vessel.id != undefined && vessel.id != $rootScope.selectedVessel.id) {
            $rootScope.dashboardReady = false;
            $rootScope.loadingModalUsed = false;
            $rootScope.initializedData = false;
            $rootScope.lastReportWasSeaReport = false;
            $rootScope.lastReportWasPortReport = false;

            ReportService.clearService();

            $rootScope.selectedVessel = vessel;
            ReportService.setVesselReportNumber(vessel.reportNumber);
            ReportService.setMEReportNumber(vessel.meReportNumber);
            ReportService.setAEReportNumber(vessel.aeReportNumber);
            $rootScope.hasIncompleteVesselReport = vessel.hasIncompleteVesselReport;
        }

        var reportId = entry.report_id;
        ReportsApiService.getReport(vesselId, reportId).then(function(response) {
            var report = response.data;
            report = ReportService.applyDatesOnReport(report, report);
            report.id = report["_id"]["$oid"];
            var targetState = ReportsApiService.getReportView(report);
            $state.go(targetState, { report: report, viewReport: true });
        });
    };

    $scope.getBFColor = function(bf) {
        if (bf > 6) {
            return {
                'color': themePalette.colors.RED
            };
        } else {
            return {};
        }
    };

    $(document).mouseup(function(e) {
        var container = $(".fleet-tracker-dropdown");

        // if the target of the click isn't the container nor a descendant of the container
        // @ts-ignore
        if (!container.is(e.target) && container.has(e.target).length === 0)
        {
            closeDropdowns();
        }
    });

    $scope.$on('$stateChangeStart', function(e) {
        $(document).unbind('mouseup');
    });

    var closeDropdowns = function() {
        var closing = $('.dropdown-menu').parent().hasClass('open');
        $('.dropdown-menu').parent().removeClass('open');
        if (closing) {

        }
    };

    $scope.options = { selectAll: false } ;

    $scope.toggleVesselAll = function(active) {
        $scope.options.selectAll = !$scope.options.selectAll;
        var vessels = $scope.fleetData;
        for (var i = 0; i < vessels.length; i++) {
            vessels[i].active = active;
            if (active) {
                $scope.showAllLayers(vessels[i]);
            } else {
                $scope.hideAllLayers(vessels[i]);
            }
        }
    };

    $scope.toggleVessel = function(vessel) {
        vessel.active = !vessel.active;
        $scope.toggleAllLayers(vessel);
    };

    FleetService.getFleet($rootScope.username).then(function(response) {
        FleetService.setFleetData(response.data.data);
        $rootScope.$broadcast('fleet-data-received');
    });

    FleetService.getBaselineAnalysis($rootScope.username).then(function(response) {
        FleetService.setBaselineAnalysisData(response.data.data);
        $rootScope.$broadcast('baseline-analysis-received');
    });

}]);
