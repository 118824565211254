import { routerApp, baseUrl } from '../../app.module';

routerApp.controller('mrvReportingController', ['$rootScope', '$scope', '$http', 'PageState', 
function($rootScope, $scope, $http, PageState) {

    $rootScope.selectedMenu = 'mrv';

    $scope.pageState = new PageState();
    $scope.pageState.setLoadingData();

    // get mrv data
    $http({
        url: baseUrl + 'mrv/' + $rootScope.selectedVessel.id + "/mrv",
        method: "GET"
    }).then(function(response) {
        var data = response.data;
        $scope.mrvData = data.data.data;
        if (!$scope.mrvData) {
            $scope.pageState.setNoData();
        } else {
            $scope.pageState.setDataLoaded();
        }
        $scope.year = data.data.year;
        if ($rootScope.username == 'verifavia') {
            $scope.year = 2018;
        }
        if ($scope.mrvData.fuel_types == undefined) {
            $scope.mrvData.fuel_types = {'fuel_types_used' : ['hshfo','lshfo','ulsfo','lsmdo','hsmgo','lsmgo']}
        }
        $scope.downloadLink = data.data.dropbox_url;
    }, function() {
        $scope.pageState.setNoData();
    });

}]);
