import { routerApp, analyticsUrl } from '../../app.module';

var tableauVisual = null;
routerApp.controller('tableauController', ['$rootScope', '$scope',  '$http', 'PageState', 
  function($rootScope, $scope, $http, PageState) {

    $rootScope.selectedMenu = 'tableau';

    // initialize page state to loading data from server
    $scope.pageState = new PageState();
    $scope.pageState.setLoadingData();

    function resizeTableauEmbed() {
      if (!tableauVisual) {
        return;
      }
      var width = $('.content').width();
      var height = $('.content-wrapper').height() - 50;
      var sheet = tableauVisual.getWorkbook().getActiveSheet();
      sheet.changeSizeAsync(
        {
          "behavior": "EXACTLY",
          "maxSize": {
            "height": height,
            "width": width
          }
        }
      ).then(tableauVisual.setFrameSize(width, height));
    }

    window.addEventListener('resize', resizeTableauEmbed);

    $http({
        url: analyticsUrl + 'tableau_ticket',
        method: "GET",
        params: {
          user_id: $rootScope.user.id
        }
    }).then(function(response) {
        $scope.tableauTicket = response.data.data.tableau_ticket;
        $scope.view = response.data.data.view;

        console.log(response.data);

        $scope.pageState.setDataLoaded();
        var viewUrl = $rootScope.features.tableau.view_link;
        viewUrl = 'https://tableau.navtor.com/trusted/' + $scope.tableauTicket + '/views' + $scope.view;

        var url = viewUrl;
        var options = {
           hideTabs: true,
           hideToolbar: true,
           width: '100%',
           height: '80vh',
           onFirstInteractive: function() {
             // The viz is now ready and can be safely used.
             resizeTableauEmbed();
           }
        };
        var placeholderDiv = document.getElementById('tableauPlaceholderX');
        // @ts-ignore
        var viz = new tableau.Viz(placeholderDiv, url, options);
        tableauVisual = viz;
    });
}]);
