import { TankStock } from "../models/TankStock";

interface FuelSpec {
    fuel_class: string
    fuel_name: string
    iso_grade: string
    density_15_max: number
    density_20_max: number
    sulphur_min: number
    sulphur_max: number
    viscosity_50_max: number
    viscosity_40_max: number
    viscosity_40_min: number
    viscosity_20_max: number
    lcv: number
    e_value: number
    wtt_emission_factor: number
    cf_co2: number
    cf_ch4: number
    cf_n2o: number
    c_slip: number
}

const fuelSpecs: FuelSpec[] = [
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RME 180",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 180.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RMG180",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 180.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RMG 380",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 380.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RMG 500",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 500.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RMG 700",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 700.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RMK 380",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 380.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RMK 500",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 500.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "hfo",
    iso_grade: "RMK 700",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: 0.5,
    sulphur_max: null,
    viscosity_50_max: 700.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "lfo",
    iso_grade: "RMA 10",
    density_15_max: 920.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: 10.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "lfo",
    iso_grade: "RMB 30",
    density_15_max: 960.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: 30.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "lfo",
    iso_grade: "RMD 80",
    density_15_max: 975.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: 80.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mgo",
    iso_grade: "DMX",
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 5.5,
    viscosity_40_min: 1.4,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mgo",
    iso_grade: "DMA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mgo",
    iso_grade: "DFA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mgo",
    iso_grade: "DMZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mgo",
    iso_grade: "DFZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mgo",
    iso_grade: "DMB",
    density_15_max: 900.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 11.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mdo",
    iso_grade: "DMX",
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 5.5,
    viscosity_40_min: 1.4,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mdo",
    iso_grade: "DMA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mdo",
    iso_grade: "DFA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mdo",
    iso_grade: "DMZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mdo",
    iso_grade: "DFZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "mdo",
    iso_grade: "DMB",
    density_15_max: 900.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 11.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "biofuel",
    fuel_name: "b10lfo",
    iso_grade: null,
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: null,
    viscosity_50_max: null,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: null,
    e_value: null,
    wtt_emission_factor: null,
    cf_co2: null,
    cf_ch4: null,
    cf_n2o: null,
    c_slip: null,
  },
  {
    fuel_class: "biofuel",
    fuel_name: "b10mgo",
    iso_grade: null,
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: null,
    viscosity_50_max: null,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: null,
    e_value: null,
    wtt_emission_factor: null,
    cf_co2: null,
    cf_ch4: null,
    cf_n2o: null,
    c_slip: null,
  },
  {
    fuel_class: "biofuel",
    fuel_name: "biolfo",
    iso_grade: null,
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: null,
    viscosity_50_max: null,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: null,
    e_value: null,
    wtt_emission_factor: null,
    cf_co2: null,
    cf_ch4: null,
    cf_n2o: null,
    c_slip: null,
  },
  {
    fuel_class: "biofuel",
    fuel_name: "biomgo",
    iso_grade: null,
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: null,
    viscosity_50_max: null,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: null,
    e_value: null,
    wtt_emission_factor: null,
    cf_co2: null,
    cf_ch4: null,
    cf_n2o: null,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RME 180",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 180.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMG180",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 180.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMG 380",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 380.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMG 500",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 500.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMG 700",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 700.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMK 380",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 380.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMK 500",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 500.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMK 700",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 700.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMA 10",
    density_15_max: 920.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 10.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMB 30",
    density_15_max: 960.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 30.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "RMD 80",
    density_15_max: 975.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 80.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "DMX",
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 5.5,
    viscosity_40_min: 1.4,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "DMA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "DFA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "DMZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "DFZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsfo2020",
    iso_grade: "DMB",
    density_15_max: 900.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 11.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulslfo2020",
    iso_grade: "RMA 10",
    density_15_max: 920.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 10.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulslfo2020",
    iso_grade: "RMB 30",
    density_15_max: 960.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 30.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulslfo2020",
    iso_grade: "RMD 80",
    density_15_max: 975.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: 80.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmdo2020",
    iso_grade: "DMX",
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 5.5,
    viscosity_40_min: 1.4,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmdo2020",
    iso_grade: "DMA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmdo2020",
    iso_grade: "DFA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmdo2020",
    iso_grade: "DMZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmdo2020",
    iso_grade: "DFZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmdo2020",
    iso_grade: "DMB",
    density_15_max: 900.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 11.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmgo2020",
    iso_grade: "DMX",
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 5.5,
    viscosity_40_min: 1.4,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmgo2020",
    iso_grade: "DMA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmgo2020",
    iso_grade: "DFA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmgo2020",
    iso_grade: "DMZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmgo2020",
    iso_grade: "DFZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "ulsmgo2020",
    iso_grade: "DMB",
    density_15_max: 900.0,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: 0.1,
    viscosity_50_max: null,
    viscosity_40_max: 11.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RME 180",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 180.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMG180",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 180.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMG 380",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 380.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMG 500",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 500.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMG 700",
    density_15_max: 991.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 700.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMK 380",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 380.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMK 500",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 500.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMK 700",
    density_15_max: 1010.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 700.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 40500.0,
    e_value: null,
    wtt_emission_factor: 13.5,
    cf_co2: 3.114,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMA 10",
    density_15_max: 920.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 10.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMB 30",
    density_15_max: 960.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 30.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "RMD 80",
    density_15_max: 975.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 80.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "DMX",
    density_15_max: null,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 5.5,
    viscosity_40_min: 1.4,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "DMA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "DFA",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "DMZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "DFZ",
    density_15_max: 890.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 6.0,
    viscosity_40_min: 3.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlsfo2020",
    iso_grade: "DMB",
    density_15_max: 900.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: null,
    viscosity_40_max: 11.0,
    viscosity_40_min: 2.0,
    viscosity_20_max: null,
    lcv: 42700.0,
    e_value: null,
    wtt_emission_factor: 14.4,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlslfo2020",
    iso_grade: "RMA 10",
    density_15_max: 920.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 10.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlslfo2020",
    iso_grade: "RMB 30",
    density_15_max: 960.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 30.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: "fossil",
    fuel_name: "vlslfo2020",
    iso_grade: "RMD 80",
    density_15_max: 975.0,
    density_20_max: null,
    sulphur_min: 0.1,
    sulphur_max: 0.5,
    viscosity_50_max: 80.0,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: 41000.0,
    e_value: null,
    wtt_emission_factor: 13.2,
    cf_co2: 3.151,
    cf_ch4: 5e-5,
    cf_n2o: 0.00018,
    c_slip: null,
  },
  {
    fuel_class: null,
    fuel_name: "other",
    iso_grade: null,
    density_15_max: null,
    density_20_max: null,
    sulphur_min: null,
    sulphur_max: null,
    viscosity_50_max: null,
    viscosity_40_max: null,
    viscosity_40_min: null,
    viscosity_20_max: null,
    lcv: null,
    e_value: null,
    wtt_emission_factor: null,
    cf_co2: null,
    cf_ch4: null,
    cf_n2o: null,
    c_slip: null,
  },
];

const fuelTypesToFuelClasses = {
  "hfo": "fossil",
  "lfo": "fossil",
  "mgo": "fossil",
  "mdo": "fossil",
  "b10lfo": "biofuel",
  "b10mgo": "biofuel",
  "biolfo": "biofuel",
  "biomgo": "biofuel",
  "ulsfo2020": "fossil",
  "ulslfo2020": "fossil",
  "ulsmdo2020": "fossil",
  "ulsmgo2020": "fossil",
  "vlsfo2020": "fossil",
  "vlslfo2020": "fossil",
  "lpgp": "fossil",
  "lpgb": "fossil",
  "lng": "fossil",
  "methanol": "fossil",
  "ethanol": "biofuel",
  "other": undefined,
};

const isFuelTypeMatch = (tankStock: Partial<TankStock>, fuelSpec: FuelSpec, fuelClass: string = 'fossil') => {
    if (fuelSpec.fuel_class !== fuelClass) return false;

    let densityMatches = true;
    let suphurMinMatches = !fuelSpec.sulphur_min;
    let suphurMaxMatches = !fuelSpec.sulphur_max;
    let viscosity50MaxMatches = true;
    let viscosity40MaxMatches = true;
    let viscosity40MinMatches = true;
    let viscosity20MaxMatches = true;

    if (fuelSpec.density_15_max) densityMatches = tankStock.density <= fuelSpec.density_15_max;
    if (fuelSpec.density_20_max) densityMatches = tankStock.density <= fuelSpec.density_20_max;
    if (fuelSpec.sulphur_min) suphurMinMatches = tankStock.sulphur_after_bunkering > fuelSpec.sulphur_min;
    if (fuelSpec.sulphur_max) suphurMaxMatches = tankStock.sulphur_after_bunkering <= fuelSpec.sulphur_max;
    if (fuelSpec.viscosity_50_max) viscosity50MaxMatches = tankStock.viscosity <= fuelSpec.viscosity_50_max;
    if (fuelSpec.viscosity_40_max) viscosity40MaxMatches = tankStock.viscosity <= fuelSpec.viscosity_40_max;
    if (fuelSpec.viscosity_40_min) viscosity40MinMatches = tankStock.viscosity > fuelSpec.viscosity_40_min;
    if (fuelSpec.viscosity_20_max) viscosity20MaxMatches = tankStock.viscosity <= fuelSpec.viscosity_20_max;

    return densityMatches && suphurMinMatches && suphurMaxMatches && viscosity50MaxMatches && viscosity40MaxMatches && viscosity40MinMatches && viscosity20MaxMatches;
}

const fuelSpecFromTankStock = (tankStock: Partial<TankStock>, fuelClass: string = 'fossil'): FuelSpec | null => {
    const matchingFuelTypes = fuelSpecs.filter(fuelSpec => isFuelTypeMatch(tankStock, fuelSpec, fuelClass));
    return matchingFuelTypes[0] || null;
};

export { fuelSpecFromTankStock, fuelTypesToFuelClasses };
