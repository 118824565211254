import { routerApp } from '../app.module';
import moment from 'moment';

routerApp.factory('TimezoneService', function() {
    var allTimezones = {
        'TVA/GMT-12.5': {
            'offset': -12.5,
            'name': '(UTC-12:30)'
        },
        'TVA/GMT-12': {
            'offset': -12,
            'name': '(UTC-12:00)'
        },
        'TVA/GMT-11': {
            'offset': -11,
            'name': '(UTC-11:00)',
        },
        'TVA/GMT-10': {
            'offset': -10,
            'name': '(UTC-10:00)',
        },
        'TVA/GMT-9.5': {
            'offset': -9.5,
            'name': '(UTC-09:30)'
        },
        'TVA/GMT-9': {
            'offset': -9,
            'name': '(UTC-09:00)',
        },
        'TVA/GMT-8.5': {
            'offset': -8.5,
            'name': '(UTC-08:30)'
        },
        'TVA/GMT-8': {
            'offset': -8,
            'name': '(UTC-08:00)',
        },
        'TVA/GMT-7.5': {
            'offset': -7.5,
            'name': '(UTC-07:30)'
        },
        'TVA/GMT-7': {
            'offset': -7,
            'name': '(UTC-07:00)',
        },
        'TVA/GMT-6.5': {
            'offset': -6.5,
            'name': '(UTC-06:30)'
        },
        'TVA/GMT-6': {
            'offset': -6,
            'name': '(UTC-06:00)',
        },
        'TVA/GMT-5.5': {
            'offset': -5.5,
            'name': '(UTC-05:30)'
        },
        'TVA/GMT-5': {
            'offset': -5,
            'name': '(UTC-05:00)',
        },
        'TVA/GMT-4.5': {
            'offset': -4.5,
            'name': '(UTC-04:30)'
        },
        'TVA/GMT-4': {
            'offset': -4,
            'name': '(UTC-04:00)',
        },
        'TVA/GMT-3.5': {
            'offset': -3.5,
            'name': '(UTC-03:30)'
        },
        'TVA/GMT-3': {
            'offset': -3,
            'name': '(UTC-03:00)',
        },
        'TVA/GMT-2.5': {
            'offset': -2.5,
            'name': '(UTC-02:30)'
        },
        'TVA/GMT-2': {
            'offset': -2,
            'name': '(UTC-02:00)',
        },
        'TVA/GMT-1.5': {
            'offset': -1.5,
            'name': '(UTC-01:30)'
        },
        'TVA/GMT-1': {
            'offset': -1,
            'name': '(UTC-01:00)',
        },
        'TVA/GMT-0.5': {
            'offset': -0.5,
            'name': '(UTC-00:30)'
        },
        'UTC': {
            'offset': +0,
            'name': '(UTC+00:00)',
        },
        'TVA/GMT+0.5': {
            'offset': +0.5,
            'name': '(UTC+00:30)'
        },
        'TVA/GMT+1': {
            'offset': +1,
            'name': '(UTC+01:00)',
        },
        'TVA/GMT+1.5': {
            'offset': +1.5,
            'name': '(UTC+01:30)'
        },
        'TVA/GMT+2': {
            'offset': +2,
            'name': '(UTC+02:00)',
        },
        'TVA/GMT+2.5': {
            'offset': +2.5,
            'name': '(UTC+02:30)'
        },
        'TVA/GMT+3': {
            'offset': +3,
            'name': '(UTC+03:00)',
        },
        'TVA/GMT+3.5': {
            'offset': +3.5,
            'name': '(UTC+03:30)'
        },
        'TVA/GMT+4': {
            'offset': +4,
            'name': '(UTC+04:00)',
        },
        'TVA/GMT+4.5': {
            'offset': +4.5,
            'name': '(UTC+04:30)'
        },
        'TVA/GMT+5': {
            'offset': +5,
            'name': '(UTC+05:00)',
        },
        'TVA/GMT+5.5': {
            'offset': +5.5,
            'name': '(UTC+05:30)'
        },
        'TVA/GMT+5.75': {
            'offset': +5.75,
            'name': '(UTC+05:45)',
        },
        'TVA/GMT+6': {
            'offset': +6,
            'name': '(UTC+06:00)',
        },
        'TVA/GMT+6.5': {
            'offset': +6.5,
            'name': '(UTC+06:30)'
        },
        'TVA/GMT+7': {
            'offset': +7,
            'name': '(UTC+07:00)',
        },
        'TVA/GMT+7.5': {
            'offset': +7.5,
            'name': '(UTC+07:30)'
        },
        'TVA/GMT+8': {
            'offset': +8,
            'name': '(UTC+08:00)',
        },
        'TVA/GMT+8.5': {
            'offset': +8.5,
            'name': '(UTC+08:30)'
        },
        'TVA/GMT+9': {
            'offset': +9,
            'name': '(UTC+09:00)',
        },
        'TVA/GMT+9.5': {
            'offset': +9.5,
            'name': '(UTC+09:30)'
        },
        'TVA/GMT+10': {
            'offset': +10,
            'name': '(UTC+10:00)',
        },
        'TVA/GMT+10.5': {
            'offset': +10.5,
            'name': '(UTC+10:30)'
        },
        'TVA/GMT+11': {
            'offset': +11,
            'name': '(UTC+11:00)',
        },
        'TVA/GMT+11.5': {
            'offset': +11.5,
            'name': '(UTC+11:30)'
        },
        'TVA/GMT+12': {
            'offset': +12,
            'name': '(UTC+12:00)',
        },
        'TVA/GMT+12.5': {
            'offset': +12.5,
            'name': '(UTC+12:30)'
        },
        'TVA/GMT+12.75': {
            'offset': +12.75,
            'name': '(UTC+12:45)'
        },
        'TVA/GMT+13': {
            'offset': +13,
            'name': '(UTC+13:00)',
        },
        'TVA/GMT+13.5': {
            'offset': +13.5,
            'name': '(UTC+13:30)'
        },
        'TVA/GMT+13.75': {
            'offset': +13.75,
            'name': '(UTC+13:45)'
        },
        'TVA/GMT+14': {
            'offset': +14,
            'name': '(UTC+14:00)'
        },
    };

    return {
        getTimezones: function() {
            return allTimezones;
        },
        dtWithTz: function(datetime, timezone) {
            if (!timezone) return moment(datetime, 'DD/MM/YYYY HH:mm');

            // `datetime` should be a moment object or a string in format 'DD/MM/YYYY HH:mm'
            var tzName = allTimezones[timezone].name;
            var offsetString = tzName.replace(/\(UTC(.*?)\).*/, '$1');
            var momentObj = null;
            if (datetime && datetime._isAMomentObject) {
                momentObj = moment(datetime.format('DD/MM/YYYY HH:mm') + ' ' + offsetString, 'DD/MM/YYYY HH:mm ZZ');
            } else {
                momentObj = moment(datetime + ' ' + offsetString, 'DD/MM/YYYY HH:mm ZZ');
            }

            // @ts-ignore
            var tzm = momentObj._tzm;
            if (tzm == undefined) {
                // in case moment.js wasn't able to find this timezone
                // get the offset directly from the timezones list
                tzm = allTimezones[timezone].offset * 60;
            }

            return momentObj.utcOffset(tzm);
        },
        dtFromISOString: function(datetimeISOString) {
            if (!datetimeISOString)
                return null;
            return moment.utc(datetimeISOString);
        }
    }

});

