import { routerApp, baseUrl, hashCode, enableHybrid, enableOnPremise } from '../app.module';
import hybridService from './hybrid/index';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

const loginUrl = baseUrl + "login";

// Structure of data returned from API server when logging in.
type LoginResponseData = {
    token: string,
    client: string,
    secondaryClients: Array<string>,
    company: string,
    name: string,
    email: string,
    username: string,
    role: string,
    classification: string,
    vessels: Array<any>,
    selectedVessel: any,
    vesselModels: any,
    features: any,
    id: string,
    formConfiguration: any
};

// Returned by checkLogin and used in login.controller.
type LoginResponse = {
    data?: LoginResponseData,
    status: number,
    success: boolean
};

// Main interface for this service.
type IAuthenticationService = {
    checkLogin: ICheckLogin;
};
// Type signature for checkLogin function.
type ICheckLogin = (username: string, password: string, remember: boolean) => Promise<LoginResponse>;

routerApp.factory('AuthenticationService', ['$http', 'OfflineDatabase', function($http, OfflineDatabase) : IAuthenticationService {
    return {
        checkLogin: (username, password, remember) => {
            let requesterFunction = enableHybrid ? hybridService.fetchWithTimeout : fetch;
            let responsePromise = requesterFunction(loginUrl, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    username: username,
                    password: password,
                    remember: remember,
                }),
            });

            return responsePromise.then(function(response) {
                if (200 <= response.status && response.status < 300) {
                    // Request is a success, try reading the response's json stream:
                    return response.json().then((data) => {
                        if (enableHybrid || enableOnPremise) {
                            // Update hybrid service if necessary.
                            hybridService.serverUsersUpdate({
                                username: username,
                                password_hash: hashCode(password),
                                response: data
                            });
                        }
                        // Then return info to login controller.
                        let loginResponse: LoginResponse = {
                            data: data,
                            status: response.status,
                            success: true
                        };
                        return loginResponse;
                    }).catch(reason => reason)
                } else {
                    // Login failed but got a response from the server, return details to login controller.
                    let loginResponse: LoginResponse = { data: undefined, status: response.status, success: false };
                    return loginResponse;
                }
            }).catch(error => {
                // Request failed, try to get details from cache.
                return hybridService.serverUsersQuery(username).then(function(users) {
                    if (users != undefined && users.length > 0 && users[0].password_hash == hashCode(password)) {
                        // Cache hit, return info to login controller.
                        OfflineDatabase.setOnlineConnection(false);
                        let loginResponse: LoginResponse = {
                            data: users[0].response,
                            status: 200,
                            success: true
                        };
                        return loginResponse;
                    } else {
                        if (error.xhrStatus == 'timeout') {
                            throw 0;
                        }
                        // Cache miss, unauthorized.
                        throw 400;
                    }
                }, function(err) {
                    throw Error();
                });
            });
        }
    }
}]);

export { IAuthenticationService, LoginResponseData, LoginResponse, ICheckLogin }
