import { routerApp, baseUrl, enableOnPremise } from '../app.module';
import { httpRequestGenerator } from './utilities';

routerApp.factory('EngineReportService', ['$rootScope', '$http', function($rootScope, $http) {
    var requestFunc = httpRequestGenerator(enableOnPremise, $http);
    return {
        getLastIncompleteEngineReport: function() {
            var url = baseUrl + $rootScope.selectedVessel.id + "/lastIncompleteEngineReport";
            return requestFunc({url: url, method: 'GET'});
        },
        submitMEReport: function(report, closeReport, config) {
            var url = baseUrl + $rootScope.selectedVessel.id + "/meReports";
            if (closeReport) {
                url += "?closeReport=true";
            }
            return requestFunc({url: url, method: 'POST', data: report, config: config});
        },
        submitAEReport: function(report, closeReport, config) {
            var url = baseUrl + $rootScope.selectedVessel.id + "/aeReports";
            if (closeReport) {
                url += "?closeReport=true";
            }
            return requestFunc({url: url, method: 'POST', data: report, config: config});
        },
        getReports: function() {
            var url = baseUrl + $rootScope.selectedVessel.id + "/engineReports";
            return requestFunc({url: url, method: 'GET'});
        },
        getMEReport: function(reportId) {
            var url = baseUrl +  $rootScope.selectedVessel.id + "/meReports?reportId=" + reportId;
            return requestFunc({url: url, method: 'GET'});
        },
        getAEReport: function(reportId) {
            var url = baseUrl +  $rootScope.selectedVessel.id + "/aeReports?reportId=" + reportId;
            return requestFunc({url: url, method: 'GET'});
        },
        extractData: function(vesselIds, reportFilter) {
            var url = baseUrl + "extractEngineReports";
            return requestFunc({
                url: url, 
                method: 'GET',
                params: {
                    vessel_ids: vesselIds.join(','),
                    report_filter: reportFilter
                }
            });
        }
    }
}]);
