import { routerApp, baseUrl } from '../app.module';
import angular from 'angular';

routerApp.factory('ManagementClientsService', ['$http', '$log', function($http: angular.IHttpService, $log: angular.ILogService) {
    return {
        getClients: () => {
            return $http.get(`${baseUrl}v1/management/client/`);
        },
        getClient: (clientId: string) => {
            return $http.get(`${baseUrl}v1/management/client/${clientId}`);
        },
        createClient: (client: any) => {
            return $http.post(`${baseUrl}v1/management/client/`, client);
        },
        updateClient: (clientId: string, client: any) => {
            return $http.put(`${baseUrl}v1/management/client/${clientId}`, client);
        },
        disableClient: (clientId: string) => {
            return $http.delete(`${baseUrl}v1/management/client/${clientId}`);
        }
    }
}]);