export class ScriptService {
    private constructor() { }
     /**
     * Append the JS tag to the Document Body.
     * @param renderer Document, Later to be used for The Angular Renderer
     * @param src The path to the script
     * @returns the script element
     */
    public static loadJsScript(renderer: Document, filePath: string): HTMLScriptElement {
        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = filePath;
        renderer.body.appendChild(script);
        return script;
    }

    public static loadManyScripts(renderer: Document, filePaths: string[]) {
        filePaths.forEach(scriptPath => {
            this.loadJsScript(renderer, scriptPath);
        })
    }
}