import { routerApp } from '../app.module';
import { generateBunkers } from '../utils/generate-bunkers';
import { generateConsumptions } from '../utils/generate-consumptions';



routerApp.controller('anchorReportController', ['$rootScope', '$scope', '$stateParams', '$timeout', 'ReportService', 'VesselSpecificationService', function($rootScope, $scope, $stateParams, $timeout, ReportService, VesselSpecificationService) {
    $rootScope.selectedLevels = ['vessel-reports', 'create-new-report', 'anchor-report'];

    if ($stateParams.report != undefined) {2
        // if we came in here with a report parameter, we should use that report parameter
        // we should also use the 'viewReport' boolean to display or hide the needed report buttons
        $scope.report = $stateParams.report;
        $scope.viewReport = $stateParams.viewReport;
        $scope.report.activeBDNFuels = ReportService.generateActiveBDNFromReport($scope.report);
        generateConsumptions($scope.report);
        generateBunkers($scope.report);
        if ($scope.viewReport) {
            $timeout(function() {
                $scope.$broadcast('validate-form', { tabs: ['operational', 'cargo', 'power', 'consumption', 'bunker', 'stock'] });
                $('.wrapper').scrollTop($stateParams.scrollLocation);
            }, 3000);
        }
    } else {
        // this just gets the newly edited report from the service, always gets it
        // as soon as this scope is initialized, so should assume that main controller
        // puts it there so that it's ready
        $scope.report = ReportService.getVesselReport();
        $scope.report._cls = "Report.AnchorReport";
        $scope.report.bunker.took_fuel_oil = true;
        $scope.report.bunker.reporting_fuel_type = true;
        // update service with report type
        ReportService.setVesselReport($scope.report);
        $rootScope.selectedLevels = ['vessel-reports', 'edit-report'];
    }

    $scope.$on('vessel-report-received', function() {
        if ($stateParams.viewReport == undefined || $stateParams.viewReport == false) {
            $scope.report = ReportService.getVesselReport();  
        }
        $scope.report.activeBDNFuels = ReportService.generateActiveBDNFromReport($scope.report);
        generateConsumptions($scope.report);
        generateBunkers($scope.report);

        if ($scope.report.bdn_based_reporting) {
            $scope.enableBDNReporting($scope);
        }

    });

    $scope.initActiveTab = function() {
        if (['operational', 'position', 'power', 'consumptions', 'bunker', 'stock','cii'].indexOf($stateParams.activeTab) != -1) {
            return $stateParams.activeTab;
        } else if ($rootScope.selectedVessel.ciiReportingOnlyEnabled == true) {
            return 'cii';
        } else {
            return 'operational';
        }
    };

    VesselSpecificationService.getVeslinkSuppliers($scope.selectedVessel).then((res) => {
        $scope.veslinkSuppliers = res.data.data;
    });
}]);
