import { routerApp, analyticsUrl } from '../../app.module';
import moment from 'moment';

routerApp.controller('activityReportINSWController', [ '$rootScope', '$scope', '$http', '$state', 'ReportService', 'ReportsApiService', 'VesselService', 'UtilityService', 
    function($rootScope, $scope, $http, $state, ReportService, ReportsApiService, VesselService, UtilityService) {

    $rootScope.selectedMenu = 'activity-insw';

    $scope.getActivityStyle = function(activity) {
        var daysAgo = new Date();
        daysAgo.setDate(daysAgo.getDate() - 2);
        if (activity.last_report_date < daysAgo) {
            return {'color': '#f0c000'};
        } else if (activity.bf > 5) {
            return {'color': '#ac0000'}
        } else {
            return {};
        }
    }

    // get activity report
    $http({
        url: analyticsUrl + "activity_reports/" + $rootScope.user.id,
        method: "GET",
        params: { client: 'insw' }
    }).then(function(response) {
        var data = response.data;
        $scope.inswActivityReport = data.data;
    });

    $('#scroll-bar').on('scroll', function () {
        var totalWidth = $('#scroll-bar').prop("scrollWidth");
        var ratio = $(this).scrollLeft() / totalWidth;
        var topRightHeaderWidth = $('.right-content').prop("scrollWidth");
        var maxScrollLeft = topRightHeaderWidth * ratio;
        $('.right-content').scrollLeft(maxScrollLeft);
        $('.top-right-header').scrollLeft(maxScrollLeft);
    });

    // opens the selected performance report from the view reports view
    $scope.openReport = function(activity) {
        var vesselId = activity.vessel_id;
        var vessel = VesselService.getVesselFromVesselList($rootScope.vessels, vesselId);

        if (vessel.id != undefined && vessel.id != $rootScope.selectedVessel.id) {
            $rootScope.dashboardReady = false;
            $rootScope.loadingModalUsed = false;
            $rootScope.initializedData = false;
            $rootScope.lastReportWasSeaReport = false;
            $rootScope.lastReportWasPortReport = false;

            ReportService.clearService();

            $rootScope.selectedVessel = vessel;
            ReportService.setVesselReportNumber(vessel.reportNumber);
            ReportService.setMEReportNumber(vessel.meReportNumber);
            ReportService.setAEReportNumber(vessel.aeReportNumber);
            $rootScope.hasIncompleteVesselReport = vessel.hasIncompleteVesselReport;
        }

        var reportId = activity.report_id;
        ReportsApiService.getReport(vesselId, reportId).then(function(response) {
            var report = response.data;
            report = ReportService.applyDatesOnReport(report, report);
            report.id = report["_id"]["$oid"];
            var targetState = ReportsApiService.getReportView(report);
            $state.go(targetState, { report: report, viewReport: true });
        });
    };

    function downloadExtract(response) {
        var url = window.URL.createObjectURL(new Blob([response.data], {type:'application/vnd.ms-excel'}));
        var filename = "NavFleet Performance Daily Activity Report - " + moment().format('YYYY-MM-DD hh_MM_SS') + '.xlsx';
        UtilityService.downloadData(url, filename);
    }

    // get activity report
    $scope.downloadActivityReport = function() {
        var downloadUrl = analyticsUrl + "dailyActivityReportExtract/" + $rootScope.user.id + "?client=insw";
        var options = {'responseType': 'blob'};
        $http.get(downloadUrl, options).then(downloadExtract);
    }

}]);
