import { routerApp, baseUrl, appVersionCommit, appVersionDate, themePalette, enableHybrid, enableOnPremise, isQABuild } from '../app.module';
import { CACHE_NAME, redirectToModule } from '../services/utilities';
import hybridModule from '../services/hybrid/index';
// import fetch & promise polyfill together to work on older browsers
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { IAuthenticationService, LoginResponseData } from '../services/authentication.service';

routerApp.controller('loginController', ['$log', '$timeout', '$rootScope', '$scope', '$state', 'AuthenticationService', 'principal', 'ReportService', '$http', 'OfflineDatabase', 'notify', function($log, $timeout, $rootScope, $scope, $state, AuthenticationService: IAuthenticationService, principal, ReportService, $http, OfflineDatabase, notify) {

    $rootScope.app = 'login';
    $scope.localDate = new Date();
    $rootScope.vessels = null;
    $rootScope.loadingModal = false;
    $rootScope.offlineModal = false;
    $scope.loginFailed = false;

    $scope.loggingIn = false;
    $scope.loginErrorMessage = null;
    $rootScope.loadingModalUsed = false;
    if (enableHybrid) {
        // initialize offline progress
        $rootScope.offlineModalUsed = false;
        $rootScope.offlineMode = false;
        $scope.offline = true;
    }
   

    $scope.appVersionCommit = appVersionCommit;
    $scope.appVersionDate = appVersionDate;
    $scope.isQABuild = isQABuild;
    $scope.baseUrl = baseUrl;

    $scope.logIn = function(email, password, remember) {

        $scope.loggingIn = true;
        $scope.loginErrorMessage = null;
        $rootScope.loadingModalMessage = 'Logging in...';
        $log.debug("Logging in, checking user with server");
        var loginMessagePromise = $timeout(function() {
            $rootScope.loginErrorMessage = 'Login took longer than usual, please try again later.';
            $rootScope.$apply();
        }, 10000)

        if (enableHybrid || enableOnPremise) {
            hybridModule.serverUsersGet(email).then(
                function(response) { 
                    if (response) {
                    $timeout.cancel(loginMessagePromise);
                };
            });
        }

        AuthenticationService.checkLogin(email, password, remember).then(function(loginResponse) {
            $scope.loggingIn = false;
            if (loginResponse.success) {
                $scope.checkLoginSuccessHandler(loginResponse.data);
            } else {
                let code = loginResponse.status;
                $timeout.cancel(loginMessagePromise);
                $scope.loginFailed = true;
                $rootScope.loadingModal = false;

                if (!code || code <= 0) {
                    $scope.loginErrorMessage = 'Error while logging in. Please try again later, or contact NAVTOR support if the error persists.';
                } else if (400 <= code && code < 500) {
                    $scope.loginErrorMessage = 'Invalid username or password, please try again.';
                } else if (500 <= code) {
                    $scope.loginErrorMessage = 'NAVTOR server error (' + code + '), please contact NAVTOR support.';
                }
                $rootScope.$apply();
            }
        }).catch(function(error) {
            $scope.loggingIn = false
            $scope.loginFailed = true;
            $rootScope.loadingModal = false;
            $scope.loginErrorMessage = 'Unable to connect to NAVTOR servers. Please check internet connection, whitelisting, and firewall, then try again.';
            $rootScope.$apply();
        });
    };

    $scope.checkLoginSuccessHandler = function(response: LoginResponseData) {
        var data = response;
        $scope.loggingIn = false;

        $rootScope.loadingModal = true;

        // initialize offline progress
        if (enableHybrid && $scope.offline) {
            $rootScope.offlineMode = true;
            $rootScope.loadingModal = false;
            $rootScope.offlineModal = true;
        }

        $http.defaults.headers.common['Authorization'] = 'Basic ' + data.token;
        if (enableHybrid || enableOnPremise) {
            hybridModule.messageActiveServiceWorker({type: 'TOKEN', token: data.token});
        }
      
        $timeout(function() {
            $rootScope.loadingModalMessage = "Loading Vessels Data ...";
        }, 1000);
    
        // if we get in here it means that login was successful
        principal.authenticate({
            username: data.username,
            roles: ["User"]
        });

        $rootScope.user = data;
        $rootScope.username = data.username;
        $rootScope.role = data.role;
        $rootScope.selectedVessel = data.selectedVessel;
        $rootScope.vesselModels = data.vesselModels;
        $rootScope.classification = data.classification;
        $rootScope.formConfiguration = data.formConfiguration;
        $rootScope.features = data.features;

        ReportService.setVesselReportNumber(data.selectedVessel.reportNumber);
        ReportService.setOffhireReportNumber(data.selectedVessel.offhireReportNumber);
        ReportService.setMEReportNumber(data.selectedVessel.meReportNumber);
        ReportService.setAEReportNumber(data.selectedVessel.aeReportNumber);
        
        if (enableHybrid || enableOnPremise) {
        // if there's a report number and a hasIncompleteVessel report values set on the service, get them from there
        // otherwise use the one that is on the response itself if it's a report number > the one from the API response/cache
            hybridModule.serverMetaGet($rootScope.selectedVessel.id).then(function(offlineVesselData) {
                ReportService.setVesselReportNumber(offlineVesselData && offlineVesselData.reportNumber && offlineVesselData.reportNumber > data.selectedVessel.reportNumber ? offlineVesselData.reportNumber : data.selectedVessel.reportNumber);
                $rootScope.hasIncompleteVesselReport = offlineVesselData && offlineVesselData.hasIncompleteVesselReport != undefined ? offlineVesselData.hasIncompleteVesselReport : data.selectedVessel.hasIncompleteVesselReport;
    
                ReportService.setMEReportNumber(offlineVesselData &&  offlineVesselData.meReportNumber &&  offlineVesselData.meReportNumber > data.selectedVessel.meReportNumber ? offlineVesselData.meReportNumber : data.selectedVessel.meReportNumber);
                ReportService.setAEReportNumber(offlineVesselData &&  offlineVesselData.aeReportNumber &&  offlineVesselData.aeReportNumber > data.selectedVessel.aeReportNumber ? offlineVesselData.aeReportNumber : data.selectedVessel.aeReportNumber);
                $rootScope.hasIncompleteMeEngineReport = offlineVesselData && offlineVesselData.hasIncompleteEngineReport && offlineVesselData.incompleteEngineReport && offlineVesselData.incompleteEngineReport._cls == 'EngineReport.MEReport';
                $rootScope.hasIncompleteAeEngineReport = offlineVesselData && offlineVesselData.hasIncompleteEngineReport && offlineVesselData.incompleteEngineReport && offlineVesselData.incompleteEngineReport._cls == 'EngineReport.AEReport';
            }).catch(function() {
                ReportService.setVesselReportNumber(data.selectedVessel.reportNumber);
                $rootScope.hasIncompleteVesselReport = data.selectedVessel.hasIncompleteVesselReport;
                ReportService.setMEReportNumber(data.selectedVessel.meReportNumber);
                ReportService.setAEReportNumber(data.selectedVessel.aeReportNumber);
            });
            OfflineDatabase.initializeDataRequestRef(data.selectedVessel.id, data.username);
        }


        if ($rootScope.features.analytics_redirect && $rootScope.features.analytics_redirect.enabled == true) {
            let analyticModuleUrl = redirectToModule(data.features, 'vessel_dashboard');
            $state.go(analyticModuleUrl, {}, { reload: true });
            // avoid return to report view because the returnToStateParams are not initialized & leads to missing fields
        } else if ($scope.returnToState && $scope.returnToState.name && $scope.returnToState.name.match && !$scope.returnToState.name.match('Report$')) {
            $state.go($scope.returnToState.name, $scope.returnToStateParams, { reload: true });
        } else {
            let reportingModuleUrl = redirectToModule(data.features, 'crew_dashboard');
            $state.go(reportingModuleUrl, {}, { reload: true});
        };
    };

    $scope.activateNewServiceWorker = function() {
        // checks if local db server has initialized and there is no existing report ready to sync 
        // before activating new service worker waiting to take over old service worker
        if (server) {
            hybridModule.runAnyNewServiceWorker()
        } else {
            window.location.reload();
        }
    }

    if (enableHybrid) {
        $scope.activateNewServiceWorker();
    } 

    $scope.$watch("loadingModal", function(value) {
        if (value) {
            $("#loadingModal").modal('show');
        } else {
            $("#loadingModal").modal('hide');
        }
    });

    $scope.isDarkTheme = themePalette.getCurrentTheme() == 'skin-navfleet';
    
    function clearCache() {
        hybridModule.messageActiveServiceWorker({
            type: 'SET_TIMEOUT',
            payload: { status: false}
        });
        hybridModule.serverProgressUpdate({
            count: 'count',
            cacheCount: 0,  
            apiRequestCount: 0,
            cacheBusterHasCached: false
        });
        if (window.caches) {
            window.caches.keys().then(cacheNames => {
                let foundCache = cacheNames.filter(cacheName => cacheName == CACHE_NAME);
                if (foundCache.length == 1) {
                    window.caches.delete(foundCache[0])
                    .then(results => {
                        notify({message: 'Succesfully cleared cache', duration: 2000, classes: ['ok-notification']});
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }  
            })
        }
    };

    function clearIndexedDB() {
        var promises = [
            hybridModule.serverUsersClear(),
            hybridModule.serverMetaClear(),
            hybridModule.serverProgressClear(),
            hybridModule.serverReportsClear(),
            hybridModule.serverEngineReportsClear(),
            hybridModule.serverOffhireReportsClear(),
        ];
    
        Promise.all(promises).then(function() {
            notify({message: 'Succesfully cleared Indexedb', duration: 2000, classes: ['ok-notification']});
        }).catch(function(error) {
            notify({message: "Something went wrong. Please refresh browser", duration: 2000, classes: ['bad-notification']});
        })
    };
    $(window).keydown(function(event) {
        // shift + alt + c
        if (event.shiftKey && event.altKey && event.keyCode == 67) {
            clearIndexedDB();
            clearCache();
            $scope.activateNewServiceWorker();
        }
    });

    
}]);



routerApp.controller('lobbyController', ['$log', '$timeout', '$rootScope', '$scope', '$state', 'AuthenticationService', 'principal', 'ReportService', '$http', function($log, $timeout, $rootScope, $scope, $state, AuthenticationService, principal, ReportService, $http, quala) {
    
    const onAuthSuccess = (response) => {
        var data = response;

        $rootScope.loadingModal = true;

        $timeout(function() {
            $rootScope.loadingModalMessage = "Loading Vessels Data ...";
        }, 1000);
        
        if (enableHybrid) {
            // initialize offline progress
            $rootScope.offlineMode = true;
            $rootScope.loadingModal = false;
            $rootScope.offlineModal = true;
        }

        // if we get in here it means that login was successful
        principal.authenticate({
            username: data.username,
            roles: ["User"]
        });

        $rootScope.user = data;
        $rootScope.username = data.username;
        $rootScope.role = data.role;
        $rootScope.selectedVessel = data.selectedVessel;
        $rootScope.vesselModels = data.vesselModels;
        $rootScope.classification = data.classification;
        $rootScope.formConfiguration = data.formConfiguration;
        $rootScope.features = data.features;

        ReportService.setVesselReportNumber(data.selectedVessel.reportNumber);
        ReportService.setOffhireReportNumber(data.selectedVessel.offhireReportNumber);
        ReportService.setMEReportNumber(data.selectedVessel.meReportNumber);
        ReportService.setAEReportNumber(data.selectedVessel.aeReportNumber);
        $rootScope.hasIncompleteVesselReport = data.selectedVessel.hasIncompleteVesselReport;

        // try to immediately redirect to the requested route.
        // avoid return to report view because the returnToStateParams are not initialized & leads to missing fields
        if ($rootScope.returnToState && $rootScope.returnToState.name && $rootScope.returnToState.name.match && !$rootScope.returnToState.name.match('Report$')) {
            $state.go($rootScope.returnToState.name, $rootScope.returnToStateParams, { reload: true });
        } else {
            // if analytics direct is set to enabled, redirect to analytics
            // otherwise, default to site.kpi
            if ($rootScope.features && $rootScope.features.analytics_redirect && $rootScope.features.analytics_redirect.enabled == true) {
                const analyticModuleUrl = redirectToModule(data.features)
                $state.go(analyticModuleUrl, {}, { reload: true });
            } else {
                const reportingModuleUrl = redirectToModule(data.features, 'crew_dashboard');
                $state.go(reportingModuleUrl, {}, { reload: true});
            }
        }
    }

    if (enableOnPremise) {
        hybridModule.serverUsersQuery()
        .then((userData) => {
            let data = userData && userData.length > 0 && userData[0].response;
            if (!data) {
                throw new Error("No user data");
            }
            $http.defaults.headers.common['Authorization'] = 'Basic ' + userData[0].response.token;
            return data;            
        })
        .then(data => onAuthSuccess(data))
        .catch(err => console.error(err))
    } else {
        // Make a request with credentials: 'include' to tell the browser
        // to include the cookie and Token automatically
        fetch(baseUrl + 'token', {
            method: 'POST',
            credentials: 'include',
        })
        .then( response => response.json() )
        .then( response => {
            if (response.code == 200) {
                return onAuthSuccess(response);
            } else {
                $state.go('login')
            }
        }).catch(error => {
            // if this an error with the login, take user back to login page
            $state.go('login')
        });
    }
    
    $scope.$watch("loadingModal", function(value) {
        if (value) {
            $("#loadingModal").modal('show');
        } else {
            $("#loadingModal").modal('hide');
        }
    });
}]);
