class ModalMessage {
    modalId: string;
    title: string;
    message: string;

    constructor(modalId, title, message) {
        this.modalId = modalId;
        this.title = title;
        this.message = message;
    }
}

const modalMessages = {
    reportMerge: new ModalMessage(
        'reportMerge',
        null,
        'Your report has been updated with the latest changes.'
    ),
    refreshSensorData: new ModalMessage(
        'refreshSensorData',
        'Reload Sensor Data?',
        'New sensor data may be available. Would you like to load data nad overwrite saved values?'
    )
};

class Modal {    
    currentModal: string;
    modalQueue: string[] = [];
    modalMessages: Map<string,ModalMessage> = new Map();
    constructor(messages) {
        this.modalMessages = messages;
    }

    getModal(modalId) {
        return this.modalMessages[modalId];
    }

    setCurrentModal(modalId: string): void {
        this.currentModal = modalId;
    }

    getCurrentModal(){
        return this.modalQueue.length > 0 && this.modalQueue[0];
    }

    enqueue(modalId: string): void {
        this.modalQueue.push(modalId)
    };

    dequeue(modalId?: string): string[] {
        if (modalId) {
            this.modalQueue = this.modalQueue.filter(queuedId => queuedId != modalId)
        } else {
            this.modalQueue.shift();
        }
        if (this.modalQueue.length > 0) {
            this.setCurrentModal(this.modalQueue[0]);
        }
        return this.modalQueue;
    };
};

const modalService = new Modal(modalMessages);

export {
    modalService
}