import { routerApp, themePalette } from '../app.module';

    routerApp.factory('TrafficSystemService', function() { 

        var green = themePalette.colors.GREEN  || "#00816b";
    var yellow = themePalette.colors.YELLOW || "#f7a153";
    var red = themePalette.colors.RED || "#ee402f";
    var grey = themePalette.colors.GREY || "#777777";
    var trafficColor = { 
        green : green,
        yellow : yellow,
        red : red,
        grey : grey  
    };

    var getColor = function(value, range, reverse, customColors=null) {
        // assumes that the first element is the upper bound for the red value
        // the second item is the upper bound for the yellow value
        // anything after the second value would end up being green
        // can flip this with the reverse boolean
        var firstUpperLimit = range[0];
        var secondUpperLimit = range[1];
        if (reverse) {
            if (value < firstUpperLimit) {
                return customColors?.[0] || trafficColor.green;
            } else if (value >= firstUpperLimit && value <= secondUpperLimit) {
                return customColors?.[1] || trafficColor.yellow;
            } else {
                return customColors?.[2] || trafficColor.red;
            }
        } else {
            if (value < firstUpperLimit) {
                return customColors?.[0] || trafficColor.red;
            } else if (value >= firstUpperLimit && value <= secondUpperLimit) {
                return customColors?.[1] || trafficColor.yellow;
            } else {
                return customColors?.[2] || trafficColor.green;
            }
        }
    }

    var updateColors = function(newColors: object) {
        trafficColor = Object.assign({}, trafficColor, newColors);       
    }

    return {
        getMileageColor: function(mileage) { return getColor(mileage, [15, 20], false); },
        getFuelColor: function(fuel) { return getColor(fuel, [5, 15], true); },
        getKPIColor: function(kpi) { return getColor(kpi, [70, 80], false); },
        getCO2EmissionsColor: function(co2) { return getColor(co2, [20, 40], true); },
        getSpeedPerformanceColor: function(speedPerformance) { return getColor(speedPerformance, [80, 90], false); },
        getRADeviationColor: function(deviation) { return getColor(deviation, [10, 15], true); },
        getCIIRatingColor: function(rating) { 
            const ratingNumber = {
                'A': 20,
                'B': 20,
                'C': 60,
                'D': 100,
                'E': 100,
            }
            const rNumber = ratingNumber[rating]
            return getColor(rNumber, [40, 80], true, [trafficColor.green, trafficColor.yellow, trafficColor.red]); 
        },
        // ra tab extras
        getSFOCDeviationColor: function(sfoc) { return getColor(sfoc, [3, 5], true); },
        getInvalidColor: function() { return trafficColor.grey; },
        getFuelConsumptionDeviationColor: function(stockDeviation) { return getColor(stockDeviation, [2, 4], true); },
        getPropulsionEfficiencyColor: function(pp) { return getColor(pp, [85, 95], false); },
        updateColors: updateColors,
    }

});
