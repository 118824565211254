export const FUEL_DEFAULT_VALUES = {
    hfo: {
        lcv: 40500,
        co2_eq_wtt: 13.5,
        cf_co2: 3.114,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    lfo: {
        lcv: 41000,
        co2_eq_wtt: 13.2,
        cf_co2: 3.151,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    mgo: {
        lcv: 42700,
        co2_eq_wtt: 14.4,
        cf_co2: 3.151,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    mdo: {
        lcv: 42700,
        co2_eq_wtt: 14.4,
        cf_co2: 3.151,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    b10lfo: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: true,
    },
    b10mgo: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: true,
    },
    biolfo: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: true,
    },
    biomgo: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: true,
    },
    ulsfo2020: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    ulslfo2020: {
        lcv: null,
        co2_eq_wtt: 13.2,
        cf_co2: 3.151,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    ulsmdo2020: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    ulsmgo2020: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    vlsfo2020: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    vlslfo2020: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: 0.00005,
        cf_n2o: 0.00018,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    lpgp: {
        lcv: 46000,
        co2_eq_wtt: 7.8,
        cf_co2: 3,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    lpgb: {
        lcv: 46000,
        co2_eq_wtt: 7.8,
        cf_co2: 3.03,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    lng: {
        lcv: 49100,
        co2_eq_wtt: 18.5,
        cf_co2: 2.75,
        cf_ch4: 0,
        cf_n2o: 0.00011,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    methanol: {
        lcv: null,
        co2_eq_wtt: 31.3,
        cf_co2: 1.375,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    ethanol: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: 1.913,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    other: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null,
        is_bio_fuel: false,
    },
    BLANK: {
        lcv: null,
        co2_eq_wtt: null,
        cf_co2: null,
        cf_ch4: null,
        cf_n2o: null,
        c_slip: null,
        e_value: null, 
        is_bio_fuel: false,
    }
}