import { routerApp, baseUrl } from '../app.module';
import angular from 'angular';

routerApp.factory('ClientSettingsService', ['$http', '$log', function($http: angular.IHttpService, $log: angular.ILogService) {
    let cachedClientsById = new Map<string, object>();

    return {
        getClient: (clientId: string) => {
            return $http.get(`${baseUrl}clients/${clientId}`);
        },
        setClient: (client: any) => {
            cachedClientsById[client._id] = client;
            return client;
        },
        saveClient: (client: any) => {
            return $http.post(`${baseUrl}clients/${client._id}`, client);
        }
    }
}]);
