import { routerApp, analyticsUrl } from '../../app.module';

routerApp.factory('VoyageService', ['$http', function($http) {
    let that = this;

    var voyageOptions = {
        allVessels: {},
        selectAll: false
    };

    var setAllClasses = function(isSelected) {
        for (var key in voyageOptions.allVessels) {
            voyageOptions.allVessels[key].active = isSelected;
        }
    };

    this.voyages = null;
    this.voyageIdType = null;
    this.dateRangeFrom = null;
    this.dateRangeTo = null;

    return {
        loadVoyages: function(reportFrom, reportTo, voyageIdType) {
            return $http.post(analyticsUrl + 'vessels/voyages', {
                vessel_ids: this.getActiveVesselIds(),
                report_from: reportFrom,
                report_to: reportTo,
                voyage_id_type: voyageIdType
            });
        },
        setVoyages: function(data) {
            that.voyages = data;
        },
        getVoyages: function() {
            return that.voyages;
        },
        setVoyageIdType: function(value) {
            that.voyageIdType = value;
        },
        getVoyageIdType: function() {
            return that.voyageIdType;
        },
        setDateRanges: function(dateRangeFrom, dateRangeTo) {
            that.dateRangeFrom = dateRangeFrom;
            that.dateRangeTo = dateRangeTo;
        },
        getDateRangeFrom: function() {
            return that.dateRangeFrom;
        },
        getDateRangeTo: function() {
            return that.dateRangeTo;
        },
        toggleVessel: function(key) {
            (voyageOptions.allVessels[key] = voyageOptions.allVessels[key] || {}).active = voyageOptions.allVessels[key] && !voyageOptions.allVessels[key].active;
        },
        getAllVessels: function() {
            return voyageOptions.allVessels;
        },
        selectAll: function() {
            voyageOptions.selectAll = true;
            setAllClasses(true);
        },
        deselectAll: function() {
            voyageOptions.selectAll = false;
            setAllClasses(false);
        },
        isSelectAllSet: function() {
            return voyageOptions.selectAll;
        },
        initializeVessels: function(vessels, selectedVessel) {
            vessels.forEach(function(v) {
                voyageOptions.allVessels[v.name] = {
                    active: v.id == selectedVessel.id,
                    id: v.id
                };
            });
        },
        getActiveVesselIds: function() {
            return Object.keys(voyageOptions.allVessels).reduce(function(acc, vesselName) {
                if (voyageOptions.allVessels[vesselName].active) {
                    acc.push(voyageOptions.allVessels[vesselName].id);
                }
                return acc;
            }, []);
        }
    }
}]);
