import { IHybridService } from './hybrid.service';

let hybridService: IHybridService;
if (process.env.enableHybrid == 'true') {
    hybridService = require('./hybrid.service').default;
}
if (process.env.enableOnPremise) {
    hybridService = require('./edge.service').default;
}

export default hybridService;
