import moment from 'moment';
import { routerApp } from '../app.module';
import { getCacheBustedTemplate } from '../app.route';
import $ from 'jquery';

// date-range-picker.html
routerApp.directive('dateRangePicker', function() {

    return {
        templateUrl: getCacheBustedTemplate('pages/directives/date-range-picker.html'),
        require: 'ngModel',
        scope: {
            ngModel: '=',
        },
        link: function(scope, element, attributes, control) {
        	var dateRangeFormat = 'DD/MM/YYYY HH:mm';
        	scope.dateRangeOptions = [
        		{
        			label: 'Performance Since Dry Dock',
                    subLabel: 'Data since last dry dock',
        			dateRangeKey: 'current',
        			dateFrom: null,
        			dateTo: null,
        		},
        		{
        			label: 'Past 3 Months',
                    subLabel: 'Based on past 3 months',
        			dateRangeKey: '3_months',
        			dateFrom: moment().subtract(3, 'months').format(dateRangeFormat),
        			dateTo: null,
        		},
        		{
        			label: 'Past 6 Months',
                    subLabel: 'Based on past 6 months',
        			dateRangeKey: '6_months',
        			dateFrom: moment().subtract(6, 'months').format(dateRangeFormat),
        			dateTo: null,
        		},
                {
                    label: 'Next 3 Months (Prediction)',
                    subLabel: 'Based on past 3 months',
                    dateRangeKey: '3_months_prediction',
                    dateFrom: moment().subtract(3, 'months').format(dateRangeFormat),
                    dateTo: moment().add(3, 'months').format(dateRangeFormat),
                },
                {
                    label: 'Next 6 Months (Prediction)',
                    subLabel: 'Based on past 6 months',
                    dateRangeKey: '6_months_prediction',
                    dateFrom: moment().subtract(3, 'months').format(dateRangeFormat),
                    dateTo: moment().add(6, 'months').format(dateRangeFormat),
                },
        	];

            scope.closeDropdowns = function() {
                $('.dropdown-menu').parent().removeClass('open');
            }

            scope.selectDate = function(option) {
                scope.customDateEnabled = false;
                if (!scope.ngModel) {
                    scope.ngModel = {};
                }
                scope.selected = option;
                scope.ngModel.dashboardDateFrom = option.dateFrom;
                scope.ngModel.dashboardDateTo = option.dateTo;
                scope.ngModel.dashboardDateLabel = option.label;
                scope.closeDropdowns();
            }

        	scope.ngModel = {};
        	scope.customDateEnabled = false;
            scope.selectDate(scope.dateRangeOptions[0]);

		    // dropdown action
		    $('.fp-dropdown a').on('click', function (event) {
		        $(this).parent().toggleClass('open');

		    });

		    $(document).mouseup(function(e) {
		        var container = $(".dropdown-menu");

		        // if the target of the click isn't the container nor a descendant of the container
                // @ts-ignore
		        if (!container.is(e.target) && container.has(e.target).length === 0) {
		            scope.closeDropdowns();
		        }
		    });

		    scope.toggleCustomDateRange = function() {
		    	scope.customDateEnabled = !scope.customDateEnabled;
		    }

        }
    }
});