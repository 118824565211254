import Highcharts from 'highcharts';
import { routerApp, themePalette, roundToPlaces } from '../app.module';

const dateFormat = {
    dateTimeLabelFormats: {
        millisecond: '%b %e',
        second: '%b %e',
        minute: '%b %e',
        hour: '%b %e',
        day: '%b %e',
        week: '%b %e',
        month: '%b %e',
        year: '%b %e'
    },
    type: 'datetime',
    tickPixelInterval: 75
};

routerApp.factory('CrewDashboardGraphService', function() {    
    type TTimestamp = number;
    type TlowerLimit = number;
    type TUpperLimit = number;
    type TBoundaryData = [ TTimestamp, TlowerLimit, TUpperLimit ];
    type TBoundarySeries = TlowerLimit[] | TUpperLimit[];

    interface IBoundarySerie {
        name: string;
        color: string;
        data: TBoundaryData[]
    }
    const buildDynamicBandSeries = (dataSeries, areaRangeSeries: IBoundarySerie[] = [], boundaries: TBoundarySeries[]) => {
        let minYValue = dataSeries.mapKey(1).min()
        let maxYValue = dataSeries.mapKey(1).max()
        let currentYearCII;
        dataSeries.map((data, index) => {
            let date = data[0];
            let avgCIIAttained = data[3];
            let cii_required = data[2];
            if (cii_required > 0) {
                currentYearCII = cii_required;
            }
       
            const superiorBoundary = boundaries[0][index];
            const lowerBoundary = boundaries[1][index];
            const upperBoundary = boundaries[2][index];
            const inferiorBoundary = boundaries[3][index];
            
            if (minYValue > avgCIIAttained) minYValue = avgCIIAttained;
            if (maxYValue < avgCIIAttained) maxYValue = avgCIIAttained;
            let maxBoundary = inferiorBoundary  +  Math.abs(inferiorBoundary - upperBoundary);
            let minBoundary = superiorBoundary - Math.abs(lowerBoundary - superiorBoundary);
            if (maxYValue > maxBoundary ) {
                maxBoundary = maxYValue *1.20 
            }
            if (minYValue < minBoundary) {
                minBoundary = minYValue * 0.8;
            }

            if (currentYearCII) {
                areaRangeSeries[0].data.push([date, minBoundary, superiorBoundary])
                areaRangeSeries[1].data.push([date, superiorBoundary, lowerBoundary])
                areaRangeSeries[2].data.push([date, lowerBoundary, upperBoundary])
                areaRangeSeries[3].data.push([date, upperBoundary, inferiorBoundary])
                areaRangeSeries[4].data.push([date, inferiorBoundary, maxBoundary])
            } 
        })

        return areaRangeSeries;
    }
    const plotCIIChart = (
            containerId,
            dataSeries,
            avg_cii_attained_lifetime,
            boundaryLimitsSeries,
            plotLines,
        ) => {

        const ciiAttainedSeries = [{
            name: 'Attained CII, 90 Days',
            data: dataSeries,
            keys: ['x','y','ciiRequired', 'avgCIIAttained','co2Emissions', 'totalObsDistance'],
            width: 1,
            color: themePalette.colors.GRAPH_BLUE,
            type: 'line',
            marker: {
                radius: 3
            },
            zIndex: 10,
        }];

        ciiAttainedSeries.push({
            name: 'Attained CII, Period Avg.',
            data: avg_cii_attained_lifetime,
            // @ts-ignore
            type: 'line',
            dashStyle: 'Dash',
            color: themePalette.colors.THEME_TEXT_COLOR,
            zIndex: 99,
            disableTooltip: true,
            hover: { enabled: false, halo: { size: 0 } },
            enableMouseTracking: false,
            // @ts-ignore
            marker: { enabled: false, states: { hover: { enabled: false, } } },
            states: { hover: { enabled: false, lineWidthPlus: 0, } },
        })
        let boundarySeries = [
            { name: 'A', color: themePalette.colors.CII_GREEN, data: []},
            { name: 'B', color: themePalette.colors.CII_LIGHT_GREEN, data: []},
            { name: 'C', color: themePalette.colors.CII_YELLOW, data: []},
            { name: 'D', color: themePalette.colors.CII_ORANGE, data: []},
            { name: 'E', color: themePalette.colors.CII_RED, data: []},
        ];

        boundarySeries = buildDynamicBandSeries(dataSeries, boundarySeries, boundaryLimitsSeries)
        boundarySeries.forEach(bandSeries => {
            ciiAttainedSeries.push({
                name: bandSeries.name,
                color: bandSeries.color,
                type: 'arearange',
                data: bandSeries.data,
                label: { enabled: true },
                tooltip: {
                    enableMouseTracking: false,
                    enabled: false,
                    formatter: null,
                    pointFormatter: null
                },
                hover: { enabled: false, halo: { size: 0 } },
                enableMouseTracking: false,
                // @ts-ignore
                marker: { enabled: false, states: { hover: { enabled: false, } } },
                states: { hover: { enabled: false, lineWidthPlus: 0, } },
                animation: false,
                animationLimit: 0,
                showInLegend: true,
                zIndex: 0,
            })
        });

        return  Highcharts.chart(containerId, {
            chart: {
                zoomType: 'xy',
                type: 'scatter',
                backgroundColor: themePalette.colors.THEME_BLUE,
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {...dateFormat, plotLines: plotLines},
            yAxis: {
                title: {
                    text: 'Attained CII'
                }
            },
            plotOptions: {
                line: {
                    marker: {
                        enabled: true
                    }
                },
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><br/><table>',
                footerFormat: '</table>',
                pointFormatter: function() {
                    var point = roundToPlaces(this.y, 2);
                    var tooltips = [
                        { name: 'Attained CII, 90 days (g/MT.NM)', value: point },
                        { name: 'Attained CII, Period Avg. (g/MT.NM)', value: roundToPlaces(this.avgCIIAttained, 2) },
                        { name: 'Required CII (g/MT.NM)', value: roundToPlaces(this.ciiRequired, 2) },
                        { name: 'CO2 Emissions (MT)', value: roundToPlaces(this.co2Emissions, 1) },
                        { name: 'Distance (MT)', value: roundToPlaces(this.totalObsDistance, 1) },
                    ];
                    return tooltips.reduce(function(acc, tt) { return acc + '<b>' + tt.name + ':</b> ' + tt.value + '<br/> '; }, '');
                },
                useHTML: true
            },
            series: ciiAttainedSeries,
        });
    };

    return {
        plotCIIChart
    }

});