import { routerApp, baseUrl } from '../../app.module';

routerApp.factory('UserService', ['$http', function($http) {
    return {
        updateFleetPerformanceSettings: function(userId, newSettings) {
            return $http.post(baseUrl + 'users/' + userId + '/fleetPerformanceSettings', {
                fleet_performance: {
                    date_range: newSettings.dateRange,
                    voyage_leg: newSettings.voyageLeg,
                    bf: newSettings.bf,
                    report_from: newSettings.reportFrom.toISOString(),
                    report_to: newSettings.reportTo.toISOString(),
                    selected_classes: newSettings.selectedClasses,
                    selected_vessels: newSettings.selectedVessels,
                    selected_metric: newSettings.selectedMetric,
                    selected_metric_reverse: newSettings.selectedMetricReverse
                }
            });
        }
    };
}]);
