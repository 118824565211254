import { routerApp } from '../app.module';

routerApp.factory('PollingService', function() {

    var _intervals = [];

    var addInterval = function(i) {
        _intervals.push(i);
    }

    var clearAllIntervals = function() {
        _intervals.forEach(clearInterval);
    }

    return {
        addInterval: addInterval,
        clearAllIntervals: clearAllIntervals
    }
});
