import 'promise-polyfill/dist/polyfill';
import 'whatwg-fetch';
import 'url-polyfill';
import moment from "moment";
import { isNull } from 'lodash';
import { enableOnPremise } from "../app.module";
import hybridService from "./hybrid";
import { IForm } from '../models/vessel';

const CACHE_VERSION = 2;
const CACHE_NAME = "tva-offline-cache-v" + CACHE_VERSION;
const ONLINE_REQ_TIMEOUT = 15000; // (milliseconds) max timeout limit while online for any server requests before defaulting to cache
const OFFLINE_REQ_TIMEOUT = 0;    // (milliseconds) max timeout limit while offline for any server requests before defaulting to cache        
const ONLINETIMEOUT = 900000; // 15 minutes interval to re-enable Work Online mode when app is Go Offline

const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

var sumValues = function (newValues) {
    var sum = 0;
    for (var k = 0; k < newValues.length; k++) {
        if (newValues[k] != undefined) {
            sum += parseFloat(newValues[k]);
        }
    }
    return sum;
}

var calculateEffiencyRatio = function (emissions, transportWork) {
    return transportWork ? emissions * 1000000 / transportWork : null;
};
// in fleet performance controller
var getEEOIMax = function (vessel) {
    var gccClientId = '59271458941636628c3713fd';
    return vessel.client_id == gccClientId ? 1000 : 20;
};

var mainFlowMeterHasFuelAndGas = function (flowMeter) {
    if (flowMeter == 'TwoLines' || flowMeter == 'DuelFuelEngineMESingleLine' || flowMeter == 'DuelFuelEngineMEAESingleLine') return true;
    else return false;
};

var boilerFlowMeterHasFuelAndGas = function (flowMeter) {
    if (flowMeter == 'InletAB12DiffCB' || flowMeter == 'GasInletAB1' || flowMeter == 'GasInletAB12') return true;
    else return false;
}

var autoPopulateMainFlowMeterTags = function (flowMeter) {
    if (flowMeter == 'SingleLineAutoPopulated') return true;
    else return false;
};
var autoPopulateBoilerFlowMeterTags = function (flowMeter) {
    if (flowMeter == 'InletAB12CBAutoPopulated') return true;
    else return false;
};

const showByDefault = function (form, curentReportType, path, formPartType?) {
    if (!form) return true;
    // formPartType is one of: fields, sections, tabs.
    var formPart = form[formPartType || 'fields'][path];

    if (!formPart) {
        // Show by default: return true if formPart doesn't exist (which means no rule exists for it).
        return true;
    } else if (formPart.for_report_types && (formPart.for_report_types.length == 0 || formPart.for_report_types.indexOf(curentReportType) != -1)) {
        // Check if rule applies for current report type. It applies if the report type is in `for_report_types`, or
        // if the `for_report_types` list is empty (meaning the rule applies for all report types). If it does
        // apply, then return visibility.
        return !!formPart.visible;
    } else if (formPart.for_report_types && formPart.for_report_types.indexOf(curentReportType) == -1) {
        return !formPart.visible; 
    } else {
        // Show by default: return true if no rule applies.
        return true;
    }
};


/**
 * checks form whether field, section, tab is required based on vessel.form
 * @param form 
 * @param curentReportType - 'Report.SeaReport', 'Report.PortReport', 'Report.AnchorReport', 'Report.ManeuveringReport'
 * @param path - ex: 'report.stock.lng'
 * @param formPartType 'fields' or 'sections', 'tabs'
 * @returns boolean
 */
const requireByDefault = (form: IForm, curentReportType: string, path: string, formPartType?: string) => {
    if (!form) return true;
    var formPart = form[formPartType || 'fields'][path];

    if (!formPart) {
        // Require by default: return true if formPart doesn't exist (which means no rule exists for it).
        return true;
    } else if (formPart.for_report_types && (formPart.for_report_types.length == 0 || formPart.for_report_types.indexOf(curentReportType) != -1)) {
        // Check if rule applies for current report type. It applies if the report type is in `for_report_types`, or
        // if the `for_report_types` list is empty (meaning the rule applies for all report types). If it does
        // apply, then return requirement.
        return !!formPart.required && !!formPart.visible;
    } else {
        // Require by default: return true if no rule applies.
        return true;
    }
}

type UserFeatures = {
    // reporting features
    reporting?: any;
    veslink?: any;
    offhire_reporting?: any;
    engine_reporting?: any;
    email_alerts?: any;
    navfleet?: any;
    crew_dashboard?: any;

    // analytics features
    fleet_performance?: any;
    fleet_tracker?: any;
    voyage_analysis?: any;
    voyage_planning?: any;
    fuel_tables?: any;
    analytics_directory?: any;
    analytics_redirect?: any;
    mrv?: any;
    tableau?: any;
    data_reports?: any;
    advanced_analytics?: any;
    vessel_dashboard?: any;
};

const redirectToModule = (features: UserFeatures, defaultModule = null): string => {
    const isReportingModule = ['crew_dashboard', 'reporting'].indexOf(defaultModule) > -1;
    const mapFeatureToModuleUrl = isReportingModule ?
        { // reporting modules
            'crew_dashboard': 'site.kpi',
            'reporting': 'site.vesselPerformance',
        } :
        { // analytic modules
            'vessel_dashboard': 'analytics.dashboard',
            'fleet_tracker': 'analytics.fleetTracker',
            'fleet_performance': 'analytics.fleetPerformance',
            'voyage_analysis': 'analytics.voyageAnalysis',
            'voyage_planning': 'analytics.voyagePlan',
            'advanced_analytics': 'analytics.myReports',
            'tableau': 'analytics.executiveDashboard',
            'fuel_tables': 'analytics.fuelTables',
            'data_reports': 'analytics.dataReports',
            'mrv': 'analytics.mrvReporting',
        };
    if (defaultModule && mapFeatureToModuleUrl[defaultModule] && (features[defaultModule] && features[defaultModule].enabled)) {
        return mapFeatureToModuleUrl[defaultModule];
    }
    let moduleUrl;
    if (isReportingModule) {
        moduleUrl = 'site.kpi';
    }
    for (const featureKey in mapFeatureToModuleUrl) {
        // reporting: if feature is enable by default unless specificied
        if (isReportingModule && (!features[featureKey] || features[featureKey] && features[featureKey].enabled != false)) {
            moduleUrl = mapFeatureToModuleUrl[featureKey];
            return moduleUrl;
        }

        if (!isReportingModule && features[featureKey] && features[featureKey].enabled) {
            if (featureKey == 'tableau') {
                if (features.tableau.view_name == 'Executive Dashboard') {
                    return 'analytics.executiveDashboard';
                } else {
                    return 'analytics.realTimeAnalytics';
                }
            }
            moduleUrl = mapFeatureToModuleUrl[featureKey];
            return moduleUrl;
        }
    }
    return moduleUrl;
};

const executeFnOnElementLoad = (elementId: string, fn: () => void, timeout = 2000, maxTries = 3) => {
    if (maxTries < 1 || elementId == undefined || fn == undefined) return;
    const targetElement = $(elementId);
    if (targetElement && targetElement[0]) {
        // execute custom function if element has loaded
        fn();
    } else {
        // wait for element to load
        maxTries--;
        setTimeout(() => {
            executeFnOnElementLoad(elementId, fn, timeout, maxTries)
        }, timeout);
    }
}


type MaybeDate = string | Date | moment.Moment | number | undefined | null;
const parseDateOrStringToMoment = (maybeDate: MaybeDate, dateFormat?: string): moment.Moment | undefined => {
    if (typeof maybeDate == 'string' || maybeDate instanceof String) {
        return moment(maybeDate.toString(), dateFormat || DATETIME_FORMAT);
    } else if (maybeDate instanceof Date) {
        return moment(maybeDate);
    } else if (maybeDate && moment.isMoment(maybeDate)) {
        return maybeDate;
    } else if (typeof maybeDate == "number") {
        return moment(new Date(maybeDate));
    } else {
        return undefined;
    }
}

const dateToUTCString = (input: MaybeDate, inputFormatString?: string, outputFormatString?: string): string => {
    return parseDateOrStringToMoment(input, inputFormatString)?.utc().format(outputFormatString || DATETIME_FORMAT);
}

// this returns default http requester or custom fetch
const httpRequestGenerator = (useCustomFetch: boolean, defaultRequester: Function) => {
    return useCustomFetch ? customFetch : defaultRequester;
}

/**
 * customFetch method is an attempt to recreate angular $http using fetch.
 * @param url
 * @param options
 * * @param data - same as body, but will be automatically serialize data
 * * @param body - accept default input value 
 */

const customFetch = ({ url, ...option }) => {
    let _method = 'GET', _params = {};
    if (option) {
        let { method, params, data, body } = option;
        _method = method || 'GET';
        _params = params || {};
        option.body = data && JSON.stringify(option.data) || body || null;
    }
    option = Object.assign({}, option, { method: _method, credentials: 'include' });
    let resource_uri = new URL(url);
    Object.keys(_params).forEach(paramKey => {
        if (_params[paramKey]) {
            resource_uri.searchParams.set(paramKey, _params[paramKey]);
        }
    })

    const requesterFunction = enableOnPremise ? hybridService.fetchWithTimeout : fetch as any;
    if (enableOnPremise) {
        // if default get request to caching 
        return requesterFunction(resource_uri, option)
    } else {
        // hybrid and online request
        return requesterFunction(resource_uri, option).then(res => {
            return new Promise((resolve, reject) => {
                return res.json().then(data => {
                    return resolve({ data });
                })
            })
        });
    }
}

const arrayToCsv = (data: Array<Array<string>>) => {
    // https://stackoverflow.com/a/68146412
    return data.map(row =>
        row
            .map(v => v ? String(v) : "")  // convert every value to String
            .map(v => v.replaceAll('"', '""'))  // escape double colons
            .map(v => `"${v}"`)  // quote it
            .join(',')  // comma-separated
    ).join('\r\n');  // rows starting on new lines
}

const downloadBlob = (content: any, filename: string, contentType: string) => {
    // https://stackoverflow.com/questions/14964035/how-to-expor-javascript-array-info-to-csv-on-client-side
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}

const hasSameProps = (obj1, obj2) => {
    return Object.keys(obj1).every(function (prop) {
        return obj2.hasOwnProperty(prop);
    });
}

const convertRadToDMS = (degreesRad: number, lng: boolean) => {
    let degreesDecimal = rad2deg(degreesRad);
    return convertDDToDMS(degreesDecimal, lng)
}

const convertDDToDMS = (degrees: number | null, lng: boolean) => {
    if (degrees == undefined) {
        return null as null;
    }
    let D = Math.abs(degrees);
    return [
        0 | D,
        0 | (((D += 1e-9) % 1) * 60),
        (0 | (((D * 60) % 1) * 6000)) / 100,
        degrees < 0 ? (lng ? "W" : "S") : lng ? "E" : "N",
    ];
}

var convertRadToDMSString = (value: any, state: boolean) => {
    let DMS = convertRadToDMS(value, state)
    return roundToPlaces(DMS[0], 0) + "° " +
        roundToPlaces(DMS[1], 0) + "' " +
        roundToPlaces(DMS[2], 0) + "\" " +
        DMS[3];
}

var roundToPlaces = function (num, decimalPlaces) {
    if (decimalPlaces === undefined || decimalPlaces === null) {
        throw 'Missing argument: decimalPlaces';
    }
    return Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
};

var calculateDistanceBetweenPositions = function (radLat1, radLon1, radLat2, radLon2) {
    if (radLat1 == null || radLon1 == null || radLat2 == null || radLon2 == null) {
        return null;
    }
    var R = 3443.92; // Radius of the earth in NM

    // convert to degrees first
    let lat1 = rad2deg(radLat1);
    let lon1 = rad2deg(radLon1);
    let lat2 = rad2deg(radLat2);
    let lon2 = rad2deg(radLon2);

    if (lat1 == null || lat2 == null || lon1 == null || lon2 == null) {
        return null;
    }
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    // input is in radians
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in NM
    return d;
}

var rad2deg = function (rad) {
    if (rad == undefined) {
        return null as null;
    }
    return rad * 180 / Math.PI;
}

var deg2rad = function (deg) {
    if (deg == undefined) {
        return null as any;
    }
    return deg * (Math.PI / 180);
}

var pairwise = function (arr: any[]) {
    let pairs: any[] = [];
    for (var i = 0; i < arr.length - 1; i++) {
        pairs.push([arr[i], arr[i + 1]]);
    }
    return pairs;
}

var convertMetersPerSecondToKnots = function (speed: number) {
    if (speed == undefined) {
        return null;
    }
    return speed * (3600 / 1852);
}

var convertKelvinToCelsius = function (temperature: number) {
    if (temperature == undefined) {
        return null;
    }
    return temperature - 273.15;
}

var convertWattToKiloWatt = function (watt: number) {
    if (watt == undefined) {
        return null;
    }
    return watt / 1000;
}

var convertSecondsToHours = function (seconds: number) {
    if (seconds == undefined) {
        return null;
    }
    return seconds / 3600;
}

var convertWattPerSecondToKiloWattHours = function (wattPerSecond: number) {
    if (wattPerSecond == undefined) {
        return null;
    }
    return wattPerSecond / 3600000;
}

var convertRadiansPerSecondToRPM = function (radPerSecond: number) {
    if (radPerSecond == undefined) {
        return null;
    }
    return radPerSecond * (30 / Math.PI);
}

var calculateTotalConsumptionOverRate = function (kgPerSecond: number[], timePeriodInHrs: number) {
    if (kgPerSecond == undefined || timePeriodInHrs == undefined || kgPerSecond.length == 0) {
        return null;
    }
    // calculate the sum of all elements in the array
    let sum = kgPerSecond.reduce((accumulator, value) => accumulator + value, 0);
    let average = sum / kgPerSecond.length;
    let seconds = timePeriodInHrs * 60 * 60;
    return average * seconds;
}

var calculateFirstLastDelta = function (lastValue: number, firstValue: number) {
    if (lastValue == undefined || firstValue == undefined) {
        return null;
    }   
    return lastValue - firstValue;
}

var deleteNullValues = (obj: any) => {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            for (let subKey in obj[key]) {
                if (isNull(obj[key][subKey])) {
                    delete obj[key][subKey];
                }
            }
        }
        else {
            if (isNull(obj[key])) {
                delete obj[key];
            }
        }
    }

    return obj
}

const zeroIfUndefined = (value) => {
    if (value == undefined) {
        return 0
    }
    return value;
}

export {
    CACHE_VERSION,
    CACHE_NAME,
    ONLINE_REQ_TIMEOUT,
    OFFLINE_REQ_TIMEOUT,
    ONLINETIMEOUT,
    sumValues,
    calculateEffiencyRatio,
    getEEOIMax,
    mainFlowMeterHasFuelAndGas,
    boilerFlowMeterHasFuelAndGas,
    autoPopulateMainFlowMeterTags,
    autoPopulateBoilerFlowMeterTags,
    showByDefault,
    redirectToModule,
    executeFnOnElementLoad,
    parseDateOrStringToMoment,
    MaybeDate,
    DATETIME_FORMAT,
    dateToUTCString,
    customFetch,
    httpRequestGenerator,
    arrayToCsv,
    downloadBlob,
    hasSameProps,
    convertDDToDMS,
    convertRadToDMS,
    roundToPlaces,
    calculateDistanceBetweenPositions,
    pairwise,
    deg2rad,
    rad2deg,
    convertMetersPerSecondToKnots,
    convertKelvinToCelsius,
    convertWattToKiloWatt,
    convertSecondsToHours,
    convertWattPerSecondToKiloWattHours,
    convertRadiansPerSecondToRPM,
    convertRadToDMSString,
    calculateTotalConsumptionOverRate,
    calculateFirstLastDelta,
    deleteNullValues,
    zeroIfUndefined,
    requireByDefault,
}
