import { routerApp} from '../app.module';

routerApp.factory('AutoSaveService', function() {
    var changedInputFields = {};
    var currentInputField = {};
    
    // keeps tracks if report was previously saved manually or automatically
    var reportWasAutoSavedStatus = false;
   
    const setChangedInputField = function(ngModelName: string) {
      let newInputField = {};
      newInputField[ngModelName] = currentInputField
      const updatedInputFields = Object.assign({}, changedInputFields, newInputField)
      changedInputFields = updatedInputFields
    }

    const removeChangedInputField = function(ngModelName: string) {
      delete changedInputFields[ngModelName];
    }
    
    const getChangedInputField = function(ngModelName: string) {
      return changedInputFields[ngModelName];
    }

    const setOldValue = function(oldValue: any) {
      let oldNewValues = {};
      if (Object.keys(currentInputField).length > 0) {
        oldNewValues = Object.assign({},currentInputField);
      }
      oldNewValues['oldValue'] = oldValue;
      currentInputField = oldNewValues;
    }

    const setNewValue = function( newValue: any) {
      let oldNewValues = {};
      if (Object.keys(currentInputField).length > 0) {
        oldNewValues = Object.assign({},currentInputField);
      }
      oldNewValues['newValue'] = newValue;
      currentInputField = oldNewValues;
    }

    const hasChanged = function() {
      if (currentInputField.hasOwnProperty('oldValue') && currentInputField.hasOwnProperty('newValue')) {
        return currentInputField['oldValue'] != currentInputField['newValue'];
      }
      return false;
    }

    const resetChanges = function() {
      changedInputFields = {};
      currentInputField = {};
    }

    const setAutosaveStatus = function(status) {
      reportWasAutoSavedStatus = !!status;
    }

    const reportWasAutoSaved = () => reportWasAutoSavedStatus;

    return {
      setChangedInputField: setChangedInputField,
      removeChangedInputField: removeChangedInputField,
      getChangedInputField: getChangedInputField,
      getChangedInputFields: function() {
        return changedInputFields;
      },
      hasUnsavedChanges: function() {
        return Object.keys(changedInputFields).length > 0;
      },
      hasChanged: hasChanged,
      setOldValue: setOldValue,
      setNewValue: setNewValue,
      resetChanges: resetChanges,
      setAutosaveStatus: setAutosaveStatus,
      reportWasAutoSaved: reportWasAutoSaved,
    }
});
