import { routerApp } from '../app.module';
import angular from 'angular';
import { parseDateOrStringToMoment } from '../services/utilities';


routerApp.factory('UtilityService', function() {
    return {
        calculateEEOI: function(emissions, transportWork) {
            return transportWork ? emissions * 1000000 / transportWork : null;
        },
        // date utilities
        isBetweenDates: function(earlyDate, dateToCheck, laterDate) {
            if (!dateToCheck) return undefined;

            var earlyMoment = this.parseDateOrStringToMoment(earlyDate);
            var dateToCheckMoment = this.parseDateOrStringToMoment(dateToCheck);
            var laterMoment = this.parseDateOrStringToMoment(laterDate);

            return earlyMoment <= dateToCheckMoment && dateToCheckMoment < laterMoment;
        },
        parseDateOrStringToMoment: parseDateOrStringToMoment,
        // formatting
        round: function(num, decimalPlaces) {
            if (decimalPlaces === undefined || decimalPlaces === null) {
                return num;
            }
            return Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
        },

        downloadData: function(url, filename) {
            var anchor = angular.element('<a/>');
            anchor.attr({
                href: url,
                target: '_blank',
                download: filename
            })[0].click();
        },
    };
});
