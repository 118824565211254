require('es6-object-assign').polyfill();
import angular from 'angular';
import 'angular-ui-router';
import '@cgross/angular-notify';
import 'angular-filter';
import moment from 'moment';
import * as $ from 'jquery';
import 'angular-ui-bootstrap';
import './services/highcharts-regression';
import './services/adminLTE';
import { themePalette, Color } from './services/theme.service';
import hybridService from './services/hybrid/index';
import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { dateToUTCString, MaybeDate } from './services/utilities';
import _ from 'lodash';

const routerApp = angular.module('routerApp', ['ui.router', 'cgNotify', 'angular.filter', 'ui.bootstrap']);

angular.merge = function (s1,s2) {
    return $.extend(true,s1,s2);
};

const baseUrl = process.env.baseUrl;
const analyticsUrl = baseUrl + "analytics/";
const environmentEnabled = process.env.environmentEnabled;
const buildTheme = process.env.BUILD_THEME || 'skin-navfleet';
const enableHybrid = process.env.enableHybrid == 'true';
const enableOnPremise = process.env.enableOnPremise && !!process.env.onPremiseUrl;
// request timeout in seconds before assuming that a request to save/submit a report has failed
var reportRequestTimeout = 15;

if (!String.prototype.startsWith) {
	String.prototype.startsWith = function(search, pos) {
		return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
	};
}

const appVersionCommit = process.env.appVersionCommit;
const appVersionDate = process.env.appVersionDate;
const isQABuild = process.env.isQABuild == 'true';

const appInsightsInstrumentationKey = process.env.APPLICATIONINSIGHTS_INSTRUMENTATION_KEY;
let appInsights: ApplicationInsights = null;
function miscDataTelemetryInitializer(envelope: ITelemetryItem) {
    envelope.data['referrer'] = document.referrer;
    envelope.data['appVersionCommit'] = appVersionCommit;
    envelope.data['appVersionDate'] = appVersionDate;
    envelope.data['enableHybrid'] = enableHybrid;
    envelope.tags['ai.cloud.role'] = 'performance-frontend';
}
if (appInsightsInstrumentationKey) {
    appInsights = new ApplicationInsights({ config: {
        instrumentationKey: appInsightsInstrumentationKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        autoTrackPageVisitTime: true,
        correlationHeaderExcludedDomains: ['*.windy.com'],
        excludeRequestFromAutoTrackingPatterns:['windy.com'],
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableAjaxPerfTracking: true,
        disableFetchTracking: false,
        maxAjaxCallsPerView: -1,
        disableDataLossAnalysis: false,
        maxAjaxPerfLookupAttempts: 10
    } });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    appInsights.addTelemetryInitializer(miscDataTelemetryInitializer);
    console.log('Application Insights tracking initialized');
}

routerApp.factory('principal', ['$q', '$http', '$timeout', function($q, $http, $timeout) {
    var _identity = undefined;
    var _authenticated = false;

    return {
        isIdentityResolved: function() {
            return angular.isDefined(_identity);
        },
        isAuthenticated: function() {
            return _authenticated;
        },
        isInRole: function(role) {
            if (!_authenticated || !_identity.roles) return false;
            return _identity.roles.indexOf(role) != -1;
        },
        isAnyRole: function(roles) {
            if (!_authenticated || !_identity.roles) return false;
            for (var i = 0; i < roles.length; i++) {
              if (this.isInRole(roles[i])) return true;
            }
            return false;
        },
        authenticate: function(identity) {
            _identity = identity;
            _authenticated = identity != null;
        },
        logout: function() {
            _identity = null;
            _authenticated = null;
        },
        identity: function(force) {
            var deferred = $q.defer();
            if (force === true) _identity = undefined;

            // check and see if we have retrieved the
            // identity data from the server. if we have,
            // reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);
                return deferred.promise;
            }

            // otherwise, retrieve the identity data from the
            // server, update the identity object, and then
            // resolve
            // .... do stuff

            // for the sake of the demo, fake the lookup
            // by using a timeout to create a valid
            // fake identity. in reality,  you'll want
            // something more like the $http request
            // commented out above. in this example, we fake
            // looking up to find the user is
            // not logged in
            var self = this;
            $timeout(function() {
              self.authenticate(null);
              deferred.resolve(_identity);
            }, 1000);
            return deferred.promise;
        }
    }

}]);

routerApp.factory('authorization', ['$rootScope', '$state', 'principal', function($rootScope, $state, principal) {
    return {
        authorize: function() {
            return principal.identity().then(function() {
                var isAuthenticated = principal.isAuthenticated();
                if ($rootScope.toState.data.roles
                    && $rootScope.toState.data.roles.length > 0
                    && !principal.isAnyRole($rootScope.toState.data.roles)) {                        
                        if (isAuthenticated) {
                            // user is signed in but not
                            // authorized for desired state
                            $state.go('site.kpi');
                        } else {
                            // user is not authenticated. Stow
                            // the state they wanted before you
                            // send them to the sign-in state, so
                            // you can return them when you're done
                            $rootScope.returnToState = $rootScope.toState;
                            $rootScope.returnToStateParams = $rootScope.toStateParams;

                            // now, send them to the sign in state
                            // so they can log in
                            $state.go('lobby');
                        }
                }
            });
        }
    }
}]);
routerApp.run(['$rootScope', '$location', '$state', '$templateCache', '$http', '$stateParams', 'authorization', 'principal', '$timeout', function($rootScope, $location, $state, $templateCache, $http, $stateParams, authorization, principal, $timeout) {

       $rootScope.$state = $state;
       $rootScope.$stateParams = $stateParams;

       var locationHost = $location.host();
       if (locationHost == 'localhost' || locationHost == '127.0.0.1') {
            $rootScope.domainImage = 'vships';
       } else {
            var subdomain = locationHost.split('.')[0];
            if (subdomain == 'tva' || subdomain == 'dsm') {
                $rootScope.domainImage = 'diamonds';
            } else {
                $rootScope.domainImage = subdomain;
            }
       }
       
       $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams) {
           // track the state the user wants to go to;
           // authorization service needs this
           $rootScope.toState = toState;
           $rootScope.toStateParams = toStateParams;
           
           // if the principal is resolved, do an
           // authorization check immediately. otherwise,
           // it'll be done when the state it resolved.
           if (principal.isIdentityResolved()) {
               authorization.authorize();
            }
        });

    if (appInsights) {
        const userTelemetryInitializer = (envelope: ITelemetryItem) => {
            envelope.data['performanceUser_username'] = $rootScope.username;
            envelope.data['performanceUser_name'] = $rootScope.user && $rootScope.user.name;
            envelope.data['performanceUser_role'] = $rootScope.role;
            envelope.data['performanceUser_selectedVesselName'] = $rootScope.selectedVessel && $rootScope.selectedVessel.name;
            envelope.data['performanceUser_selectedVesselId'] = $rootScope.selectedVessel && $rootScope.selectedVessel.id;
            envelope.data['performanceUser_client'] = $rootScope.user && $rootScope.user.client;
            envelope.data['performanceUser_company'] = $rootScope.user && $rootScope.user.company;
        }
        appInsights.addTelemetryInitializer(userTelemetryInitializer);
    }

    }
]);

routerApp.filter('dec', function() {
  return function(num, numberOfDecimals) {
    if (num != undefined && numberOfDecimals != undefined && !isNaN(num)) {
        var multiplier = Math.pow(10, numberOfDecimals);
        var result = Math.round(num * multiplier) / multiplier
        return result.toFixed(numberOfDecimals);
    } else {
        return null;
    }
  }
});

routerApp.filter('ifEmpty', function() {
    return function(input, defaultValue) {
        if (angular.isUndefined(input) || input === null || input === '') {
            return defaultValue;
        }

        return input;
    }
});

routerApp.filter('ifValueAdd', function() {
  return function(input, defaultValue) {
      const ignoreArray = [null, '', '-', 'N/A', 'n/a'];
      if (angular.isUndefined(input) || ignoreArray.indexOf(input) > -1) {
          return input;
      }

      return input + defaultValue;
  }
});

routerApp.filter('withUnit', function() {
    return function(input, unit) {
        if (angular.isUndefined(input) || input === null || input === '') {
            return "-";
        }

        return input + " " + unit;
    }
});

routerApp.filter('ifPortStayIs0', function() {
    return function(input, defaultValue) {
        if (angular.isUndefined(input) || input === null || input === '' || input == 0) {
            return defaultValue;
        }

        if (input > 24) {
            input = input/24;
            return Math.round(input * 10) / 10 + " Days";
        } else {
            return input + " Hours";
        }
    }
});

routerApp.filter('showIfSeaOrManReport', function() {
    return function(input, entry) {
        if (entry.operation == 'Sea' || entry.operation == 'Maneuvering') {
            return input;
        } else {
            return "-";
        }
    };
});

routerApp.filter('showIfSeaReport', function() {
    return function(input, activity) {
        if (activity.report_type == 'Sea') {
            return input;
        } else {
            return "-";
        }
    };
});

routerApp.filter('parseDate', function() {
    return function(input, dateString) {
        return input && moment(input, dateString).toDate();
    };
});

routerApp.filter('removeEmptyValues', function() {
    return function(input: any[]) {
        return removeEmptyValues(input);
    };
});

routerApp.filter('addPositiveSign', function() {
    return function(input: number) {
        if (input > 0) {
            return '+' + input;
        } else {
            return input
        }
    };
});

routerApp.filter('dateToUTCString', function() {
    return function(input: MaybeDate, inputFormatString?: string, outputFormatString?: string) : string {
        return dateToUTCString(input, inputFormatString, outputFormatString);
    }
});

routerApp.filter('toCamelCase', () => (input: string) => _.camelCase(input));
routerApp.filter('toStartCase', () => (input: string) => _.startCase(input));
routerApp.filter('toSnakeCase', () => (input: string) => _.snakeCase(input));

Array.prototype.sum = function() {
    var values = this.filter(function(el) { return el !== undefined; });
    return values.reduce(function(x, y) {
        return x + y;
    }, 0);
};

Array.prototype.mean = function() {
    var values = this.filter(function(el) { return el !== undefined && el !== null; });
    if (values.length > 0) {
        return values.sum() / values.length;
    } else {
        return undefined;
    }
};

Array.prototype.max = function() {
  return Math.max.apply(null, this);
};

Array.prototype.min = function() {
  return Math.min.apply(null, this);
};

Array.prototype.standardDeviation = function() {
    var mean = this.mean();
    var squaredDifferences = this.map(function(value) {
        return Math.pow(value - mean, 2);
    });
    var differenceMean = squaredDifferences.mean();
    var sd = Math.sqrt(differenceMean);
    return sd;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function(predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}

let range = function(n) {
    if (!(n >= 0)) {
        throw new RangeError('Invalid array length: ' + n);
    }
    return Array.apply(null, Array(n)).map(function (_, i) {return i;});
};

Date.prototype.addDays = function(days) {
    var m = moment(this);
    return m.add(days * 24 * 60, 'minutes').toDate();
};

Array.prototype.groupBy = function(grouper) {
    var groups = {};
    angular.forEach(this, function(o) {
        var group = JSON.stringify(grouper(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);  
    });
    return Object.keys(groups).map(function(group) {
        return groups[group]; 
    });
};

const setTankGroupColors = function(tanks) {
    var colors = [
        '#b7c1fb',
        '#ff5310',
        '#bed060',
        '#348adf',
        '#ffdd7f',
        '#f7bfad',
    ].reverse();
  
  var tankGroups = tanks.filter((tank)=> tank != undefined).groupBy(function (tank) {
      return [tank?.bunker_date, tank?.bunker_port, tank?.fuel_grade];
    });
    tankGroups.sort(function(a, b) { return moment(a[0].bunker_date, 'DD-MM-YYYY') > moment(b[0].bunker_date, 'DD-MM-YYYY') ? 1 : -1; });
    angular.forEach(tankGroups, function(tanks) {
        if (tanks[0].bunker_date && tanks[0].bunker_port && tanks[0].fuel_grade) {
            var groupColor = colors.pop();
            angular.forEach(tanks, function(tank) {
                tank.group_color = groupColor;
            });
        }
    });
};

function flatDeep(arr, d) {
    return d > 0 ? arr.reduce(function(acc, val) { return acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val); }, [])
        : arr.slice();
}

Array.prototype.flatten = function() {
    return flatDeep(this, Infinity);
};

// Array.prototype.flat = function(depth) {
//     return flatDeep(this, depth || 1);
// };

Array.prototype.mapKey = function(key) {
    return this.map(function(item) { return item[key]; });
};

Array.prototype.last = function() {
    return this[this.length - 1];
};

const fuelGradeMapping = {
    'hshfo': 'HS HFO',
    'lshfo': 'LS HFO',
    'ulsfo': 'ULS FO',
    'hsmdo': 'HS MDO',
    'lsmdo': 'MDO',
    'hsmgo': 'MGO',
    'lsmgo': 'LS MGO',
};

const newFuelGradeMapping = {
    'hfo': 'HFO',
    'lfo': 'LFO',
    'mgo': 'MGO',
    'mdo': 'MDO',
    'b10lfo': 'B10LFO',
    'b10mgo': 'B10MGO',
    'biolfo': 'BioLFO',
    'biomgo': 'BioMGO',
    'ulsfo2020': 'ULSFO2020',
    'ulslfo2020': 'ULSLFO2020',
    'ulsmdo2020': 'ULSMDO2020',
    'ulsmgo2020': 'ULSMGO2020',
    'vlsfo2020': 'VLSFO2020',
    'vlslfo2020': 'VLSLFO2020',
    'lpgp': 'LPGP',
    'lpgb': 'LPGB',
    'lng': 'LNG',
    'methanol': 'Methanol',
    'ethanol': 'Ethanol',
    'other': 'Other',
};
const fuelGradeKeys = Object.keys(newFuelGradeMapping);

// very simple hash function
var hashCode = function(inputValue) {
    var hash = 0;
    if (inputValue.length == 0) {
        return hash;
    }
    for (var i = 0; i < inputValue.length; i++) {
        var char = inputValue.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}
var sumValues = function(newValues) {
    var sum = 0;
    for (var k = 0; k < newValues.length; k++) {
        if (newValues[k] != undefined) {
            sum += parseFloat(newValues[k]);
        }
    }
    return sum;
}

var roundToPlaces = function(num, decimalPlaces) {
    if (decimalPlaces === undefined || decimalPlaces === null) {
        throw 'Missing argument: decimalPlaces';
    }
    return Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
};

var getMEPerformanceData = function(performance) {
    var mePerformance = [];
    for (var i = 0; i < performance?.timestamp?.length; i++) {
        mePerformance.push([
            performance['timestamp'][i],
            performance['total_me_consumption_24'][i]
        ]);
    }
    return mePerformance;
}

var removeEmptyValues = function(values: any[]) {
    if (values && values.length > 0) {
        return values.filter(value => value)
    }
    return [];
};

var updatedCacheBust;
if (enableHybrid) {
    hybridService.channel.addEventListener('message', function(event) {
        if (event.data.type == 'refresh-browser') {
            // window.location.reload();
        }
        if (event.data.type == 'receive-cachebust') {
            updatedCacheBust = event.data.payload;
        }
    });
    hybridService.messageActiveServiceWorker({type: 'GET_CACHEBUST'}) // initialize updatedCacheBust to include main and vendor bundle
}

themePalette.setTheme(buildTheme)

export {
    routerApp as default,
    analyticsUrl,
    baseUrl,
    fuelGradeMapping,
    newFuelGradeMapping,
    fuelGradeKeys,
    reportRequestTimeout,
    environmentEnabled,
    getMEPerformanceData,
    range,
    roundToPlaces,
    routerApp,
    setTankGroupColors,
    sumValues,
    hashCode,
    updatedCacheBust,
    removeEmptyValues,
    themePalette,
    Color,
    appVersionCommit,
    appVersionDate,
    isQABuild,
    enableHybrid,
    appInsights,
    enableOnPremise,
};
