import Highcharts from 'highcharts';
import moment from 'moment';
import angular from 'angular';
import { routerApp, roundToPlaces, themePalette, Color, newFuelGradeMapping, fuelGradeKeys } from '../../app.module';
import Point from 'ol/geom/point';
import Feature from 'ol/feature';
import proj from 'ol/proj';

routerApp.controller('voyageAnalysisDetailsController', [ '$rootScope', '$scope', '$state', 'GraphService', 'VesselSpecificationService',
    function($rootScope, $scope, $state, GraphService, VesselSpecificationService) {

    if (!$rootScope.current_voyage) {
        $state.go('analytics.voyageAnalysis');
    }

    $scope.newFuelGradeMapping = newFuelGradeMapping;
    $scope.fuelGradeKeys = fuelGradeKeys;

    $rootScope.selectedMenu = 'voyage-report';
    $scope.pdf = true;
    $scope.currentVoyage = $rootScope.current_voyage;
    $scope.mapWaypointsByDate = {};

    $scope.isPortStay = function() {
        return $scope.currentVoyage.voyage_number && $scope.currentVoyage.voyage_number.startsWith('PORT');
    };

    $scope.refreshVoyageVesselSpecifications = function() {
        VesselSpecificationService.getVesselSpecifications($scope.currentVoyage.vessel_id).then(function(response) {
            var data = response.data;
            VesselSpecificationService.setSpecifications(data);
            var voyageVesselSpecs = VesselSpecificationService.getSpecifications();
            $scope.voyageVesselSpecs = voyageVesselSpecs;
        });
    }
    $scope.refreshVoyageVesselSpecifications();
    
    $scope.isDieselElectricPropulsion = function() {
        return $scope.voyageVesselSpecs?.information?.prime_mover_type=='diesel_electric_propulsion'
    }

    Array.prototype.fill = function (value, start, end) {
        if (!Array.isArray(this)) {
            throw new TypeError('this is not a Array');
        }

        var length = this.length;
        start = start || 0;
        end = end === undefined ? length : (end || 0);

        var i;
        var l;

        if (start < 0) {
            i = Math.max(length + start, 0);
        } else {
            i = Math.min(start, length);
        }

        if (end < 0) {
            l = Math.max(length + end, 0);
        } else {
            l = Math.min(end, length);
        }

        for (; i < l; i++) {
            this[i] = value;
        }

        return this;
    };

    $scope.$on('$stateChangeSuccess', function () {
        $('.wrapper').scrollTop(0);
    });

    var detailRound = function(num) {
        var n = 1;
        return Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
    };

    $scope.formatVoyageTime = function(hours) {
        if (!hours) { return '0d 0h'; }

        var days = (hours / 24).toFixed(10);
        var decimal = parseFloat('.' + days.split('.')[1] + '0');
        var remainingHours = decimal * 24;

        return Math.floor(parseFloat(days)) + 'd ' + Math.round(remainingHours) + 'h';
    };

    $scope.formatCargoTime = function(hours) {
        if (!hours) return '0h';

        var days = (hours / 24).toFixed(10);
        var daysDecimal = parseFloat('.' + days.split('.')[1] + '0');
        var remainingHours = (daysDecimal * 24).toFixed(10);
        var hoursDecimal = parseFloat('.' + remainingHours.split('.')[1] + '0');
        var remainingMinutes = (hoursDecimal * 60);

        var daysPortion = Math.round(parseFloat(days)) > 0 ? Math.round(parseFloat(days)) + 'd ' : '';

        return daysPortion + Math.round(parseFloat(remainingHours)) + 'h ' + Math.round(remainingMinutes) + 'm';
    };

    var variables = [
        'report_number',
        '_cls',
        // weather factors
        'position.aggregated.wave_height',
        'position.aggregated.swell',
        'position.aggregated.true_wind_force',
        'position.aggregated.relative_wind_speed',
        'position.aggregated.current_speed',

        // voyage data
        'operational.report_from',
        'operational.report_to',
        'operational.report_period',
        'operational.eta',
        'operational.eosp',
        // ship lat lon hours
        'position.ship_lat_hours',
        'position.ship_lat_minutes',
        'position.ship_lat_seconds',
        'position.ship_lat_direction',
        'position.ship_lon_hours',
        'position.ship_lon_minutes',
        'position.ship_lon_seconds',
        'position.ship_lon_direction',
        // average speed
        'position.aggregated.observed_speed',
        'position.aggregated.log_speed',
        // total distance
        'position.aggregated.observed_distance',
        'position.aggregated.log_distance',
        'position.aggregated.true_wind_force',
        'position.aggregated.true_wind_speed',
        'position.aggregated.true_wind_direction',
        'consumption.total_me_consumption',
        'consumption.total_ae_consumption',
        'consumption.total_fuel_consumption',
        // todo: need slip
        'computed.mileage',
        'computed.model_mileage',

        // voyage performance analysis
        'computed.speed_performance',
        'consumption.total_me_consumption_24',
        'consumption.total_ae_consumption_24',
        'computed.sfoc_lcv_corrected',
        'computed.power',
        'computed.fuel_24',
        'computed.total_fuel_24',
        'computed.model_fuel',
        'computed.model_fuel_24',
        'operational.cp_speed',
        'computed.total_cp_fuel',
        'computed.me_cp_fuel',
        'computed.ae_cp_fuel',
        'computed.model_speed_calm_water',

        'consumption.main_engine_hshfo',
        'consumption.main_engine_lshfo',
        'consumption.main_engine_ulsfo',
        'consumption.main_engine_hsmdo',
        'consumption.main_engine_lsmdo',
        'consumption.main_engine_hsmgo',
        'consumption.main_engine_lsmgo',
        'consumption.main_engine_hfo',
        'consumption.main_engine_lfo',
        'consumption.main_engine_mgo',
        'consumption.main_engine_mdo',
        'consumption.main_engine_b10lfo',
        'consumption.main_engine_b10mgo',
        'consumption.main_engine_biolfo',
        'consumption.main_engine_biomgo',
        'consumption.main_engine_ulsfo2020',
        'consumption.main_engine_ulslfo2020',
        'consumption.main_engine_ulsmdo2020',
        'consumption.main_engine_ulsmgo2020',
        'consumption.main_engine_vlsfo2020',
        'consumption.main_engine_vlslfo2020',
        'consumption.main_engine_lpgp',
        'consumption.main_engine_lpgb',
        'consumption.main_engine_propane',
        'consumption.main_engine_butane',
        'consumption.main_engine_lng',
        'consumption.main_engine_methanol',
        'consumption.main_engine_ethanol',
        'consumption.main_engine_other',

        'consumption.aux_engine_hshfo',
        'consumption.aux_engine_lshfo',
        'consumption.aux_engine_ulsfo',
        'consumption.aux_engine_hsmdo',
        'consumption.aux_engine_lsmdo',
        'consumption.aux_engine_hsmgo',
        'consumption.aux_engine_lsmgo',
        'consumption.aux_engine_hfo',
        'consumption.aux_engine_lfo',
        'consumption.aux_engine_mgo',
        'consumption.aux_engine_mdo',
        'consumption.aux_engine_b10lfo',
        'consumption.aux_engine_b10mgo',
        'consumption.aux_engine_biolfo',
        'consumption.aux_engine_biomgo',
        'consumption.aux_engine_ulsfo2020',
        'consumption.aux_engine_ulslfo2020',
        'consumption.aux_engine_ulsmdo2020',
        'consumption.aux_engine_ulsmgo2020',
        'consumption.aux_engine_vlsfo2020',
        'consumption.aux_engine_vlslfo2020',
        'consumption.aux_engine_lpgp',
        'consumption.aux_engine_lpgb',
        'consumption.aux_engine_propane',
        'consumption.aux_engine_butane',
        'consumption.aux_engine_lng',
        'consumption.aux_engine_methanol',
        'consumption.aux_engine_ethanol',
        'consumption.aux_engine_other',

        'consumption.aux_boiler_hshfo',
        'consumption.aux_boiler_lshfo',
        'consumption.aux_boiler_ulsfo',
        'consumption.aux_boiler_hsmdo',
        'consumption.aux_boiler_lsmdo',
        'consumption.aux_boiler_hsmgo',
        'consumption.aux_boiler_lsmgo',
        'consumption.aux_boiler_hfo',
        'consumption.aux_boiler_lfo',
        'consumption.aux_boiler_mgo',
        'consumption.aux_boiler_mdo',
        'consumption.aux_boiler_b10lfo',
        'consumption.aux_boiler_b10mgo',
        'consumption.aux_boiler_biolfo',
        'consumption.aux_boiler_biomgo',
        'consumption.aux_boiler_ulsfo2020',
        'consumption.aux_boiler_ulslfo2020',
        'consumption.aux_boiler_ulsmdo2020',
        'consumption.aux_boiler_ulsmgo2020',
        'consumption.aux_boiler_vlsfo2020',
        'consumption.aux_boiler_vlslfo2020',
        'consumption.aux_boiler_lpgp',
        'consumption.aux_boiler_lpgb',
        'consumption.aux_boiler_propane',
        'consumption.aux_boiler_butane',
        'consumption.aux_boiler_lng',
        'consumption.aux_boiler_methanol',
        'consumption.aux_boiler_ethanol',
        'consumption.aux_boiler_other',

        'consumption.ig_generator_hshfo',
        'consumption.ig_generator_lshfo',
        'consumption.ig_generator_ulsfo',
        'consumption.ig_generator_hsmdo',
        'consumption.ig_generator_lsmdo',
        'consumption.ig_generator_hsmgo',
        'consumption.ig_generator_lsmgo',
        'consumption.ig_generator_hfo',
        'consumption.ig_generator_lfo',
        'consumption.ig_generator_mgo',
        'consumption.ig_generator_mdo',
        'consumption.ig_generator_b10lfo',
        'consumption.ig_generator_b10mgo',
        'consumption.ig_generator_biolfo',
        'consumption.ig_generator_biomgo',
        'consumption.ig_generator_ulsfo2020',
        'consumption.ig_generator_ulslfo2020',
        'consumption.ig_generator_ulsmdo2020',
        'consumption.ig_generator_ulsmgo2020',
        'consumption.ig_generator_vlsfo2020',
        'consumption.ig_generator_vlslfo2020',
        'consumption.ig_generator_lpgp',
        'consumption.ig_generator_lpgb',
        'consumption.ig_generator_propane',
        'consumption.ig_generator_butane',
        'consumption.ig_generator_lng',
        'consumption.ig_generator_methanol',
        'consumption.ig_generator_ethanol',
        'consumption.ig_generator_other',

        'consumption.total_consumption_hshfo',
        'consumption.total_consumption_lshfo',
        'consumption.total_consumption_ulsfo',
        'consumption.total_consumption_hsmdo',
        'consumption.total_consumption_lsmdo',
        'consumption.total_consumption_hsmgo',
        'consumption.total_consumption_lsmgo',
        'consumption.total_consumption_hfo',
        'consumption.total_consumption_lfo',
        'consumption.total_consumption_mgo',
        'consumption.total_consumption_mdo',
        'consumption.total_consumption_b10lfo',
        'consumption.total_consumption_b10mgo',
        'consumption.total_consumption_biolfo',
        'consumption.total_consumption_biomgo',
        'consumption.total_consumption_ulsfo2020',
        'consumption.total_consumption_ulslfo2020',
        'consumption.total_consumption_ulsmdo2020',
        'consumption.total_consumption_ulsmgo2020',
        'consumption.total_consumption_vlsfo2020',
        'consumption.total_consumption_vlslfo2020',
        'consumption.total_consumption_lpgp',
        'consumption.total_consumption_lpgb',
        'consumption.total_consumption_propane',
        'consumption.total_consumption_butane',
        'consumption.total_consumption_lng',
        'consumption.total_consumption_methanol',
        'consumption.total_consumption_ethanol',
        'consumption.total_consumption_other',

        'consumption.total_ae_consumption',
        'consumption.total_boiler_consumption',

        'consumption.fuel_type_1',
        'consumption.fuel_type_2',
        'consumption.fuel_type_3',

        'computed.model_sfoc',
        'computed.excess_consumption',
        'computed.co2_efficiency',
        'computed.ae_excess_consumption',
        'computed.boiler_excess_consumption',
        'computed.vpi',
        'computed.me_load',
        'power.n_aux_engines_running',
        'power.n_diesel_gens_running',
        'operational.destination_port',
        'cargo.bl_quantity',
        'operational.commence',
        'operational.finish',
        'cargo.operation_type',
        'cargo.load_rate',
        'cargo.bl_quantity',
        'cargo.surveyors_cargo_quantity',
        'cargo.cargo_grade',
        'computed.matches_baseline_conditions',
        'computed.performance_speed',
        'computed.meets_cp_speed',
        'computed.meets_cp_consumption',
    ];

    $scope.dateRanges = ['voyage'];
    $scope.selectedDateRange = 'voyage';

    $scope.after = new Date($rootScope.current_voyage.departure_date + 'Z').toISOString();
    $scope.before = $rootScope.current_voyage.status == 'ongoing' ? null : new Date($rootScope.current_voyage.arrival_date + 'Z').toISOString();

    var reportTypes = $scope.isPortStay() ? ['Report.PortReport', 'Report.AnchorReport', 'Report.ManeuveringReport'] : ['Report.SeaReport'];

    GraphService.getGraphsBetweenDates($scope.currentVoyage.vessel_id, variables, $scope.after, $scope.before, reportTypes).then(function(res) {
        var response = res.data;
        $scope.voyage = response.data['voyage'];
        $scope.newMileage = response.data['voyage']['mileage'];
        var timestamps = response.data['voyage']['timestamp'];
        $scope.newTimeStamp = [];
        $scope.actualTimeStamp = [];
        for (var i = 0; i < timestamps.length; i++) {
            $scope.newTimeStamp.push((new Date(timestamps[i])).toLocaleDateString());
            $scope.actualTimeStamp.push((new Date(timestamps[i])));
        }
        // $scope.newTimeStamp = response.data['voyage']['timestamp'];
        $scope.newIdealMileage = response.data['voyage']['model_mileage'];
        $scope.bf = response.data['voyage']['true_wind_force'];
        $scope.observedSpeed = response.data['voyage']['observed_speed'];
        $scope.observedSpeed.length = response.data['voyage']['total_me_consumption'].length;
        $scope.swell = response.data['voyage']['swell'];
        $scope.waveHeight = response.data['voyage']['wave_height'];
        $scope.relativeWindSpeed = response.data['voyage']['relative_wind_speed'];
        $scope.currentSpeed = response.data['voyage']['current_speed'];
        $scope.modelSpeedCalmWater = response.data['voyage']['model_speed_calm_water'];
        response.data['voyage']['model_fuel'].length = response.data['voyage']['total_me_consumption'].length;
        $scope.calmWaterConsumption = response.data['voyage']['model_fuel'];
        $scope.charterPartyConsumption = response.data['voyage']['me_cp_fuel'];
        $scope.charterPartySpeed = response.data['voyage']['cp_speed'];
        $scope.vpi = response.data['voyage']['vpi'];
        $scope.numAuxEngines = response.data['voyage']['n_aux_engines_running'];
        if ($scope.isDieselElectricPropulsion() == true) $scope.numAuxEngines = response.data['voyage']['n_diesel_gens_running'];
        $scope.meExcessConsumption = response.data['voyage']['excess_consumption'];
        $scope.aeExcessConsumption = response.data['voyage']['ae_excess_consumption'];
        $scope.boilerExcessConsumption = response.data['voyage']['boiler_excess_consumption'];
        $scope.totalBoilerConsumption = response.data['voyage']['total_boiler_consumption'];
        $scope.meLoad = response.data['voyage']['me_load'];
        $scope.charterPartyConsAvg = $scope.voyage.me_cp_fuel
            .map(function(meCpFuel, i) { return ((meCpFuel + $scope.voyage.ae_cp_fuel[i]) || 0) * $scope.voyage.report_period[i]; })
            .sum() / $scope.voyage.report_period.sum();
        $scope.charterPartySpeedAvg = $scope.voyage.cp_speed
            .map(function(val, i) { return val * $scope.voyage.report_period[i]; })
            .sum() / $scope.voyage.report_period.sum();

        let total_igg_consumption: number[] = $scope.voyage.report_period.map(function(_, i) {
            return fuelGradeKeys.map((fg) => $scope.voyage[`ig_generator_${fg}`][i]).sum();
        });
        $scope.voyage['total_igg_consumption'] = total_igg_consumption;
        let total_ig_generator: number  = total_igg_consumption.sum()
        $scope.voyage['total_ig_generator'] = total_ig_generator;

        fuelGradeKeys.forEach((fg) => {
            $scope.voyage[`total_voyage_consumption_${fg}`] = $scope.voyage[`total_consumption_${fg}`].sum();

            let allOther = $scope.voyage[`total_consumption_${fg}`].map((totalCons, i) => {
                return totalCons - $scope.voyage[`main_engine_${fg}`][i] - $scope.voyage[`aux_engine_${fg}`][i] - $scope.voyage[`aux_boiler_${fg}`][i];
            });
            $scope.voyage[`all_other_${fg}`] = allOther;
        });

        $scope.voyage['total_all_other'] = $scope.voyage['total_fuel_consumption'].sum() - $scope.voyage['total_me_consumption'].sum() - $scope.voyage['total_ae_consumption'].sum() - $scope.voyage['total_boiler_consumption'].sum();
        $scope.voyage['total_all_other_consumption'] = $scope.voyage.report_period.map(function(_, i) {
            return $scope.voyage['total_fuel_consumption'][i] - $scope.voyage['total_me_consumption'][i] - $scope.voyage['total_ae_consumption'][i] - $scope.voyage['total_boiler_consumption'][i];
        });

        if ($scope.isPortStay()) {
            fuelGradeKeys.forEach((fg) => {
                let iggCons = $scope.voyage[`ig_generator_${fg}`].sum();
                if (iggCons > 0) {
                    $scope.voyage[`all_other_${fg}`] -= iggCons;
                }
            });

            $scope.voyage['total_all_other'] -= $scope.voyage['total_igg_consumption'].sum();
            $scope.voyage.report_period.map(function(_, i) {
                $scope.voyage['total_all_other_consumption'][i] -= $scope.voyage['total_igg_consumption'][i];
            });
        }

        // sfoc
        $scope.sfocData = [];
        var sfocSeries = response.data['voyage']['sfoc_lcv_corrected'];
        var powerSeries = response.data['voyage']['power'];
        for (var i = 0; i < sfocSeries.length; i++) {
            $scope.sfocData.push([powerSeries[i], sfocSeries[i]]);
        }

        // time series data:
        var processedData = GraphService.getTimeSeriesData(response.data, $scope.dateRanges, variables);
        $scope.processedData = processedData;

        // speed performance
        $scope.speedPerformanceData = processedData[$scope.selectedDateRange]['speed_performance'];
        // ae consumption / 24 hours
        $scope.aeConsumption24 = processedData[$scope.selectedDateRange]['total_ae_consumption_24'];

        $scope.total_me_consumption_24 = processedData[$scope.selectedDateRange]['total_me_consumption_24'];
        var total_me_consumption_24_values = $scope.total_me_consumption_24.map(function(pair) {
            var value = pair[1];
            return value;
        });

        // total distance / total period
        $scope.averageConsumptionData = total_me_consumption_24_values.sum() / total_me_consumption_24_values.length;

        // mileage
        $scope.mileage = processedData[$scope.selectedDateRange]['mileage'];
        $scope.modelMileage = processedData[$scope.selectedDateRange]['model_mileage'];

        if ($scope.isPortStay()) {
            let cargoType = (cargo) => {
                return cargo.replace(/\s+/g, '').toLowerCase();
            }
            var reports = response.data['voyage']['_cls'];
            var firstPortReport = -1;
            var lastPortReport = -1;
            for (var i = 0; i < reports.length; i++) {
                if ('Report.PortReport' !== reports[i])
                    continue;
                if (firstPortReport === -1)
                    firstPortReport = i;
                lastPortReport = i;
            }
            if ((firstPortReport !== -1) || (firstPortReport != lastPortReport)) {
                var cargoFirst = response.data['voyage']['surveyors_cargo_quantity'][firstPortReport];
                var cargoGradeFirst = response.data['voyage']['cargo_grade'][firstPortReport];
                var totalQuantityLoaded = 0;
                var totalQuantityDischarged = 0;

                var map = {};
                for (var i = 0; i < cargoGradeFirst.length; i++) {
                    map[i] = false;
                }

                for (var i = lastPortReport; i > firstPortReport; i--) {
                    if ('Report.PortReport' == reports[i]) {
                        var cargoGradeLast = response.data['voyage']['cargo_grade'][i]
                        var cargoLast = response.data['voyage']['surveyors_cargo_quantity'][i];
                        var blQuantity = response.data['voyage']['bl_quantity'][i];
                        for (var j = 0; j < cargoGradeFirst.length; j++) {
                            for (var k = 0; k < cargoGradeLast.length; k++) {
                                if (map[j] == true)
                                    break;
                                if (cargoType(cargoGradeFirst[j]) == cargoType(cargoGradeLast[k])) {
                                    if (cargoFirst[j] < cargoLast[k]) {
                                        totalQuantityLoaded += blQuantity[k];
                                    } else if (cargoFirst[j] > cargoLast[k]) {
                                        totalQuantityDischarged += blQuantity[k];
                                    }
                                    map[j] = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                $scope.bl_quantity_total_loaded = totalQuantityLoaded;
                $scope.bl_quantity_total_discharged = totalQuantityDischarged;
            } else {
                $scope.bl_quantity_total_loaded = 0;
                $scope.bl_quantity_total_discharged = 0;
            }
        }

        $scope.duration_cargo_operation = response.data['voyage']['finish'].map(function(finish, i) {
            var commence = response.data['voyage']['commence'][i];
            if (finish && commence) {
                var ms = moment(finish).toDate().getTime() - moment(commence).toDate().getTime();
                return ms / 1000 / 60 / 60;
            } else {
                return 0;
            }
        }).sum();

        $scope.voyage['port_duration'] = 0;
        $scope.voyage['port_percent_total_time'] = 0;
        $scope.voyage['port_distance'] = 0;
        $scope.voyage['port_me_cons'] = 0;
        $scope.voyage['port_ae_cons'] = 0;
        $scope.voyage['port_boiler_cons'] = 0;
        $scope.voyage['port_igg_cons'] = 0;
        $scope.voyage['port_all_other_cons'] = 0;
        $scope.voyage['port_total_cons'] = 0;
        $scope.voyage['maneuvering_duration'] = 0;
        $scope.voyage['maneuvering_percent_total_time'] = 0;
        $scope.voyage['maneuvering_distance'] = 0;
        $scope.voyage['maneuvering_me_cons'] = 0;
        $scope.voyage['maneuvering_ae_cons'] = 0;
        $scope.voyage['maneuvering_boiler_cons'] = 0;
        $scope.voyage['maneuvering_igg_cons'] = 0;
        $scope.voyage['maneuvering_all_other_cons'] = 0;
        $scope.voyage['maneuvering_total_cons'] = 0;
        $scope.voyage['anchor_duration'] = 0;
        $scope.voyage['anchor_percent_total_time'] = 0;
        $scope.voyage['anchor_distance'] = 0;
        $scope.voyage['anchor_me_cons'] = 0;
        $scope.voyage['anchor_ae_cons'] = 0;
        $scope.voyage['anchor_boiler_cons'] = 0;
        $scope.voyage['anchor_igg_cons'] = 0;
        $scope.voyage['anchor_all_other_cons'] = 0;
        $scope.voyage['anchor_total_cons'] = 0;

        $scope.totalDuration = $scope.voyage.report_period.sum();

        $scope.voyage['_cls'].forEach(function(reportType, i) {
            var type = reportType.replace(/Report\.?/g, '').toLowerCase();
            $scope.voyage[type + '_duration'] += $scope.voyage['report_period'][i];
            $scope.voyage[type + '_distance'] += $scope.voyage['observed_distance'][i];
            $scope.voyage[type + '_me_cons'] += $scope.voyage['total_me_consumption'][i];
            $scope.voyage[type + '_ae_cons'] += $scope.voyage['total_ae_consumption'][i];
            $scope.voyage[type + '_boiler_cons'] += $scope.voyage['total_boiler_consumption'][i];
            $scope.voyage[type + '_igg_cons'] += $scope.voyage['total_igg_consumption'][i];
            $scope.voyage[type + '_all_other_cons'] += $scope.voyage['total_all_other_consumption'][i];
            $scope.voyage[type + '_total_cons'] += $scope.voyage['total_fuel_consumption'][i];
        });

        $scope.voyage['port_percent_total_time'] = $scope.voyage['port_duration'] / $scope.totalDuration * 100;
        $scope.voyage['maneuvering_percent_total_time'] = $scope.voyage['maneuvering_duration'] / $scope.totalDuration * 100;
        $scope.voyage['anchor_percent_total_time'] = $scope.voyage['anchor_duration'] / $scope.totalDuration * 100;

        var voyageReportMapData = { coordinates: undefined };
        var voyageReportData = [];
        var seriesData = response.data['voyage'];
        for (var i = 0; i < seriesData['timestamp'].length; i++) {
            voyageReportData.push({
                report_to: seriesData['timestamp'][i],
                report_period: seriesData['report_period'][i],
                ship_lat: convertDMSToDD(
                    seriesData['ship_lat_hours'][i], 
                    seriesData['ship_lat_minutes'][i],
                    seriesData['ship_lat_seconds'][i],
                    seriesData['ship_lat_direction'][i]
                    ),
                ship_lon: convertDMSToDD(
                    seriesData['ship_lon_hours'][i], 
                    seriesData['ship_lon_minutes'][i],
                    seriesData['ship_lon_seconds'][i],
                    seriesData['ship_lon_direction'][i]
                    ),
                observed_speed: seriesData['observed_speed'][i],
                observed_distance: seriesData['observed_distance'][i],
                true_wind_force: seriesData['true_wind_force'][i],
                true_wind_speed: seriesData['true_wind_speed'][i],
                true_wind_direction: seriesData['true_wind_direction'][i],
                total_me_consumption: seriesData['total_me_consumption'][i],
                total_fuel_consumption: seriesData['total_fuel_consumption'][i],
                mileage: seriesData['mileage'][i],
                eta: seriesData['eta'][i],
                total_me_consumption_24: seriesData['total_me_consumption_24'][i]

            });
        }
        voyageReportMapData.coordinates = voyageReportData;
        $scope.voyageReportData = voyageReportData;

        $scope.goodWeatherDefinitions = [{
            as_per_charter_party: true,
            getDisplayName: undefined
        }, {
            bf: 4,
            wave_height: undefined,
        }, {
            bf: 4,
            wave_height: 1.25
        }, {
            bf: 4,
            wave_height: 2,
        }, {
            bf: 4,
            wave_height: 3,
        }, {
            bf: 5,
            wave_height: undefined,
        }, {
            bf: 5,
            wave_height: 1.25,
        }, {
            bf: 5,
            wave_height: 2,
        }].map(function(def) {
            def.getDisplayName = function() {
                var params = [];
                if (this.as_per_charter_party) params.push('As per Charter Party (default)');
                if (this.bf != undefined) params.push('Beaufort ' + this.bf);
                if (this.wave_height != undefined) params.push('Wave Height ' + this.wave_height + 'm');
                return params.join(', ');
            };
            return def;
        });
        $scope.goodWeatherDefinition = $scope.goodWeatherDefinitions[0];
        $rootScope.drawMap(voyageReportMapData, {targetMapElementId: 'voyageMap', labelElementId: 'popup' });
        $scope.setMapWaypointsByDate(voyageReportMapData);
        $scope.drawCharts();
    });

    $scope.$watch('goodWeatherDefinition', function(newValue) {
        if (newValue) {
            var goodWeatherDef = newValue;
            $scope.totalGoodWeatherConsumption = 0;
            $scope.totalGoodWeatherGPSDistance = 0;
            $scope.totalGoodWeatherLogDistance = 0;
            $scope.totalGoodWeatherReportPeriod = 0;
            $scope.goodWeatherReportNumbers = [];
            $scope.voyage.good_weather = [];
            angular.forEach($scope.voyage.total_me_consumption, function(meCons, i) {
                var goodWeather = goodWeatherDef.as_per_charter_party
                    ? $scope.voyage.matches_baseline_conditions[i]
                    : (!goodWeatherDef.bf || $scope.voyage.true_wind_force[i] <= goodWeatherDef.bf) && (!goodWeatherDef.wave_height || $scope.voyage.wave_height[i] <= goodWeatherDef.wave_height);
                $scope.voyage.good_weather[i] = goodWeather;
                if (goodWeather) {
                    var aeCons = $scope.voyage.total_ae_consumption[i] || 0;
                    var cons = (meCons || 0) + aeCons;
                    $scope.totalGoodWeatherConsumption += cons;
                    $scope.totalGoodWeatherGPSDistance += $scope.voyage.observed_distance[i] || 0;
                    $scope.totalGoodWeatherLogDistance += $scope.voyage.log_distance[i] || 0;
                    $scope.totalGoodWeatherReportPeriod += $scope.voyage.report_period[i] || 0;
                    $scope.goodWeatherReportNumbers.push($scope.voyage.report_number[i]);
                }
            });
            $scope.goodWeatherConsAvg = $scope.totalGoodWeatherConsumption / $scope.totalGoodWeatherReportPeriod * 24;
            $scope.goodWeatherGPSSpeedAvg = $scope.totalGoodWeatherGPSDistance / $scope.totalGoodWeatherReportPeriod;
            $scope.goodWeatherLogSpeedAvg = $scope.totalGoodWeatherLogDistance / $scope.totalGoodWeatherReportPeriod;

            $scope.voyage.total_fuel_consumption_24 = $scope.voyage.total_fuel_consumption.map(function(totalFuel, i) {
                var reportPeriod = $scope.voyage.report_period[i];
                return totalFuel / reportPeriod * 24;
            });

            $scope.cpFuelDataSeries = $scope.voyage.total_cp_fuel.map(function(fuel, i) {
                return fuel != undefined ? roundToPlaces(fuel, 1) : null;
            });

            $scope.totalConsumptionGraphData = [{
                name: 'Reported Consumption',
                data: [],
                color: themePalette.colors.GRAPH_BLUE,
            }, {
                name: 'Excess Consumption',
                data: [],
                color: themePalette.colors.RED,
            }, {
                name: 'Bad Weather (not analyzed)',
                data: [],
                color: '#AAA',
            }, {
                name: 'Charter Party Consumption',
                data: $scope.cpFuelDataSeries,
                color: themePalette.colors.CHARTER_PARTY_DATA,
                type: 'line',
            }];

            angular.forEach($scope.voyage.total_fuel_24.map(detailRound), function(cons, i) {
                var aboveCPCons = $scope.cpFuelDataSeries[i] > 0 && cons > $scope.cpFuelDataSeries[i];
                if (!$scope.isGoodWeatherAtIndex(i)) {
                    // '#AAA';
                    $scope.totalConsumptionGraphData[0].data.push(null);
                    $scope.totalConsumptionGraphData[1].data.push(null);
                    $scope.totalConsumptionGraphData[2].data.push(cons);
                } else if (aboveCPCons) {
                    // Color.RED;
                    $scope.totalConsumptionGraphData[0].data.push(null);
                    $scope.totalConsumptionGraphData[1].data.push(cons);
                    $scope.totalConsumptionGraphData[2].data.push(null);
                } else {
                    // Color.GRAPH_BLUE;
                    $scope.totalConsumptionGraphData[0].data.push(cons);
                    $scope.totalConsumptionGraphData[1].data.push(null);
                    $scope.totalConsumptionGraphData[2].data.push(null);
                }
            });

            $scope.constantPowerChart = Highcharts.chart('chart-voyage-constant-power', {
                chart: {
                    type: 'column'
                },
                plotOptions: {
                    // line: { marker: { enabled: false, states: { hover: { enabled: false } } } },
                    column: { grouping: false },
                },
                title: { text: null },
                subtitle: { text: 'Total (ME+AE) Consumption / 24h (MT)' },
                yAxis: {
                    title: { text: 'MT / 24h' },
                    min: Math.min.apply(Math, $scope.voyage.fuel_24) * 0.2
                },
                xAxis: { categories: $scope.newTimeStamp },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormatter: function() {
                        if (this.series.name == "Charter Party Consumption") return;
                        var pt = this;
                        var tooltipHTML = '';
                        var makeTooltipRowHTML = function(name, value, unit, color) {
                            return '<tr><td style="padding:0; color: #000"><span style="color:' + color + '">\u25CF</span>' + name + ': </td>' +
                                '<td style="padding:0; color: #000"><b>' + value + (unit && unit[0] === '%' ? '' : ' ') + (unit || '') + '</b></td></tr>';
                        };
                        angular.forEach(this.series.chart.series, function(s) {
                            var y = s.data[pt.x].y;
                            if (y != undefined) {
                                let name = s.name;
                                if (pt.series.name == 'Excess Consumption' && s.name == 'Excess Consumption') {
                                    name = 'Reported Consumption';
                                }
                                tooltipHTML += makeTooltipRowHTML(name, roundToPlaces(y, 1), 'MT', s.color);
                            }
                        });
                        if (this.series.name == 'Excess Consumption') {
                            let totalConsPoint = this.series.chart.series[1]?.data[pt.x]?.y;
                            let cpConsPoint = this.series.chart.series[3]?.data[pt.x]?.y;
                            if (totalConsPoint != undefined && cpConsPoint != undefined) {
                                let y = totalConsPoint - cpConsPoint;
                                tooltipHTML += makeTooltipRowHTML('Excess Consumption', roundToPlaces(y, 1), 'MT', '#AC0000');
                            }
                        }
                        return tooltipHTML;
                    },
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                credits: { enabled: false },
                exporting: { enabled: false },
                series: $scope.totalConsumptionGraphData,
                legend: { enabled: true }
            });

            $scope.meCpConsumptionGraphData = [{
                name: 'Reported Consumption',
                color: themePalette.colors.GRAPH_BLUE,
                data: [],
            }, {
                name: 'Excess Consumption',
                color: themePalette.colors.RED,
                data: [],
            }, {
                name: 'Bad Weather Consumption (not analyzed)',
                color: '#AAA',
                data: [],
            }, {
                name: 'Charter Party Consumption',
                type: 'line',
                color: themePalette.isDarkTheme() ? themePalette.colors.DATA_2 : Color.BLACK,
                data: $scope.charterPartyConsumption
            }];


            angular.forEach($scope.voyage.fuel_24.map(detailRound), function(cons, i){
                var aboveCPCons = $scope.charterPartyConsumption[i] > 0 && cons > $scope.charterPartyConsumption[i];
                if (!$scope.isGoodWeatherAtIndex(i)) {
                    // '#AAA';
                    $scope.meCpConsumptionGraphData[0].data.push(null);
                    $scope.meCpConsumptionGraphData[1].data.push(null);
                    $scope.meCpConsumptionGraphData[2].data.push(cons);
                } else if (aboveCPCons) {
                    // Color.RED;
                    $scope.meCpConsumptionGraphData[0].data.push(null);
                    $scope.meCpConsumptionGraphData[1].data.push(cons);
                    $scope.meCpConsumptionGraphData[2].data.push(null);
                } else {
                    // Color.GRAPH_BLUE;
                    $scope.meCpConsumptionGraphData[0].data.push(cons);
                    $scope.meCpConsumptionGraphData[1].data.push(null);
                    $scope.meCpConsumptionGraphData[2].data.push(null);
                }
            });
            $scope.normalizedMEConsumptionChart = Highcharts.chart('chart-normalized-me-consumption', {
                chart: { type: 'column' },
                title: { text: null },
                subtitle: { text: 'ME Consumption / 24h (MT)'},
                xAxis: { categories: $scope.newTimeStamp },
                yAxis: { title: { text: 'MT/24h'}, allowDecimals: false },
                credits: { enabled: false },
                exporting: { enabled: false },
                plotOptions: {
                    column: { grouping: false },
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormatter: function() {
                        if (this.series.name == "Charter Party Consumption") return;
                        var pt = this;
                        var tooltipHTML = '';
                        var makeTooltipRowHTML = function(name, value, unit, color) {
                            return '<tr><td style="padding:0; color: #000"><span style="color:' + color + '">\u25CF</span>' + name + ': </td>' +
                                '<td style="padding:0; color: #000"><b>' + value + (unit && unit[0] === '%' ? '' : ' ') + (unit || '') + '</b></td></tr>';
                        };
                        angular.forEach(this.series.chart.series, function(s) {
                            var y = s.data[pt.x].y;
                            if (y != undefined) {
                                let name = s.name;
                                if (pt.series.name == 'Excess Consumption' && s.name == 'Excess Consumption') {
                                    name = 'Reported Consumption';
                                }
                                tooltipHTML += makeTooltipRowHTML(name, roundToPlaces(y, 1), 'MT', s.color);
                            }
                        });
                        if (this.series.name == 'Excess Consumption') {
                            let meCons = this.series.chart.series[1]?.data[pt.x]?.y;
                            let cpConsPoint = this.series.chart.series[3]?.data[pt.x]?.y;
                            if (meCons != undefined && cpConsPoint != undefined) {
                                let y = meCons - cpConsPoint;
                                tooltipHTML += makeTooltipRowHTML('Excess Consumption', roundToPlaces(y, 1), 'MT', '#AC0000');
                            }
                        }
                        return tooltipHTML;
                    },
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                series: $scope.meCpConsumptionGraphData,
            });

            $scope.cpSpeedGraphData = [{
                name: 'Performance Speed',
                color: themePalette.colors.GRAPH_BLUE,
                data: [],
            }, {
                name: 'Speed Shortfall',
                color: themePalette.colors.RED,
                data: [],
            }, {
                name: 'Bad Weather Speed (not analyzed)',
                color: '#AAA',
                data: [],
            }, {
                name: 'Charter Party Speed',
                type: 'line',
                color: themePalette.isDarkTheme() ? themePalette.colors.DATA_2 : Color.BLACK,
                data: $scope.charterPartySpeed
            }];

            angular.forEach($scope.voyage.performance_speed.map(detailRound), function(speed, i){
                    var belowCPSpeed = $scope.voyage.meets_cp_speed[i] === false;
                    if (!$scope.isGoodWeatherAtIndex(i)) {
                        // '#AAA';
                        $scope.cpSpeedGraphData[0].data.push(null);
                        $scope.cpSpeedGraphData[1].data.push(null);
                        $scope.cpSpeedGraphData[2].data.push(speed);
                    } else if (belowCPSpeed) {
                        // Color.RED;
                        $scope.cpSpeedGraphData[0].data.push(null);
                        $scope.cpSpeedGraphData[1].data.push(speed);
                        $scope.cpSpeedGraphData[2].data.push(null);
                    } else {
                        // Color.GRAPH_BLUE;
                        $scope.cpSpeedGraphData[0].data.push(speed);
                        $scope.cpSpeedGraphData[1].data.push(null);
                        $scope.cpSpeedGraphData[2].data.push(null);
                    }
            });

            $scope.charterPartySpeedGraph = Highcharts.chart('chart-voyage-ship', {
                chart: { type: 'column' },
                title: { text: null },
                subtitle: { text: "Performance Speed (kn)" },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormatter: function() {
                        if (this.series.name == "Charter Party Speed") return;
                        var pt = this;
                        var tooltipHTML = '';
                        var makeTooltipRowHTML = function(name, value, unit, color) {
                            return '<tr><td style="padding:0; color: #000"><span style="color:' + color + '">\u25CF</span>' + name + ': </td>' +
                                '<td style="padding:0; color: #000"><b>' + value + (unit && unit[0] === '%' ? '' : ' ') + (unit || '') + '</b></td></tr>';
                        };
                        angular.forEach(this.series.chart.series, function(s) {
                            var y = s.data[pt.x].y;
                            if (y != undefined) {
                                let name = s.name;
                                if (pt.series.name == 'Speed Shortfall' && s.name == 'Speed Shortfall') {
                                    name = 'Performance Speed';
                                }
                                tooltipHTML += makeTooltipRowHTML(name, roundToPlaces(y, 1), 'kn', s.color);
                            }
                        });
                        if (this.series.name == 'Speed Shortfall') {
                            let pSpeed = this.series.chart.series[1]?.data[pt.x]?.y;
                            let cpSpeed = this.series.chart.series[3]?.data[pt.x]?.y;
                            if (pSpeed != undefined && cpSpeed != undefined) {
                                let y = Math.abs(pSpeed - cpSpeed);
                                tooltipHTML += makeTooltipRowHTML('Speed Shortfall', roundToPlaces(y, 1), 'kn', '#AC0000');
                            }
                        }
                        return tooltipHTML;
                    },
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                xAxis: {
                    startOnTick: true,
                    endOnTick: true,
                    categories: $scope.newTimeStamp
                },
                yAxis: {
                    title: {
                        enabled: true,
                        text: 'Performance Speed (kn)'
                    }
                },
                credits: { enabled: false },
                exporting: { enabled: false },
                plotOptions: {
                    column: { grouping: false },
                },
                series: $scope.cpSpeedGraphData,
            });

        }
    });

    $scope.isGoodWeatherAtIndex = function(i) {
        return $scope.voyage.good_weather[i];
    };

    $scope.drawCharts = function() {

        if ($scope.isPortStay()) {
            $scope.totalAverageLoadDischargeRate = $scope.voyage.load_rate
                .filter(function(loadRates) { return loadRates !== null; })
                .map(function(loadRates) { return loadRates.mean() })
                .mean();

            $scope.averageLoadDischargeRateChart = Highcharts.chart('chart-voyage-average-load-discharge-rate', {
                title: { text: null },
                subtitle: { text: 'Load/Discharge Rate (MT/hr)' },
                credits: { enabled: false },
                exporting: { enabled: false },
                yAxis: [{
                    title: { text: 'Load Discharge Rate (MT/hr)'},
                    min: 0,
                    allowDecimals: true,
                    maxPadding: 0,
                }],
                xAxis: { categories: $scope.newTimeStamp },
                plotOptions: { column: { stacking: 'normal' } },
                series: [{
                    name: 'Daily Reported',
                    data: $scope.voyage.load_rate.map(function(loadRates) { return loadRates && detailRound(loadRates.mean()); }),
                    type: 'column',
                    color: themePalette.colors.GRAPH_BLUE,
                    tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT/hr</b><br/>'}
                }, {
                    name: 'Avg. Port Call',
                    data: Array($scope.voyage.load_rate.length).fill($scope.totalAverageLoadDischargeRate),
                    type: 'line',
                    color: themePalette.colors.AVG_PORT_CALL_DATA,
                    marker: { enabled: false, states: { hover: { enabled: false } } },
                    tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT/hr</b><br/>'}
                }]
            });
        }

        if ($scope.isPortStay()) {
            $scope.operationConsumptionData = [{
                name: 'Port',
                showInLegend: $scope.voyage['port_total_cons'] > 0,
                visible: $scope.voyage['port_total_cons'] > 0,
                data: [detailRound($scope.voyage['port_total_cons'])],
                color: themePalette.colors.PORT_CONSUMPTION_DATA
            }, {
                name: 'Maneuvering',
                showInLegend: $scope.voyage['maneuvering_total_cons'] > 0,
                visible: $scope.voyage['maneuvering_total_cons'] > 0,
                data: [detailRound($scope.voyage['maneuvering_total_cons'])],
                color: themePalette.colors.GRAPH_BLUE
            }, {
                name: 'Anchor/Drifting',
                showInLegend: $scope.voyage['anchor_total_cons'] > 0,
                visible: $scope.voyage['anchor_total_cons'] > 0,
                data: [detailRound($scope.voyage['anchor_total_cons'])],
                color: themePalette.colors.ANCHOR_CONSUMPTION_DATA
            }];

            $scope.fuelTypeChart = Highcharts.chart('chart-port-analysis-consumption-split', {
                chart: { type: 'bar' },
                title: { text: null },
                subtitle: { text: 'Operation (MT)'},
                credits: { enabled: false },
                exporting: { enabled: false },
                plotOptions: {
                    series: { stacking: 'normal' },
                    bar: { dataLabels: { enabled: true, style: { color: 'white', textOutline: '' } } }
                },
                yAxis: {
                    title: { text: null },
                    allowDecimals: false
                },
                xAxis: { labels: { enabled: false } },
                tooltip: { formatter: function() {
                    return '<span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <b>' + this.point.y + ' MT</b><br/>';
                } },
                legend: { reversed: true },
                series: $scope.operationConsumptionData.sort(function(a, b) {
                    return a.data[0] > b.data[0];
                })
            });
        }

        $scope.sfocDifference = $scope.voyage['sfoc_lcv_corrected'].map(function(actual, i) {
            var model = $scope.voyage['model_sfoc'][i];

            if (actual && model && model != 0) {
                var result = (actual - model) / model * 100;
                return detailRound(result);
            } else {
                return null;
            }
        });

        $scope.differenceMeSfocChart = Highcharts.chart("chart-difference-me-sfoc", {
            title: { text: null },
            subtitle: { text: 'ME SFOC Difference (Model vs. Actual)' },
            credits: { enabled: false },
            exporting: { enabled: false },
            xAxis: { categories: $scope.newTimeStamp },
            yAxis: { title: { text: '(%)'} },
            legend: { enabled: false },
            series: [{
                name: 'ME SFOC Difference',
                data: $scope.sfocDifference,
                color: themePalette.colors.GRAPH_BLUE
            }]
        });

        $scope.propulsionEfficiency = $scope.voyage['model_fuel'].map(function(val, i) {
            if (!$scope.voyage['total_me_consumption'][i] || !val) {
                return null;
            } else {
                return val / $scope.voyage['total_me_consumption'][i];
            }
        });

        $scope.propulsionEfficiencyChart = Highcharts.chart("chart-propulsion-efficiency", {
            title: { text: null },
            subtitle: { text: 'Propulsion Efficiency (%)' },
            credits: { enabled: false },
            exporting: { enabled: false },
            xAxis: { categories: $scope.newTimeStamp },
            yAxis: {
                title: { text: '(%)'},
                min: 25,
                max: 150,
                tickInterval: 25,
                endOnTick: false,
            },
            legend: { enabled: false },
            series: [{
                name: 'Propulsion Efficiency',
                data: $scope.propulsionEfficiency.map(function(val) { return val && detailRound(val * 100); }),
                color: themePalette.colors.GRAPH_BLUE
            }]
        });

        $scope.vesselProductivityIndexChart = Highcharts.chart("chart-vessel-productivity-index", {
            title: { text: null },
            subtitle: { text: 'Vessel Productivity Index (%)' },
            credits: { enabled: false },
            exporting: { enabled: false },
            yAxis: {
                title: { text: '(%)' },
                enabled: false,
                allowDecimals: false
            },
            xAxis: { categories: $scope.newTimeStamp },
            legend: { enabled: false },
            series: [{
                name: 'VPI',
                data: $scope.vpi.map(function(val) { return detailRound(val * 100); }),
                color: themePalette.colors.GRAPH_BLUE
            }]
        });

        let eeoiUnit = $scope.voyageVesselSpecs?.information?.vessel_type == 'cruise_passenger' ? 'g/passenger*nm' : 'g/cargo*nm';

        $scope.eeoiChart = Highcharts.chart("chart-eeoi", {
            title: { text: null },
            subtitle: { text: `EEOI (${eeoiUnit})` },
            credits: { enabled: false },
            exporting: { enabled: false },
            yAxis: { title: { text: '(g/cargo*nm)'}, enabled: false },
            xAxis: { categories: $scope.newTimeStamp },
            legend: { enabled: false },
            series: [{
                name: 'EEOI',
                data: $scope.voyage['co2_efficiency'].map(detailRound),
                color: themePalette.colors.GRAPH_BLUE
            }]
        });


        // For each report
        //   For each fuel type (3)
        //     Add to total
        $scope.voyageFuelTotals = {};
        $scope.voyage.report_number.forEach((_, i) => {
            let ft = $scope.voyage.fuel_type_1[i];
            let cons = $scope.voyage[`total_consumption_${ft}`]?.[i];
            $scope.voyageFuelTotals[ft] = ($scope.voyageFuelTotals[ft] || 0) + cons;
        });

        $scope.totalVoyageFuelCons = $scope.voyage['total_fuel_consumption'].sum();

        $scope.consumptionTypeData = Object.keys($scope.voyageFuelTotals).map((ft) => {
            let pct = detailRound($scope.voyageFuelTotals[ft] / $scope.totalVoyageFuelCons * 100);
            return {
                name: newFuelGradeMapping[ft],
                showInLegend: pct > 0,
                visible: pct > 0,
                data: [pct],
                color: undefined
            }
        });

        var colors = [themePalette.colors.GRAPH_BLUE, themePalette.colors.DATA_1, themePalette.colors.DATA_2, themePalette.colors.DATA_5, themePalette.colors.DATA_4, '#65717f'];
        angular.forEach($scope.consumptionTypeData, function(series) {
            if (series.visible) {
                series.color = colors.shift();
            }
        });

        $scope.fuelTypeChart = Highcharts.chart('chart-fuel-type', {
            chart: { type: 'bar' },
            title: { text: null },
            subtitle: { text: 'Fuel Type'},
            credits: { enabled: false },
            exporting: { enabled: false },
            plotOptions: {
                series: { stacking: 'percent' },
                bar: { dataLabels: { enabled: true, style: { color: 'white', textOutline: '' } } }
            },
            yAxis: {
                title: { text: null },
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    }
                },
                allowDecimals: false
            },
            xAxis: { labels: { enabled: false } },
            tooltip: { formatter: function() {
                return '<span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <b>' + this.point.y + '%</b><br/>';
            } },
            legend: { reversed: true },
            series: $scope.consumptionTypeData.sort(function(a, b) {
                return a.data[0] - b.data[0];
            })
        });

        var mainEngineData = detailRound($scope.voyage['total_me_consumption'].sum() / $scope.voyage['total_fuel_consumption'].sum() * 100);
        var auxiliaryEngineData = detailRound($scope.voyage['total_ae_consumption'].sum() / $scope.voyage['total_fuel_consumption'].sum() * 100);
        var auxiliaryBoilerData = detailRound($scope.voyage['total_boiler_consumption'].sum() / $scope.voyage['total_fuel_consumption'].sum() * 100);
        var allOtherData = detailRound($scope.voyage['total_all_other'] / $scope.voyage['total_fuel_consumption'].sum() * 100);
        var iggData = detailRound($scope.voyage['total_ig_generator'] / $scope.voyage['total_fuel_consumption'].sum() * 100);

        var consumptionConsumerData = [
            {
                name: 'Main Engine',
                data: [mainEngineData],
                visible: mainEngineData > 0,
                showInLegend: mainEngineData > 0,
                color: themePalette.colors.GRAPH_BLUE
            },
            {
                name: 'Auxiliary Engine',
                data: [auxiliaryEngineData],
                visible: auxiliaryEngineData > 0,
                showInLegend: auxiliaryEngineData > 0,
                color: themePalette.colors.DATA_1
            },
            {
                name: 'Auxiliary Boiler',
                data: [auxiliaryBoilerData],
                visible: auxiliaryBoilerData > 0,
                showInLegend: auxiliaryBoilerData > 0,
                color: themePalette.colors.DATA_2
            },
            {
                name: 'All Other',
                data: [allOtherData],
                visible: allOtherData > 0,
                showInLegend: allOtherData > 0,
                color: themePalette.colors.DATA_5
            }
        ];

        if ($scope.isPortStay()) {
            consumptionConsumerData.push({
                name: 'IGG',
                data: [iggData],
                visible: iggData > 0,
                showInLegend: iggData > 0,
                color: themePalette.colors.DATA_5
            });
        }

        $scope.fuelConsumerChart = Highcharts.chart('chart-fuel-consumer', {
            chart: { type: 'bar' },
            title: { text: null },
            subtitle: { text: 'Consumer'},
            credits: { enabled: false },
            exporting: { enabled: false },
            plotOptions: {
                series: { stacking: 'percent' },
                bar: { dataLabels: { enabled: true, style: { color: 'white', textOutline: '' } } }
            },
            yAxis: {
                title: { text: null },
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    }
                },
                allowDecimals: false
            },
            xAxis: { labels: { enabled: false } },
            tooltip: { formatter: function() {
                return '<span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <b>' + this.point.y + '%</b><br/>';
            } },
            legend: { reversed: true },
            series: consumptionConsumerData.sort(function(a, b) {
                return a.data[0] - b.data[0];
            })
        });

        var voyageLegs = [];
        var currentLeg = [];
        var reportFromInMilliseconds = $scope.voyage.report_from.map(function(reportFrom) {
            return new Date(reportFrom).getTime();
        });
        for (var i = 0; i < reportFromInMilliseconds.length; i++) {
            var reportFrom = reportFromInMilliseconds[i];
            var reportTo = $scope.voyage.timestamp[i];
            var previousReportTo = $scope.voyage.timestamp[i - 1];

            if (i === 0) {
                // Always push first report
                currentLeg.push(reportTo);
                continue;
            }

            if (previousReportTo) {
                if (reportFrom === previousReportTo) {
                    currentLeg.push(reportTo);
                } else {
                    voyageLegs.push(currentLeg);
                    currentLeg = [reportTo];
                }
            }
        }
        voyageLegs.push(currentLeg);
        $scope.voyageLegs = voyageLegs;

        var meExcessConsumption = 0;
        for (var i = 0; i < voyageLegs.length; i++) {
            var leg = voyageLegs[i];
            var legData = leg.map(function(reportTo) {
                var index = $scope.voyage.timestamp.indexOf(reportTo);

                return {
                    totalMeCons24: $scope.voyage.total_me_consumption[index],
                    modelFuel: $scope.voyage.model_fuel[index]
                };
            });

            var legModelFactorMean = legData.filter(function(d) { return !!d.modelFuel; }).map(function(d) { return d.totalMeCons24 / d.modelFuel; }).mean();
            var legConstantPower = legData.map(function(d) { return d.modelFuel; }).mean() * legModelFactorMean;

            let legMEExcessConsumption = legData.map(function(d) { return d.totalMeCons24 - legConstantPower; }).sum();
            legMEExcessConsumption = legMEExcessConsumption > 0 ? legMEExcessConsumption : 0;

            meExcessConsumption += legMEExcessConsumption;

        }

        // draw speed performance chart
        GraphService.getAISBetweenDates($scope.currentVoyage.vessel_id, $scope.after, $scope.before).then(function(res) {
            var response = res.data;
            var aisData = response.data;
            var speedAISData = [];
            for (var i = 0; i < aisData.length; i++) {
                var t = new Date(aisData[i].timestamp);
                speedAISData.push([
                    t.getTime(),
                    aisData[i].speed
                ]);
            }
            var speedProfileSeries = [];
            var observedSpeeds = $scope.observedSpeed.map(detailRound);
            for (var i = 0; i < $scope.actualTimeStamp.length; i++) {
                speedProfileSeries.push([
                    $scope.actualTimeStamp[i].getTime(),
                    observedSpeeds[i],
                ]);
            }
            $scope.speedPerformanceChart = Highcharts.chart('chart-voyage-speed-performance', {
                        chart: {
                            zoomType: 'x',
                            type: 'line'
                        },
                        title: { text: null },
                        subtitle: { text: "Speed Profile" },
                        xAxis: {
                            type: 'datetime',
                            dateTimeLabelFormats: {
                                millisecond: '%m/%d/%Y',
                                second: '%m/%d/%Y',
                                minute: '%m/%d/%Y',
                                hour: '%m/%d/%Y',
                                day: '%m/%d/%Y',
                                week: '%m/%d/%Y',
                                month: '%m/%d/%Y',
                                year: '%m/%d/%Y'
                            },
                            tickInterval: 2 * (24 * 60 * 60 * 1000), // N days in ms
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                enabled: true,
                                text: 'Knots'
                            },
                            allowDecimals: false
                        },
                        legend: { enabled: true },
                        plotOptions: {
                            scatter: {
                                tooltip: {
                                    headerFormat: '<b>{series.name}</b><br>',
                                    pointFormat: '{point.y} %'
                                }
                            }
                        },
                        credits: { enabled: false },
                        exporting: { enabled: false },
                        series: [{
                            name: 'Speed Profile',
                            data: speedProfileSeries,
                            threshold: 85,
                            width: 1,
                            color: themePalette.colors.GRAPH_BLUE,
                            marker: {
                                enabled: true,
                                radius: 4,
                                symbol: 'circle'
                            },
                            point: {
                                events: {
                                    mouseOver: function(event) {
                                        var date = event.target.category;
                                        var waypoint = $scope.mapWaypointsByDate[date];
                                        waypoint.setStyle($scope.highlightedIconStyle);
                                    },
                                    mouseOut: function(event) {
                                        var date = event.target.category;
                                        var waypoint = $scope.mapWaypointsByDate[date];
                                        waypoint.setStyle(null);
                                    }
                                }
                            }
                        },
                        {
                            name: 'AIS Speed',
                            data: speedAISData,
                            color: themePalette.colors.DATA_4,
                            lineWidth: 0.5,
                            marker: {
                                enabled: true,
                                radius: 2,
                                symbol: 'circle'
                            },
                            tooltip: {
                                    valueDecimals: 1
                            }
                        }
                        ]
                    });

            $scope.speedDiff = $scope.voyage.performance_speed.map(function(val, i) {
                return val - $scope.voyage.cp_speed[i];
            });

            // fuel consumption w/ cp fuel
            $scope.cpExcess = $scope.voyage.me_cp_fuel.map(function(cpFuel, i) {
                var totalFuel = $scope.voyage.total_me_consumption_24[i];
                return cpFuel > 0 ? Math.max(totalFuel - cpFuel, 0) : 0;
            }).map(detailRound);

            // AIS speed w/ cp speed
            var timestampToIndex = $scope.newTimeStamp.reduce(function(acc, element, index) {
                acc[element] = index;
                return acc;
            }, {});

            var aisSpeedDiff = speedAISData.reduce(function(acc, element) {
                var time = element[0];
                var speedValue = element[1];
                var formattedTime = (new Date(time + 12 * 60 * 60 * 1000)).toLocaleDateString();

                var index = timestampToIndex[formattedTime];
                if (index != undefined) {
                    if (acc[index] == undefined) {
                        acc[index] = [];
                    }
                    if (speedValue > 0) {
                        acc[index].push(speedValue);
                    }
                }
                return acc;
            }, new Array($scope.newTimeStamp.length)).reduce(function(acc, speedArray, index) {
                var average = 0;
                if (speedArray.length > 0) {
                    average = speedArray.reduce(function(a, b) { return a + b; }) / speedArray.length;
                    average -= $scope.voyage.cp_speed[index];
                    average = Math.abs(Math.min(average, 0));
                }
                acc.push(average);
                return acc;
            }, []);

            $scope.charterPartyEvaluationChart = Highcharts.chart('chart-charter-party-evaluation', {
                chart: { type: 'line' },
                title: { text: null },
                subtitle: { text: 'Charter Party Evaluation' },
                legend: { enabled: true },
                credits: { enabled: false },
                exporting: { enabled: false },
                yAxis: [{ title: { text: 'Speed (kn)' }, allowDecimals: false },
                        { title: { text: 'ME Excess Consumption' }, opposite: true, allowDecimals: false }],
                xAxis: { categories: $scope.newTimeStamp },
                series: [{
                    name: 'Performance Speed vs. CP Speed Deviation',
                    data: $scope.speedDiff.map(function(val) { return detailRound(Math.abs(Math.min(val, 0))); }),
                    color: themePalette.colors.GRAPH_BLUE,
                    zIndex: 2
                }, {
                    name: 'AIS Speed vs. CP Speed Deviation',
                    data: aisSpeedDiff.map(function(val) { return detailRound(val); }),
                    color: themePalette.colors.DATA_4,
                    zIndex: 2
                }, {
                    name: 'CP Excess Consumption',
                    data: $scope.cpExcess,
                    color: themePalette.colors.RED,
                    type: 'column',
                    yAxis: 1,
                    tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT/24h</b><br/>'}
                }]
            });
        });

        var expectedAeConsumptions = $scope.voyage.total_ae_consumption.map(function(value, i) {
            return value / $scope.voyage.report_period[i] * 24;
        }).filter(function(_, i) { return !$scope.isPortStay() || $scope.voyage['_cls'][i] == 'Report.PortReport'; });

        $scope.expectedAeConsumption = Array($scope.voyage.total_ae_consumption.length).fill(expectedAeConsumptions.mean());

        $scope.aeExcessConsumption = $scope.voyage.total_ae_consumption_24.map(function(aeCons, i) {
            var expected = $scope.expectedAeConsumption[i];
            var excess = aeCons - expected;
            return Math.max(excess, 0);
        }).map(detailRound);

        $scope.aeConsumptionChart = Highcharts.chart('chart-voyage-aux-engine-consumption', {
            chart: { type: 'column' },
            title: { text: null },
            subtitle: { text: 'Aux Engine Consumption / 24h (MT)' },
            credits: { enabled: false },
            exporting: { enabled: false },
            yAxis: [
                {
                    title: { text: 'MT/24h'},
                    opposite: true,
                    min: 0,
                    tickInterval: 1,
                    endOnTick: true,
                    allowDecimals: false,
                    maxPadding: 0
                },
                {
                    title: { text: 'No. Aux Engines' },
                    enabled: false,
                    allowDecimals: false,
                    maxPadding: 0,
                    min: 0
                }
            ],
            xAxis: { categories: $scope.newTimeStamp },
            plotOptions: { column: { stacking: 'normal' } },
            series: [{
                name: 'Excess',
                data: $scope.aeExcessConsumption,
                color: themePalette.colors.PORT_REPORT_COLOR,
                tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT/24h</b><br/>'}
            }, {
                name: 'Expected',
                data: $scope.expectedAeConsumption.map(detailRound),
                color: themePalette.colors.GRAPH_BLUE,
                tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT/24h</b><br/>'}
            }, {
                name: 'No. AE running',
                data: $scope.numAuxEngines.map(detailRound),
                type: 'line',
                yAxis: 1,
                color: themePalette.colors.AVG_PORT_CALL_DATA
            }]
        });

        $scope.boilerConsumptionData = $scope.meLoad.map(function(meLoad, i) {
            if (meLoad < 40) {
                return detailRound($scope.totalBoilerConsumption[i]);
            } else {
                return 0;
            }
        });

        $scope.cargoUnitsPerConsumption = $scope.voyage.bl_quantity.map(function(qty, i) {
            var boilerCons = $scope.totalBoilerConsumption[i];
            return qty / boilerCons;
        });

        $scope.boilerYAxis = [{
            title: { text: 'MT/24h' },
            allowDecimals: false
        }];

        $scope.boilerSeries = [];

        if ($scope.isPortStay()) {
            $scope.boilerYAxis.push({
                title: { text: 'Cargo Units per Fuel (Cargo/MT)' },
                opposite: true,
                min: 0,
            });
            $scope.boilerSeries.push({
                name: 'Boiler Cons. MT/24h',
                data: $scope.totalBoilerConsumption.map(function(value, i) {
                    return detailRound(value / $scope.voyage.report_period[i] * 24);
                }),
                color: themePalette.colors.GRAPH_BLUE,
                tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT/24h</b><br/>'}
            });
            $scope.boilerSeries.push({
                name: 'Cargo Units per Ton Fuel (Cargo/MT)',
                data: $scope.cargoUnitsPerConsumption.map(detailRound),
                color: themePalette.colors.AVG_PORT_CALL_DATA,
                yAxis: 1,
                type: 'line',
                tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} Cargo/MT</b><br/>'}
            });
        } else {
            $scope.boilerYAxis.push({
                title: { text: '%ME Load' },
                opposite: true,
                min: 0,
                max: 100
            });
            $scope.boilerSeries.push({
                name: 'ME Load <40%',
                data: $scope.boilerConsumptionData,
                color: themePalette.colors.GRAPH_BLUE
            });
            $scope.boilerSeries.push({
                name: 'Excess',
                data: $scope.boilerExcessConsumption,
                color: themePalette.colors.ANCHOR_CONSUMPTION_DATA
            });
            $scope.boilerSeries.push({
                name: '%ME Load',
                data: $scope.meLoad.map(function(val) { return detailRound(val); }),
                type: 'line',
                color: themePalette.colors.AVG_PORT_CALL_DATA,
                yAxis: 1
            });

        }

        $scope.boilerExcessConsumptionChart = Highcharts.chart('chart-voyage-boiler-engine-consumption', {
            chart: { type: 'column' },
            title: { text: null },
            subtitle: { text: 'Boiler Consumption / 24h (MT)' },
            credits: { enabled: false },
            exporting: { enabled: false },
            yAxis: $scope.boilerYAxis,
            xAxis: { categories: $scope.newTimeStamp },
            series: $scope.boilerSeries
        });

        $scope.excessConsumptionSeries = [{
            name: 'AE',
            data: [detailRound($scope.aeExcessConsumption.sum())],
            color: themePalette.colors.GRAPH_BLUE,
            tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT</b><br/>'}
        }];

        if (!$scope.isPortStay()) {
            $scope.excessConsumptionSeries.push({
                name: 'ME',
                data: [detailRound(meExcessConsumption)],
                color: themePalette.colors.ANCHOR_REPORT_COLOR,
                tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT</b><br/>'}
            });
            $scope.excessConsumptionSeries.push({
                name: 'Boiler',
                data: [detailRound($scope.boilerExcessConsumption.sum())],
                color: themePalette.colors.PORT_REPORT_COLOR,
                tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} MT</b><br/>'}
            });
        }

        $scope.excessConsumptionChart = Highcharts.chart('chart-excess-consumptions', {
            chart: { type: 'bar' },
            title: { text: null },
            subtitle: { text: 'Excess Consumption (MT)' },
            credits: { enabled: false },
            exporting: { enabled: false },
            tooltip: { formatter: function() {
                return this.series.name + ': <b>' + this.y + '</b>';
            } },
            xAxis: {
                title: { text: null },
                labels: { enabled: false }
            },
            yAxis: {
                title: { text: null },
                max: 1.2 * ($scope.aeExcessConsumption.sum() + $scope.boilerExcessConsumption.sum() + meExcessConsumption)
            },
            plotOptions: {
                series: { stacking: 'normal' },
                bar: { dataLabels: { enabled: true, style: { color: 'white', textOutline: '' } } }
            },
            series: $scope.excessConsumptionSeries
        });

        // bf, swell and wave height
        Highcharts.chart('chart-voyage-bf', {
            chart: {
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: null
            },
            subtitle: {
                text: "Wind, Wave & Current"
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                categories: $scope.newTimeStamp
            },
            yAxis: [{
                title: {
                    enabled: true,
                    text: 'Wave Height (m)'
                },
                allowDecimals: false
            }, {
                title: {
                    enabled: true,
                    text: 'BF and Swell'
                },
                opposite: true,
                allowDecimals: false
            }],
            plotOptions: {
                series: {
                    pointWidth: 20
                }
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: [{
                name: 'Wave Height (m)',
                yAxis: 0,
                type: 'column',
                data: $scope.waveHeight,
                width: 1,
                color: themePalette.colors.PORT_REPORT_COLOR,
            }, {
                name: 'BF',
                yAxis: 1,
                type: 'line',
                data: $scope.bf,
                width: 1,
                color: themePalette.colors.DATA_4,
            }, {
                name: 'Swell',
                yAxis: 1,
                type: 'line',
                data: $scope.swell,
                width: 1,
                color: themePalette.colors.GRAPH_BLUE,
            }, {
                name: 'Current (kn)',
                color: themePalette.colors.ANCHOR_REPORT_COLOR,
                data: $scope.currentSpeed,
                type: 'line'
            }]
        });
    };

    $scope.setMapWaypointsByDate = function(voyageData) {

        var routeData = voyageData.coordinates;
        // get coordinates

        for (var i = 0; i < routeData.length; i++) {
            var latitude = routeData[i]['ship_lat'];
            var longitude = routeData[i]['ship_lon'];
            if (latitude == null || longitude == null) continue;
            var iconFeature = new Feature({
                geometry: new Point(proj.transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')),
                content: formatTemplate(routeData[i], latitude, longitude),
                population: 4000,
                rainfall: 500,
                latitude: latitude,
                longitude: longitude
            });

            $scope.mapWaypointsByDate[routeData[i]['report_to']] = iconFeature;
        }
    };

    var convertDMSToDD = function(degrees, minutes, seconds, direction) {
        if (degrees != null) {
            var dd = Number(degrees) + Number(minutes)/60 + Number(seconds)/(60*60);

            if (direction == "S" || direction == "W") {
                dd = dd * -1;
            } // Don't do anything for N or E
            return dd;
        } else {
            return null;
        }
    };

    var formatTemplate = function(row, lat, long) {
        var label = row['label'];
        var classification = row['classification'];
        var dateString = row['report_to'] ? (new Date(row['report_to'])).toLocaleDateString() : null;

        var html = '<div>';
        html += label ? '<div style=\"text-align: center; font-weight: bold\">' + label + '</div>' : '';
        html += classification ? '<div>Route Detail: ' + row['classification'] + '</div>' : '';
        html += dateString ? '<div style=\"text-align: center; font-weight: bold\">' + dateString + '</div>' : '';
        html += row['eta'] ? '<div>ETA: ' + new Date(row['eta']).toLocaleDateString() + '</div>' : '';
        html += row['observed_speed']  ? '<div>Speed: ' + row['observed_speed'] + ' kn</div>' : '';
        html += row['total_me_consumption_24'] ? '<div>MT/24h: ' + row['total_me_consumption_24'] + '</div>' : '';
        html += (lat && long) ? '<div>Pos: ' + roundToPlaces(lat,1) + ', ' + roundToPlaces(long,1) + '</div>' : '';
        html += '</div>';
        return html;
    };

}]);
