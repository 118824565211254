import { routerApp, baseUrl, enableOnPremise } from '../app.module';
import { httpRequestGenerator } from './utilities';

routerApp.factory('VesselApiService', ['$http',function($http) {
    var requestFunc = httpRequestGenerator(enableOnPremise, $http);
    return {
        getVessels: function(username) {
            var url = baseUrl + username + "/vessels";
            return requestFunc({
                url: url,
                method: "GET",
            });
        }
    }
}]);
