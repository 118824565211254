import { IBdnTankConsumption, TankConsumptionObject } from "../models/TankConsumption"
import { TankStockObject } from '../models/TankStock';

export const generateConsumptions = (report) => {
    if (report.consumption.bdn_consumptions && report.consumption.bdn_consumptions.length > 0) {
        report.consumption.bdn_consumptions = report.consumption.bdn_consumptions.map((bdn_cons ) => updateBdnConsumptionObj(report, bdn_cons))
    } else {
        report.consumption.bdn_consumptions = [
            createBdnConsumptionObj(),
            createBdnConsumptionObj(),
            createBdnConsumptionObj()
        ]
    }
}

const updateBdnConsumptionObj = (report, bdnConsObj: TankConsumptionObject): IBdnTankConsumption => {
    const defaultBdnCons = createBdnConsumptionObj();

    const filteredBDNs: TankStockObject[] = report.activeBDNFuels.filter((bdnTankStock: TankStockObject) => bdnTankStock.bdn_number == bdnConsObj.bdn_number)
    let selectedBdn = {
        fuel_grade: '',
        lcv: 0,
        density: 0,
        sulphur_after_bunkering: 0,
    };
    if (filteredBDNs.length > 0) {
        selectedBdn = filteredBDNs[0];
    }
    const { 
        fuel_grade = '',
        lcv = 0,
        density = 0,
        sulphur_after_bunkering: sulphur,
    } = selectedBdn;
    
    // add in missing attributes in IBdnTankConsumption to bdnConsObj
    return {...defaultBdnCons, ...bdnConsObj, ...{fuel_grade, lcv, density, sulphur}};
}



export const createBdnConsumptionObj = (): TankConsumptionObject => {
    return {
        bdn_number: '',
        total_consumption: null,
        main_engine: null,
        mdg_port: null,
        mdg_starboard: null,
        hdg: null,
        dg: null,
        aux_engine: null,
        aux_boiler: null,
        incinerator: null,
        other: null,
        cargo_related: null,
        ig_generator: null,
        gas_turbines: null,
        ig_boiler_flue_gas: null,
        other_aux_engines: null,
        tank_cleaning: null,
        cargo_heating: null,
        ballast_operations: null,
        cargo_shifting: null,
        ae_basic_load: null,
        ae_ballast_operations: null,
        ae_crane_operations: null,
        ae_cdl_operations: null,
        ae_cargo_heating: null,
        ae_cargo_cooling: null,
        ae_tank_cleaning: null,
        ae_cargo_shifting: null,
        ae_inert_gas_systems: null,
        ae_cargo_hold_washing: null,
        ae_scrubber: null,
        ae_bunker_heating: null,
        dg_basic_load: null,
        prop_cons: null,
        dg_ballast_operations: null,
        dg_cargo_heating: null,
        dg_tank_cleaning: null,
        dg_cargo_shifting: null,
        dg_inert_gas_systems: null,
        ab_basic_load: null,
        ab_ballast_operations: null,
        ab_cargo_heating: null,
        ab_bunker_heating: null,
        ab_tank_cleaning: null,
        ab_cargo_shifting: null,
        ab_inert_gas_systems: null,
        gas_combustion_units: null,
        dpp: null
    }
}