import { TankStock } from "../models/TankStock";

export const generateBunkers = (report) => {

    if (!report.bunker.bdn_based_reporting_bunkered_fuels || report.bunker.bdn_based_reporting_bunkered_fuels.length == 0) {
        try {
            report.bunker.bdn_based_reporting_bunkered_fuels = [
                new TankStock(),
                new TankStock(),
                new TankStock()
            ]
        } catch (err) {
            console.error(err)
        }
    } else {
        report.bunker.bdn_based_reporting_bunkered_fuels = report.bunker.bdn_based_reporting_bunkered_fuels.map(a => new TankStock(a))
    }
}