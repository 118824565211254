import { TankStock } from '../models/TankStock';

export const parseBdnObjects = (arr: TankStock[]) => {
    resetRows(arr);
    addSuffixesToDuplicates(buildRef(arr))

}

const resetRows = (arr: TankStock[]) => {
    arr.forEach((tankStock: TankStock) => {
        tankStock.suffix = null;
        tankStock.updateBdnNumber();
    });
}

const buildRef = (arr: TankStock[]) => {
    return arr.reduce((prev, curr) => {
        if (curr.bdn_number === '') return prev;

        if (prev.hasOwnProperty(curr.bdn_number)) {
            prev[curr.bdn_number] = [
                ...prev[curr.bdn_number],
                curr
            ];

            return prev;
        }

        prev[curr.bdn_number] = [
            curr
        ];

        return prev;
    }, {})
}

const addSuffixesToDuplicates = (bdnRef) => {
    // find duplicate bdn numbers
    const bdnKeys = Object.keys(bdnRef)
    const duplicateKeys = bdnKeys.filter(a => bdnRef[a].length > 1)

    // add suffixes and update
    duplicateKeys.forEach(key => {
        bdnRef[key].forEach((tankStock, i) => {
            tankStock.suffix = (i + 1);
            tankStock.updateBdnNumber();
        })
    })
}