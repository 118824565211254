import { routerApp } from '../app.module';
import { getCacheBustedTemplate } from '../app.route';

routerApp.directive('infoTooltip', function() {

    return {
        templateUrl: getCacheBustedTemplate('pages/directives/info-tooltip.html'),
        scope: {
            info: '@',
        },
        link: function(scope, element, attributes, control) {
            // animate or something
        }
    }
});
