import { FUEL_DEFAULT_VALUES } from './fuel-default-values'

export const getDefaultValues = (fuelGrade: string) => {
    if (!FUEL_DEFAULT_VALUES[fuelGrade]) {
        console.warn(`Fuel grade ${fuelGrade} not found, using blank values.`);

        return FUEL_DEFAULT_VALUES.BLANK;
    }

    return FUEL_DEFAULT_VALUES[fuelGrade];
}