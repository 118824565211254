import { routerApp, baseUrl } from '../app.module';
import { deleteNullValues } from '../services/utilities';
import { User } from '../models/user';

routerApp.controller('managementUsersController', ['ManagementUsersService', 'ManagementClientsService','$rootScope', '$scope', '$http', 'notify', 
    function (ManagementUsersService, ManagementClientsService, $rootScope, $scope, $http, notify) {
        
    $rootScope.selectedLevels = ['management', 'users'];
    $scope.pageState = $scope.pageState || {};  // Ensure pageState is initialized
    $scope.pageState.pageTitle = 'View Users';
    $scope.selectedUser = null;
    $scope.client = null;
    $scope.editing = false;
    $scope.creating = false;

    $scope.roles = ['admin', 'internal_admin', 'shore_user', 'ship_user'];
    $scope.roleMappings = {
      'admin': 'Admin',
      'internal_admin': 'Internal Admin',
      'shore_user': 'Shore User',
      'ship_user': 'Ship User'
    };

    $scope.selectedRole = null;
    
    ManagementClientsService.getClients().then((res: any) => {
        $scope.clientsList = res.data.data;
    });

    $scope.filterUsers = () => {
        ManagementUsersService.getUsers($scope.userForm.client).then((res: any) => {
            $scope.usersList = res.data.data;
        });
    }

    $scope.disableUser = (userId: string) => {
        $scope.pageState.setLoadingData();
        ManagementUsersService.deleteUser(userId).then((res: any) => {
            ManagementUsersService.getUsers($scope.userForm.client).then((res: any) => {
                $scope.usersList = res.data.data;
            });
            $scope.pageState.setDataLoaded();
        });
    }

    $scope.selectUser = (userId: string) => {
        $scope.pageState.setLoadingData();
        ManagementUsersService.getUser(userId).then((res: any) => {
            $scope.selectedUser = res.data.data;
            $scope.selectedUserId = userId;
            $scope.pageState.setDataLoaded();
            $scope.pageState.pageTitle = "User Details - " + $scope.selectedUser.name;
            $scope.selectedRole = $scope.selectedUser.role;
            $scope.selectedUser.vessels = setEnabledOnVessels($scope.selectedUser.vessels);
            $scope.vessels_count = $scope.selectedUser.vessels.length;
            $scope.selectedUser['vesselInfo'] = $scope.selectedUser.vessels
        });
    }

    $scope.$watch('selectedUser.vessels', function(newValue, oldValue, scope) {
        if($scope.selectedUser && $scope.selectedUser.vessels && ($scope.editing || $scope.creating))
        {
            $scope.vessels_count = Object.keys($scope.selectedUser.vessels).reduce(function(total, current_key) { if($scope.selectedUser.vessels[current_key] == true) { total+= 1 }; return total;}, 0);
        }
    }, 1);
    

    $scope.newUser = () => {
        $scope.selectedUser = new User({}, true);
        $scope.creating = true;
        $scope.pageState.pageTitle = 'Create New User';
    }

    $scope.createUser = () => {
        let userDict = Object.assign({}, $scope.selectedUser);
        userDict = deleteNullValues(userDict);

        ManagementUsersService.createUser(userDict)
        .then((response: any) => {
            $scope.userCredentials = response.data.data;
            $('#userCreated').modal('show');
            notify({ message: 'User created successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.userForm.client = $scope.selectedUser.client
            $scope.filterUsers();
            $scope.userForm.$setPristine();
            $scope.creating = false;
            $scope.selectedUser = null;
        })
        .then(() => {
            ManagementUsersService.getUsers($scope.userForm.client).then((res: any) => {
                $scope.users = res.data.data;
            });
        })
        .catch((err: any) => {
            if (Array.isArray(err.data.message)) {
                err.data.message.forEach(message => {
                    notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] });
                });
            } else if (typeof err.data.message === 'string') {
                if (err.data.message.charAt(0) === "[") {
                    try {
                        const messages: string[] = JSON.parse(err.data.message.replace(/'/g, '"'));
                        messages.forEach(message => {
                            notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] });
                        });
                    } catch (parseError) {
                        notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] });
                    }
                } else {
                    notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] });
                }
            } else {
                notify({ message: `Unexpected error format`, duration: 5000, classes: ['bad-notification'] });
            }
        });
    }

    $scope.setEditing = () => {
        $scope.editing = true;
        $scope.selectedUser = new User($scope.selectedUser, false);
    }

    $scope.updateVessel = (vessel: any) => {
        $scope.selectedUser.vessels[vessel.id] = !$scope.selectedUser.vessels[vessel.id];
    }

    $scope.saveUser = () => {
        ManagementUsersService.updateUser($scope.selectedUserId, $scope.selectedUser)
        .then((res: any) => {
            notify({ message: 'User saved successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.userForm.$setPristine();
        })
        .then(() => {
            ManagementUsersService.getUsers($scope.userForm.client).then((res: any) => {
                $scope.usersList = res.data.data;
                $scope.deselectUser();
            });
        })
        .catch((err: any) => {
            if (Array.isArray(err.data.message)) {
                err.data.message.forEach(message => {
                    notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] });
                });
            } else if (typeof err.data.message === 'string') {
                if (err.data.message.charAt(0) === "[") {
                    try {
                        const messages: string[] = JSON.parse(err.data.message.replace(/'/g, '"'));
                        messages.forEach(message => {
                            notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] });
                        });
                    } catch (parseError) {
                        notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] });
                    }
                } else {
                    notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] });
                }
            } else {
                notify({ message: `Unexpected error format`, duration: 5000, classes: ['bad-notification'] });
            }
        });
    }

    $scope.copyToClipboard = () => {
        navigator.clipboard.writeText($scope.userCredentials.join('\n'))
        .then(function() {
            notify({ message: 'Credentials copied to clipboard!', duration: 5000, classes: ['ok-notification'] });
        }).catch(function(err) {
            notify({ message: `Could not copy text to clipboard. Error: ${err}`, duration: 5000, classes: ['bad-notification'] })
        });
    }

    // get a new list of vessels when a client is selected
    $scope.$watch('selectedUser.client', function(newValue, oldValue, scope) {
        if (newValue) {
            $scope.setVesselStatus(newValue);
        } else {
            $scope.clientVessels = [];
        }
    });

    $scope.deselectUser = () => {
        $scope.selectedUser = null;
        $scope.editing = false;
        $scope.creating = false;
        $scope.pageState.pageTitle = 'View Users';
        $scope.selectedRole = null;
        $scope.vessels_count = null;
    }

    $scope.userSearch = '';

    $scope.combinedFilter = function(user) {
        return user.name.toLowerCase().includes($scope.userSearch.toLowerCase());
    };

    $scope.formatRole = function(key) {
        return key.replace(/_/g, ' ')
                  .replace(/\b\w/g, function(l) {
                      return l.toUpperCase();
                  });
    };
   
    $scope.setVesselStatus = function(clientId: string) {
        var url = baseUrl + 'clients/' + clientId + '/vessels';
        $scope.selectedUser.vessels = {};
        $http.get(url).then(function(response) {
            $scope.clientVessels = response.data.data;
            
            $scope.clientVessels.forEach(vessel => {
                if ($scope.creating) {
                    $scope.selectedUser.vessels[vessel.id] = true;
                    $scope.selectedUser.vesselInfo = setEnabledOnVessels($scope.clientVessels);
                }
                else if ($scope.selectedUser.vesselInfo.filter(v => v.id === vessel.id).length === 0) {
                    $scope.selectedUser.vessels[vessel.id] = false;
                    $scope.selectedUser.vesselInfo.push(vessel);
                }
                else {
                    $scope.selectedUser.vessels[vessel.id] = true;
                    $scope.selectedUser.vesselInfo.filter(v => v.id === vessel.id)[0]['enabled'] = true; 
                }
            });
        });
    };

    $scope.formatLabel = function(key) {
        // Replace underscores with spaces and capitalize each word
        return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    };
    }])
    .filter('roleDisplayName', function() {
        return function(roleKey, roleMappings) {
        return roleMappings[roleKey] || roleKey;
        };
    });

const setEnabledOnVessels = (vessels: any) => {
    vessels.forEach(vessel => 
        {
        vessel["enabled"] = true;
        }
    );

    return vessels
};
