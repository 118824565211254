import { routerApp, baseUrl } from '../../app.module';

routerApp.factory('FuelTablesService', [ '$http', function($http) {

    var fuelTablesUrl = baseUrl + 'fuel_tables/';

    var speedIndex = 0;
    var draughtIndex = 1;
    var fuelIndex = 2;
    var powerIndex = 3;

    return {
        getFuelTables: function(vesselId, propulsionChange, bf, fromDate, toDate) {
            var url = fuelTablesUrl + vesselId + '/';
            return $http({
                url: url,
                method: "GET",
                params: {
                    propulsionChange: propulsionChange,
                    bf: bf,
                    fromDate: fromDate,
                    toDate: toDate
                }
            });
        },
        getGraphs: function(vesselId, fromDate) {
            var url = fuelTablesUrl + vesselId + '/graphData';
            return $http({
                url: url,
                method: 'GET',
                params: {
                    fromDate: fromDate,
                }
            });
        },
        getFuelConsumptionAnalysis: function(vesselId) {
            var url = fuelTablesUrl + vesselId + '/fuelConsumptionAnalysis';
            return $http({
                    url: url,
                    method: "GET"
            });
        },
        getFuelTableExtractUrl: function(vesselId, propulsionChange, bf, fromDate, toDate) {
            var url = fuelTablesUrl + vesselId + '/extract';
            if (propulsionChange != undefined) {
                url += "?propulsionChange=" + propulsionChange + "&bf=" + bf + "&fromDate=" + fromDate + "&toDate=" + toDate;
            }
            return url;
        },
        getDraughts: function(fuelTableData) {
            var draughtsDict = {};
            var draughts = [];
            for (var i = 0; i < fuelTableData.length; i++) {
                var draught = fuelTableData[i][draughtIndex];
                if (draughtsDict[draught]) {
                    continue;
                } else {
                    draughtsDict[draught] = true;
                    draughts.push(draught);
                }
            }
            draughts = draughts.sort(function(a, b) { return a - b });
            return draughts;
        },
        groupBySpeed: function(fuelTableData, loadedFuels, ballastFuels) {
            var speedGroups = fuelTableData.reduce(function(acc, entry) {
                acc[entry[speedIndex]] = acc[entry[speedIndex]] || [];
                acc[entry[speedIndex]].push(entry[fuelIndex]);
                return acc;
            }, {});

            var speedGroupsList = [];
            for (let speed in speedGroups) {
                var draughtFuels = speedGroups[speed];
                speedGroupsList.push([
                    speed,
                    draughtFuels
                ]);
            }
            speedGroupsList = speedGroupsList.sort(function(a, b) { return a[0] - b[0] });

            speedGroupsList = speedGroupsList.map(function(speedGroups, index) {
                speedGroups.push(index < loadedFuels.length ? loadedFuels[index] : null);
                speedGroups.push(index < ballastFuels.length ? ballastFuels[index] : null);
                return speedGroups;
            })

            return speedGroupsList;
        },
        getPowerGraphData: function(fuelTableData) {
            var draughtToSeries = fuelTableData.reduce(function(acc, entry) {
                acc[entry[draughtIndex]] = acc[entry[draughtIndex]] || [];
                acc[entry[draughtIndex]].push([
                    entry[speedIndex],
                    entry[powerIndex]
                ]);
                return acc;
            }, {});

            var draughtToSeriesList = [];
            for (let draught in draughtToSeries) {
                var series = draughtToSeries[draught];
                draughtToSeriesList.push([
                    draught,
                    series
                ]);
            }
            draughtToSeriesList = draughtToSeriesList.sort(function(a, b) { return a[0] - b[0] });
            return draughtToSeriesList;
        },
        getFuelGraphData: function(fuelTableData) {
            var draughtToSeries = fuelTableData.reduce(function(acc, entry) {
                acc[entry[draughtIndex]] = acc[entry[draughtIndex]] || [];
                acc[entry[draughtIndex]].push([
                    entry[speedIndex],
                    entry[fuelIndex]
                ]);
                return acc;
            }, {});

            var draughtToSeriesList = [];
            for (let draught in draughtToSeries) {
                var series = draughtToSeries[draught];
                draughtToSeriesList.push([
                    draught,
                    series
                ]);
            }
            draughtToSeriesList = draughtToSeriesList.sort(function(a, b) { return a[0] - b[0] });
            return draughtToSeriesList;
        },
        getMinSpeed: function(fuelTableData) {
            return fuelTableData.reduce(function(acc, entry) {
                return Math.min(acc ? acc : entry[speedIndex], entry[speedIndex]);
            }, null);
        },
        getMaxSpeed: function(fuelTableData) {
            return fuelTableData.reduce(function(acc, entry) {
                return Math.max(acc ? acc : entry[speedIndex], entry[speedIndex]);
            }, null);
        }
    };
}]);
