import moment from 'moment';
import angular from 'angular';
import { routerApp, baseUrl, enableOnPremise } from '../app.module';
import hybridService from '../services/hybrid';
import testService from '../services/testing/index';

routerApp.controller('offhireReportsController', ['$rootScope', '$scope', '$state', '$timeout', '$stateParams', 'OffhireReportService', 'ReportService', 'notify', 'OfflineDatabase', 'ReportsApiService',
    function($rootScope, $scope, $state, $timeout, $stateParams, OffhireReportService, ReportService, notify, OfflineDatabase, ReportsApiService) {

    $rootScope.selectedMenu = 'offhire-report';
    $rootScope.selectedLevels = ['offhire-reports'];

    $scope.$on('offhire-report-received', function() {
        if ($stateParams.viewReport == undefined || $stateParams.viewReport == false) {
            $scope.offhire_report = ReportService.getOffhireReport();
        }
    });

    $scope.openOffhireReport = function(reportId) {
        OffhireReportService.getOffhireReport(reportId).then(function(response) {
            var report = response.data;
            report = ReportService.applyDatesOnOffhireReport(report);
            report.id = report["_id"]["$oid"];
            $state.go('site.offhireReport', {offhireReport: report, viewReport: true });
        }, function() {
            notify({message: 'Error loading report from server, try again in a bit!', duration: 2000, classes: ['bad-notification']});
        });
    };

    $scope.submitOffhireReport = function(closeReport) {
        $scope.submittingReport = true;
        return OffhireReportService.submitOffhireReport($scope.offhire_report, closeReport).then(function(response) {
            var data = response.data;
            notify({message: 'Successfully saved offhire report!', duration: 2000, classes: ['ok-notification']});
            $scope.submittingReport = false;
            $scope.offhire_report.id = data._id['$oid'];

            // redirect to report performance page if the report is closed
            if (closeReport) {
                $rootScope.hasIncompleteOffhireReport = false;
                $scope.refreshOffhireReports(function() {
                    $state.go('site.offhireReports');
                });
                // because we're closing the report, create a new one, put it on the service
                ReportService.setOffhireReportNumber(data.report_number + 1);
                var newReport = ReportService.createNewOffhireReport();
                ReportService.setOffhireReport(newReport);
            } else {
                ReportService.setOffhireReport($scope.offhire_report)
                $scope.refreshOffhireReports();
                $scope.refreshLastIncompleteOffhireReport();
            }
        }, function() {
            $scope.submittingReport = false;
            if (enableOnPremise) {
                notify({message: 'Submitting report to local cache.', duration: 5000, classes: ['warning-notification']});
                return $scope.saveOffhireReportToLocalDatabase($scope.offhire_report, closeReport)
                .then(()=> $scope.refreshOfflineOffhireReports())
                .then(()=> {
                    if (closeReport == true) {
                        $state.go('site.offhireReports');
                    }
                });
            }
            notify({message: 'Error saving offhire report, please try again later.', duration: 5000, classes: ['bad-notification']});
        });
    };

    $scope.saveOffhireReportToLocalDatabase = function(report, closeReport) {
        var reportKey = OfflineDatabase.getOffhireReportKey(report);
        var vesselId = $rootScope.selectedVessel.id;
        var url = baseUrl + $rootScope.selectedVessel.id + '/offhireReports';
        var reportStatus = 'draft';
        if (closeReport == true) {
            report.status = 'completed';
            reportStatus = 'ready';
            url += "?closeReport=true";
        }
        var method = "POST";
        var body = report;
        return hybridService.serverOffhireReportsUpdate({
            reportKey: reportKey,
            url: url,
            method: method,
            body: JSON.stringify(body),
            type: 'OffhireReport',
            vesselId: vesselId,
            username: $rootScope.username,
            report: report,
            reportStatus: reportStatus
        }).then(function(response) {
            // update meta data
            return hybridService.serverMetaGet(vesselId)
            .then((offlineVesselData: any) => {
                var offhireReportNumber = report.report_number;
                var updatedVesselData = offlineVesselData ? Object.assign({}, offlineVesselData) : {vesselId: vesselId};
                if (closeReport == true) {
                    // TODO: need sync offhire report for hybrid
                    // hybridService.syncReportsServiceWorker('new-offhire-report');
                    offhireReportNumber = report.report_number + 1;
                } else {
                    updatedVesselData.incompleteOffhireReport = report;
                }
                updatedVesselData.hasIncompleteOffhireReport = !closeReport;
                updatedVesselData.offhireReportNumber = offhireReportNumber;
                return updatedVesselData;
            })
            .then(updatedVesselData => {
                return hybridService.serverMetaUpdate(updatedVesselData).then(response => {
                    var newOffhireReport = updatedVesselData.incompleteOffhireReport;
                    if (closeReport == true) {
                        notify({message: 'Report added to submission queue!', duration: 2000, classes: ['ok-notification']});
                        ReportService.setOffhireReportNumber(updatedVesselData.offhireReportNumber);
                        newOffhireReport  = ReportService.createNewOffhireReport();
                    }
                    ReportService.setOffhireReport(newOffhireReport);
                    $rootScope.hasIncompleteOffhireReport = updatedVesselData.hasIncompleteOffhireReport;
                })
            })
        })
    }

    $scope.backToNewReport = function() {
        $timeout(function() {
            $state.go('site.offhireReport', {offhireReport: undefined, viewReport: false }, { reload: true, notify: true });
        });
    };

    $scope.validateReport = function() {
        $scope.reportForm.$pristine = false;
        angular.forEach($scope.reportForm.$error, function(controls, errorName) {
            angular.forEach(controls, function(control) {
                if (control != undefined) control.$pristine = false;
            });
        });
    };

    $scope.latitudeInvalid = function() {
        if ($scope.reportForm.shipLatDirection == undefined || $scope.reportForm.shipLatSeconds == undefined ||
            $scope.reportForm.shipLatMinutes == undefined || $scope.reportForm.shipLatHours == undefined) {
            return true;
        } else {
            return ($scope.reportForm.shipLatSeconds.$invalid && !$scope.reportForm.shipLatSeconds.$pristine) ||
                ($scope.reportForm.shipLatMinutes.$invalid && !$scope.reportForm.shipLatMinutes.$pristine) ||
                ($scope.reportForm.shipLatHours.$invalid && !$scope.reportForm.shipLatHours.$pristine) ||
                ($scope.reportForm.shipLatDirection.$invalid && !$scope.reportForm.shipLatDirection.$pristine);
        }
    };

    $scope.longitudeInvalid = function() {
        if ($scope.reportForm.shipLonDirection == undefined || $scope.reportForm.shipLonSeconds == undefined ||
            $scope.reportForm.shipLonMinutes == undefined || $scope.reportForm.shipLonHours == undefined) {
            return true;
        } else {
            return ($scope.reportForm.shipLonSeconds.$invalid && !$scope.reportForm.shipLonSeconds.$pristine) ||
                ($scope.reportForm.shipLonMinutes.$invalid && !$scope.reportForm.shipLonMinutes.$pristine) ||
                ($scope.reportForm.shipLonHours.$invalid && !$scope.reportForm.shipLonHours.$pristine) ||
                ($scope.reportForm.shipLonDirection.$invalid && !$scope.reportForm.shipLonDirection.$pristine);
        }
    };

    $scope.cancelReport = function() {
        $('.modal-backdrop').remove();
        if ($scope.offhire_report.id == undefined) {
            if (enableOnPremise) {
                OfflineDatabase.deleteOffhireReportInProgress($scope.offhire_report, $scope.selectedVessel.id).then(() => {
                    $scope.offhire_report = ReportService.createNewOffhireReport();
                    ReportService.setOffhireReport($scope.offhire_report);
                    var targetState = ReportsApiService.getReportView($scope.offhire_report);
                    $rootScope.hasIncompleteOffhireReport = false;
                    $state.go(targetState, {}, {reload: true, notify: true});
                })
            } else {
                $scope.offhire_report = ReportService.createNewOffhireReport();
                ReportService.setOffhireReport($scope.offhire_report);
                $state.go('site.offhireReport', {}, {reload: true, notify: true});
            }
        } else {
            OffhireReportService.deleteReport($scope.offhire_report._id.$oid)
            .then((response) => {
                if (enableOnPremise) {
                    return OfflineDatabase.deleteOffhireReportInProgress($scope.offhire_report, $scope.selectedVessel.id).then(response => response);
                }
                return response;
            }).then((response) => {
                notify({message: 'Successfully deleted offhire report!', duration: 2000, classes: ['ok-notification']});
                $scope.offhire_report = ReportService.createNewOffhireReport();
                ReportService.setOffhireReport($scope.offhire_report);
                $rootScope.hasIncompleteOffhireReport = false;
                $scope.refreshOffhireReports(function() {
                    $state.go('site.offhireReports');
                });
            }).catch(()=> {
                if (enableOnPremise) {
                    return  OfflineDatabase.deleteOffhireReportInProgress($scope.offhire_report, $scope.selectedVessel.id).then(() => {
                        $scope.offhire_report = ReportService.createNewOffhireReport();
                        ReportService.setOffhireReport($scope.offhire_report);
                        var targetState = ReportsApiService.getReportView($scope.offhire_report);
                        $state.go(targetState, {}, {reload: true, notify: true});
                        $rootScope.hasIncompleteOffhireReport = false;
                    })
                }
                notify({message: 'Error deleting report, please try again in a bit', duration: 2000, classes: ['bad-notification']});
            });
        }
    };

    $scope.$watchGroup(['offhire_report.report_from', 'offhire_report.report_to', 'offhire_report.timezone', 'offhire_report.previous_timezone'], function(newValues) {
        if (newValues[0] != undefined && newValues[1] != undefined) {
            var fromMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
            var toMoment = moment(newValues[1], 'DD/MM/YYYY HH:mm');
            var duration = moment.duration(toMoment.diff(fromMoment));
            let toDST = toMoment.isDST() ? 1 : 0;
            let fromDST = fromMoment.isDST() ? 1 : 0;
            let durationHours = duration.asHours() + (toDST - fromDST);
             if ($scope.offhire_report.previous_timezone != undefined && $scope.offhire_report.timezone != undefined) {
                try {
                    var diff = $scope.allTimezones[$scope.offhire_report.timezone].offset - $scope.allTimezones[$scope.offhire_report.previous_timezone].offset;
                    durationHours -= diff;
                } catch(err) {

                }
             }
            $scope.offhire_report.report_period = Math.max(Math.round(durationHours * 100) / 100, 0);
        } else if ($scope.offhire_report != undefined) {
            $scope.offhire_report.report_period = 0;
        }
    });

    if ($stateParams.offhireReport != undefined) {
        // if we came in here with a report parameter, we should use that report parameter
        // we should also use the 'viewReport' boolean to display or hide the needed report buttons
        $scope.offhire_report = $stateParams.offhireReport;
        $scope.viewReport = $stateParams.viewReport;
    } else {
        // this gets the newly edited report from the service, always gets it as
        // soon as this scope is initialized, so should assume that main controller
        // puts it there so that it's ready.
        $scope.offhire_report = ReportService.getOffhireReport();
        // update service with report type
        ReportService.setOffhireReport($scope.offhire_report);
    }
    
    if (process.env.NODE_ENV == 'test' && testService) {
        /**
         * Add all service and scopes to test framework to test offhireReport.controller async methods
         * Test Scripts are added back into scope and used in site/test 
         * Test Scripts are assign to button in test-report.html
         */
        const ts = testService.TestFrameWork.getInstance({offhireControllerScope: $scope});
        $scope.testOffhireSave = ts.testOffhireSave;
        $scope.testOffhireSubmit = ts.testOffhireSubmit;
        $scope.testOffhireDelete = ts.testOffhireDelete;
    }

}]);
