export class Client {
    _id?: string;
    tres_name: string;
    name: string;
    short_name: string;
    navtor_company_id: string;
    is_emissions_compliant: boolean;
    disabled: boolean;
    features: Features;
    engine_reporting: IFeature;
    dnv_mapping?: IDnvMapping;
    information: {
        official_name?: string;
        address_line_1?: string;
        address_line_2?: string;
        city?: string;
        state?: string;
        zip_code?: string;
        country?: string;
        contact_name?: string;
        contact_email?: string;
        contact_telephone?: string;
        contact_job_title?: string;
    };
    dnv?: IFeature;
    veslink?: IVeslinkConfig;

    constructor(client: any, creating: boolean) {
        if (creating) {
            this.tres_name = null;
            this.name = null;
            this.short_name = null;
            this.navtor_company_id = null;
            this.is_emissions_compliant = false;
            this.disabled = false;
            this.features = new Features({});
            this.engine_reporting = new Feature(FeatureSet.EngineReporting, false);
            this.information = {
                official_name: null,
                address_line_1: null,
                address_line_2: null,
                city: null,
                state: null,
                zip_code: null,
                country: null,
                contact_name: null,
                contact_email: null,
                contact_telephone: null,
                contact_job_title: null
            };
            this.dnv = new Feature(FeatureSet.Dnv, false);
        } else {
            this._id = client._id;
            this.tres_name = client.tres_name;
            this.name = client.name;
            this.short_name = client.short_name;
            this.navtor_company_id = client.navtor_company_id || null; // fix error with missing navtor_company_id
            this.is_emissions_compliant = client.is_emissions_compliant;
            this.disabled = client.disabled;
            
            this.features = new Features(client.features);
            this.engine_reporting = new Feature(
                FeatureSet.EngineReporting, 
                client.hasOwnProperty('engine_reporting') ? client.engine_reporting.enabled : false
            );
            this.dnv = new Feature(
                FeatureSet.Dnv, 
                client.hasOwnProperty('dnv') ? client.dnv.enabled : false
            );
            if (client.dnv_mapping.hasOwnProperty('NewKey')) {
                delete client.dnv_mapping.NewKey;
            }
            this.dnv_mapping = client.dnv_mapping;
            this.information = client.information || {};
            this.veslink = client.veslink;
        }
    }
}

export interface IDnvMapping {
    PartitionKey?: string;
    RowKey?: string;
    PerformanceId: string;
    DnvId: string;
    DnvAccessKey: string;
}

export interface IVeslinkConfig {
    enabled: boolean;
    companyName: string;
    companyCode: string;
    productionEnabled: boolean;
    tokenTesting: string;
    tokenProduction: string;
    tokenProductionLegacy: string;
    veslinkFuelTypes: IVeslinkFuelType[];
    bunkeringFormEnabled: boolean;
    formConfig: IVeslinkFormConfig;
    formSchemas: IVeslinkFormSchema[];
    suppliers: IVeslinkSupplier[];
}

export interface IVeslinkSupplier {
    veslink_id: number;
    name: string;
    inactive: boolean;
}

export interface IVeslinkFormConfig {
    noonReport: IVeslinkFormConfigItem;
    arrivalNotice: IVeslinkFormConfigItem;
    departureNotice: IVeslinkFormConfigItem;
    bunkering: IVeslinkFormConfigItem;
    cargoHandling: IVeslinkFormConfigItem;
    statementOfFacts: IVeslinkFormConfigItem;
}

export interface IVeslinkFormConfigItem {
    veslinkReportCls: string;
    enabled: boolean;
    formIdentifier: string;
}

export interface IVeslinkFormSchema {
    filename: string;
    veslinkFormIdentifier: string;
    veslinkTitle: string;
}

export interface IVeslinkFuelType {
    veslinkType: string;
    veslinkName: string;
    veslinkTypeId: number;
    newFuelGradeName: string;
    tvaName: string;
    trackSulfurContent: boolean;
    units: string;
    description: string;
    isLowSulfur: boolean;
}

export class Features {
    reporting?: IFeature;
    veslink?: IFeature;
    offhire_reporting?: IFeature;
    engine_reporting?: IFeature;
    email_alerts?: IFeature;
    navfleet?: IFeature;
    crew_dashboard?: IFeature;
    cii_reporting?: IFeature;
    fleet_performance?: IFeature;
    fleet_tracker?: IFeature;
    voyage_analysis?: IFeature;
    voyage_planning?: IFeature;
    fuel_tables?: IFeature;
    analytics_directory?: IFeature;
    analytics_redirect?: IFeature;
    mrv?: IFeature;
    tableau?: IFeature;
    data_reports?: IFeature;
    advanced_analytics?: IFeature;
    vessel_dashboard?: IFeature;
    vessel_settings?: IFeature;

    constructor(features) {
        this.reporting = new Feature(
            FeatureSet.Reporting, 
            features.hasOwnProperty('reporting') ? features.reporting.enabled : false
        );
        this.veslink = new Feature(
            FeatureSet.Veslink, 
            features.hasOwnProperty('veslink') ? features.veslink.enabled : false
        );
        this.offhire_reporting = new Feature(
            FeatureSet.OffhireReporting, 
            features.hasOwnProperty('offhire_reporting') ? features.offhire_reporting.enabled : false
        );
        this.engine_reporting = new Feature(
            FeatureSet.EngineReporting, 
            features.hasOwnProperty('engine_reporting') ? features.engine_reporting.enabled : false
        );
        this.email_alerts = new Feature(
            FeatureSet.EmailAlerts, 
            features.hasOwnProperty('email_alerts') ? features.email_alerts.enabled : false
        );
        this.navfleet = new Feature(
            FeatureSet.NavFleet, 
            features.hasOwnProperty('navfleet') ? features.navfleet.enabled : false
        );
        this.crew_dashboard = new Feature(
            FeatureSet.CrewDashboard, 
            features.hasOwnProperty('crew_dashboard') ? features.crew_dashboard.enabled : false
        );
        this.cii_reporting = new Feature(
            FeatureSet.CiiReporting, 
            features.hasOwnProperty('cii_reporting') ? features.cii_reporting.enabled : false
        );
        this.fleet_performance = new Feature(
            FeatureSet.FleetPerformance,
            features.hasOwnProperty('fleet_performance') ? features.fleet_performance.enabled : false
        );
        this.fleet_tracker = new Feature(
            FeatureSet.FleetTracker, 
            features.hasOwnProperty('fleet_tracker') ? features.fleet_tracker.enabled : false
        );
        this.voyage_analysis = new Feature(
            FeatureSet.VoyageAnalysis, 
            features.hasOwnProperty('voyage_analysis') ? features.voyage_analysis.enabled : false
        );
        this.voyage_planning = new Feature(
            FeatureSet.VoyagePlanning, 
            features.hasOwnProperty('voyage_planning') ? features.voyage_planning.enabled : false
        );
        this.fuel_tables = new Feature(
            FeatureSet.FuelTables, 
            features.hasOwnProperty('fuel_tables') ? features.fuel_tables.enabled : false
        );
        this.analytics_directory = new Feature(
            FeatureSet.AnalyticsDirectory, 
            features.hasOwnProperty('analytics_directory') ? features.analytics_directory.enabled : false
        );
        this.analytics_redirect = new Feature(
            FeatureSet.AnalyticsRedirect, 
            features.hasOwnProperty('analytics_redirect') ? features.analytics_redirect.enabled : false);
        this.mrv = new Feature(
            FeatureSet.Mrv, 
            features.hasOwnProperty('mrv') ? features.mrv.enabled : false
        );
        this.tableau = new Feature(
            FeatureSet.Tableau, 
            features.hasOwnProperty('tableau') ? features.tableau.enabled : false);
        this.data_reports = new Feature(
            FeatureSet.DataReports, 
            features.hasOwnProperty('data_reports') ? features.data_reports.enabled : false
        );
        this.advanced_analytics = new Feature(
            FeatureSet.AdvancedAnalytics, 
            features.hasOwnProperty('advanced_analytics') ? features.advanced_analytics.enabled : false
        );
        this.vessel_dashboard = new Feature(
            FeatureSet.VesselDashboard, 
            features.hasOwnProperty('vessel_dashboard') ? features.vessel_dashboard.enabled : false
        );
        this.vessel_settings = new Feature(
            FeatureSet.VesselSettings, 
            features.hasOwnProperty('vessel_settings') ? features.vessel_settings.enabled : false
        );
    }
}

export class Feature implements IFeature {
    _cls: string;
    enabled: boolean;

    constructor(cls: string, enabled: boolean) {
        this._cls = cls;
        this.enabled = enabled;
    }
}

export interface IFeature {
    _cls: string;
    enabled: boolean;
}

enum FeatureSet {
    Reporting = 'ReportingFeature',
    Veslink = 'VeslinkFeature',
    OffhireReporting = 'OffhireReporting',
    EngineReporting = 'EngineReporting',
    EmailAlerts = 'EmailAlertsFeature',
    NavFleet = 'NavFleetFeature',
    CrewDashboard = 'CrewdashboardFeature',
    CiiReporting = 'CIIReportingOnlyFeature',
    FleetPerformance = 'FleetPerformanceFeature',
    FleetTracker = 'FleetTrackerFeature',
    VoyageAnalysis = 'VoyageAnalysisFeature',
    VoyagePlanning = 'VoyagePlanningFeature',
    FuelTables = 'FuelTablesFeature',
    AnalyticsDirectory = 'AnalyticsDirectoryFeature',
    AnalyticsRedirect = 'AnalyticsRedirectFeature',
    Mrv = 'MRVFeature',
    Tableau = 'TableauFeature',
    DataReports = 'DataReportsFeature',
    AdvancedAnalytics = 'AdvancedAnalyticsFeature',
    VesselDashboard = 'VesselDashboardFeature',
    VesselSettings = 'VesselSettingsFeature',
    Dnv = 'DnvFeature'
}