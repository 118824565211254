import angular from 'angular';
import { routerApp, roundToPlaces, reportRequestTimeout, enableHybrid, enableOnPremise } from '../app.module';
import hybridService from './../services/hybrid/index';

routerApp.controller('aePerformanceController', [ '$rootScope', '$scope', '$state', '$stateParams', 'ReportService', 'EngineReportService', 'VesselSpecificationService', 'notify' , 'OfflineDatabase',
function($rootScope, $scope, $state, $stateParams, ReportService, EngineReportService, VesselSpecificationService, notify, OfflineDatabase) {

    $scope.warningMessages = {};

    $scope.selectedLevels = ['engine-reports', 'create-new-engine-report', 'ae-report'];

    $scope.cylinderIndices = [];
    $scope.turbochargerIndices = [];
    $scope.engines = [];

    $scope.setEngines = function() {
        if ($scope.isDieselElectricPropulsion()) {
            $scope.engines = $scope.vessel_specifications
                && $scope.vessel_specifications.prime_mover
                && $scope.vessel_specifications.prime_mover.diesel_generators
                || [];
        } else {
            $scope.engines = $scope.vessel_specifications
                && $scope.vessel_specifications.engine
                && $scope.vessel_specifications.engine.ae_engines
                || [];
        }
    };

    $scope.getEngine = function(engineIndex: number) {
        return $scope.engines[engineIndex];
    };

    $scope.refreshCylinderAndTurbochargerIndices = function(engineIndex) {
        $scope.setEngines();
        let auxEngine = $scope.getEngine(engineIndex);
        if (auxEngine) {
            if (auxEngine.n_cylinders != undefined) {
                // cylinder indices
                $scope.cylinderIndices = [];
                for (var i = 0; i < auxEngine.n_cylinders; i++) {
                    $scope.cylinderIndices.push(i);
                }
            }
            if (auxEngine.n_turbochargers != undefined) {
                // cylinder indices
                $scope.turbochargerIndices = [];
                for (var i = 0; i < auxEngine.n_turbochargers; i++) {
                    $scope.turbochargerIndices.push(i);
                }
            }
        } else {
            $scope.cylinderIndices = [0, 1, 2];
            $scope.turbochargerIndices = [0, 1, 2];
        }

    }

    // get specification that sets auxiliary engines dropdown and settings
    $scope.vessel_specifications = VesselSpecificationService.getSpecifications();
    $scope.refreshCylinderAndTurbochargerIndices(0);

    $scope.$on('vessel-specifications-received', function() {
        $scope.vessel_specifications = VesselSpecificationService.getSpecifications();
        $scope.refreshCylinderAndTurbochargerIndices(0);
    });

    $scope.$watch('engine_report.details.aux_engine', function(newValue, oldValue, scope) {
        $scope.refreshCylinderAndTurbochargerIndices(newValue);
    });

    if ($stateParams.engineReport != undefined) {
        // if we came in here with a report parameter, we should use that report parameter
        // we should also use the 'viewReport' boolean to display or hide the needed report buttons
        $scope.engine_report = $stateParams.engineReport;
        if ($scope.engine_report["_id"]) $scope.engine_report.id = $scope.engine_report["_id"]["$oid"];
        $scope.viewReport = $stateParams.viewReport;
    } else {
        // this gets the newly edited report from the service, always gets it as
        // soon as this scope is initialized, so should assume that main controller
        // puts it there so that it's ready.
        $scope.engine_report = ReportService.getEngineReport();
        $scope.engine_report._cls = "EngineReport.AEReport";
        $scope.engine_report.report_number = ReportService.getAEReportNumber();
        // update service with report type
        ReportService.setEngineReport($scope.engine_report);
    }

    $scope.$on('engine-report-received', function() {
        if ($stateParams.viewReport == undefined || $stateParams.viewReport == false) {
            $scope.engine_report = ReportService.getEngineReport();
        }
    });

    $scope.$watch('engine_report.details', function(newValue) {
        if (newValue) {
            $scope.engine_report.details.aux_engine = $scope.engine_report.details.aux_engine || 0;
        }
    });

    // initialize
    $scope.submittingReport = false;

    // submit auxiliary engine report
    $scope.submitAeReport = function(closeReport) {
        // check if there any reports that are completed offline engine reports
        // that need to sync before sending any new reports to server
        // prevents server receiving reports out of order
        if ((enableHybrid || enableOnPremise) && !OfflineDatabase.canSyncNewEngineReport()) {
            notify({message: 'Saving engine report in local cache.', duration: 5000, classes: ['warning-notification']});
            $scope.saveEngineReportToLocalDb($scope.engine_report, closeReport)
            .then(()=> {
                $scope.refreshOfflineEngineReports().then(function(){
                    if (closeReport == true) {
                        $state.go('site.engineReports');
                    }
                });
            });
            return;
        }
        var options = enableHybrid || enableOnPremise ? { timeout: reportRequestTimeout * 1000 } : {};
        $scope.submittingReport = true;
        EngineReportService.submitAEReport($scope.engine_report, closeReport, options)
        .then(function (response, status, headers, config) {
            var data = response.data;
            notify({message: 'Successfully saved auxiliary engine report!', duration: 2000, classes: ['ok-notification']});
            $scope.engine_report.id = data.data.report_id;
            $scope.submittingReport = false;

            if ((enableHybrid || enableOnPremise) && closeReport) {
                OfflineDatabase.removeEngineReport($scope.engine_report).then(()=> {
                    hybridService.serverMetaGet($rootScope.selectedVessel.id).then(offlineVesselData => {
                        var updatedVesselData = offlineVesselData ? Object.assign({}, offlineVesselData) : {vesselId: $rootScope.selectedVessel.id};
                        updatedVesselData.hasIncompleteEngineReport = false;
                        updatedVesselData.aeReportNumber =  data.data.report_number;
                        hybridService.serverMetaUpdate(updatedVesselData);
                    })
                });
            }
            // redirect to report performance page if the report is closed
            if (closeReport) {
                $rootScope.hasIncompleteAeEngineReport = false;
                $scope.refreshEngineReports(function() {
                    $state.go('site.engineReports');
                });
                // because we're closing the report, create a new on and put it on the service
                ReportService.setAEReportNumber(data.data.report_number);
                var newReport = ReportService.createNewEngineReport();
                newReport.report_number = ReportService.getAEReportNumber();
                ReportService.setEngineReport(newReport);
            } else {
                if((enableHybrid || enableOnPremise) && $scope.engine_report.status != 'completed') {
                    $scope.saveEngineReportToLocalDb($scope.engine_report, false)
                }
                $scope.refreshEngineReports();
                $scope.refreshLastIncompleteEngineReport();
            }
        }, function (response, status, header, config) {
            $scope.submittingReport = false;
            if (status == 403) {
                notify({message: 'This report has already been submitted.', duration: 5000, classes: ['warning-notification']});
            // } else if (!enableHybrid) {
                // notify({message: 'Error saving auxiliary engine report, please try again later.', duration: 5000, classes: ['bad-notification']});
            } else if ((enableHybrid || enableOnPremise)) {
                $scope.saveEngineReportToLocalDb($scope.engine_report, closeReport)
                .then(()=> {
                    $scope.refreshOfflineEngineReports().then(function(){
                        if (closeReport == true) {
                            $state.go('site.engineReports');
                        }
                    });
                });
            } else {
                notify({message: 'Error saving auxiliary engine report, please try again later.', duration: 5000, classes: ['bad-notification']});
            }
        });
    }

    $scope.$watch('engine_report.turbocharging.turbo', function(newValue, oldValue, scope) {
        var pressure_drop_across_air_cooler = 0;
        var air_temperature_before_cooler = 0;
        var air_temperature_after_cooler = 0;
        var rpm = 0;
        var exhaust_temperature_before_tc = 0;
        var exhaust_temperature_after_tc = 0;
        var exhaust_pressure_before_tc = 0;
        var exhaust_pressure_after_tc = 0;

        let counter = 0;

        var turbos = newValue;
        angular.forEach(turbos, function(value) {
            if (value.pressure_drop_across_air_cooler != undefined) {
                pressure_drop_across_air_cooler += value.pressure_drop_across_air_cooler;
            }
            if (value.air_temperature_before_cooler != undefined) {
                air_temperature_before_cooler += value.air_temperature_before_cooler;
            }
            if (value.air_temperature_after_cooler != undefined) {
                air_temperature_after_cooler += value.air_temperature_after_cooler;
            }
            if (value.rpm != undefined) {
                rpm += value.rpm;
            }
            if (value.exhaust_temperature_before_tc != undefined) {
                exhaust_temperature_before_tc += value.exhaust_temperature_before_tc;
            }
            if (value.exhaust_temperature_after_tc != undefined) {
                exhaust_temperature_after_tc += value.exhaust_temperature_after_tc;
            }
            if (value.exhaust_pressure_before_tc != undefined) {
                exhaust_pressure_before_tc += value.exhaust_pressure_before_tc;
            }
            if (value.exhaust_pressure_after_tc != undefined) {
                exhaust_pressure_after_tc += value.exhaust_pressure_after_tc;
            }
            counter += 1;
        });
        if (counter > 0) {
            pressure_drop_across_air_cooler = pressure_drop_across_air_cooler / counter;
            air_temperature_before_cooler = air_temperature_before_cooler / counter;
            air_temperature_after_cooler = air_temperature_after_cooler / counter;
            rpm = rpm / counter;
            exhaust_temperature_before_tc = exhaust_temperature_before_tc / counter;
            exhaust_temperature_after_tc = exhaust_temperature_after_tc / counter;
            exhaust_pressure_before_tc = exhaust_pressure_before_tc / counter;
            exhaust_pressure_after_tc = exhaust_pressure_after_tc / counter;
        }
        $scope.engine_report.turbocharging.computed = {};
        $scope.engine_report.turbocharging.computed.pressure_drop_across_air_cooler = pressure_drop_across_air_cooler;
        $scope.engine_report.turbocharging.computed.air_temperature_before_cooler = air_temperature_before_cooler;
        $scope.engine_report.turbocharging.computed.air_temperature_after_cooler = air_temperature_after_cooler;
        $scope.engine_report.turbocharging.computed.rpm = rpm;
        $scope.engine_report.turbocharging.computed.exhaust_temperature_before_tc = exhaust_temperature_before_tc;
        $scope.engine_report.turbocharging.computed.exhaust_temperature_after_tc = exhaust_temperature_after_tc;
        $scope.engine_report.turbocharging.computed.exhaust_pressure_before_tc = exhaust_pressure_before_tc;
        $scope.engine_report.turbocharging.computed.exhaust_pressure_after_tc = exhaust_pressure_after_tc;
    }, true);

    $scope.$watch('engine_report.cylinder_units.cyl', function(newValue, oldValue, scope) {
        var exhaust_temperature = 0;
        var p_max = 0;
        var p_comp = 0;
        var fuel_pump_index = 0;

        var cyl = newValue;
        var counter = 0;
        angular.forEach(cyl, function(value) {
            if (value.exhaust_temperature != undefined) {
                exhaust_temperature += value.exhaust_temperature;
            }
            if (value.p_max != undefined) {
                p_max += value.p_max;
            }
            if (value.p_comp != undefined) {
                p_comp += value.p_comp;
            }
            if (value.fuel_pump_index != undefined) {
                fuel_pump_index += value.fuel_pump_index;
            }
            counter += 1;
        });

        if (counter > 0) {
            exhaust_temperature = exhaust_temperature / counter;
            p_max = p_max / counter;
            p_comp = p_comp / counter;
            fuel_pump_index = fuel_pump_index / counter;
        }
        $scope.engine_report.cylinder_units.computed = {};
        $scope.engine_report.cylinder_units.computed.exhaust_temperature = exhaust_temperature;
        $scope.engine_report.cylinder_units.computed.p_max = p_max;
        $scope.engine_report.cylinder_units.computed.p_comp = p_comp;
        $scope.engine_report.cylinder_units.computed.fuel_pump_index = fuel_pump_index;
    }, true);

    $scope.turbochargerIndices.forEach(function(i) {
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].rpm', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].rpm'] = (newValue < 1000 || newValue > 100000)
                ? 'TC RPM should be between 1000 and 100000'
                : undefined;
        });
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_before_tc', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_before_tc'] = (newValue < 150 || newValue > 550)
                ? 'Exhaust Temperature Before T/C should be between 150 and 550°C'
                : undefined;
        });
        $scope.$watch('engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_after_tc', function(newValue, oldValue, scope) {
            $scope.warningMessages['engine_report.turbocharging.turbo[' + i + '].exhaust_temperature_after_tc'] = (newValue < 150 || newValue > 500)
                ? 'Exhaust Temperature Before T/C should be between 150 and 500°C'
                : undefined;
        });
    });

    $scope.$watch('engine_report.details.air_inlet_temperature', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.air_inlet_temperature'] = (newValue < 10 || newValue > 70)
            ? 'Air Inlet Temperature should be between 10 and 70°C'
            : undefined;
    });

    $scope.$watch('engine_report.details.scavenge_air_pressure', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.scavenge_air_pressure'] = (newValue < 0 || newValue > 3)
            ? 'Scavenge Air Pressure should be between 0 and 3 Bar'
            : undefined;
    });

    $scope.$watch('engine_report.details.scavenge_air_temperature', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.scavenge_air_temperature'] = (newValue < 25 || newValue > 60)
            ? 'Scavenge Air Temperature should be between 25 and 60°C'
            : undefined;
    });

    $scope.$watchGroup(['engine_report.details.oil_consumption', 'engine_report.details.power'], function(newValues, oldValues, scope) {
        var fuelCons = newValues[0];
        var power = newValues[1];
        var minValue = roundToPlaces(150 * Math.pow(10, -6) * power * 24, 2);
        var maxValue = roundToPlaces(300 * Math.pow(10, -6) * power * 24, 2);
        $scope.warningMessages['engine_report.details.oil_consumption'] = (fuelCons < minValue || fuelCons > maxValue)
            ? 'Fuel Consumption should be between ' + minValue + ' and ' + maxValue
            : undefined;
    });

    $scope.$watch('engine_report.details.viscosity_at_40', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.viscosity_at_40'] = (newValue < 10 || newValue > 1000)
            ? 'Viscosity should be between 10 and 1000 cSt'
            : undefined;
    });

    $scope.$watch('engine_report.details.oil_pressure', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.oil_pressure'] = (newValue < 0 || newValue > 10)
            ? 'Fuel Oil Pressure should be between 0 and 10 Bar'
            : undefined;
    });

    $scope.$watch('engine_report.details.oil_temperature_inlet_engine', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.oil_temperature_inlet_engine'] = (newValue < 10 || newValue > 160)
            ? 'Fuel temperature should be between 10 and 160°C'
            : undefined;
    });

    $scope.$watch('engine_report.details.power_factor', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.power_factor'] = (newValue < -1|| newValue > 1)
            ? 'Power factor should be between -1 and 1'
            : undefined;
    });

    $scope.$watch('engine_report.details.fuel_rack', function(newValue, oldValue, scope) {
        $scope.warningMessages['engine_report.details.fuel_rack'] = (newValue < 5|| newValue > 100)
            ? 'Fuel Rack should be between 5 and 100 mm'
            : undefined;
    });

    $scope.$watch('engine_report.unable_to_fill_report', function(newValue) {
        var reportFormScope = angular.element($('[name="reportForm"]')).scope();
        if (reportFormScope) {
            var reportForm = reportFormScope.reportForm;
        } else {
            return;
        }

        if (newValue) {
            angular.forEach(['details', 'cylinder', 'turbocharging'], function(tab) {
                if (reportForm[tab] != undefined) {
                    reportForm[tab].$pristine = true;
                    angular.forEach(reportForm[tab], function(control) {
                        if (typeof control === 'object' && control.hasOwnProperty('$modelValue')) {
                            var alwaysRequired = ['reportFrom', 'reportTo'].indexOf(control.$name) != -1;
                            if (!alwaysRequired) {
                                control.$pristine = true;
                                control.$invalid = false;
                            } else {
                                control.$pristine = false;
                            }
                        }
                    });
                }
            });
        } else {
            angular.forEach(['details', 'cylinder', 'turbocharging'], function(tab) {
                if (reportForm[tab] != undefined) {
                    angular.forEach(reportForm[tab], function(control) {
                        if (typeof control === 'object' && control.hasOwnProperty('$modelValue')) {
                            control.$validate();
                        }
                    });
                }
            });
        }
    });

}]);
