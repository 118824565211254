import { routerApp } from '../app.module';
import { Client, IDnvMapping } from '../models/client';
import { isNull } from 'lodash';

routerApp.controller('managementClientsController', ['ManagementClientsService', '$rootScope', '$scope', '$stateParams', 'notify', 
    function (ManagementClientsService, $rootScope, $scope, $stateParams, notify) {
        
    $rootScope.selectedLevels = ['management', 'clients'];
    $scope.pageState.pageTitle = 'View Clients';
    $scope.selectedClient = null;
    $scope.editing = false;
    $scope.creating = false;
    
    ManagementClientsService.getClients().then((res: any) => {
        $scope.clients = res.data.data;
    });

    $scope.$watch('selectedClient.navtor_company_id', function(newValue, oldValue, scope) {
        if (scope.selectedClient && scope.selectedClient.dnv_mapping){
            scope.selectedClient.dnv_mapping.PerformanceId = newValue;
        }
    });

    $scope.clientFilter = function(client) {
        if (!$scope.clientSearch) {
            return true;  // if no search term is entered, show all clients
        }
        let search = $scope.clientSearch.toLowerCase();
        return (
            client.name.toLowerCase().includes(search) 
            || (client.navtor_company_id && client.navtor_company_id.toLowerCase().includes(search))
        );
    };

    $scope.selectClient = (clientId: string) => {
        $scope.pageState.setLoadingData();
        ManagementClientsService.getClient(clientId).then((res: any) => {
            $scope.selectedClient = parseGetClient(res.data.data);
            $scope.selectedClientId = clientId;
            $scope.pageState.setDataLoaded();
            $scope.pageState.pageTitle = "Client Details - " + $scope.selectedClient.name;
        });
    }

    if ($stateParams.cameFromVessel && $stateParams.clientId != null) {
        $scope.selectClient($stateParams.clientId)
    }

    $scope.deselectClient = () => {
        $scope.selectedClient = null;
        $scope.selectedClientId = null;
        $scope.hasDnvAccessKey = false;
        $scope.editing = false;
        $scope.creating = false;
        $scope.clientForm.$setPristine();
        $scope.pageState.pageTitle = 'View Clients';
    }

    $scope.formatLabel = function(key) {
        // Replace underscores with spaces and capitalize each word
        return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    };
    
    $scope.setEditing = () => {
        $scope.editing = true;
        $scope.selectedClient = new Client($scope.selectedClient, false);
    }

    $scope.newClient = () => {
        $scope.selectedClient = new Client({}, true);
        $scope.creating = true;
        $scope.pageState.pageTitle = 'Create New Client';
    }

    $scope.dnvMapping = () => {
        if ($scope.editing) {
            return;
        }

        if ($scope.selectedClient.dnv_mapping && $scope.selectedClient.dnv.enabled == false) {
            delete $scope.selectedClient.dnv_mapping;
        } else {
            const newDnvMapping: IDnvMapping = {
                PerformanceId: null,
                DnvId: null,
                DnvAccessKey: null
            }
            $scope.selectedClient.dnv_mapping = newDnvMapping;
        }
    }

    $scope.createClient = () => {
        let clientDict = Object.assign({}, $scope.selectedClient);
        clientDict = deleteNullValues(clientDict);

        ManagementClientsService.createClient(clientDict)
        .then((res: any) => {
            $scope.selectedClient = parseGetClient(res.data.data);
            notify({ message: 'Client created successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.clientForm.$setPristine();
            $scope.creating = false;
            $scope.selectedClientId = $scope.selectedClient._id;
        })
        .then(() => {
            ManagementClientsService.getClients().then((res: any) => {
                $scope.clients = res.data.data;
            });
        })
        .catch((err: any) => {
            if (err.data.message.charAt(0) === "[") {
                const messages: string[] = JSON.parse(err.data.message.replace(/'/g, '"'));
                messages.forEach(message => {
                    if (message.includes("Missing navtor_company_id")) {
                        notify({ message: `Error: Missing ID`, duration: 5000, classes: ['bad-notification'] })
                    }
                    else {
                        notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] })
                    }
                });
            } else {
                notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] })
            }
        });
    }

    $scope.saveClient = () => {
        let clientDict = Object.assign({}, $scope.selectedClient);
        clientDict = deleteNullValues(clientDict);

        ManagementClientsService.updateClient($scope.selectedClientId, clientDict)
        .then((res: any) => {
            $scope.selectedClient = parseGetClient(res.data.data);
            notify({ message: 'Client saved successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.clientForm.$setPristine();
            $scope.editing = false;
        })
        .then(() => {
            ManagementClientsService.getClients().then((res: any) => {
                $scope.clients = res.data.data;
            });
        })
        .catch((err: any) => {
            if (err.data.message.charAt(0) === "[") {
                const messages: string[] = JSON.parse(err.data.message.replace(/'/g, '"'));
                messages.forEach(message => {
                    notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] })
                });
            } else {
                notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] })
            }
        });
    }

    $scope.disableClient = (clientId: string): void => {
        ManagementClientsService.disableClient(clientId)
        .then((res: any) => {
            notify({ message: 'Client disabled successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.deselectClient();
        })
        .then((res: any) => {
            ManagementClientsService.getClients().then((res: any) => {
                $scope.clients = res.data.data;
            });
        })
        .catch((err: any) => {
            notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] })
        });
    }

    const deleteNullValues = (obj: any) => {
        delete obj["sanitiseClient"]

        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                for (let subKey in obj[key]) {
                    if (isNull(obj[key][subKey]) || obj[key][subKey] === "") {
                        delete obj[key][subKey];
                    }
                }
            }
            else {
                if (isNull(obj[key]) || obj[key] === "") {
                    delete obj[key];
                }
            }
        }

        return obj
    }

    const parseGetClient = (client: Client) => {
        $scope.hasDnvAccessKey = client.dnv_mapping && client.dnv_mapping.DnvAccessKey;
        client.dnv_mapping.DnvAccessKey = null;
        return client;
    }
}]);