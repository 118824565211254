import Highcharts from 'highcharts';
import moment from 'moment';
import { routerApp, roundToPlaces, themePalette } from '../app.module';

routerApp.factory('SpeedPerformanceService', function() {
    var splitSeriesByDates = function(speedPerformanceSeries, dates) {
            var spIndex = 0;
            var splitSeries = [];
            var sortedDates = dates.slice().sort(function(a, b) { return a - b; });
            sortedDates.push(new Date(8640000000000000)); // Max date
            for (var dateIndex = 0; dateIndex < sortedDates.length; dateIndex++) {
                var date = sortedDates[dateIndex];
                var currentList = [];

                while (spIndex < speedPerformanceSeries.length) {
                    var value = speedPerformanceSeries[spIndex];
                    if (value[0] < date) {
                        currentList.push(value);
                        spIndex++;
                    } else {
                        break;
                    }
                }

                if (currentList.length > 0) {
                    splitSeries.push(currentList);
                }
            }
            return splitSeries;
    };

    var buildSeries = function(timeseriesValues, dryDockings, speed_performance_lifetime_data) {
        var dockingDates = parseDryDockingDates(dryDockings).filter(function(dd) { return dd.dry_docking; }).map(function(dd) { return dd.date; });
        // var sortedDockings = parseDryDockingDates(dryDockings).sort(function(a, b) { return a.date - b.date; });
        var splitSeries = splitSeriesByDates(speed_performance_lifetime_data, dockingDates).filter(function(data) { return data.length > 0; });

        var series = splitSeries.map(function(data, i) {
            var seriesName = splitSeries.length == 1 ? 'Speed Performance' : 'Speed Performance #' + (i + 1);
            var regressionSeriesName = splitSeries.length == 1 ? 'Speed Performance Trend Line' : 'Speed Performance Trend Line #' + (i + 1);
            return {
                data: data,
                keys: ['x','y','reportNumber'],
                name: seriesName,
                zIndex: 10,
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 3
                },
                threshold: 85,
                width: 1,
                color: themePalette.colors.GRAPH_BLUE,
                negativeColor: themePalette.colors.RED,
                regression: true,
                regressionSettings: {
                    name: regressionSeriesName,
                    type: 'linear',
                    color: themePalette.colors.GRAPH_BLUE,
                    dashStyle: 'Dash',
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormatter: function() {
                            var series = this.series;
                            var value = roundToPlaces(this.y, 1);

                            var makeTooltipRowHTML = function(name: string, value: any, unit = undefined) {
                                return'<tr><td style="color:' + series.color + ';padding:0">' + name + ': </td>' +
                                    '<td style="color:' + themePalette.colors.LIGHT_GRAY + ';padding:0"><b>' + value + (unit && unit[0] === '%' ? '' : ' ') + (unit || '') + '</b></td></tr>';
                            };

                            var tooltipHTML = makeTooltipRowHTML(series.name, value, '%');

                            var startPoint = series.data[0];
                            var endPoint = series.data[series.data.length - 1];
                            var yDiff = endPoint.y - startPoint.y;
                            var days = (endPoint.x - startPoint.x) / 1000 / 60 / 60 / 24;
                            var slopePer30Days = yDiff / days * 30;

                            tooltipHTML += makeTooltipRowHTML('Trend Slope', roundToPlaces(slopePer30Days, 1), '% per 30d');
                            tooltipHTML += makeTooltipRowHTML('Trend Start Date', moment(new Date(startPoint.x)).format('DD-MM-YYYY'));
                            tooltipHTML += makeTooltipRowHTML('Trend Start Value', roundToPlaces(startPoint.y, 1), '%');
                            tooltipHTML += makeTooltipRowHTML('Trend End Date', moment(new Date(endPoint.x)).format('DD-MM-YYYY'));
                            tooltipHTML += makeTooltipRowHTML('Trend End Value', roundToPlaces(endPoint.y, 1), '%');

                            return tooltipHTML;
                        },
                        footerFormat: '</table>',
                        useHTML: true
                    },
                    hover: {
                        enabled: true,
                        halo: {
                            size: 0
                        }
                    },
                    marker: {
                        enabled: false,
                    },
                    dataLabels: { enabled: false },
                    allowPointSelect: true,
                    animation: true,
                    animationLimit: 0,
                    enableMouseTracking: true,
                }
            };
        })

        return series;
    };

    var buildDryDockingSeries = function(dryDockings) {
        var series = [];
        var n = 1;
        dryDockings.map(function(dd) {
            if (!dd.dry_docking && (dd.hull_cleaning || dd.propeller_polishing)) {
                var ninetyDays = (1000 * 60 * 60 * 24 * 90);
                var date = dd.date.getTime();

                var events = [];
                if (dd.hull_cleaning) events.push('Hull Cleaning');
                if (dd.propeller_polishing) events.push('Propeller Polishing');
                var name = events.join('/');

                var beforeSeries = {
                    name: 'Before ' + name + ' #'+ n,
                    type: 'line',
                    width: 1,
                    color: themePalette.colors.WHITE_HIGHLIGHT,
                    dashStyle: 'dash',
                    marker: {
                        enabled: false,
                        states: { hover: { enabled: false } }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-weight: bold;">90 days Avg.</span><br/>',
                        pointFormat:
                        'Mean: ' + dd.speed_performance_before + '%<br>' +
                            'Change: ' + dd.speed_performance_change + '%<br>' +
                            'Duration: ' + dd.speed_performance_before_days + ' days<br>' +
                            'Reports: ' + dd.speed_performance_before_reports + '<br>',
                        pointFormatter: null
                    },
                    showInLegend: true,
                    data: [[date - ninetyDays, dd.speed_performance_before], [date, dd.speed_performance_before]]
                };
                var afterSeries = {
                    name: 'After ' + name + ' #'+ n,
                    type: 'line',
                    width: 1,
                    color: themePalette.colors.WHITE_HIGHLIGHT,
                    dashStyle: 'dash',
                    marker: {
                        enabled: false,
                        states: { hover: { enabled: false } }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-weight: bold;">90 days Avg.</span><br/>',
                        pointFormat:
                        'Mean: ' + dd.speed_performance_after + '%<br>' +
                            'Change: ' + dd.speed_performance_change + '%<br>' +
                            'Duration: ' + dd.speed_performance_after_days + ' days<br>' +
                            'Reports: ' + dd.speed_performance_after_reports + '<br>',
                        pointFormatter: null
                    },
                    showInLegend: true,
                    data: [[date + 1, dd.speed_performance_after], [date + ninetyDays, dd.speed_performance_after]]
                };

                if (dd.speed_performance_before != undefined) series.push(beforeSeries);
                if (dd.speed_performance_after != undefined) series.push(afterSeries);
                if (dd.speed_performance_before != undefined|| dd.speed_performance_after != undefined) n += 1;

                return [beforeSeries, afterSeries];
            }
        });

        return series;
    };

    var buildPlotLines = function(dryDockings) {
        var sortedDockings = parseDryDockingDates(dryDockings).sort(function(a, b) { return a.date - b.date; });
        var plotLines = sortedDockings.map(function(dd, i) {
            var text = null;
            if (dd.dry_docking) {
                text = 'Dry Docking: ' + dd.date.toLocaleDateString();
            } else {
                text = [];
                if (dd.hull_cleaning) {
                    text.push('Hull Cleaning');
                }
                if (dd.propeller_polishing) {
                    text.push('Propeller Polish');
                }
                text = text.join(' and ');
                text += ':<br>' + dd.date.toLocaleDateString();
            }

            return {
                color: themePalette.colors.YELLOW,
                dashStyle: 'solid',
                width: 1,
                value: dd.date,
                label: {
                    rotation: 0,
                    verticalAlign: 'bottom',
                    y: -6,
                    text: text,
                    style: {
                        color: themePalette.colors.THEME_TEXT_COLOR
                    }
                }
            };
        });

        return plotLines;
    }

    var plotChart = function(timeseriesValues, dryDockings, speed_performance_lifetime_data, htmlId, mergeOptions) {
        var plotLines = buildPlotLines(dryDockings);
        var speedPerformanceSeries = buildSeries(timeseriesValues, dryDockings, speed_performance_lifetime_data);
        var dryDockingSeries = buildDryDockingSeries(parseDryDockingDates(dryDockings));
        var series = speedPerformanceSeries.concat(dryDockingSeries);

        var xMinAndMax = getXMinAndMax(timeseriesValues, dryDockings);
        var xMin = xMinAndMax[0];
        var xMax = xMinAndMax[1];

        var options = {
            chart: {
                backgroundColor: themePalette.colors.THEME_BLUE,
                zoomType: 'x',
                marginTop: undefined,
                type: 'scatter',
            },
            title: { text: null },
            subtitle: { text: null, },
            xAxis: {
                min: xMin,
                max: xMax,
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%b %e',
                    second: '%b %e',
                    minute: '%b %e',
                    hour: '%b %e',
                    day: '%b %e',
                    week: '%b %e',
                    month: '%b %e',
                    year: '%b %e'
                },
                plotLines: plotLines
            },
            yAxis: [{
                min: 40,
                floor: 50,
                max: 150,
                ceilling: 150,
                tickInterval: 10,
                startOnTick: false,
                title: {
                    text: 'Speed Performance (%)',
                },
                plotLines: [{
                    verticalAlign: 'bottom',
                    color: themePalette.colors.RED,
                    width: 2,
                    value: 85,
                    dashStyle: 'dash',
                    label: {
                        align: 'left',
                        text: 'Hull Fouling'
                    }
                }]
            }],
            legend: { enabled: true },
            credits: { enabled: false },
            exporting: {
                buttons: {
                    exportButton: {
                        enabled: false
                    },
                    contextButton: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormatter: function() {
                    let value = undefined;
                    value = roundToPlaces(this.y, 1);
                    var unit = '%'
                    var report_number = this.reportNumber

                    if (this.series.name == 'Ballast Condition') {
                        value = this.y ? 'Laden' : 'Ballast';
                        unit = '';
                    }

                    return '<br>Report Number: ' + report_number + '<br/>' +
                        '<tr><td style="color:' + this.series.color + ';padding:0">' + this.series.name + ': </td>' +
                        '<td style="color:' + this.series.color + ';padding:0"><b>' + value + ' ' + unit + '</b></td></tr>';
                },
                footerFormat: '</table>',
                shared: false,
                useHTML: true
            },
            plotOptions: {
                series: {
                    color: themePalette.colors.DATA_4,
                    connectNulls: false
                },
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: series
        };
        if (mergeOptions) options = Object.assign(options, mergeOptions);

        var chart = Highcharts.chart(htmlId, options);

        return chart;
    };

    var getXMinAndMax = function(timeseriesValues, dryDockings) {
        var dockingDates = parseDryDockingDates(dryDockings).filter(function(dd) { return dd.dry_docking; })
        var splitSeries = splitSeriesByDates(timeseriesValues, dockingDates)
            .filter(function(data) { return data.length > 0; });

        if (!splitSeries || splitSeries.length == 0) return [undefined, undefined];

        var xMin = splitSeries[0][0][0];
        var lastSeries = splitSeries[splitSeries.length - 1];
        var lastPoint = lastSeries[lastSeries.length - 1];
        var xMax = lastPoint[0];

        return [xMin, xMax];
    };

    var parseDryDockingDates = function(dryDockings) {
        return dryDockings.map(function(dd) {
            dd.date = dd.date['$date'] != undefined ? new Date(dd.date['$date']) : new Date(dd.date);
            return dd;
        });
    };

    return {
        splitSeriesByDates: splitSeriesByDates,
        buildSeries: buildSeries,
        buildDryDockingSeries: buildDryDockingSeries,
        buildPlotLines: buildPlotLines,
        plotChart: plotChart,
        getXMinAndMax: getXMinAndMax,
    };
});
