import get from 'lodash.get';
import { roundToPlaces, pairwise, calculateDistanceBetweenPositions, rad2deg, convertMetersPerSecondToKnots, convertKelvinToCelsius, convertWattToKiloWatt, convertSecondsToHours, convertWattPerSecondToKiloWattHours, convertRadiansPerSecondToRPM, convertRadToDMS, convertRadToDMSString, calculateTotalConsumptionOverRate, calculateFirstLastDelta } from '../../services/utilities';
import moment from 'moment';


export const debounce = (fn: Function, ms = 1000) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

class TrackPoint {
    TimeStamp: Date;
    Latitude: number;
    Longitude: number;
    Course: number;
    Heading: number;
    Speed: number;

    constructor(params: any) {
        this.TimeStamp = moment(params.TimeStamp).toDate();
        this.Heading = roundToPlaces(params.Heading, 2);
        this.Latitude = params.lat_nmea != null ? params.lat_nmea : params.Latitude;
        this.Longitude = params.long_nmea != null ? params.long_nmea : params.Longitude;
        this.Course = params.cog != null ? roundToPlaces(params.cog, 2) : roundToPlaces(params.Course, 2);
        this.Speed = params.sog != null ? roundToPlaces(params.sog, 2) : roundToPlaces(params.Speed, 2);
    }

    /**
     * Calculates total covered distsance within list of trackpoints using
     * distances between Latitudes and Longitude pairs.
     * @param trackpoints list of TrackPoint objects that have Latitude and Longitude properties
     * @returns distance between trackpoints in NM
     */
    static calculateDistanceBetweenPoints(trackpoints: TrackPoint[]) {
        let distance = 0;
        let pairs = pairwise(trackpoints);
        pairs.forEach((pair) => {
            let current = pair[0];
            let next = pair[1];
            let calculatedDistance = calculateDistanceBetweenPositions(
                current.Latitude,
                current.Longitude,
                next.Latitude,
                next.Longitude
            );
            if (calculatedDistance != undefined && calculatedDistance > 0) {
                distance += calculatedDistance;
            }
        });
        return distance;
        // return 500;
    }
}

// how to support navbox data tags?
// get list of vessel tags configured on the vessel, this is in the VesselSpecification service
// combine the output from Navbox API call with the vessel tags depending on configuration and how to map them

class NavboxTag {
    tag_name: string;
    tag_type: string;
    unit: string;
    description: string;
    agg: string;
    report_path: string;
    enabled: boolean;

    constructor(params: any) {
        this.tag_name = params.tag_name;
        this.tag_type = params.tag_type;
        this.unit = params.unit;
        this.description = params.description;
        this.agg = params.agg;
        this.report_path = params.report_path;
        this.enabled = params.enabled;
    }
}

class NavboxTagValueMetadata extends NavboxTag {
    system: string;
    dataPoints: Array<[string, object]>; // array of timeseries 
    numberOfDataPoints: number; // represents number of datapoints received from Navbox API
    numberOfValidPoints: number;   // represents number of datapoints actually used for the analysis
    value: number;  // summary value calculated from running 'agg' function over valid data points

    constructor(params: any) {
        super(params);
        this.dataPoints = params.dataPoints;
        this.system = params.system;
        this.numberOfDataPoints = params.numberOfDataPoints;
        this.numberOfValidPoints = params.numberOfValidPoints;
        this.value = params.value;
    }

    public clone() {
        return new NavboxTagValueMetadata({
            system: this.system,
            value: this.value,
            dataPoints: this.dataPoints,
            numberOfDataPoints: this.numberOfDataPoints,
            numberOfValidPoints: this.numberOfValidPoints,
            description: this.description,
        })
    }

    public setValue(value): NavboxTagValueMetadata {
        this.value = value;
        return this;
    }

}

export class SensorData {

    // singleton pattern to share updateAttributes objects across different responses
    private static _instance: SensorData;

    // keeps track of attributes that were filled using sensor data
    updatedAttributes: Map<string, boolean>;

    system: string;
    attributionEnabled: boolean;
    responseSensorData: Array<[string, number]>;
    navboxTrackPointResponse: Array<object>;
    navboxSelectedTagResponse: Array<object>;
    navboxTags: Array<NavboxTag>;
    latestSensorDataWithMinTimestamp: [string, object];

    // attributesSet: Map<string, boolean>;
    attributesSet: Map<string, NavboxTagValueMetadata>;
    numberOfEntries: number;

    // tags to exclude from initial rounding for more precision
    excludedTagNames = ["Latitude", "Longitude", "lat_nmea", "long_nmea"];

    private constructor() { }

    public static getInstance(responseSensorData?): SensorData {
        if (!SensorData._instance) {
            SensorData.initialize();
        }
        if (responseSensorData)
            SensorData._instance.build(responseSensorData);

        return SensorData._instance;
    }

    public static getInstanceFromNavboxTrackResponse(navboxTrackPointResponse: Array<object>, navboxTags): SensorData {
        if (!SensorData._instance) {
            SensorData.initialize();
        }
        SensorData._instance.build({
            data: {
                system: 'Navbox',
                tags: null,
                number_of_entries: 0,
                attribution_enabled: false,
            }
        });
        SensorData._instance.setNavboxTrackPointResponse(navboxTrackPointResponse);
        SensorData._instance.setNavboxTags(SensorData.buildNavboxTags(navboxTags));
        return SensorData._instance;
    }

    public static buildNavboxTags(navboxTags: Array<object>): Array<NavboxTag> {
        return navboxTags as Array<NavboxTag>;
    }

    public static downsample(navboxTrackPointResponse: Array<object>, frequency: string) {
        let frequencyToBucket = {
            'minute': (timestamp: string) => {
                return moment(timestamp).format('YYYY-MM-DD HH:mm');
            },
            'hour': (timestamp: string) => {
                return moment(timestamp).format('YYYY-MM-DD HH');
            },
            'day': (timestamp: string) => {
                return moment(timestamp).format('YYYY-MM-DD');
            },
        }

        let result = {};
        let binFunction = frequencyToBucket[frequency];
        let trackPoints = navboxTrackPointResponse.map((o) => {
            return new TrackPoint(o);
        });
        for (let index in trackPoints) {
            let trackpoint = trackPoints[index];
            if (trackpoint != undefined && trackpoint['TimeStamp'] != undefined) {
                let bin = binFunction(trackpoint['TimeStamp']);
                if (!result.hasOwnProperty(bin)) {
                    result[bin] = trackpoint;
                }
            }
        }
        return Object.keys(result).sort().reduce((agg, key) => {
            agg.push(result[key]);
            return agg;
        }, []);
    }
    
    public static getNavboxTagFromReportPath(reportTagPath, navboxTags) {
        if (CustomLongitudePaths.includes(reportTagPath)) {
            return navboxTags.find(tag => tag.tag_name === "lat_nmea" || tag.tag_name === "Latitude");

        } else if (CustomLatitudePaths.includes(reportTagPath)) {
            return navboxTags.find(tag => tag.tag_name === "long_nmea" || tag.tag_name === "Longitude");

        } else {
            return navboxTags.find(tag => tag.report_path === reportTagPath);
        }
    }

    /**
     * Initializes/re-initializes sensor data so that updated attributes are cleared
     */
    public static initialize() {
        SensorData._instance = new SensorData();
        SensorData._instance.updatedAttributes = new Map();
    }

    private build(responseSensorData, attributesSet = new Map()) {
        this.system = responseSensorData.data.system;
        this.responseSensorData = responseSensorData.data.tags;
        this.numberOfEntries = responseSensorData.data.number_of_entries;
        this.attributionEnabled = responseSensorData.data.attribution_enabled;
        this.attributesSet = attributesSet;
    }

    private setNavboxTrackPointResponse(navboxTrackPointResponse: Array<object>) {
        this.navboxTrackPointResponse = navboxTrackPointResponse;
    }
    private setNavboxTags(navboxTags: Array<NavboxTag>) {
        this.navboxTags = navboxTags;
    }
    public setSelectedNavboxTagResponse(navboxTagResponse: Array<object>) {
        this.navboxSelectedTagResponse = navboxTagResponse;
    }

    public getMinOfLastUpdatedSensorDataTime() {
        if (!this.latestSensorDataWithMinTimestamp)
            return null;            
        return this.latestSensorDataWithMinTimestamp[0];
    }

    private buildDataPointsSummary(dataPoints: Array<[string, object]>, tag: NavboxTag) {
        // todo: format date objects so that they are readable and in the report's timezone

        const formatValue = (value: number, unit: string) => `${roundToPlaces(value, 2)} ${unit}`;
        const formatDate = (currentDate: string) => (new Date(currentDate)).toLocaleString();

        //calculate and format concurrent data points to achieve consumption over rate
        const formatRateEntry = (currentDate: string, currentValue: number, index: number) => {
            const [prevDate, prevValue] = points[index];
            const timeDifference = (+new Date(currentDate) - +new Date(prevDate)) / 1000;
            const valueAverage = (+currentValue + +prevValue) / 2;
            return `${formatDate(currentDate)}: ${formatValue(valueAverage * timeDifference, tag.unit)}`;
        };

        //calculate and format concurrent data points to achieve value differences
        const formatDeltaEntry = (currentDate: string, currentValue: number, index: number) => {
            const [, prevValue] = points[index];
            return `${formatDate(currentDate)}: ${formatValue(currentValue - +prevValue, tag.unit)}`;
        };

        const formatPositionEntry = (currentDate: string, value: object) => {
            const formattedValue = this.convertNavboxPositionSensorData(tag, value);
            return `${formatDate(currentDate)}: ${formattedValue} ${tag.unit}`;
        };

        const formatDefaultEntry = (currentDate: string, value: object) => {
            const formattedValue = this.convertNavboxSensorData(tag, value);
            return `${formatDate(currentDate)}: ${formattedValue} ${tag.unit}`;
        };

        let result = "Sample data: <br/>";
        const points = dataPoints.slice(-6);

        switch (tag.tag_name) {
            case "fm_me_tot_rate":
            case "fm_ae_tot_rate":
            case "fm_aux_blr_1_tot_rate":
            case "fm_aux_blr_2_tot_rate":
            case "fm_comp_blr_1_tot_rate":
                result += points
                    .slice(1)
                    .map(([currentDate, currentValue], index) => formatRateEntry(currentDate, +currentValue, index))
                    .reverse()
                    .join("<br/>");
                break;
            case "fm_me_tot_cnt":
            case "fm_ae_tot_cnt":
                result += points
                    .slice(1)
                    .map(([currentDate, currentValue], index) => formatDeltaEntry(currentDate, +currentValue, index))
                    .reverse()
                    .join("<br/>");
                break;
            case "Latitude":
            case "Longitude":
            case "lat_nmea":
            case "long_nmea":
                result += points.slice(-5)
                    .map(([currentDate, currentValue]) => formatPositionEntry(currentDate, currentValue))
                    .reverse()
                    .join("<br/>");
                break;
            default:
                result += points.slice(-5)
                    .map(([currentDate, currentValue]) => formatDefaultEntry(currentDate, currentValue))
                    .reverse()
                    .join("<br/>");
        }
        return result;
    }

    attributeNavboxTagsToReport(report, override = false, paths = [], rootScope): void {
        let customTags = [
            'derived_observed_distance',
            'derived_pwr_me1_me2'
        ]
        let customTagPaths = [
            'report.position', 
            'power.main_engine_1_and_2_power'
        ]
        if (!this.navboxTags) {
            return;
        }
        for (const tag of this.navboxTags) {
            if (!tag.enabled) {
                continue;
            }
            const tagName = tag.tag_name;
            const tagType = tag.tag_type;
            const agg = tag.agg;
            const reportPath = tag.report_path;

            const isCustomTag = customTags.includes(tagName);
            const isSelectedNavboxTag = paths.length > 0 && this.navboxSelectedTagResponse.length > 0;
            const source = isSelectedNavboxTag ? this.navboxSelectedTagResponse : this.navboxTrackPointResponse;
            const trackPoints = source
                .map(object => new TrackPoint(object));

            const navboxFilteredTrackPoints = source
                .sort((a, b) => a['TimeStamp'] > b['TimeStamp'] ? 1 : 0)
                .filter(x => x[tagName] != null || isCustomTag);

            let navboxTagTrackPointPairs = navboxFilteredTrackPoints
                .map(x => ([
                    x['TimeStamp'],
                    tagType == 'number' && !this.excludedTagNames.includes(tag.tag_name) ? roundToPlaces(x[tagName], 2) : x[tagName]
                ]));
            let navboxTagTrackPoints = navboxFilteredTrackPoints
                .map(x => x[tagName]);

            // custom tag use to calculate main engines 1 & 2 power
            if (tagName == customTags[1]) {
                const dualEnginePowerPoints = this.extractDualEnginePowerPoints(navboxFilteredTrackPoints);
                navboxTagTrackPoints = dualEnginePowerPoints.totalPowerPoints;
                navboxTagTrackPointPairs = dualEnginePowerPoints.totalPowerPointPairs;
            }

            // if it is a custom tag, and there are no points whatsoever
            // or if there are no tag points
            if (
                (isCustomTag && (trackPoints == undefined || trackPoints.length == 0))  // if this is a custom tag, and there are no points in the list
                || (!navboxTagTrackPoints || navboxTagTrackPoints.length == 0) // if this is not a custom tag, and there are no tag datapoints
                || (isCustomTag && isSelectedNavboxTag && customTagPaths.every(path => !paths.includes(path))) // for selected custom tags for reloading
            ) {
                continue;
            }

            // run aggregation function
            let value: number | null = null;
            switch (agg) {
                case "last": {
                    // return last item in the list
                    value = navboxTagTrackPoints[navboxTagTrackPoints.length - 1];
                    break;
                }
                case "first": {
                    // return first item in the list
                    value = navboxTagTrackPoints[0];
                    break;
                }
                case "avg": {
                    // return average from the list
                    value = navboxTagTrackPoints.sum() / navboxTagTrackPoints.length;
                    break;
                }
                case "median": {
                    // sort numbers
                    let sortedNavboxTagTrackPoints = navboxTagTrackPoints.sort();
                    let midIndex = Math.floor(sortedNavboxTagTrackPoints.length / 2);
                    if (midIndex % 2) {
                        // pick the number in the middle
                        value = sortedNavboxTagTrackPoints[midIndex];
                    } else {
                        // average the two middle numbers
                        value = (sortedNavboxTagTrackPoints[midIndex - 1] + sortedNavboxTagTrackPoints[midIndex]) / 2;
                    }
                    break;
                }
                case "derived": {
                    value = TrackPoint.calculateDistanceBetweenPoints(trackPoints);
                    break;
                }
                case "consumption_over_rate": {
                    value = calculateTotalConsumptionOverRate(navboxTagTrackPoints, report.operational.report_period);
                    break;
                }
                case "delta": {
                    value = calculateFirstLastDelta(navboxTagTrackPoints[navboxTagTrackPoints.length - 1], navboxTagTrackPoints[0]);
                    break;
                }
                default: {
                    // return first item in the list
                    value = null;
                    break;
                }
            }

            // convert units and format values         
            value = this.convertNavboxSensorData(tag, value);

            // summarize
            let dataPointsSummary;
            if (!isCustomTag[0]) {
                const points = navboxTagTrackPointPairs as Array<[string, object]>;
                dataPointsSummary = this.buildDataPointsSummary(points, tag);

                const lastPoint = points && points.length > 0 ? points[points.length - 1] : null;
                const timestamp = lastPoint ? new Date(lastPoint[0]) : null;
                const lastSensorDataTime = this.latestSensorDataWithMinTimestamp ? new Date(this.latestSensorDataWithMinTimestamp[0]) : null;
                if (timestamp && (!lastSensorDataTime || timestamp < lastSensorDataTime))
                    this.latestSensorDataWithMinTimestamp = lastPoint;
            }
            const system = 'Navbox';
            const numberOfDataPoints = isSelectedNavboxTag ? this.navboxSelectedTagResponse.length : this.navboxTrackPointResponse.length;
            const numberOfValidPoints = navboxTagTrackPoints.length;
            const description = tag.description;
            const metadata = new NavboxTagValueMetadata({
                system: system,
                value: value,
                dataPoints: dataPointsSummary,
                numberOfDataPoints: numberOfDataPoints,
                numberOfValidPoints: numberOfValidPoints,
                description: description,
            })

            // attribute data to report
            switch (tag.tag_name) {
                case "Latitude":
                case "lat_nmea": {
                    // if this is a Port report, we have to map attributes to the operational tab
                    // otherwise, map them to position tab
                    var isPortReport = report._cls == 'Report.PortReport';
                    let latitude_path_hours = isPortReport ? 'operational.port_latitude_hours' : 'position.ship_lat_hours';
                    let latitude_path_minutes = isPortReport ? 'operational.port_latitude_minutes' : 'position.ship_lat_minutes';
                    let latitude_path_seconds = isPortReport ? 'operational.port_latitude_seconds' : 'position.ship_lat_seconds';
                    let latitude_path_direction = isPortReport ? 'operational.port_latitude_direction' : 'position.ship_lat_direction';
                    // custom handle latitude
                    const positionData = convertRadToDMS(value, false);
                    if (positionData != undefined) {
                        report = this.setValueOnReportPath(report, latitude_path_hours, metadata.clone().setValue(positionData[0]), override);
                        report = this.setValueOnReportPath(report, latitude_path_minutes, metadata.clone().setValue(positionData[1]), override);
                        report = this.setValueOnReportPath(report, latitude_path_seconds, metadata.clone().setValue(roundToPlaces(positionData[2], 0)), override);
                        report = this.setValueOnReportPath(report, latitude_path_direction, metadata.clone().setValue(positionData[3]), this.isBrandNewReport(report,rootScope,override));
                    }
                    break;
                }
                case "Longitude":
                case "long_nmea": {
                    // if this is a Port report, we have to map attributes to the operational tab
                    // otherwise, map them to position tab
                    var isPortReport = report._cls == 'Report.PortReport';
                    let longitude_path_hours = isPortReport ? 'operational.port_longitude_hours' : 'position.ship_lon_hours';
                    let longitude_path_minutes = isPortReport ? 'operational.port_longitude_minutes' : 'position.ship_lon_minutes';
                    let longitude_path_seconds = isPortReport ? 'operational.port_longitude_seconds' : 'position.ship_lon_seconds';
                    let longitude_path_direction = isPortReport ? 'operational.port_longitude_direction' : 'position.ship_lon_direction';
                    // custom handle longitude
                    const positionData = convertRadToDMS(value, true);
                    if (positionData != undefined) {
                        report = this.setValueOnReportPath(report, longitude_path_hours, metadata.clone().setValue(positionData[0]), override);
                        report = this.setValueOnReportPath(report, longitude_path_minutes, metadata.clone().setValue(positionData[1]), override);
                        report = this.setValueOnReportPath(report, longitude_path_seconds, metadata.clone().setValue(roundToPlaces(positionData[2], 0)), override);
                        report = this.setValueOnReportPath(report, longitude_path_direction, metadata.clone().setValue(positionData[3]), this.isBrandNewReport(report,rootScope,override));
                    }
                    break;
                }
                default: {
                    // the usual case, map directly to report_path
                    if (reportPath != null) {
                        report = this.setValueOnReportPath(report, reportPath, metadata, override);
                    }
                    break;
                }
            }
        }
    }

    convertNavboxSensorData(tag: NavboxTag, value) {
        // project stage   
        if (value) {
            switch (tag.tag_name) {
                case "Speed":
                case "sog":
                case "stw":
                    value = convertMetersPerSecondToKnots(value);
                    break;
                case "Course":
                case "cog":
                case "relativewind_dir":
                    value = rad2deg(value);
                    break;
                case "sea_tmp":
                    value = convertKelvinToCelsius(value);
                    break;
                case "pwr_me_1":
                case "pwr_me_2":
                case "derived_pwr_me1_me2":
                case "ae_1_pwr":
                case "ae_2_pwr":
                case "ae_3_pwr":
                case "ae_4_pwr":
                case "ae_5_pwr":
                case "ae_6_pwr":
                case "pto_shft_1_pwr":
                    value = convertWattToKiloWatt(value);
                    break;
                case "me_1_rhs_cnt":
                case "me_2_rhs_cnt":
                case "ae_1_rhs_cnt":
                case "ae_2_rhs_cnt":
                case "ae_3_rhs_cnt":
                case "ae_4_rhs_cnt":
                case "ae_5_rhs_cnt":
                case "ae_6_rhs_cnt":
                    value = convertSecondsToHours(value);
                    break;
                case"prop_tot_shft_1_eng":
                    value = convertWattPerSecondToKiloWattHours(value);
                    break;
                case "me1_rpm":
                    value = convertRadiansPerSecondToRPM(value);
                    break;
                default:
                    break;
            }
        }

        // round numbers, functions run on data types
        if (tag.tag_type === "number" && !this.excludedTagNames.includes(tag.tag_name)) {
            value = roundToPlaces(value, 2);
        }
        return value;
    }

    convertNavboxPositionSensorData(tag: NavboxTag, value: any) {
        switch (tag.tag_name) {
            case "Latitude":
            case "lat_nmea":
                value = convertRadToDMSString(value, false);
                break
            case "Longitude":
            case "long_nmea":
                value = convertRadToDMSString(value, true);
                break
            default:
                break;
        }
        return value
    }

    //get power for dual engines bases on engine 1 and 2
    extractDualEnginePowerPoints(navboxFilteredTrackPoints: any[]) {
        
        // filter null properties for both engines since it was skipped as a custom tag
        const filteredTrackPoints = navboxFilteredTrackPoints.filter(x => {
            return !(x['pwr_me_1'] === null && x['pwr_me_2'] === null);
        });
      
        if (filteredTrackPoints.length > 0){
            // get the sum of pwr_me_1 and pwr_me_2 into an array
            const totalPowerPoints = filteredTrackPoints.map(x => x['pwr_me_1'] + x['pwr_me_2']);
            const totalPowerPointPairs = filteredTrackPoints.map(x => ([x['TimeStamp'], roundToPlaces(x['pwr_me_1'] + x['pwr_me_2'], 2)]));
            return { totalPowerPoints: totalPowerPoints, totalPowerPointPairs: totalPowerPointPairs };
        } else {
            return null; // Return null if both pwr_me_1 and pwr_me_2 tags not available
        }
    }

    setValueOnReportPath(report: object, propertyPath: string, metadata: NavboxTagValueMetadata, override = false) {
        // get value
        const sensorValue = metadata.value;
        const reportValue = get(report, propertyPath);
        const reportValueStillEmpty = !reportValue && reportValue != 0;

        // map already updated sensor properties after saving, in "Edit Reports in Progress" mode
        if(this.updatedAttributes[propertyPath] == undefined && report['lastUpdatedSensorData'] && report['lastUpdatedSensorData'][propertyPath] != undefined) {
            this.updatedAttributes[propertyPath] = report['lastUpdatedSensorData'][propertyPath];
        }
        const previouslyFilledBySensorData = this.updatedAttributes[propertyPath];
        const shouldPopulateSensorEntry = reportValueStillEmpty || previouslyFilledBySensorData || override;
        const sensorValueIsValid = sensorValue != undefined;

        this.attributesSet[propertyPath] = metadata;

        if (shouldPopulateSensorEntry) {
            let propertyPaths = propertyPath.split('.');
            let subObject = report;
            for (let i = 0; i < propertyPaths.length; i++) {
                let subPath = propertyPaths[i];
                if (i == propertyPaths.length - 1) {
                    if (sensorValueIsValid) {
                        subObject[subPath] = sensorValue;
                        this.updatedAttributes[propertyPath] = true;
                    } else {
                        delete subObject[subPath];
                        this.updatedAttributes[propertyPath] = false;
                    }
                } else {
                    // handle main engines
                    if (subPath.includes('[') && subPath.includes(']')) {
                        // let objectIndex = parseInt(subPath.split('[')[1].split(']')[0]);
                        let arrayAttribute = subPath.split('[')[0];
                        if (!subObject[arrayAttribute]) {
                            subObject[arrayAttribute] = [{}];
                        }
                        subObject = subObject[arrayAttribute][0];
                    } else {
                        if (!subObject[subPath]) {
                            subObject[subPath] = {};
                        }
                        subObject = subObject[subPath];
                    }
                }
            }
        }
        return report;
    }

    /**
     * Attributes all available data inside responseSensorData onto input report object, unless specified.
     * @param report passed in $scope.report object
     * @param override set true to update all report sensor field
     * @param paths specify field sensor path(s) to update in report 
     */
    attributeToReport(report, override = false, paths = []): void {
        if (!this.attributionEnabled) {
            return report;
        }
        for (var keyValuePair of this.responseSensorData) {
            let propertyPath: string = keyValuePair[0];
            let value = keyValuePair[1];

            // get value
            let reportValue = get(report, propertyPath);

            this.attributesSet[propertyPath] = {
                value: value,
                numberOfEntries: this.numberOfEntries,
            };

            // set it on the report value if that value is null
            let reportValueStillEmpty = !reportValue && reportValue != 0;
            let previouslyFilledBySensorData = this.updatedAttributes[propertyPath];
            // if paths is not empty then only fields within paths should be filled
            let shouldPopulateSensorEntry = paths && paths.length > 0 ? paths.indexOf(propertyPath) > -1 : reportValueStillEmpty || previouslyFilledBySensorData || override;
            let sensorValueIsValid = value != undefined;
            if (shouldPopulateSensorEntry) {
                let propertyPaths = propertyPath.split('.');
                let subObject = report;
                for (let i = 0; i < propertyPaths.length; i++) {
                    let subPath = propertyPaths[i];
                    if (i == propertyPaths.length - 1) {
                        if (sensorValueIsValid) {
                            subObject[subPath] = value;
                            this.updatedAttributes[propertyPath] = true;
                        } else {
                            delete subObject[subPath];
                            this.updatedAttributes[propertyPath] = false;
                        }
                    } else {
                        // handle main engines
                        if (subPath.includes('[') && subPath.includes(']')) {
                            // let objectIndex = parseInt(subPath.split('[')[1].split(']')[0]);
                            let arrayAttribute = subPath.split('[')[0];
                            if (!subObject[arrayAttribute]) {
                                subObject[arrayAttribute] = [{}];
                            }
                            subObject = subObject[arrayAttribute][0];
                        } else {
                            if (!subObject[subPath]) {
                                subObject[subPath] = {};
                            }
                            subObject = subObject[subPath];
                        }
                    }
                }
            }
        }
        return report
    }

    /**
     * @returns map of attribute path to true/false, indicating the value is available from sensor data
     */
    getAttributeSet(): Map<string, NavboxTagValueMetadata> {
        return this.attributesSet;
    }

    // Determine a brand new, not saved/drafted report to override with sensor data
    isBrandNewReport(report, rootScope, override: boolean) {
        return (report.status == 'started' && rootScope.selectedLevels[1] == "create-new-report" && rootScope.offlineReports.length > 0) ? override : true;
    }
}

export const CustomLatitudePaths = [
    "position.ship_lat_hours", "position.ship_lat_minutes", "position.ship_lat_seconds", "position.ship_lat_direction",
    "operational.port_latitude_hours", "operational.port_latitude_minutes", "operational.port_latitude_seconds", "operational.port_latitude_direction"
];

export const CustomLongitudePaths = [
    "position.ship_lon_hours", "position.ship_lon_minutes", "position.ship_lon_seconds", "position.ship_lon_direction",
    "operational.port_longitude_hours", "operational.port_longitude_minutes", "operational.port_longitude_seconds", "operational.port_longitude_direction"
];

export {
    TrackPoint,
}


